import { createSelector } from 'reselect';

/*
  Input
*/
const message = state => state.global.message;
const secondaryNav = state => state.global.secondary_sidebar;
const inputRules = state => state.global.input_rules;
const notifications = state => state.global.notifications;
const viewPreferences = state => state.global.view_preferences || {};

/*
  Selectors
*/
export const globalMessage = createSelector(message, message => message);
export const secondaryNavState = createSelector(secondaryNav, secondaryNav => secondaryNav);
export const notificationsSelector = createSelector(notifications, notifications => notifications);
export const viewPreferencesSelector = createSelector(viewPreferences, viewPreferences => viewPreferences);

// Accepted domain regex.
export const domainRegex = createSelector(inputRules, inputRules => inputRules.valid_domain_regex);

// Accpeted slug regex.
export const slugRegex = createSelector(inputRules, inputRules => inputRules.valid_slug_regex);
