import moment from 'moment';
import momentZone from 'moment-timezone';
import StringHelper from './string';
import MathHelper from './math';

const localTimezone = moment.tz.guess();
const timezoneSelectOptions = moment.tz.names().map(tz => ({ value: tz, label: tz }));

const now = () => moment();
const fromNow = (dateValue) => moment(dateValue).fromNow()

const toUTCFormat = (localValue, format) => {
  localValue = localValue || now();
  format = format || 'YYYY-MM-DD H:mm:ss';
  return moment(localValue).utc().format(format)
}

const create = (value) => {
  return moment(value);
}

const humanize = seconds => {
  if (seconds < 60) {
    return parseInt(seconds) + ' seconds';
  }
  const duration = moment.duration(seconds * 1000);
  return duration.humanize();
};

const humanizePrecisly = seconds => {
  seconds = parseFloat(seconds);
  if (seconds < 60) {
    return MathHelper.toFloat(seconds) + ' seconds';
  } else if (seconds < 3600) {
    const mins = MathHelper.toFloat(seconds / 60);
    return mins + ' minutes';
  } else {
    const hrs = MathHelper.toFloat(seconds / 3600);
    return hrs + ' hours';
  }
}

const getFilenameSuffix = (date) => moment(date || now()).format('YYYY-MM-DD_hh-mm-ss');
const getDateOnly = date => moment(date || now()).format('YYYY-MM-DD');
const getTimeOnly = date => moment(date || now()).format('hh:mm A');

const getDiffInSeconds = (date1, date2) => {
  date2 = date2 || moment();
  return Math.abs(Math.ceil((date2 - date1) / 1000));
}

const convertToTimezone = (time, tz, toFormat, fromFormat) => {
  if (!time) {
    window.logHelper.error('No time provided');
    return 'Invalid date';
  }

  if (fromFormat) {
    time = moment(time, fromFormat).toISOString();
  } else if (time.endsWith('Z') && !time.includes('+00:00')) {
    time = StringHelper.trimRight(time, 'Z') + 'Z';
  } else if (!time.endsWith('Z')) {
    time = moment(time + 'Z').toISOString(); // Convert to UTC
  }

  if (!tz) {
    tz = momentZone.tz.guess(); // No timezone provided, use local timezone
  }

  let dateString = tz && tz !== 'null'
    ? momentZone(time).tz(tz).format(toFormat)
    : moment(time).format(toFormat);
  if (dateString === 'Invalid date') {
    window.logHelper.error('Invalid date', time, tz, toFormat, fromFormat);
    return 'Invalid date';
  }

  return dateString;
}

const toHumanDateTime = (time, tz, fromFormat, includeSeconds) => {
  const toFormat = 'YYYY-MM-DD HH:mm' + (includeSeconds ? ':ss' : '');
  return convertToTimezone(time, tz, toFormat, fromFormat);
}

const toHumanDate = (time, tz, fromFormat) => {
  return convertToTimezone(time, tz, 'YYYY-MM-DD', fromFormat);
}

const toHumanTime = (time, tz, fromFormat, includeSeconds) => {
  const toFormat = 'hh:mm' + (includeSeconds ? ':ss' : '') + ' A';
  return convertToTimezone(time, tz, toFormat, fromFormat);
}

const toHumanTimeWithSeconds = (time, tz, fromFormat) => {
  return convertToTimezone(time, tz, 'hh:mm:ss A', fromFormat);
}

const toHumanText = (time, tz, fromFormat) => {
  return convertToTimezone(time, tz, 'MMM Do YYYY, h:mm A', fromFormat);
}

const timeRangeOptions = [
  {name:'Last minute',value:'last_1_minute'},
  {name:'Last 5 minutes',value:'last_5_minutes'},
  {name:'Last 15 minutes',value:'last_15_minutes'},
  {name:'Last 30 minutes',value:'last_30_minutes'},
  {name:'Last 1 hour',value:'last_1_hour'},
  {name:'Last 3 hours',value:'last_3_hours'},
  {name:'Last day',value:'last_24_hours'},
  {name:'Last 3 days',value:'last_3_days'},
  {name:'Custom',value:'custom'}
];

const getDateFromTimeRange = (timeRangeOption) => {
  if (timeRangeOption === 'custom') return null;
  let minsAgo = null;
  const now = moment();
  const parts = timeRangeOption.split('_');
  if (parts[2].startsWith('minute')) {
    minsAgo = parseInt(parts[1]);
  } else if (parts[2].startsWith('hour')) {
    minsAgo = parseInt(parts[1]) * 60;
  } else if (parts[2].startsWith('day')) {
    minsAgo = parseInt(parts[1]) * 60 * 24;
  }
  return now.subtract(minsAgo, 'minutes').toISOString();
}

const DateHelper = {
  create,
  now,
  localTimezone,
  fromNow,
  toUTCFormat,
  humanize,
  humanizePrecisly,
  getDateOnly,
  getTimeOnly,
  getDiffInSeconds,
  convertToTimezone,
  toHumanDateTime,
  toHumanTimeWithSeconds,
  toHumanDate,
  toHumanTime,
  getFilenameSuffix,
  timeRangeOptions,
  toHumanText,
  getDateFromTimeRange,
  timezoneSelectOptions,
};

export default DateHelper;