import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components';
import StringHelper from '../../helpers/string';

const Tip = styled.span`
  font-size: 12px;
  color: #676aff;
  margin: 0 5px;
  height: 16px;
  svg {
    font-size: 16px;
    color: inherit;
  }
  p::first-letter {
    text-transform: uppercase;
  }
  .custom-tooltip {
    span,
    span.multiline {
      text-align: left;
      font-weight: normal;
    }
  }
`;

const TipBlock = styled.div`
  svg {
    font-size: 16px;
    color: inherit;
  }
  p::first-letter {
    text-transform: uppercase;
  }
`;

const Tooltip = ({ text, place }) => {
  // Generate a random string ID for each tooltip to avoid multiple rerenders
  // Of the same component.
  const id = StringHelper.randomString();

  return (
    <Fragment>
      <Tip className='custom-tip' data-tip={text} data-for={id}>
        <FaInfoCircle />
        <ReactTooltip id={id} multiline={true} place={place} className='custom-tooltip' />
      </Tip>
    </Fragment>
  );
};

export const SQTooltip = ({ title, children }) => {
  // Generate a random string ID for each tooltip to avoid multiple rerenders
  // Of the same component.
  const id = StringHelper.randomString();

  return (
    <TipBlock data-tip data-for={id}>
      {children}
      <ReactTooltip id={id}>{title}</ReactTooltip>
    </TipBlock>
  );
};

export default Tooltip;
