import React, { useEffect, useRef } from 'react';
import UserHelper from '../../helpers/user';
import { useRouteMatch } from 'react-router-dom';
import AdminSettings from './admin';
import PartnerSettings from './partner';
import { setSecondarySidebar } from '../../store/global/globalActions';
import { useDispatch } from 'react-redux';
import EmailTemplates from './common/emailtemplates';
import Legal from './common/legal';

const commonLinks = [
  { name: 'Email Templates', slug: '/email-templates' },
  { name: 'Terms & Conditions', slug: '/legal' },
];

const commonComponents = {
  EmailTemplates: EmailTemplates,
  Legal: Legal,
};

const Settings = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const commonRoutes = useRef([
    {
      path: `${path}/email-templates`,
      componentName: 'EmailTemplates',
      exact: true,
    },
    {
      path: `${path}/legal`,
      componentName: 'Legal',
      exact: true,
    },
  ]);

  const props = useRef({
    routes: commonRoutes.current,
    links: commonLinks,
    components: commonComponents,
  });

  // Handle secondary sidebar view
  useEffect(() => {
    if(UserHelper.isAdmin()){
      dispatch(setSecondarySidebar(true));
    }
    return () => {
    if(UserHelper.isAdmin()){
      dispatch(setSecondarySidebar(false));
    }
    };
  }, [dispatch]);

  return UserHelper.isAdmin() ? (
    <AdminSettings {...props.current} />
  ) : UserHelper.isPartner() ? (
    <PartnerSettings />
  ) : null;
};

export default Settings;
