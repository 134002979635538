import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 24px;
  top: 52px;
  display: flex;
  align-items: center;
  background: white;
  padding: 12px 12px 12px 18px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.15);
  z-index: 1400;
  color: black;
  cursor: pointer;
  /* Capitalize 1st letter only */
  p::first-letter {
    text-transform: uppercase;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: ${props =>
      props.color ? props.theme.background[props.color] : props.theme.background.danger};
  }
`;
