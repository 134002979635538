import { dispatchPostPromise, dispatchDeletePromise, dispatchGetPromise } from '../../helpers';
import ServerService from '../../services/server';

/*
  Action Types
*/
export const SERVER_SET_ALL = 'SERVER_SET_ALL';
export const SERVER_CREATE = 'SERVER_CREATE';
export const SERVER_UPDATE = 'SERVER_UPDATE';
export const SERVER_RECENT_UPDATE = 'SERVER_RECENT_UPDATE';
export const SERVER_DELETE = 'SERVER_DELETE';
export const SERVER_DELETE_RECENT_UPDATES = 'SERVER_DELETE_RECENT_UPDATES';
export const SERVER_INSTANCE_CREATE = 'SERVER_INSTANCE_CREATE';
export const SERVER_INSTANCE_UPDATE = 'SERVER_INSTANCE_UPDATE';
export const SERVER_INSTANCE_DELETE = 'SERVER_INSTANCE_DELETE';
export const SERVER_INSTANCES_SET_ALL = 'SERVER_INSTANCES_SET_ALL';

/*
  Action Creators
*/

// Create a new server.
export const createNewServer = data => {
  return dispatchPostPromise(ServerService.create(data), SERVER_CREATE);
};

// Update a specified server.
export const updateServer = data => {
  return dispatchPostPromise(ServerService.update(data), SERVER_UPDATE);
};

// Delete specified server.
export const deleteServer = server => {
  let data = { server_slug: server.slug };
  return dispatchDeletePromise(ServerService.delete(data), SERVER_DELETE, server);
};

// List server instances.
export const listServerInstances = data => {
  return dispatchGetPromise(ServerService.listInstances(data), SERVER_INSTANCES_SET_ALL);
}

// Clone a specified server instance
export const cloneServerInstance = data => {
  return dispatchPostPromise(ServerService.cloneInstance(data), SERVER_INSTANCE_CREATE);
};

// Set the primary instance for a server.
export const setServerPrimaryInstance = data => {
  return dispatchPostPromise(ServerService.setPrimaryInstance(data), SERVER_UPDATE);
};

// Delete a server instance
export const deleteServerInstance = data => {
  return dispatchDeletePromise(ServerService.deleteInstance(data), SERVER_INSTANCE_DELETE, data);
};

// Resize server volumes
export const resizeServerVolumes = data => {
  return dispatchPostPromise(ServerService.resizeVolumes(data), SERVER_INSTANCE_UPDATE);
};

// Update server config
export const updatePhpConfig = data => {
  return dispatchPostPromise(ServerService.updatePhpConfig(data), SERVER_UPDATE);
};

// Get OPcache stats
export const getOPcacheStats = data => {
  return dispatchPostPromise(ServerService.getOPcacheStats(data));
};

// Get Access stats
export const getAccessStats = data => {
  return dispatchPostPromise(ServerService.getAccessStats(data));
};

// Get Redis stats
export const getRedisStats = data => {
  return dispatchPostPromise(ServerService.getRedisStats(data));
};
