import { dispatchPostPromise } from 'helpers';
import MeService from 'services/me';

/*
  Action Types
*/
export const ME_SET_ALL = 'ME_SET_ALL';
export const ME_SET_PARENT = 'ME_SET_PARENT';
export const ME_UPDATE = 'ME_UPDATE';
export const ME_SET_INTEGRATION = 'ME_SET_INTEGRATION';

/*
  Action Creators
*/

export const setIntegration = body => {
  return dispatch => dispatch({ type: ME_SET_INTEGRATION, payload: body });
};

// Update my profile data.
export const updateMyProfile = data => {
  return dispatchPostPromise(MeService.updateProfile(data), ME_UPDATE);
};
