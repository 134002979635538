import {
  WEBSITE_SET_ALL,
  WEBSITE_CREATE,
  WEBSITE_UPDATE,
  WEBSITE_RECENT_UPDATE,
  WEBSITE_DELETE_RECENT_UPDATES,
  WEBSITE_DELETE,
  WEBSITE_PACKAGE_SET_ALL,
  WEBSITE_PACKAGE_CREATE,
  WEBSITE_PACKAGE_UPDATE,
  WEBSITE_PACKAGE_DELETE,
  WEBSITE_CDN_CREATE,
  WEBSITE_CDN_UPDATE,
  WEBSITE_CDN_DELETE,
  WEBSITE_SSL_UPDATE,
  SET_WEBSITE_BACKUPS,
} from './websiteActions';
import {
  isEmpty, isEmptyOrNull, hasProperty
} from '../../helpers';
import ArrayHelper from '../../helpers/array';
import ReducerHelper from '../../helpers/reducer';

const websitesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_WEBSITE_BACKUPS:
      if (!isEmpty(action.payload)) {
        return state.map(w =>
          w.slug === action.payload[0].website_slug ? {
            ...w,
            backups: [...action.payload]
          } : w,
        );
      } else {
        return state;
      }

    case WEBSITE_SET_ALL:
      return [...action.payload];

    case WEBSITE_CREATE:
      // Check if the website exists in store already. This is to prevent duplicate websites in the store.
      // For example, if the website was created in the database but the API call to fetch recent updates
      // was made before the create API call was made, the website will be added to the store twice.
      if (ArrayHelper.find(state, 'slug', action.payload.slug)) {
        window.logHelper.warning(`Website ${action.payload.slug} already exists in the store, updating it instead.`);
        return ReducerHelper.update(state, 'slug', action.payload);
      }
      return [...state, action.payload];

    case WEBSITE_UPDATE:
      let updatedWebsite = action.payload;
      // Add from resources if the payload is a customer's subscription
      if (!isEmpty(action.payload.customer_website)) {
        updatedWebsite = action.payload.customer_website;
      }
      // Add from resources if the payload has website inside it
      if (hasProperty(action.payload, 'website')) {
        updatedWebsite = action.payload.website;
        if (isEmptyOrNull(updatedWebsite)) {
          return state;
        }
      }
      return ReducerHelper.update(state, 'slug', updatedWebsite);

    case WEBSITE_RECENT_UPDATE:
      let _websites = [...state];
      action.payload.websites.forEach(website => {
        if (action.payload.currentSlugs.includes(website.slug)) {
          let updatedWebsite = website;
          // Add from resources if the payload is a customer's subscription
          if (!isEmpty(website.customer_website)) {
            updatedWebsite = website.customer_website;
          }
          _websites = ReducerHelper.update(_websites, 'slug', updatedWebsite, true);
        } else {
          _websites.push(website)
        }
      });
      return _websites;

    case WEBSITE_DELETE:
      return ArrayHelper.filterOut(state, 'slug', action.payload.slug);

    case WEBSITE_DELETE_RECENT_UPDATES:
      let _allWebsites = [...state];
      const _allWebsitesData = [...action.payload]
      for (const i in _allWebsitesData) {
        _allWebsites = ArrayHelper.filterOut(_allWebsites, 'slug', _allWebsitesData[i]);
      }
      return _allWebsites

    case WEBSITE_PACKAGE_SET_ALL:
      const websiteSlug = action.params.website_slug;
      return state.map(w =>
        w.slug === websiteSlug ? {
          ...w,
          private_packages: action.payload,
        } :
        w,
      );

    case WEBSITE_PACKAGE_CREATE:
      // Add the new package to website private_packages
      return state.map(w =>
        w.slug === action.payload.website_slug ? {
          ...w,
          private_packages: [...w.private_packages, action.payload]
        } :
        w,
      );

    case WEBSITE_PACKAGE_UPDATE:
      // Change to the new updated package inside the private_packages array.
      return state.map(w =>
        w.slug === action.payload.website_slug ? {
          ...w,
          private_packages: [
            ...ArrayHelper.filterOut(w.private_packages, 'slug', action.payload.slug),
            action.payload,
          ],
        } :
        w,
      );

    case WEBSITE_PACKAGE_DELETE:
      return state.map(w =>
        w.slug === action.payload.website_slug ? {
          ...w,
          private_packages: [
            ...ArrayHelper.filterOut(w.private_packages, 'slug', action.payload.slug),
          ],
        } :
        w,
      );

    case WEBSITE_CDN_CREATE:
    case WEBSITE_CDN_UPDATE:
      return state.map(w =>
        w.slug === action.payload.website_slug ? {
          ...w,
          cdns: action.payload,
        } :
        w,
      );

    case WEBSITE_CDN_DELETE:
      return state.map(w =>
        w.slug === action.payload.website_slug ? {
          ...w,
          cdns: [],
        } :
        w,
      );

    case WEBSITE_SSL_UPDATE:
      return state.map(w =>
        w.slug === action.payload.website_slug ? {
          ...w,
          domains_ssl: action.payload,
        } :
        w,
      );

    default:
      return state;
  }
};

export default websitesReducer;