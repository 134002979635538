import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TitleBar } from 'styles/layout/titlebar';
import DatabaseService from 'services/database';
import { setGlobalErrorMsg, setGlobalPleaseWaitMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { websitesSelector } from 'store/website/websiteSelectors';
import useConfirm from 'hooks/useConfirm';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';

const ServerIndex = () => {
  useTitle('Databases');
  const dispatch = useDispatch();
  const allWebsites = useSelector(websitesSelector);
  const [loading, setLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [modal, setModal] = useState(false);
  const confirm = useConfirm();
  const mounted = useRef(true);

  useEffect(() => {
    loadDatabases();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // ----------
  // API
  // ----------

  const loadDatabases = () => {
    setLoading(true);
    DatabaseService.list({})
      .then(databases => {
        if (mounted.current) {
          setDatabases(databases);
        }
      })
      .catch(error => dispatch(setGlobalErrorMsg(error)))
      .finally(() => setLoading(false));
  }

  const deleteDatabase = (item) => {
    dispatch(setGlobalPleaseWaitMsg({ model: 'database', action: 'delete', id: item.slug }));
    DatabaseService.destroy({ database_slug: item.slug })
      .then(() => {
        if (mounted.current) {
          setDatabases(databases.filter(d => d.slug !== item.slug));
          dispatch(setGlobalSuccessMsg({ model: 'database', id: item.slug, action: 'deleted' }));
        }
      })
      .catch(error => dispatch(setGlobalErrorMsg(error)));
  }

  const refreshDatabase = (item) => {
    dispatch(setGlobalPleaseWaitMsg({ model: 'database', action: 'refresh', id: item.slug }));
    DatabaseService.refresh({ database_slug: item.slug })
      .then((database) => {
        if (mounted.current) {
          const index = databases.findIndex(d => d.slug === database.slug);
          if (index !== -1) {
            const newDatabases = [...databases];
            newDatabases[index] = database;
            setDatabases(newDatabases);
            dispatch(setGlobalSuccessMsg({ model: 'database', id: database.slug, action: 'refreshed' }));
          } else {
            window.logHelper.error('Database not found in list');
          }
        }
      })
      .catch(error => dispatch(setGlobalErrorMsg(error)));
  }

  const attachWebsite = (database, websiteSlug) => {
    setModal(prevState => ({ ...prevState, loading: true }));
    dispatch(setGlobalPleaseWaitMsg({ model: 'database', action: 'attach', id: database.slug }));
    const data = { database_slug: database.slug, website_slug: websiteSlug };
    DatabaseService.attachWebsite(data)
      .then(() => {
        dispatch(setGlobalPleaseWaitMsg({ model: 'database', id: database.slug, action: 'attached' }));
      })
      .catch(error => dispatch(setGlobalErrorMsg(error)))
      .finally(() => setModal(false));
  }

  const detachWebsite = (database) => {
    dispatch(setGlobalPleaseWaitMsg({ model: 'database', action: 'detach', id: database.slug }));
    DatabaseService.detachWebsite({ database_slug: database.slug, website_slug: database.website_slug })
      .then(() => {
        dispatch(setGlobalPleaseWaitMsg({ model: 'database', id: database.slug, action: 'detached' }));
      })
      .catch(error => dispatch(setGlobalErrorMsg(error)));
  }

  // ---------
  // HANDLERS
  // ---------

  const onClickAttachWebsite = (database) => {
    setModal({ database, website_slug: '', loading: false });
  }

  // ----------
  // TABLE
  // ----------

  const actions = [
    {
      value: 'Refresh',
      onClick: refreshDatabase,
    },
    {
      value: 'Attach Website',
      doHide: row => !!row.website_slug,
      onClick: onClickAttachWebsite,
    },
    {
      value: 'Detach Website',
      doHide: row => !row.website_slug,
      onClick:  item => DialogHelper
        .confirmAction(confirm, 'detach', item.slug, 'database')
        .then(() => detachWebsite(item)),
    },
    {
      value: 'Delete',
      onClick:  item => DialogHelper
        .confirmDelete(confirm, item.slug, 'database')
        .then(() => deleteDatabase(item)),
    },
  ];

  const headers = [
    JsxHelper.createTableTextHeaderWithCallback('slug', 'Database', '15%', row =>
      JsxHelper.createTableMultiLineCell({
        header: row.slug,
        subheader: row.region_slug,
      }),
    ),
    JsxHelper.createTableWebsiteHeader(allWebsites, '30%'),
    JsxHelper.createTableTextHeaderWithCallback('storage', 'Storage', '15%', row => `${row.storage} GB`),
    JsxHelper.createTableActionsHeader(actions, '40%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={JsxHelper.createBreadcrumbs('databases')}>Databases</TitleBar.Title>
      </TitleBar>
      <Content className='main-databases-table'>
        <WPSDataTable columns={headers} body={databases} rowsPerPage={25} loading={loading} />
        {modal && DialogHelper.inputs({
          title: 'Attach Website',
          onClose: () => setModal(false),
          onConfirm: () => attachWebsite(modal.database, modal.website_slug),
          confirmBtn: 'Attach',
          loading: modal.loading,
          disabled: !modal.website_slug,
          inputs: [{
            name: 'website_slug',
            type: 'select',
            label: 'Website',
            value: modal.website_slug,
            options: WebsiteHelper.buildSelectOptions(allWebsites),
            onChange: e => setModal(prevState => ({ ...prevState, website_slug: e.target.value })),
            required: true,
          }]
        })}
      </Content>
    </Fragment>
  );
};

export default ServerIndex;
