import React, { useEffect, useState, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/auth/login';
import Index from './pages';
import GlobalStyles, { GlobalLoader } from './styles/globalStyles';
import { PageLoader } from './styles/layout/loaders';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from './store/auth/authActions';
import { getGlobalData, setGlobalLoader } from './store/global/globalActions';
import PrivateRoute from './utils/privateRoute';
import Toaster from './components/layout/toaster';
import MeService from 'services/me';
import { WEBSITE_RECENT_UPDATE, WEBSITE_DELETE_RECENT_UPDATES, fetchWebsite } from 'store/website/websiteActions';
import { SERVER_RECENT_UPDATE, SERVER_DELETE_RECENT_UPDATES } from 'store/server/serverActions';
import { DNS_ZONE_UPDATE } from 'store/dnsZone/dnsZoneActions';
import store from 'store';
import { isServerBusy, isWebsiteBusy, isEmptyOrNull } from 'helpers';
import EmailVerification from 'pages/guest/emailVerification';
import PasswordReset from 'pages/guest/passwordReset';
import ResetTempPassword from 'pages/user/resetTempPassword';
import CustomerProfileSetup from '../src/pages/guest/customerProfileSetup';
import useDidMountEffect from 'hooks/useDidMountEffect';
import EmailPreview from 'pages/settings/common/emailtemplates/emailPreview';
import TermsAndConditions from 'pages/guest/termsAndConditions';
import { websitesSelector } from 'store/website/websiteSelectors';
// React package to manipulate pages metadata easly
import { Helmet } from 'react-helmet';
import ErrorBoundaryHandler from './components/wpstaq/ErrorBoundaryHandler/ErrorBoundaryHandler';
import { ErrorBoundary } from 'react-error-boundary';
import DateHelper from 'helpers/date';
import WebsiteHelper from 'helpers/website';

const App = () => {
  const dispatch = useDispatch();
  const app_loader = useSelector(state => state.global.app_loader);
  const currentWebsitesSlugs = useSelector(websitesSelector).map(w => w.slug);
  const [siteUpdateAttempts, setSiteUpdateAttempts] = useState({});
  let websitesRef = useRef();

  // current path name : for manipulate mobile case in client profile setup
  const currentPath = window.location.pathname;

  // Try to auth the user from localStorage access token and fetch user startup data
  // if token not found return to login page
  useEffect(() => {
    const token = localStorage.getItem('access_token');

    if (token) {
      dispatch(setAuthData());
      dispatch(getGlobalData());
    } else {
      dispatch(setGlobalLoader(false));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useDidMountEffect(() => {
    websitesRef.current = currentWebsitesSlugs;
  }, [currentWebsitesSlugs]);

  // Periodically check against the API if there are any recent
  // updates to the resources that require updating the redux
  useDidMountEffect(() => {
    let intervalId;
    let currentInterval;
    const token = localStorage.getItem('access_token');
    const minInterval = 10;
    const minSecondsAgo = 10;
    let timeAway;
    let visibiltyScheduled = false;

    // Checks what the current interval should be depending on the
    // states of servers/websites
    const calcNewInterval = () => {
      const state = store.getState();
      const isBusyServers = state.servers.some(server => isServerBusy(server));
      const isBusyWebsites = state.websites.some(website => isWebsiteBusy(website));
      if (isBusyServers || isBusyWebsites) {
        return 10;
      }
      return 60;
    };
    // Fetches recent updates from the API
    const fetchRecentUpdates = secondsAgo => {
      // Don't call API if the tab is not active.
      if (document.visibilityState === 'hidden') {
        return;
      }

      // If the user not logged in then don't call the API.
      // (on logout the localStorage is cleared).
      const token = localStorage.getItem('access_token');
      if (!token) {
        return;
      }

      // Do not allow seconds less than the minimum.
      if (!secondsAgo) {
        secondsAgo = currentInterval;
      }

      // If the secondsAgo parameter is not specified then apply the
      // length of the current interval.
      secondsAgo = Math.max(secondsAgo, minSecondsAgo);
      // Fetch updates from server
      MeService.fetchStatusUpdates({ seconds_ago: secondsAgo })
        .then(res => {
          const deleted = res.deleted;
          const saved = res.saved;
          // Deleted websites
          if (!isEmptyOrNull(deleted.websites)) {
            dispatch({ type: WEBSITE_DELETE_RECENT_UPDATES, payload: deleted.websites });
          }
          // Deleted servers
          if (deleted.servers && !isEmptyOrNull(deleted.servers)) {
            dispatch({ type: SERVER_DELETE_RECENT_UPDATES, payload: deleted.servers });
          }
          // Updated websites
          if (saved.websites && !isEmptyOrNull(saved.websites)) {
            let _data = {
              websites: saved.websites,
              currentSlugs: websitesRef.current,
            };
            dispatch({ type: WEBSITE_RECENT_UPDATE, payload: _data });
            // Updated dns zones
            saved.websites.filter(w => !!w.dns_zone).forEach(w => dispatch({ type: DNS_ZONE_UPDATE, payload: w }));
          }
          // Updated servers
          if (saved.servers && !isEmptyOrNull(saved.servers)) {
            dispatch({ type: SERVER_RECENT_UPDATE, payload: saved.servers });
          }
        })
        // .catch(err => {
        //   console.log('Error while getting recent-updates :', err);
        // });
    };
    // Schedule update logic on browser visibiltiy change
    const scheduleUpdateOnVisibilityChange = () => {
      if (visibiltyScheduled) {
        return;
      }
      document.addEventListener('visibilitychange', () => {
        // If hidden start a timer until it changes back to visible
        if (document.visibilityState === 'hidden') {
          // Capture the time the user left the browser
          if (!timeAway) {
            timeAway = DateHelper.now();
          }
        } else if (document.visibilityState === 'visible') {
          // If user hasn't left the browser yet
          if (!timeAway) {
            return;
          }
          // If the user returns to browser check the time elapsed since
          // the user left the browser
          const timeDifference = DateHelper.getDiffInSeconds(timeAway);
          // If elapsed time is more than 5 hours then reload page
          if (timeDifference > 5 * 3600) {
            window.location.reload();
          }
          // If elapsed time is more than 30 seconds then fetch recent updates
          if (timeDifference > 30) {
            fetchRecentUpdates(timeDifference);
            // Remove previous interval if set
            if (intervalId) {
              clearInterval(intervalId);
            }
            // Set new fetch timer from start again (60 seconds)
            intervalId = setInterval(fetchRecentUpdates, 60 * 1000);
            currentInterval = 60;
            timeAway = null; // Reset time user left
            window.logHelper.info(`Updated fetch interval to ${currentInterval} seconds.`);
          }
        }
      });
      // Set flag so the listener is not double scheduled
      visibiltyScheduled = true;
    };
    // Updates the interval between consequent API calls
    const maybeUpdateFetchTimer = () => {
      // Do not allow less than the minimum interval
      const newInterval = Math.max(calcNewInterval(), minInterval);

      // If the current interval equals the new then do nothing
      if (currentInterval && newInterval === currentInterval) {
        return;
      }

      // Remove previous interval if set
      if (intervalId) {
        clearInterval(intervalId);
      }

      // Set new fetch timer
      intervalId = setInterval(fetchRecentUpdates, newInterval * 1000);
      currentInterval = newInterval;
      window.logHelper.info(`Updated fetch interval to ${currentInterval} seconds.`);
    };
    // For some sites don't wait for the recent updates
    const forceUpdateSitesBasedOnStatus = () => {
      const state = store.getState();
      const toUpdate = state.websites.filter(website => {
        if (!isWebsiteBusy(website) && !WebsiteHelper.hasSslConnectionError(website)) {
          return false;
        }
        const siteData = siteUpdateAttempts[website.slug];
        return !siteData || siteData.attempts < 10;
      });
      for (const _website of toUpdate) {
        const data = {
          website_slug: _website.slug,
          check_status: WebsiteHelper.hasSslConnectionError(_website)
        }
        dispatch(fetchWebsite(data))
        .then(() => {
          let siteData = siteUpdateAttempts[_website.slug];
          if (!isEmptyOrNull(siteData)) {
            siteData.attempts += 1;;
          } else {
            siteData = {
              attempts: 1,
              last_try: DateHelper.now()
            };
          }
          window.logHelper.info(`(#${siteData.attempts}) Refreshed ${WebsiteHelper.getLabel(_website)} data.`);
          setSiteUpdateAttempts(prev => {
            prev[_website.slug] = siteData;
            return prev;
          });
        })
      }
    }

    // Run a hook every 10 seconds to check whether the fetch timer's
    // interval should be updated
    if (!app_loader && token) {
      setInterval(maybeUpdateFetchTimer, 10 * 1000);
      setInterval(forceUpdateSitesBasedOnStatus, 30 * 1000);
      maybeUpdateFetchTimer();
      scheduleUpdateOnVisibilityChange();
    }
    // eslint-disable-next-line
  }, [app_loader]);

  const getPageMetaData = () => {
    if (currentPath.includes('setup-profile')) {
      return <meta name='viewport' content='width=device-width, initial-scale=1' />;
    } else {
      return <meta name='viewport' content='width=1024' />;
    }
  };
  // Show loader if user data is not yet loaded
  if (app_loader) {
    return (
      <GlobalLoader>
        <GlobalStyles />
        <PageLoader />
      </GlobalLoader>
    );
  }

  return (
    <Fragment>
      <ErrorBoundary FallbackComponent={ErrorBoundaryHandler}>
        <GlobalStyles />
        <Toaster />
        <Helmet>{getPageMetaData()}</Helmet>
        <Router>
          <Switch>
            <Route exact path='/verify'>
              <EmailVerification />
            </Route>
            <Route exact path='/reset-password'>
              <PasswordReset />
            </Route>
            <Route exact path='/reset-temp-password'>
              <ResetTempPassword />
            </Route>
            <Route exact path='/setup-profile'>
              <CustomerProfileSetup />
            </Route>
            <Route exact path='/email-preview'>
              <EmailPreview />
            </Route>
            <Route exact path='/terms-and-conditions'>
              <TermsAndConditions />
            </Route>
            <Route exact path='/login'>
              <Login />
            </Route>
            <PrivateRoute path='/'>
              <Index />
            </PrivateRoute>
            <Route path='*'>
              <div>404</div>
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;
