import React from 'react';
import Icon from '../../../components/layout/icon';
import { isFunction } from '../../../helpers';
import ReactTooltip from 'react-tooltip';
import { useHistory, useParams } from 'react-router-dom';

import './sPanelCard.css';
const SPanelCard = ({ website, cardData }) => {
  const history = useHistory();
  const { slug } = useParams();
  const openItemPage = (el, prefix, link)=>{
    if (el.disabled || !link) return;
    history.push({ pathname: `${prefix}${link}` })
  }
  return (
    <div className='card-container'>
      <h2 className='card-title'>{cardData.title}</h2>
      <div className='items-container'>
        {cardData.items && cardData.items.map((el, index) => {
            if (el.doHide && el.doHide(website)) {
              return null;
            }
            const disabled = el.doDisable && el.doDisable(website);
            const _prefix = isFunction(el.isAbsolute) && el.isAbsolute(website) ? '' : `/websites/${slug}`;
            const _link = isFunction(el.link) ? el.link(website) : el.link;
            return (
                <div onClick={()=>!disabled?openItemPage(el, _prefix, _link):null} key={index} className='card-item' data-for={`item_${index}`} data-tip={!disabled?el.hoverText:(el.disableText?el.disableText(website):'')}>
                  <span className={`${!disabled?cardData.color:'disabled-color'} icon-container`}>
                    <Icon tag={el.icon} />
                  </span>
                  <span className='item-name'>{el.name}</span>
                  <ReactTooltip id={`item_${index}`} />
                </div>
            );
          })}
      </div>
    </div>
  );
};

export default SPanelCard;
