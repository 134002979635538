import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { getAccessStats } from 'store/server/serverActions';
import { Content } from 'styles/globalStyles';
import { AnalyticsCard } from 'styles/website/profile';
import { TitleBar, GroupTitle } from 'styles/layout/titlebar';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { serverBySlug } from 'store/server/serverSelectors';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { WPSInput } from 'styles/layout/forms';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import DateHelper from 'helpers/date';
import WebsiteHelper from 'helpers/website';
import ArrayHelper from 'helpers/array';
import FirewallHelper from 'helpers/firewall';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';

const ServerAccessStats = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { params } = useRouteMatch();
  const server = useSelector(serverBySlug(params.slug));
  const mounted = useRef(true);
  const services = [ 'nginx', 'wordpress' ];
  // States for the main table.
  const [currentService, setCurrentService] = useState('nginx');
  const [nginxRawData, setNginxRawData] = useState(false);
  const [nginxData, setNginxData] = useState(false);
  const [wordpressData, setWordpressData] = useState(false);
  const [wordpressRawData, setWordpressRawData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [timeRangeOption, setTimeRangeOption] = useState(DateHelper.timeRangeOptions[1].value);
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [nginxCards, setNginxCards] = useState([]);
  const [wordpressCards, setWordpressCards] = useState([]);
  // States for requests and IPs.
  const [resource, setResource] = useState(null);
  const [requestsTableData, setRequestsTableData] = useState([]);
  const [requestsTableHeaders, setRequestsTableHeaders] = useState([]);

  // ---------------------------------------------------------------
  // MAIN TABLE
  // ---------------------------------------------------------------

  const prepareTableData = (service, data) => {
    let _tableData = [];
    if (service === 'nginx') {
      setNginxRawData(data);
      _tableData = parseNginxData(data);
      setNginxData(_tableData);
    } else if (service === 'wordpress') {
      setWordpressRawData(data);
      _tableData = parseWordpressData(data);
      setWordpressData(_tableData);
    }
    setTableData(_tableData);
  };

  const parseWordpressData = (data) => {
    // data: { client, method, time, uri, wpuser, auth, remote_addr }
    let _tableData = [];
    let _cards = { "Requests": 0, "Authenticated": 0, "Unique IPs": 0, "POST": 0, "GET": 0, "WP CLI": 0 };
    let _ips = {};
    Object.keys(data).forEach((resource) => {
      let requests = data[resource];
      let row = { resource };
      let ips = {};
      for (const request of requests) {
        if (!request) {
          continue;
        }
        row.auth_requests = (row.auth_requests || 0) + (request.auth ? 1 : 0);
        _cards["Authenticated"] += (request.auth ? 1 : 0);
        row.total_requests = (row.total_requests || 0) + 1;
        _cards["Requests"] += 1;
        if (!ips[request.client]) {
          ips[request.client] = true;
          row.unique_ips = (row.unique_ips || 0) + 1;
        }
        if (!_ips[request.client]) {
          _ips[request.client] = true;
          _cards["Unique IPs"] += 1;
        }
        row.total_post = (row.total_post || 0) + (request.method === 'POST' ? 1 : 0);
        _cards["POST"] += (request.method === 'POST' ? 1 : 0);
        row.total_get = (row.total_get || 0) + (request.method === 'GET' ? 1 : 0);
        _cards["GET"] += (request.method === 'GET' ? 1 : 0);
        row.total_wpcli = (row.total_wpcli || 0) + (request.wp_cli ? 1 : 0);
        _cards["WP CLI"] += (request.wp_cli ? 1 : 0);
      }
      _tableData.push(row);
    });
    setWordpressCards(_cards);
    return _tableData;
  }

  const parseNginxData = (data) => {
    // data: { client, time, status, sentBytes, request }
    let _tableData = [];
    let _cards = { "Requests": 0, "404": 0, "200": 0, "POST": 0, "Assets": 0, "Unique IPs": 0};
    let _ips = {};
    const assets = ['.css', '.js', '.png', '.jpg', '.jpeg', '.gif', '.ico', '.svg', '.woff', '.woff2', '.ttf', '.eot'];
    Object.keys(data).forEach((resource) => {
      let requests = data[resource];
      let row = { resource };
      let ips = {};
      for (const request of requests) {
        if (!request) {
          continue;
        }
        _cards["Requests"] += 1;
        row.total_404 = (row.total_404 || 0) + (request.status === '404' ? 1 : 0)
        _cards["404"] += (request.status === '404' ? 1 : 0);
        row.total_200 = (row.total_200 || 0) + (request.status === '200' ? 1 : 0)
        _cards["200"] += (request.status === '200' ? 1 : 0);
        row.total_requests = (row.total_requests || 0) + 1;
        row.total_post = (row.total_post || 0) + (request.request.includes('POST') ? 1 : 0);
        _cards["POST"] += (request.request.includes('POST') ? 1 : 0);
        const includesAsset = assets.some((asset) => request.request.includes(asset));
        row.total_assets = (row.total_assets || 0) + (includesAsset ? 1 : 0);
        _cards["Assets"] += (includesAsset ? 1 : 0);
        if (!ips[request.client]) {
          ips[request.client] = true;
          row.unique_ips = (row.unique_ips || 0) + 1;
        }
        if (!_ips[request.client]) {
          _ips[request.client] = true;
          _cards["Unique IPs"] += 1;
        }
      }
      _tableData.push(row);
    });
    setNginxCards(_cards);
    return _tableData;
  }

  const switchService = (service) => {
    setCurrentService(service);
    if (service === currentService) return;
    if (service === 'nginx') {
      if (nginxData) {
        setTableData(nginxData);
      } else {
        fetchData(service);
      }
    } else if (service === 'wordpress') {
      if (wordpressData) {
        setTableData(wordpressData);
      } else {
        fetchData(service);
      }
    }
  };

  const fetchData = (service, fromDate, toDate) => {
    setLoading(true);
    const data = { 
      server_slug: server.slug,
      start_date: fromDate || selectedDates[0] || DateHelper.getDateFromTimeRange(timeRangeOption),
      end_date: toDate || selectedDates[1] || null,
      service: service || currentService,
    }
    dispatch(getAccessStats(data))
      .then((stats) => prepareTableData(stats.service, stats.data))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const ipActions = [
    {
      value: 'Block IP',
      onClick: (item) => blockClientIP(item),
    },
  ];

  const resourceActions = [
    {
      value: 'View IPs',
      onClick: (item) => displayResourceData(item.resource, 'View IPs'),
    },
    {
      value: 'View Requests',
      onClick: (item) => displayResourceData(item.resource, 'View Requests'),
    },
  ];

  const websiteHeader = {
    name: 'Website',
    selector: 'resource',
    width: '20%',
    searchable: true,
    cell: row => {
      if (row.resource.includes(':')) {
        return row.resource;
      }
      return (
        <span className='class-link' onClick={() => WebsiteHelper.goTo(history, row.resource)}>
          {row.resource}
        </span>
      );
    },
  };

  const ZERO_VALUE = '0';

  const wordpressHeaders = [
    websiteHeader,
    JsxHelper.createTableTextHeader('total_requests', 'Requests', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('auth_requests', 'Authenticated', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('unique_ips', 'Unique IPs', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_get', 'GET', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_post', 'POST', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_wpcli', 'WP CLI', '10%', ZERO_VALUE),
    JsxHelper.createTableActionsHeader(resourceActions, '20%'),
  ];

  const nginxHeaders = [
    websiteHeader,
    JsxHelper.createTableTextHeader('total_requests', 'Requests', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('unique_ips', 'Unique IPs', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_200', '200', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_404', '404', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_post', 'POST', '10%', ZERO_VALUE),
    JsxHelper.createTableTextHeader('total_assets', 'Assets', '10%', ZERO_VALUE),
    JsxHelper.createTableActionsHeader(resourceActions, '20%'),
  ];

  const handleTimeRangeSelect = (e) => {
    const value = e.target.value;
    setTimeRangeOption(value);
    if (value === 'custom') return;
    const startDate = DateHelper.getDateFromTimeRange(value);
    fetchData(currentService, startDate, null);
  }

  const handleCustomDateSelect = (e) => {
    const { name, value } = e.target;
    const valueAsDate = DateHelper.create(value);
    if (!valueAsDate.isValid()) {
      dispatch(setGlobalErrorMsg('Invalid date'));
      return;
    }
    const _value = valueAsDate.toISOString();
    if (name === 'from') {
      setSelectedDates([_value, selectedDates[1]]);
    } else if (name === 'to') {
      setSelectedDates([selectedDates[0], _value]);
    }
    // If both dates are selected, fetch data.
    if (name === 'to' && selectedDates[0] && _value) {
      fetchData(currentService, selectedDates[0], _value);
    } else if (name === 'from' && selectedDates[1] && _value) {
      fetchData(currentService, _value, selectedDates[1]);
    }
  }

  // ---------------------------------------------------------------
  // SECONDARYY TABLE
  // ---------------------------------------------------------------

  const renderIPWithTrustSymbol = (row) => {
    const color = FirewallHelper.httpResponseToColor('200');
    if (row.localhost) {
      row.trusted = true;
      row.trusted_origin = 'localhost';
    }
    return (
      <Fragment>
        <span style={{marginRight: '5px'}}>{row.client}</span>
        {row.trusted && <div style={{display: 'inline-block', marginRight: '5px'}}>
          {JsxHelper.createIcon({
            icon: color,
            color,
            tooltip: row.trusted_origin ? `Trusted by ${row.trusted_origin}` : 'Trusted',
            customClass: 'wps-icon-small',
          })}
        </div>}
      </Fragment>
    );
  }

  const blockClientIP = (row) => {
    alert('Not implemented yet');
  }

  // View requests headers.
  const viewRequestsWordpressHeaders = [
    JsxHelper.createTableRequestTimeHeader('time'),
    JsxHelper.createTableTextHeaderWithCallback('client', 'Client IP', '12%', renderIPWithTrustSymbol),
    JsxHelper.createTableCountryFlagHeader(),
    JsxHelper.createTableTextHeader('remote_addr', 'Proxy IP', '12%', '-'),
    JsxHelper.createTableHttpRequestHeader('uri', 10, '44%'),
    JsxHelper.createTableActionsHeader(ipActions, '12%'),
  ];
  const viewRequestsNginxHeaders = [
    JsxHelper.createTableRequestTimeHeader('time', 'DD-MMM-YYYY HH:mm:ss Z'),
    JsxHelper.createTableTextHeaderWithCallback('clientOrigin', 'Client IP', '12%', (row) => {
      row.client = row.clientOrigin !== '-' ? row.clientOrigin : row.client;
      return renderIPWithTrustSymbol(row);
    }),
    JsxHelper.createTableCountryFlagHeader(),
    JsxHelper.createTableTextHeaderWithCallback('client', 'Proxy IP', '12%', (row) => row.clientOrigin === '-' ? '-' : row.client),
    JsxHelper.createTableRequestStatusHeader(),
    JsxHelper.createTableHttpRequestHeader('request', 0, '32%'),
    JsxHelper.createTableActionsHeader(ipActions, '14%'),
  ];
  // View IPs headers.
  const viewIPsWordpressHeaders = [
    JsxHelper.createTableTextHeaderWithCallback('client', 'Client IP', '12%', renderIPWithTrustSymbol),
    JsxHelper.createTableCountryFlagHeader(),
    JsxHelper.createTableTextHeader('total_requests', 'Requests', '10%'),
    JsxHelper.createTableTextListHeader('uris', 'URIs', (key, value) => `(${value}) ${key}`, '61%'),
    JsxHelper.createTableActionsHeader(ipActions, '10%'),
  ];
  const viewIPsNginxHeaders = [
    JsxHelper.createTableTextHeaderWithCallback('client', 'Client IP', '12%', renderIPWithTrustSymbol),
    JsxHelper.createTableCountryFlagHeader(),
    JsxHelper.createTableTextHeader('total_requests', 'Requests', '10%'),
    JsxHelper.createTableTextListHeader('uris', 'URIs', (key, value) => `(${value}) ${key}`, '61%'),
    JsxHelper.createTableActionsHeader(ipActions, '10%'),
  ];

  const backToMainTable = () => {
    setResource(null);
  };

  const displayResourceData = (resource, format) => {
    // Set the loading state.
    setLoading(true);
    // Get the resource data.
    if (currentService === 'nginx') {
      displayNginxResourceData(resource, format);
    } else if (currentService === 'wordpress') {
      displayWordpressResourceData(resource, format);
    }
    // Set the current resource.
    setResource(resource);
    // Set the loading state.
    setLoading(false);
  };

  const normalizeUriKey = (uri) => {
    // If the URI has known query params, remove them with a place holder "[paramName]".
    const knownQueryParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'doing_wp_cron'];
    knownQueryParams.forEach((param) => {
      uri = uri.replace(new RegExp(`${param}=[^&]+&?`), `${param}=[${param}]&`);
    });
    // Remove the last "&" if exists.
    return StringHelper.trimRight(uri, '&');
  }

  const displayWordpressResourceData = (resource, format) => {
    const resourceData = wordpressRawData[resource] ?? [];
    // Set the table data.
    if (format === 'View IPs') {
      // Group requests by IPs.
      const _resourceData = {};
      resourceData.forEach((request) => {
        if (!request) {
          window.logHelper.warning('Invalid wordpress request', request);
          return;
        }
        const ip = request.client;
        if (!_resourceData[ip]) {
          _resourceData[ip] = [];
        }
        _resourceData[ip].push(request);
      });
      // Convert the object to an array.
      const _resourceDataArray = [];
      Object.keys(_resourceData).forEach((ip) => {
        const requests = _resourceData[ip];
        const row = {
          client: ip,
          total_requests: requests.length,
          country_code: requests[0].country_code || '-',
          trusted: requests[0].trusted,
          trusted_origin: requests[0].trusted_origin,
          localhost: requests[0].localhost,
        };
        // Get the URIs.
        const uris = {};
        requests.forEach((request) => {
          let uri = !request.wp_cli ? `${request.method} ${request.uri}` : request.argv;
          uri = normalizeUriKey(uri);
          uris[uri] = (uris[uri] || 0) + 1;
        });
        row.uris = uris;
        _resourceDataArray.push(row);
      });
      setRequestsTableHeaders(viewIPsWordpressHeaders);
      setRequestsTableData(_resourceDataArray);
    } else {
      setRequestsTableData(resourceData);
      setRequestsTableHeaders(viewRequestsWordpressHeaders);
    }
  };

  const displayNginxResourceData = (resource, format) => {
    const resourceData = nginxRawData[resource] ?? [];
    // Set the table data.
    if (format === 'View IPs') {
      // Group requests by IPs.
      const _resourceData = {};
      resourceData.forEach((request) => {
        if (!request) {
          window.logHelper.warning('Invalid nginx request', request);
          return;
        }
        const ip = request.clientOrigin === '-' ? request.client : request.clientOrigin;
        if (!_resourceData[ip]) {
          _resourceData[ip] = [];
        }
        _resourceData[ip].push(request);
      });
      // Convert the object to an array.
      const _resourceDataArray = [];
      Object.keys(_resourceData).forEach((ip) => {
        const requests = _resourceData[ip];
        const row = {
          client: ip,
          total_requests: requests.length,
          country_code: requests[0].country_code || '-',
          trusted: requests[0].trusted,
          trusted_origin: requests[0].trusted_origin,
          localhost: requests[0].localhost,
        };
        // Get the URIs.
        const uris = {};
        requests.forEach((request) => {
          const uri = normalizeUriKey(request.request);
          uris[uri] = (uris[uri] || 0) + 1;
        });
        row.uris = uris;
        _resourceDataArray.push(row);
      });
      setRequestsTableHeaders(viewIPsNginxHeaders);
      setRequestsTableData(_resourceDataArray);
    } else {
      setRequestsTableData(resourceData);
      setRequestsTableHeaders(viewRequestsNginxHeaders);
    }
  }

  // ---------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------
  
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: params.slug,
    },
  ];

  return (
    <Fragment>
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>Access Statistics</TitleBar.Title>
          <TitleBar.Actions>
            {!resource && <WPSSelect
              name='time_range'
              value={timeRangeOption}
              options={ArrayHelper.buildSelectOptions(DateHelper.timeRangeOptions, 'name', 'value')}
              isSearchable={true}
              onChange={handleTimeRangeSelect}
              sortOff={true}
              disabled={loading}
            />}
            {!resource && timeRangeOption === 'custom' && <WPSInput
              style={{ height: 'auto' }}
              type='datetime-local'
              name='from'
              placeholder='Start Date'
              onChange={handleCustomDateSelect}
              disabled={loading}
            />}
            {!resource && timeRangeOption === 'custom' && <WPSInput
              style={{ height: 'auto' }}
              type='datetime-local'
              name='to'
              onChange={handleCustomDateSelect}
              placeholder='End Date'
              disabled={loading}
            />}
            {resource && JsxHelper.createCloseButton({
              disabled: loading,
              onClick: backToMainTable,
            })}
            {!resource && JsxHelper.createRefreshButton({
              loading: loading,
              onClick: () => fetchData(currentService),
            })}
            {!resource && JsxHelper.createBackButton()}
          </TitleBar.Actions>
        </TitleBar>
        <Content>
          <GroupTitle>
            <h3 style={{ textTransform: 'none' }}>{currentService}{!resource?'':` (${resource})`}</h3>
            {!resource && <GroupTitle.Filters>
              {services.map((key) => (
                <button
                  key={key}
                  className={key === currentService ? 'active' : ''}
                  onClick={() => switchService(key)}>
                  {key}
                </button>
              ))}
            </GroupTitle.Filters>}
          </GroupTitle>
          {!resource && <AnalyticsCard style={{marginBottom:'25px', justifyContent: 'center'}}>
            {!loading && currentService === 'nginx' && Object.keys(nginxCards).map((index) => (
              <li key={index}>
                <h4>{index}</h4>
                <span>{nginxCards[index]}</span>
              </li>
            ))}
            {!loading && currentService === 'wordpress' && Object.keys(wordpressCards).map((index) => (
              <li key={index}>
                <h4>{index}</h4>
                <span>{wordpressCards[index]}</span>
              </li>
            ))}
          </AnalyticsCard>}
          {!resource && <WPSDataTable
            dataKey='id'
            customClass='logs-table'
            loading={loading}
            columns={currentService === 'nginx' ? nginxHeaders : wordpressHeaders}
            body={tableData}
            rowsPerPage={100}
          />}
          {resource && <WPSDataTable
            dataKey='id'
            customClass='logs-table'
            loading={loading}
            columns={requestsTableHeaders}
            body={requestsTableData}
            rowsPerPage={100}
          />}
        </Content>
    </Fragment>
  );
};

export default ServerAccessStats;
