import React, { useState } from 'react';
import { useEffect } from 'react';
import { isEmpty } from 'helpers';
import StringHelper from 'helpers/string';
import ChartHelper from 'helpers/chart';
import { ChartsContainer } from 'styles/website/profile';

const EC2Tab = ({ data, contentType }) => {
  const [requests, setRequests] = useState([]);
  const [bandwidth, setBandwidth] = useState([]);
  const REQUEST_KEYS = [
    { label: 'Total Robots', key: 'total_robots' },
    { label: 'Total Users', key: 'total_users' },
    { label: 'Unique Robots', key: 'unique_robots' },
    { label: 'Unique Users', key: 'unique_users' },
  ];
  const BANDWIDTH_KEYS = [
    { label: 'Robots', key: 'robots' },
    { label: 'Users', key: 'users' },
  ];
  // Get the data ready for charts.
  useEffect(() => {
    if (!isEmpty(data)) {
      // bandwidth
      const bandwidthData = data.bandwidth.map(i => ({
        date: i.date,
        robots: StringHelper.convertDown('B2MB', i.robots),
        users: StringHelper.convertDown('B2MB', i.users),
      }));
      setBandwidth(bandwidthData);
      // requests
      const requestData = data.requests.map(i => ({
        date: i.date,
        total_robots: i.total_robots,
        total_users: i.total_users,
        unique_robots: i.unique_robots,
        unique_users: i.unique_users,
      }));
      setRequests(requestData);
    }
  }, [data]);

  return (
    <ChartsContainer>
      {contentType === 'bandwidth' 
        ? ChartHelper.renderGraph('Bandwidth Usage (MB)', bandwidth, BANDWIDTH_KEYS, 'MB')
        : ChartHelper.renderGraph('Requests', requests, REQUEST_KEYS)
      }
    </ChartsContainer>
  );
};

export default EC2Tab;
