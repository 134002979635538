import styled from 'styled-components';

export const TableWrapper = styled.div`
  .data-table-main-class .rdt_Table {
    min-width: ${props => props.width};
  }
  .data-table-main-class .rdt_TableCol_Sortable {
    word-break: break-all;
  }
`;

export const WebsitesCardStyle = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
`;

export const WebsiteCard = styled.li`
  background: white;
  box-shadow: 0 2px 9px rgba(102, 142, 230, 0.15);
  min-width: 250px;
  border-radius: 4px;
  .status {
    padding: 8px 0;
  }
  .view-website {
    font-size: 12px;
    color: #2c84ed;
  }
  .wp-password {
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
    }
  }
  .inner-grid {
    display: flex;
    margin: 16px 0;
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      span:first-of-type {
        font-size: 12px;
      }
    }
  }
  .card-info,
  .card-stats {
    padding: 24px;
  }
  .card-stats {
    display: flex;
    justify-content: space-between;
    background: #fafbfd;
    min-height: 140px;
    .stat {
      flex: 0 0 1;
      text-align: center;
    }
    .circle {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #dce6f1;
      margin: 16px auto;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
  }
  h2 {
    display: flex;
    align-items: center;
    font-size: 19px;
    color: ${props => props.theme.color.primary};
  }
  .status-left {
    > div {
      display: flex;
    }
    > div:nth-child(2) {
      margin-top: 4px;
    }
  }
  .status-right {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }  
  ul {
    display: flex;
    li {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 18px;
      }
      span {
        display: flex;
        align-items: center;
      }
      > span:nth-child(2) {
        margin: 6px auto;
        color: ${props => props.theme.color.placeholder};
        svg {
          font-size: 16px;
        }
      }
    }
  }
`;
