import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'helpers';

export const Container = styled.section`
  width: 100%;
  overflow: inherit;
  overflow-x: scroll;
  position: relative;
  height: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 12px;
  }
  thead {
    td {
      border-bottom: 1px solid ${props => props.theme.border.primary};
      text-transform: capitalize;
      cursor:pointer;
      position:relative;
      padding: 12px 18px 12px 8px;
    }
  }
  tbody {
    tr:nth-child(odd) {
      background: ${props => props.theme.table.oddRows};
    }
    td.actions {
      width: 1px;
      white-space: nowrap;
      .action {
        text-transform: capitalize;
      }
    }
  }
  a {
    color: ${props => props.theme.color.link};
  }
  .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  .checkmark {
    border: 1px solid red;
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #ccc;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  /* When the checkbox is checked, add a background */
  .custom-checkbox input:checked ~ .checkmark {
    background-color: ${props => props.theme.background.success};
    border: none;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  .custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }  
  .arrows-up:hover:after {
    content: ''; 
    position: absolute;
    font: 17px "Consolas", monospace;
    right:50%;
    bottom:3px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #999;
    }
  .arrows-down:hover:after {
    content: ''; 
    position: absolute;
    font: 17px "Consolas", monospace;
    right:50%;
    bottom:3px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #999;
    }
`;

export const TableCellData = styled.td`
  color: ${({ color, theme }) => (color ? theme.color[color] : 'inherit')};
  width: ${({ columnWidth }) => (columnWidth ? columnWidth : 'inherit')};
  min-width: ${({ columnWidth }) => (columnWidth ? columnWidth : 'inherit')};
`;

const Client = styled.span`
  color: ${props => props.theme.background.success};
`;

const Time = styled.span`
  color: ${props => props.theme.background.warning};
`;

export const LogsList = ({ list }) => {
  return (
    <Table>
      {!isEmpty(list) ? (
        <tbody>
          {list.map((item, i) => (
            <tr key={i}>
              <td>
                <Time className='time'>
                  [{moment(item.datetime).format('YYYY/MM/DD hh:mm:ss A')}]{' '}
                </Time>
                <Client className='client'>[{item.client}] </Client>
                <span>{item.raw}</span>
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
          <tbody>
            <tr>
              <td>No logs available for today.</td>
            </tr>
          </tbody>
        )}
    </Table>
  );
};

export const ActiveFilters = styled.ul`
  display: flex;
  background: ${props => props.theme.table.oddRows};
  border-left: 4px solid ${props => props.theme.border.primary};
  padding: 8px 16px;
  align-items: center;
  li {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 6px;
    margin: 0 6px;
    border: 1px solid ${props => props.theme.border.primary};
    background: white;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 12px 0;
  select {
    all: unset;
    -webkit-appearance: menulist;
    font-weight: bold;
    border-bottom: 1px solid black;
    min-width: 42px;
    margin: 0 16px;
  }
  .from-text {
    margin: 0 16px;
  }
  button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
      font-size: 20px;
    }
    &:hover {
      background: ${({ theme }) => theme.table.oddRows};
    }
    &:disabled {
      svg {
        color: lightgray;
      }
    }
  }
`;

export const PaymentTable = styled.ul`
  margin: 20px 0 25px;
  li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    min-width: 350px;
    .title {
      display: flex;
      align-items: center;
    }
    .price {
      font-weight: bold;
      display: flex;
      flex-direction: column;
      text-align: end;
      .note {
        font-weight: normal;
        font-size: 12px;
        font-style: italic;
      }
    }

    &:nth-child(odd) {
      background: ${({ theme }) => theme.table.oddRows};
    }
  }
  li.plan-details {
    font-size: 12px;
    padding: 2px 0 2px 8px;
    background: transparent;
    span:first-child {
      color: ${({ theme }) => theme.color.link};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
