import React, { Fragment } from 'react';
import DNSZoneService from '../services/dnsZone';
import { isEmpty } from 'helpers';
import JsxHelper from './jsx';
import UrlHelper from './url';

const delayPropagationTooltip = ipv4 => {
  return `Enable this option to inform the platform that your domain record has not yet propagated
			so the system will automatically issue the SSL certificate once it is linked to ${ipv4}.
			<br/>This may cause your website to be inaccessible until the domain record is propagated.`;
};

const isDefaultHostingDomain = domain => {
  return domain.includes(process.env.REACT_APP_DEFAULT_HOSTING_DOMAIN);
};

const isRegistrableDomain = rootDomain => {
  return (
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(rootDomain) &&
    !rootDomain.startsWith('www.') &&
    !rootDomain.startsWith('http://') &&
    !rootDomain.startsWith('https://') &&
    !rootDomain.includes('/') &&
    !rootDomain.includes(':')
  );
};

const resolveSingleDNS = async (domain, expected, recordType) => {
  const results = await DNSZoneService.fetchDomain({
    domains: [ domain ],
    expected: expected || null,
    record_type: recordType || 'A'
  });
  return isEmpty(results) ? null : results[0];
}

const resolveDNS = async (domains, resolvedDomains, expected, recordType) => {
  let toResolve = [];
  const allResolvedDomains = resolvedDomains;
  for (const d of domains) {
    if (allResolvedDomains[d]) {
      continue; // Skip if already resolved
    }
    toResolve.push(d);
  }
  const resData = !isEmpty(toResolve) ? await DNSZoneService.fetchDomain({
    domains: toResolve,
    expected: expected || null,
    record_type: recordType || 'A'
  }) : [];
  return {
    res: resData,
    myResolvedDomains: allResolvedDomains,
  };
};

const scanRecords = async (domain, recordType) => {
  return await DNSZoneService.scanDomain({ domain: domain, record_type: recordType || null });
}

/* Table Headers */

const renderDnsManagerPrimaryButtonCell = (row, callback) => JsxHelper.createButton({
  label: row.is_default ? 'Primary' : 'Set As Primary',
  classes: row.is_default ? 'success--btn' : 'primary--btn',
  onClick: () => row.is_default ? null : callback(row),
  small: true,
});

const renderDnsManagerPointsTo2 = (status) => {
  if (!status) {
    return JsxHelper.createLoadingSpinner('', 20, '0 0 0 20px', '3px 0 0 0');
  }
  const isActive = status.is_active && status.is_dns_active;
  const color = isActive ? 'success' : 'warning';
  return <Fragment>
    <div>
      {(!isActive && status.auto_fixable)
        ? <div className='dns-auto-config'>
          {JsxHelper.createSuccessBox('Auto-configurable', { padding: '5px', fontSize: '12px', 'display': 'inline-block', fontWeight: 'bold' })}
          <span>{JsxHelper.createBasicTooltip('DNS record auto-configures in Cloudflare upon saving changes.')}</span>
        </div>
        : <div>
            <span>{status.points_to || '-'}</span>
            {JsxHelper.createBubble({
              icon: color,
              color,
              customClass: 'dns-points-to',
              tooltip: status.description,
            })}
            {status.warning && JsxHelper.createWarningBox(status.warning, { padding: '5px', fontSize: '12px', 'display': 'inline-block', fontWeight: 'bold' })}
          </div>
      }
      
    </div>
  </Fragment>
}

const renderDnsManagerPointsTo = (pointsTo, expected) => {
  let icon = 'success';
  if (pointsTo && !UrlHelper.areEquals(pointsTo, expected)) {
    icon = 'warning';
  }
  return <Fragment>
    {pointsTo
      ? (<span>{pointsTo}</span>)
      : (<div style={{ display: 'inline-block' }} className='loadingspinner'></div>)}
    {pointsTo && JsxHelper.createBubble({
      icon,
      customClass: 'dns-points-to',
      color: icon,
      tooltip: icon === 'warning'
        ? `Domain does not point to ${expected}.`
        : `It looks like the domain points to ${expected}.`
    })}
  </Fragment>
};

const domainHelper = {
  delayPropagationTooltip,
  isDefaultHostingDomain,
  resolveDNS,
  isRegistrableDomain,
  resolveSingleDNS,
  scanRecords,
  renderDnsManagerPointsTo,
  renderDnsManagerPointsTo2,
  renderDnsManagerPrimaryButtonCell
};

export default domainHelper;
