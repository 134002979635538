import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { WPSForm } from 'styles/layout/forms';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { isEmptyOrNull } from 'helpers';
import { updateWebsiteSMTPConfig, installSMTPPluginOnWebsite } from 'store/website/websiteActions';
import SendGridSMTPForm from 'components/sendgrid/smtpForm';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import SMTPHelper from 'helpers/smtp';
import WebsiteHelper from 'helpers/website';
import WebsiteService from 'services/website';
import { useHistory } from 'react-router-dom';
import env from 'config/env';
import '../website.css';

const Mailer = props => {
  useTitle('SMTP Settings');

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const website = props.website;
  const stepperView = props.stepperView || false;
  const onWebsiteUpdate = props.onWebsiteUpdate || null;
  const [loading, setLoading] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(1);
  const [userConfig, setUserConfig] = useState({ smtp: {}, mail: {}, plugin: {} });
  const [loadingSMTPDetails, setLoadingSMTPDetails] = useState(true);
  const [websiteSMTPDetails, setWebsiteSMTPDetails] = useState(null);
  const localSMTPConfig = WebsiteHelper.getSMTPMailerConfig(website);
  const isPluginActive = SMTPHelper.isPluginActive(website);
  const isProPluginActive = SMTPHelper.isProPluginActive(website);
  const [selectedSMTPMethod, setSelectedSMTPMethod] = useState('custom');
  const [savedSMTPMethod, setSavedSMTPMethod] = useState('custom');

  const BUTTON_STYLE = { margin: '24px auto auto 0px' };
  const INPUT_FIELDS = SMTPHelper.getInputFields(website);
  
  const hasGlobalSMTPConfig = (isEnabled) => {
    if (!websiteSMTPDetails) return false;
    return isEnabled ? websiteSMTPDetails.global_enabled : websiteSMTPDetails.global_available;
  }

  useEffect(() => {
    // Load the SMTP details.
    setLoadingSMTPDetails(true);
    WebsiteService.fetchSMTPConfig({ website_slug: website.slug })
    .then(res => {
      setWebsiteSMTPDetails(res);
      if (res.smtp_method === 'none' && stepperView) {
        // Automatically select the "none" option for the stepper view as a suggestion.
        setSelectedSMTPMethod(res.sendgrid_available ? 'sendgrid' : (res.global_available ? 'global' : 'custom'));
      } else {
        setSelectedSMTPMethod(res.smtp_method);
      }
      setSavedSMTPMethod(res.smtp_method);
      fillInputFields(localSMTPConfig);
    }).finally(() => setLoadingSMTPDetails(false));
    // eslint-disable-next-line
  }, []);

  const fillInputFields = (_initialConfig) => {
    const formData = SMTPHelper.buildUserConfig(INPUT_FIELDS, _initialConfig);
    setUserConfig(formData);
    setTriggerRerender(triggerRerender + 1)
  };

  const renderSaveButton = () => 
    JsxHelper.createButton({ label: 'Save', onClick: onSubmit, style: BUTTON_STYLE, loading: loading })

  const renderNextButton = () =>
    JsxHelper.createButton({ label: 'Next', onClick: onWebsiteUpdate, style: BUTTON_STYLE })

  // ------------------------------
  // HANDLERS
  // ------------------------------

  const goToGlobalSettings = () => history.push({ pathname: `/settings/smtp-mailer` });

  const onSelectSMTPMethod = (method) => {
    // The "use_settings" means that the user wants to use the local settings and not the global settings.
    const _config = localSMTPConfig || {};
    _config.plugin = _config.plugin || {};
    _config.plugin.use_settings = method !== 'global';
    fillInputFields(_config);
    setSelectedSMTPMethod(method);
  };

  const handleOnChange = (e, group) => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    let groupData = { ...userConfig[group], [name]: value };
    setUserConfig(prev => ({ ...prev, [group]: groupData }));
  };

  const onSubmit = () => {
    const data = { website_slug: website.slug };
    if (selectedSMTPMethod === 'global') {
      // Apply global settings for website when possible.
      data.config = { plugin: { use_settings: false, ignore_globals: false } };
    } else if (selectedSMTPMethod === 'none') {
      // Do not apply any SMTP settings for this website.
      data.config = { plugin: { use_settings: false, ignore_globals: true } };
    } else {
      // Save the SMTP settings for this website.
      data.config = { ...userConfig };
      data.config.plugin.ignore_globals = true;
    }
    window.logHelper.info(`Saving SMTP settings for website ${website.slug}`, data.config);
    setLoading(true);
    dispatch(updateWebsiteSMTPConfig(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({model: 'SMTP Mail options', onId: WebsiteHelper.getLabel(website), action: 'updated', plural: true}));
        onWebsiteUpdate && onWebsiteUpdate();
        setSavedSMTPMethod(selectedSMTPMethod);
      })
      .finally(() => setLoading(false));
  };

  const handlePluginActivation = () => {
    setLoading(true);
    const data = {
      website_slug: website.slug,
    };
    dispatch(installSMTPPluginOnWebsite(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: SMTPHelper.PLUGIN_NAME, onId: WebsiteHelper.getLabel(website), action: 'activated', plural: true, })))
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      {!stepperView && <TitleBar className='box-shadow-none margin-0'>
        <TitleBar.Title>SMTP Settings</TitleBar.Title>
      </TitleBar>}
      {!stepperView && isPluginActive && !loadingSMTPDetails && (
        <Fragment>
          <p className='color-primary subheader'>
            {env.getBrandShortName()} simplifies managing
            {' '}{JsxHelper.createSubheaderLink(SMTPHelper.PLUGIN_URL, SMTPHelper.PLUGIN_NAME)}{' '}
            plugin, allowing changes without WP Admin access. It sets up a mail server for all website outgoing emails.{' '}
          </p>
          <p className='subheader subheader-box'>
            {JsxHelper.createTipBox(savedSMTPMethod === 'none' 
              ? <Fragment>SMTP settings are currently disabled for this website. You can enable them by selecting a method below.</Fragment>
              : <Fragment>The plugin is currently using the <span className='font-bold'>{SMTPHelper.METHODS.find(m => m.value === savedSMTPMethod).name}</span> method{savedSMTPMethod==='global' ? ' found at Global Settings > SMTP' : ''}.</Fragment>
            , true)}
          </p>
          {UserHelper.isPartner() && !hasGlobalSMTPConfig() && savedSMTPMethod === 'custom' && <p className='subheader subheader-box'>
            {JsxHelper.createTipBox(<Fragment>
              Did you know that you can manage your SMTP settings <span onClick={goToGlobalSettings} className='subheader-link'>globally</span>?
            </Fragment>)}
          </p>}
        </Fragment>
      )}
      {isPluginActive ? <Content className='smtp-mailer'>
        {(loadingSMTPDetails || isEmptyOrNull(websiteSMTPDetails)) ? JsxHelper.createTableLoader() : <WPSForm id='smtp-mailer-form' key={triggerRerender} onSubmit={handleSubmit(onSubmit)} noValidate>
          {/* Display select method */}
          {SMTPHelper.renderMethodSelectOptions(selectedSMTPMethod, onSelectSMTPMethod, { global: hasGlobalSMTPConfig() ? null : SMTPHelper.GLOBAL_DISABLED_TOOLTIP })}
          {/* Display the custom method fields */}
          {selectedSMTPMethod === 'custom' && <Fragment>
            {SMTPHelper.renderPluginFields(INPUT_FIELDS, userConfig, handleOnChange, false, isProPluginActive)}
            {SMTPHelper.renderCustomFormFields(INPUT_FIELDS, userConfig, handleOnChange)}
            {renderSaveButton()}
          </Fragment>}
          {/* Display global settings */}
          {selectedSMTPMethod === 'global' && <Fragment>
            {SMTPHelper.renderGlobalSettingsBox(hasGlobalSMTPConfig(), hasGlobalSMTPConfig(true), savedSMTPMethod === 'global', goToGlobalSettings)}
            {savedSMTPMethod === 'global' ? (stepperView ? renderNextButton() : null) : renderSaveButton()}
          </Fragment>}
          {/* None option for the stepper view */}
          {selectedSMTPMethod === 'none' && <Fragment>
            {SMTPHelper.renderNoneBox(savedSMTPMethod === 'none')}
            {savedSMTPMethod === 'none' ? (stepperView ? renderNextButton() : null) : renderSaveButton()}
          </Fragment>}
          {/* Display SendGrid settings */}
          {selectedSMTPMethod === 'sendgrid' && <SendGridSMTPForm
            website={website}
            onSaveSMTPSettings={onWebsiteUpdate}
            onAccountsLoaded={(accounts) => !isEmptyOrNull(accounts) && setSelectedSMTPMethod('sendgrid')}
          />}
        </WPSForm>}
      </Content> : SMTPHelper.renderInstallPlugin(
        handlePluginActivation,
        loading,
        'subheader ' + (stepperView ? 'width-100 padding-left-0 margin-top-24' : ''),
        onWebsiteUpdate
      )}
    </Fragment>
  );
};

export default Mailer;
