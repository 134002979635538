import React, { Fragment, useState } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import BillingService from 'services/billing';
import DateHelper from 'helpers/date';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import { useHistory } from 'react-router-dom';
import env from '../../../config/env';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';

const BillingAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const adminCards = [
    {
      title: 'Details',
      name: 'details',
      desc:
        `Save the details of ${env.getBrandShortName()} that will appear on the generated partner invoices.`,
      icon: 'infoDark',
      btnText: 'Update',
    },
    {
      title: 'Invoices',
      name: 'invoices',
      desc: 'See invoices generated periodically from partner subscriptions.',
      icon: 'customers',
      btnText: 'View',
    },
    {
      title: 'Plans',
      name: 'plans',
      desc: 'Manage partner plans offered by Staq for recurring usage of its services.',
      icon: 'plans',
      btnText: 'Manage',
    },
    {
      title: 'Subscriptions',
      name: 'subscriptions',
      desc: 'Manage partner subscriptions to Staq services.',
      icon: 'customers',
      btnText: 'Manage',
    },
    {
      title: 'Payment Intents',
      name: 'payment-intents',
      desc: 'See the process of collecting partner payments.',
      icon: 'paymentintent',
      btnText: 'View',
    },
    {
      title: 'Cost & Usage Report',
      name: 'cost-and-usage',
      desc: 'Generate a CSV file for tracking cost & usage per website.',
      icon: 'fileCsv',
      loading: loading,
      btnText: 'Generate',
      onClick: () => {
        const data = {
          file_name: `cost-and-usage-${DateHelper.getFilenameSuffix()}.csv`
        };
        setLoading(true);
        BillingService.downloadCostUsageReport(data)
          .catch(err => dispatch(setGlobalErrorMsg(err)))
          .finally(() => setLoading(false));
      }
    },
  ];
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
    },
  ];

  const handleOnClick = e => {
    if (e) {
      history.push({
        pathname: `/billing/${e}`,
      });
    }
  };
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Billing</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {adminCards.map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              loading={el.loading}
              btnText={el.btnText}
              onClick={el.onClick || (e => handleOnClick(e))}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default BillingAdmin;
