import { postRequest, getRequest, downloadRequest } from '../config/axiosRequest';
import env from '../config/env';

// Create file
const createFile = (website, data) => {
  const path = `/files`
  return postRequest({
    url: env.buildFileManagerApiEndpoint(website, path),
    data: data,
  });
};

// Read file content
const getFileContent = (website, data) => {
  const path = `/files/${data.file_id}/read`;
  return getRequest({
    url: env.buildFileManagerApiEndpoint(website, path)
  });
};

// Read changes history
const getChangesHistory = (website) => {
  const path = `/files/commits-history`;
  return getRequest({
    url: env.buildFileManagerApiEndpoint(website, path)
  });
};

// Write file content
const putFileContent = (website, data) => {
  const path = `/files/${data.file_id}/write`
  return postRequest({
    url: env.buildFileManagerApiEndpoint(website, path),
    data: data,
  });
};

// Download file
const downloadFile = (website, data) => {
  const path = `/files/${data.file_id}/download`
  return downloadRequest({
    url: env.buildFileManagerApiEndpoint(website, path),
    data: data
  });
};

const FileManagerService = {
	createFile,
	putFileContent,
	getFileContent,
  downloadFile,
  getChangesHistory
}

export default FileManagerService;
