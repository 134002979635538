import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { resourceUsageTrendsWidgetSelector } from 'store/widget/widgetSelectors';
import JsxHelper from 'helpers/jsx';
import WebsiteHelper from 'helpers/website';
import PlanHelper from 'helpers/plan';
import { websitesSelector } from 'store/website/websiteSelectors';
import { isEmptyOrNull } from 'helpers';

const ResourceUsageMetrics = () => {
  const widget = useSelector(resourceUsageTrendsWidgetSelector);
  const websites = useSelector(websitesSelector);
  const widgetData = widget ? widget.data : [];

  const buildMessage = (item) => 
    <span><b>{item.human_name}</b> currently at {item.human_stats}</span>

  const getTrendingFeatures = () => {
    // If the widget data is empty, return an empty array.
    if (isEmptyOrNull(widgetData)) {
      return [];
    }
    // Get the top GROUP_LIMIT trending features based on the percent value.
    const GROUP_LIMIT = 10;
    const topFeatures = [];
    Object.keys(widgetData).forEach((feature) => {
      const items = widgetData[feature];
      items.forEach((item) => {
        item.feature = feature;
        item.percent = parseFloat(item.percent);
        if (topFeatures.length < GROUP_LIMIT) {
          topFeatures.push({ ...item });
        } else {
          const minItem = topFeatures.reduce((min, item) => item.percent < min.percent ? item : min);
          if (item.percent > minItem.percent) {
            const index = topFeatures.indexOf(minItem);
            topFeatures[index] = { ...item };
          }
        }
      });
    });
    // Retrieve LIMIT random features from the list of trending features.
    const SELECT_LIMIT = 5 > topFeatures.length ? topFeatures.length : 5;
    let selectedFeatures = {};
    while (Object.keys(selectedFeatures).length < SELECT_LIMIT) {
      const index = Math.floor(Math.random() * topFeatures.length);
      if (!selectedFeatures[index]) {
        selectedFeatures[index] = topFeatures[index];
      }
    }
    // Convert the selected features to an array.
    selectedFeatures = Object.values(selectedFeatures);
    // Sort the selected features by percent value.
    selectedFeatures.sort((a, b) => b.percent - a.percent);
    return selectedFeatures;
  }

  const trendingFeatures = getTrendingFeatures();

  return(
    <Fragment>
    {!isEmptyOrNull(trendingFeatures) ? <table className='notifications-table'>
        <colgroup>
          <col/>
          <col/>
        </colgroup>
        <tbody>
          {trendingFeatures.map((item, index) => {
            if (!item) {
              window.logHelper.error('Item is null', item);
            }
            const website = WebsiteHelper.findWebsite(websites, item.website_slug);
            return <tr key={index} className='notification-row'>
              <td className='notification-content'>
                <div className='notification-label'>{website
                  ? JsxHelper.createWebsiteLink(website)
                  : JsxHelper.createRelativeLink('billing/subscriptions', item.subscription_name)
                }</div>
                <div className='notification-text'>{buildMessage(item)}</div>
              </td>
              <td className='notification-icon'>
                {JsxHelper.createIcon(PlanHelper.featureUsageToIconDetails(item))}
              </td>
            </tr>
          })}
        </tbody>
      </table> : <div className='no-data'>No data available</div>}
    </Fragment>
  );
}

export default ResourceUsageMetrics;