import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import CreatePlan from 'components/plan/save';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const PlanCreate = () => {
  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Billing',
      link: '/billing',
    },
    {
      text: 'Plans',
      link: '/billing/plans',
    },
    {
      text: 'Create',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Create New Plan</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/billing/plans')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <CreatePlan />
      </Content>
    </Fragment>
  );
};

export default PlanCreate;
