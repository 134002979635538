import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSLabel, WPSCheckbox, ErrorMsg } from 'styles/layout/forms';
import { WPSButton } from 'styles/layout/buttons';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import StringHelper from 'helpers/string';
import WebsiteService from 'services/website';
import Modal from 'components/layout/modal';
import JsonInspector from 'react-json-inspector';
import { Row } from 'styles/layout/grid';
import { Container } from 'styles/website/profile';
import ArrayHelper from 'helpers/array';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import globalHelper from 'helpers/globalHelper';

const isExpanded = () => true;

const ExecuteCommand = ({ slug }) => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ command: '', is_sync: true, output_format: 'auto' });
  const [output, setOuput] = useState({ isOpen: false, data: null, title: 'Output' });

  const onSubmit = async () => {
    if (!StringHelper.startsWith(details.command, 'wp ')) {
      dispatch(setGlobalErrorMsg(`Invalid WP-CLI command.`));
      return;
    }
    setLoading(true);
    const data = {
      website_slug: slug,
      command: details.command,
      is_async: !details.is_sync,
    };
    if (details.output_format === 'json') {
      data.command += ` --format=json`;
    }
    try {
      const res = await WebsiteService.WPCLICommand(data);
      setOuput({
        ...output,
        data: res.output,
        isOpen: true,
      });
      dispatch(setGlobalSuccessMsg({ model: 'command', action: 'executed' }));
    } catch (err) {
      dispatch(setGlobalErrorMsg(err));
    }
    setLoading(false);
  };

  const onChange = e => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleOutputClose = () => {
    setOuput({ ...output, isOpen: false });
  };
  const handleOutputOpen = () => {
    setOuput({ ...output, isOpen: true });
  };

  const openDocs = () => {
    return window.open('https://developer.wordpress.org/cli/commands/', '_blank');
  };

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title> WP CLI</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        WP-CLI is the command-line interface for many actions you might perform in the WordPress
        admin, without using a browser. You can visit the official{' '}
        <span
          onClick={() => openDocs()}
          style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}>
          WP-CLI documentation
        </span>{' '}
        for more information about the available commands and functions.
      </p>
      <WPSForm className='margin-24'>
        <WPSLabel required>
          Command
          {errors.command && <ErrorMsg>{errors.command.message}</ErrorMsg>}
        </WPSLabel>
        <textarea
          name='command'
          className='wps-textarea'
          placeholder="wp search-replace 'foo' 'bar'"
          value={details.old}
          onChange={onChange}
          ref={register({ required: FormHelper.messages.required })}
        />
        <WPSForm.RowItem margin='0px'>
          <WPSLabel>Output format</WPSLabel>
          <WPSSelect
            name='output_format'
            value={details.output_format}
            options={ArrayHelper.buildSelectOptions(globalHelper.outputFormat, 'name', 'value')}
            onChange={onChange}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem direction='row' minHeight='50' margin='0px'>
          <WPSCheckbox
            type='checkbox'
            name='is_sync'
            onChange={onChange}
            checked={details.is_sync}
          />
          <span className='margin-left-6'>Sync</span>
        </WPSForm.RowItem>
      </WPSForm>
      <Row spacing={8} className='margin-24'>
        {output.data && (
          <WPSButton
            className='primary--btn'
            style={{ marginLeft: '0px' }}
            onClick={handleOutputOpen}
            disabled={loading}>
            Output
          </WPSButton>
        )}
        <WPSButton
          className='warning--btn'
          style={output.data ? null : { marginLeft: '0px' }}
          onClick={handleSubmit(onSubmit)}
          loading={loading}>
          Run Command
        </WPSButton>
      </Row>
      {output.isOpen && (
        <Modal
          onClose={handleOutputClose}
          maxWidth='700'
          width='50%'
          height='80%'
          maxHeight='40vh'
          closeBtn='Close'>
          <JsonInspector className='json-viewer' data={output.data} isExpanded={isExpanded} />
        </Modal>
      )}
    </Container>
  );
};

export default ExecuteCommand;
