import React, { Fragment } from 'react';
import PackageGitEvents from '../../components/package/git-events';
import { TitleBar } from '../../styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { WPSButton } from 'styles/layout/buttons';
import { useHistory, useLocation } from 'react-router-dom';

const PluginGitEvents = () => {
  useTitle('Plugin Events History');

  const history = useHistory();
  const { state } = useLocation();

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Plugins',
      link: '/plugins',
    },
    {
      text: 'Events History',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Plugin Events History</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='back--btn' onClick={() => history.goBack()}>
            Back
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <PackageGitEvents currentPackage={state} />
      </Content>
    </Fragment>
  );
};

export default PluginGitEvents;
