import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { customerDuePaymentWidgetSelector } from 'store/widget/widgetSelectors';
import JsxHelper from 'helpers/jsx';
import { isEmptyOrNull } from 'helpers';

const CustomerDuePaymentMetrics = () => {
  const widget = useSelector(customerDuePaymentWidgetSelector);
  const widgetData = widget ? widget.data : [];

  const getRandomCustomers = () => {
    if (isEmptyOrNull(widgetData)) return [];
    const MAX_ITEMS = widgetData.length > 5 ? 5 : widgetData.length;
    let customers = {};
    while (Object.keys(customers).length < MAX_ITEMS) {
        const randomIndex = Math.floor(Math.random() * widgetData.length);
        if (!customers[randomIndex]) {
          customers[randomIndex] = widgetData[randomIndex];
        }
    }
    customers = Object.values(customers);
    return customers.sort((a, b) => b.amount - a.amount);
}

  const buildMessage = (item) =>
    <span>owes <b>{item.amount} {item.currency}</b> for {item.invoices} unpaid invoices.</span>

  return(
    <Fragment>
      <table className='notifications-table'>
        <colgroup>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <tbody>
          {getRandomCustomers().map((item, index) => {
            return (
              <tr key={index} className='notification-row'>
                <td className='notification-content'>
                  <div className='notification-label'>{JsxHelper.createRelativeLink(`clients/invoices`, item.name)}</div>
                  <div className='notification-text'>{buildMessage(item)}</div>
                </td>
                <td className='notification-icon'>
                  {JsxHelper.createIcon({
                    icon: item.invoices > 3 ? 'danger' : 'warning',
                    color: item.invoices > 3 ? 'danger' : 'warning',
                    tooltip: `${item.invoices} unpaid invoices`
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan='3' className='widget-notice'>
              * Widgets may not be up-to-date. For real-time data, {JsxHelper.createRelativeLink('clients/invoices', 'click here', 'goto-link')}.
            </td>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
}

export default CustomerDuePaymentMetrics;