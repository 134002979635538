import React from 'react';
import { RaisedCard, DashboardCardsContainer, CardContent } from 'styles/layout/cards';
import SitesMetrics from './sitesMetrics';
import AccountHealthMetrics from './accountHealthMetrics';
import PluginUpdatesMetrics from './pluginUpdatesMetrics';
import ResourceUsageMetrics from './resourceUsageMetrics';
import ChangelogNotifications from './changelogNotifications';
import NewsNotifications from './newsNotifications';
import CustomerDuePaymentMetrics from './customerDuePaymentMetrics';
import PartnerRevenueMetrics from './partnerRevenueMetrics';
import { useSelector } from 'react-redux';
import { integrationSelector } from 'store/me/meSelectors';
import UserHelper from 'helpers/user';
import ConnectStripe from './connectStripe';

const DashboardCards = () => {
  const integrations = useSelector(integrationSelector);
  const isStripeConnected = integrations.stripe ? true : false;

  return (
    <DashboardCardsContainer className='dashboard-cards'>

      <RaisedCard colSpan={4}>
        <CardContent>
          <h4 className='card-title'>Website Deployments</h4>
          <SitesMetrics/>
        </CardContent>
      </RaisedCard>

      <RaisedCard colSpan={6}>
        <CardContent>
          <h4 className='card-title'>Websites Health</h4>
          <AccountHealthMetrics/>
        </CardContent>
      </RaisedCard>

      {!UserHelper.isAdminOrAgent() && <RaisedCard colSpan={4}>
        <CardContent>
          <h4 className='card-title'>Resource Usage Trends</h4>
          <ResourceUsageMetrics/>
        </CardContent>
      </RaisedCard>}

      {!UserHelper.isAdminOrAgent() && <RaisedCard colSpan={6}>
        <CardContent>
          <h4 className='card-title'>Plugin / Theme Updates</h4>
          <PluginUpdatesMetrics/>
        </CardContent>
      </RaisedCard>}

      {UserHelper.isPartner() && <RaisedCard colSpan={4}>
        <CardContent>
          <h4 className='card-title'>Outstanding Invoices</h4>
          {isStripeConnected && <CustomerDuePaymentMetrics/>}
          {!isStripeConnected && <ConnectStripe/>}
        </CardContent>
      </RaisedCard>}

      {UserHelper.isPartner() && <RaisedCard colSpan={6}>
        <CardContent>
          <h4 className='card-title'>Hosting Revenue</h4>
          {isStripeConnected && <PartnerRevenueMetrics/>}
          {!isStripeConnected && <ConnectStripe/>}
        </CardContent>
      </RaisedCard>}

      {UserHelper.isAdminOrAgent() && <RaisedCard colSpan={4}>
        <CardContent>
          <h4 className='card-title'>Change Log & Release Notes</h4>
          <ChangelogNotifications/>
        </CardContent>
      </RaisedCard>}
  
      {UserHelper.isAdminOrAgent() && <RaisedCard colSpan={6}>
        <CardContent>
          <h4 className='card-title'>News & Announcements</h4>
          <NewsNotifications/>
        </CardContent>
      </RaisedCard>}

    </DashboardCardsContainer>
  );
};

export default DashboardCards;
