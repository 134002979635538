import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DialogHelper from 'helpers/dialog';
import ArrayHelper from 'helpers/array';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import NotificationService from 'services/notification';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import { timezoneSelector } from 'store/me/meSelectors';
import useConfirm from 'hooks/useConfirm';
import useModal from 'hooks/useModal';

const Notifications = () => {
  useTitle('Notifications');
  const dispatch = useDispatch();
  const [ notifications, setNotifications ] = useState([]);
  const [ tableLoading, setTableLoading ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ details, setDetails ] = useState({});
  const [ modal, setModal ] = useState(false);
  const [ contentChanged, setContentChanged ] = useState(false);
  const [ contentNotChangedError, setContentNotChangedError ] = useState(false);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onModalSubmit' });
  const timezone = useSelector(timezoneSelector);
  const confirm = useConfirm();
  const modalDialog = useModal();

  // -----------------------
  // CONSTANTS
  // -----------------------

  const CATEGORY_OPTIONS = [
    { value: 'changelog', name: 'Change Log' },
    { value: 'news', name: 'News' },
    { value: 'login-popup', name: 'Login Popup' },
  ];

  const DEFAULT_DATA = {
    guid: null,
    category: '',
    title: '',
    message: '',
    featured: false,
  };

  // -----------------------
  // UI - LOADER
  // -----------------------

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line
  }, []);

  // -----------------------
  // API
  // -----------------------

  const fetchNotifications = () => {
    setTableLoading(true);
    NotificationService.list().then(items => {
      setNotifications(items);
    }).finally(() => setTableLoading(false));
  }

  const saveNotification = () => {
    if (!contentChanged) {
      setContentNotChangedError('Please change the content before saving.');
      return;
    }
    setSaveLoading(true);
    NotificationService.save(details).then((item) => {
      dispatch(setGlobalSuccessMsg({ model: 'notification', action: 'saved' }));
      setModal(false);
      fetchNotifications();
    }).catch(err => {
      dispatch(setGlobalErrorMsg(err));
    }).finally(() => setSaveLoading(false));
  }

  // -----------------------
  // UI - BREADCRUMBS
  // -----------------------

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Notifications',
    },
  ];

  // -----------------------
  // UI - CALLBACKS
  // -----------------------

  const onModalInputChange = e => {
    const { name, value } = e.target;
    setContentChanged(true);
    setContentNotChangedError(false);
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  const openModal = () => {
    setModal(true);
    setContentChanged(false);
  };

  const onClickCreateUser = () => {
    openModal();
    setDetails(DEFAULT_DATA);
  }

  const onClickUpdate = item => {
    openModal();
    setDetails({
      guid: item.guid,
      category: item.category,
      title: item.title,
      message: item.message,
      featured: item.featured,
    });
  };

  const onClickDelete = item => {
    DialogHelper.confirmDelete(confirm, null, 'notification')
      .then(() => {
        NotificationService.archive({ guid: item.guid }).then(() => {
          setNotifications(prev => prev.filter(notification => notification.guid !== item.guid));
          dispatch(setGlobalSuccessMsg({ model: 'notification', action: 'archived' }));
        }).catch(err => dispatch(setGlobalErrorMsg(err)));
      });
  };

  const onClickPreview = item => {
    DialogHelper.info(modalDialog, item.message, '', {
      hideIcon: true,
      hideCloseBtn: item.message.includes('hide-close-btn'),
    });
  }

  // -----------------------
  // UI - TABLE
  // -----------------------

  const actions = [
    {
      value: 'Update',
      onClick: item => onClickUpdate(item),
    },
    {
      value: 'Preview',
      doHide: item => ![ 'login-popup' ].includes(item.category),
      onClick: item => onClickPreview(item),
    },
    {
      value: 'Delete',
      onClick: item => onClickDelete(item),
    },
  ];

  const headers = [
    JsxHelper.createTableTimeHeader('created_at', timezone, '15%'),
    JsxHelper.createTableTextHeaderWithCallback('category', 'Category', '15%', (item) => CATEGORY_OPTIONS.find(option => option.value === item.category)?.name || item.category),
    JsxHelper.createTableTitleHeader('title', 'Content', '50%', 'message'),
    JsxHelper.createTableActionsHeader(actions, '20%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          <div style={{ display: 'flex' }}>
            Notifications
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({
            label: 'Create Notification',
            onClick: onClickCreateUser,
            disabled: saveLoading,
          })}
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content className='main-notifications-table'>
        <WPSDataTable columns={headers} body={notifications} loading={tableLoading} customClass='baseline-cells' />
      </Content>
      {modal && DialogHelper.inputs({
        title: details.guid ? 'Update Notification' : 'Create Notification',
        onClose: () => setModal(false),
        register,
        header: contentNotChangedError ? <div className='error-box width-fit-content'>{contentNotChangedError}</div> : null,
        loading: saveLoading,
        confirmBtn: details.guid ? 'Update' : 'Create',
        onConfirm: handleSubmit(saveNotification),
        inputs: [{
          label: 'Category',
          required: true,
          type: 'select',
          name: 'category',
          value: details.category,
          options: ArrayHelper.buildSelectOptions(CATEGORY_OPTIONS),
          onChange: onModalInputChange,
          errors,
        }, {
          label: 'Title',
          required: true,
          type: 'text',
          name: 'title',
          value: details.title,
          onChange: onModalInputChange,
          errors,
        }, {
          required: true,
          label: 'Message',
          type: 'textarea',
          name: 'message',
          value: details.message,
          rows: 15,
          onChange: onModalInputChange,
          errors,
        },
      ].filter(Boolean)})}
    </Fragment>
  );
};

export default Notifications;
