import PackageService from '../../services/package';
import { dispatchPostPromise, dispatchDeletePromise } from '../../helpers';

/*
  Action Types
*/
export const GLOBAL_PACKAGE_SET_ALL = 'GLOBAL_PACKAGE_SET_ALL';
export const GLOBAL_PACKAGE_CREATE = 'GLOBAL_PACKAGE_CREATE';
export const GLOBAL_PACKAGE_UPDATE = 'GLOBAL_PACKAGE_UPDATE';
export const GLOBAL_PACKAGE_DELETE = 'GLOBAL_PACKAGE_DELETE';

/*
  Action Creators
*/

export const createGlobalPackage = data => {
  return dispatchPostPromise(PackageService.create(data), GLOBAL_PACKAGE_CREATE);
};

export const updateGlobalPackage = data => {
  return dispatchPostPromise(PackageService.update(data), GLOBAL_PACKAGE_UPDATE);
};

export const updateGlobalPackageFromUrl = data => {
  return dispatchPostPromise(PackageService.updateFromUrl(data), GLOBAL_PACKAGE_UPDATE);
};

export const deleteGlobalPackage = data => {
  return dispatchDeletePromise(PackageService.delete(data), GLOBAL_PACKAGE_DELETE, data);
};

export const setGlobalPackageAutoUpdate = data => {
  return dispatchPostPromise(PackageService.setAutoUpdate(data), GLOBAL_PACKAGE_UPDATE);
};
