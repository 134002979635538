import React, { useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';

const RoutesRenderer = ({ routes, components }) => {
  const getRouteComponent = useCallback(
    route => {
      let component = route.component;
      if (route.componentName) {
        component = components[route.componentName];
      }
      return component;
    },
    [components]
  );

  return (
    <Switch>
      {routes.map((route, i) => {
        const { exact = false, path } = route;
        const component = getRouteComponent(route);
        if (!component) return null;
        return <Route key={`admin-routes-${i}`} path={path} exact={exact} component={component} />;
      })}
    </Switch>
  );
};

export default RoutesRenderer;
