import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalMessage } from 'store/global/globalSelectors';
import { useEffect } from 'react';
import { MESSAGE_REMOVE } from 'store/global/globalActions';
import { Container } from 'styles/layout/toaster';

const Toaster = () => {
  const dispatch = useDispatch();

  const message = useSelector(globalMessage);

  const removeMsg = () => {
    dispatch({ type: MESSAGE_REMOVE });
  };

  useEffect(() => {
    if (message.text) {
      setTimeout(() => {
        dispatch({ type: MESSAGE_REMOVE });
      }, 4000);
    }
  }, [message.text, dispatch]);

  return (
    message.text && (
      <Container onClick={removeMsg} color={message.color}>
        <p dangerouslySetInnerHTML={{ __html: message.text }} />
      </Container>
    )
  );
};

export default Toaster;
