import React, { useState } from 'react';
import { WPSForm, WPSInput, ErrorMsg, WPSCheckbox, WPSLabel, WPSCheckboxLabel } from '../../styles/layout/forms';
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector';
import { WPSButton } from '../../styles/layout/buttons';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createNewCustomer, updateCustomer } from '../../store/customer/customerActions';
import { setGlobalSuccessMsg } from '../../store/global/globalActions';
import { useHistory } from 'react-router-dom';
import Tooltip from 'components/layout/tooltip';
import { integrationSelector } from 'store/me/meSelectors';
import { useSelector } from 'react-redux';

const CreateCustomer = ({ customer }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const integrations = useSelector(integrationSelector);
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onSubmit' });
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    customer_slug: customer ? customer.slug : '',
    display_name: customer ? customer.display_name : '',
    email: customer ? customer.email : '',
    first_name: customer ? customer.first_name : '',
    last_name: customer ? customer.last_name : '',
    business_name: customer ? customer.metadata.business_details.business_name : '',
    business_address_1: customer ? customer.metadata.business_details.business_address_1 : '',
    business_address_2: customer ? customer.metadata.business_details.business_address_2 : '',
    business_city: customer ? customer.metadata.business_details.business_city : '',
    business_state: customer ? customer.metadata.business_details.business_state : '',
    business_postcode: customer ? customer.metadata.business_details.business_postcode : '',
    business_country: customer ? customer.metadata.business_details.business_country : '',
    business_phone: customer ? customer.metadata.business_details.business_phone : '',
    sync_with_stripe: customer ? customer.sync_with_stripe : !!integrations.stripe,
    request_profile_setup: !customer,
  });

  const onChange = e => {
    const { id, value, checked } = e.target;
    if (id === 'sync_with_stripe') {
      setDetails(prev => ({ ...prev, sync_with_stripe: checked }));
    } else if (id === 'request_profile_setup') {
      setDetails(prev => ({ ...prev, request_profile_setup: checked }));
    } else {
      setDetails(prev => ({ ...prev, [id]: value }));
    }
  };

  const onSubmit = () => {
    // Create new customer
    setLoading(true);
    if (!customer) {
      dispatch(createNewCustomer(details))
        .then(() => {
          dispatch(setGlobalSuccessMsg({ model: 'client', id: details.email }));
          history.push('/clients/accounts');
        })
        .catch(() => setLoading(false));
      // Update specified client
    } else {
      dispatch(updateCustomer(details))
        .then(() => {
          dispatch(setGlobalSuccessMsg({ model: 'client', action: 'updated', id: details.email }));
          history.push('/clients/accounts');
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <WPSForm onSubmit={handleSubmit(onSubmit)}>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='display_name' required>
            Display name
            {errors.display_name && <ErrorMsg>{errors.display_name.message}</ErrorMsg>}
          </WPSLabel>
          <WPSInput
            type='text'
            id='display_name'
            name='display_name'
            value={details.display_name}
            onChange={onChange}
            ref={register({
              required: 'This field is required',
              minLength: {
                value: 5,
                message: 'Display name must be at least 5 characters long',
              },
            })}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='email' required>
            Email
            {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
          </WPSLabel>
          <WPSInput
            type='text'
            id='email'
            name='email'
            value={details.email}
            onChange={onChange}
            ref={register({ required: 'This field is required' })}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='first_name'>First name</WPSLabel>
          <WPSInput type='text' id='first_name' value={details.first_name} onChange={onChange} />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='last_name'>Last name</WPSLabel>
          <WPSInput type='text' id='last_name' value={details.last_name} onChange={onChange} />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_name'>Business name</WPSLabel>
          <WPSInput
            type='text'
            id='business_name'
            value={details.business_name}
            onChange={onChange}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_phone'>Business phone</WPSLabel>
          <WPSInput
            type='text'
            id='business_phone'
            value={details.business_phone}
            onChange={onChange}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_address_1'>Business address 1</WPSLabel>
          <WPSInput
            type='text'
            id='business_address_1'
            value={details.business_address_1}
            onChange={onChange}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_address_2'>Business address 2</WPSLabel>
          <WPSInput
            type='text'
            id='business_address_2'
            value={details.business_address_2}
            onChange={onChange}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_city'>Business city</WPSLabel>
          <WPSInput
            type='text'
            id='business_city'
            value={details.business_city}
            onChange={onChange}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_postcode'>Business postcode</WPSLabel>
          <WPSInput
            type='text'
            id='business_postcode'
            value={details.business_postcode}
            onChange={onChange}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_country'>Business country</WPSLabel>
          <CountryDropdown
            id='business_country'
            valueType='short'
            value={details.business_country}
            classes='custom-select'
            onChange={val => setDetails(prev => ({ ...prev, business_country: val }))}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='business_state'>Business state</WPSLabel>
          <RegionDropdown
            id='business_state'
            classes='custom-select'
            countryValueType='short'
            blankOptionLabel='Select State'
            defaultOptionLabel='Select State'
            value={details.business_state}
            country={details.business_country}
            onChange={val => setDetails(prev => ({ ...prev, business_state: val }))}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        {false && integrations.stripe && (
          <WPSForm.RowItem direction='row'>
            <WPSCheckboxLabel disabled={loading}>
              <WPSCheckbox type='checkbox' id='sync_with_stripe' checked={details.sync_with_stripe} onChange={onChange}/>
              &nbsp; Keep client synced with Stripe.
              <Tooltip
                place='right'
                text={`
                  By checking this box you agree that your client profile data will always be in sync with the client<br/>
                  profile under your connected Stripe account.`
                }
              />
            </WPSCheckboxLabel>
          </WPSForm.RowItem>
        )}
        {!customer && (
          <WPSForm.RowItem direction='row'>
            <WPSCheckboxLabel disabled={loading}>
              <WPSCheckbox type='checkbox' id='request_profile_setup' checked={details.request_profile_setup} onChange={onChange}/>
              &nbsp; Send profile setup request to client.
              <Tooltip
                place='right'
                text={`
                  By checking this box your client will receive an email advising them to review their profile details.<br/>
                  In addition, the client may add a credit card on file which will automatically be charged for services.<br/>
                  You can add your own Terms & Conditions page through Settings > Terms & Conditions > Billing.<br/>
                  If you decide to leave this box unchecked then no email will be sent.<br/>
                  The client details may be edited at anytime through Clients > Client List > Update.`
                }
              />
            </WPSCheckboxLabel>
          </WPSForm.RowItem>
        )}
      </WPSForm.Row>
      <WPSButton className='save--btn max-width-150' type='submit' loading={loading}>
        Save
      </WPSButton>
    </WPSForm>
  );
};

export default CreateCustomer;
