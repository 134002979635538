import { createSelector } from 'reselect';

/*
  Input
*/
const partnerPackages = state => state.partner_packages;

/*
  Selectors
*/
export const partnerPackagesSelector = createSelector(partnerPackages, packages => packages);

/*
  Theme Selectors
*/
export const partnerThemesSelector = createSelector(partnerPackages, packages =>
  packages.filter(item => item.type === 'theme'),
);

/*
  Plugin Selectors
*/
export const partnerPluginsSelector = createSelector(partnerPackages, packages =>
  packages.filter(item => item.type === 'plugin'),
);

export const partnerPluginsListSelector = createSelector(partnerPluginsSelector, plugins =>
  plugins
    .filter(item => !item.must_install)
    .map(item => {
      return {
        label: `${item.display_name} (v${item.version})`,
        value: item.slug,
        key: item.folder_name,
        partner_slug: item.partner_slug,
      };
    }),
);
