import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import EmailService from 'services/emailtemplate';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import useQuery from 'hooks/useQuery';

const PreviewEmail = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [html, setHtml] = useState(null);

  useEffect(() => {
    const data = {
      header_grd1: `#${query.get('header_grd1')}`,
      header_grd2: `#${query.get('header_grd2')}`,
      button_grd1: `#${query.get('button_grd1')}`,
      button_grd2: `#${query.get('button_grd2')}`,
    };

    EmailService.previewEmail(data)
      .then(res => setHtml({ __html: res.html }))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
    // eslint-disable-next-line
  }, []);

  return <div dangerouslySetInnerHTML={html} />;
};

export default PreviewEmail;
