import React from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import SslStep from 'components/website/goLive/sslStep';
import { WPSForm } from 'styles/layout/forms';
import env from 'config/env';
import JsxHelper from 'helpers/jsx';

const WebsiteSSL = ({ website }) => {
  useTitle('Website SSL');

  return (
    <Container id='go-live-wizard'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>
          SSL Certificates
        </TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        {env.getBrandShortName()} helps you protect your site by automating the process of installing a free {JsxHelper.createSubheaderLink('letsencrypt.org', "Let's Encrypt")} SSL for your custom domains.
        SSL certificates allow your visitors to connect to your website via a secure HTTPS protocol that provides an encrypted link between the server and the client.
      </p>
      <Content>
        <WPSForm className='medium-fieldset go-live-form' onSubmit={e => e.preventDefault()}>
          <SslStep
            website={website}
            nextBtnHandler={null}
          />
        </WPSForm>
      </Content>
    </Container>
  );
};

export default WebsiteSSL;
