import {
  PARTNER_PACKAGE_SET_ALL,
  PARTNER_PACKAGE_CREATE,
  PARTNER_PACKAGE_UPDATE,
  PARTNER_PACKAGE_DELETE
} from './partnerPackageActions';
import ArrayHelper from '../../helpers/array';

const partnerPackageReducer = (state = [], action) => {
  switch (action.type) {
    case PARTNER_PACKAGE_SET_ALL:
      return [...action.payload];

    case PARTNER_PACKAGE_CREATE:
      return [...state, action.payload];

    case PARTNER_PACKAGE_UPDATE:
      return ArrayHelper.update(state, 'slug', action.payload);

    case PARTNER_PACKAGE_DELETE:
      return ArrayHelper.filterOut(state, 'slug', action.payload.package_slug);

    default:
      return state;
  }
};

export default partnerPackageReducer;
