import {
  SERVER_SET_ALL,
  SERVER_CREATE,
  SERVER_UPDATE,
  SERVER_RECENT_UPDATE,
  SERVER_DELETE,
  SERVER_DELETE_RECENT_UPDATES,
  SERVER_INSTANCES_SET_ALL,
  SERVER_INSTANCE_CREATE,
  SERVER_INSTANCE_UPDATE,
  SERVER_INSTANCE_DELETE,
} from './serverActions';
import ArrayHelper from 'helpers/array';

const serverReducer = (state = [], action) => {
  switch (action.type) {
    case SERVER_SET_ALL:
      return [...action.payload];

    case SERVER_CREATE:
      return [...state, action.payload];

    case SERVER_UPDATE:
      return ArrayHelper.update(state, 'slug', action.payload);

    case SERVER_RECENT_UPDATE:
      let _servers = [...state];
      const _serversData = [...action.payload]
      for (const i in _serversData) {
        _servers = ArrayHelper.update(_servers, 'slug', _serversData[i]);
      }
      return _servers

    case SERVER_DELETE:
      return ArrayHelper.filterOut(state, 'slug', action.payload.slug);
      
    case SERVER_DELETE_RECENT_UPDATES:
      let allServers = [...state];
      const _allServersData = [...action.payload]
      for (const i in _allServersData) {
        allServers = ArrayHelper.filterOut(allServers, 'slug', _allServersData[i]);
      }
      return allServers

    case SERVER_INSTANCES_SET_ALL:
      return state.map(s =>
        s.slug === action.payload.server_slug ? {
          ...s,
          instances: action.payload.instances
        } :
        s,
      );

    case SERVER_INSTANCE_CREATE:
      const {
        server_slug
      } = action.payload;
      return state.map(s =>
        s.slug === server_slug ? {
          ...s,
          instances: [...s.instances, action.payload]
        } : s,
      );

    case SERVER_INSTANCE_UPDATE:
      return state.map(s =>
        s.slug === action.payload.server_slug ? {
          ...s,
          instances: ArrayHelper.update(s.instances, 'instance_id', action.payload)
        } :
        s,
      );

    case SERVER_INSTANCE_DELETE:
      return state.map(s =>
        s.slug === action.payload.server_slug ? {
          ...s,
          instances: ArrayHelper.filterOut(
            s.instances,
            'instance_id',
            action.payload.instance_id,
          ),
        } :
        s,
      );

    default:
      return state;
  }
};

export default serverReducer;