import { postRequest, getRequest } from '../config/axiosRequest';
import env from '../config/env';

const list = data => {
  return getRequest({
  url: env.buildApiEndpoint('notifications'),
  data,
  });
};

const save = data => {
  return postRequest({
    url: env.buildApiEndpoint('notifications'),
    data,
  });
};

const archive = data => {
  return postRequest({
    url: env.buildApiEndpoint(`notifications/archive/${data.guid}`),
    data,
  });
};

const NotificationService = {
  list,
  save,
  archive,
};

export default NotificationService;
