import React, { useState, useEffect, useCallback } from 'react';
import { WPSForm, WPSLabel } from 'styles/layout/forms';
import Modal from 'components/layout/persistentmodal';
import TinyMCE from 'components/Editor/TinyMCE';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import LegalService from 'services/legal';
import ArrayHelper from 'helpers/array';

const EditLegalTemplate = ({ show, value, onClose, setLegal }) => {
  const dispatch = useDispatch();
  const [body, setBody] = useState('');
  const [submitting, setSubmit] = useState(false);

  // Dismiss modal and reset state on modal close.
  const handleModalClose = () => {
    if (onClose) onClose();
    setBody(null);
  };

  const handleContentChange = v => {
    setBody(v);
  };

  const updateTemplate = () => {
    setSubmit(true);
    const data = {
      name: value.name,
      body: body || value.body,
    };
    LegalService.saveTermsAndConditions(data)
      .then(res => {
        handleModalClose();
        dispatch(setGlobalSuccessMsg({ model: 'Terms & Conditions', action: 'updated' }));
        setLegal(prev => ArrayHelper.update(prev, 'name', res));
      })
      .finally(() => setSubmit(false));
  };

  useEffect(() => {
    if (show && !body) {
      setBody(value.body);
    }
    // eslint-disable-next-line
  }, [show, value]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  return (
    <Modal
      title={`Edit ${value ? value.title : ''} terms and conditions`}
      onClose={handleModalClose}
      onConfirm={updateTemplate}
      maxWidth='80vw'
      width='100%'
      loading={submitting}
      persistent
      open={show}>
      <WPSForm onSubmit={handleSubmit}>
        <WPSLabel required>Body</WPSLabel>
        <TinyMCE value={body} onChange={handleContentChange} minHeight={300} key='body' />
      </WPSForm>
    </Modal>
  );
};

export default EditLegalTemplate;
