import React, { useState } from 'react';
import Icon from 'components/layout/icon';
import HtmlParser from 'html-react-parser';
import Editor from '@monaco-editor/react';
import './DynamicModal.css';
import { Fragment } from 'react';
import StringHelper from 'helpers/string';
const DynamicModal = ({
  icon,
  headerTitle,
  line1,
  line2,
  iconSpinner,
  btnText,
  textAlign,
  btnColor,
  btnIcon,
  iconColor,
  hideCloseBtn,
  closeBtn,
  closeBtnColor,
  btnsStyle,
  btnsAlign,
  btnsClass,
  btnOnClick,
  btnOnClose,
  customLineOneTextClass,
  hideIcon,
  editorMode,
  editorConfig,
  modalCloseIcon,
  preventLine1Parsing,
  preventLine2Parsing,
  hideHeader,
  rawHtml,
  customClass,
}) => {
  // eslint-disable-next-line
  const [editorOptions, setEditorOptions] = useState({
    readOnly: true,
  });

  textAlign = `text-align-${textAlign || 'center'}`

  return (
    <div className={'dynamic-modal-container' + (customClass ? ` ${customClass}` : '')}>
      <div
        className={
          editorMode ? 'dynamic-modal-body editor-body editor-dialog-padding' : 'dynamic-modal-body'
        }>
        {modalCloseIcon !== false && (
          <span onClick={btnOnClose} className='modal-close-icon'>
            &#x2716;
          </span>
        )}
        {!editorMode && (
          <Fragment>
            {!hideHeader && <div
              className={
                'dynamic-modal-header' +
                (iconSpinner ? ' dynamic-modal-spin' : '') +
                (iconColor ? ` ${iconColor}` : '')
              }>
              {headerTitle && <div className='primary-font-color dynamic-modal-header-title'>{headerTitle}</div>}
              {icon && !hideIcon && <Icon tag={icon} />}
            </div>}
            <div className='dynamic-modal-content-info width-100'>
              {rawHtml && <div className='dynamic-modal-raw-html'>{rawHtml}</div>}
              {line1 && (
                <p
                  className={
                    customLineOneTextClass
                      ? `${customLineOneTextClass} ${textAlign}`
                      : textAlign
                  }>
                  {preventLine1Parsing === true ? line1 : HtmlParser(line1)}
                </p>
              )}
              {line2 && (
                <p className={textAlign}>
                  {preventLine2Parsing === true ? line2 : HtmlParser(line2)}
                </p>
              )}
            </div>
            <div
              className='display-flex-center width-100'
              style={btnsAlign ? { justifyContent: btnsAlign } : null}>
              {!hideCloseBtn && <div className={closeBtn ? 'display-flex-center' : ''}>
                {closeBtn && (
                  <button
                    onClick={() => btnOnClose()}
                    style={{ marginRight: '15px' }}
                    className={
                      'modal-close-btn ' +
                      (btnsStyle === 'standard'
                        ? 'cancel--btn modal-standard-btns-padding'
                        : closeBtnColor
                        ? `${closeBtnColor}--light dynamic-modal-delete-btn`
                        : 'primary--light dynamic-modal-delete-btn')
                    }>
                    {StringHelper.is(closeBtn) ? closeBtn : 'Close'}
                  </button>
                )}
                <button
                  onClick={() => btnOnClick(() => btnOnClose())}
                  className={
                    'modal-custom-btn ' +
                    (btnsStyle === 'standard'
                      ? `${btnsClass} modal-standard-btns-padding`
                      : btnColor
                      ? `${btnColor}--light dynamic-modal-delete-btn`
                      : 'primary--light dynamic-modal-delete-btn')
                  }>
                  {btnIcon && <Icon tag={btnIcon} />}
                  <span>{btnText ? btnText : 'Close'}</span>
                </button>
              </div>}
            </div>
          </Fragment>
        )}
        {editorMode && (
          <Fragment>
            <Editor
              value={editorConfig.content}
              wrapperClassName='dynamic-modal-editor'
              language={editorConfig.language}
              options={editorOptions}
            />
            <button
              onClick={() => btnOnClose()}
              style={{ marginTop: '25px' }}
              className='cancel--btn modal-standard-btns-padding'>
              Close
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DynamicModal;
