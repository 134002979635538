import React, { useEffect, useRef } from 'react';
import { isEmptyOrNull } from 'helpers';
import { BtnGroup } from 'styles/layout/buttons';
import { useMemo } from 'react';
import WPSDropdown from './WPSDropdown';
import TableHelper from 'helpers/table';

// Table actions component
const TableActions = ({ actions, item, customStyle }) => {
  const ref = useRef();

  const closeList = () => {
    let list = document.getElementsByClassName('KIWI-option-list');
    if (!isEmptyOrNull(list) && !isEmptyOrNull(list[0])) {
      list[0].parentNode.children[0].click();
    }
  };
  const onChange = options => {
    try {
      options.data.onClick(item);
      closeList();
    } catch (error) {
      console.log('WPSTableActions.onChange error:', error);
    }
  };

  useEffect(() => {
    if (ref.current && ref.current.children[0] && ref.current.children[0].children[0]) {
      ref.current.children[0].children[0].type = "button";
    }
    const listener = event => {
      try {
        // if window start resizing, close the list if it opened
        // cuz it's positioned full absolute based on list button which location will change on resize
        if (event.type === 'resize') {
          closeList();
          return;
        }
      } catch (error) {
        console.log('WPSTableActions.listener error:', error);
      }
    };

    document.addEventListener('click', listener);
    window.addEventListener('resize', listener);

    return () => {
      document.removeEventListener('click', listener);
      window.removeEventListener('resize', listener);
    };
  }, [ref]);

  // filter visible actions and make options list
  const optionList = useMemo(() => actions.filter(a => {
    if (a.doHide) {
      return !a.doHide(item);
    }
    return isEmptyOrNull(a.hide) || a.hide === false;
  }).map(TableHelper.actionToDropdownItem), [actions, item])

  // show all options inside dropdown menu
  return (
    <div className='wps-table-actions' style={customStyle ? customStyle : null}>
      <BtnGroup ref={ref}>
        {!isEmptyOrNull(optionList) && <WPSDropdown options={optionList} onChange={onChange} />}
      </BtnGroup>
    </div>
  );
};

export default TableActions;