import React, { useEffect } from 'react';
import ErrorsReportsService from '../../../services/errorsReports';
import UserHelper from '../../../helpers/user';
import env from 'config/env';

import './ErrorBoundaryHandler.css';
const ErrorBoundaryHandler = props => {
  useEffect(() => {
    const errorData = [
      `URL : ${window.location.href}`,
      `User Role : ${UserHelper.getRole()}`,
      `Error : ${props.error.message}`,
      `Stack Trace : ${props.error.stack}`,
    ];
    if (env.offlineMode || env.ignoreErrors) {
      window.logHelper.error('ErrorBoundaryHandler', errorData);
      return;
    }
    ErrorsReportsService.saveError({ content: errorData });
    // eslint-disable-next-line
  }, []);
  const handleOnClick = option => {
    if (option === 'home') {
      window.history.pushState({ urlPath: '/' }, '', '/');
      window.location.reload();
    } else {
      window.location.reload();
    }
  };
  return (
    <div style={{ maxWidth: '500px', textAlign: 'center', margin: '100px auto' }}>
      <h1>Opps, Something went wrong!</h1>
      <div className='links' style={{ marginTop: '45px' }} onClick={() => handleOnClick('home')}>
        Go back to Home
      </div>
      <div className='links' onClick={() => handleOnClick('reload')}>
        Reload the page again
      </div>
    </div>
  );
};

export default ErrorBoundaryHandler;
