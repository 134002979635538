import React from 'react';
import StringHelper from './string';
import JsxHelper from './jsx';
import { Link } from 'react-router-dom';

const DEFAULT_ROWS_PER_PAGE = 50;
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 75, 100];

const customizeCellValue = (row, selector, newValue) => {
  if (row.ignoreSelector && !row.ignoreSelector.includes(selector)) {
    row.ignoreSelector.push(selector);
  } else if (!row.ignoreSelector) {
    row.ignoreSelector = [];
    row.ignoreSelector.push(selector);
  }
  row[`custom_${selector}`] = newValue;
  return row;
}

const sortByNumeric = (rowA, rowB, key) => StringHelper.toNumeric(rowA[key]) - StringHelper.toNumeric(rowB[key])

const getBubbleColor = status => {
  switch (status) {
    case 'expired':
      return 'danger-font-color';
    case 'valid':
      return 'success-font-color';
    case 'expiring':
      return 'warning-font-color';
    default:
      return 'inactive-font-color';
  }
};

const createActionItem = (date) => {
  const { doHide, onClick, value } = date;
  const _value = value.toLowerCase();
  const textColor = 
    _value.includes('delete') ? 'delete-color'
    : (['remove', 'cancel', 'disconnect'].some(v => _value.includes(v) && !_value.startsWith('un')) ? 'warning-font-color'
    : '');
  return {value, textColor, doHide, onClick};
};

const updateTableViewPreferences = (viewPreferences, tableName, rowsPerPage) => {
  const tablePreferences = viewPreferences ? (viewPreferences.tables || []) : [];
  const tableIndex = tablePreferences.findIndex(table => table.name === tableName);
  if (tableIndex !== -1) {
    tablePreferences[tableIndex].rows_per_page = rowsPerPage;
  } else {
    tablePreferences.push({ name: tableName, rows_per_page: rowsPerPage });
  }
  return { ...viewPreferences, tables: tablePreferences };
}

const getTableViewsPerPage = (viewPreferences, tableName) => {
  const table = (viewPreferences ? (viewPreferences.tables || []) : []).find(t => t.name === tableName);
  return table && table.rows_per_page ? table.rows_per_page : DEFAULT_ROWS_PER_PAGE;
}

const actionToDropdownItem = (action, index) => ({
  data: action,
  value: action.value + index,
  content: action.link
    ? <Link to={action.link}> {action.value}</Link>
    : <span className={action.textColor ? action.textColor : ''}>{action.value}</span>
});

const createActionIcon = (icon, onClick) => JsxHelper.createIcon({
  icon,
  customClass: 'wps-table-actions',
  style: { padding: '4px 12px'},
  onClick,
});

const TableHelper = {
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  customizeCellValue,
  sortByNumeric,
  getBubbleColor,
  createActionItem,
  updateTableViewPreferences,
  getTableViewsPerPage,
  actionToDropdownItem,
  createActionIcon,
};

export default TableHelper;