import React, { useState } from 'react';
import { WPSForm, WPSInput } from '../../styles/layout/forms';
import { WPSButton } from '../../styles/layout/buttons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createNewServer } from '../../store/server/serverActions';
import { globalRegionsSelector } from '../../store/region/regionSelectors';
import UserHelper from '../../helpers/user';
import StringHelper from '../../helpers/string';
import { isEmpty } from '../../helpers';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg } from '../../store/global/globalActions';
import WPSSelect from '../../components/wpstaq/WPSSelect/WPSSelect';
import ArrayHelper from '../../helpers/array';

const CreateServer = () => {
  const dispatch = useDispatch();
  const regions = useSelector(globalRegionsSelector);
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [server, setServer] = useState({
    region_slug: !isEmpty(regions) ? regions[0].slug : '',
    server_slug: UserHelper.isAdmin() ? StringHelper.randomSlug(6) : '',
    instance_type: 't3.small',
    env_type: 'production',
    volume_size: 64,
  });
  const serversTypes = [{ name: 't3.small' }, { name: 't3.medium' }, { name: 't3.large' }];
  const envTypes = [{ label: 'Production', value: 'production' }, { label: 'Staging', value: 'staging' }];
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    const { name, value } = e.target;
    setServer(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(setGlobalPleaseWaitMsg({ model: 'server', action: 'created' }));
    const data = { ...server, is_staging: server.env_type === 'staging' };
    dispatch(createNewServer(data))
      .then(() => {
        setLoading(false);
        dispatch(setGlobalSuccessMsg({ model: 'server', id: server.server_slug, action: 'created' }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <WPSForm onSubmit={handleSubmit(onSubmit)} style={{maxWidth: '25%'}}>
      <label htmlFor='region_slug'>Region</label>
      <WPSSelect
        name='region_slug'
        value={server.region_slug}
        options={ArrayHelper.buildSelectOptions(regions, 'display_name', 'slug')}
        onChange={onChange}
        isSearchable={true}
      />

      <label htmlFor='server_slug'>Name</label>
      <WPSInput name='server_slug' value={server.server_slug} onChange={onChange} />

      <label htmlFor='instance_type'>EC2 Instance Type</label>
      <WPSSelect
        name='instance_type'
        value={server.instance_type}
        options={ArrayHelper.buildSelectOptions(serversTypes, 'name', 'name')}
        onChange={onChange}
      />

      <label htmlFor='env_type'>Environment Type</label>
      <WPSSelect
        name='env_type'
        value={server.env_type}
        options={ArrayHelper.buildSelectOptions(envTypes, 'label', 'value')}
        onChange={onChange}
      />

      <label htmlFor='volume_size'>Volume Size (GB)</label>
      <WPSInput name='volume_size' value={server.volume_size} onChange={onChange} />

      <WPSButton type='submit' className='create--btn max-width-150' loading={loading}>
        Create
      </WPSButton>
    </WPSForm>
  );
};

export default CreateServer;
