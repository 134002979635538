import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { updatePhpConfig } from 'store/server/serverActions';
import { WPSForm, WPSLabel } from 'styles/layout/forms';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import Tooltip from 'components/layout/tooltip';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { useRouteMatch } from 'react-router-dom';
import { serverBySlug } from 'store/server/serverSelectors';
import JsxHelper from 'helpers/jsx';

const ServerPhpConfig = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { params } = useRouteMatch();
  const server = useSelector(serverBySlug(params.slug));
  const serverPhpConfig = server.php_config || {};

  const defaultConfig = {
    opcache_cli_enabled: 'default',
    opcache_memory_consumption: 'default',
    opcache_interned_strings_buffer: 'default',
    opcache_max_accelerated_files: 'default',
    opcache_revalidate_freq: 'default',
    opcache_jit: 'default',
    opcache_jit_buffer_size: 'default',
  }
  const initDetauls = () => {
    let _details = {}
    for (const k in defaultConfig) {
      _details[k] = serverPhpConfig[k] || defaultConfig[k];
    }
    return _details;
  }
  const [details, setDetails] = useState(initDetauls);

  let phpConfigData = [
    {
      name: 'opcache_cli_enabled',
      title: 'OPcache CLI Enabled',
      desc: 'Enables the opcode cache for the CLI version of PHP.',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'No', value: 'no' },
        { label: 'Yes', value: 'yes' },
      ],
    },
    {
      name: 'opcache_memory_consumption',
      title: 'OPcache Memory Consumption',
      desc: 'The size of the shared memory storage used by OPcache.',
      options: [
        { label: 'Default', value: 'default' },
        { label: '128 MB', value: '128' },
        { label: '256 MB', value: '256' },
        { label: '512 MB', value: '512' },
        { label: '1 GB', value: '1024' },
        { label: '2 GB', value: '2048' },
        { label: '4 GB', value: '4096' },
      ],
    },
    {
      name: 'opcache_interned_strings_buffer',
      title: 'OPcache Interned Strings Buffer',
      desc: 'The amount of memory used to store interned strings.',
      options: [
        { label: 'Default', value: 'default' },
        { label: '8 MB', value: '8' },
        { label: '16 MB', value: '16' },
        { label: '32 MB', value: '32' },
        { label: '64 MB', value: '64' },
        { label: '128 MB', value: '128' },
        { label: '256 MB', value: '256' },
      ],
    },
    {
      name: 'opcache_max_accelerated_files',
      title: 'OPcache Max Accelerated Files',
      desc: 'The maximum number of keys in the OPcache hash table.',
      options: [
        { label: 'Default', value: 'default' },
        { label: '7963', value: '7963' },
        { label: '16229', value: '16229' },
        { label: '65407', value: '65407' },
        { label: '130987', value: '130987' },
        { label: '262237', value: '262237' },
        { label: '524521', value: '524521' },
        { label: '1048793', value: '1048793' },
      ],
    },
    {
      name: 'opcache_revalidate_freq',
      title: 'OPcache Revalidate Freq',
      desc: 'How often OPcache checks for file modifications.',
      options: [
        { label: 'Default', value: 'default' },
        { label: '0', value: '0' },
        { label: '2', value: '2' },
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '30', value: '30' },
        { label: '60', value: '60' },
        { label: '120', value: '120' },
      ],
    },
    {
      name: 'opcache_jit',
      title: 'OPcache JIT',
      desc: 'Determines the JIT compilation mode.',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'Disabled', value: 'disable' },
        { label: 'Tracing', value: 'tracing' },
        { label: 'Function', value: 'function' },
      ],
    },
    {
      name: 'opcache_jit_buffer_size',
      title: 'OPcache JIT Buffer Size',
      desc: 'The amount of shared memory to reserve for compiled JIT code. A zero value disables the JIT.',
      options: [
        { label: 'Default', value: 'default' },
        { label: '0', value: '0' },
        { label: '16 MB', value: '16M' },
        { label: '32 MB', value: '32M' },
        { label: '64 MB', value: '64M' },
        { label: '128 MB', value: '128M' },
        { label: '256 MB', value: '256M' },
        { label: '512 MB', value: '512M' },
        { label: '1 GB', value: '1024M' },
        { label: '2 GB', value: '2048M' },
      ],
    },
  ];

  // Set the values in the select list
  for (const i in phpConfigData) {
    const configName = phpConfigData[i].name;
    // Add name field to options
    phpConfigData[i].options = phpConfigData[i].options.map(o => ({...o, name: configName}));
    phpConfigData[i].default = defaultConfig[configName];
    // Set selected value
    const userValue = details[configName];
    const selectedOption = phpConfigData[i].options.find(o => o.value === userValue)
    phpConfigData[i].value = {
      value: selectedOption ? selectedOption.value : phpConfigData[i].options[0].value,
      label: selectedOption ? selectedOption.label : phpConfigData[i].options[0].label
    }
  }

  const validateDataBeforeSubmit = (data) => {
    return true;
  }

  const onSubmit = () => {
    const data = {
      server_slug: server.slug,
      ...details,
    };
    if (!validateDataBeforeSubmit(data)) {
      return;
    }
    setLoading(true);
    dispatch(updatePhpConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Config', onId: server.slug, action: 'updated' })))
      .finally(() => setLoading(false));
  };

  const onChange = e => {
    const { name, value } = e.target;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: params.slug,
    },
  ];

  return (
    <Fragment>
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>PHP Config</TitleBar.Title>
          <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
        </TitleBar>
        <Content>
          <div style={{ maxWidth: '500px', marginTop: '20px', marginBottom: '20px' }}>
              {
              phpConfigData.filter(item => !item.hide).map((item, index) => {
                  return (
                  <WPSForm.RowItem key={index} margin='0px'>
                      <div className='display-inline-block'>
                      <WPSLabel className='display-inline-block'>{item.title}</WPSLabel>
                      <Tooltip place='right' text={item.desc} />
                      </div>
                      <WPSSelect
                      selectClass='secondary-box-select'
                      value={item.value ? item.value.value : null}
                      options={item.options}
                      onChange={onChange}
                      sortOff={true}
                      />
                      {item.btnOnClick && !item.btnHide() && (
                      <WPSButton onClick={item.btnOnClick} className='text--btn'>{item.btnText}</WPSButton>
                      )}
                  </WPSForm.RowItem>
                  );
              })
              }
          </div>
          <WPSButton className='save--btn' loading={loading} onClick={onSubmit} style={{marginBottom: '25px'}}>
              Save
          </WPSButton>
        </Content>
    </Fragment>
  );
};

export default ServerPhpConfig;
