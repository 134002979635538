import React, { Fragment } from 'react';
import {
  WPSForm,
  WPSLabel,
  WPSInput,
  WPSCheckbox,
  WPSCheckboxLabel,
  ErrorMsg,
} from '../../styles/layout/forms';
import FormHelper from '../../helpers/form';
import Tooltip from '../../components/layout/tooltip';
import WPSSelect from '../../components/wpstaq/WPSSelect/WPSSelect';
import ArrayHelper from '../../helpers/array';

const UserFormFields = ({ errors, onChange, register, details, user, userRoles, showVerifyEmail }) => {
  return (
    <Fragment>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel htmlFor='email' required>
            Email
            {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
          </WPSLabel>
          <WPSInput
            type='email'
            name='email'
            value={details.email}
            onChange={onChange}
            disabled={user}
            ref={register({ required: FormHelper.messages.required })}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel required>
            Display name
            {errors.display_name && <ErrorMsg>{errors.display_name.message}</ErrorMsg>}
          </WPSLabel>
          <WPSInput
            type='text'
            name='display_name'
            value={details.display_name}
            onChange={onChange}
            ref={register({
              required: FormHelper.messages.required,
              minLength: {
                value: 5,
                message: FormHelper.messages.minLength(5),
              },
            })}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel>Role</WPSLabel>
          <WPSSelect
            name={userRoles.input_name}
            value={details[userRoles.input_name]}
            disabled={!userRoles.can_edit}
            options={ArrayHelper.buildSelectOptions(userRoles.options, 'text', 'value')}
            onChange={onChange}
            isSearchable={true}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel>First name</WPSLabel>
          <WPSInput
            type='text'
            id='first_name'
            name='first_name'
            value={details.first_name || ''}
            onChange={onChange}
          />
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel>Last name</WPSLabel>
          <WPSInput
            type='text'
            id='last_name'
            name='last_name'
            value={details.last_name || ''}
            onChange={onChange}
          />
        </WPSForm.RowItem>
      </WPSForm.Row>
      {showVerifyEmail && !user && (
        <WPSForm.Row style={{ display: 'inline-flex', marginTop: '20px' }}>
          <WPSForm.RowItem style={{ minHeight: '15px' }}>
            <WPSCheckboxLabel>
              <WPSCheckbox
                type='checkbox'
                name='verify_email'
                id='verify_email'
                onChange={onChange}
                checked={details.verify_email}
              />
              &nbsp; Send email verification request
              <Tooltip
                place='right'
                text={`If unchecked, the user's email ownership validation will be skipped.<br/>This option is only available for administrators.`}
              />
            </WPSCheckboxLabel>
          </WPSForm.RowItem>
        </WPSForm.Row>
      )}
    </Fragment>
  );
};

export default UserFormFields;
