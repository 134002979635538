import { getRequest, postRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Fetch all dns zones.
 *
 */
const fetchAll = () => {
  return getRequest({
    url: env.buildApiEndpoint('dns-zones'),
  });
};

/**
 * Create a new dns zone.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('dns-zones'),
    data,
  });
};

/**
 * Lookup a DNS record.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const fetchDomain = domain => {
  return postRequest({
    url: env.buildApiEndpoint('dns-zones/lookup-domains'),
    data: domain,
  });
};

/**
 * Scan domain for records.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const scanDomain = domain => {
  return postRequest({
    url: env.buildApiEndpoint('dns-zones/scan-domain'),
    data: domain,
  });
};

/**
 * Delete the specified dns zone.
 *
 * @param   {dnsZoneSlug}  dnsZoneSlug
 */
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`dns-zones/${data.dns_zone_slug}`),
    data,
  });
};

/**
 * Create or update dns record that has the specified parameters.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const createRecord = data => {
  return postRequest({
    url: env.buildApiEndpoint(`dns-zones/${data.dns_zone_slug}/record`),
    data,
  });
};

/**
 * Deletes the dns record that has the specified parameters.
 *
 * @param   {Object}  data
 * @return  {null}
 */
const deleteRecord = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`dns-zones/${data.dns_zone_slug}/record`),
    data,
  });
};

/**
 * Tests NS records.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const testNameservers = data => {
  return postRequest({
    url: env.buildApiEndpoint(`dns-zones/${data.dns_zone_slug}/test-nameservers`),
    data: data,
  });
};

const DNSZoneService = { create, delete: del, createRecord, deleteRecord, fetchDomain, fetchAll, scanDomain, testNameservers };

export default DNSZoneService;
