import { createSelector } from 'reselect';
import ArrayHelper from 'helpers/array';
import { isServerBusy } from 'helpers';

/*
  Input
*/
const servers = state => state.servers;

/*
  Selectors
*/
export const globalServersSelector = createSelector(servers, servers => servers);

// Return shared servers
export const sharedServersSelector = createSelector(servers, servers =>
  servers.filter(server => server.hosting_type === 'shared'),
);

// Return server by slug
export const serverBySlug = serverSlug =>
  createSelector(servers, servers => servers.find(s => s.slug === serverSlug));

// Return a server's instances
export const serverInstancesBySlug = serverSlug =>
  createSelector(servers, servers => ArrayHelper.find(servers, 'slug', serverSlug).instances || []);

// Return busy servers
export const busyServers = createSelector(servers, servers =>
  servers.some(server => isServerBusy(server)),
);

// Return servers select list
export const serversListSelector = createSelector(servers, servers =>
  servers.map(s => ({ label: s.slug, value: s.slug })),
);
