import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useSelector, useDispatch } from 'react-redux';
import { plansSelector, planCategoriesSelector } from 'store/billing/billingSelectors';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { deletePlan } from 'store/billing/billingActions';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import useConfirm from 'hooks/useConfirm';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const Plan = () => {
  useTitle('Billing Plans');
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const confirm = useConfirm();
  const plans = useSelector(plansSelector);
  const plansCategories = useSelector(planCategoriesSelector);

  const actions = [
    {
      value: 'Update',
      onClick: item => {
        history.push({
          pathname: `/billing/plans/${item.slug}/update`,
          state: item,
        });
      },
    },
    {
      value: 'Delete',
      onClick: plan => DialogHelper
        .confirmDelete(confirm, plan.name, 'plan')
        .then(() => dispatch(deletePlan(plan))
            .then(() => dispatch(setGlobalSuccessMsg({ model: 'plan', action: 'deleted' })))
            .catch(err => dispatch(setGlobalErrorMsg(err))))
    },
  ];

  const headers = [
    {
      name: 'Plan',
      selector: 'name',
      searchable: true,
      width: '26%',
      cell: row => {
        const category = plansCategories.filter(c => c.slug === row.category_slug)[0];
        return JsxHelper.createTableMultiLineCell({
          header: row.name,
          subheader: category.name,
        })
      },
    },
    JsxHelper.createTableLongTextHeader('description', 'Description', '34%'),
    JsxHelper.createTableTextHeader('price', 'Price', '10%'),
    JsxHelper.createTableActionsHeader(actions, '30%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
      link: '/billing',
    },
    {
      text: 'Price List',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Price List</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({ label: 'Create Plan', linkTo: `${path}/create` })}
          {JsxHelper.createBackButton('/billing')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          columns={headers}
          body={plans}
          noSearchOnTable={false}
          rowsPerPage={100}
        />
      </Content>
    </Fragment>
  );
};

export default Plan;
