import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContainer, ImageWrapper, FormWrapper } from 'styles/auth';
import { InlineLoader } from 'styles/layout/loaders';
import { WPSForm } from 'styles/layout/forms';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, SET_AUTH_ERROR } from 'store/auth/authActions';
import { getGlobalData, setGlobalLoader } from 'store/global/globalActions';
import { isAuthedSelector, authErrorSelector } from 'store/auth/authSelectors';
import { WPSButton } from 'styles/layout/buttons';
import GuestService from 'services/guest';
import { WPS_LOGO_FULL } from 'assets/wps_logo';
import MeService from 'services/me';
import useTitle from 'hooks/useTitle';
import LoginSlider from 'components/layout/slider';
import JsxHelper from 'helpers/jsx';

const Login = () => {
  useTitle('Login');
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector(isAuthedSelector);
  const authError = useSelector(authErrorSelector);
  const [twoFactorResponse, setTwoFactorResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ email: '', password: '' });
  const [currentForm, setCurrentForm] = useState('login');
  const [response, setResponse] = useState(false);
  const [error, setError] = useState();

  // Check if user already logged in and go back to home page
  useEffect(() => {
    const userData = localStorage.getItem('user_data');
    if (userData) {
      history.push('/');
    }
  }, [history, dispatch]);

  // After successful login, goto homepage
  useEffect(() => {
    if (authState) {
      const userData = localStorage.getItem('user_data');
      dispatch(setGlobalLoader(true));
      dispatch(getGlobalData());
      if (userData && JSON.parse(userData).force_password_reset) {
        history.push('/reset-temp-password');
      } else {
        localStorage.setItem('user-logged-in', true);
        history.push('/');
      }
    } else if (authError) {
      setLoading(false);
    }
  }, [authState, authError, history, dispatch]);

  // Login form submit
  const handleLogin = () => {
    setLoading(true);
    // Reset password form.
    if (currentForm === 'reset') {
      const data = { email: user.email };
      GuestService.passwordResetEmail(data)
        .then(() => setResponse(true))
        .catch(err => setError(err.data.error))
        .finally(() => setLoading(false));
      // Login form.
    } else if (currentForm === 'login') {
      MeService.login(user)
        .then(res => {
          if (!res.two_factor_required) {
            dispatch({ type: LOGIN, payload: res })
          } else {
            // Two-factor authentication required.
            setCurrentForm('2fa');
            setTwoFactorResponse({
              user_slug: res.user_slug,
              two_factor_auth_token: res.two_factor_auth_token,
            })
          }
        }).catch(err => {
          setError(err.data ? err.data.error : 'Please check your internet connection.');
          dispatch({ type: SET_AUTH_ERROR, payload: err });
        }).finally(() => setLoading(false));
    } else if (currentForm === '2fa') {
      MeService.loginTwoFactor({ ...twoFactorResponse, two_factor_code: user.two_factor_code })
        .then(res => dispatch({ type: LOGIN, payload: res }))
        .catch(err => setError(err.data ? err.data.error : 'Please check your internet connection.'))
        .finally(() => setLoading(false));
    }
  };

  // Handle input change and record to component local state
  const handleOnChange = e => {
    const { value, name } = e.target;
    setUser(prevState => ({ ...prevState, [name]: value }));
  };

  const changeForm = (newForm) => {
    setCurrentForm(newForm);
    setResponse(false);
    setError(false);
  }

  return (
    <AuthContainer>
      <ImageWrapper>
        <LoginSlider />
        <WPS_LOGO_FULL />
      </ImageWrapper>
      <FormWrapper flex='1'>
        <WPS_LOGO_FULL inverted />
        <h1>{currentForm === 'reset' ? 'Recover your password' : (currentForm === '2fa' ? 'Two-factor authentication' : 'Login to your account')}</h1>
        {currentForm === '2fa' && <div className='notice-box'>Enter the two-factor (2FA) code sent to your email address.</div>}
        <WPSForm onSubmit={handleSubmit(handleLogin)} noValidate style={{ minWidth: '320px' }}>
          {['login', 'reset'].includes(currentForm) && JsxHelper.createTextInput({
            name: 'email',
            type: 'email',
            value: user.email,
            onChange: handleOnChange,
            label: 'Email',
            errors: errors,
            ref: register({ required: 'This field is required' }),
          })}
          {currentForm === 'login' && JsxHelper.createTextInput({
            name: 'password',
            type: 'password',
            value: user.password,
            onChange: handleOnChange,
            label: 'Password',
            errors: errors,
            ref: register({ required: 'This field is required' }),
          })}
          {currentForm === '2fa' && <Fragment>
            {JsxHelper.createTextInput({
              label: '2FA code',
              name: 'two_factor_code',
              type: 'text',
              placeholder: 'Enter code here',
              value: user.two_factor_code,
              onChange: handleOnChange,
              errors: errors,
              ref: register({ required: 'This field is required' }),
            })}
          </Fragment>}
          {!response && <WPSButton className='info--btn' disabled={loading}>
            {loading ? <InlineLoader /> : currentForm === 'reset' ? 'Send Password Reset Email' : (currentForm === '2fa' ? 'Verify' : 'Login')}
          </WPSButton>}
        </WPSForm>
        {response && (<div className='message success'>Check your email inbox and click the link in the email you received to reset your password.</div>)}
        {error && !response && <div className='message error'>{error}</div>}
        {['reset', '2fa'].includes(currentForm) && <span className='secondary-action' onClick={() => changeForm('login')}>&#8592; Back to login</span>}
        {currentForm === 'login' && <span className='secondary-action' onClick={() => changeForm('reset')}>Forgot password?</span>}
      </FormWrapper>
    </AuthContainer>
  );
};

export default Login;
