import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { meSelector } from 'store/me/meSelectors';
import { UserTopBar, ImportantNoticeBar } from 'styles/layout/userTopBar';
import Notifications from './notifications';
import UserProfile from './userProflie';
import QuickAccess from './quickAccess';
import Help from './help';

const UserBar = () => {
  const me = useSelector(meSelector);

  const getUserInitials = () => {
    let initials = '';
    if (me.first_name && me.last_name) {
      initials = `${me.first_name.slice(0, 1)}${me.last_name.slice(0, 1)}`;
    } else {
      let displayNameParts = (me.display_name || 'Unknown').split(' ');
      if (displayNameParts.length > 1) {
        initials = `${displayNameParts[0].slice(0, 1)}${displayNameParts[1].slice(0, 1)}`;
      } else {
        initials = displayNameParts[0].slice(0, 2);
      }
    }
    return initials;
  };

  const getImportantNotice = () => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (!isChrome) {
      return {
        text: 'Google Chrome is the only officially supported browser. We recommend that you use Google Chrome instead.',
        background: 'warning'
      }
    }
    return null;
  }
  const notice = getImportantNotice();

  return (
    <Fragment>
      {notice && <ImportantNoticeBar background={notice.background}>{notice.text}</ImportantNoticeBar>}
      <UserTopBar>
        <QuickAccess />
        <Help />
        <Notifications />
        <UserProfile user={getUserInitials()} image={me.photo_url} />
      </UserTopBar>
    </Fragment>
  );
};

export default UserBar;
