import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalRegionsSelector } from '../../store/region/regionSelectors';
import { TitleBar } from '../../styles/layout/titlebar';
import Can from '../../utils/can';
import { useHistory } from 'react-router-dom';
import { deleteRegion } from '../../store/region/regionActions';
import useConfirm from '../../hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import RegionService from 'services/region';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const RegionIndex = () => {
  useTitle('Regions');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const regions = useSelector(globalRegionsSelector);
  const [loading, setLoading] = useState(false);

  const syncEWSMCode = region => {
    if (loading) {
      return;
    }
    setLoading(true);
    const data = { region_slug: region.slug };

    RegionService.syncEWSM(data)
      .then(() =>
        dispatch(
          setGlobalSuccessMsg({
            id: region.display_name,
            model: 'region sync EWSM code request',
            action: 'sent',
          }),
        ),
      )
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  const updateRegion = region => history.push(`regions/${region.slug}`);

  const actions = [
    {
      value: 'Update',
      onClick: updateRegion,
    },
    {
      value: 'Servers',
      onClick: () => history.push('servers'),
    },
    {
      value: 'Sync EWSM code',
      onClick: syncEWSMCode,
    },
    {
      value: 'Delete',
      onClick: region => DialogHelper
        .confirmDelete(confirm, region.display_name, 'region')
        .then(() => dispatch(deleteRegion(region)))
    },
  ];

  const headers = [
    {
      name: 'Name',
      selector: 'display_name',
      sortable: true,
      searchable: true,
      width: '170px',
    },
    {
      name: 'API Endpoint',
      selector: 'erm_endpoint',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      searchable: true,
    },
    JsxHelper.createTableActionsHeader(actions, '180px'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Regions',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Regions</TitleBar.Title>
        <TitleBar.Actions>
          <Can
            perform='region:create:*'
            yes={() => JsxHelper.createButton({ label: 'Create Region', linkTo: `/regions/create` })}
          />
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable columns={headers} body={regions} noSearchOnTable={true}  rowsPerPage={100} />
      </Content>
    </Fragment>
  );
};

export default RegionIndex;
