// API Services
import CustomerService from '../../services/customer';
// Helpers
import { dispatchPostPromise, dispatchDeletePromise } from '../../helpers';

/*
  Action Types
*/
export const CUSTOMER_SET_ALL = 'CUSTOMER_SET_ALL';
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_PAYMENT_METHOD_SETDEFAULT = 'CUSTOMER_PAYMENT_METHOD_SETDEFAULT';
export const CUSTOMER_PAYMENT_METHOD_CREATE = 'CUSTOMER_PAYMENT_METHOD_CREATE';
export const CUSTOMER_PAYMENT_METHOD_UPDATE = 'CUSTOMER_PAYMENT_METHOD_UPDATE';
export const CUSTOMER_PAYMENT_METHOD_DELETE = 'CUSTOMER_PAYMENT_METHOD_DELETE';

/*
  Action Creators
*/

// Create new customer
export const createNewCustomer = data => {
  return dispatchPostPromise(CustomerService.create(data), CUSTOMER_CREATE);
};

// Update specified customer
export const updateCustomer = data => {
  return dispatchPostPromise(CustomerService.update(data), CUSTOMER_UPDATE);
};

// Delete specified customer
export const deleteCustomer = customer => {
  const data = { customer_slug: customer.slug };
  return dispatchDeletePromise(CustomerService.delete(data), CUSTOMER_DELETE, customer);
};

// Default Customer Payment Method
export const defaultCustomerPaymentMethod = payload => {
  return dispatch => dispatch({ type: CUSTOMER_PAYMENT_METHOD_SETDEFAULT, payload });
  // return dispatchPostPromise(editPaymentMethod(data), CUSTOMER_PAYMENT_METHOD_SETDEFAULT);
};

// Add Customer Payment Method
export const addCustomerPaymentMethod = payload => {
  return dispatch => dispatch({ type: CUSTOMER_PAYMENT_METHOD_CREATE, payload });
  // return dispatchPostPromise(addPaymentMethod(data), CUSTOMER_PAYMENT_METHOD_CREATE);
};

// Edit Customer Payment Method
export const editCustomerPaymentMethod = payload => {
  return dispatch => dispatch({ type: CUSTOMER_PAYMENT_METHOD_UPDATE, payload });
  // return dispatchPostPromise(editPaymentMethod(data), CUSTOMER_PAYMENT_METHOD_UPDATE);
};

// Remove Customer Payment Method
export const deleteCustomerPaymentMethod = payload => {
  return dispatch => dispatch({ type: CUSTOMER_PAYMENT_METHOD_DELETE, payload });
  // return dispatchDeletePromise(deletePaymentMethod(data), CUSTOMER_PAYMENT_METHOD_DELETE);
};
