import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import CreateServer from 'components/server/create';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const ServerCreate = () => {
  useTitle('Server create');
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: 'Create',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Create New Server</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <CreateServer />
      </Content>
    </Fragment>
  );
};

export default ServerCreate;
