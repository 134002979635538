import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import StripePaymentModal from 'components/stripe/stripePaymentModal';
import useConfirm from 'hooks/useConfirm';
import { useSelector, useDispatch } from 'react-redux';
import {
  listPartnerPaymentMethods,
  addPartnerPaymentMethod,
  defaultPartnerPaymentMethod,
  deletePartnerPaymentMethod,
} from 'store/billing/billingActions';
import { stripePubKeySelector } from 'store/platform/platformSelectors';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const Payment = ({ isCustomPage, handleStepperSave }) => {
  useTitle('Credit Cards');
  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);
  const [stripeForm, setStripeForm] = useState(false);
  const [patmentMethods, setPatmentMethods] = useState([]);
  const confirm = useConfirm();
  const platormPubKey = useSelector(stripePubKeySelector);
  const mounted = useRef(true);


  useEffect(() => {
    listPaymentMethods();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const openStripeForm = useCallback(() => {
    setStripeForm(true);
  }, []);

  const closeStripeForm = useCallback(() => {
    setStripeForm(false);
  }, []);

  const listPaymentMethods = () => {
    setTableLoading(true);
    dispatch(listPartnerPaymentMethods({}))
    .then(res => mounted.current && setPatmentMethods(res))
    .finally(() => mounted.current && setTableLoading(false))
    .catch(error => setGlobalErrorMsg(error))
  }

  const onPaymentMethodCreated = (data) => {
    // Check if the returned payload has any error or undefined
    if (!data || !data.data || data.data.error) {
      return false;
    }
    setTableLoading(true);
    dispatch(addPartnerPaymentMethod(data))
    .then((res) => setPatmentMethods(res))
    .finally(() => setTableLoading(false))
    .catch(error => setGlobalErrorMsg(error))
    return true;
  }

  const deleteItem = item => DialogHelper
    .confirmDelete(confirm, item.last4, 'Credit Card')
    .then(() => {
      setTableLoading(true);
      dispatch(deletePartnerPaymentMethod({ guid: item.guid }))
        .then((res) => setPatmentMethods(res))
        .finally(() => setTableLoading(false))
        .catch(error => setGlobalErrorMsg(error))
    });

  const setAsDefault = useCallback(
    async item => {
      try {
        setTableLoading(true);
        dispatch(defaultPartnerPaymentMethod({ guid: item.guid, is_default: true }))
        .then((res) => setPatmentMethods(res))
        .catch(error => setGlobalErrorMsg(error))
        .finally(() => setTableLoading(false))
      } catch (error) {
        dispatch(setGlobalErrorMsg(error));
      }
    },
    [dispatch],
  );

  const actions = [
    {
      value: 'Set Default',
      onClick: setAsDefault,
      doHide: item => item.is_default,
    },
    {
      value: 'Delete',
      onClick: deleteItem,
    },
  ];

  const headers = [
    JsxHelper.createTableCreditCardTypeIconHeader('type', '8%'),
    JsxHelper.createTableTextHeader('last4', 'Last 4', '8%'),
    JsxHelper.createTableStatusHeader('status', null, true, '10%'),
    JsxHelper.createTableTextHeaderWithCallback('exp_year', 'Expiry', '10%', (row) => JsxHelper.createBubble({
      text: row.exp_month + ' / ' + row.exp_year,
      background: 'info',
      small: true,
    })),
    JsxHelper.createTableBinaryBubbleHeader('is_default', 'Default', '10%'),
    JsxHelper.createTableActionsHeader(actions, '54%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
      link: '/billing',
    },
    {
      text: 'Credit Cards',
    },
  ];

  return (
    <Fragment>
      {!isCustomPage && (
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>Credit Cards</TitleBar.Title>
          <TitleBar.Actions>
            {JsxHelper.createButton({ label: 'Add New Card', onClick: openStripeForm })}
            {JsxHelper.createBackButton('/billing')}
          </TitleBar.Actions>
        </TitleBar>
      )}
      <Content className={isCustomPage ? 'padding-0':''}>
        <WPSDataTable
          columns={headers}
          body={patmentMethods}
          loading={tableLoading}
          noSearchOnTable={true}
          rowsPerPage={100}
          dataKey='guid'
          noMarginBottom={true}
        />
        {isCustomPage && (
          <div className='display-flex-center-start margin-top-bottom-24'>
            {JsxHelper.createButton({ label: 'Add New Card', onClick: openStripeForm })}
            {JsxHelper.createButton({ label: 'Save', onClick: handleStepperSave })}
          </div>
        )}
        <StripePaymentModal
          show={stripeForm}
          onClose={closeStripeForm}
          stripeKey={platormPubKey}
          onPaymentMethodCreated={onPaymentMethodCreated}
        />
      </Content>
    </Fragment>
  );
};

export default Payment;
