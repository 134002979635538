import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar } from 'styles/layout/titlebar';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { updateWebsiteCronJobs } from 'store/website/websiteActions';
import { Row } from 'styles/layout/grid';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import ArrayHelper from 'helpers/array';
import { Container } from 'styles/website/profile';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';

const CronManager = ({ website }) => {
  const dispatch = useDispatch();
  const { register, unregister, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [cronJobs, setCronJobs] = useState([]);
  const [editableItem, setEditableItem] = useState(null);
  const [editFlag, setEditFlag] = useState(false);

  const initialValue = {
    interval: 'Every 5 Minutes',
    expression: '*/5 * * * *',
    command: '',
  };
  const [details, setDetails] = useState(initialValue);

  useEffect(() => {
    setCronJobs(WebsiteHelper.getCronJobs(website));
    // eslint-disable-next-line
  }, []);

  const intervalOptions = [
    {
      interval: 'Every 5 Minutes',
      expression: '*/5 * * * *',
    },
    {
      interval: 'Every 15 Minutes',
      expression: '*/15 * * * *',
    },
    {
      interval: 'Every 1 Hour',
      expression: '0 */1 * * *',
    },
    {
      interval: 'Every 3 Hours',
      expression: '0 */3 * * *',
    },
    {
      interval: 'Daily',
      expression: '0 0 * * *',
    },
    {
      interval: 'Custom',
      expression: '',
    },
  ];

  const onSubmit = () => {
    setLoading(true);
    const data = {
      website_slug: website.slug,
      crons: cronJobs.map(o => ({
        interval: o.interval,
        expression: o.expression,
        command: o.command,
        slug: o.slug,
      })),
    };
    dispatch(updateWebsiteCronJobs(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Cron jobs', action: 'updated', onId: WebsiteHelper.getLabel(website), plural: true })))
      .finally(() => setLoading(false));
  };

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'interval') {
      const expression = ArrayHelper.find(intervalOptions, 'interval', value).expression;
      setDetails(prev => ({ ...prev, interval: value, expression }));
      if (editFlag) {
        setEditableItem(prev => ({ ...prev, interval: value, expression }));
      }
      if (value !== 'Custom') {
        unregister('expression');
      }
    } else {
      setDetails(prev => ({ ...prev, [name]: value }));
      if (editFlag) {
        setEditableItem(prev => ({ ...prev, [name]: value }));
      }
    }
  };

  const onAdd = () => {
    if (editFlag) {
      let tempCrons = ArrayHelper.filterOut(cronJobs, 'slug', editableItem.slug);
      tempCrons.push(editableItem);
      setCronJobs(tempCrons);
    } else {
      const data = { ...details, slug: StringHelper.randomString() };
      setCronJobs(prev => [...prev, data]);
    }
    setDetails(initialValue);
    setModal(false);
    setEditFlag(false);
    setEditableItem(null);
  };

  const handleClose = () => {
    setModal(false);
    setEditFlag(false);
    setEditableItem(null);
    setDetails(initialValue);
  };
  const actions = [
    {
      value: 'Edit',
      onClick: item => {
        setEditFlag(true);
        setEditableItem({ ...item });
        setModal(true);
      },
    },
    {
      value: 'Remove',
      onClick: item => setCronJobs(prev => ArrayHelper.filterOut(prev, 'slug', item.slug)),
    },
  ];

  const headers = [
    JsxHelper.createTableTextHeader('interval', 'Interval', '15%'),
    JsxHelper.createTableTextHeader('expression', 'Cron Time', '15%'),
    JsxHelper.createTableTextHeader('command', 'Command', '50%'),
    JsxHelper.createTableActionsHeader(actions, '20%'),
  ];

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title> Cron Manager</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader padding-left-0'>
        Schedule real server Cron jobs that run command-lines at various intervals. You can find
        some of the most common schedules preset when adding a cron job, or decide to add your
        custom Cron schedule expression.
      </p>
      <WPSDataTable
        columns={headers}
        body={cronJobs}
        rowsPerPage={100}
      />
      <Row className='action-buttons'>
        {JsxHelper.createButton({
          label: 'Add',
          classes: 'info--btn',
          disabled: loading,
          onClick: () => setModal(true),
        })}
        {JsxHelper.createButton({
          label: 'Save',
          onClick: onSubmit,
          loading,
        })}
      </Row>
      {modal && DialogHelper.inputs({
        title: editFlag ? 'Edit cron job' : 'Add cron job',
        onClose: () => handleClose(),
        onConfirm: handleSubmit(onAdd),
        confirmBtn: editFlag ? 'Edit' : 'Add',
        register,
        inputs: [{
          label: 'Job interval',
          name: 'interval',
          type: 'select',
          options: ArrayHelper.buildSelectOptions(intervalOptions, 'interval', 'interval'),
          value: editFlag ? editableItem.interval : details.interval,
          onChange,
          errors,
          required: true,
        }, (!editFlag && details.interval === 'Custom') || (editFlag && editableItem.interval === 'Custom') ? {
          label: 'Cron time',
          name: 'expression',
          type: 'text',
          value: editFlag ? editableItem.expression : details.expression,
          onChange,
          errors,
          required: true,
          ref: register({
            required: FormHelper.messages.required,
            pattern: {
              value: FormHelper.regex.cronExp,
              message: FormHelper.messages.invalidExpression,
            },
          })
        } : null,
        {
          label: 'Command',
          name: 'command',
          type: 'text',
          value: editFlag ? editableItem.command : details.command,
          onChange,
          errors,
          required: true,
        }
      ].filter(Boolean)})}
    </Container>
  );
};

export default CronManager;
