import React, { Fragment } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import { useHistory } from 'react-router-dom';
import env from 'config/env';
import UserHelper from 'helpers/user';

const AdminReports = () => {
  const history = useHistory();

  const adminCards = [
    {
      title: `${env.getBrandShortName()} WAF`,
      name: 'firewall',
      desc: `Manage ${env.getBrandShortName()} Web Application Firewall and create related reports.`,
      icon: 'firewall',
      btnText: 'Manage',
    },
    {
      title: `WordPress Vulnerability`,
      name: 'wp-vulnerability',
      desc: `View the known vulnerabilities affecting installed WordPress core, plugin and theme packages.`,
      icon: 'leaks',
      btnText: 'View',
    },
    {
      title: `Package Updates History`,
      name: 'package-updates-history',
      desc: `View the history of plugins and themes updates, including the update status and other details.`,
      icon: 'history',
      btnText: 'View',
    },
    {
      title: 'Business Performance',
      name: 'business-performance',
      desc: 'Monitor your business performance and financial health with a graphical overview.',
      icon: 'analytics',
      btnText: 'View',
      hide: !UserHelper.isAdmin(),
    },
    {
      title: `Partner Analytics`,
      name: 'partners-usage',
      desc: `View the partner websites usage, invoices summary and other related data.`,
      icon: 'activity',
      btnText: 'View',
      hide: !UserHelper.isAdmin(),
    },
    {
      title: `Client Reports`,
      name: 'client-reports',
      desc: `View client PDF reports generated by partners for all their clients.`,
      icon: 'pdf',
      btnText: 'View',
    },
    {
      title: `Subscriptions High Usage`,
      name: 'subscriptions-high-usage',
      desc: `View the subscription features that have high usage and can incur additional charges.`,
      icon: 'trendUp',
      btnText: 'View',
    },
  ];
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Reports',
    },
  ];

  const handleOnClick = e => {
    if (e) {
      history.push({
        pathname: `/reports/${e}`,
      });
    }
  };
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Reports</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {adminCards.filter(el => !el.hide).map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              loading={el.loading}
              btnText={el.btnText}
              btnDisabled={el.btnDisabled}
              onClick={el.onClick || (e => handleOnClick(e))}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default AdminReports;
