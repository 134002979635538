import React, { useState, useRef } from 'react';
import useOnClickOutside from '../../utils/useOnClickOutside';
// Styles
import { UserProfileStyle, UserProfileImg } from '../../styles/layout/sidebar';
// Redux
import { useDispatch } from 'react-redux';
import { userLogout } from '../../store/auth/authActions';
import { useHistory } from 'react-router-dom';
import { NotificationBarList } from 'styles/layout/userTopBar';

const UserProfile = ({ secondary, image, user }) => {
  const history = useHistory();
  const ref = useRef();
  const dispatch = useDispatch();

  // Handle menu toggle
  const [toggleMenu, setToggle] = useState(false);
  useOnClickOutside(ref, () => setToggle(false));

  // Handle user logout
  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <UserProfileStyle onClick={() => setToggle(!toggleMenu)} secondary={secondary}>
      <UserProfileImg background={image}>{!image && user && user}</UserProfileImg>
      {toggleMenu && (
        <NotificationBarList ref={ref}>
          <NotificationBarList.Item onClick={() => history.push('/profile')} icon='user'>
            Profile
          </NotificationBarList.Item>
          <NotificationBarList.Item onClick={handleLogout} icon='logout'>
            Logout
          </NotificationBarList.Item>
        </NotificationBarList>
      )}
    </UserProfileStyle>
  );
};

export default UserProfile;
