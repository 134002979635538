import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import PackageService from 'services/package';
import useTitle from 'hooks/useTitle';
import { WPSBubble } from 'styles/layout/buttons';
import { getStatusColor, openNewTab } from 'helpers';
import StringHelper from 'helpers/string';
import ReactTooltip from 'react-tooltip';
import env from 'config/env';
import JsxHelper from 'helpers/jsx';
import { useSelector } from 'react-redux';
import { timezoneSelector } from 'store/me/meSelectors';

const ListGitEvents = ({ currentPackage }) => {
  useTitle('Git Events History');
  const dispatch = useDispatch();
  const [ commits, setCommits ] = useState([]);
  const [loading, setLoading] = useState(false);
  const timezone = useSelector(timezoneSelector);

  const fetchGitEvents = async () => {
    setLoading(true);
    try {
      const res = await PackageService.fetchGitEvents({
        package_slug: currentPackage.slug,
      });
      setCommits(res);
    } catch (err) {
      dispatch(setGlobalErrorMsg(err));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchGitEvents();
    // eslint-disable-next-line
  }, []);

  const headers = [
    JsxHelper.createTableTimeHeader('created_at', timezone, '15%'),
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      width: '15%',
      cell: row => row.type === 'system' ? `System event` : row.type,
    },
    {
      name: 'Action',
      selector: 'name',
      searchable: true,
      sortable: true,
      width: '15%',
      cell: row => {
        if (row.name === 'push') {
          return 'push:' + row.branch;
        }
        return row.name;
      }
    },
    {
      name: 'Status',
      selector: 'status',
      width: '10%',
      style: {
        margin: '0 -15px 0 15px',
      },
      sortable: true,
      cell: row => {
        const rowId = row.commit_id ? row.commit_id : StringHelper.randomSlug();
        return (
          <Fragment>
            <WPSBubble
              display='inline'
              padding='0'
              margin='0'
              color={getStatusColor(row)}
              icon={getStatusColor(row)}
              data-for={rowId}
              data-tip={row.status_reason}></WPSBubble>
            {row.status === 'error' && <ReactTooltip id={rowId} />}
          </Fragment>
        );
      },
    },
    {
      name: 'Commit',
      selector: 'commit_id',
      width: '13%',
      searchable: true,
      cell: row => {
        return row.name === 'push' && row.commit_link ? (
          <Fragment>
            <WPSBubble
              display='inline'
              color='light'
              onClick={() => row.commit_link && openNewTab(row.commit_link)}
              background='info'
              padding='5px'
              style={{minWidth: '80px', textAlign: 'center'}}
              margin='0'>{row.commit_id}</WPSBubble>
          </Fragment>
        ) : '-';
      },
    },
    {
      name: 'Message',
      width: '30%',
      selector: 'commit_message',
      style: {
        marginRight: '5px',
      },
      searchable: true,
      cell: row => {
        if (row.type === 'system') {
          return `Automated healthcheck by ${env.getBrandShortName()}`;
        }
        return row.commit_message;
      },
    }
  ]

  return (
    <Fragment>
      <Content className='main-users-table'>
        <WPSDataTable loading={loading} columns={headers} body={commits} />
      </Content>
    </Fragment>
  );
};

export default ListGitEvents;
