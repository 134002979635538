import React, { Fragment } from 'react';
import styled from 'styled-components';
import Icon from 'components/layout/icon';
import { Link } from 'react-router-dom';

export const TitleBar = styled.div`
  flex: 0 0 70px;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
`;

const TitleStyle = styled.h1`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.color.primary};
  > span {
    font-size: 14px;
    font-weight: normal;
    margin: 6px 0;
  }
`;

const Breadcrumbs = styled.ul`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  li {
    display: flex;
    align-items: center;
  }
`;

const BreadcrumbsItem = ({ item }) => {
  return <li>{item.link ? <Link to={item.link}>{item.text}</Link> : <span>{item.text}</span>}</li>;
};

const Title = ({ children, breadcrumbs, ...rest }) => {
  return (
    <TitleStyle {...rest}>
      {breadcrumbs && (
        <Breadcrumbs>
          {breadcrumbs.map((b, index) => (
            <Fragment key={index}>
              <BreadcrumbsItem item={b} />
              {index !== breadcrumbs.length - 1 && <Icon tag='arrow_right' />}
            </Fragment>
          ))}
        </Breadcrumbs>
      )}
      {children}
    </TitleStyle>
  );
};

const Actions = styled.div`
  > *:not(:first-child) {
    margin-left: 9px;
  }
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Website = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    display: flex;
    align-items: center;
    font-size: 19px;
    color: ${props => props.theme.color.primary};
    .status {
      font-size: 14px;
      color: ${props => props.theme.color.light};
      background: ${props => props.theme.background.success};
      padding: 6px 16px;
      border-radius: ${props => props.theme.border.radius};
      margin-left: 24px;
    }
  }
  a {
    text-transform: none;
    color: ${props => props.theme.color.link};
  }
  ul {
    margin-top: 8px;
    display: flex;
    li {
      display: flex;
      flex-direction: column;
      margin-right: 24px;
      span:nth-child(2) {
        color: #4f6783;
        margin-top: 4px;
      }
    }
    .actions {
      a {
        padding: 0 6px;
      }
    }
  }
`;

TitleBar.Title = Title;
TitleBar.Actions = Actions;
TitleBar.Website = Website;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '24px')};
  ${({ marginTop }) => `margin-top: ${marginTop ? marginTop : 0 }px`};

  h3 {
    padding-bottom: 12px;
    text-transform: capitalize;
    font-size: 25px;
  }
  button {
    position: relative;
    font-size: 16px;
    padding-bottom: 12px;
    color: ${props => props.theme.color.inactive2};
    &::after {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 3px;
      background: ${props => props.theme.color.primary};
    }
    &.active {
      color: ${props => props.theme.color.primary};
      &::after {
        display: block;
      }
    }
  }
`;

const Filters = styled.div`
  display: flex;
  margin-left: auto;
  > * {
    margin-left: 12px;
  }
`;

GroupTitle.Filters = Filters;
