import React from 'react';
import ListGitEvents from "./Git/ListEvents";

const PackageCommitsHistory = ({ currentPackage }) => {
  return (
    <section>
      <ListGitEvents currentPackage={currentPackage} />
    </section>
  );
}

export default PackageCommitsHistory;