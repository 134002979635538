import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.background.overlay};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
`;

const ModalBox = styled.div`
  min-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '500px')};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '500px')};
  width: ${({ width = 'auto' }) => width};
  height: auto;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}` : 'inherit')};
  background: ${props => props.theme.background.light};
  border-radius: 8px;
  padding: 30px;
  overflow: auto;
`;

const ModalBody = styled.div`
  padding: 16px 0;
  max-height: 85vh;
  overflow: auto;
`;

const ModalTitle = styled.h3`
  padding-bottom: 16px;
  text-align: center;
`;

const ModalSubtitle = styled.h5`
  padding-bottom: 16px;
  text-align: center;
`;

const ModalSubject = styled.h3`
  padding-bottom: 16px;
  text-align: center;
  color:#458FF5;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  border-radius: 5px;
  button:not(:first-child) {
    margin-left: 10px;
  }
`;

Container.ModalBox = ModalBox;
Container.ModalSubject = ModalSubject;
Container.ModalBody = ModalBody;
Container.ModalFooter = ModalFooter;
Container.ModalTitle = ModalTitle;
Container.ModalSubtitle = ModalSubtitle;
