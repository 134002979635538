import { NOTIFICATION_SET_ALL } from './notificationActions';

const notificationReducer = (state = [], action) => {
  switch (action.type) {
    case NOTIFICATION_SET_ALL:
      return [...action.payload];
    default:
      return state;
  }
};

export default notificationReducer;
