import React, { Fragment, useState, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'helpers';
import { plansSelector } from 'store/billing/billingSelectors';
import { partnersSelector } from 'store/user/userSelectors';
import Modal from 'components/layout/modal';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { useForm } from 'react-hook-form';
import ArrayHelper from 'helpers/array';
import FormHelper from 'helpers/form';
import { WPSForm } from 'styles/layout/forms';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useHistory } from 'react-router-dom';
import useConfirm from 'hooks/useConfirm';
import { handleFilterURLParamsChanges, convertToFilterObject } from 'helpers';
import useOnClickOutside from 'utils/useOnClickOutside';
import useQuery from 'hooks/useQuery';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import PlanHelper from 'helpers/plan';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import SubscriptionService from 'services/subscription';

const Subscription = () => {
  useTitle('Billing subscriptions');
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const query = useQuery();
  const partners = useSelector(partnersSelector);
  const allPlans = useSelector(plansSelector);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [resourcesTableData, setResourcesTableData] = useState(null);
  const [selectedResourceItem, setSelectedResourceItem] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const [loadedPage, setLoadedPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const { handleSubmit, errors, register } = useForm({ reValidateMode: 'onSubmit' });

  // -------------
  // FILTERS
  // -------------

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const filtersRef = useRef();
  useOnClickOutside(filtersRef, () => setShowFilters(false));

  const onClickFilter = e => {
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  useEffect(() => {
    if (!isEmpty(subscriptions) && !loading) {
      handleFilterURLParamsChanges(filters, history);
    }
    // eslint-disable-next-line
  }, [filters]);

  // -------------
  // PLANS
  // -------------

  const getPlanGroups = (partnerSlug) => {
    let groups = [
      { label: 'Website Hosting', slug: 'website_hosting' },
      { label: 'Domain Registration', slug: 'domain_registration' },
      { label: 'Email Hosting', slug: 'email_hosting' },
      { label: 'Database Hosting', slug: 'database_hosting' },
    ];
    groups = groups.map(group => {
      let plans = allPlans.filter(plan => plan.category_slug === group.slug);
      if (partnerSlug) {
        plans = plans.filter(plan => !plan.assignee_slug || plan.assignee_slug === partnerSlug);
      }
      if (isEmpty(plans)) return null;
      return {
        label: group.label,
        options: ArrayHelper.getSelectOptions(plans, 'slug', 'name'),
      };
    });
    return groups.filter(Boolean);
  };

  // -------------
  // SUBSCRIPTIONS
  // -------------

  useEffect(() => {
    loadSubscriptions();
    // eslint-disable-next-line
  }, []);

  const loadSubscriptions = () => {
    setLoading(true);
    const limit = 1000;
    const data = { subscriber_type: 'partner', page: loadedPage, limit };
    SubscriptionService.fetch(data).then(_subscriptions => {
      setSubscriptions(prev => [...prev, ..._subscriptions]);
      setLoadedPage(loadedPage + 1);
      if (_subscriptions.length < limit) {
        setCanLoadMore(false);
      }
    })
    .catch((err) => dispatch(setGlobalErrorMsg(err)))
    .finally(() => setLoading(false))
  }

  const initialSubscription = {
    plan_slug: '',
    partner_slug: '',
    name: '',
    starts_at: FormHelper.getTodayDate() || '',
  };
  const [subscription, setSubscription] = useState(initialSubscription);

  const onChange = e => {
    const { value, name } = e.target;
    if (name === 'partner_slug') {
      // Reset plan when partner changes
      setSubscription(prev => ({ ...prev, plan_slug: '' }));
    }
    setSubscription(prev => ({ ...prev, [name]: value }));
  };

  const handleAddSubscription = () => {
    setModal(true);
    setUpdate(false);
    setSubscription(initialSubscription);
  };

  useEffect(() => {
    if (!isEmpty(subscriptions)) {
      const name = subscriptions.map(p => p.name);
      const plan_name = subscriptions.map(p => p.plan_name);
      const status = subscriptions.map(p => StringHelper.toText(p.status));
      const subscriber = subscriptions.map(subscription => {
        const partner = partners.find(partner => partner.slug === subscription.subscriber_slug);
        return partner ? partner.display_name : false;
      }).filter(Boolean);
      setFilters(prev => ({
        ...prev,
        ...convertToFilterObject(query, 'Name', new Set(name)),
        ...convertToFilterObject(query, 'Subscriber', new Set(subscriber)),
        ...convertToFilterObject(query, 'Plan', new Set(plan_name)),
        ...convertToFilterObject(query, 'Status', new Set(status)),
      }));
    }
    // eslint-disable-next-line
  }, [subscriptions]);

  // -------------
  // API
  // -------------

  const onSubmit = () => {
    setModalLoading(true);
    if (!update) {
      SubscriptionService.create(subscription)
        .then(_subscription => {
          setSubscriptions([_subscription, ...subscriptions]);
          dispatch(setGlobalSuccessMsg({ model: 'subscription', action: 'created' }));
          setModal(false);
        })
        .catch(error => dispatch(setGlobalErrorMsg(error)))
        .finally(() => {
          setModalLoading(false);
          setSubscription(initialSubscription);
        });
    } else {
      SubscriptionService.update(subscription)
        .then((_subscription) => {
          setSubscriptions(subscriptions.map(sub => sub.guid === _subscription.guid ? _subscription : sub));
          dispatch(setGlobalSuccessMsg({ model: 'subscription', action: 'updated' }));
          setModal(false);
        })
        .finally(() => {
          setModalLoading(false);
          setUpdate(false);
          setSubscription(initialSubscription);
        });
    }
  };

  // ------------------
  // TABLE AND OTHER UI
  // ------------------

  const breadcrumbs = JsxHelper.createBreadcrumbs('Subscriptions', 'billing');
  const resourcesBreadcrumb = JsxHelper.createBreadcrumbs('Subscriptions', 'billing', [ { text: 'Subscriptions', link: '#' } ], '/billing/subscriptions');
  
  const onBackButtonClick = () => {
    setResourcesTableData(null);
    setSelectedResourceItem('');
  };  

  const actions = [
    {
      value: 'Update',
      onClick: item => {
        const data = {
          guid: item.guid,
          plan_slug: item.plan_slug,
          name: item.name,
          starts_at: item.starts_at,
          partner_slug: item.subscriber_slug,
        };
        setSubscription(data);
        setUpdate(true);
        setModal(true);
      },
    },
    {
      value: 'Usage History',
      onClick: item => {
        history.push({
          pathname: `/billing/subscriptions/${item.guid}/usage`,
          search: `?guid=${item.guid}&from=${item.usage_start_date}`,
        });
      },
    },
    {
      value: 'Resources',
      doHide: item => isEmpty(item.resources),
      onClick: item => {
        if (!isEmpty(item.resources)) {
          setResourcesTableData([...item.resources]);
        }
      },
    },
    {
      value: 'Cancel',
      doHide: item => item.canceled_at,
      onClick: item => DialogHelper
        .confirmAction(confirm, 'cancel', item.name, 'subscription')
        .then(() => {
          SubscriptionService.cancel({ guid: item.guid }).then((_subscription) => {
            dispatch(setGlobalSuccessMsg({ model: 'subscription', action: 'cancelled' }))
            setSubscriptions(subscriptions.map(sub => sub.guid === item.guid ? _subscription : sub));
          });
        })
    },
    {
      value: 'Delete',
      onClick: item => DialogHelper
        .confirmDelete(confirm, item.name, 'subscription')
        .then(() => {
          SubscriptionService.forceDelete({ guid: item.guid }).then(() => {
            dispatch(setGlobalSuccessMsg({ model: 'subscription', action: 'deleted' }));
            setSubscriptions(subscriptions.filter(sub => sub.guid !== item.guid));
          });
        })
    },
  ];

  const headers = [
    JsxHelper.createTableTitleHeader('name', 'Subscription', '26%', 'plan_name'),
    JsxHelper.createTableStatusHeader('status', PlanHelper.getSubscriptionStatusColor, true),
    JsxHelper.createTablePeriodHeader('starts_at', 'ends_at', 'Period', '20%'),
    JsxHelper.createTableTitleHeader('subscriber_display_name', 'Subscriber', '25%', 'subscriber_email'),
    JsxHelper.createTableActionsHeader(actions, '19%'),
  ];

  const resourcesTableHeaders = [
    JsxHelper.createTableTitleHeader('slug', 'Resource', '25%', 'type'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={resourcesTableData ? resourcesBreadcrumb : breadcrumbs}>
          {resourcesTableData ? (
            <div>{selectedResourceItem}</div>
          ) : (
            <div>Subscriptions</div>
          )}
        </TitleBar.Title>
        <div style={{ display: 'inline-flex' }}>
          {!resourcesTableData ? JsxHelper.createFilterIcon({
            setShowFilters,
            showFilters,
            filtersRef,
            onClickFilter,
            filters,
            marginRight: '10px'
          }) : null}
          <TitleBar.Actions>
            {JsxHelper.createButton({ label: 'Load More', onClick: loadSubscriptions, loading: loading && loadedPage > 1, disabled: !canLoadMore })}
            {JsxHelper.createButton({ label: 'Add Subscription', onClick: handleAddSubscription })}
            {resourcesTableData
              ? JsxHelper.createButton({ label: 'Back', onClick: onBackButtonClick })
              : JsxHelper.createBackButton()
            }
          </TitleBar.Actions>
        </div>
      </TitleBar>
      <Content>
        {resourcesTableData ? (
          <Fragment>
            <WPSDataTable
              columns={resourcesTableHeaders}
              body={resourcesTableData}
              noSearchOnTable={true}
              rowsPerPage={100}
            />
          </Fragment>
        ) : (
          <WPSDataTable
            columns={headers}
            body={subscriptions}
            loading={loading}
            dataKey='guid'
          />
        )}
        {modal && (
          <Modal
            title={update ? 'Update Subscription' : 'Create New Subscription'}
            confirmBtn={update ? 'Update' : 'Create'}
            className='input-modal'
            icon={'create'}
            iconColor={'success'}
            loading={modalLoading}
            onClose={() => {
              setModal(false);
              setUpdate(false);
            }}
            onConfirm={handleSubmit(onSubmit)}>
            <WPSForm>
              {JsxHelper.createSelectInput({
                name: 'partner_slug',
                label: 'Partner',
                value: subscription.partner_slug,
                options: ArrayHelper.buildSelectOptions(partners, 'display_name', 'slug'),
                onChange,
                disabled: update,
                required: true,
                isSearchable: true,
                errors,
                register,
              })}
              {JsxHelper.createSelectInput({
                name: 'plan_slug',
                label: 'Plan',
                value: subscription.plan_slug,
                options: getPlanGroups(subscription.partner_slug),
                onChange,
                isSearchable: true,
                sortOff: true,
                disabled: update,
                required: true,
                withGroups: true,
                errors,
                register,
              })}
              {JsxHelper.createTextInput({
                name: 'name',
                label: 'Name',
                value: subscription.name,
                onChange,
                required: true,
                errors,
                register,
              })}
              {JsxHelper.createDateInput({
                name: 'starts_at',
                label: 'Starts at',
                value: subscription.starts_at,
                onChange,
                min: FormHelper.getTodayDate(),
                required: true,
                errors,
                register,
              })}
            </WPSForm>
          </Modal>
        )}
      </Content>
    </Fragment>
  );
};

export default Subscription;
