import React from 'react';
import UserHelper from '../../helpers/user';
import AdminReports from './admin';
import PartnerReports from './partner';

const ReportsIndex = () => {

  return UserHelper.isAdminOrAgent() ? (
      <AdminReports />
  ) : UserHelper.isPartner() || UserHelper.isEmployee() ? (
      <PartnerReports />
  ) : null;
};

export default ReportsIndex;
