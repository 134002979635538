import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import UserFormFields from '../user/formFields';
import { WPSForm } from 'styles/layout/forms';
import EmployeePermissions from './employeePermissions';
import { WPSButton } from 'styles/layout/buttons';
import { useDispatch } from 'react-redux';
import { createUser, updateUser } from 'store/user/userActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { useHistory } from 'react-router-dom';
import UserHelper from 'helpers/user';

const SaveEmployee = ({ employee }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  const initialPermissions = [];

  const initialState = {
    display_name: employee ? employee.display_name : '',
    first_name: employee ? employee.first_name : '',
    last_name: employee ? employee.last_name : '',
    email: employee ? employee.email : '',
    sub_role: employee ? employee.sub_role : 'team_member',
    permissions: employee
      ? employee.permissions.map(p => (p ? `${p.type}:${p.action}:${p.scope}` : null))
      : initialPermissions,
  };

  const [details, setDetails] = useState(initialState);
  const [loading, setLoading] = useState(false);

  // Create permission object for each permission for form submit.
  const preparePermissions = permissionIds => {
    let permissions = [];
    permissionIds.forEach(p => {
      const permission = p.split(':');
      permissions.push({ type: permission[0], action: permission[1], slug: permission[2] });
    });
    return permissions;
  };

  const userRoleOptions = {
    can_edit: true,
    input_name: 'sub_role',
    options: [
      { value: 'agency_admin', text: 'Agency Admin' },
      { value: 'team_member', text: 'Team Member' },
      { value: 'third_party', text: 'Third Party' },
    ]
  }

  // Prepare data for form submit.
  const initFormData = () => {
    return {
      user_slug: employee ? employee.slug : null,
      display_name: details.display_name,
      first_name: details.first_name,
      last_name: details.last_name,
      email: details.email,
      sub_role: details.sub_role,
      permissions: preparePermissions(details.permissions),
    };
  };

  // Onchange for form fields.
  const onChange = e => {
    const { type, name, value, checked } = e.target;
    const _value = type === 'checkbox' ? checked : value;
    if (name === 'sub_role') {
      // If an "Agency Admin" is selected then all global permissions must be checked
      // as a suggestion (user may uncheck any of them).
      if (value === 'agency_admin') {
        // Todo: fix bug, this is not really checking the boxes!
        setDetails(prev => ({ ...prev, permissions: UserHelper.fullPermissions() }));
        document.querySelectorAll('input[id$=":*"]').forEach(e => { e.checked = true });
      }
      // The employee's role is "employee" and is fixed and the role field is actually
      // a sub-role for employee therefore we do this small trick
      setDetails(prev => ({ ...prev, sub_role: value }))
    }
    setDetails(prev => ({ ...prev, [name]: _value }));
  };

  // On success handler
  const onSuccess = id => {
    dispatch(setGlobalSuccessMsg({ id, model: 'user' }));
    setLoading(false);
    history.push('/users');
  };

  const onSubmit = () => {
    const data = initFormData();
    setLoading(true);

    // Create new employee
    if (!employee) {
      // Remove slug fields if creating a new employee
      delete data.user_slug;
      dispatch(createUser(data))
        .then(res => onSuccess(res.email))
        .catch(() => setLoading(false));
    }

    // Update existing employee
    if (employee) {
      dispatch(updateUser(data))
        .then(res => onSuccess(res.email))
        .catch(() => setLoading(false));
    }
  };

  
  return (
    <WPSForm onSubmit={handleSubmit(onSubmit)}>
      <UserFormFields
        errors={errors}
        onChange={onChange}
        register={register}
        details={details}
        user={employee}
        userRoles={userRoleOptions}
      />
      <WPSForm.Fieldset>
        <legend>Permissions</legend>
        <EmployeePermissions
          setUserDetails={setDetails}
          checkedPermissions={initialState.permissions}
        />
      </WPSForm.Fieldset>
      <WPSButton className='zIndex-2048 save--btn' style={{ maxWidth: '150px' }} type='submit' loading={loading}>
        Save
      </WPSButton>
    </WPSForm>
  );
};

export default SaveEmployee;
