import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import WebsiteService from 'services/website';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { WPSButton } from 'styles/layout/buttons';
import Tabs, { Tab, TabPanel } from 'components/wpstaq/tabs';
import EC2Tab from 'components/website/analytics/ec2';
import S3Tab from 'components/website/analytics/s3';
import CloudfrontTab from 'components/website/analytics/cloudfront';
import TrafficCards from 'components/website/analytics/traffic-cards';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';

const TrafficAnalytics = ({ website }) => {
  useTitle('Traffic Analytics');
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(0);
  const mounted = useRef(true);

  const fetchData = useCallback(
    days => {
      if (day === days || loading) {
        return;
      }
      setLoading(true);
      setDay(days);
      const data = {
        website_slug: website.slug,
        days_ago: days,
      };
      WebsiteService.fetchTrafficAnalytics(data)
        .then(res => {
          if (mounted.current) {
            setData(res);
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
          }
        });
    },
    // eslint-disable-next-line
    [day, loading],
  );

  useEffect(() => {
    if (mounted.current) {
      fetchData(3);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Traffic & Storage Analytics</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
      Gain in-depth insights into your website's traffic and storage usage across Amazon AWS services, including EC2, S3, and CloudFront.
      </p>
      <Content>
        <TrafficCards website={website} data={data} loading={loading} />

        <div className='analytics-filters'>
          <WPSButton
            className={day === 3 ? 'primary--btn' : 'disabled--btn'}
            onClick={() => fetchData(3)}
            loading={day === 3 && loading}>
            3 Days
          </WPSButton>
          <WPSButton
            className={day === 7 ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6'}
            onClick={() => fetchData(7)}
            loading={day === 7 && loading}>
            7 Days
          </WPSButton>
          <WPSButton
            className={day === 14 ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6'}
            onClick={() => fetchData(14)}
            loading={day === 14 && loading}>
            14 Days
          </WPSButton>
          <WPSButton
            className={day === 30 ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6'}
            onClick={() => fetchData(30)}
            loading={day === 30 && loading}>
            30 Days
          </WPSButton>
        </div>
        <div className='analytics-tables'>
          {!!data && (
            <Tabs
              className='stats-tabs traffic-tabs'
              initialValue='ec2'
              tabs={() => (
                <Fragment>
                  <Tab name='ec2'>EC2</Tab>
                  <Tab name='s3'>S3</Tab>
                  <Tab name='cloudfront'>CloudFront</Tab>
                </Fragment>
              )}
              panels={() => (
                <Fragment>
                  <TabPanel name='ec2'>
                    <EC2Tab slug={website.slug} data={data.traffic.ec2} contentType='requests' />
                  </TabPanel>
                  <TabPanel name='s3'>
                    <S3Tab slug={website.slug} data={data.traffic.s3} contentType='requests' />
                  </TabPanel>
                  <TabPanel name='cloudfront'>
                    <CloudfrontTab
                      slug={website.slug}
                      data={data.traffic.cloudfront}
                      contentType='requests'
                    />
                  </TabPanel>
                </Fragment>
              )}
            />
          )}
          {!!data && (
            <Tabs
              className='stats-tabs traffic-tabs'
              initialValue='ec2'
              contentType='requests'
              tabs={() => (
                <Fragment>
                  <Tab name='ec2'>EC2</Tab>
                  <Tab name='s3'>S3</Tab>
                  <Tab name='cloudfront'>CloudFront</Tab>
                </Fragment>
              )}
              panels={() => (
                <Fragment>
                  <TabPanel name='ec2'>
                    <EC2Tab slug={website.slug} data={data.traffic.ec2} contentType='bandwidth' />
                  </TabPanel>
                  <TabPanel name='s3'>
                    <S3Tab slug={website.slug} data={data.traffic.s3} contentType='bandwidth' />
                  </TabPanel>
                  <TabPanel name='cloudfront'>
                    <CloudfrontTab
                      slug={website.slug}
                      data={data.traffic.cloudfront}
                      contentType='bandwidth'
                    />
                  </TabPanel>
                </Fragment>
              )}
            />
          )}
        </div>
      </Content>
    </Container>
  );
};

export default TrafficAnalytics;
