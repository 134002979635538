import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { WPSForm } from 'styles/layout/forms';
import WebsiteService from 'services/website';
import { activeWebsite, websitesSelector } from 'store/website/websiteSelectors';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';
import { isEmptyOrNull, isObject, isArray, isUndefined, getErrorMsg } from 'helpers';
import { timezoneSelector } from 'store/me/meSelectors';
import ArrayHelper from 'helpers/array';
import WebsiteHelper from 'helpers/website';
import { deleteWebsite } from 'store/website/websiteActions';
import DialogHelper from 'helpers/dialog';
import useConfirm from 'hooks/useConfirm';
import { setGlobalErrorMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import StringHelper from 'helpers/string';

const PushStaging = ({ stagingWebsite }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const timezone = useSelector(timezoneSelector);
  const liveWebsite = useSelector(activeWebsite(stagingWebsite.parent_slug));
  const allWebsites = useSelector(websitesSelector);
  const [syncScheduled, setSyncScheduled] = useState(false);
  const [prevRequest, setPrevRequest] = useState(null); // The previous request taken place before opening this page.
  const [activeRequest, setActiveRequest] = useState(null);
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDBTables, setLoadingDBTables] = useState(false);
  const [userTypes, setUserTypes] = useState([]); // ['database', 'themes', 'plugins', 'content']
  const [allDBTableOptions, setAllDBTableOptions] = useState([]);
  const [includeDBTableUserOptions, setIncludeDBTableUserOptions] = useState([]);
  const [excludeDBTableUserOptions, setExcludeDBTableUserOptions] = useState([]);
  const mounted = useRef(true);

  // ------------------
  // CONSTANTS
  // ------------------

  const FORM_STYLE = { maxWidth: '1000px', marginTop: '120px' };
  const PLUGIN_WARNINGS = [ 'woocommerce', 'formidable', 'gravityforms' ];
  const PLUGIN_WARNING_TOOLTIP = `${env.getBrandShortName()} does not differentiate between the new and old database data. If you have new data on the live site, it will be overwritten by the old data from the staging site.`;
  const PLUGIN_WARNING_MESSAGE = `To avoid overwriting outdated data, such as contact form submissions and WooCommerce orders, use the "Exclude Tables" dropdown list to select specific database tables to exclude when updating the live site. If this isn't possible, manually export the data from the live site and import it into the live site after the sync.`;
  const BACKUP_TYPES = { database: 'Database', themes: 'Themes', plugins: 'Plugins', content: 'Content (media, uploads, files, etc.)' };
  const STEPS_NUM = 3;

  useEffect(() => {
    goToStep(1);
    fetchActiveRequests();
    fetchDBTables();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // ------------------
  // CALLBACKS
  // ------------------

  const onDeleteStaging = () => {
    DialogHelper
      .confirmDelete(confirm, WebsiteHelper.getLabel(stagingWebsite), 'website')
      .then(() => {
        setLoadingDelete(true);
        dispatch(deleteWebsite(stagingWebsite)).then(() => {
          dispatch(setGlobalSuccessMsg({
            id: WebsiteHelper.getLabel(stagingWebsite),
            model: 'website',
            action: 'deleted'
          }))
          WebsiteHelper.goToIndex(history);
        }).finally(() => setLoadingDelete(false));
      })
  };

  const onTypeChange = e => {
    const name = e.target.name;
    if (userTypes.includes(name)) {
      setUserTypes(userTypes.filter(item => item !== name));
    } else {
      setUserTypes([...userTypes, name]);
    }
  };

  const fetchDBTables = () => {
    setLoadingDBTables(true);
    WebsiteService.getDatabaseInfo({ website_slug: stagingWebsite.slug })
      .then(response => {
        const dbTables = response.tables; // Table name to size in bytes.
        const options = [];
        for (const table in dbTables) {
          options.push({ name: table, label: `${table} (${StringHelper.convertToHumanReadableSize(dbTables[table], 'B')})`, size: dbTables[table]});
        }
        setAllDBTableOptions(options.sort((a, b) => b.size - a.size));
      })
      .finally(() => setLoadingDBTables(false));
  };

  const fetchActiveRequests = (ignoreGoToStep, syncRequested) => {
    if (!mounted.current) {
      window.logHelper.info('Component is unmounted. Skipping fetch requests.');
      return;
    }
    setLoadingRequests(true);
    syncRequested = syncRequested || false;
    ignoreGoToStep = ignoreGoToStep || false;
    const data = {
      website_slug: liveWebsite.slug,
      limit: 1,
    };
    WebsiteService.fetchStagingSyncRequests(data).then(requests => {
      const newest = ArrayHelper.first(requests);
      window.logHelper.info(`Fetched latest request:`, newest);
      // Save the very first request in order to make sure we are showing the correct request's
      // data and avoid race conditions. For example: if the user hits "Sync Now" and the request
      // doesn't start in the backend, there is a possibility that when fetching requests, it will
      // show the previous request as the newest one. In this case, we need to ignore it.
      if (!prevRequest) {
        window.logHelper.info('Updated the previous request.');
        setPrevRequest(newest ? newest : { guid: '' }); // Guarantee it is always set on first request.
      }
      // If the user requested sync but we are getting the previous request, then we need to wait
      // for the new request to be created.
      if (syncRequested && newest && prevRequest && newest.guid === prevRequest.guid) {
        window.logHelper.info('Latest request is not the expected one: our request not started yet.');
        return waitAndRefreshActiveRequests(syncRequested, false);
      }
      // If no requests yet, then do nothing if the user has not requested a sync.
      // However, if the user has requested a sync, wait for the request to be created as the
      // job could still be queued in the background and has not started yet.
      if (!newest) {
        window.logHelper.info('No requests yet. Waiting for the request to be created.');
        return syncRequested ? waitAndRefreshActiveRequests(syncRequested, false) : null;
      }
      // If the user hasn't requested a sync, we need to check the latest request's status.
      if (!syncRequested) {
        if (newest.status === 'running') {
          // If it is in running, then we treat it as if the user has requested a sync, allowing
          // the user to go to step 2 to continue seeing the progress after a page refresh.
          syncRequested = true;
          setSyncScheduled(true);
          window.logHelper.info('A previous running job detected.');
        } else {
          // If the latest request is not in running state, then we do nothing as it is nothing but
          // a previous request that has already finished.
          window.logHelper.info('Latest request already finished.');
          return;
        }
      }
      // Set the newest request as the active request and the user settings from it.
      window.logHelper.info('Updated the active request.');
      setActiveRequest(newest);
      setUserTypes(newest.data_types);
      // If the job is still running, wait and refresh the active requests.
      if (newest.status === 'running') {
        waitAndRefreshActiveRequests(syncRequested, true);
      }
      // Go to step 2 if indicated.
      if (!ignoreGoToStep) {
        goToStep(2);
      }
    }).catch(err => {
      window.logHelper.error('Failed to fetch requests:', getErrorMsg(err));
      window.logHelper.info('Retrying to fetch requests after failure...');
      waitAndRefreshActiveRequests(syncRequested, false);
    }).finally(() => setLoadingRequests(false));
  }

  const waitAndRefreshActiveRequests = (syncRequested, ignoreGoToStep) =>
    setTimeout(() => fetchActiveRequests(ignoreGoToStep, syncRequested), 10000);

  const scheduleSync = () => {
    setLoadingProcess(true);
    setSyncScheduled(true);
    window.logHelper.info('Scheduling sync request...');
    const data = {
      website_slug: liveWebsite.slug,
      staging_slug: stagingWebsite.slug,
      data_types: userTypes,
      db_include_tables: includeDBTableUserOptions,
      db_exclude_tables: excludeDBTableUserOptions,
    };
    WebsiteService.syncStagingToLive(data)
      .then(() => fetchActiveRequests(false, true))
      .finally(() => setLoadingProcess(false))
      .catch((err) => {
        dispatch(setGlobalErrorMsg(err));
        setSyncScheduled(false);
      });
  }

  // ------------------
  // WARNINGS
  // ------------------

  const getPreSyncWarnings = (ignoreTypes) => {
    let warnings = [];
    if (userTypes.includes('database') || ignoreTypes) {
      for (const activePlugin of liveWebsite.active_plugins) {
        if (PLUGIN_WARNINGS.some(warning => activePlugin.includes(warning))) {
          warnings.push({
            type: 'database',
            message: PLUGIN_WARNING_MESSAGE,
            tooltip: PLUGIN_WARNING_TOOLTIP,
          });
          break;
        }
      }
    }
    const numOfStagingSites = allWebsites.filter(item => item.parent_slug === liveWebsite.slug).length;
    if (numOfStagingSites > 1) {
      warnings.push({
        type: 'any',
        message: <span>You have {numOfStagingSites} staging sites and now you are syncing <strong>{stagingWebsite.slug}</strong>. Ensure that you are syncing the correct one.</span>,
      });
    }
    return warnings;
  };

  // ------------------
  // CONFIG DIFFS
  // ------------------

  // Given a config key, get the actual value from the options. Used to get the real "default" option
  // in order to compare it with the actual value.
  const getActualStaticConfigValue = (options, key, value) => {
    value = isUndefined(value) ? 'default' : value;
    const inputOption = options.find(item => item.name === key);
    if (!inputOption) {
      window.logHelper.warning(`Failed to get ${key} value because the options do not exist.`, options);
      return false;
    } else if (!inputOption.options) {
      window.logHelper.warning(`Failed to get ${key} value because it is not a select input.`, inputOption);
      return false;
    }
    // If the value is "default" and the option has a default value, return it.
    if (value === 'default') {
      if (inputOption.default) { 
        window.logHelper.debug(`Returning the default value for ${key}: ` + inputOption.default, inputOption);
        return inputOption.default;
      }
    }
    // If the option exists, get the value.
    const option = inputOption.options.find(item => item.value === value);
    if (option && option.value) {
      return option.value;
    }
    // Otherwise, return the first option.
    window.logHelper.debug(`Returning the first option for ${key} because the value "${value}" does not exist.`, inputOption);
    return inputOption.options[0].value;
  }

  // Calculates the differences between two static configs. If the default value is not an option, it will
  // guess the default value by getting the first option for precise comparison.
  const _calcStaticConfigDiffs = (allOptions, srcConfig, dstConfig, srcName) => {
    const dstName = srcName === 'staging' ? 'live' : 'staging';
    let diffs = [];
    for (const key of allOptions.map(item => item.name)) {
      const srcValue = getActualStaticConfigValue(allOptions, key, srcConfig[key]);
      const dstValue = getActualStaticConfigValue(allOptions, key, dstConfig[key]);
      if (srcValue === dstValue) continue;
      const optionConfig = allOptions.find(item => item.name === key);
      if (!optionConfig) {
        window.logHelper.warning(`Failed to get ${key} label because the option does not exist.`, allOptions);
        continue;
      } else if (!optionConfig.options) {
        window.logHelper.warning(`Failed to get ${key} label because it is not a select input.`, optionConfig);
        continue;
      }
      const srcInputOption = optionConfig.options.find(item => item.value === srcValue);
      if (!srcInputOption) {
        window.logHelper.warning(`Failed to get ${key} label because the src input option does not exist.`, optionConfig);
        continue;
      }
      const dstInputOption = optionConfig.options.find(item => item.value === dstValue);
      if (!dstInputOption) {
        window.logHelper.warning(`Failed to get ${key} label because the dst input option does not exist.`, optionConfig);
        continue;
      }
      diffs.push({
        key,
        keyLabel: optionConfig.title,
        srcLabel: srcInputOption.label,
        dstLabel: dstInputOption.label,
        srcValue,
        dstValue,
        src: srcName,
        dst: dstName,
      });
    }
    return diffs;
  }

  const _calcDynamicConfigDiffs = (srcConfig, dstConfig, srcName, excludeKeys) => {
    excludeKeys = excludeKeys || [ 'slug' ];
    const dstName = srcName === 'staging' ? 'live' : 'staging';
    // Both are expected to be array of objects.
    if (!isArray(dstConfig) || !isArray(srcConfig)) {
      window.logHelper.warning(`Failed to compare dynamic config because it is not an array.`, { srcConfig, dstConfig });
      return [];
    }
    // For dynamic config keys, get all objects that exist in src but not in dst.
    let diffs = [];
    for (const key in srcConfig) {
      const srcItem = srcConfig[key];
      if (!isObject(srcItem)) {
        window.logHelper.warning(`Failed to compare src item because it is not an object.`, srcItem);
        continue;
      }
      // Check if the item exists in dst.
      const existsInDst = dstConfig.some(dstItem => {
        if (!isObject(dstItem)) {
          window.logHelper.warning(`Failed to compare dst item because it is not an object.`, dstItem);
          return false;
        }
        let found = true;
        for (const dstItemKey of Object.keys(dstItem)) {
          if (excludeKeys && excludeKeys.includes(dstItemKey)) {
            continue;
          } else if (dstItem[dstItemKey] !== srcItem[dstItemKey]) {
            found = false;
            break;
          }
        }
        return found;
      });
      // If the item does not exist in dst, add it to the diffs.
      if (!existsInDst) {
        diffs.push({...srcItem, src: srcName, dst: dstName});
      }
    }
    return diffs;
  }

  // Calculates the differences between two nginx configs.
  const calcNginxDiffs = () => {
    const options = WebsiteHelper.getNginxConfigOptions();
    const stagingConfig = WebsiteHelper.getNginxConfig(stagingWebsite);
    const liveConfig = WebsiteHelper.getNginxConfig(liveWebsite);
    let diffs = [];
    diffs.push(..._calcStaticConfigDiffs(options, stagingConfig, liveConfig, 'staging'));
    return diffs;
  }

  // Calculates the differences between two php configs.
  const calcPhpDiffs = () => {
    const options = WebsiteHelper.getPhpConfigOptions();
    const stagingConfig = WebsiteHelper.getPHPConfig(stagingWebsite);
    const liveConfig = WebsiteHelper.getPHPConfig(liveWebsite);
    let diffs = [];
    diffs.push(..._calcStaticConfigDiffs(options, stagingConfig, liveConfig, 'staging'));
    return diffs;
  }

  // Calculates the differences between two object cache configs.
  const calcObjectCacheDiffs = () => {
    const options = WebsiteHelper.getRedisObjectCacheConfigOptions();
    const stagingConfig = WebsiteHelper.getObjectCacheConfig(stagingWebsite);
    const liveConfig = WebsiteHelper.getObjectCacheConfig(liveWebsite);
    let diffs = [];
    diffs.push(..._calcStaticConfigDiffs(options, stagingConfig, liveConfig, 'staging'));
    return diffs;
  }

  // Calculates the differences between two cron jobs configs.
  const calcCronJobDiffs = () => {
    const stagingCronJobs = WebsiteHelper.getCronJobs(stagingWebsite);
    const liveCronJobs = WebsiteHelper.getCronJobs(liveWebsite);
    let diffs = [];
    diffs.push(..._calcDynamicConfigDiffs(stagingCronJobs, liveCronJobs, 'staging'));
    diffs.push(..._calcDynamicConfigDiffs(liveCronJobs, stagingCronJobs, 'live'));
    return diffs;
  }

  // Calculates the differences between two wp config configs.
  const calcWpConfigDiffs = () => {
    const stagingWpDefines = WebsiteHelper.getWPDefines(stagingWebsite);
    const liveWpDefines = WebsiteHelper.getWPDefines(liveWebsite);
    let diffs = [];
    diffs.push(..._calcDynamicConfigDiffs(stagingWpDefines, liveWpDefines, 'staging'));
    diffs.push(..._calcDynamicConfigDiffs(liveWpDefines, stagingWpDefines, 'live'));
    return diffs;
  }

  // Calculates the differences between two url redirects configs.
  const calcUrlRedirectDiffs = () => {
    const stagingUrlRedirects = WebsiteHelper.getURLRedirects(stagingWebsite);
    const liveUrlRedirects = WebsiteHelper.getURLRedirects(liveWebsite);
    let diffs = [];
    diffs.push(..._calcDynamicConfigDiffs(stagingUrlRedirects, liveUrlRedirects, 'staging', [ 'slug', 'order' ]));
    diffs.push(..._calcDynamicConfigDiffs(liveUrlRedirects, stagingUrlRedirects, 'live', [ 'slug', 'order' ]));
    return diffs;
  }

  // Calculates the differences between configs.
  const _calcDiffs = () => {
    let diffs = [];
    diffs.push({ name: 'Nginx', type: 'static', diffs: calcNginxDiffs() });
    diffs.push({ name: 'PHP', type: 'static', diffs: calcPhpDiffs() });
    diffs.push({ name: 'Object Cache', type: 'static', diffs: calcObjectCacheDiffs() });
    diffs.push({ name: 'Cron Jobs', type: 'dynamic', diffs: calcCronJobDiffs() });
    diffs.push({ name: 'WP Config', type: 'dynamic', diffs: calcWpConfigDiffs() });
    diffs.push({ name: 'URL Redirects', type: 'dynamic', diffs: calcUrlRedirectDiffs() });
    return diffs;
  }

  // Renders a static items. Static items are found in both staging and live equally.
  // We don't expect to have an item found in staging but not in live or vice versa.
  const renderStaticItems = (data) => {
    data = data || {};
    data.diffs = data.diffs || [];
    return data.diffs.filter(item => item.src === 'staging').map((item, index) => <div className='staging-diff-item' key={index}>
      <strong>{item.keyLabel}</strong> is set to <strong>{item.srcLabel}</strong> on {item.src} and <strong>{item.dstLabel}</strong> on {item.dst}.
    </div>);
  };

  // Checks whether a dynamic item exists in an array of items.
  const dynamicItemExists = (items, compareFields, item) => {
    for (const compareField of compareFields) {
      const found = items.find(i => i[compareField] === item[compareField]);
      if (!found) {
        return false;
      }
    }
    return true;
  }

  // Renders a dynamic item.
  const renderDynamicItem = (data, compareFields, type, valueFn) => {
    const stagingItems = data.diffs.filter(item => item.src === 'staging');
    const liveItems = data.diffs.filter(item => item.src === 'live');
    const onlyInStaging = stagingItems.filter(item => !dynamicItemExists(liveItems, compareFields, item));
    const onlyInLive = liveItems.filter(item => !dynamicItemExists(stagingItems, compareFields, item));
    const inBoth = stagingItems.filter(item => dynamicItemExists(liveItems, compareFields, item));
    return (
      <Fragment>
        {!isEmptyOrNull(onlyInStaging) && onlyInStaging.map((item, index) => <div className={'staging-diff-item'} key={index}>
          {type} <strong>{valueFn(item)}</strong> is only set on staging.
        </div>)}
        {!isEmptyOrNull(onlyInLive) && onlyInLive.map((item, index) => <div className={'staging-diff-item'} key={index}>
          {type} <strong>{valueFn(item)}</strong> is only set on live.
        </div>)}
        {!isEmptyOrNull(inBoth) && inBoth.map((item, index) => <div className={'staging-diff-item'} key={index}>
          {type} <strong>{valueFn(item)}</strong> is different on staging and live.
        </div>)}
      </Fragment>
    );
  }

  const renderCategoryHeader = (data) => {
    const hasDiffs = !isEmptyOrNull(data.diffs);
    const icon = hasDiffs ? 'warning' : 'success';
    const tooltip = hasDiffs ? 'Configuration is different on both sites.' : 'Configuration is the same on both sites.';
    return <div>{JsxHelper.createIcon({icon, color: icon, tooltip})} {data.name}</div>;
  }

  const renderCategoryDiffs = (data) => {
    if (isEmptyOrNull(data.diffs)) {
      return <div className='staging-diff-item'>No differences found.</div>;
    } else if (data.type === 'static') {
      return renderStaticItems(data);
    } else if (data.name === 'Cron Jobs') {
      return renderDynamicItem(data, ['command'], 'Command', (item) => item.command);
    } else if (data.name === 'WP Config') {
      return renderDynamicItem(data, ['name'], 'Constant', (item) => item.name);
    } else if (data.name === 'URL Redirects') {
      return renderDynamicItem(data, ['from', 'to'], 'Rule', (item) => `${item.from} ${JsxHelper.ARROW_SYMBOL} ${item.to}`);
    }
  }

  const renderObjectDiffs = () => <div>
    {Object.keys(CONFIG_DIFFS).map(key => CONFIG_DIFFS[key]).map((categoryData) => (
      <div key={categoryData.name} className='category-wrapper'>
        <h4>{renderCategoryHeader(categoryData)}</h4>
        <ul style={{margin: '10px'}}>{renderCategoryDiffs(categoryData)}</ul>
      </div>
    ))}
  </div>

  const CONFIG_DIFFS = _calcDiffs();

  // ------------------
  // STEPPERS
  // ------------------

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(1);
  const [disabledStepsData, setDisabledStepsData] = useState([]);
  const disabledSteps = useRef([]);
  const stepperRef = useRef();
  
  const initStep = stepNum => {
    for (var i = 1; i <= STEPS_NUM; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        step.classList.add('active-color');
      } else if (!disabledSteps.current.includes(stepIndex)) {
        step.classList.add('complete-color');
      }
    }
    setCurrentStepForData(stepNum);
  };

  // eslint-disable-next-line
  const STEPPER_STEPS = [
    {
      title: 'Select Data',
      onClick: () => initStep(1),
    },
    {
      title: 'Sync Progress',
      onClick: () => initStep(2),
    },
    {
      title: 'Review & Finish',
      onClick: () => initStep(3),
    },
  ];

  const goToStep = newStep => {
    window.logHelper.info(`Going to step ${newStep}.`);
    // Remember: newStep is 1-based index but the stepper works with 0-based indexes.
    const allIndexesArray = Array.from(Array(STEPS_NUM).keys()); // [0, 1, ..., STEPS_NUM - 1]
    disabledSteps.current = allIndexesArray.filter(step => step > newStep - 1);
    setDisabledStepsData(disabledSteps.current);
    setCurrentStep(newStep - 1);
    initStep(newStep);
  }

  const renderEventLogNoticeBox = () => {
    let content = '';
    let level = 'notice';
    if (activeRequest.status === 'running') {
      content = 'The sync is currently running. Please wait for it to finish.';
    } else if (activeRequest.status === 'failed') {
      content = 'The sync has failed. Please try again or contact support.';
      level = 'error';
    } else {
      return null;
    }
    return JsxHelper.createBox({ content, level, style: { marginBottom: '15px' } });
  }

  const renderReviewNoticeBox = () => {
    if (CONFIG_DIFFS.some(item => !isEmptyOrNull(item.diffs))) {
      return JsxHelper.createBox({
        content: 'Configuration is not auto-synced; you need to manually update them on the live site.',
        level: 'warning',
        showLevel: true,
        style: { marginBottom: '15px' },
      });
    }
    return null;
  }

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title>Sync Staging To Live</TitleBar.Title>
      </TitleBar>
      <Content style={{ position: 'relative', paddingTop: '2px' }}>
        <div className='create-site-stepper' ref={stepperRef}>
          {JsxHelper.createStepper(STEPPER_STEPS, currentStep, disabledStepsData)}
        </div>
        <div style={{ maxWidth: '1000px', marginTop: '25px' }}>
          {currentStepForData === 1 && (
            <WPSForm style={FORM_STYLE}>
              <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px', marginTop: '0px' }}>
                <legend>What data do you want to push?</legend>
                <WPSForm.Row className='margin-top-20'>
                  <WPSForm.RowItem className='custom-form-row-item' style={{minHeight: 0, maxWidth: 'none'}}>
                    <Fragment>
                      {/* Notice */}
                      {JsxHelper.createBox({
                        level: 'notice',
                        content: 'Before initiating the process, we will create backups of the live and staging websites.'
                      })}
                      {/* Warnings */}
                      {getPreSyncWarnings().map((warning) => JsxHelper.createBox({
                        content: <span>{warning.message}</span>,
                        level: 'warning',
                        tooltip: warning.tooltip,
                        showLevel: true,
                      }))}
                      {/* Description */}
                      <p style={{marginTop: '15px'}}>Select the data you want to push to the live website:</p>
                    </Fragment>
                  </WPSForm.RowItem>
                </WPSForm.Row>
                <WPSForm.Row className='margin-top-20'>
                  <WPSForm.RowItem className='custom-form-row-item'>
                    <Fragment>
                      {JsxHelper.createCheckbox({
                        name: 'database',
                        class: 'staging-checkbox',
                        label: BACKUP_TYPES['database'],
                        checked: userTypes.includes('database'),
                        disabled: !isEmptyOrNull(activeRequest),
                        onChange: onTypeChange,
                      })}
                      {userTypes.includes('database') && <div className='db-extra-input'>
                        {!loadingDBTables ? <Fragment>
                          {isEmptyOrNull(excludeDBTableUserOptions) && JsxHelper.createSelectInput({
                            name: 'include_tables',
                            label: 'Tables',
                            tooltip: WebsiteHelper.tooltips.db_include_tables,
                            multiSelect: true,
                            options: ArrayHelper.buildSelectOptions(allDBTableOptions, 'label', 'name'),
                            disabled: !isEmptyOrNull(activeRequest),
                            value: includeDBTableUserOptions,
                            sortOff: true,
                            isSearchable: true,
                            onChange: e => {
                              const values = e.target.values;
                              setIncludeDBTableUserOptions(values.map(item => item.value));
                              setExcludeDBTableUserOptions([]);
                            }
                          })}
                          {isEmptyOrNull(includeDBTableUserOptions) && JsxHelper.createSelectInput({
                            name: 'exclude_tables',
                            label: 'Exclude Tables',
                            tooltip: WebsiteHelper.tooltips.db_exclude_tables,
                            multiSelect: true,
                            options: ArrayHelper.buildSelectOptions(allDBTableOptions, 'label', 'name'),
                            disabled: !isEmptyOrNull(activeRequest) || !isEmptyOrNull(includeDBTableUserOptions),
                            value: excludeDBTableUserOptions,
                            sortOff: true,
                            isSearchable: true,
                            onChange: e => {
                              const values = e.target.values;
                              setExcludeDBTableUserOptions(values.map(item => item.value));
                              setIncludeDBTableUserOptions([]);
                            }
                          })}
                        </Fragment> : <div className='staging-extra-loading'>Loading database tables...</div>}
                      </div>}
                    </Fragment>
                    {Object.keys(BACKUP_TYPES).filter(type => type !== 'database').map((value, index) => {
                      return JsxHelper.createCheckbox({
                        name: value,
                        class: 'staging-checkbox',
                        label: BACKUP_TYPES[value],
                        checked: userTypes.includes(value),
                        disabled: !isEmptyOrNull(activeRequest),
                        onChange: onTypeChange,
                      }, index)
                    })}
                  </WPSForm.RowItem>
                </WPSForm.Row>
              </WPSForm.Fieldset>
              <WPSForm.Row className='margin-top-12'>
                {JsxHelper.createButton({
                  label: 'Sync Now',
                  onClick: scheduleSync,
                  // Wait until the loading process finishes or until we have an active request to be shown in next step.
                  loading: loadingProcess || (syncScheduled && isEmptyOrNull(activeRequest)),
                  // Disable if user hasn't selected any data, if no active request yet, user synced before (we don't
                  // expect users to do multiple syncs), or requests are being loaded (handles the first request when
                  // this page opens).
                  disabled: userTypes.length === 0 || !isEmptyOrNull(activeRequest) || syncScheduled || loadingRequests,
                })}
              </WPSForm.Row>
            </WPSForm>
          )}
          {currentStepForData === 2 && (
            <WPSForm style={FORM_STYLE}>
              {renderEventLogNoticeBox()}
              <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px', marginTop: '0px' }}>
                <legend>Event Log</legend>
                {JsxHelper.createEventLogTable(activeRequest.timeline || [], timezone)}
              </WPSForm.Fieldset>
              <WPSForm.Row className='margin-top-12'>
                {activeRequest.status === 'completed' && JsxHelper.createButton({
                  label: 'Review',
                  onClick: () => goToStep(3),
                })}
                {activeRequest.status === 'failed' && JsxHelper.createButton({
                  label: 'Retry',
                  onClick: scheduleSync,
                  loading: loadingProcess,
                })}
              </WPSForm.Row>
            </WPSForm>
          )}
          {currentStepForData === 3 && (
            <WPSForm style={FORM_STYLE}>
              {renderReviewNoticeBox()}
              <WPSForm.Fieldset className='display-block staging-review' style={{ marginLeft: '2px', marginTop: '0px' }}>
                <legend>Configuration</legend>
                <WPSForm.Row>
                  {renderObjectDiffs()}
                </WPSForm.Row>
              </WPSForm.Fieldset>
              <WPSForm.Row className='margin-top-12'>
                <div className='display-flex action-buttons'>
                  {JsxHelper.createButton({
                    label: 'Delete Staging',
                    loading: loadingDelete,
                    onClick: onDeleteStaging,
                  })}
                  {JsxHelper.createButton({
                    label: 'Go To Live Site',
                    classes: 'info--btn',
                    onClick: () => WebsiteHelper.goTo(history, liveWebsite.slug),
                  })}
                </div>
              </WPSForm.Row>
            </WPSForm>
          )}
        </div>
      </Content>
    </Container>
  );
};

export default PushStaging;
