import React from 'react';
import Package from 'components/website/packageManager';

const Plugins = ({ website,refresh, setCustomBackBtn }) => {
  return (
    <Package
      website={website}
      refresh={refresh}
      setCustomBackBtn={setCustomBackBtn}
      type="plugin"
    ></Package>
  )
};

export default Plugins;
