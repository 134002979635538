import React, { Fragment } from 'react';
import { TitleBar } from '../../styles/layout/titlebar';
import CreateRegion from '../../components/region/create';
import { useRouteMatch } from 'react-router-dom';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const RegionUpdate = () => {
  useTitle('Region update');
  const { params } = useRouteMatch();

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Regions',
      link: '/regions',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Update</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <CreateRegion slug={params.slug} />
      </Content>
    </Fragment>
  );
};

export default RegionUpdate;
