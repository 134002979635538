import styled from 'styled-components';

export const Container = styled.section`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const TabsList = styled.ul`
  width:100%;
  height: 45px;
  display: flex;
  background: #E0E9FF;
  li {
    position: relative;
    display: flex;
    margin-bottom: -1px;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    background: #E0E9FF;
    &::after {
      content: '';
      display: none;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background: white;
    }
    &.active {
      background: white;
      &::after {
        display: block;
      }
      border: 2px solid #E0E9FF;
      border-bottom: none;
    }
  }
`;

export const TabsPanel = styled.div`
  border: 2px solid #E0E9FF;
  padding: 16px;
  background: white;
`;
