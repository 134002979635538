import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useRouteMatch, Switch, Route, useHistory } from 'react-router-dom';
import { TitleBar } from 'styles/layout/titlebar';
import { ProfilePage } from 'styles/website/profile';
import { useSelector, useDispatch } from 'react-redux';
import { activeWebsite, websitesSelector } from 'store/website/websiteSelectors';
import Domains from './domains';
import DNS from './dns-zone';
import Plugins from './plugins';
import Themes from './themes';
import Cache from './cache';
import Cdn from './cdn';
import CdnSettings from './cdn-settings';
import CloudflareZoneDetails from './cloudflare-details';
import WebsiteGoLive from './go-live';
import WebsiteBackups from './backups';
import WebsiteAlerts from './alerts';
import ImportSQL from './import-sql';
import Redirects from './redirects';
import WebsiteSSL from './ssl';
import WebsiteSSL2 from './ssl2';
import WebsiteReview from './review';
import Debug from './debug';
import TrafficAnalytics from './analytics-traffic';
import BannedIPs from './banned-ips';
import HideLogin from './hide-login';
import PHPAnalytics from './analytics-php';
import { createPartnerWebsiteStaging } from 'store/website/websiteActions';
import Activity from './activity';
import Account from './account';
import WebsiteStatus from 'components/website/status';
import Users from './users';
import WebsiteHelper from 'helpers/website';
import JsxHelper from 'helpers/jsx';
import UserHelper from 'helpers/user';
import ExecuteCommand from 'components/website/advanced/wordpressCLI';
import WPDefines from 'components/website/advanced/wpDefines';
import NginxConfig from 'components/website/advanced/nginxConfig';
import SMTPMailer from 'components/website/advanced/smtpMailer';
import CronManager from 'components/website/advanced/cronManager';
import SearchReplace from 'components/website/advanced/searchReplace';
import DatabaseSearch from 'components/website/databaseSearch';
import DatabaseRunQueries from 'components/website/databaseRunQueries';
import RedisObjectCache from 'components/website/redisObjectCache';
import PushStaging from 'components/website/pushStaging';
import DatabaseStatistics from 'components/website/databaseStatistics';
import PHPConfig from 'components/website/advanced/phpConfig';
import FileManager from 'components/website/fileManager/fileManager';
import SftpAccess from 'components/website/sftpAccess';
import WebsiteOverview from 'components/website/WebsiteOverview/WebsiteOverview';
import UpgradeWordpress from './upgrade-wordpress';
import { setSecondarySidebar } from 'store/global/globalActions';
import SecondaryNav from 'components/layout/sPanelSecondaryNav';
import { SecondNav } from 'styles/layout/sidebar';
import { WPSButton } from 'styles/layout/buttons';
import { useForm } from 'react-hook-form';
import DialogHelper from 'helpers/dialog';
import env from 'config/env';
import WebsiteOptionsTrait from 'components/website/CreateWebsiteOptions/WebsiteOptionsTrait';
import { getErrorMsg } from 'helpers';

const Profile = () => {
  const { slug } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const website = useSelector(activeWebsite(slug));
  const allWebsites = useSelector(websitesSelector);
  const [inputError, setInputError] = useState(null);
  const [stagingSlug, setStagingSlug] = useState(null);
  const [customBackBtn, setCustomBackBtn] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const showEnvSelector = history.location.pathname.endsWith('/overview');
  const [modalLoading, setModalLoading] = useState(false);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  // Handle secondary sidebar view
  useEffect(() => {
    dispatch(setSecondarySidebar(true));
    if (history.location.pathname.endsWith(slug)) {
      history.replace(`/websites/${slug}/overview`, null);
    }
    return () => {
      dispatch(setSecondarySidebar(false));
    };
    // eslint-disable-next-line
  }, [dispatch, website]);

  const sidebarLinks = [{
    title: `${env.getBrandShortName()} Panel`,
    solidLink: '/overview',
  }].concat(WebsiteHelper.staqPanelItems).filter(group => !!group);

  const getLiveSlug = () => website.is_staging ? website.parent_slug : website.slug;

  const stagingExists = () => {
    // Loading means the API hasn't returned the app yet. However, the app can be created in the backend before the API returns.
    // This can lead to the Recent Updates fetching the staging app before the API returns it. In that case, this can lead to
    // mistakenly showing the warning box. To prevent this, we don't consider the staging app as existing if still loading.
    const liveSlug = getLiveSlug();
    return !modalLoading
      ? allWebsites.some(w => w.parent_slug === liveSlug)
      : allWebsites.some(w => w.parent_slug === liveSlug && w.slug !== stagingSlug);
  }

  const renderStagingHeader = () => {
    const phpWarning = WebsiteOptionsTrait.getCloneWarningIfOriginHasPremiumConfig(website.slug, allWebsites);
    return <Fragment>
      {stagingExists() && JsxHelper.createBox({
        content: <span><strong>WARNING</strong>: This is not your first staging environment, and additional charges may apply, depending on your plan.</span>,
      })}
      {phpWarning && JsxHelper.createBox({
        content: <span><strong>WARNING</strong>: {phpWarning}</span>,
      })}
      {inputError && JsxHelper.createBox({
        content: <span><strong>ERROR</strong>: {inputError}</span>,
        level: 'error',
      })}
    </Fragment>
  }
    
  const onClickNewStaging = () => setStagingSlug(`${getLiveSlug()}-staging`);

  const onClickPushToLive = () => WebsiteHelper.goTo(history, website.slug, 'push-staging');

  const onSubmitStaging = () => {
    setModalLoading(true);
    const data = {
      website_slug: getLiveSlug(),
      staging_slug: stagingSlug,
    }
    dispatch(createPartnerWebsiteStaging(data))
      .then(() => {
        WebsiteHelper.goTo(history, stagingSlug);
        setStagingSlug(null);
      })
      .catch(err => setInputError(getErrorMsg(err)))
      .finally(() => setModalLoading(false));
  };

  const envSelectOptions = () => {
    const live = website.is_staging ? allWebsites.find(w => w.slug === website.parent_slug) : website;
    if (!live) {
      return []; // If an employee user has access only to the staging site.
    }
    const stagings = allWebsites.filter(w => w.parent_slug === live.slug);
    return [
      { value: live.slug, label: 'Live', sign: JsxHelper.BULLET_SYMBOL, class: 'live', onClick: () => WebsiteHelper.goTo(history, live.slug) },
      ...stagings.map(w => ({ value: w.slug, label: WebsiteHelper.getLabel(w), sign: JsxHelper.BULLET_SYMBOL, class: 'staging', onClick: () => WebsiteHelper.goTo(history, w.slug) })),
      !website.is_staging ? { value: '#', label: 'Create Staging Site', class: 'create', sign: '+', onClick: onClickNewStaging } : false,
      website.is_staging ? { value: '#', label: 'Sync To Live', sign: '>', class: 'sync', onClick: onClickPushToLive } : false,
    ].filter(Boolean)
  }

  const handleBackBtnClick = e => {
    e.preventDefault();
    if (!customBackBtn) {
      history.goBack();
    } else {
      doRefresh(prev => prev + 1);
      setCustomBackBtn(false);
    }
  };

  const handleCustomBackBtn = setCustomBackBtn;

  return (
    <ProfilePage>
      {website && <SecondNav className='custom-nav-list'>
        <div>
          {sidebarLinks.map((link, index) => (
            <SecondaryNav
              key={link.title + index + index}
              link={link}
              index={index}
              website={website}
            />
          ))}
        </div>
      </SecondNav>}

      {website && (
        <Fragment>
          <TitleBar>
            <WebsiteStatus website={website} />
            <TitleBar.Actions>
              {showEnvSelector && JsxHelper.createDropdownButton({
                classes: `ws-env-selector ${website.is_staging ? 'alt' : 'success'}--btn`,
                options: envSelectOptions(website, onClickNewStaging),
                label: `${website.is_staging ? 'Staging' : 'Live'} Environment`,
              })}
              <WPSButton className='back--btn' onClick={handleBackBtnClick}>Back</WPSButton>
            </TitleBar.Actions>
          </TitleBar>
          <hr />
          <Switch>
            <Route path={`${path}/overview`}>
              <WebsiteOverview website={website} />
            </Route>
            <Route exact path={`${path}/domains`}>
              <Domains website={website} />
            </Route>
            <Route exact path={`${path}/go-live`}>
              <WebsiteGoLive website={website} />
            </Route>
            <Route path={`${path}/dns-zone`}>
              <DNS website={website} />
            </Route>
            <Route exact path={`${path}/plugins`}>
              <Plugins website={website} refresh={refresh} setCustomBackBtn={handleCustomBackBtn} />
            </Route>
            <Route exact path={`${path}/plugins/:name/file-manager`}>
              <FileManager
                website={website}
                refresh={refresh}
                setCustomBackBtn={handleCustomBackBtn}
              />
            </Route>
            <Route exact path={`${path}/themes`}>
              <Themes website={website} refresh={refresh} setCustomBackBtn={handleCustomBackBtn} />
            </Route>
            <Route exact path={`${path}/themes/:name/file-manager`}>
              <FileManager
                website={website}
                refresh={refresh}
                setCustomBackBtn={handleCustomBackBtn}
              />
            </Route>
            <Route exact path={`${path}/sftp-access`}>
              <SftpAccess slug={website.slug} />
            </Route>
            <Route exact path={`${path}/import-sql`}>
              <ImportSQL slug={website.slug} />
            </Route>
            <Route exact path={`${path}/cache`}>
              <Cache website={website} />
            </Route>
            <Route exact path={`${path}/upgrade-wordpress`}>
              <UpgradeWordpress website={website} />
            </Route>
            <Route exact path={`${path}/cdn`}>
              <Cdn website={website} />
            </Route>
            <Route exact path={`${path}/cdn/settings`}>
              <CdnSettings website={website} />
            </Route>
            <Route exact path={`${path}/cloudflare-details`}>
              <CloudflareZoneDetails website={website} />
            </Route>
            <Route exact path={`${path}/backups`}>
              <WebsiteBackups website={website} />
            </Route>
            <Route exact path={`${path}/alerts`}>
              <WebsiteAlerts website={website} />
            </Route>
            <Route path={`${path}/debug`}>
              <Debug website={website} />
            </Route>
            <Route path={`${path}/analytics-traffic`}>
              <TrafficAnalytics website={website} />
            </Route>
            <Route path={`${path}/banned-ips`}>
              <BannedIPs website={website} />
            </Route>
            <Route path={`${path}/hide-login`}>
              <HideLogin website={website} />
            </Route>
            <Route exact path={`${path}/analytics-php`}>
              <PHPAnalytics website={website} />
            </Route>
            <Route exact path={`${path}/advanced/php-config`}>
              <PHPConfig website={website} />
            </Route>
            <Route exact path={`${path}/advanced/redirects`}>
              <Redirects website={website} />
            </Route>
            <Route exact path={`${path}/advanced/ssl`}>
              <WebsiteSSL website={website} />
            </Route>
            <Route exact path={`${path}/advanced/ssl2`}>
              <WebsiteSSL2 website={website} />
            </Route>
            <Route exact path={`${path}/advanced/review`}>
              <WebsiteReview website={website} />
            </Route>
            <Route exact path={`${path}/advanced/wp-cli`}>
              <ExecuteCommand slug={website.slug} />
            </Route>
            <Route exact path={`${path}/advanced/wp-config`}>
              <WPDefines website={website} />
            </Route>
            <Route exact path={`${path}/advanced/smtp-mailer`}>
              <SMTPMailer scope='private' website={website} />
            </Route>
            <Route exact path={`${path}/advanced/cron-jobs`}>
              <CronManager website={website} />
            </Route>
            <Route exact path={`${path}/advanced/nginx-config`}>
              <NginxConfig website={website} />
            </Route>
            <Route exact path={`${path}/advanced/search-replace`}>
              <SearchReplace website={website} />
            </Route>
            <Route exact path={`${path}/advanced/search`}>
              <DatabaseSearch slug={website.slug} />
            </Route>
            <Route exact path={`${path}/advanced/tables`}>
              <DatabaseStatistics website={website} />
            </Route>
            <Route exact path={`${path}/database/run-sql-query`}>
              <DatabaseRunQueries slug={website.slug} />
            </Route>
            <Route exact path={`${path}/database/object-cache`}>
              <RedisObjectCache website={website}/>
            </Route>
            <Route exact path={`${path}/push-staging`}>
              <PushStaging stagingWebsite={website}/>
            </Route>
            <Route path={`${path}/activity-history`}>
              <Activity website={website} />
            </Route>
            {!UserHelper.isEmployee() && (
              <Route path={`${path}/users`}>
                <Users website={website} />
              </Route>
            )}
            <Route path={`${path}/account-billing`}>
              <Account website={website} />
            </Route>
          </Switch>
        </Fragment>
      )}

      {stagingSlug && DialogHelper.inputs({
        title: 'Create Staging Environment',
        icon: 'create',
        iconColor: 'success',
        btnText: 'Create',
        header: renderStagingHeader(),
        onConfirm: handleSubmit(onSubmitStaging),
        onClose: () => setStagingSlug(null),
        loading: modalLoading,
        register,
        inputs: [{
          name: 'label',
          label: 'Staging Website ID',
          placeholder: `Enter staging website ID`,
          value: stagingSlug,
          onChange: (e) => {
            setStagingSlug(e.target.value);
            setInputError(null);
          },
          errors,
          required: true,
        }]
      })}

    </ProfilePage>
  );
};

export default Profile;
