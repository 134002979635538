import React, { Fragment, useState, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import DetailsPartner from 'pages/billing/partner/details';
import env from '../../config/env';
import { useSelector } from 'react-redux';
import { integrationSelector } from 'store/me/meSelectors';
import StripeConnect from 'components/stripe/stripeconnect';
import StripeDisconnect from 'components/stripe/stripedisconnect';
import CustomerBilling from '../customer/billing';
import CustomerCreatePlan from 'components/plan/save';
import JsxHelper from 'helpers/jsx';

const CustomerBillingSetup = () => {
  const stepperRef = useRef();
  const integrations = useSelector(integrationSelector);

  const initStep = stepNum => {
    let iterationNumber = 4;

    for (var i = 1; i <= iterationNumber; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];

      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!refDisabledSteps.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add('complete-color');
      }
    }
    setCurrentStepForData(stepNum);
  };

  // eslint-disable-next-line
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Connect Stripe',
      onClick: () => initStep(1),
    },
    {
      title: 'Profile Details',
      onClick: () => initStep(2),
    },
    {
      title: 'Billing Options',
      onClick: () => initStep(3),
    },
    {
      title: 'Agency Plans',
      onClick: () => initStep(4),
    },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(1);
  const [disabledStepsData, setDisabledStepsData] = useState([1, 2, 3]);
  const refDisabledSteps = useRef([1, 2, 3]);

  const changeSteps = newIndex => {
    setCurrentStep(newIndex);
    let data = disabledStepsData;
    data.push(currentStep - 1);
    let fitleredSteps = data.filter(el => el !== newIndex - 1);
    setDisabledStepsData(fitleredSteps);
  };

  const moveToSecondStep = () => {
    changeSteps(2);
    refDisabledSteps.current = [2, 3, 4];
    setDisabledStepsData([2, 3, 4]);
    setCurrentStepForData(2);
    initStep(2);
  };
  const moveToThirdStep = () => {
    changeSteps(3);
    refDisabledSteps.current = [3, 4];
    setDisabledStepsData([3, 4]);
    setCurrentStepForData(3);
    initStep(3);
  };
  const moveToFourthStep = () => {
    changeSteps(4);
    refDisabledSteps.current = [];
    setDisabledStepsData([]);
    setCurrentStepForData(2);
    initStep(4);
  };

  const saveBillingDetails = () => {
    // Api request or redirect to Home page
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Client Billing Setup',
    },
  ];
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Client Billing Setup</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ position: 'relative' }}>
        <div
          className='create-site-stepper'
          style={{ left: '-22px', maxWidth: '800px' }}
          ref={stepperRef}>
          {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
        </div>
        <div style={{ maxWidth: '1000px', marginTop: '110px' }}>
          {currentStepForData === 1 && (
            <Fragment>
              <div style={{ maxWidth: '500px' }}>
                <p className='step-subheader'>
                  Easily and securely process online payments by connecting to your Stripe account.
                </p>
                {integrations.stripe ? (
                  <StripeDisconnect isCustomPage={true} onClickCustomPageNext={moveToSecondStep} />
                ) : (
                  <StripeConnect isCustomPage={true} onClickCustomPageNext={moveToSecondStep} />
                )}
              </div>
            </Fragment>
          )}
          {currentStepForData === 2 && (
            <Fragment>
              <p className='step-subheader'>
                {`Fill and review your business details that will appear on the invoices issued by ${env.getBrandShortName()}, in addition to invoices generated by your business to your clients if you wish to use the Client Billing feature offered by ${env.getBrandShortName()}.`}
              </p>
              <DetailsPartner isCustomPage={true} onSaveDetails={moveToThirdStep} />
            </Fragment>
          )}
          {currentStepForData === 3 && (
            <Fragment>
              <p className='step-subheader'>
                Set up the billing day to charge clients, currency, and the tax rate to add on
                their invoices.
              </p>
              <CustomerBilling isCustomPage={true} onUpdateSettings={moveToFourthStep} />
            </Fragment>
          )}
          {currentStepForData === 4 && (
            <Fragment>
              <p className='step-subheader'>
                Create the default plan for your clients by setting the base price, features and
                usage limits in order to start charging for recurring usage of services. You may
                create or update more client plans after the setup.
              </p>
              <CustomerCreatePlan onSavePlan={saveBillingDetails} />
            </Fragment>
          )}
        </div>
      </Content>
    </Fragment>
  );
};

export default CustomerBillingSetup;
