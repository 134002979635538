import React from 'react';
import ReactCountryFlag from "react-country-flag"
import { WPSBubble } from 'styles/layout/buttons';
import ReactTooltip from 'react-tooltip';
import { Fragment } from 'react';

const displayRowStatus = (row, type) => {
	let statusText = '';
	let statusColor = '';
	let tooltip = '';
	if (type === 'server') {
		statusColor = row.status === 'banned' ? 'danger' : (row.status === 'expired' ? 'primary' : 'info');
		statusText = row.status === 'banned' ? 'Banned' : (row.status === 'expired' ? 'Expired' : 'Not Spam');
		tooltip = row.reported_not_spam ? row.metadata.reported_not_spam_reason : '';
	} else {
		statusColor = !row.unbanned_at ? 'danger' : (row.unban_reason.includes('expired.') ? 'info' : 'primary');
		statusText = !row.unbanned_at ? 'Banned' : (row.unban_reason.includes('expired.') ? 'Expired' : 'Removed');
		tooltip = row.unban_reason || '';
	}

	return <Fragment>
		<WPSBubble
			style={{margin: '4px 0 0 3px'}}
			display='inline'
			padding='0'
			margin='0'
			data-for={row.id}
			data-tip={tooltip}
			color={statusColor}>
			{statusText}
		</WPSBubble>
		<ReactTooltip id={row.id} />
	</Fragment>
}

const displayCountryFlag = (row) => {
  if (!row.country_code && !row.country) {
    return 'Unknown';
  }
  return <ReactCountryFlag
    className='flag'
    countryCode={row.country_code || row.country}
    title={row.country_code || row.country}
    style={{ fontSize: '2em', lineHeight: '1.3em', }}
  />
}

const httpResponseToColor = status => {
	let color = 'info';
  status = status.toString();
	if (status.startsWith('2')) {
	  color = 'success';
	} else if (status.startsWith('3')) {
	  color = 'info';
	} else if (status.startsWith('4')) {
	  color = 'warning';
	} else if (status.startsWith('5')) {
	  color = 'danger';
	}
	return color;
};

const httpResponseToText = status => {
  let text = 'Unknown';
  status = status.toString();
  if (status.startsWith('2')) {
    text = 'Success';
  } else if (status.startsWith('3')) {
    text = 'Redirect';
  } else if (status === '444') {
    text = 'No Response';
  } else if (status === '499') {
    text = 'Client Closed Request';
  } else if (status.startsWith('4')) {
    text = 'Client Error';
  } else if (status.startsWith('5')) {
    text = 'Server Error';
  }
  return text;
};

const FirewallHelper = {
  displayCountryFlag,
  displayRowStatus,
  httpResponseToColor,
  httpResponseToText,
};
  
  export default FirewallHelper;
  