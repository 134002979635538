import { deleteRequest, getRequest, postRequest } from 'config/axiosRequest';
import env from 'config/env';

const list = data => {
  return getRequest({
    url: env.buildApiEndpoint('databases'),
    data,
  });
};

const refresh = data => {
  return getRequest({
    url: env.buildApiEndpoint(`databases/${data.slug}`),
    data,
  });
};

const destroy = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`databases/${data.slug}`),
    data,
  });
}

const attachWebsite = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/attach-database-async`),
    data,
  });
};

const detachWebsite = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/detach-database-async`),
    data,
  });
};

const DatabaseService = { list, refresh, destroy, attachWebsite, detachWebsite };

export default DatabaseService;
