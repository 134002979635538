import React from "react"
import Icon from '../../layout/icon';

const UploadPackage = props => {

  const handleOnInput = e => e.target.id === 'package_file' ? e.preventDefault() : props.onChange(e);
  const handleOnClick = id => document.getElementById(id).click()

  const wpUrl = 'https://wordpress.org';
  const type = props.options.package_type;
  const placeholder = 
    type === 'plugin' ? `${wpUrl}/plugins/example.zip` : 
    type === 'theme' ? `${wpUrl}/themes/example.zip` :
    `${wpUrl}/latest.zip`;

  return (
    <div className="file-uploader">
      <div className="option file-option">
        <Icon className="icon" tag='fileupload' />
        <span>Upload File</span>
        <button
          className="create-light--btn"
          onClick={() => handleOnClick('package_file')}>Browse
        </button>
        <input
          id='package_file'
          className='display-none'
          type='file'
          accept='.zip, application/zip, application/x-zip-compressed'
          onChange={props.onChange} />
      </div>
      <div className="option file-option">
        <span className='url-option'>...or upload via URL</span>
        <input
          className='download-url'
          id='download_url'
          placeholder={placeholder}
          onChange={handleOnInput} />
      </div>
    </div>
  )
};

export default UploadPackage;