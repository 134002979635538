import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import env from '../config/env';

// Reducers
import authReducer from './auth/authReducer';
import globalReducer from './global/globalReducer';
import meReducer from './me/meReducer';
import widgetReducer from './widget/widgetReducer';
import notificationReducer from './notification/notificationReducer';
import userReducer from './user/userReducer';
import customerReducer from './customer/customerReducer';
import regionReducer from './region/regionReducer';
import serverReducer from './server/serverReducer';
import websiteReducer from './website/websiteReducer';
import globalPackageReducer from './globalPackage/globalPackageReducer';
import partnerPackageReducer from './partnerPackage/partnerPackageReducer';
import billingReducer from './billing/billingReducer';
import customerBillingReducer from './customerBilling/customerBillingReducer';
import dnsZoneReducer from './dnsZone/dnsZoneReducer';
import platformReducer from './platform/platformReducer';
import settingReducer from './setting/settingReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  widgets: widgetReducer,
  notifications: notificationReducer,
  me: meReducer,
  users: userReducer,
  customers: customerReducer,
  regions: regionReducer,
  servers: serverReducer,
  websites: websiteReducer,
  global_packages: globalPackageReducer,
  partner_packages: partnerPackageReducer,
  billing: billingReducer,
  customers_billing: customerBillingReducer,
  dns_zones: dnsZoneReducer,
  platform_options: platformReducer,
  global_settings: settingReducer,
});

const devTools = middleWare => {
  if (env.NODE_ENV === 'development') {
    return composeWithDevTools(applyMiddleware(middleWare));
  } else {
    return applyMiddleware(middleWare);
  }
};

const store = createStore(rootReducer, devTools(thunk));

export default store;
