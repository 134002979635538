import { getRequest, postRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Get all subscriptions.
 *
 * @param   {Object} data
 * @return  {Object}
 */
const fetch = data => {
  return getRequest({
    url: env.buildApiEndpoint('subscriptions'),
    data,
  });
};

/**
 * Creates a new subscription.
 *
 * @param   {Object} data
 * @return  {Object}
 */
const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('subscriptions'),
    data,
  });
};

const fetchUsage = data => {
  return getRequest({
    url: env.buildApiEndpoint(`subscriptions/${data.guid}/usage`),
    data,
  });
};

const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`subscriptions/${data.guid}`),
    data,
  });
};

const cancel = data => {
  return postRequest({
    url: env.buildApiEndpoint(`subscriptions/${data.guid}/cancel`),
    data,
  });
};

const uncancel = data => {
  return postRequest({
    url: env.buildApiEndpoint(`subscriptions/${data.guid}/uncancel`),
    data,
  });
};

const forceDelete = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`subscriptions/${data.guid}`),
    data,
  });
};

const SubscriptionService = {
  fetch,
  create,
  fetchUsage,
  update,
  cancel,
  uncancel,
  forceDelete,
};

export default SubscriptionService;
