import React, {  useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import StringHelper from 'helpers/string';
import PackageHelper from 'helpers/package';
import UserHelper from 'helpers/user';
import ReportService from 'services/report';
import { isNullOrUndefined } from 'helpers';
import { TitleBar } from 'styles/layout/titlebar';
import { Container } from 'styles/website/profile';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import { getStatusColor } from 'helpers';
import { installPackage, listInstalledPackages, } from 'store/website/websiteActions';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg } from 'store/global/globalActions';
import 'components/package/packages.css';

const GlobalActiveInstalls = ({globalPackage}) => {
  const websites = useSelector(websitesSelector);
  const [loading, setLoading] = useState(false);
  const [tablePackages, setTablePackages] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNullOrUndefined(tablePackages)) {
      const data = {
        package_type: globalPackage.type,
        search: globalPackage.display_name,
        fields: [ 'version', 'status', 'scope' ],
      }
      if (UserHelper.isAdminOrAgent() && PackageHelper.isPartner(globalPackage)) {
        data.partner_slug = globalPackage.partner_slug;
      }
      setLoading(true);
      ReportService.filterAppsInstalledPacakges(data).then(packages => {
        const items = packages.filter(p => p.scope === globalPackage.scope)
                              .map(p => ({ ...p, slug: p.slug || StringHelper.randomSlug() }));
        // Save sorted by package version for users to track non-updated websites.
        setTablePackages(items.sort((a, b) => a.version.localeCompare(b.version)));
      }).finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  const softRefreshInstalledPackages = (item, callback, silent) => {
    const data = {
      website_slug: item.website_slug,
      type: globalPackage.type,
      soft_sync: true, // Refresh ERM cache only.
    };
    !silent && dispatch(setGlobalPleaseWaitMsg({ model: globalPackage.type, id: globalPackage.display_name, action: 'refreshed' }));
    dispatch(listInstalledPackages(data))
      .then((installed) => {
        const installedPackage = installed.find(p => p.display_name === globalPackage.display_name);
        if (installedPackage) {
          setTablePackages(items => items.map(i => i.slug === item.slug ? { ...i, version: installedPackage.version } : i));
        }
        callback && callback(installedPackage);
        !silent && dispatch(setGlobalSuccessMsg({ model: globalPackage.type, id: globalPackage.display_name, action: 'refreshed' }));
      });
  }

  const reinstallManagedPackage = item => {
    dispatch(setGlobalPleaseWaitMsg({ model: globalPackage.type, action: 'installed' }));
    const data = {
      website_slug: item.website_slug,
      package_slug: globalPackage.slug,
    };
    dispatch(installPackage(data)).then(() => {
      dispatch(setGlobalSuccessMsg({ model: globalPackage.type, id: globalPackage.display_name, action: 'installed' }));
      setTablePackages(items => items.map(i => i.slug === item.slug ? { ...i, version: globalPackage.version } : i));
    });
  }

  const actions = [
    {
      value: `Install v${globalPackage.version}`,
      doHide: item =>item.version === globalPackage.version,
      onClick: item => {
        dispatch(setGlobalPleaseWaitMsg({ model: globalPackage.type, action: 'installed', id: globalPackage.display_name }));
        return softRefreshInstalledPackages(item, (installedPackage) => {
          // Refresh the status of the installed package and only reinstall if the version is different.
          if (installedPackage && installedPackage.version !== globalPackage.version) {
            reinstallManagedPackage(item);
          } else {
            dispatch(setGlobalSuccessMsg({ model: globalPackage.type, id: globalPackage.display_name, action: 'installed' }));
          }
        }, true)
      }
    },
    {
      value: `Refresh ${StringHelper.capitalizeFirstLetter(globalPackage.type)}`,
      onClick: (item) => softRefreshInstalledPackages(item, null, false)
    },
  ];

  const headers = [
    PackageHelper.renderScopeHeader(),
    PackageHelper.renderWebsiteHeader(websites),
    JsxHelper.createTableTextHeaderWithCallback(
      'installed_version',
      'Installed Version',
      '15%', (row) => {
        const isVersionDifferent = row.version !== globalPackage.version;
        const differentVersionTooltip = isVersionDifferent ? `Installed version is different from the global version (${globalPackage.version}). This usually means the internal update did not succeed due to errors in the website. Consider updating the website manually.` : '';
        return JsxHelper.createBubble({
          customClass: 'active-cell',
          small: true,
          text: row.version,
          tooltip: isVersionDifferent ? differentVersionTooltip : 'This website is synchronized with the global version.',
          background: isVersionDifferent ? 'warning' : 'success'
        })
      }
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'status',
      'Status',
      '10%',
      (row) => JsxHelper.createBubble({ customClass: 'active-cell', small: true, text: row.status, background: getStatusColor(row.status) })
    ),
    JsxHelper.createTableActionsHeader(actions, '40%'),
  ]

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar padding-0' style={{ minHeight: '54px' }}>
        <TitleBar.Title style={{ fontSize: '20px' }}>{globalPackage.display_name} v{globalPackage.version}</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader' style={{ 'padding': '0' }}>
        You can see all websites where the global {globalPackage.type} <strong>{globalPackage.display_name} v{globalPackage.version}</strong> is installed.
      </p>
      <WPSDataTable
        customClass={'packages-table'}
        columns={headers}
        body={tablePackages}
        loading={loading || isNullOrUndefined(tablePackages)}
        noSearchOnTable={false}
        rowsPerPage={100}
      />
    </Container>
  );
};

export default GlobalActiveInstalls;
