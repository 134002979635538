import React, { useRef, Fragment } from 'react';
import RoutesRenderer from 'components/RoutesRenderer';
import { useRouteMatch } from 'react-router-dom';
import Website from './website';
import SMTPMailer from 'components/website/advanced/smtpMailer';
import WPDefines from 'components/website/advanced/wpDefines';
import BulkReset from 'components/website/bulkReset';
import BulkPurge from 'components/website/bulkPurge';

const partnerComponents = {
  Website,
  SMTPMailer,
  WPDefines,
  BulkReset,
  BulkPurge,
};

const PartnerSettings = props => {
  const { path } = useRouteMatch();

  const partnerRoutes = useRef([
    {
      path: `${path}/wp-config`,
      componentName: 'WPDefines',
      exact: true,
    },
    {
      path: `${path}/bulk-reset`,
      componentName: 'BulkReset',
      exact: true,
    },
    {
      path: `${path}/bulk-purge`,
      componentName: 'BulkPurge',
      exact: true,
    },
    {
      path: `${path}/smtp-mailer`,
      componentName: 'SMTPMailer',
      exact: true,
    },
    {
      path: `${path}/nginx-config`,
      componentName: 'NginxConfig',
      exact: true,
    },
  ]);

  const routes = useRef(partnerRoutes.current);
  const components = useRef({ ...partnerComponents });

  return (
    <Fragment>
      <Website />
      <RoutesRenderer routes={routes.current} components={components.current} />
    </Fragment>
  );
};

export default PartnerSettings;
