import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.background.overlay};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1300;
`;

const ModalBox = styled.div`
  min-width: 500px;
  max-width: ${({ maxWidth = '500px' }) => maxWidth};
  width: ${({ width = 'auto' }) => width};
  height: auto;
  background: ${props => props.theme.background.light};
  border-radius: 5px;
  padding: 16px;
`;

const ModalBody = styled.div`
  padding: 16px 0;
  max-height: 75vh;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  overflow: auto;
  overflow-y: scroll;
`;

const ModalTitle = styled.h3`
  padding-bottom: 16px;
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

const ModalSubtitle = styled.h5`
  padding-bottom: 16px;
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  border-radius: 5px;
  button:not(:first-child) {
    margin-left: 16px;
  }
`;

Container.ModalBox = ModalBox;
Container.ModalBody = ModalBody;
Container.ModalFooter = ModalFooter;
Container.ModalTitle = ModalTitle;
Container.ModalSubtitle = ModalSubtitle;
