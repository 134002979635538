import styled from 'styled-components';
import theme from 'styles/theme';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: ${props => (props.secondary ? '60px' : '200px')};
  background: linear-gradient(to bottom, #232aa4, #1c2285);
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

export const Logo = styled.div`
  padding: ${props => (props.secondary ? '0 16px' : '0 24px')};
`;

export const Nav = styled.ul`
  color: white;
  margin-top: 16px;
  li {
    color: rgba(255, 255, 255, 0.8);
  }
  a {
    display: flex;
    align-items: center;
    justify-content: ${props => (props.secondary ? 'center' : 'initial')};
    color: inherit;
    padding: ${props => (props.secondary ? '12px' : '12px 24px')};
    text-transform: capitalize;
    &:hover,
    &.active {
      color: white;
    }
    span {
      display: ${props => (props.secondary ? 'none' : 'inline')};
    }
  }
  svg {
    font-size: ${props => (props.secondary ? '20px' : '18px')};
    margin-right: ${props => (props.secondary ? '0' : '8px')};
  }
`;

export const UserProfileStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 12px;
`;

export const UserProfileImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  flex-shrink: 0;
  height: 26px;
  width: 26px;
  /* margin-right: 8px; */
  border-radius: 50%;
  background-color: ${theme.color.inactive2};
  background-image: ${({ background }) => (background ? `url('${background}')` : '#AAB9E5')};
  background-size: cover;
  background-position: center center;
`;

export const SecondNav = styled.ul`
  position: fixed;
  left: 60px;
  top: 0;
  width: 140px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: ${theme.background.sidebar2};
  z-index: 120;
  button.close {
    position: absolute;
    right: -14px;
    top: 28px;
    padding: 4px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    color: #2448ad;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid ${theme.border.primary};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  li {
    padding: 12px 0;
    color: white;
  }
  a {
    &.active {
      text-decoration: underline;
    }
  }
`;
