import React from 'react';
import styled, { keyframes } from 'styled-components';

const Ani_1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const Ani_2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }`;

const Ani_3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const fullRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const InlineLoaderStyle = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 10px;
  div {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props =>
      props.color ? props.theme.background[props.color] : 'rgba(255, 255, 255, 0.8)'};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: ${Ani_1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: ${Ani_2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: ${Ani_2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: ${Ani_3} 0.6s infinite;
  }
`;

const RingLoaderStyle = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.size ? `${props.size}px` : '16px')};
  height: ${props => (props.size ? `${props.size}px` : '16px')};
  ${props =>
    props.margin &&
    `
    margin: ${props.margin}
  `};
  ${props =>
    props.padding &&
    `
    padding: ${props.padding}
  `};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => (props.size ? `${props.size}px` : '16px')};
    height: ${props => (props.size ? `${props.size}px` : '16px')};
    border: 2px solid
      ${props => (props.color ? props.theme.color[props.color] : 'rgba(255, 255, 255, 0.8)')};
    border-radius: 50%;
    animation: ${RingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props =>
        props.color ? props.theme.color[props.color] : 'rgba(255, 255, 255, 0.8)'}
      transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const InlineLoader = ({ color }) => (
  <InlineLoaderStyle color={color}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </InlineLoaderStyle>
);

export const RingLoader = ({ color, margin, size, padding }) => (
  <RingLoaderStyle color={color} margin={margin} size={size} padding={padding}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </RingLoaderStyle>
);

const PageLoaderStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 110px;
  span {
    font-family: 'Open Sans', sans-serif;
    display: block;
    font-weight: 300;
  }
  div:nth-child(1) {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 100px;
    width: 100px;
    border: 2px solid transparent;
    border-top-color: #1f2592;
    border-bottom-color: #1f2592;
    border-radius: 50%;
    animation: ${fullRotate} 1.6s linear infinite alternate;
  }
  div:nth-child(2) {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 90px;
    width: 90px;
    border: 2px solid transparent;
    border-bottom-color: #457fff;
    border-top-color: #457fff;
    border-radius: 50%;
    animation: ${fullRotate} 1.4s linear infinite alternate-reverse;
  }
  div:nth-child(3) {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 80px;
    width: 80px;
    border: 2px solid transparent;
    border-left-color: #467bff;
    border-right-color: #467bff;
    border-radius: 50%;
    animation: ${fullRotate} 1.2s linear infinite alternate;
  }
`;

const AreaLoaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size.width}px`};
  height: ${({ size }) => `${size.height}px`};
`;

export const PageLoader = () => (
  <PageLoaderStyle>
    <div></div>
    <div></div>
    <div></div>
    <span>Loading..</span>
  </PageLoaderStyle>
);

export const AreaLoader = ({ size }) => (
  <AreaLoaderStyle size={size}>
    <RingLoader color='info' size='24' />
  </AreaLoaderStyle>
);
