import { createSelector } from 'reselect';

/*
  Input
*/
const regions = state => state.regions;

/*
  Selectors
*/
export const globalRegionsSelector = createSelector(regions, regions => regions);

export const getRegionBySlug = slug =>
  createSelector(regions, regions => regions.filter(region => region.slug === slug)[0]);

export const regionsListSelector = createSelector(regions, regions =>
  regions.map(r => ({ label: r.display_name, value: r.slug })),
);
