import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import WebsiteService from 'services/website';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import Tabs, { Tab, TabPanel } from 'components/wpstaq/tabs';
import PoolCPUTab from 'components/website/analytics/pool-cpu';
import PoolMemoryTab from 'components/website/analytics/pool-memory';
import PoolRequestTab from 'components/website/analytics/pool-request';
import ParallelPoolTab from 'components/website/analytics/pool-parallel';
import ProcessPoolTab from 'components/website/analytics/pool-process';
import SystemSummaryCards from 'components/website/analytics/system-summary-cards';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import MathHelper from 'helpers/math';
import JsxHelper from 'helpers/jsx';
import StringHelper from 'helpers/string';
import { isEmptyOrNull } from 'helpers';

const PHPAnalytics = ({ website }) => {
  useTitle('PHP Workers Analytics');
  const dispatch = useDispatch();
  const [stats, setStats] = useState();
  const [loading, setLoading] = useState(false);
  const [unitsAgo, setUnitsAgo] = useState(0);
  const [granularity, setGranularity] = useState('daily');
  const mounted = useRef(true);
  const DAILY_OPTIONS = [1, 3, 7, 30];
  const HOUR_OPTIONS = [3, 6, 12];

  useEffect(() => {
    if (mounted.current) {
      fetchData('hourly', 3);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const fetchData = useCallback(
    (_granularity, _unitsAgo) => {
      if ((unitsAgo === _unitsAgo && _granularity === granularity) || loading) {
        return;
      }
  
      setGranularity(_granularity)
      if (_unitsAgo === 0) {
        setUnitsAgo(0);
        return; // expect user to select unit on granularity selection
      }

      setUnitsAgo(_unitsAgo);
      setLoading(true);
      const apiParams = {
        website_slug: website.slug,
        granularity: _granularity,
        units_ago: _granularity === 'hourly' ? _unitsAgo + 2 : _unitsAgo,
      };
      WebsiteService.fetchPHPWorkersAnalytics(apiParams)
        .then(res => {
          if (mounted.current) {
            setStats(transformStats(res.data));
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
          }
        });
    },
    // eslint-disable-next-line
    [unitsAgo, loading],
  );

  const transformStats = stats => {
    for (const i in stats) {
      stats[i].timeframe = stats[i].granularity === 'daily' ? stats[i].date : stats[i].starts_at.split(':')[0] + ':00';
      stats[i].requests_peak_mem_mb = stats[i].requests_peak_mem / (1024 * 1024);
      stats[i].parallel_processes_avg = MathHelper.toFloat(stats[i].parallel_processes_avg);
    }
    return stats.reverse();
  }

  const getButtonClasses = (unit) => {
    const classes = unitsAgo === unit ? 'primary--btn' : 'disabled--btn';
    return  (granularity === 'daily' && DAILY_OPTIONS[0] !== unit) ||
            (granularity === 'hourly' && HOUR_OPTIONS[0] !== unit) ? classes + ' margin-left-6' : classes;
  }

  const renderButton = (unit) => JsxHelper.createButton({
    key: `${granularity}-${unit}`,
    loading: unitsAgo === unit && loading,
    classes: getButtonClasses(unit),
    label: StringHelper.maybePluraize(unit, 'Day'),
    onClick: () => fetchData(granularity, unit)
  });

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>PHP Performance Analytics</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
      Monitor your website's PHP performance with detailed analytics on request volume, PHP worker allocation, CPU usage, memory consumption, and concurrent worker activity.
      </p>
      <Content>
        <SystemSummaryCards website={website} stats={stats} />
        <div className='analytics-filters'>
          {JsxHelper.createButton({
            key: 'hourly',
            loading: granularity === 'hourly' && loading,
            classes: granularity === 'hourly' ? 'primary--btn' : 'disabled--btn',
            label: 'Hourly',
            onClick: () => fetchData('hourly', 0)
          })}
          {JsxHelper.createButton({
            key: 'daily',
            loading: granularity === 'daily' && loading,
            classes: granularity === 'daily' ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6',
            label: 'Daily',
            onClick: () => fetchData('daily', 0)
          })}
        </div>
        <br/>
        <div className='analytics-filters'>
          {granularity === 'hourly' && HOUR_OPTIONS.map(renderButton)}
          {granularity === 'daily' && DAILY_OPTIONS.map(renderButton)}
        </div>
        <div className='analytics-tables'>
          {!isEmptyOrNull(stats) ? (
            <Tabs
              className='stats-tabs system-tabs'
              initialValue='cpu'
              tabs={() => (
                <Fragment>
                  <Tab name='cpu'>CPU</Tab>
                  <Tab name='mem'>Memory</Tab>
                  <Tab name='request'>Requests</Tab>
                  <Tab name='process'>PHP Workers</Tab>
                  <Tab name='parallel'>Parallel PHP Workers</Tab>
                </Fragment>
              )}
              panels={() => (
                <Fragment>
                  <TabPanel name='cpu'>
                    <PoolCPUTab slug={website.slug} stats={stats}/>
                  </TabPanel>
                  <TabPanel name='mem'>
                    <PoolMemoryTab slug={website.slug} stats={stats}/>
                  </TabPanel>
                  <TabPanel name='request'>
                    <PoolRequestTab slug={website.slug} stats={stats}/>
                  </TabPanel>
                  <TabPanel name='process'>
                    <ProcessPoolTab slug={website.slug} stats={stats}/>
                  </TabPanel>
                  <TabPanel name='parallel'>
                    <ParallelPoolTab slug={website.slug} stats={stats}/>
                  </TabPanel>
                </Fragment>
              )}
            />
          ) : <div style={{ textAlign: 'center', marginTop: '45px' }}>
            {!loading ? 'No data available' : JsxHelper.createLoadingSpinner()}
          </div>}
        </div>
      </Content>
    </Container>
  );
};

export default PHPAnalytics;
