import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import WebsiteService from 'services/website';
import { WPSButton } from 'styles/layout/buttons';
import { isEmpty } from 'helpers';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { useSelector } from 'react-redux';
import { timezoneSelector } from 'store/me/meSelectors';
import env from 'config/env';
import DialogHelper from 'helpers/dialog';
import UserHelper from 'helpers/user';

const viewerDefaults = { title: '', isOpen: false, data: {} };
const Activity = ({ website }) => {
  useTitle('Website Activity');
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewer, setViewer] = useState({ ...viewerDefaults });
  const [data, setData] = useState({
    website_slug: website.slug,
    page: 1,
    limit: 10,
  });
  const timezone = useSelector(timezoneSelector);
  const [disabled, setDisabled] = useState(false);
  const mounted = useRef(true);

  const fetchActivities = useCallback(() => {
    setLoading(true);
    WebsiteService.fetchWebsiteActivites(data)
      .then(res => {
        if (mounted.current) {
          setActivities(prev => [...prev, ...res]);
          setData(prev => ({ ...prev, page: prev.page + 1 }));
          if (res.length < 10) {
            setDisabled(true);
          }
        }
      })
      .finally(() => {
        if (mounted.current) {
          setLoading(false);
        }
      });
  }, [data]);

  useEffect(() => {
    if (mounted.current) {
      fetchActivities();
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleCellClick = (_title, value) => setViewer({
    title: _title,
    isOpen: true,
    data: value || {},
  });

  const handleViewerClose = useCallback(() => {
    setViewer({ ...viewerDefaults });
  }, []);

  const headers = UserHelper.getActivityTableHeaders(handleCellClick, timezone);

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Activity History</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='action--read' onClick={fetchActivities} loading={loading} disabled={disabled}>
            Load more
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
      View a record of actions taken on your website through {env.getBrandShortName()} and gain insights into their behavior.
      </p>
      <Content>
        <WPSDataTable
          columns={headers}
          body={activities}
          loading={isEmpty(activities) && loading}
          dataKey='guid'
        />
      </Content>
      {viewer.isOpen && DialogHelper.jsonViewer({
        onClose: handleViewerClose,
        data: viewer.data,
      })}
    </Container>
  );
};

export default Activity;
