import React, { useMemo, useImperativeHandle } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { setGlobalErrorMsg } from 'store/global/globalActions';
import useResponsiveFontSize from "./useResponsiveFontSize";
import { useDispatch } from "react-redux"

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const CardForm = React.forwardRef((props, ref ) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const createPaymentMethod = async event => {
    if(event && event.preventDefault) {
      event.preventDefault();
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });

    if (payload.error) {
      dispatch(setGlobalErrorMsg(payload.error.message));
      return payload;
    }

    return payload;
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    createPaymentMethod
  }));

  return (
    <CardElement
      options={options}
    />
  );
});

export default CardForm;