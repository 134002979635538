import React, { useState } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import { purgeWebsiteCache } from 'store/website/websiteActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WebsiteHelper from 'helpers/website';
import AdvancedTabs from '../../../components/website/advanced/advancedTabs/advancedTabs'
import WebsiteService from 'services/website';
import CloudflareService from 'services/cloudflare';
import env from 'config/env';

const Cache = ({ website }) => {
  useTitle('Website Cache');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const hasSupportedPageBuilder = WebsiteHelper.hasSupportedPageBuilder(website);

  const cacheOptions = [
    {
      type: 'table',
      title: 'Clear Website Cache',
      name: 'clear_wide_cache',
      desc: `Clear the cached static files on sites that use ${env.getBrandShortName()} Cache or WP Rocket.`,
      icon: 'trash',
      btnText: 'Clear'
    },
    {
      type: 'table',
      title: 'Clear Page Builder Cache',
      name: 'clear_pb_cache',
      desc: 'Clear the cached files for the supported page builders: Elementor, Beaver Builder, Oxygen, Avada and Divi.',
      icon: 'trash',
      btnDisabled: !hasSupportedPageBuilder,
      btnDisabledTip: !hasSupportedPageBuilder ? 'No supported page builders found.' : null,
      btnText: 'Clear'
    },
    {
      type: 'table',
      title: 'Clear CDN Cache',
      name: 'clear_cdn_cache',
      desc: 'Invalidates the CDN cache for the entire website at the CloudFront edge locations.',
      icon: 'trash',
      hide: !WebsiteHelper.getCdnCache(website),
      btnText: 'Clear'
    },
    {
      type: 'table',
      title: 'Purge Cloudflare Cache',
      name: 'purge_cloudflare_cache',
      desc: 'Purge the Cloudflare cache for the entire website.',
      icon: 'trash',
      hide: !WebsiteHelper.getCloudflareZone(website),
      btnText: 'Purge'
    },
  ]

  const clearSiteCache = (clearPagebuilder) => {
    const data = {
      website_slug: website.slug,
      clear_pagebuilder: clearPagebuilder
    };
    dispatch(purgeWebsiteCache(data))
      .then(() => dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'Website cache', action: 'cleared' })))
      .finally(() => setLoading(false));
  };

  const clearCdnCache = () => {
    const data = {
      website_slug: website.slug,
      object_paths: [ '/' ],
    };
    WebsiteService.invalidateCDNPaths(data)
      .then(() => dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'Website CDN cache', action: 'cleared' })))
      .finally(() => setLoading(false));
  }

  const purgeCloudflareCache = () => {
    const zone = WebsiteHelper.getCloudflareZone(website);
    CloudflareService.purgeZoneCache({ zone_id: zone.zone_id, website_slug: website.slug })
      .then(() => dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'Cloudflare cache', action: 'purged' })))
      .finally(() => setLoading(false));
  }

  const handleOnClick = e => {
    if (e) {
      setLoading(e);
      switch (e) {
        case 'clear_wide_cache':
          clearSiteCache(false);
          break;
        case 'clear_pb_cache':
          clearSiteCache(true);
          break;
        case 'clear_cdn_cache':
          clearCdnCache(true);
          break;
        case 'purge_cloudflare_cache':
          purgeCloudflareCache();
          break;
        default:
          return;
      }
    }
  }

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Cache</TitleBar.Title>
      </TitleBar>
      <Content style={{ maxidth: '1140px', display: 'flex', flexWrap: 'wrap' }}>
        {
          cacheOptions.filter(el => !el.hide).map((el, index) => {
            return <AdvancedTabs
              key={index}
              type={el.type}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              btnDisabledTip={el.btnDisabledTip}
              btnDisabled={!!el.btnDisabled || !!loading}
              loading={loading === el.name}
              btnText={el.btnText ? el.btnText : ''}
              _placeholder={el._placeholder ? el._placeholder : ''}
              selectType={el.selectType ? el.selectType : ''}
              onClick={handleOnClick}
            />
          })
        }
      </Content>
    </Container>
  );
};

export default Cache;
