import React, { Fragment, useState, useRef } from 'react';
import Modal from '../../components/layout/modal';
import ArrayHelper from 'helpers/array';
import { useForm } from 'react-hook-form';
import { WPSForm, WPSLabel, WPSInput, WPSInputFix, ErrorMsg } from '../../styles/layout/forms';
import { isEmpty, isEmptyOrNull } from '../../helpers';
import FormHelper from '../../helpers/form';
import dnsHelper from '../../helpers/dnszone';
import { WPSButton } from '../../styles/layout/buttons';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { WPSBubble } from 'styles/layout/buttons';
import { useDispatch } from 'react-redux';
import {
  setGlobalWarningMsg,
} from '../../store/global/globalActions';

const DNSRecordModal = ({ isTemplate, isEdit, domainName, setRecord, record, setModal, dnsSlug, onSubmit, loading }) => {
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const dispatch = useDispatch();

  // states
  const [noValues, setNoValues] = useState(false);
  const [bubbledValues, setBubbledValues] = useState(record ? record.values : []);
  const [bubbledTemplateValues, setBubbledTemplateValues] = useState([]);
  const [recordTemplate, setRecordTemplate] = useState('');
  const [normalRecordVal, setNormalRecordVal] = useState('');
  const [allFieldsRequired, setAllFieldsRequired] = useState(false);
  const [mxRecordVal, setMXRecordVal] = useState({
    priority: '',
    hostName: '',
  });
  const [srvRecordVal, setSRVRecordVal] = useState({
    priority: '',
    weight: '',
    port: '',
    hostName: '',
  });

  // uncontrolled Form inputs
  const valuesGeneralType = useRef();
  const valuesMXTypePriority = useRef();
  const valuesMXTypeHostName = useRef();
  const valuesSRVPriority = useRef();
  const valuesSRVWeight = useRef();
  const valuesSRVPort = useRef();
  const valuesSRVHostName = useRef();

  const onModalClose = () => {
    setRecordTemplate('');
    setBubbledTemplateValues([]);
    setModal(false);
  };

  const onRecordTemplateChange = e => {
    const { value } = e.target;
    setRecordTemplate(value);
    if (value === dnsHelper.templates.OUTLOOK365_MAIL_SERVICE) {
      const outlookData = dnsHelper.outlookMailServiceValues.map(el => {
        const _val =
          el.type === 'SRV' ? `${el.priority} ${el.weight} ${el.port} ${el.hostName}` : el.value;
        return { ...FormHelper.createSelectOption(_val), type: el.type };
      });
      setBubbledTemplateValues([...outlookData]);
    } else if (value === dnsHelper.templates.GOOGLE_WORKSPACE) {
      const googleData = dnsHelper.googleValues.map(el => {
        return { ...FormHelper.createSelectOption(el), type: 'MX' };
      });
      setBubbledTemplateValues([...googleData]);
    } else {
      setBubbledTemplateValues([]);
    }
  };

  const handleAddValues = () => {
    if (!dnsHelper.multiValuesAllowed(record.type) && bubbledValues.length > 0) {
      dispatch(setGlobalWarningMsg(`${record.type} records can only have a single value.`));
      return;
    }
    let currentValues = [...bubbledValues];
    switch (record.type) {
      case 'A':
      case 'AAAA':
      case 'CNAME':
      case 'TXT':
      case 'SPF':
      case 'PTR':
        if (isEmpty(normalRecordVal)) {
          setAllFieldsRequired(true);
          return;
        }
        setAllFieldsRequired(false);
        currentValues.push(FormHelper.createSelectOption(normalRecordVal));
        setBubbledValues(currentValues);
        setRecord(prev => ({ ...prev, values: [...currentValues] }));
        setNormalRecordVal('');
        valuesGeneralType.current.value = '';
        break;
      case 'MX':
        if (isEmpty(mxRecordVal.priority.length) || isEmpty(mxRecordVal.hostName.length)) {
          setAllFieldsRequired(true);
          return;
        }
        setAllFieldsRequired(false);
        let mxVal = `${mxRecordVal.priority} ${mxRecordVal.hostName}`;
        currentValues.push(FormHelper.createSelectOption(mxVal));
        setBubbledValues(currentValues);
        setRecord(prev => ({ ...prev, values: [...currentValues] }));
        setMXRecordVal({ priority: '', hostName: '' });
        valuesMXTypePriority.current.value = '';
        valuesMXTypeHostName.current.value = '';
        break;
      case 'SRV':
        if (
          isEmpty(srvRecordVal.priority.length) ||
          isEmpty(srvRecordVal.weight.length) ||
          isEmpty(srvRecordVal.port.length) ||
          isEmpty(srvRecordVal.hostName.length)
        ) {
          setAllFieldsRequired(true);
          return;
        }
        setAllFieldsRequired(false);
        let srvVal = `${srvRecordVal.priority} ${srvRecordVal.weight} ${srvRecordVal.port} ${srvRecordVal.hostName}`;
        currentValues.push(FormHelper.createSelectOption(srvVal));
        setBubbledValues(currentValues);
        setRecord(prev => ({ ...prev, values: [...currentValues] }));
        setSRVRecordVal({ priority: '', weight: '', port: '', hostName: '' });
        valuesSRVPriority.current.value = '';
        valuesSRVWeight.current.value = '';
        valuesSRVPort.current.value = '';
        valuesSRVHostName.current.value = '';
        break;
      default:
        setAllFieldsRequired(false);
        setNormalRecordVal('');
        setMXRecordVal({ priority: '', hostName: '' });
        setSRVRecordVal({ priority: '', weight: '', port: '', hostName: '' });
        return;
    }
  };

  // Handle new record state.
  const onRecordChange = e => {
    const { name, value } = e.target;
    if (isEmptyOrNull(value)) {
      return;
    }
    setRecord(prev => ({ ...prev, [name]: value }));
    setAllFieldsRequired(false);
    switch (name) {
      case 'ttl':
        return;
      case 'type':
        setBubbledValues([]);
        return;
      default:
        return;
    }
  };

  const handleDeleteBubbleItem = index => {
    let data = [...bubbledValues];
    data.splice(index, 1);
    setBubbledValues([...data]);
    setRecord(prev => ({ ...prev, values: [...data] }));
  };

  return (
    <Modal
      className='input-modal'
      icon={'create'}
      iconColor={'success'}
      title={isEdit ? 'Edit DNS Record' : 'Add New DNS Record'}
      onClose={() => onModalClose()}
      onConfirm={handleSubmit(() => {
        if (!isTemplate && isEmptyOrNull(record.values)) {
          setNoValues(true);
        } else {
          setNoValues(false);
          onSubmit(record, recordTemplate);
        }
      })}
      confirmBtn={isEdit ? 'Edit' : 'Add'}
      maxWidth='560'
      loading={loading}>
      {!isTemplate && <WPSForm onSubmit={e => e.preventDefault()}>
        <Fragment>
          <WPSLabel>Type</WPSLabel>
          <WPSSelect
            name='type'
            id='record_type'
            value={record.type}
            disabled={isEdit}
            options={ArrayHelper.buildSelectOptions(
              dnsHelper.getTypesOptions(),
              'name',
              'value',
            )}
            onChange={onRecordChange}
            isSearchable={true}
            required={true}
            refId='record_type'
            register={register}
          />
          {errors.record_type && (
            <ErrorMsg className='margin-top-5'>{errors.record_type.message}</ErrorMsg>
          )}
          <WPSLabel className={isEmpty(record.type) ? 'visibility-hidden' : ''}>
            Name
          </WPSLabel>
          <WPSInputFix className={isEmpty(record.type) ? 'visibility-hidden' : ''}>
            <WPSInput
              disabled={isEdit}
              type='text'
              name='name'
              defaultValue={record.name}
              onBlur={onRecordChange}
            />
            <div className='suffix'>.{domainName}</div>
          </WPSInputFix>
          <WPSForm.Row className={isEmpty(record.type) ? 'visibility-hidden' : ''}>
            <WPSForm.RowItem>
              <WPSLabel>TTL</WPSLabel>
              <WPSSelect
                name='ttl'
                value={record.ttl}
                options={ArrayHelper.buildSelectOptions(
                  dnsHelper.ttlOptions,
                  'text',
                  'value',
                )}
                onChange={onRecordChange}
                sortOff={true}
              />
            </WPSForm.RowItem>
            {record.ttl === 'custom' && (
              <WPSForm.RowItem>
                <WPSLabel>Custom TTL</WPSLabel>
                <WPSInput
                  type='number'
                  name='custom_ttl'
                  defaultValue={record.custom_ttl}
                  onBlur={onRecordChange}
                />
              </WPSForm.RowItem>
            )}
          </WPSForm.Row>
          <WPSLabel className={isEmpty(record.type) ? 'visibility-hidden' : ''}>
            Values
            {noValues && <ErrorMsg>{FormHelper.messages.required}</ErrorMsg>}
            {allFieldsRequired && <ErrorMsg>{FormHelper.messages.allRequired}</ErrorMsg>}
          </WPSLabel>
          <div
            style={{ display: 'flex' }}
            className={isEmpty(record.type) ? 'visibility-hidden' : ''}>
            <div style={{ width: '90%', display: 'flex' }}>
              {record.type !== 'MX' && record.type !== 'SRV' && (
                <WPSInput
                  style={{ width: '98%' }}
                  type='text'
                  placeholder={
                    !isEmpty(record.type) ? dnsHelper.typeOptions[record.type].example : ''
                  }
                  name='values'
                  ref={valuesGeneralType}
                  defaultValue={normalRecordVal}
                  onBlur={e => {
                    e.persist();
                    setNormalRecordVal(e.target.value)
                  }}
                />
              )}
              {record.type === 'MX' && (
                <Fragment>
                  <WPSInput
                    style={{ width: '20%', marginRight: '10px', minWidth: '20%' }}
                    type='number'
                    name='values'
                    placeholder='priority'
                    ref={valuesMXTypePriority}
                    defaultValue={mxRecordVal.priority}
                    onBlur={e => {
                      e.persist();
                      setMXRecordVal(prev => ({ ...prev, priority: e.target.value }))
                    }}
                  />
                  <WPSInput
                    style={{ width: '75%', marginRight: '10px', minWidth: '75%' }}
                    type='text'
                    name='values'
                    placeholder='mail server host name'
                    ref={valuesMXTypeHostName}
                    defaultValue={mxRecordVal.hostName}
                    onBlur={e => {
                      e.persist();
                      setMXRecordVal(prev => ({ ...prev, hostName: e.target.value }));
                    }}
                  />
                </Fragment>
              )}
              {record.type === 'SRV' && (
                <Fragment>
                  <WPSInput
                    style={{ width: '17%', marginRight: '4px', minWidth: '17%' }}
                    type='number'
                    name='values'
                    placeholder='priority'
                    ref={valuesSRVPriority}
                    defaultValue={srvRecordVal.priority}
                    onBlur={e => {
                      e.persist();
                      setSRVRecordVal(prev => ({ ...prev, priority: e.target.value }))
                    }}
                  />
                  <WPSInput
                    style={{ width: '17%', marginRight: '4px', minWidth: '17%' }}
                    type='number'
                    name='values'
                    placeholder='weight'
                    ref={valuesSRVWeight}
                    defaultValue={srvRecordVal.weight}
                    onBlur={e => {
                      e.persist();
                      setSRVRecordVal(prev => ({ ...prev, weight: e.target.value }))
                    }}
                  />
                  <WPSInput
                    style={{ width: '11%', marginRight: '4px', minWidth: '11%' }}
                    type='number'
                    name='values'
                    placeholder='port'
                    ref={valuesSRVPort}
                    defaultValue={srvRecordVal.port}
                    onBlur={e => {
                      e.persist();
                      setSRVRecordVal(prev => ({ ...prev, port: e.target.value }))
                    }}
                  />
                  <WPSInput
                    style={{ width: '51%', marginRight: '4px', minWidth: '51%' }}
                    type='text'
                    name='values'
                    placeholder='server host name'
                    ref={valuesSRVHostName}
                    defaultValue={srvRecordVal.hostName}
                    onBlur={e => {
                      e.persist();
                      setSRVRecordVal(prev => ({ ...prev, hostName: e.target.value }))
                    }}
                  />
                </Fragment>
              )}
            </div>
            <WPSButton onClick={() => handleAddValues()} className='custom-add-btn'>
              &#43;
            </WPSButton>
          </div>
          {!isEmpty(bubbledValues) && (
            <ul className='display-flex margin-top-12'>
              {bubbledValues.map((item, index) => (
                <li key={item.label + index} className='custom-bubble-li'>
                  <WPSBubble color='light' background='info' display='block'>
                    {item.label}
                  </WPSBubble>
                  <span
                    onClick={() => handleDeleteBubbleItem(index)}
                    className='delete-bubble'>
                    &#10006;
                  </span>
                </li>
              ))}
            </ul>
          )}
        </Fragment>
      </WPSForm>}
      {isTemplate && (
        <Fragment>
          <WPSSelect
            name='template'
            id='template_type'
            value={recordTemplate}
            options={ArrayHelper.buildSelectOptions(
              dnsHelper.typeTemplate,
              'name',
              'value',
            )}
            onChange={onRecordTemplateChange}
            placeholder='Select template'
            required={true}
            refId='template_type'
            register={register}
          />
          {errors.template_type && (
            <ErrorMsg className='margin-top-5'>{errors.template_type.message}</ErrorMsg>
          )}
          {!isEmpty(bubbledTemplateValues) ? (
            <ul className='display-flex margin-top-12'>
              {bubbledTemplateValues.map((item, index) => (
                <li key={item.label + index} className='custom-bubble-li'>
                  <WPSBubble color='light' background='info' display='block'>
                    {`${item.type}: ${item.label}`}
                  </WPSBubble>
                  <span
                    onClick={() => handleDeleteBubbleItem(index)}
                    className='delete-bubble'>
                    &#10006;
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <div style={{ height: '250px' }}></div>
          )}
        </Fragment>
      )}
    </Modal>
  )
}

export default DNSRecordModal;