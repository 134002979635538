import React, { Fragment, useCallback } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSLabel, WPSInput, ErrorMsg } from 'styles/layout/forms';
import { useState } from 'react';
import { WPSButton } from 'styles/layout/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { serversListSelector } from 'store/server/serverSelectors';
import { isEmpty } from 'helpers';
import ToolService from 'services/tool';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { Content } from 'styles/globalStyles';
import ArrayHelper from 'helpers/array';
import { regionsListSelector } from 'store/region/regionSelectors';
import StringHelper from 'helpers/string';
import DateHelper from 'helpers/date';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import globalHelper from 'helpers/globalHelper';
import DialogHelper from 'helpers/dialog';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import './ActivityLogs.css';
import useModal from 'hooks/useModal';
import { timezoneSelector } from 'store/me/meSelectors';
import { useHistory } from 'react-router-dom';
import JsxHelper from 'helpers/jsx';

const ActivityLogs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const servers = useSelector(serversListSelector);
  const regions = useSelector(regionsListSelector);
  const viewerDefaults = { title: '', isOpen: false, data: {} };
  const [viewer, setViewer] = useState({ ...viewerDefaults });
  const { handleSubmit, errors, register } = useForm({ reValidateMode: 'onSubmit' });
  const timezone = useSelector(timezoneSelector);
  const [details, setDetails] = useState({
    service_name: 'erm',
    type: '',
    function: '',
    limit: '25',
    days_ago: '7',
    input: '',
    output: '',
    display: '',
    service_id: null,
  });

  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectKey, setSelectKey] = useState(1);
  const [hideFilters, setHideFilters] = useState(false);

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'service_name') {
      setDetails(prev => ({ ...prev, [name]: value, service_id: null }));
      setSelectKey(selectKey + 1);
    } else {
      setDetails(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleViewJSONTree = (_title, value) => {
    setViewer({
      title: _title,
      isOpen: true,
      data: value ? value : 'null',
    });
  };
  const openErrorStackModal = (message, hideIcon) => {
    modalDialog({
      line1: message,
      type: 'danger',
      btnText: 'Close',
      customLineOneTextClass: 'text-word-break',
      hideIcon: hideIcon,
    });
  };

  const handleViewerClose = useCallback(() => {
    setViewer({ ...viewerDefaults });
  }, [viewerDefaults]);

  const onSubmit = e => {
    setLoading(true);
    let data = { ...details };

    if (data.service_name === 'egm') {
      data.service_id = null;
    }

    ToolService.getActivityLogs(data)
      .then(res => {
        if (res) {
          setLogsData([...res]);
          setHideFilters(true);
        }
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => {
        setLoading(false);
      });
  };

  const headers = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      searchable: true,
      width: '80px',
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      searchable: true,
      width: '200px',
      cell: row => {
        return row.name || '-';
      },
    },
    {
      name: 'Started At',
      selector: 'started_at',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.started_at ? DateHelper.toHumanDateTime(row.started_at, timezone) : '-';
      },
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.type || '-';
      },
    },
    {
      name: 'Input',
      selector: 'input',
      sortable: true,
      searchable: true,
      width: '50px',
      cell: row => (
        <span className='class-link' onClick={() => handleViewJSONTree('Input', row.input)}>
          View
        </span>
      ),
    },
    {
      name: 'Output',
      selector: 'output',
      sortable: true,
      searchable: true,
      width: '55px',
      cell: row => (
        <span className='class-link' onClick={() => handleViewJSONTree('Output', row.output)}>
          View
        </span>
      ),
    },
    {
      name: 'Logs',
      selector: 'logs',
      sortable: true,
      searchable: true,
      width: '50px',
      cell: row => {
        return (
          <span className='class-link' onClick={() => handleViewJSONTree('Logs', row.logs)}>
            View
          </span>
        );
      },
    },
    {
      name: 'Guid',
      selector: 'guid',
      sortable: true,
      searchable: true,
      width: '200px',
      cell: row => {
        return row.guid || '-';
      },
    },
    {
      name: 'Host id',
      selector: 'host_id',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.host_id || '-';
      },
    },
    {
      name: 'Host name',
      selector: 'host_name',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.host_name || '-';
      },
    },
    {
      name: 'Host user',
      selector: 'host_user',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.host_user || '-';
      },
    },
    {
      name: 'Duration',
      selector: 'duration_s',
      sortable: true,
      searchable: true,
      cell: row => {
        return StringHelper.secondsToStr(row.duration_s, row);
      },
    },
    {
      name: 'Error level',
      selector: 'error_level',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.error_level || '-';
      },
    },
    {
      name: 'Error message',
      selector: 'error_msg',
      sortable: true,
      searchable: true,
      width: '55px',
      cell: row => {
        return !isEmpty(row.error_msg) ? (
          <span className='class-link' onClick={() => openErrorStackModal(row.error_msg, true)}>
            View
          </span>
        ) : (
          '-'
        );
      },
    },
    {
      name: 'Error stack',
      selector: 'error_stack',
      sortable: true,
      searchable: true,
      width: '55px',
      cell: row => {
        return !isEmpty(row.error_stack) ? (
          <span className='class-link' onClick={() => openErrorStackModal(row.error_stack, true)}>
            View
          </span>
        ) : (
          '-'
        );
      },
    },
    {
      name: 'Priority',
      selector: 'priority',
      sortable: true,
      searchable: true,
      width: '55px',
      cell: row => {
        return row.priority || '-';
      },
    },
    {
      name: 'Scheduled at',
      selector: 'scheduled_at',
      sortable: true,
      searchable: true,
      cell: row => {
        return row.scheduled_at ? moment(row.scheduled_at).format('DD-MM-YYYY HH:MM') : '-';
      },
    },
    {
      name: 'Destination id',
      selector: 'dest_id',
      sortable: true,
      searchable: true,
      width: '250px',
      cell: row => {
        return row.dest_id || '-';
      },
    },
  ];

  const breadcrumbs = JsxHelper.createBreadcrumbs('Activity Logs', 'Tools');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Activity Logs</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({
            label: hideFilters ? 'Show Filters' : 'Hide Filters',
            onClick: () => setHideFilters(!hideFilters),
            classes: hideFilters ? 'success--btn' : 'warning--btn',
          })}
          {JsxHelper.createButton({
            label: 'Back',
            onClick: history.goBack,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <Content className='activity-logs-table'>
        { !hideFilters && <WPSForm
          style={{ maxWidth: '700px', marginBottom: '50px' }}
          onSubmit={handleSubmit(onSubmit)}>
          <WPSForm.Fieldset>
            <legend>Logs Filters</legend>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel required>Service</WPSLabel>
                <WPSSelect
                  name='service_name'
                  value={details.service_name}
                  options={ArrayHelper.buildSelectOptions(
                    globalHelper.activityLogsFilters,
                    'name',
                    'value',
                  )}
                  onChange={onChange}
                  sortOff={true}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='2'>
                {details.service_name === 'ewsm' ? (
                  <Fragment>
                    <WPSLabel required>
                      Servers
                      {errors.service_id && <ErrorMsg>{errors.service_id.message}</ErrorMsg>}
                    </WPSLabel>
                    <WPSSelect
                      name='service_id'
                      value={details.service_id}
                      options={servers}
                      onChange={onChange}
                      isSearchable={true}
                      key={selectKey}
                      required={true}
                      refId='service_id'
                      register={register}
                    />
                  </Fragment>
                ) : details.service_name === 'erm' || details.service_name === 'erm-aws' ? (
                  <Fragment>
                    <WPSLabel required>
                      Regions
                      {errors.service_id && <ErrorMsg>{errors.service_id.message}</ErrorMsg>}
                    </WPSLabel>
                    <WPSSelect
                      name='service_id'
                      value={details.service_id}
                      options={regions}
                      isSearchable={true}
                      onChange={onChange}
                      key={selectKey}
                      required={true}
                      refId='service_id'
                      register={register}
                    />
                  </Fragment>
                ) : null}
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>Type</WPSLabel>
                <WPSSelect
                  name='type'
                  value={details.type}
                  options={ArrayHelper.buildSelectOptions(
                    globalHelper.activityLogsServiceTypes,
                    'name',
                    'value',
                  )}
                  onChange={onChange}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>Function</WPSLabel>
                <WPSInput
                  type='text'
                  name='function'
                  placeholder='Enter substring or leave empty'
                  value={details.function}
                  onChange={onChange}
                />
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>Input</WPSLabel>
                <WPSInput
                  type='text'
                  name='input'
                  placeholder='Enter substring or leave empty'
                  value={details.input}
                  onChange={onChange}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>Output</WPSLabel>
                <WPSInput
                  type='text'
                  name='output'
                  placeholder='Enter substring or leave empty'
                  value={details.output}
                  onChange={onChange}
                />
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>Limit</WPSLabel>
                <WPSInput
                  type='number'
                  name='limit'
                  placeholder='Enter value between [1 - 100] or leave empty'
                  style={{ maxHeight: '36px' }}
                  value={details.limit}
                  onChange={onChange}
                  ref={register({
                    min: 1,
                    max: 100,
                  })}
                />
                {errors.limit && (
                  <ErrorMsg className='margin-top-5'>
                    value must be between [1-100] or empty
                  </ErrorMsg>
                )}
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='1'>
                <WPSLabel required>Days ago</WPSLabel>
                <WPSInput
                  type='number'
                  name='days_ago'
                  placeholder='Enter value between [1 - 15] or leave empty'
                  style={{ maxHeight: '36px' }}
                  value={details.days_ago}
                  onChange={onChange}
                  ref={register({
                    min: 1,
                    max: 15,
                  })}
                />
                {errors.days_ago && (
                  <ErrorMsg className='margin-top-5'>
                    value must be between [1-15] or empty
                  </ErrorMsg>
                )}
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>Display</WPSLabel>
                <WPSSelect
                  name='display'
                  value={details.display}
                  options={ArrayHelper.buildSelectOptions(
                    globalHelper.activityLogsServiceDisplayOptions,
                    'name',
                    'value',
                  )}
                  onChange={onChange}
                  sortOff={true}
                />
              </WPSForm.RowItem>
            </WPSForm.Row>
            <div style={{ display: 'flex' }}>
              <WPSButton
                type='submit'
                className='action--read'
                style={{ maxWidth: '100px' }}
                disabled={isEmpty(details)}
                loading={loading}>
                Load
              </WPSButton>
            </div>
          </WPSForm.Fieldset>
        </WPSForm>}
        <WPSDataTable
          loading={loading}
          columns={headers}
          body={logsData}
          dataKey='id'
          rowsPerPage={50}
        />
        {viewer.isOpen && DialogHelper.jsonViewer({
          title: viewer.title,
          onClose: handleViewerClose,
          data: viewer.data,
        })}
      </Content>
    </Fragment>
  );
};

export default ActivityLogs;
