import styled from 'styled-components';
import theme from 'styles/theme';

export const ProfilePage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.section`
  margin: 24px 0;
  h2 {
    margin-bottom: 24px;
  }
  > button {
    margin-top: 24px;
  }
  form {
    max-width: 600px;
  }
  .titlebar {
    box-shadow: none;
  }
`;

export const PiesContainer = styled.div`
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  height: 100%;
  .titles {
    > * {
      flex: 1;
      text-align: center;
    }
    display: flex;
  }
`;

export const PlanDetails = styled.ul`
  margin: 16px 0;
  li {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }
  strong {
    min-width: 125px;
  }
  span {
    color: ${({ theme }) => theme.color.placeholder};
  }
`;

export const CDNStatus = styled.ul`
  margin-top: 16px;
  li {
    display: flex;
    align-items: center;
    padding: 8px 0;
    span:first-child {
      display: inline-block;
      width: 64px;
    }
  }
`;
export const DomainStep = CDNStatus;
export const StepperStep = CDNStatus;

export const SFTPDetails = styled.ul`
  margin-top: 16px;
  li {
    display: flex;
    align-items: center;
    padding: 8px 0;
    span:first-child {
      display: inline-block;
      width: 64px;
    }
  }
  .sftp-input {
    max-width: 380px;
    min-width: 380px;
  }
  .sftp-icon {
    position: relative;
    right: -350px;
    bottom: 25px;
  }
`;

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin: 12px;
  }
`;

export const AnalyticsCard = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px !important;
  width: 100%;
  li {
    flex: 1;
    max-width: 230px;
    border: 1px solid ${theme.border.card};
    padding: 20px 14px 20px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 7px;
    color: ${theme.color.info};
    h4 {
      display: flex;
      align-items: center;
      margin: ${props => props.titleMargin ? props.titleMargin : '10px auto'};
      text-align: center;
      margin-bottom: 12px;
    }
    svg {
      width: ${props => props.iconWidth ? props.iconWidth : '50px'}; ;
      height: ${props => props.iconHeight ? props.iconHeight : '40px'};
      color: ${props => props.iconColor ? props.iconColor : theme.color.card};
      margin: 0px auto;
      path {
        stroke: currentColor;
      }
    }
  }
`;
