const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const DEFAULT_HOSTING_DOMAIN = process.env.REACT_APP_DEFAULT_HOSTING_DOMAIN;
const BRAND_SHORT_NAME = process.env.REACT_APP_BRAND_SHORT_NAME;
const BRAND_SITEURL = process.env.REACT_APP_BRAND_SITEURL;
const BRAND_SLUG = process.env.REACT_APP_BRAND_SLUG;
const API_LOGIN = `${BASE_API_URL}/api/v2/auth/login`;
const API_LOGIN_2FA = `${BASE_API_URL}/api/v2/auth/two-factor-login`;
const API_LOGOUT = `${BASE_API_URL}/api/v2/auth/logout`;
const API_PREFIX = localStorage.getItem('api_prefix')
  ? `/${localStorage.getItem('api_prefix')}`
  : '/api/v2/';
const API_ENDPOINT = `${BASE_API_URL}${API_PREFIX}`;
const DEBUG_MODE = false;

const buildFileManagerApiEndpoint = (website, path) => {
  const FM_BASE_URL = `https://filemanager.${website.server_slug}.${website.region_slug}.${DEFAULT_HOSTING_DOMAIN}`;
  const FM_API_PREFIX = `/api/v1/filemanager/${website.slug}`;
  path = path || '';
  return FM_BASE_URL + FM_API_PREFIX + path;
}

const buildApiEndpoint = path => {
  const API_PREFIX = localStorage.getItem('api_prefix')
    ? `/${localStorage.getItem('api_prefix')}`
    : '/api/v2/guest/';

  return BASE_API_URL + API_PREFIX + path;
};

const guestApiEndpoint = path => `${BASE_API_URL}/api/v2/guest/${path}`;

const getBrandShortName = () => BRAND_SHORT_NAME;

const getBrandSiteUrl = (path = '') => BRAND_SITEURL + '/' + path.trimLeft('/');

const getBrandDomain = () => BRAND_SITEURL.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');

const getDashboardDomain = () => getBrandDomain().replace('www.', 'app.');

const getKnowledgeBaseUrl = (path = '') => getBrandSiteUrl('knowledgebase/' + path.trimLeft('/'));

const getS3MediaBucket = (regionSlug) => `${BRAND_SLUG}-${regionSlug}-media`;

const isProduction = () => NODE_ENV === 'production';

const offlineMode = false; // Whether to use offline mode.
const ignoreErrors = window.location.hostname === 'localhost'; // Whether to ignore sending errors to API.

const env = {
  NODE_ENV,
  BASE_API_URL,
  API_LOGIN,
  API_LOGIN_2FA,
  API_LOGOUT,
  API_PREFIX,
  API_ENDPOINT,
  DEBUG_MODE,
  buildApiEndpoint,
  buildFileManagerApiEndpoint,
  guestApiEndpoint,
  getBrandShortName,
  getBrandSiteUrl,
  getBrandDomain,
  getDashboardDomain,
  getKnowledgeBaseUrl,
  getS3MediaBucket,
  isProduction,
  offlineMode,
  ignoreErrors,
};

export default env;
