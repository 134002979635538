import React, { useState, useEffect, Fragment } from 'react';
import { WPSForm } from '../../styles/layout/forms';
import Modal from '../../components/layout/modal';
import { useForm } from 'react-hook-form';
import FormHelper from '../../helpers/form';
import UserHelper from 'helpers/user';
import ArrayHelper from 'helpers/array';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';

const SaveFeature = props => {
  const { modal, setModal, addNew, updateExisting, currentFeature, setCurrentFeature } = props;
  const { handleSubmit, register, triggerValidation, errors } = useForm({
    reValidateMode: 'onSubmit',
  });

  const initialState = {
    name: '',
    type: 'limit',
    description: '',
    unit: '',
    limit: 0,
    is_hidden: false,
    allow_extra_units: false,
    is_proratable: false,
    extra_unit_price: '0.00',
    value: '',
  };

  const [feature, setFeature] = useState(initialState);

  // Set current feature to state (updating feature).
  useEffect(() => {
    if (currentFeature) {
      setFeature(prev => ({ ...prev, ...currentFeature }));
    }
  }, [currentFeature]);

  // Limit type feature data.
  const initLimitData = () => {
    return {
      name: feature.name,
      type: 'limit',
      description: feature.description,
      unit: feature.unit,
      limit: parseInt(feature.limit),
      is_hidden: feature.is_hidden,
      allow_extra_units: feature.allow_extra_units,
      is_proratable: feature.is_proratable,
      extra_unit_price: feature.extra_unit_price,
      value: '',
    };
  };

  // Basic type feature data.
  const initBasicData = () => {
    return {
      name: feature.name,
      type: 'basic',
      description: feature.description,
      value: feature.description,
    };
  };

  // OnChange handler.
  const onChange = e => {
    const { name, type } = e.target;
    let value = type === 'checkbox' ? e.target.checked : e.target.value;
    if (name === 'limit') {
      value = isNaN(value) || !value ? 0 : parseInt(value);
    }
    if (name === 'limit' && value <= 0) {
      setFeature(prev => ({ ...prev, extra_unit_price: '0.00', allow_extra_units: false }));
    }
    setFeature(prev => ({ ...prev, [name]: value }));
  };

  // Handle price field onBlur event.
  const handleOnBlur = e => {
    const { value } = e.target;
    const _value = FormHelper.normalizePrice(value);
    setFeature(prev => ({ ...prev, extra_unit_price: _value }));
  };

  // OnSubmit handler for feature modal.
  const onSubmit = async () => {
    // Custom validation for unit price field.
    const validation = await triggerValidation('extra_unit_price');
    if (!validation && feature.allow_extra_units && feature.type === 'limit') {
      return;
    }
    // New feature
    if (!currentFeature) {
      let data;
      if (feature.type === 'limit') {
        data = initLimitData();
      } else {
        data = initBasicData();
      }
      addNew(data);
    } else {
      // Update feature
      updateExisting(feature);
      setCurrentFeature(null);
    }
    setModal(false);
    setFeature(initialState);
  };

  // Dismiss modal and reset state on modal close.
  const handleModalClose = () => {
    setModal(false);
    setFeature(initialState);
    setCurrentFeature(null);
  };

  return (
    modal && (
      <Modal
        title={currentFeature ? 'Edit Feature' : 'Add New Feature'}
        onClose={handleModalClose}
        onConfirm={handleSubmit(onSubmit)}
        confirmColor='add-btn'>
        <WPSForm onSubmit={e => e.preventDefault()}>
          <WPSForm.Row>
            <WPSForm.RowItem>
              {JsxHelper.createTextInput({
                label: 'Name',
                name: 'name',
                value: feature.name,
                onChange,
                disabled: currentFeature ? currentFeature.is_template : false,
                required: true,
              })}
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              {JsxHelper.createSelectInput({
                label: 'Type',
                name: 'type',
                value: feature.type,
                options: ArrayHelper.buildSelectOptions(globalHelper.featuresTypes, 'name', 'value'),
                disabled: currentFeature ? currentFeature.is_template : false,
                onChange,
                required: true,
              })}
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              {JsxHelper.createTextInput({
                label: 'Description',
                name: 'description',
                value: feature.description,
                onChange,
                required: true,
              })}
            </WPSForm.RowItem>
          </WPSForm.Row>

          {feature.type === 'limit' && (
            <Fragment>
              <WPSForm.Row>
                <WPSForm.RowItem>
                  {JsxHelper.createTextInput({
                    label: 'Unit',
                    name: 'unit',
                    value: feature.unit,
                    onChange,
                    disabled: currentFeature ? currentFeature.is_template : false,
                    required: true,
                  })}
                </WPSForm.RowItem>
                <WPSForm.RowItem>
                  {JsxHelper.createTextInput({
                    label: 'Limit',
                    name: 'limit',
                    value: feature.limit,
                    onChange,
                    required: true,
                    tooltip: 'Insert zero for unlimited option',
                  })}
                </WPSForm.RowItem>
              </WPSForm.Row>
              <WPSForm.Row>
                {UserHelper.isAdmin() && (
                  <WPSForm.RowItem direction='row'>
                    {JsxHelper.createToggleInput({
                      label: 'Hidden',
                      name: 'is_hidden',
                      checked: feature.is_hidden,
                      onChange,
                    })}
                  </WPSForm.RowItem>
                )}
                <WPSForm.RowItem direction='row'>
                  {JsxHelper.createToggleInput({
                    label: 'Proratable',
                    name: 'is_proratable',
                    checked: feature.is_proratable,
                    onChange,
                  })}
                </WPSForm.RowItem>
              </WPSForm.Row>
              {feature.limit > 0 && (
                <WPSForm.Row style={{ marginTop: '-25px' }}>
                  <WPSForm.RowItem direction='row'>
                    {JsxHelper.createToggleInput({
                      label: 'Allow extra units',
                      name: 'allow_extra_units',
                      checked: feature.allow_extra_units,
                      onChange,
                    })}
                  </WPSForm.RowItem>
                  {feature.allow_extra_units && (
                    <WPSForm.RowItem>
                      {JsxHelper.createTextInput({
                        label: 'Extra unit price',
                        name: 'extra_unit_price',
                        value: feature.extra_unit_price,
                        onChange,
                        onBlur: handleOnBlur,
                        required: true,
                        ref: register({
                          validate: value => parseFloat(value, 2) > 0,
                        }),
                        errors,
                      })}
                    </WPSForm.RowItem>
                  )}
                </WPSForm.Row>
              )}
            </Fragment>
          )}

          <WPSForm.Row>
            {feature.type === 'basic' && (
              <WPSForm.RowItem>
                {feature.defaults && feature.defaults.value_options ?
                  JsxHelper.createSelectInput({
                    label: 'Value',
                    name: 'value',
                    value: feature.value || feature.defaults.value,
                    options: ArrayHelper.buildSelectOptions(feature.defaults.value_options, 'text', 'value'),
                    onChange,
                    required: true,
                  }) : JsxHelper.createTextInput({
                    label: 'Value',
                    name: 'value',
                    value: feature.value,
                    onChange,
                    required: true,
                  })
                }
              </WPSForm.RowItem>
            )}
          </WPSForm.Row>
        </WPSForm>
      </Modal>
    )
  );
};

export default SaveFeature;
