import styled, { css } from 'styled-components';

export const WPSCard = styled.div`
  display: flex;
  align-items: ${props => (props.align === 'center' ? 'center' : 'initial')};
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  width: 100%;
  min-height: ${props => props.minHeight && props.minHeight};
  margin: 24px 0;
  padding: 24px;
  background: ${props => props.theme.background.light};
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
`;

export const WPSInnerCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: center;
  background: ${props => props.theme.background.info};
  border-radius: ${props => props.theme.border.radius};
  margin-right: 12px;
  color: white;
  .numbers {
    margin-top: 12px;
    font-size: 22px;
  }
`;

export const WPSCardContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 35px;
  margin-top: 24px;
  margin-bottom: 48px;
`;

export const DashboardCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  gap: 24px;
`;

export const WPSPlanCard = styled.li`
  background: white;
  box-shadow: 0 2px 9px rgba(102, 142, 230, 0.15);
  min-width: 280px;
  border-radius: 4px;
  .title {
    padding: 16px;
    background: ${props => props.theme.background.primary};
    color: white;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    h3 {
      margin-bottom: 12px;
    }
    .price {
      display: flex;
      align-items: flex-end;
      .number {
        font-weight: bold;
      }
      span:last-child {
        font-size: 12px;
      }
    }
  }
  .body {
    padding: 16px;
    > * {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      strong {
        flex: 2;
      }
      span {
        flex: 1;
      }
      svg {
        margin-right: 8px;
        color: ${props => props.theme.background.success};
      }
    }
    .item-extra {
      margin-left: 1em;
      margin-top: 0;
    }
    .item-extra span {
      margin-left: 8px;
      font-size: 12px;
    }
    button {
      margin-top: 24px;
    }
  }
`;

export const RaisedCard = styled.div`
  box-shadow: 0 2px 9px 0 rgba(102, 142, 230, 0.17);
  background-color: #fff;
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  border-radius: 4px;
  padding: 16px 0;
  ${props =>
    props.colSpan &&
    css`
      grid-column: span ${props.colSpan};
    `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  h2 {
    color: #727272;
    margin-bottom: 30px;
  }
  ul {
    margin: auto 0;
    align-self: flex-start;
    font-size: 15px;
    li {
      padding: 4px 0;
    }
  }

  .users {
    display: flex;
    width: 100%;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      span:nth-child(2) {
        font-size: 48px;
        margin-top: 18px;
      }
    }
  }
  .link {
    text-decoration: underline;
    color: #727272;
    font-size: 12px;
    margin-top: auto;
  }
`;

const getColor = color => {
  let newColor = '#19274E';
  switch (color) {
    case 'primary':
      newColor = '#676AFF';
      break;
    default:
  }
  return newColor;
};

export const titleMixin = css`
  color: ${({ color }) => (color ? getColor(color) : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  font-size: 18px;
  padding: 0 16px;
`;

export const CardHeader = styled.div`
  ${titleMixin}
  padding: 0 16px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

export const CardTitle = styled.div`
  ${titleMixin}
`;

export const CardText = styled.div`
  color: ${({ color }) => (color ? getColor(color) : null)};
  font-size: 14px;
  line-height: 1.55;
`;

export const CardFooter = styled.div`
  font-size: 14px;
  padding: 20px 16px;
  text-align: center;
  border-top: 1px solid rgba(146, 171, 197, 0.3);
  margin-bottom: -16px;
  display: flex;
  justify-content: center;
`;
