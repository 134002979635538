import { createSelector } from 'reselect';
import { isEmpty } from '../../helpers';

/*
  Input
*/
const plans = state => state.billing.plans;
const subscriptions = state => state.billing.subscriptions;
const planCategories = state => state.billing.plan_categories;
const planSubcategories = state => state.billing.plan_subcategories;
const invoiceDetails = state => state.billing.invoice_details;
const billingDetails = state => state.billing.details;
const billingPeriod = state => state.billing.current_period;
const hasPaymentMethods = state => state.billing.has_payment_methods;
const invoices = state => state.billing.invoices;

/*
  Selectors
*/

// Tells whether the user has payment methods
export const hasPaymentMethodsSelector = createSelector(hasPaymentMethods, status => status);

// Return all pricing plans
export const plansSelector = createSelector(plans, plans => plans);

// Return all plans by plan category
export const plansByCategoryType = categoryType =>
  createSelector(plans, plans => plans.filter(p => p.category_type === categoryType));

// Return plan categories
export const planCategoriesSelector = createSelector(planCategories, categories => categories);

// Return plan subcategories
export const planSubcategoriesSelector = createSelector(
  planSubcategories,
  subcategories => subcategories,
);

// Return all partner subscriptions
export const subscriptionsSelector = createSelector(subscriptions, subscriptions => subscriptions);

// Return all active subscriptions
export const activeSubscriptionsSelector = createSelector(subscriptions, subscriptions =>
  subscriptions.filter(subscription => subscription.is_active === true),
);

// Return all active subscriptions by resource type
export const activeSubscriptionsByType = type => {
  type = type === 'website' ? 'website_hosting' : type;
  return createSelector(
    subscriptions,
    subscriptions => {
      if (isEmpty(subscriptions)) {
        return [];
      }
      return subscriptions.filter(
        subscription =>
          subscription.is_active === true && subscription.plan_subcategory_slug.includes(type),
      );
    }
  );
};

// Return all active subscriptions
export const activeSubscriptions = () => {
  return createSelector(
    subscriptions,
    subscriptions =>
      !isEmpty(subscriptions) &&
      subscriptions.filter(
        subscription =>
          subscription.is_active === true && subscription.plan_subcategory_slug.includes('website_hosting')
      ),
  );
};

// Get all plans for specified subcategory
export const pricingPlanBySubcategory = subcategory => {
  return createSelector(plans, plans =>
    plans.filter(plan => plan.subcategory_slug === subcategory),
  );
};

// Get subscriptions by plan slug
export const subscriptionsByPlan = planSlug => {
  return createSelector(subscriptions, subscriptions =>
    subscriptions.filter(subscription => subscription.plan_slug === planSlug),
  );
};

// Get admin billing details (Invoice details).
export const adminInvoiceDetails = createSelector(invoiceDetails, details => details);

// Return partner billing details
export const partnerBillingDetails = createSelector(billingDetails, details => details);

// Return all invoices.
export const billingInvoices = createSelector(invoices, invoices => invoices);

// Return billing period
export const billingPeriodSelector = createSelector(billingPeriod, period => period);