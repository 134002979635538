import React, { useEffect, Fragment } from 'react';
import {
  WPSForm,
  WPSInput,
  WPSLabel,
  ErrorMsg,
  InputTip,
  WPSCheckbox,
  WPSCheckboxLabel,
} from 'styles/layout/forms';
import { useForm } from 'react-hook-form';
import { WPSButton, WPSBubble } from 'styles/layout/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import SaveFeature from './createFeature';
import {
  planCategoriesSelector,
  planSubcategoriesSelector,
} from 'store/billing/billingSelectors';
import { createPartnerPlan, updatePlan } from 'store/billing/billingActions';
import {
  createCustomerPlan,
  updateCustomerPlan,
} from 'store/customerBilling/customerBillingActions';
import { partnersSelector } from 'store/user/userSelectors';
import { customersSelector } from 'store/customer/customerSelectors';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { isEmpty, isEmptyOrNull } from 'helpers';
import FormHelper from 'helpers/form';
import StringHelper from 'helpers/string';
import MathHelper from 'helpers/math';
import UserHelper from 'helpers/user';
import Can from 'utils/can';
import ArrayHelper from 'helpers/array';
import PlanHelper from 'helpers/plan';
import { useHistory } from 'react-router-dom';
import useConfirm from 'hooks/useConfirm';
import Tooltip from 'components/layout/tooltip';
import Modal from 'components/layout/modal';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import DialogHelper from 'helpers/dialog';

const SavePlan = ({ plan, onSavePlan }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onSubmit' });
  const {
    handleSubmit: handleDiscountSubmit,
    register: registerDiscount,
    errors: errorsDiscount,
  } = useForm({
    reValidateMode: 'onSubmit',
  });
  const [featureModal, setFeatureModal] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(false);

  const defaultDiscountFormData = {
    subscriber_slug: '',
    entity: 'price',
    type: 'new_price',
    amount: '0.00',
    rules: [
      {
        operand1: 'active_subscriptions_size',
        operator: 'gte',
        operand2: '0',
      },
    ],
  };

  const planCategories = useSelector(planCategoriesSelector);
  const planSubcategories = useSelector(planSubcategoriesSelector);
  const partners = useSelector(partnersSelector);
  const customers = useSelector(customersSelector);
  const [discountRows, setDiscountRows] = useState([]);
  const [discountModal, setDiscountModal] = useState(false);
  const [discountFormData, setDiscountFormData] = useState(defaultDiscountFormData);

  // Set assigness based on logged in user role (for the field: assignee_slug).
  // Admins take in partners and partners take in customers.
  const assignees = UserHelper.isAdmin() ? partners : UserHelper.isPartner() ? customers : [];

  const [currentFeature, setCurrentFeature] = useState(null);
  const [formData, setFormData] = useState({
    plan_category_slug: !isEmpty(planCategories) ? planCategories[0].slug : '',
    plan_subcategory_slug: !isEmpty(planSubcategories) ? planSubcategories[0].slug : '',
    name: '',
    description: '',
    price: '0.00',
    interval: 'month',
    interval_count: 1,
    charged_immediately: false,
    is_default: false,
    allow_multi_subs: false,
    assignee_slug: '',
    features: [],
    discounts: [],
  });

  const findCategory = slug => {
    slug = slug || plan.category_slug;
    return ArrayHelper.find(planCategories, 'slug', slug);
  };

  const findSubcategory = slug => {
    return planSubcategories.find(sc => sc.slug === slug);
  };

  const onNewPlan = () => {
    const category = findCategory(formData.plan_category_slug);
    const subcategories = ArrayHelper.filterIn(planSubcategories, 'category_slug', category.slug);
    setSubCategories(subcategories);
  };

  const getCategoryDefaultFeatures = (slug) => {
    const category = findCategory(slug);
    return PlanHelper.filterFeaturesByUserRole(category.defaults.features);
  }

  const getFeatureDefaults = (categorySlug, featureCode) => {
    const _defaults = getCategoryDefaultFeatures(categorySlug);
    return _defaults.find(f => f.code === featureCode);
  }

  const onChangeSubcategory = () => {
    const selectedSubcategory = findSubcategory(formData.plan_subcategory_slug);
    setSelectedSubcategory(selectedSubcategory);
    const defaultFeaturesByUser = getCategoryDefaultFeatures(formData.plan_category_slug);
    const defaultFeatures = PlanHelper.filterFeaturesBySubcategory(defaultFeaturesByUser, selectedSubcategory);

    // By default set allow multiple subscriptions to true for non-upgrade only plans
    setFormData(prev => ({
      ...prev,
      plan_subcategory_slug: selectedSubcategory.slug,
      allow_multi_subs: false,
      features: defaultFeatures,
    }));
  };

  useEffect(() => {
    if (!plan) {
      onNewPlan();
    }
    // eslint-disable-next-line
  }, [planCategories, formData.plan_category_slug]);

  useEffect(() => {
    if (!plan) {
      onChangeSubcategory();
    }
    // eslint-disable-next-line
  }, [formData.plan_subcategory_slug, formData.plan_category_slug]);

  // Update plan?
  useEffect(() => {
    if (plan) {
      setFormData(plan);
      setDiscountRows((plan.discounts || []).map(d => ({ ...d, slug: StringHelper.randomSlug() })));
    }
    // eslint-disable-next-line
  }, []);

  // OnChange handler for create plan form.
  const onChange = e => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData(prev => ({ ...prev, [name]: value }));

    // A custom plan cannot be set as default
    if (name === 'assignee_slug' && value) {
      setFormData(prev => ({ ...prev, is_default: false }));
    }
  };

  // Handle discount form field changes
  const onDiscountChange = e => {
    const { name, value } = e.target;
    if (['operand1', 'operand2', 'operator'].includes(name)) {
      const rules = discountFormData.rules;
      rules[0][name] = value;
      setDiscountFormData(prev => ({ ...prev, rules: rules }));
    } else {
      setDiscountFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleDiscountAmountOnBlur = e => {
    const { value } = e.target;
    const _value = FormHelper.normalizePrice(value);
    setDiscountFormData(prev => ({ ...prev, amount: _value }));
  };

  // Handle discount form save
  const onDiscountSubmit = () => {
    let data = [...discountRows];
    let _discountFormData = discountFormData;
    if (_discountFormData.slug) {
      const i = data.findIndex(d => d.slug === _discountFormData.slug);
      if (i !== -1) {
        data[i] = _discountFormData;
      } else {
        dispatch(setGlobalErrorMsg('Oops! Something went wrong.'));
        return;
      }
    } else {
      _discountFormData.slug = StringHelper.randomSlug();
      data.unshift({ ..._discountFormData });
    }
    setDiscountRows([...data]);
    setDiscountModal(false);
    setDiscountFormData(defaultDiscountFormData);
  };

  const initApiParams = () => {
    return {
      plan_category_slug: formData.plan_category_slug,
      plan_subcategory_slug: formData.plan_subcategory_slug,
      name: formData.name,
      description: formData.description,
      price: formData.price,
      interval: formData.interval,
      interval_count: formData.interval_count,
      charged_immediately: formData.charged_immediately,
      is_default: formData.is_default,
      allow_multi_subs: formData.allow_multi_subs,
      assignee_slug: formData.assignee_slug,
      // Return features with value field added.
      features: formData.features.map(f => {
        f.value = PlanHelper.getFeatureValue(f.value || null, f);
        return f;
      }),
      discounts: discountRows,
    };
  };

  // Handle onSubmit event for create plan.
  const onSubmit = () => {
    let data = initApiParams();
    setLoading(true);
    const savePlan = data => {
      // Create new plan
      if (!plan) {
        return UserHelper.isAdmin()
          ? createPartnerPlan(data)
          : UserHelper.isPartner()
          ? createCustomerPlan(data)
          : null;
      } else {
        // Update plan
        data.plan_slug = plan.slug;
        return UserHelper.isAdmin()
          ? updatePlan(data)
          : UserHelper.isPartner()
          ? updateCustomerPlan(data)
          : null;
      }
    };
    dispatch(savePlan(data))
      .then(() => {
        if (onSavePlan) {
          onSavePlan();
        } else {
          history.goBack();
        }
        dispatch(setGlobalSuccessMsg({ model: 'plan' }));
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  // Handle price field onBlur event.
  const handleOnBlur = e => {
    const { value } = e.target;
    const _value = FormHelper.normalizePrice(value);
    setFormData(prev => ({ ...prev, price: _value }));
  };

  // Add new feature to state.
  const addNewFeature = feature => {
    setFormData(prev => ({ ...prev, features: [...prev.features, feature] }));
  };

  // Handle update feature
  const updateExistingFeature = feature => {
    // If sites_included is not 1 then remove trial_days_dev
    let features = formData.features;
    if (feature.code === 'sites_included' && feature.value !== 1) {
      features = features.filter(f => f.code !== 'trial_days_dev');
    }
    // Update features
    setFormData(prev => ({
      ...prev,
      features: features.map(f => (f.name === feature.name ? feature : f)),
    }));
  };

  // Handle feature deletion.
  const deleteFeature = feature => {
    DialogHelper.confirmRemove(confirm, feature.name, 'feature')
                .then(() => setFormData(prev => ({
                  ...prev,
                  features: prev.features.filter(f => f.name !== feature.name),
                })));
  };

  // Features table actions.
  const featureActions = [
    {
      value: 'Edit',
      onClick: item => {
        setCurrentFeature({ ...item, defaults: getFeatureDefaults(plan ? plan.category_slug : formData.plan_category_slug, item.code)});
        setFeatureModal(true);
      },
    },
    {
      value: 'Remove',
      doHide: item => item.is_used_by_system,
      onClick: deleteFeature,
    },
  ];

  // Features table headers
  const featureHeaders = [
    JsxHelper.createTableTextHeader('name', 'Name', '200px'),
    JsxHelper.createTableTextHeaderWithCallback('value', 'Value', '150px', (row) => PlanHelper.getFeatureValue(row.value, row)),
    JsxHelper.createTableTextHeader('description', 'Description'),
    JsxHelper.createTableTextHeaderWithCallback('allow_extra_units', 'Extra unit price', '150px', row => (row.allow_extra_units ? row.extra_unit_price : 'Not allowed')),
    JsxHelper.createTableActionsHeader(featureActions, '100px'),
  ];

  // Handle discount deletion.
  const deleteDiscount = discount => {
    setDiscountRows(prev => prev.filter(d => d.slug !== discount.slug));
  };

  const discountActions = [
    {
      value: 'Edit',
      onClick: item => {
        setDiscountFormData(item);
        setDiscountModal(true);
      },
    },
    {
      value: 'Remove',
      onClick: deleteDiscount,
    },
  ];

  const discountHeaders = [
    {
      name: 'Subscriber',
      selector: 'subscriber_slug',
      sortable: true,
      searchable: true,
      width: '20%',
      cell: row => (isEmptyOrNull(row.subscriber_slug) ? 'Any' : row.subscriber_slug),
    },
    {
      name: 'Condition',
      selector: 'condition',
      sortable: true,
      searchable: true,
      width: '30%',
      cell: row => {
        let op1 = row.rules[0].operand1;
        if (op1 === 'active_subscriptions_size') {
          op1 = 'Number of active subscriptions';
        }
        const op = MathHelper.textToSymbol(row.rules[0].operator);
        const op2 = row.rules[0].operand2;
        return `${op1} ${op} ${op2}`;
      },
    },
    {
      name: 'Entity',
      selector: 'entity',
      sortable: true,
      searchable: true,
      width: '17%',
      cell: row => StringHelper.toText(row.entity === 'price' ? 'Base price' : row.entity),
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      searchable: true,
      width: '10%',
      cell: row => StringHelper.toText(row.type),
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      searchable: true,
      width: '10%',
      interpolate: row => {
        const _value = StringHelper.toText(row.amount);
        if (row.type === 'percentage') {
          return _value + '%';
        }
        return _value;
      },
    },
    JsxHelper.createTableActionsHeader(discountActions, '13%'),
  ];
  return (
    <Fragment>
      <WPSForm onSubmit={handleSubmit(onSubmit)}>
        {!plan && (
          <WPSForm.Row>
            <WPSForm.RowItem style={UserHelper.isAdmin() ? {}:{maxWidth:'50%'}}>
              <WPSLabel>Category</WPSLabel>
              <WPSSelect
                name='plan_category_slug'
                value={formData.plan_category_slug}
                options={ArrayHelper.buildSelectOptions(planCategories, 'name', 'slug')}
                onChange={onChange}
                isSearchable={true}
              />
            </WPSForm.RowItem>
            {UserHelper.isAdmin() && (
              <WPSForm.RowItem>
                <WPSLabel>Subcategory</WPSLabel>
                <WPSSelect
                  name='plan_subcategory_slug'
                  value={formData.plan_subcategory_slug}
                  options={ArrayHelper.buildSelectOptions(subCategories, 'name', 'slug')}
                  onChange={onChange}
                  isSearchable={true}
                />
                {!isEmptyOrNull(formData.plan_subcategory_slug) && selectedSubcategory.upgrade_only && (
                  <div className='input-tip'>
                    <InputTip>
                      The selected subcategory is upgrade-only
                      <Tooltip
                        place='right'
                        text='Upgrade-only plan means that a subscriber may have only a single active subscription within the same subcategory at any given time. In order to by pass this option, please enable "Allow multiple subscriptions" option using the checkbox below.'
                      />
                    </InputTip>
                  </div>
                )}
              </WPSForm.RowItem>
            )}
          </WPSForm.Row>
        )}

        <WPSForm.Row>
          <WPSForm.RowItem>
            <WPSLabel required>
              Name
              {errors.name && <ErrorMsg>{errors.name.message}</ErrorMsg>}
            </WPSLabel>
            <WPSInput
              name='name'
              value={formData.name}
              onChange={onChange}
              ref={register({ required: FormHelper.messages.required })}
            />
          </WPSForm.RowItem>
          <WPSForm.RowItem>
            <WPSLabel required>
              Description
              {errors.description && <ErrorMsg>{errors.description.message}</ErrorMsg>}
            </WPSLabel>
            <WPSInput
              name='description'
              value={formData.description}
              onChange={onChange}
              ref={register({ required: FormHelper.messages.required })}
            />
          </WPSForm.RowItem>
        </WPSForm.Row>

        <WPSForm.Row>
          <WPSForm.RowItem>
            <WPSLabel required>
              Base price
              {errors.price && <ErrorMsg>{errors.price.message}</ErrorMsg>}
            </WPSLabel>
            <WPSInput
              type='number'
              name='price'
              value={formData.price}
              onChange={onChange}
              onBlur={handleOnBlur}
              ref={register({ required: FormHelper.messages.required })}
            />
          </WPSForm.RowItem>
          <WPSForm.RowItem>
            <WPSLabel>Interval count</WPSLabel>
            <WPSInput
              type='number'
              min='1'
              step='1'
              name='interval_count'
              value={formData.interval_count}
              disabled={true}
              onChange={onChange}
            />
          </WPSForm.RowItem>
          <WPSForm.RowItem>
            <WPSLabel>Interval</WPSLabel>
            <WPSSelect
              name='interval'
              value={formData.interval}
              options={ArrayHelper.buildSelectOptions(globalHelper.intervals, 'name', 'value')}
              onChange={onChange}
              disabled={true}
            />
          </WPSForm.RowItem>
        </WPSForm.Row>
        <WPSForm.Row>
          <WPSForm.RowItem direction='row'>
            <WPSCheckboxLabel>
              <WPSCheckbox
                type='checkbox'
                name='is_default'
                checked={formData.is_default}
                disabled={formData.assignee_slug}
                onChange={onChange}
              />
              &nbsp; Set as default plan
              <Tooltip
                place='right'
                text={`
                  By checking this box the plan will be set as the default within the same plans category.`}
              />
            </WPSCheckboxLabel>
          </WPSForm.RowItem>
          <WPSForm.RowItem direction='row'>
            {
              // Partners don't hav access to create upgrade-only plans so no need to show them
              UserHelper.isAdmin() && (
                <WPSCheckboxLabel>
                  <WPSCheckbox
                    disabled={!selectedSubcategory.upgrade_only}
                    type='checkbox'
                    name='allow_multi_subs'
                    checked={formData.allow_multi_subs}
                    onChange={onChange}
                  />
                  &nbsp; Allow multiple subscriptions
                  <Tooltip
                    place='right'
                    text='By checking this box the upgrade-only constraint will be disabled so users may subscribe to this plan multiple times.'
                  />
                </WPSCheckboxLabel>
              )
            }
          </WPSForm.RowItem>
          <WPSForm.RowItem>
            <WPSLabel>Assigned to</WPSLabel>
            <WPSSelect
              name='assignee_slug'
              value={formData.assignee_slug || ''}
              options={ArrayHelper.buildSelectOptions(assignees, 'display_name', 'slug', `All ${UserHelper.isAdmin() ? 'partners' : 'clients'}`)}
              onChange={onChange}
              disabled={plan ? true : false}
              isSearchable={true}
            />
          </WPSForm.RowItem>
        </WPSForm.Row>

        <WPSForm.Fieldset>
          <legend>Features</legend>
          {UserHelper.isPartner() &&
            formData.plan_category_slug &&
            !formData.plan_category_slug.includes('website') && (
              <WPSBubble
                display='inline'
                color='warning'
                style={{ marginLeft: '-3px' }}
                icon='warning'>
                The selected category has no support for recording features usage yet.
              </WPSBubble>
            )}
          <Can
            perform='plan-feature:create:*'
            yes={() => JsxHelper.createButton({ label: 'Add Feature', onClick: () => setFeatureModal(true) })}
          />
          <WPSDataTable
            columns={featureHeaders}
            body={formData.features || []}
            noSearchOnTable={true}
            rowsPerPage={25}
            
          />
        </WPSForm.Fieldset>
        {UserHelper.isAdmin() && formData.allow_multi_subs && (
          <WPSForm.Fieldset>
            <legend>Discounts</legend>
            {JsxHelper.createButton({
              label: 'Add Discount',
              onClick: () => setDiscountModal(true),
            })}
            <WPSDataTable
              columns={discountHeaders}
              body={discountRows || []}
              noSearchOnTable={false}
              rowsPerPage={10}
            />
          </WPSForm.Fieldset>
        )}

        <WPSButton type='submit' className='save--btn max-width-150' loading={loading}>
          Save
        </WPSButton>
      </WPSForm>
      <SaveFeature
        modal={featureModal}
        setModal={setFeatureModal}
        currentFeature={currentFeature}
        setCurrentFeature={setCurrentFeature}
        addNew={addNewFeature}
        updateExisting={updateExistingFeature}
      />
      {discountModal && (
        <Modal
          title={'Add Discount'}
          maxWidth='950'
          onClose={() => setDiscountModal(false)}
          onConfirm={handleDiscountSubmit(onDiscountSubmit)}>
          <WPSForm onSubmit={e => e.preventDefault()}>
            <div className='display-flex-nowrap'>
              <WPSForm.RowItem>
                <WPSLabel>Subscriber</WPSLabel>
                <WPSSelect
                  name='subscriber_slug'
                  value={
                    isEmptyOrNull(formData.assignee_slug)
                      ? discountFormData.subscriber_slug
                      : formData.assignee_slug
                  }
                  options={ArrayHelper.buildSelectOptions(assignees, 'display_name', 'slug', 'All partners')}
                  onChange={onDiscountChange}
                  disabled={isEmpty(formData.assignee_slug) ? false : true}
                  isSearchable={true}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem>
                <WPSLabel required>Applied to</WPSLabel>
                <WPSSelect
                  name='entity'
                  value={discountFormData.entity}
                  options={ArrayHelper.buildSelectOptions(
                    [{ name: 'Base price', value: 'price' }],
                    'name',
                    'value',
                  )}
                  onChange={onDiscountChange}
                />
              </WPSForm.RowItem>
            </div>
            <div className='display-flex-nowrap'>
              <WPSForm.RowItem>
                <WPSLabel required>Discount type</WPSLabel>
                <WPSSelect
                  name='type'
                  value={discountFormData.type}
                  options={ArrayHelper.buildSelectOptions(
                    globalHelper.discountsTypes,
                    'name',
                    'value',
                  )}
                  onChange={onDiscountChange}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem>
                <WPSLabel required>
                  Discount amount
                  {errorsDiscount.amount && <ErrorMsg>{errorsDiscount.amount.message}</ErrorMsg>}
                </WPSLabel>
                <WPSInput
                  name='amount'
                  value={discountFormData.amount}
                  onChange={onDiscountChange}
                  onBlur={handleDiscountAmountOnBlur}
                  ref={registerDiscount({ required: FormHelper.messages.required })}
                />
              </WPSForm.RowItem>
            </div>
            <div className='display-flex-nowrap'>
              <WPSForm.RowItem>
                <WPSLabel required>Discount operand</WPSLabel>
                <WPSSelect
                  name='operand1'
                  value={discountFormData.rules[0].operand1}
                  options={ArrayHelper.buildSelectOptions(
                    [
                      {
                        name: 'Number of active subscriptions',
                        value: 'active_subscriptions_size',
                      },
                    ],
                    'name',
                    'value',
                  )}
                  onChange={onDiscountChange}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem>
                <WPSLabel>Should be</WPSLabel>
                <WPSSelect
                  name='operator'
                  value={discountFormData.rules[0].operator}
                  options={ArrayHelper.buildSelectOptions(
                    globalHelper.discountOperatorOptions,
                    'name',
                    'value',
                  )}
                  onChange={onDiscountChange}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem>
                <WPSLabel required>
                  Rule operand
                  {errorsDiscount.value && <ErrorMsg>{errorsDiscount.value.message}</ErrorMsg>}
                </WPSLabel>
                <WPSInput
                  name='operand2'
                  value={discountFormData.rules[0].operand2}
                  onChange={onDiscountChange}
                  ref={registerDiscount({ required: FormHelper.messages.required })}
                />
              </WPSForm.RowItem>
            </div>
          </WPSForm>
        </Modal>
      )}
    </Fragment>
  );
};

export default SavePlan;
