import React, { Fragment, useState, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import DetailsPartner from 'pages/billing/partner/details';
import PaymentPartner from 'pages/billing/payment';
import env from '../../config/env';
import { useHistory } from 'react-router-dom';
import JsxHelper from 'helpers/jsx';

const BillingSetup = () => {
  const stepperRef = useRef();
  const history = useHistory();

  const initStep = stepNum => {
    let iterationNumber = 2;

    for (var i = 1; i <= iterationNumber; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];

      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!refDisabledSteps.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add('complete-color');
      }
    }
    setCurrentStepForData(stepNum);
  };

  // eslint-disable-next-line
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Business Details',
      onClick: () => initStep(1),
    },
    {
      title: 'Credit Cards',
      onClick: () => initStep(2),
    },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(1);
  const [disabledStepsData, setDisabledStepsData] = useState([1]);
  const refDisabledSteps = useRef([1]);

  const changeSteps = newIndex => {
    setCurrentStep(newIndex);
    let data = disabledStepsData;
    data.push(currentStep - 1);
    let fitleredSteps = data.filter(el => el !== newIndex - 1);
    setDisabledStepsData(fitleredSteps);
  };

  const handleStepperSecondStep = () => {
    changeSteps(2);
    refDisabledSteps.current = [];
    setDisabledStepsData([]);
    setCurrentStepForData(2);
    initStep(2);
  };
  const handleStepperSave = () => {
    history.push('/')
  };
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing Setup',
    },
  ];
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Billing Setup</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ position: 'relative' }}>
        <div className='create-site-stepper' style={{ left: '-85px' }} ref={stepperRef}>
          {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
        </div>
        <div style={{ maxWidth: '1000px', marginTop: '110px' }}>
          {currentStepForData === 1 && (
            <Fragment>
              <p className='step-subheader'>
                {
                  `Fill and review your business details that will appear on the invoices issued by ${env.getBrandShortName()}, in addition to invoices generated by your business to your clients if you wish to use the Client Billing feature offered by ${env.getBrandShortName()}.`
                }
              </p>
              <DetailsPartner
                isCustomPage={true}
                handleStepperNext={handleStepperSecondStep}
              />
            </Fragment>
          )}
          {currentStepForData === 2 && (
            <Fragment>
              <p className='step-subheader'>
                {
                  `Manage the credit/debit cards used to charge your invoices by ${env.getBrandShortName()}. You can also make any of your cards the default payment method at any time.`
                }
              </p>
              <PaymentPartner isCustomPage={true} handleStepperSave={handleStepperSave} />
            </Fragment>
          )}
        </div>
      </Content>
    </Fragment>
  );
};

export default BillingSetup;
