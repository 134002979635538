import { postRequest, deleteRequest, getRequest } from 'config/axiosRequest';
import env from 'config/env';

const listAccounts = (data) => {
  return getRequest({
    url: env.buildApiEndpoint('cloudflare/accounts'),
    data,
  });
}

const connectAccount = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/accounts'),
    data,
  });
};

const updateAccountAPIKey = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/accounts/' + data.account_id + '/api-key'),
    data,
  });
};

const listAccountAssignableZones = data => {
  return getRequest({
    url: env.buildApiEndpoint('cloudflare/accounts/' + data.account_id + '/assignable-zones'),
    data,
  });
}

const disconnectAccount = data => {
  return deleteRequest({
    url: env.buildApiEndpoint('cloudflare/accounts/' + data.account_id),
    data,
  });
};

const listZones = data => {
  return getRequest({
    url: env.buildApiEndpoint('cloudflare/zones'),
    data,
  });
}

const createZone = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/zones'),
    data,
  });
}

const connectZone = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id + '/connect'),
    data,
  });
}

const getZoneSettings = data => {
  return getRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id + '/settings'),
    data,
  });
}

const syncZone = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id + '/sync'),
    data,
  });
}

const refreshZone = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id + '/refresh'),
    data,
  });
}

const optimizeZoneSettings = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id + '/optimize-settings'),
    data,
  });
}

const purgeZoneCache = data => {
  return postRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id + '/purge-cache'),
    data,
  });
}

const deleteZone = data => {
  return deleteRequest({
    url: env.buildApiEndpoint('cloudflare/zones/' + data.zone_id),
    data,
  });
}

const CloudflareService = {
  listAccounts,
  listAccountAssignableZones,
  updateAccountAPIKey,
  connectAccount,
  disconnectAccount,
  listZones,
  syncZone,
  optimizeZoneSettings,
  refreshZone,
  getZoneSettings,
  purgeZoneCache,
  connectZone,
  createZone,
  deleteZone,
};

export default CloudflareService;
