import React from 'react';
import { TitleBar } from '../../styles/layout/titlebar';
import { useSelector } from 'react-redux';
import { integrationSelector } from 'store/me/meSelectors';
import StripeConnect from 'components/stripe/stripeconnect';
import StripeDisconnect from 'components/stripe/stripedisconnect';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import './customer.css';

const Stripe = () => {
  useTitle('Client Stripe');
  const integrations = useSelector(integrationSelector);
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
    },
  ];

  return (
    <>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Stripe</TitleBar.Title>
      </TitleBar>
      <Content id="stripe-connect-customer">
        {integrations.stripe ? <StripeDisconnect /> : <StripeConnect />}
      </Content>
    </>
  );
};

export default Stripe;
