import React, { Fragment } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import theme from 'styles/theme';
import { useSelector } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import WebsiteHelper from 'helpers/website';
import { isEmptyOrNull } from 'helpers';
import { globalCoresSelector } from 'store/globalPackage/globalPackageSelector';
import { sortBy } from 'lodash';

const AccountHealthMetrics = () => {
  const websites = useSelector(websitesSelector);

  // Determine the health color based on the percent.
  const getBackgroundColor = (percent) => {
    if (percent <= 25) {
      return theme.background.danger;
    } else if (percent <= 80) {
      return theme.background.warning;
    } else {
      return theme.background.success;
    }
  }

  // Get the latest WordPress version.
  const wpPackages = sortBy(useSelector(globalCoresSelector), p => p.created_at).reverse();
  const latestWpVersion = wpPackages.length > 0 ? wpPackages[0].version : 'Unknown';

  // Count the number of attributes per account.
  const attributes = [
    {
      label: 'CDN (Cloudflare / CloudFront)',
      value: websites.filter(w => {
        const cdn = WebsiteHelper.getCdnCache(w) || WebsiteHelper.getCdnProxy(w) || WebsiteHelper.getCloudflareZone(w);
        const status = WebsiteHelper.getCdnStatus(cdn);
        return status.color === 'success';
      }).length,
    },
    {
      label: 'Page Caching',
      value: websites.filter(w => !!w.site_cached_by).length,
    },
    {
      label: 'Latest PHP (v8.2)',
      value: websites.filter(w => w.php_version && w.php_version.startsWith('8.2')).length,
    },
    {
      label: 'Latest WordPress (' + latestWpVersion + ')',
      value: websites.filter(w => w.wp_version === latestWpVersion).length,
    },
    {
      label: 'SSL Certificates',
      value: websites.filter(w => {
        const sslDetails = WebsiteHelper.parseSSL(w.domains, w.domains_ssl);
        return sslDetails && sslDetails.modalParams.type === 'success';
      }).length,
    },
    {
      label: 'Object Cache (Redis)',
      value: websites.filter(w => !isEmptyOrNull(w.objectcache_config)).length,
    },
  ];

  // Render the table.
  return(
    <Fragment>
      <table className='progress-table'>
        <colgroup>
          <col/>
          <col/>
        </colgroup>
        <tbody>
          {attributes.map((item, index) => {
            const percent = Math.round((item.value / websites.length) * 100);
            return (
              <tr key={index} className='progress-row'>
                <td className='progress-label'>
                  <span>{item.label}</span>
                </td>
                <td className='progress-bar'>
                  <ProgressBar
                    completed={percent}
                    initCompletedOnAnimation={3}
                    animateOnRender={true}
                    bgColor={getBackgroundColor(percent)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
}

export default AccountHealthMetrics;