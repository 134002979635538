import React, { Fragment, useState, useEffect, useRef } from 'react';
import Tabs, { Tab, TabPanel } from '../wpstaq/tabs';
import { useSelector } from 'react-redux';
import { websitesSelector } from '../../store/website/websiteSelectors';
import { partnerPackagesSelector } from '../../store/partnerPackage/partnerPackageSelector';
import { dnsZonesSelector } from '../../store/dnsZone/dnsZoneSelectors';
import { isEmpty } from '../../helpers';
import ArrayHelper from '../../helpers/array';
import { WPSCheckbox } from '../../styles/layout/forms';
import WPSDataTable from '../wpstaq/WPSDataTable/WPSDataTable';

/**
 * Handles the employee user permissions.
 *
 * @param {Function} setUserDetails The user form state setter.
 * @param {Object} checkedPermissions The currently checked user permissions.
 *
 * @return {boolean}
 */

const EmployeePermissions = ({ setUserDetails, checkedPermissions }) => {
  const websites = useSelector(websitesSelector);
  const packages = useSelector(partnerPackagesSelector);
  const dnsZones = useSelector(dnsZonesSelector);
  const [permissionsTabs, setPermissionsTabs] = useState();
  // const [myCheckedPerm, setMyCheckedPerm] = useState(checkedPermissions);
  const myCheckedPerm = useRef(checkedPermissions);

  const makePermissionStateObject = slug => {
    return { slug, checked: myCheckedPerm.current.includes(slug) };
  };

  const getWebsiteData = () => {
    let websiteData = {
      name: 'Websites',
      body: [],
    };

    if (!isEmpty(websites)) {
      websiteData.body = websites.map(website => ({
        slug: website.slug,
        name: website.slug,
        update: makePermissionStateObject(`website:update:${website.slug}`),
        delete: makePermissionStateObject(`website:delete:${website.slug}`),
        read: makePermissionStateObject(`website:read:${website.slug}`),
      }));
    }

    return websiteData;
  };

  const getPackageData = () => {
    let packageData = {
      name: 'Plugins & Themes',
      body: [],
    };

    if (!isEmpty(packages)) {
      packageData.body = packages.map(item => ({
        slug: item.slug,
        name: `${item.display_name} (${item.type})`,
        update: makePermissionStateObject(`partner-package:update:${item.slug}`),
        delete: makePermissionStateObject(`partner-package:delete:${item.slug}`),
        read: makePermissionStateObject(`partner-package:read:${item.slug}`),
      }));
    }
    return packageData;
  };

  const getDnsZonesData = () => {
    let dnsZonesData = {
      name: 'DNS Zones',
      body: [],
    };

    if (!isEmpty(dnsZones)) {
      dnsZonesData.body = dnsZones.map(item => ({
        slug: item.slug,
        name: `${item.name}`,
        update: makePermissionStateObject(`dns-zone:update:${item.slug}`),
        delete: makePermissionStateObject(`dns-zone:delete:${item.slug}`),
        read: makePermissionStateObject(`dns-zone:read:${item.slug}`),
      }));
    }
    return dnsZonesData;
  };
  const initPermissionsTabs = (force) => {
    if (permissionsTabs && !force) {
      return; // Already initialized
    }
    const tabs = [
      // Globals Tab
      {
        name: 'Global',
        body: [
          {
            name: 'Websites',
            slug: 'website',
            create: makePermissionStateObject('website:create:*'),
            update: makePermissionStateObject('website:update:*'),
            delete: makePermissionStateObject('website:delete:*'),
            read: makePermissionStateObject('website:read:*'),
          },
          {
            name: 'Plugins & Themes',
            slug: 'partner-package',
            create: makePermissionStateObject('partner-package:create:*'),
            update: makePermissionStateObject('partner-package:update:*'),
            delete: makePermissionStateObject('partner-package:delete:*'),
            read: makePermissionStateObject('partner-package:read:*'),
          },
          {
            name: 'DNS Zones',
            slug: 'dns-zone',
            create: makePermissionStateObject('dns-zone:create:*'),
            update: makePermissionStateObject('dns-zone:update:*'),
            delete: makePermissionStateObject('dns-zone:delete:*'),
            read: makePermissionStateObject('dns-zone:read:*'),
          },
          {
            name: 'Invoices',
            slug: 'invoice',
            create: false,
            update: false,
            delete: false,
            read: makePermissionStateObject('invoice:read:*'),
          },
          {
            name: 'Payment Methods',
            slug: 'payment-method',
            create: makePermissionStateObject('payment-method:create:*'),
            update: makePermissionStateObject('payment-method:update:*'),
            delete: makePermissionStateObject('payment-method:delete:*'),
            read: makePermissionStateObject('payment-method:read:*'),
          },
          {
            name: 'Client PDF Reports',
            slug: 'client-pdf-report',
            create: makePermissionStateObject('client-pdf-report:create:*'),
            update: makePermissionStateObject('client-pdf-report:update:*'),
            delete: makePermissionStateObject('client-pdf-report:delete:*'),
            read: makePermissionStateObject('client-pdf-report:read:*'),
          },
        ],
      },
    ];

    const websiteData = getWebsiteData();
    !isEmpty(websiteData.body) && tabs.push(websiteData);

    const packageData = getPackageData();
    !isEmpty(packageData.body) && tabs.push(packageData);

    const dnsZonesData = getDnsZonesData();
    !isEmpty(dnsZonesData.body) && tabs.push(dnsZonesData);

    setPermissionsTabs(ArrayHelper.clone(tabs));
    
  };
  // Generate the Permissions/Checkboxes array on component mount
  useEffect(() => {
    initPermissionsTabs();
    // eslint-disable-next-line
  }, []);

  const onChecboxChange = target => {
    // id: the checked permission's id: '[model]:[action]:[scope]'.
    // checked: checkbox state (checked or unchecked).
    const { id, checked } = target.currentTarget;
    // Update current user input
    setUserDetails(prev => ({
      ...prev,
      permissions: checked
        ? [...prev.permissions, id]
        : [...prev.permissions.filter(p => p !== id)],
    }));
    // Update the current permissions state
    if (checked) {
      let data = ArrayHelper.clone(myCheckedPerm.current);
      data.push(id);
      myCheckedPerm.current = (ArrayHelper.clone(data));
    } else {
      let data = [...myCheckedPerm.current.filter(p => p !== id)];
      myCheckedPerm.current = (ArrayHelper.clone(data));
    }
    initPermissionsTabs(true);
  };

  const globalColumns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Create',
      selector: 'create',
      sortableValue: 'checked',
      sortable: true,
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            disabled={row.create === false}
            id={row.create.slug}
            checked={myCheckedPerm.current.includes(row.create.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
    {
      name: 'Update',
      selector: 'update',
      sortableValue: 'checked',
      sortable: true,
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            disabled={row.update === false}
            id={row.update.slug}
            checked={myCheckedPerm.current.includes(row.update.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
    {
      name: 'Delete',
      selector: 'delete',
      sortableValue: 'checked',
      sortable: true,
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            disabled={row.delete === false}
            id={row.delete.slug}
            checked={myCheckedPerm.current.includes(row.delete.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
    {
      when: row => row.read,
      name: 'View',
      selector: 'read',
      sortableValue: 'checked',
      sortable: true,
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            disabled={row.read === false}
            id={row.read.slug}
            checked={myCheckedPerm.current.includes(row.read.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
  ];
  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      searchable: true,
      width: '40%',
    },
    {
      name: 'Update',
      selector: 'update',
      sortableValue: 'checked',
      sortable: true,
      width: '20%',
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            id={row.update.slug}
            checked={myCheckedPerm.current.includes(row.update.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
    {
      name: 'Delete',
      selector: 'delete',
      sortableValue: 'checked',
      sortable: true,
      width: '20%',
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            id={row.delete.slug}
            checked={myCheckedPerm.current.includes(row.delete.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
    {
      name: 'View',
      selector: 'read',
      sortableValue: 'checked',
      sortable: true,
      width: '20%',
      cell: row => {
        return (
          <WPSCheckbox
            type='checkbox'
            id={row.read.slug}
            checked={myCheckedPerm.current.includes(row.read.slug)}
            onChange={onChecboxChange}
          />
        );
      },
    },
  ];
  return (
    <Fragment>
      {permissionsTabs && (
        <Tabs
          initialValue='Global'
          tabs={() =>
            permissionsTabs.map(tab => (
              <Tab key={tab.name} name={tab.name}>
                {tab.name}
              </Tab>
            ))
          }
          panels={() =>
            permissionsTabs.map(tab => (
              <TabPanel key={tab.name} name={tab.name} className='employee-table'>
                <WPSDataTable
                  columns={tab.name === 'Global' ? globalColumns : columns}
                  body={tab.body}
                />
              </TabPanel>
            ))
          }
        />
      )}
    </Fragment>
  );
};

export default EmployeePermissions;
