import React, { Fragment } from 'react';
import { TitleBar } from '../../styles/layout/titlebar';
import CreateCustomer from '../../components/customer/create';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCustomerBySlug } from '../../store/customer/customerSelectors';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const CustomerUpdate = () => {
  useTitle('Update Client');
  const location = useLocation();
  const customer = useSelector(getCustomerBySlug(location.state.slug));

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Accounts',
      link: '/clients/accounts',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Update client</TitleBar.Title>
        <TitleBar.Actions>
            {JsxHelper.createBackButton()}
          </TitleBar.Actions>
      </TitleBar>
      <Content>
        <CreateCustomer customer={customer} />
      </Content>
    </Fragment>
  );
};

export default CustomerUpdate;
