import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { integrationSelector } from 'store/me/meSelectors';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { setIntegration } from 'store/me/meActions';
import { disconnect } from 'services/stripe';
import { WPSButton } from '../../styles/layout/buttons';

const Payment = ({ isCustomPage, onClickCustomPageNext }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const integrations = useSelector(integrationSelector);

  const disconnectStripe = async () => {
    setLoading(true);
    try {
      await disconnect();
      dispatch(setIntegration({ stripe: null }));
      dispatch(setGlobalSuccessMsg({ model: 'Stripe account', action: 'disconnected' }));
    } catch (err) {
      dispatch(setGlobalErrorMsg(err));
    }
    setLoading(false);
  };

  return (
    <div id='stripe-disconnect'>
      <p className='stripe-info-item display-flex-nowrap' style={{ paddingTop: '15px' }}>
        <span>
          <strong>Account:</strong>
        </span>
        <span>{integrations.stripe.account_data.settings.dashboard.display_name}</span>
      </p>
      <p className='display-flex-nowrap stripe-info-item'>
        <span>
          <strong>Email:</strong>
        </span>
        <span>{integrations.stripe.account_data.email}</span>
      </p>
      <div>
        {!isCustomPage && (
          <WPSButton
            loading={loading}
            disabled={loading}
            loadingColor='light'
            onClick={() => disconnectStripe()}
            className='card-active--btn stripe-disconnect-btn'>
            Disconnect
          </WPSButton>
        )}
        {isCustomPage && (
          <div className='display-flex-center-start margin-top-20'>
            <WPSButton
              className='remove-light--btn margin-right-25'
              loading={loading}
              disabled={loading}
              loadingColor='light'
              onClick={disconnectStripe}>
              Disconnect
            </WPSButton>
            <WPSButton className='next-light--btn' onClick={onClickCustomPageNext}>
              Next
            </WPSButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
