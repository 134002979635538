import React, { useState, useEffect, Fragment } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useSelector, useDispatch } from 'react-redux';
import { usersSelector, usersByResource } from 'store/user/userSelectors';
import { WPSButton } from 'styles/layout/buttons';
import { WPSForm, WPSLabel, WPSInput, WPSCheckbox, WPSToggle, ErrorMsg } from 'styles/layout/forms';
import { isEmpty } from 'helpers';
import ArrayHelper from 'helpers/array';
import { updateUser, createUser } from 'store/user/userActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useHistory } from 'react-router-dom';
import useConfirm from 'hooks/useConfirm';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import JsxHelper from 'helpers/jsx';
import globalHelper from 'helpers/globalHelper';
import DialogHelper from 'helpers/dialog';
import env from 'config/env';

const Users = ({ website }) => {
  useTitle('Website Users');
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });
  const allUsers = useSelector(usersSelector);
  const users = useSelector(usersByResource('website', website.slug));
  const confirm = useConfirm();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const initialDetails = {
    display_name: '',
    email: '',
    sub_role: 'team_member',
  };
  const [details, setDetails] = useState(initialDetails);

  const [user, setUser] = useState(!isEmpty(allUsers) ? allUsers[0].slug : '');

  const initialPermissions = [
    { slug: `website:read:${website.slug}`, checked: true },
    { slug: `website:update:${website.slug}`, checked: false },
    { slug: `website:delete:${website.slug}`, checked: false },
  ];
  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    if (modal) {
      if (!!user) {
        const selectedUser = ArrayHelper.find(allUsers, 'slug', user);
        const userPermissions = selectedUser.permissions.map(
          p => `${p.type}:${p.action}:${p.scope}`,
        );

        setPermissions(prev =>
          prev.map(p =>
            userPermissions.includes(p.slug)
              ? { slug: p.slug, checked: true }
              : { slug: p.slug, checked: false },
          ),
        );
      }
    }
    // eslint-disable-next-line
  }, [modal, user]);

  const onChange = e => {
    const { name, value, checked, type } = e.target;
    if (name === 'slug') {
      setUser(value);
      return;
    }

    setDetails(prev => ({ ...prev, [name]: value }));

    if (type === 'checkbox') {
      const item = { slug: name, checked };
      setPermissions(prev => [...ArrayHelper.update(prev, 'slug', item)]);
      return;
    }
  };

  const selectOnChange = e => {
    const { name, value } = e.target;
    if (name === 'slug') {
      setUser(value);
    }
  };

  const clearForm = () => {
    setErrorMsg('');
    setPermissions(initialPermissions);
    setDetails(initialDetails);
  };

  const initPermissions = permissions => {
    let newPermissions = [];
    permissions.forEach(p => {
      let values = p.split(':');
      newPermissions.push({ type: values[0], action: values[1], slug: values[2] });
    });
    return newPermissions;
  };

  const handleSaveUser = () => {
    const _permissions = initPermissions(permissions.filter(p => p.checked).map(p => p.slug));
    // Don't submit empty permissions/ atleast on permission should be selected.
    if (isEmpty(_permissions)) {
      setErrorMsg('Please select at least one permission');
      return;
    }
    setLoading(true);
    if (newUser) {
      const data = {
        ...details,
        permissions: initPermissions(permissions.filter(p => p.checked).map(p => p.slug)),
      };

      dispatch(createUser(data))
        .then(() => {
          dispatch(setGlobalSuccessMsg({ id: data.email, model: 'user', action: 'created' }));
          setModal(false);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    // Update user permissions for website.
    const currentUser = ArrayHelper.find(allUsers, 'slug', user);
    const userPermissions = currentUser.permissions
      .filter(p => p.scope !== website.slug)
      .map(p => `${p.type}:${p.action}:${p.scope}`);
    const websitePermissions = permissions.filter(p => p.checked).map(p => p.slug);
    const allPermissions = [...userPermissions, ...websitePermissions];
    const updatedUser = {
      ...currentUser,
      user_slug: currentUser.slug,
      permissions: initPermissions(allPermissions),
    };

    dispatch(updateUser(updatedUser))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: updatedUser.email, model: 'user', action: 'updated' }));
        setModal(false);
      })
      .finally(() => {
        setLoading(false);
        clearForm();
        setPermissions(initialPermissions);
        setUser(!isEmpty(allUsers) ? allUsers[0].slug : '');
      });
  };

  const toggleNewUser = e => {
    setNewUser(!newUser);
    if (e.target.checked) {
      setPermissions(initialPermissions);
      setUser('');
    } else {
      setUser(!isEmpty(allUsers) ? allUsers[0].slug : '');
    }
  };

  const removeUser = user => {
    // Update user permissions for website.
    const currentUser = allUsers.find(el => el.slug === user.slug);
    const userPermissions = currentUser.permissions
      .filter(p => p.scope !== website.slug)
      .map(p => `${p.type}:${p.action}:${p.scope}`);
    const allPermissions = [...userPermissions];

    const updatedUser = {
      ...currentUser,
      user_slug: currentUser.slug,
      permissions: initPermissions(allPermissions),
    };

    dispatch(updateUser(updatedUser))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: updatedUser.email, model: 'user', action: 'updated' }));
        setModal(false);
      })
      .finally(() => {
        setLoading(false);
        clearForm();
        setPermissions(initialPermissions);
        setUser(!isEmpty(allUsers) ? allUsers[0].slug : '');
      });
  };

  const actions = [
    {
      value: 'Update',
      onClick: user => history.push({
        pathname: `/users/${user.slug}/update`,
        state: user,
      }),
    },
    {
      value: 'Remove',
      onClick: user => DialogHelper
        .confirmRemove(confirm, user.email, 'user')
        .then(() => removeUser(user)),
    },
  ];

  const headers = [
    JsxHelper.createTableTitleHeader('display_name', 'User', '25%', 'email'),
    {
      name: 'Permissions',
      selector: 'permissions',
      width: '60%',
      searchable: true,
      cell: row => {
        let websitePermissions = row.permissions.filter(p => p.type === 'website');
        const userPermisions = [];
        websitePermissions.forEach(p => {
          if (p.scope === '*') {
            userPermisions.push({ scope: 'global', action: p.action });
          } else if (p.scope === website.slug) {
            userPermisions.push({ scope: 'local', action: p.action });
          }
        });

        return (
          <ul style={{ display: 'flex' }}>
            {userPermisions.map((p, i) => (
              <li key={i}>{
                JsxHelper.createBubble({
                  background: p.scope === 'global' ? 'success' : 'info',
                  text: p.action === 'read' ? 'view' : p.action,
                  tooltip: `${p.scope} permission`,
                  sentence: true
                })
              }</li>
            ))}
          </ul>
        );
      },
    },
    JsxHelper.createTableActionsHeader(actions, '15%')
  ];

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Users</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='assign--btn' onClick={() => setModal(true)}>
            + Assign user
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        Grant access to your website's profile on {env.getBrandShortName()} to developers, IT Managers or clients and set
        permissions as to what that user can view or do. You should only grant permission if you
        trust the user.
      </p>
      <Content>
        <WPSDataTable
          columns={headers}
          body={users}
          noSearchOnTable={true}
          rowsPerPage={100}
        />
        {modal && (DialogHelper.inputs({
          title: 'Assign Website User',
          icon: 'create',
          iconColor: 'success',
          onConfirm: handleSubmit(handleSaveUser),
          onClose: () => {
            setModal(false);
            clearForm();
          },
          loading: loading,
          header: <WPSForm>
            <WPSForm.Row>
              <WPSForm.RowItem direction='row' minHeight='45'>
                <WPSToggle className='fix-input-modal-toggle' type='checkbox' checked={newUser} onChange={toggleNewUser} />
                <span>New User?</span>
              </WPSForm.RowItem>
            </WPSForm.Row>
            {newUser ? (
              <Fragment>
                <WPSLabel required>
                  Display name
                  {errors.display_name && <ErrorMsg>{errors.display_name.message}</ErrorMsg>}
                </WPSLabel>
                <WPSInput
                  name='display_name'
                  type='text'
                  onChange={onChange}
                  ref={register({ required: FormHelper.messages.required })}
                />
                <WPSLabel required>
                  Email
                  {errors.email && <ErrorMsg>{errors.email.message}</ErrorMsg>}
                </WPSLabel>
                <WPSInput
                  name='email'
                  type='email'
                  onChange={onChange}
                  ref={register({ required: FormHelper.messages.required })}
                />
                <WPSLabel required>
                  Role
                  {errors.sub_role && <ErrorMsg>{errors.sub_role.message}</ErrorMsg>}
                </WPSLabel>
                <WPSSelect
                  name='sub_role'
                  value={details.sub_role}
                  options={ArrayHelper.buildSelectOptions(
                    globalHelper.subRolesTypes,
                    'name',
                    'value',
                  )}
                  onChange={onChange}
                />
              </Fragment>
            ) : (
              !isEmpty(allUsers) && (
                <Fragment>
                  <WPSLabel>Select existing user</WPSLabel>
                  <WPSSelect
                    name='slug'
                    value={!isEmpty(allUsers) ? allUsers[0].slug : ''}
                    options={
                      !isEmpty(allUsers) &&
                      allUsers.map(el => ({
                        name: 'slug',
                        value: el.slug,
                        label: `${el.display_name} (${el.email})`,
                      }))
                    }
                    onChange={selectOnChange}
                  />
                </Fragment>
              )
            )}
            <WPSLabel required>
              Permissions
              <ErrorMsg>{errorMsg}</ErrorMsg>
            </WPSLabel>
            <WPSForm.Row>
              <WPSForm.RowItem minHeight='45' direction='row'>
                <WPSCheckbox
                  type='checkbox'
                  name={`website:read:${website.slug}`}
                  checked={permissions[0].checked}
                  onChange={onChange}
                />
                <span style={{ marginLeft: '16px' }}>View</span>
              </WPSForm.RowItem>
              <WPSForm.RowItem minHeight='45' direction='row'>
                <WPSCheckbox
                  type='checkbox'
                  name={`website:update:${website.slug}`}
                  checked={permissions[1].checked}
                  onChange={onChange}
                />
                <span style={{ marginLeft: '16px' }}>Update</span>
              </WPSForm.RowItem>
              <WPSForm.RowItem minHeight='45' direction='row'>
                <WPSCheckbox
                  type='checkbox'
                  name={`website:delete:${website.slug}`}
                  checked={permissions[2].checked}
                  onChange={onChange}
                />
                <span style={{ marginLeft: '16px' }}>Delete</span>
              </WPSForm.RowItem>
            </WPSForm.Row>
          </WPSForm>
        }))}
      </Content>
    </Container>
  );
};

export default Users;
