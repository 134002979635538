import {
  getRequest,
  postRequest,
  deleteRequest,
  downloadRequest
} from '../config/axiosRequest';
import env from '../config/env';

/**
 * Get all pricing plans.
 *
 * @param   {Object} data
 * @return  {Object}
 */
const fetch = data => {
  return getRequest({
    url: env.buildApiEndpoint('billing/pricing-plans'),
    data,
  });
};

/**
 * Save the invoice details. (Admins only)
 *
 * @param   {Object} data
 * @return  {Object}
 */
const savePlatformDetails = data => {
  return postRequest({
    url: env.buildApiEndpoint('billing/invoice-details'),
    data,
  });
};

/**
 * Save your business details. (Partners only)
 *
 * @param   {Object} data
 * @return  {Object}
 */
const save = data => {
  return postRequest({
    url: env.buildApiEndpoint('billing/details'),
    data,
  });
};

/**
 * Create a subscription.
 *
 * @param   {Object} data
 * @return  {Object}
 */
const createSubscription = data => {
  return postRequest({
    url: env.buildApiEndpoint('billing/subscriptions'),
    data,
  });
};

/**
 * Update a subscription.
 *
 * @param   {Object} data
 * @return  {Object}
 */
const updateSubscription = data => {
  return postRequest({
    url: env.buildApiEndpoint(`billing/subscriptions/${data.guid}`),
    data,
  });
};

/**
 * Cancel a subscription.
 *
 * @param   {Object} data
 * @return  {Object}
 */
const cancelSubscription = data => {
  return postRequest({
    url: env.buildApiEndpoint(`billing/subscriptions/${data.guid}/cancel`),
    data,
  });
};

// Update global billing settings.
const updateBillingSettings = data => {
  return postRequest({
    url: env.buildApiEndpoint(`billing/settings`),
    data,
  });
};

// Update global billing settings.
export const addPartnerPaymentMethod = data => {
  return postRequest({
    url: env.buildApiEndpoint('billing/payment-method'),
    data,
  });
};

// Update global billing settings.
export const editPartnerPaymentMethod = data => {
  return postRequest({
    url: env.buildApiEndpoint(`billing/payment-method/${data.guid}`),
    data,
  });
};

// Update global billing settings.
export const deletePartnerPaymentMethod = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`billing/payment-method/${data.guid}`),
    data,
  });
};

// List partner payment methods.
const fetchPartnerPaymentMethods = data => {
  return getRequest({
    url: env.buildApiEndpoint('billing/payment-method'),
    data,
  });
};

// List partner invoices.
const fetchPartnerInovices = data => {
  return getRequest({
    url: env.buildApiEndpoint('billing/invoices'),
    data,
  });
};

// List Payment Intents.
const fetchAdminPaymentIntents = (data, isCustomer) => {
  if (isCustomer) {
    return getRequest({
      url: env.buildApiEndpoint('customers/payment-intents'),
      data,
    });
  } else {
    return getRequest({
      url: env.buildApiEndpoint('billing/payment-intents'),
      data,
    });
  }
};

// Download invoice.
const downloadInovice = data => {
  return downloadRequest({
    url: env.buildApiEndpoint(`billing/invoices/${data.guid}/download-url`),
    data,
  });
};

// Update partner credits.
const updatePartnerCredits = (data) => {
  return postRequest({
    url: env.buildApiEndpoint(`billing/credits`),
    data,
  });
}

// Charge partner invoice.
const chargePartnerInvoice = (data) => {
  return postRequest({
    url: env.buildApiEndpoint(`billing/invoices/${data.guid}/charge`),
    data,
  });
}

// Charge customer invoice.
const chargeCustomerInvoice = (data) => {
  return postRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}/invoices/${data.guid}/charge`),
    data,
  });
};

// Set invoice as paid.
const setCustomerInvoiceAsPaid = data => {
  return postRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}/invoices/${data.guid}/set-as-paid`),
    data
  });
};

// Delete partner invoice.
const deletePartnerInvoice = (data) => {
  return deleteRequest({
    url: env.buildApiEndpoint(`billing/invoices/${data.guid}`),
    data,
  });
}

// Delete customer invoice.
const deleteCustomerInvoice = (data) => {
  return deleteRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}/invoices/${data.guid}`),
    data,
  });
};

// Download invoice.
const fetchAnalytics = data => {
  return getRequest({
    url: env.buildApiEndpoint(`billing/analytics`),
    data
  });
};

// Generate a CSV file for tracking cost & usage per website.
const downloadCostUsageReport = data => {
  return downloadRequest({
    url: env.buildApiEndpoint(`billing/cost-usage-report`),
    data,
  });
};

const BillingService = {
  fetch,
  save,
  savePlatformDetails,
  createSubscription,
  updateSubscription,
  cancelSubscription,
  updateBillingSettings,
  addPartnerPaymentMethod,
  editPartnerPaymentMethod,
  deletePartnerPaymentMethod,
  fetchPartnerInovices,
  fetchAdminPaymentIntents,
  downloadInovice,
  chargePartnerInvoice,
  chargeCustomerInvoice,
  setCustomerInvoiceAsPaid,
  deletePartnerInvoice,
  deleteCustomerInvoice,
  fetchAnalytics,
  updatePartnerCredits,
  downloadCostUsageReport,
  fetchPartnerPaymentMethods
};

export default BillingService;