import { getRequest, postRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Get all pricing plans.
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const fetch = data => {
  return getRequest({
    url: env.buildApiEndpoint('email-templates'),
    data,
  });
};

/**
 * Save the invoice details. (Admins only)
 *
 * @param   {String} key
 * @param   {Object} data
 * @return  {Object}
 */
export const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`email-templates/${data.key}`),
    data,
  });
};

/**
 * Save the invoice details. (Admins only)
 *
 * @param   {String} key
 * @param   {Object} data
 * @return  {Object}
 */
export const test = (key, data) => {
  return postRequest({
    url: env.buildApiEndpoint(`email-templates/${key}/send-test`),
    data,
  });
};

const previewEmail = data => {
  return getRequest({
    url: env.buildApiEndpoint('email-templates/style'),
    data,
  });
};

const saveEmailColors = data => {
  return postRequest({
    url: env.buildApiEndpoint('email-templates/style'),
    data,
  });
};

const resetEmailTemplate = data => {
  return postRequest({
    url: env.buildApiEndpoint(`email-templates/${data.key}/reset`),
    data,
  });
};

const EmailService = {
  fetch,
  update,
  test,
  previewEmail,
  saveEmailColors,
  resetEmailTemplate,
};

export default EmailService;
