import MeService from '../../services/me';

/*
  Action Types
*/
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

/*
  Action Creators
*/

// Set user data from the localstorage
export const setAuthData = () => {
  return dispatch => {
    dispatch({ type: SET_AUTH_DATA });
  };
};

// Logout user and clear storage data(Async)
export const userLogout = () => {
  return dispatch => {
    MeService.logout()
      .then(() => dispatch({ type: LOGOUT }))
      .catch(err => dispatch({ type: SET_AUTH_ERROR, payload: err }));
  };
};
