import { ME_SET_ALL, ME_SET_INTEGRATION, ME_UPDATE, ME_SET_PARENT } from './meActions';

const meReducer = (state = [], action) => {
  switch (action.type) {
    case ME_SET_ALL:
      return {
        ...action.payload,
      };
    case ME_SET_PARENT:
      return {
        ...state,
        parent: action.payload,
      };
    case ME_SET_INTEGRATION:
      return {
        ...state,
        integrations: action.payload,
      };

    case ME_UPDATE:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default meReducer;
