import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useHistory } from 'react-router-dom';
import SubscriptionService from 'services/subscription';
import { WPSInput } from 'styles/layout/forms';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { isEmpty, handleFilterURLParamsChanges, convertToFilterObject } from 'helpers';
import useOnClickOutside from 'utils/useOnClickOutside';
import useQuery from 'hooks/useQuery';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import ArrayHelper from 'helpers/array';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import TableHelper from 'helpers/table';
import DateHelper from 'helpers/date';
import StringHelper from 'helpers/string';

const SubscriptionUsage = ({ type }) => {
  useTitle('Subscription Usage');
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const [usage, setUsage] = useState([]);
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(query.toString());
  const pageGuid = urlParams.get('guid');
  const pageFrom = urlParams.get('from');
  const [details, setDetails] = useState({
    from: pageFrom ? pageFrom : DateHelper.now().subtract(30, 'd').format('YYYY-MM-DD'),
    to: DateHelper.now().format('YYYY-MM-DD'),
    feature: '',
    guid: pageGuid ? pageGuid : null,
  });

  const fetchData = data => {
    setLoading(true);
    SubscriptionService.fetchUsage(data)
      .then(res => {
        setUsage(res.reverse());
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const data = {
      guid: pageGuid ? pageGuid : '',
      from_date: pageFrom ? pageFrom : DateHelper.now().subtract(30, 'd').format('YYYY-MM-DD'),
      to_date: DateHelper.now().format('YYYY-MM-DD'),
      feature: '',
    };
    fetchData(data);
    // eslint-disable-next-line
  }, []);

  const onChange = e => {
    const { name, value, type } = e.target;
    const _value = type === 'date' ? DateHelper.create(value).format('YYYY-MM-DD') : value;
    setDetails(prev => ({ ...prev, [name]: _value }));
  };

  const onSubmit = () => {
    const data = {
      from_date:
        details.from && details.from.length > 0
          ? DateHelper.create(details.from).format('YYYY-MM-DD')
          : DateHelper.now().subtract(15, 'd').format('YYYY-MM-DD'),
      to_date: DateHelper.create(details.to).format('YYYY-MM-DD'),
      guid: pageGuid ? pageGuid : '',
      feature: details.feature,
    };
    fetchData(data);
  };

  const getFeatureDetails = (row) => {
    let label = '';
    switch (row.feature) {
      case 'bandwidth':
        label = 'Bandwidth';
        break;
      case 'db_storage':
        label = 'DB Storage';
        break;
      case 'redis_maxmem':
        label = 'Redis Memory';
        break;
      case 'cpu_time':
        label = 'CPU Time';
        break;
      case 'local_storage':
        label = 'Local Storage';
        break;
      case 'backup_storage':
        label = 'Backup Storage';
        break;
      case 'visits':
        label = 'Unique Visits';
        break;
      case 'php_maxmem':
        label = 'PHP Memory';
        break;
      case 'sites_included':
        label = 'Websites';
        break;
      case 'cloud_storage':
        label = 'Cloud Storage';
        break;
      default:
        label = row.feature;
    }
    let awsService = row.type.split('.')[0];
    switch (awsService) {
      case 'ec2':
        awsService = 'EC2';
        break;
      case 's3':
        awsService = 'S3';
        break;
      case 'rds':
        awsService = 'RDS';
        break;
      case 'cloudfront':
        awsService = 'CloudFront';
        break;
      default:
        awsService = 'EC2';
    }
    return { label, awsService };
  }

  // ------ FILTERS ------
  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  // Handle on Click outside for the filters menu
  const ref = useRef();
  useOnClickOutside(ref, () => setShowFilters(false));

  // Populate filters
  useEffect(() => {
    if (!isEmpty(usage)) {
      // Filter status
      const date = usage.map(p => p.date);
      const feature = usage.map(row => getFeatureDetails(row).label);
      setFilters(prev => ({
        ...prev,
        ...convertToFilterObject(query, 'Date', new Set(date)),
        ...convertToFilterObject(query, 'Feature', new Set(feature)),
      }));
    }

    // eslint-disable-next-line
  }, [usage]);

  // Handle filters add/remove from URL.
  useEffect(() => {
    if (!isEmpty(usage) && !loading) {
      handleFilterURLParamsChanges(filters, history, true);
    }
    // eslint-disable-next-line
  }, [filters]);

  // Update state when changing a filter checked state.
  const onClickFilter = e => {
    // id: filter.value, name: filter.name
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  const headers = [
    JsxHelper.createTableTextHeader('date', 'Date', '13%'),
    JsxHelper.createTableTextHeaderWithCallback('Feature', 'Feature', '15%', row => {
      const details = getFeatureDetails(row);
      TableHelper.customizeCellValue(row, 'Feature', details.label);
      return JsxHelper.createTableMultiLineCell({
        header: details.label,
        subheader: details.awsService,
      });
    }),
    JsxHelper.createTableTextHeaderWithCallback('value', 'Usage', '15%', row => {
      const _unit = parseInt(row.unit);
      if (!isNaN(_unit)) {
        return row.value;
      } else if (row.unit === 'KB') {
        return StringHelper.convertKBytes(row.value);
      } else if (row.unit === 's') {
        return row.value + ' seconds';
      }
      return row.value + ' ' + row.unit;
    }),
    UserHelper.isAdminOrAgent() && JsxHelper.createTableTextHeaderWithCallback('resource_slug', 'Resource ID', '50%', row => {
      let type = row.resource_type;
      const slug = row.resource_slug;
      if (type) {
        TableHelper.customizeCellValue(row, 'resource_slug', slug + ' ' + type);
        if (type.toLowerCase() === 'app') {
          type = 'Website';
        }
      }
      return type ? JsxHelper.createTableMultiLineCell({
        header: slug || '-',
        subheader: type,
      }) : '-';
    }),
  ].filter(Boolean);

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: type === 'customer' ? 'Clients' : 'Billing',
    },
    {
      text: type === 'customer' ? 'Assigned Plans' : 'Subscriptions',
      link: type === 'customer' ? '/clients/subscriptions' : '/billing/subscriptions',
    },
    {
      text: 'Subscription Usage',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Subscription Usage</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createFilterIcon({
            setShowFilters,
            filters,
            showFilters,
            onClickFilter,
            filtersRef: ref,
          })}
          <label>From</label>
          <WPSInput
            type='date'
            name='from'
            value={details.from}
            onChange={onChange}
            placeholder='YYYY-MM-DD'
          />
          <label>To</label>
          <WPSInput
            type='date'
            name='to'
            value={details.to}
            onChange={onChange}
            placeholder='YYYY-MM-DD'
          />
          {JsxHelper.createLoadMoreButton({ loading: loading, onClick: onSubmit })}
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          columns={headers}
          body={ArrayHelper.generateUniqueField(usage, 'feature', 'rowId')}
          loading={loading}
          dataKey='rowId'
          rowsPerPage={100}
        />
      </Content>
    </Fragment>
  );
};

export default SubscriptionUsage;
