import { dispatchPostPromise, dispatchDeletePromise } from '../../helpers';
import RegionService from '../../services/region';

/*
  Action Types
*/
export const REGION_SET_ALL = 'REGION_SET_ALL';
export const REGION_CREATE = 'REGION_CREATE';
export const REGION_UPDATE = 'REGION_UPDATE';
export const REGION_DELETE = 'REGION_DELETE';

/*
  Action Creators
*/

// Create a new region
export const createNewRegion = data => {
  return dispatchPostPromise(RegionService.create(data), REGION_CREATE);
};

// Update a specified region
export const updateRegion = data => {
  return dispatchPostPromise(RegionService.update(data), REGION_UPDATE);
};

// Delete specified region
export const deleteRegion = region => {
  let data = { region_slug: region.slug };
  return dispatchDeletePromise(RegionService.delete(data), REGION_DELETE, region);
};
