import DateHelper from './date';

const write = (key, data, expiryMins) => {
  const _data = {
    data,
    expiry: expiryMins ? DateHelper.now().add(expiryMins, 'minutes') : null,
  };
  localStorage.setItem(key, JSON.stringify(_data));
};

const read = key => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  const _data = JSON.parse(data);
  if (_data.expiry && DateHelper.now().isAfter(_data.expiry)) {
    localStorage.removeItem(key);
    return null;
  }
  return _data.data;
}

const remove = key => {
  localStorage.removeItem(key);
}

const LocalStorageHelper = { write, read, remove };

export default LocalStorageHelper;
