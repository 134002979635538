import React, { useState, useRef, useEffect } from 'react';
import { Container } from '../../../styles/website/profile';
import { TitleBar } from '../../../styles/layout/titlebar';
import { Content } from '../../../styles/globalStyles';
import { FileManager, FileNavigator } from '@opuscapita/react-filemanager';
import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';
import Editor from '@monaco-editor/react';
import { WPSButton } from '../../../styles/layout/buttons';
import config from '../../../config/env';
import xhook from 'xhook';
import './fileManager.css';
import FileManagerService from 'services/filemanager';
import { getErrorMsg, isEmpty, isNotEmpty } from 'helpers';
import JsxHelper from '../../../helpers/jsx';
import StringHelper from '../../../helpers/string';
import useTitle from 'hooks/useTitle';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalWarningMsg,
} from '../../../store/global/globalActions';
import { useDispatch } from 'react-redux';
import WebsiteService from '../../../services/website';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Fragment } from 'react';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { purgeWebsiteCache } from '../../../store/website/websiteActions';
import useModal from '../../../hooks/useModal';
import { useSelector } from 'react-redux';
import { timezoneSelector } from 'store/me/meSelectors';
import WebsiteHelper from 'helpers/website';

const FileManagerData = ({ website, refresh, setCustomBackBtn }) => {
  useTitle('Website File Manager');
  const dispatch = useDispatch();
  let { name } = useParams();
  const fileManagerType = !name ? 'website' : 'package';
  const packageName = name || '';
  const packageType = fileManagerType === 'package' ? (window.location.href.includes('/plugins/') ? 'plugin' : 'theme') : '';
  const location = useLocation();
  const history = useHistory();
  const modalDialog = useModal();
  const timezone = useSelector(timezoneSelector);
  const [isTokenReceived, setIsTokenReceived] = useState(false);
  const [selectedFileValue, setSelectedFileValue] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editorSaveLoading, setEditorSaveLoading] = useState(false);
  const [saveAndCloseLoading, setSaveAndCloseLoading] = useState(false);
  const [editorLoading, setEditorLoading] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editBtn, setEditBtn] = useState(true);
  const [showHistoryLogsTable, setShowHistoryLogsTable] = useState(false);
  const [clearCacheLoading, setClearCacheLoading] = useState(false);

  const responseMessage = useRef(false);
  const refreshTokenBusy = useRef(true);
  const refreshTokenLoading = useRef(false);
  const editorValue = useRef();
  const [selectedEditorLanguage, setSelectedEditorLanguage] = useState('js');
  const editorAvailableLanguages = [
    'php',
    'js',
    'css',
    'html',
    'json',
    'txt',
    'htaccess',
    'csv',
    'xml',
    'yaml',
  ];

  const clearServerCache = useRef(false);
  const [triggerRefreshCount, setTriggerRefreshCount] = useState(1);
  const [triggerRefreshBtn, setTriggerRefreshBtn] = useState(1);

  const apiOptions = {
    ...connectorNodeV1.apiOptions,
    // For demo use https://demo.core.dev.opuscapita.com/filemanager/master
    // but do not forget to comment the xhook.before below.
    apiRoot: config.buildFileManagerApiEndpoint(website),
  };

  const [historyLogs, setHistoryLogs] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [customEditorRenderFlag, setCustomEditorRenderFlag] = useState(false);

  const coreFileManagerInfo = `Create, upload, modify or delete your website files via an easy and safe
  to use interface, in contrast to the hassles of using FTP or SSH clients. You can track the history of your changes.`;

  const packageFileManagerInfo = `Create, upload, modify or delete your ${packageType} files via an easy and safe
  to use interface, in contrast to the hassles of using FTP or SSH clients. You can track the history of your changes.`;

  const historyColumns = [
    {
      name: 'Time',
      selector: 'datetime',
      sortable: true,
      searchable: true,
      cell: (row) => JsxHelper.createTableDateTimeCell(row.datetime, timezone),
    },
    {
      name: 'User',
      selector: 'editor',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      searchable: true,
    },
  ];

  useEffect(() => {
    setShowHistoryLogsTable(false);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    // every time access this page get new token
    refreshToken();
    // Schedule refreshToken to run every 10 minutes
    setInterval(refreshToken, 10 * 60 * 1000);

    return () => {
      setCustomBackBtn(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // watch route changes, to force FM re-render
    refreshToken(true);
    // eslint-disable-next-line
  }, [location]);

  const getLogsData = async () => {
    setHistoryLoading(true);
    await FileManagerService.getChangesHistory(website)
      .then(res => {
        if (res.logs && !isEmpty(res.logs)) {
          setHistoryLogs([...res.logs]);
        } else {
          setHistoryLogs([]);
        }
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setHistoryLoading(false));
  };

  const isFileManagerApiRequest = uri => uri.includes(`api/v1/filemanager/${website.slug}`);
  const getLocalStorageKey = () => `fm-${website.slug}-${packageName}-${packageType || 'website'}`;

  const handleTokenError = (error, service) => {
    if (error.statusText === 'Unauthorized') {
      setErrorMessage('You are not authorized to use the File Manager.');
    } else {
      setErrorMessage(getErrorMsg(error));
    }
  };

  const refreshToken = force => {
    if (refreshTokenLoading.current) {
      window.logHelper.info('Token refresh is already in progress');
      return;
    }
    if (force) {
      setIsTokenReceived(false);
    }
    const lsKey = getLocalStorageKey();
    // Connect to File Manager and get token per service
    const data = {
      website_slug: website.slug,
    };
    if (fileManagerType === 'package') {
      data.name = packageName;
      data.type = packageType;
    }
    refreshTokenLoading.current = true;
    WebsiteService.createFileManagerToken(data)
      .then(res => {
        localStorage.removeItem(lsKey);
        localStorage.setItem(lsKey, res.token);
        setIsTokenReceived(true);
        refreshTokenBusy.current = true;
      })
      .catch(error => handleTokenError(error, 'core'))
      .finally(() => {
        refreshTokenLoading.current = false;
      });
  };
  // Intercept request and add headers
  xhook.before(function (request) {
    // Only consider requests with filemanager/
    if (!isFileManagerApiRequest(request.url)) {
      return;
    }
    // Use flag to display error only once
    responseMessage.current = true;
    // Add token to header
    const lsKey = getLocalStorageKey();
    const fmToken = localStorage.getItem(lsKey);
    if (fmToken) {
      request.headers['Filemanager-Token'] = fmToken;
    }
    // Add cache option
    if (clearServerCache.current && request.url.endsWith('files/')) {
      request.url = request.url.slice(0, -1) + '?clearCache=1';
      clearServerCache.current = false;
    }
    // Handle body object
    if (!request.body) {
      // Body is null: nothing to modify.
      return;
    } else if (StringHelper.is(request.body)) {
      // Body is a string: parse and modify.
      let bodyAsObj = JSON.parse(request.body || '{}');
      if (request.method === 'PATCH') {
        // Convert PATCH + PUT requests to POST
        request.method = 'POST';
        request.url += '/rename';
      }
      // Apply modified body
      request.body = JSON.stringify(bodyAsObj);
    }
  });

  // If the api/v1/filemanager request fails then try to refresh token and/or
  // show an error notification
  xhook.after(function (request, response) {
    if (!isFileManagerApiRequest(request.url)) {
      return;
    }

    // stop refresh button loading after the /files/ API back.
    if (request.url.endsWith('clearCache=1')) {
      setTriggerRefreshBtn(triggerRefreshBtn + 1);
    }

    // error is displayed more than once.
    if (response.status === 500 || response.statusText !== 'OK') {
      // Handle failed response
      const resData = StringHelper.is(response.data) ? StringHelper.toJson(response.data) : response.data;
      if (!resData || !resData.error) {
        return;
      }
      if (
        resData.error &&
        resData.error.includes('Token data not found') &&
        responseMessage.current
      ) {
        // If token is not found then display a custom error
        dispatch(setGlobalErrorMsg(`Oops! Something went wrong. Refreshing token...`));
        responseMessage.current = false;
        if (refreshTokenBusy.current) {
          refreshTokenBusy.current = false;
          refreshToken(true);
        }
      } else {
        if (responseMessage.current) {
          // Otherwise display the server error
          dispatch(setGlobalErrorMsg(resData.error));
          responseMessage.current = false;
        }
      }
    } else {
      // Handle successful response
      const resText = response.text;
      if (!resText) {
        return;
      }
      const resData = StringHelper.toJson(resText);
      if (resData && resData.action && responseMessage.current) {
        dispatch(
          setGlobalSuccessMsg({
            model: `${resData.object_type} ${resData.object_name}`,
            onId: WebsiteHelper.getLabel(website),
            action: resData.action,
          }),
        );
        responseMessage.current = false;
      }
    }
  });

  const handleEditorDidMount = value => {
    editorValue.current = value;
    setCustomEditorRenderFlag(true);
  };

  const getFilesInfoStatus = actions => {
    const item = actions.getSelectedResources();
    const isEmptyResourse = isEmpty(item);
    const isPhpFile = !isEmptyResourse ? item[0].name.endsWith('.php') : false;
    const isInsideAdminIncludesFolders =
      (!isEmpty(item[0].ancestors) && item[0].ancestors[0].path.startsWith('wp-admin')) ||
      (!isEmpty(item[0].ancestors) && item[0].ancestors[0].path.startsWith('wp-includes'));

    return isPhpFile || isInsideAdminIncludesFolders;
  };

  const editFileHandler = async (actions, preventFileEdit, type) => {
    if (getFilesInfoStatus(actions) && type === 'edit-file' && fileManagerType === 'website') {
      if (actions.getSelectedResources()[0].name === 'wp-config.php') {
        modalDialog({
          line1: `To edit the wp-config.php file, please click <strong>Edit WP Config File</strong> button below. Editing this particular file from the main directory has been disabled for security reasons.`,
          type: 'info',
          btnText: 'Edit WP Config File',
          closeBtn: true,
          btnsStyle: 'standard',
          btnsAlign: 'flex-end',
        }).then(() => {
          history.push({
            pathname: `/websites/${website.slug}/advanced/wp-config`,
          });
        });
      } else {
        modalDialog({
          line1: `Editing of WordPress core files has been disabled due to both security and best practices. Any update of WordPress will override your changes.`,
          type: 'warning',
        });
      }

      return;
    }
    setEditorLoading(true);
    if (preventFileEdit) {
      setEditBtn(false);
    }
    if (!isEmpty(actions.getSelectedResources())) {
      // Determine file extension
      const name = actions.getSelectedResources()[0].name;
      if (name.endsWith('js')) {
        setSelectedEditorLanguage('javascript');
      } else {
        setSelectedEditorLanguage(name.split('.').pop());
      }

      // Get the selected file resource
      const fileResource = actions.getSelectedResources()[0];
      const data = {
        file_id: fileResource.id,
      };

      // Send API requeust to get file content
      try {
        const res = await FileManagerService.getFileContent(website, data);
        if (res) {
          setSelectedFileValue(res.content);
          res.parentId = fileResource.parentId;
          res.navigateToDirCallback = actions.navigateToDir;
          setSelectedFile(res);
          setEditorLoading(false);
        } else {
          dispatch(setGlobalErrorMsg(`Failed to read ${name} file on ${WebsiteHelper.getLabel(website)}.`));
          setEditorLoading(false);
          setCustomEditorRenderFlag(false);
        }
      } catch (error) {
        dispatch(setGlobalErrorMsg(error));
        setEditorLoading(false);
        setCustomEditorRenderFlag(false);
      }
    }
  };

  const downloadFileHandler = async actions => {
    if (!isEmpty(actions.getSelectedResources())) {
      const name = actions.getSelectedResources()[0].name;
      const id = actions.getSelectedResources()[0].id;

      const data = {
        file_id: id,
        file_name: name,
      };

      try {
        await FileManagerService.downloadFile(website, data);
      } catch (error) {
        dispatch(setGlobalErrorMsg(error));
      }
    }
  };

  const canDownloadItem = item => {
    // Check if file is downloadable
    return item.capabilities.canDownload;
  };

  const canEditItem = item => {
    // Add dummy edit for *.php files and wp-admin/* wp-includes/* files no matter if it's allowed or not
    // Add dummy edit for wp-config.php file no matter if it's allowed or not
    // both in one step
    if (
      (item.name.endsWith('.php') ||
        (!isEmpty(item.ancestors) && item.ancestors[0].path.startsWith('wp-admin')) ||
        (!isEmpty(item.ancestors) && item.ancestors[0].path.startsWith('wp-includes'))) &&
      fileManagerType === 'website' &&
      item.type !== 'dir'
    ) {
      return true;
    }
    // Check if file is editable
    const canEdit = item.capabilities.canEdit;
    if (!canEdit) {
      return false;
    }
    // Check if extension is permitted and set editor if so
    const name = item.name;
    for (const i in editorAvailableLanguages) {
      if (name.endsWith(`.${editorAvailableLanguages[i]}`)) {
        return true;
      }
    }
    // Otherwise; do not allow editing file
    return false;
  };

  const createFileHandler = (apiOptions, actions) => {
    let showDialog = actions.showDialog;
    let hideDialog = actions.hideDialog;
    let getResource = actions.getResource;
    let navigateToDir = actions.navigateToDir;
    let _id = null;
    if (!currentFolderId) {
      _id = getResource().id;
      setCurrentFolderId(_id);
      window.logHelper.warning(`File Manager: current folder id is not set. Using resource id: ${_id}.`);
    }
    let rawDialogElement = {
      elementType: 'SetNameDialog',
      elementProps: {
        initialValue: '',
        onHide: hideDialog,
        onSubmit: async function (_name) {
          const data = {
            parentId: currentFolderId ? currentFolderId : _id,
            name: _name,
            type: 'file',
          };
          hideDialog();
          try {
            await FileManagerService.createFile(website, data);
            navigateToDir(data.parentId);
          } catch (error) {
            window.logHelper.warning('Failed to create file', error);
          }
        },
        onValidate: async _name => {
          if (!_name || _name === '') {
            return 'File name cannot be empty.';
          } else if (_name.length >= 32) {
            return 'File name cannot be longer than 32 characters.';
          }
          return null;
        },
        inputLabelText: 'File name',
        headerText: 'Create file',
        submitButtonText: 'Create',
        cancelButtonText: 'Cancel',
      },
    };
    showDialog(rawDialogElement);
  };

  const saveFileHandler = async (e, closeFlag) => {
    const data = {
      file_id: selectedFile.file_id,
      content: editorValue.current() || '',
    };
    if (editorValue.current() === selectedFileValue) {
      dispatch(setGlobalWarningMsg('File content has not changed.'));
      return;
    }
    if (closeFlag) {
      setSaveAndCloseLoading(true);
    } else {
      setEditorSaveLoading(true);
    }
    await FileManagerService.putFileContent(website, data)
      .then(res => {
        selectedFile.navigateToDirCallback(selectedFile.parentId);
        postFileSave(res.object_path, res.object_type);
        if (closeFlag) {
          closeFileEditor();
        }
      })
    setEditorSaveLoading(false);
    setSaveAndCloseLoading(false);
  };
  const handleSaveAndCloseFileHandler = () => {
    saveFileHandler(null, true);
  };
  const clearCache = () => {
    setClearCacheLoading(true);
    const data = {
      website_slug: website.slug,
      permalinks: '',
    };
    dispatch(purgeWebsiteCache(data))
      .then(() => dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'Cached files', action: 'purged' })))
      .finally(() => setClearCacheLoading(false));
  };

  const postFileSave = (path, type) => {
    // After saving file we need to refresh the file's cdn path if the website has cdn installed
    if (fileManagerType === 'package') {
      const fileExt = StringHelper.getFileExtension(path);
      if (!isEmpty(website.cdns) && fileExt !== 'php') {
        const data = {
          website_slug: website.slug,
          object_paths: [ `/wp-content/${packageType}s/${packageName}/${path}` ],
        };
        WebsiteService.invalidateCDNPaths(data)
          .then(() => window.logHelper.info('CDN paths invalidated', data))
      }
    }
  };

  const hasPermission = (actions, type) => {
    if (!actions) {
      return;
    }
    if (type === 'download-file') {
      const selectedResources = actions.getSelectedResources();
      if (selectedResources.length !== 1) {
        return false; // Disallow multi-select
      }
      return selectedResources.every(canDownloadItem);
    } else if (type === 'create-file') {
      const resourceLocation = actions.getResourceLocation();
      if (isEmpty(resourceLocation.length)) {
        return false;
      }
      const deepestChild = resourceLocation[resourceLocation.length - 1];
      return deepestChild.capabilities.canAddChildren;
    } else if (type === 'edit-file') {
      const selectedResources = actions.getSelectedResources();
      if (selectedResources.length !== 1) {
        return false; // Disallow multi-select
      }
      return selectedResources.every(canEditItem);
    }

    return false;
  };

  const getCapabilities = (apiOptions, actions) => {
    const customCapabilities = [...connectorNodeV1.capabilities(apiOptions, actions)].filter(
      el => el.id !== 'download',
    );
    return [
      ...customCapabilities,

      {
        id: 'view-btn',
        icon: {
          svg: `<svg data-test-id="svg-view-btn" xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 512 512" 
             height="512" viewBox="0 0 512 512" width="512"><g><path d="m74.737 0h-.016l-74.707 74.707v.016h74.723z"/>
             <circle cx="222.536" cy="222.504" r="84.757"/><path d="m387.87 342.862v90.054l29.484 29.481 45.029-45.03z"/>
             <path d="m443.435 455.214h63.682v40.143h-63.682z" transform="matrix(.707 -.707 .707 .707 -196.873 475.283)"/>
             <path d="m334.191 355.383-41.859-41.854c-19.352 14.874-43.557 23.731-69.795 23.731-63.277 0-114.757-51.479-114.757-114.757s51.479-114.758 
             114.757-114.758 114.757 51.48 114.757 114.758c0 26.247-8.864 50.459-23.746 69.815l41.858 41.853 11.888-11.885 
             20.578 20.575v-342.861h-283.135v104.723h-104.723v407.277h387.856v-79.084l-65.611-65.604z"/></g></svg>`,
        },
        label: 'View',
        shouldBeAvailable: () => hasPermission(actions, 'download-file'),
        availableInContexts: ['row', 'toolbar'],
        handler: () => editFileHandler(actions, true),
      },
      {
        id: 'download-btn',
        icon: {
          svg:
            '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" focusable="false"><path d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z"></path></svg>',
        },
        label: 'Download',
        shouldBeAvailable: () => hasPermission(actions, 'download-file'),
        availableInContexts: ['row', 'toolbar'],
        handler: () => downloadFileHandler(actions),
      },
      {
        id: 'create-file',
        icon: {
          svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="m262.96875 8.785156v119.746094h119.746094zm0 0"/>
            <path d="m211 376.5c0-91.257812 74.242188-165.5 165.5-165.5 5.058594 0 10.058594.242188 15 .6875v-53.152344h-143.53125c-8.285156 0-15-6.71875-15-15v-143.535156h-217.96875c-8.285156 
            0-15 6.714844-15 15v482c0 8.285156 6.714844 15 15 15h266.585938c-42.652344-29.96875-70.585938-79.527344-70.585938-135.5zm0 0"/><path d="m416.667969 361.5h-25.167969v-25.167969c0-8.28125-6.714844-15-15-15s-15 
            6.71875-15 15v25.167969h-25.164062c-8.285157 0-15 6.714844-15 15s6.714843 15 15 15h25.164062v25.167969c0 8.28125 
            6.714844 15 15 15s15-6.71875 15-15v-25.167969h25.167969c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15zm0 0"/>
            <path d="m376.5 241c-74.714844 0-135.5 60.785156-135.5 135.5s60.785156 135.5 135.5 135.5 135.5-60.785156 
            135.5-135.5-60.785156-135.5-135.5-135.5zm0 241c-58.171875 0-105.5-47.328125-105.5-105.5s47.328125-105.5 105.5-105.5 
            105.5 47.328125 105.5 105.5-47.328125 105.5-105.5 105.5zm0 0"/></svg>`,
        },
        label: 'Create file',
        shouldBeAvailable: () => hasPermission(actions, 'create-file'),
        availableInContexts: ['toolbar'],
        handler: () => createFileHandler(apiOptions, actions),
      },
      {
        id: 'edit-btn',
        icon: {
          svg: `<svg data-test-id="svg-edit-btn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" 
            x="0px" y="0px" viewBox="0 0 455.5 455.5" style="enable-background:new 0 0 455.5 455.5;" xml:space="preserve">
            <g>
              <path d="M242.56,420.117l-60.104-60.103l132.781-132.781l60.103,60.104L242.56,420.117z"/>
              <polygon points="235.787,14.793 235.787,85.5 306.494,85.5  "/>
              <path d="M152.04,348H70.787v-30H182.04l40-40H70.787v-30H252.04l69.247-69.247V115.5h-115.5V0h-185.5v416h107.062l15.702-53.79   
              l2.153-7.374L152.04,348z M70.787,178h200v30h-200V178z"/>
              <path d="M336.449,206.018l38.661-38.661l60.104,60.104l-38.661,38.661L336.449,206.018z"/>
              <polygon points="158.602,416 147.071,455.5 215.534,435.514 167.057,387.036  "/>
            </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
            <g></g><g></g></svg>`,
        },
        label: 'Edit',
        shouldBeAvailable: () => hasPermission(actions, 'edit-file'),
        availableInContexts: ['row', 'toolbar'],
        handler: () => editFileHandler(actions, false, 'edit-file'),
      },
    ];
  };

  const closeFileEditor = () => {
    setSelectedFileValue(null);
    setSelectedFile(null);
    setEditBtn(true);
    setCustomEditorRenderFlag(false);
  };

  const showChangesHistoryTable = () => {
    setShowHistoryLogsTable(true);
    setCustomBackBtn(true);
    getLogsData();
  };

  const refreshAndClearServerCache = () => {
    clearServerCache.current = true;
    setTriggerRefreshCount(triggerRefreshCount + 1);
  };
  const onResourceItemDoubleClick = ({ rowData }) => {
    setCurrentFolderId(rowData.id);
    if (rowData.type === 'dir' && (rowData.name === 'plugins' || rowData.name === 'themes')) {
      const capitalizedName = StringHelper.capitalizeFirstLetter(rowData.name);
      const Name = rowData.name === 'plugins' ? 'plugin' : 'theme';
      modalDialog({
        line1: `To edit a ${Name} file, please click <strong>Browse ${capitalizedName}</strong> button below. Browsing through the ${rowData.name} directory from the main File Manager has been disabled for security reasons.`,
        type: 'warning',
        btnText: `Browse ${capitalizedName}`,
        closeBtn: true,
      }).then(() => {
        history.push({
          pathname: `/websites/${website.slug}/${rowData.name}`,
        });
      });
    }
  };

  return (
    <Container className='margin-0'>
      {showHistoryLogsTable ? (
        <Fragment>
          <TitleBar className='titlebar'>
            <TitleBar.Title> History Changes </TitleBar.Title>
          </TitleBar>

          <Content className='history-changes-table'>
            <WPSDataTable
              columns={historyColumns}
              body={historyLogs}
              loading={historyLoading}
              dataKey='datetime'
            />
          </Content>
        </Fragment>
      ) : (
        <Fragment>
          {isNotEmpty(selectedFileValue) ? (
            <Fragment>
              <TitleBar className='titlebar'>
                <TitleBar.Title>
                  {selectedFile && selectedFile.file_name ? selectedFile.file_name : ''}
                </TitleBar.Title>
                <TitleBar.Actions>
                  {editBtn && (
                    <Fragment>
                      <WPSButton
                        className='warning--btn'
                        loading={clearCacheLoading}
                        onClick={() => clearCache()}>
                        Clear cache
                      </WPSButton>
                      <WPSButton
                        disabled={editorSaveLoading}
                        loading={saveAndCloseLoading}
                        className='success--btn'
                        onClick={handleSaveAndCloseFileHandler}>
                        Save & Close
                      </WPSButton>
                      <WPSButton
                        disabled={saveAndCloseLoading}
                        loading={editorSaveLoading}
                        className='save--btn'
                        onClick={saveFileHandler}>
                        Save
                      </WPSButton>
                    </Fragment>
                  )}
                  <WPSButton
                    className='close--btn'
                    style={{
                      color: 'rgb(87, 87, 87)',
                      border: '1px solid rgb(87, 87, 87)',
                      width: '64px',
                      height: '34px',
                    }}
                    onClick={() => closeFileEditor()}>
                    Close
                  </WPSButton>
                </TitleBar.Actions>
              </TitleBar>
            </Fragment>
          ) : (
            <Fragment>
              <TitleBar className='titlebar'>
                <TitleBar.Title> File Manager </TitleBar.Title>
                <TitleBar.Actions>
                  <WPSButton
                    icon='refresh'
                    className='refresh--btn'
                    onClick={() => refreshAndClearServerCache()}
                    loading={clearServerCache.current}
                    key={triggerRefreshBtn}>
                    Refresh
                  </WPSButton>
                  <WPSButton
                    icon='history'
                    className='action--read'
                    onClick={() => showChangesHistoryTable()}>
                    History
                  </WPSButton>
                </TitleBar.Actions>
              </TitleBar>
              <p className='color-primary subheader'>
                {fileManagerType !== 'package' ? coreFileManagerInfo : packageFileManagerInfo}
              </p>
            </Fragment>
          )}
          <Content>
            <div
              className='file-manager-parent'
              style={{
                height: '480px',
                position: 'relative',
              }}>
              <FileManager className={isNotEmpty(selectedFileValue) ? 'visibility-hidden' : ''}>
                {/* Do not load file navigator unless token is received */}
                {isTokenReceived ? (
                  <FileNavigator
                    id='filemanager-1'
                    api={connectorNodeV1.api}
                    apiOptions={apiOptions}
                    capabilities={(apiOptions, actions) => getCapabilities(apiOptions, actions)}
                    onResourceItemClick={e => onResourceItemDoubleClick(e)}
                    listViewLayout={connectorNodeV1.listViewLayout}
                    viewLayoutOptions={connectorNodeV1.viewLayoutOptions}
                    key={triggerRefreshCount}
                  />
                ) : (
                  <div className='wait-message'>
                    {errorMessage ? errorMessage : 'Connecting...'}
                  </div>
                )}
              </FileManager>
              {editorLoading ? (
                <div className='editor-loading'>
                  <span>Loading...</span>
                </div>
              ) : (
                isNotEmpty(selectedFileValue) && (
                  <Editor
                    value={selectedFileValue}
                    wrapperClassName={
                      customEditorRenderFlag
                        ? 'custom-editor'
                        : 'custom-editor custom-hidden-editor'
                    }
                    language={selectedEditorLanguage}
                    editorDidMount={e => handleEditorDidMount(e)}
                  />
                )
              )}
            </div>
          </Content>
        </Fragment>
      )}
    </Container>
  );
};

export default FileManagerData;
