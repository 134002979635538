import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { partnerRevenueSummaryWidgetSelector } from 'store/widget/widgetSelectors';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
  } from 'recharts';
import theme from 'styles/theme';
import JsxHelper from 'helpers/jsx';

const PartnerRevenueMetrics = () => {
  const widget = useSelector(partnerRevenueSummaryWidgetSelector);
  const widgetData = widget ? widget.data : {};
  let currency = '';

  const getChartData = () => {
    let chartData = [];
    for (const date of Object.keys(widgetData)) {
      const item = widgetData[date];
      currency = currency || item.currency;
      chartData.push({
        date: date,
        invoiced_amount: (parseFloat(item.paid_amount) + parseFloat(item.unpaid_amount)).toFixed(2),
        paid_amount: item.paid_amount,
        unpaid_amount: item.unpaid_amount,
        paid_invoices: item.paid_invoices,
        unpaid_invoices: item.unpaid_invoices,
      });
    }
    return chartData;
  }

  return (
    <Fragment>
      <ResponsiveContainer width='100%' height={250}>
      <LineChart data={getChartData()}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='date' />
      <YAxis />
      <Tooltip formatter={(value) => `${value} ${currency}`} />
      <Legend align='right' wrapperStyle={{ bottom: 0 }} />
      <Line type='monotone' name='Invoiced' dataKey='invoiced_amount' nstroke={theme.color.info}/>
      <Line type='monotone' name='Paid' dataKey='paid_amount' stroke={theme.color.success} />
      <Line type='monotone' name='Unpaid' dataKey='unpaid_amount' stroke={theme.color.danger} />
      </LineChart>
      </ResponsiveContainer>
      <div className='widget-notice' style={{marginTop: '25px'}}>
        * Widgets may not be up-to-date. For real-time data, {JsxHelper.createRelativeLink('reports/business-performance', 'click here', 'goto-link')}.
      </div>
    </Fragment>
  );
}

export default PartnerRevenueMetrics;