import React, { useState, useEffect } from 'react';
import LegalService from 'services/legal';
import useQuery from 'hooks/useQuery';

const styles = {
  maxWidth: '1000px',
  padding: '24px',
  margin: '0 auto',
};

const TermsAndConditions = () => {
  const query = useQuery();
  const [data, setData] = useState({ __html: '' });

  useEffect(() => {
    const data = { page: query.get('page'), email: query.get('email') };
    const fetchData = () => {
      LegalService.fetchGuestPage(data).then(res => setData({ __html: res.body }));
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return <div style={styles} dangerouslySetInnerHTML={data}></div>;
};

export default TermsAndConditions;
