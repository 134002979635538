import React, { Fragment, useRef, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSLabel } from 'styles/layout/forms';
import { useState } from 'react';
import { WPSButton } from 'styles/layout/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { serversListSelector } from 'store/server/serverSelectors';
import { websitesListSelector } from 'store/website/websiteSelectors';
import { isEmpty, sortDataAlph } from 'helpers';
import ToolService from 'services/tool';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { Content } from 'styles/globalStyles';
import { regionsListSelector } from 'store/region/regionSelectors';
import StringHelper from 'helpers/string';
import WPSDataTable from '../../components/wpstaq/WPSDataTable/WPSDataTable';
import globalHelper from 'helpers/globalHelper';
import ArrayHelper from 'helpers/array';
import { useHistory } from 'react-router-dom';
import JsxHelper from 'helpers/jsx';

const Script = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const servers = useSelector(serversListSelector);
  const websites = useSelector(websitesListSelector);
  const regions = useSelector(regionsListSelector);

  const [details, setDetails] = useState({
    server_slugs: [],
    website_slugs: [],
    region_slugs: [],
    working_dir: 'ewsm',
    shell_context: '',
    select_value: [],
  });
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();

  const cancelledRef = useRef([]);
  const [currentRequest, setCurrentRequest] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [shellContext, setShellContext] = useState('');
  const [selectKey, setSelectKey] = useState(1);
  const [templateKey, setTemplateKey] = useState(1);

  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);
  const onMultiSelectResource = options => {
    const _values = (!isEmpty(options) ? options.target.values : []).map(option => option.value);

    setDetails(prev => ({
      ...prev,
      server_slugs: _values,
      website_slugs: _values,
      region_slugs: _values,
      select_value: _values,
    }));
  };

  const onSelectScript = e => {
    const { value } = e.target;
    setDetails(prev => ({ ...prev, working_dir: value, select_value: [] }));
    setSelectedTemplate('');
    setShellContext('');
    setSelectKey(selectKey + 1);
    setTemplateKey(templateKey + 1);
  };

  const onSelectTemplate = e => {
    const { value } = e.target;
    // document.getElementById('shell-context').value = value;
    setShellContext(value);
  };

  const handleCancel = () => {
    setLoading(false);
    let updatedValues = [...cancelledRef.current, currentRequest];
    cancelledRef.current = updatedValues;
  };

  const handleSuccess = (random, res) => {
    if (cancelledRef.current.includes(random)) return;
    setResults(res);
  };

  const handleFinally = random => {
    if (cancelledRef.current.includes(random)) return;
    setLoading(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    let data;

    let random = StringHelper.randomString();
    setCurrentRequest(random);

    if (details.working_dir === 'ewsm') {
      data = {
        server_slugs: details.server_slugs,
        working_dir: 'ewsm',
        shell_context: shellContext,
      };
      ToolService.runEWSScript(data)
        .then(res => {
          if (mounted.current) {
            handleSuccess(random, res);
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            handleFinally(random);
          }
        });
    } else if (details.working_dir === 'website_custom') {
      data = {
        website_slugs: details.website_slugs,
        working_dir: 'website',
        shell_context: shellContext,
      };
      ToolService.runEWSScript(data)
        .then(res => {
          if (mounted.current) {
            handleSuccess(random, res);
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            handleFinally(random);
          }
        });
    } else if (details.working_dir === 'website_all') {
      data = {
        server_slugs: details.server_slugs,
        working_dir: 'website',
        shell_context: shellContext,
      };
      ToolService.runEWSScript(data)
        .then(res => {
          if (mounted.current) {
            handleSuccess(random, res);
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            handleFinally(random);
          }
        });
    } else if (details.working_dir === 'erm') {
      data = {
        region_slugs: details.region_slugs,
        shell_context: shellContext,
      };
      ToolService.runERMScript(data)
        .then(res => {
          if (mounted.current) {
            handleSuccess(random, res);
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            handleFinally(random);
          }
        });
    } else if (details.working_dir === 'egm') {
      data = {
        shell_context: shellContext,
      };
      ToolService.runEGMScript(data)
        .then(res => {
          if (mounted.current) {
            handleSuccess(random, res);
          }
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          if (mounted.current) {
            handleFinally(random);
          }
        });
    }
  };

  const getNameKey = () => {
    if (!!results) {
      return 'server_slug' in results[0] ? 'server_slug' : 'region_slug';
    } else {
      return 'server_slug';
    }
  };

  const headers = [
    JsxHelper.createTableTextHeader(getNameKey(), 'Name', '10%'),
    JsxHelper.createTableStatusHeader(),
    JsxHelper.createTableTextHeaderWithCallback('output', 'Output', '80', row => 
      !StringHelper.is(row.output)
        ? JSON.stringify(row.output)
        : row.output.split("\n").map((v,k) => (<Fragment key={k}>{v}<br/></Fragment>))
    ),
  ];

  const scriptTemplates = [
    {
      text: 'Custom',
      context: '',
      working_dirs: ['website_all', 'website_custom', 'ewsm', 'erm', 'egm'],
    },
    {
      text: 'Restart PHP-FPM service',
      context: 'service php-fpm restart',
      working_dirs: ['ewsm'],
    },
    {
      text: 'Restart NGINX service',
      context: 'service nginx restart',
      working_dirs: ['ewsm'],
    },
    {
      text: 'Refresh internal cache',
      context: 'wp elemento-core refresh-internal-cache',
      working_dirs: ['website_all', 'website_custom'],
    },
    {
      text: 'Update must-use core plugin for all sites',
      context: 'sh .ec2admin/tools/update-mu-plugins.sh',
      working_dirs: ['ewsm'],
    },
    {
      text: 'Renew the default wildcard SSL certificate',
      context: 'sh .ec2admin/tools/renew-wildcard-ssl.sh',
      working_dirs: ['ewsm'],
    },
  ];

  const breadcrumbs = JsxHelper.createBreadcrumbs('Shell Scripts', 'Tools');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Run shell scripts</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({
            label: 'Back',
            onClick: history.goBack,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSForm style={{ maxWidth: '600px' }} onSubmit={onSubmit}>
          <WPSForm.Fieldset>
            <legend>Execute</legend>
            <WPSForm.Row>
              <WPSForm.RowItem flex='2'>
                {JsxHelper.createSelectInput({
                  name: 'script',
                  label: 'Select script',
                  options: ArrayHelper.buildSelectOptions(
                    globalHelper.shellScriptsTypes,
                    'name',
                    'value',
                  ),
                  value: details.working_dir,
                  onChange: onSelectScript,
                })}
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='2'>
                {details.working_dir !== 'egm' && (
                  <Fragment>
                    {JsxHelper.createSelectInput({
                      label: `Select ${details.working_dir === 'website_custom' ? 'websites' : details.working_dir === 'erm' ? 'regions' : 'servers'}`,
                      name: 'select_value',
                      options: details.working_dir === 'website_custom' ? sortDataAlph(websites) : details.working_dir === 'erm' ? sortDataAlph(regions) : sortDataAlph(servers),
                      value: details.select_value,
                      onChange: onMultiSelectResource,
                      closeMenuOnSelect: false,
                      multiSelect: true,
                      key: selectKey,
                    })}
                  </Fragment>
                )}
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='2'>
                <Fragment>
                  <WPSLabel>Script context</WPSLabel>
                  <textarea
                    id='shell-context'
                    rows='3'
                    style={{ width: '100%', resize: 'vertical', padding: '5px' }}
                    value={shellContext}
                    onChange={e => setShellContext(e.target.value)}></textarea>
                </Fragment>
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='2'>
                <Fragment>
                  {JsxHelper.createSelectInput({
                    label: '...or select from template',
                    name: 'select-template',
                    value: selectedTemplate,
                    options: ArrayHelper.buildSelectOptions(
                      scriptTemplates.filter(t => t.working_dirs.includes(details.working_dir)),
                      'text',
                      'context',
                    ),
                    onChange: onSelectTemplate,
                    sortOff: true,
                    key: templateKey,
                  })}
                </Fragment>
              </WPSForm.RowItem>
            </WPSForm.Row>

            <div style={{ display: 'flex' }}>
              <WPSButton
                type='submit'
                className='primary--btn'
                style={{ maxWidth: '120px' }}
                loading={loading}>
                Execute
              </WPSButton>
              <WPSButton
                type='button'
                className='warning--btn'
                style={{ maxWidth: '120px', maxHeight: '34px', margin: 'auto 0 0 16px' }}
                disabled={!loading}
                onClick={handleCancel}>
                Cancel
              </WPSButton>
            </div>
          </WPSForm.Fieldset>
        </WPSForm>
        {results && (
          <WPSForm.Fieldset>
            <legend>Results</legend>
            {results[0].error &&
              results.map((r, index) => (
                <div style={{ color: 'red' }} key={index}>
                  {r.error}
                </div>
              ))}
            <WPSDataTable
              columns={headers}
              body={results}
              noSearchOnTable={true}
              rowsPerPage={100}
            />
          </WPSForm.Fieldset>
        )}
      </Content>
    </Fragment>
  );
};

export default Script;
