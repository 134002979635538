import React, { Fragment, useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSInput, WPSLabel, ErrorMsg } from 'styles/layout/forms';
import Tooltip from 'components/layout/tooltip';
import { WPSButton } from 'styles/layout/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { customerBillingSettings } from 'store/customerBilling/customerBillingSelectors';
import { updateCustomerBillingSettings } from 'store/customerBilling/customerBillingActions';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import ArrayHelper from 'helpers/array';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';

const Billing = ({ isCustomPage, onUpdateSettings }) => {
  useTitle('Client Billing');
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [loading, setLoading] = useState(false);

  const currentSettings = useSelector(customerBillingSettings);

  const [settings, setSettings] = useState({
    billing_day: currentSettings ? currentSettings.billing_day : 1,
    billing_currency: currentSettings ? currentSettings.billing_currency : 'USD',
    tax_rate: currentSettings ? currentSettings.tax_rate : 1,
  });

  const onChange = e => {
    const { name, value } = e.target;
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    setLoading(true);
    const data = {
      billing_day: parseInt(settings.billing_day),
      billing_currency: settings.billing_currency,
      tax_rate: parseInt(settings.tax_rate),
    };
    dispatch(updateCustomerBillingSettings(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'billing settings', plural: true }));
        if (isCustomPage) {
          onUpdateSettings();
        }
      })
      .finally(() => setLoading(false));
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Billing Settings',
    },
  ];

  return (
    <Fragment>
      {!isCustomPage && (
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>Billing Settings</TitleBar.Title>
          <TitleBar.Actions>
            {JsxHelper.createBackButton('/clients')}
          </TitleBar.Actions>
        </TitleBar>
      )}
      <Content className={isCustomPage? 'padding-0':''}>
        <WPSForm style={{ maxWidth: '600px' }}>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel required>
                Billing day
                {errors.billing_day && <ErrorMsg>{errors.billing_day.message}</ErrorMsg>}
                <Tooltip
                  place='right'
                  text="The billing day of the month on which you want to end your client's subscriptions billing cycle."
                />
              </WPSLabel>
              <WPSInput
                type='number'
                name='billing_day'
                min='1'
                max='28'
                step='1'
                value={settings.billing_day}
                onChange={onChange}
                ref={register({ required: FormHelper.messages.required })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel required>Billing currency</WPSLabel>
              <WPSSelect
                name='billing_currency'
                value={settings.billing_currency}
                options={ArrayHelper.buildSelectOptions(
                  globalHelper.customerCurrencyOptions,
                  'name',
                  'value',
                )}
                onChange={onChange}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel required>
                Tax rate %{errors.tax_rate && <ErrorMsg>{errors.tax_rate.message}</ErrorMsg>}
                <Tooltip
                  place='right'
                  text='The default tax rate you want to apply to the subtotal amount of client invoices.'
                />
              </WPSLabel>
              <WPSInput
                type='number'
                name='tax_rate'
                min='0'
                max='100'
                step='1'
                value={settings.tax_rate}
                onChange={onChange}
                ref={register({ required: FormHelper.messages.required })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSButton
            className={isCustomPage ? 'next-light--btn margin-top-bottom-24' : 'save--btn max-width-150'}
            type='submit'
            onClick={handleSubmit(onSubmit)}
            loading={loading}>
            {isCustomPage ? 'Next' : 'Save'}
          </WPSButton>
        </WPSForm>
      </Content>
    </Fragment>
  );
};

export default Billing;
