import React, { useState, Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useForm } from 'react-hook-form';
import StringHelper from 'helpers/string';
import ArrayHelper from 'helpers/array';
import { useDispatch } from 'react-redux';
import { updateWebsiteRedirectRules } from 'store/website/websiteActions';
import { setGlobalSuccessMsg, setGlobalWarningMsg } from 'store/global/globalActions';
import { Container } from 'styles/website/profile';
import { isEmpty, isEmptyOrNull, sortByNumericField, exportCsvToFile } from 'helpers';
import { useRef } from 'react';
import Papa from 'papaparse';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import FormHelper from 'helpers/form';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';
import env from 'config/env';

const Redirects = ({ website }) => {
  useTitle('Website Redirects');
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onModalSubmit' });

  const ANY_DOMAIN_VALUE = 'any';
  const ANY_DOMAIN_LABEL = 'Any';

  // Get origin host value.
  const getOriginHostValue = (row) => !row.origin_host || row.origin_host === ANY_DOMAIN_VALUE ? ANY_DOMAIN_VALUE : row.origin_host;

  // Get origin host label.
  const getOriginHostLabel = (row) => !row.origin_host || row.origin_host === ANY_DOMAIN_VALUE ? ANY_DOMAIN_LABEL : row.origin_host;

  // Get origin host domain (if set to any, use default domain).
  const getOriginHostDomain = (row) => !row.origin_host || row.origin_host === ANY_DOMAIN_VALUE ? website.default_domain : row.origin_host;

  const initialState = {
    origin_host: ANY_DOMAIN_VALUE,
    type: 'strcmp',
    from: '/old-page',
    to: '/new-page',
    code: '301',
    order: 1,
  };

  const originHosts = [{
    label: ANY_DOMAIN_LABEL,
    value: ANY_DOMAIN_VALUE
  }].concat(website.domains.map(domain => ({
    label: domain,
    value: domain,
  })));

  const types = [
    { value: 'strcmp', label: 'Literal string' },
    { value: 'regex', label: 'Regular expression' },
  ];

  const fixRulesOrder = rules => {
    if (isEmpty(rules)) {
      return [];
    }
    let tempRules = sortByNumericField(rules, 'order');
    let order = 1;
    for (const i in tempRules) {
      tempRules[i] = { ...tempRules[i], order: order++ };
    }
    return tempRules;
  };

  const fileUpload = useRef(null);
  const [importLoading, setImportLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(initialState);
  const [nextOrder, setNextOrder] = useState(1);
  const [rules, setRules] = useState(fixRulesOrder(WebsiteHelper.getURLRedirects(website)));

  const onModalInputChange = e => {
    const { name, value } = e.target;
    if (name === 'origin_host') {
      // Force strcmp + '/' for custom domains.
      if (value !== ANY_DOMAIN_VALUE) {
        setDetails(prev => ({ ...prev, type: 'strcmp', from: '/' }));
      }
    }
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  // Add rule to state (modal submit)
  const onModalSubmit = () => {
    // Add unique ID to each entry.
    const newRule = {
      origin_host: getOriginHostValue(details),
      type: details.type,
      from: details.from,
      to: details.to,
      order: nextOrder,
      code: details.code,
      slug: update ? details.slug : StringHelper.randomString(),
    };
    if (update) {
      setRules(prev => ArrayHelper.update(prev, 'slug', newRule));
      setUpdate(false);
    } else {
      setRules(prev => [...prev, newRule]);
    }
    // Close modal and reset
    setModal(false);
    setDetails(initialState);
  };

  const saveRules = () => {
    setLoading(true);
    const data = {
      website_slug: website.slug,
      rules: rules.map(r => ({
        origin_host:  getOriginHostValue(r),
        from: r.from,
        to: r.to,
        type: r.type,
        order: r.order,
        slug: r.slug,
        code: r.code.toString(),
      })),
    };
    dispatch(updateWebsiteRedirectRules(data))
      .then(() =>
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'Redirects', plural: true })),
      )
      .finally(() => setLoading(false));
  };

  const importRulesToTable = result => {
    let newRules = [];
    result.forEach((rule, index) => {
      // Skip headers
      if (index === 0 && rule[0] === 'order') {
        window.logHelper.warning(`Rule #${index + 1} is a header.`);
        return;
      }
      // Remove empty rules.
      if (!rule[0]) {
        window.logHelper.warning(`Rule #${index + 1} order is empty.`);
        return;
      }
      // If rule has [1] and is empty, set to default origin host.
      if (rule.length > 1 && !rule[1]) {
        rule[1] = ANY_DOMAIN_VALUE;
        window.logHelper.warning(`Rule #${index + 1} origin host found empty and set to default.`);
      }
      let newRule = {};
      let addRule = false;
      for (const i in rule) {
        // Try guess columns
        if (rule[i].includes('/')) {
          // If the string includes '/' then it is probably
          // a from/to input and a precedence is given to from
          if (!newRule.from) {
            newRule.from = rule[i];
          } else {
            newRule.to = rule[i];
          }
        } else if ([301, 302, 403, 404, 444].includes(parseInt(rule[i]))) {
          // newRule status codes
          newRule.code = rule[i];
        } else if (i === 0 && !isNaN(rule[i])) {
          newRule.order = parseInt(rule[i]);
        } else if (['strcmp', 'Literal string'].includes(rule[i])) {
          newRule.type = 'strcmp';
        } else if (['regex', 'Regular expression'].includes(rule[i])) {
          newRule.type = 'regex';
        } else if (rule[i] === 'any' || /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(rule[i])) {
          // If the string is a valid domain, then it is probably an origin host
          newRule.origin_host = rule[i];
        }
        // Skip if from couldn't be found in rule
        if (!newRule.from) {
          continue;
        }
        // Set defaults
        addRule = true;
        newRule.origin_host = newRule.origin_host || getOriginHostValue(newRule);
        newRule.order = newRule.order || index + 1;
        newRule.code = newRule.code || 301;
        newRule.to = newRule.to || '';
        newRule.type = newRule.type || 'strcmp';
        newRule.slug = StringHelper.randomString();
      }
      if (addRule) {
        newRules.push(newRule);
      }
    });
    const mergedRules = fixRulesOrder(rules.concat(newRules));
    setRules(mergedRules);
  };

  const handleFileUpload = e => {
    const { files } = e.target;
    setImportLoading(true);
    // Make sure a file is selected.
    if (files.length > 0) {
      const selectedFile = files[0];
      // Reset file input to allow re-import.
      fileUpload.current.value = '';
      // Check file extension.
      if (StringHelper.getFileExtension(selectedFile.name) === 'csv') {
        Papa.parse(selectedFile, { complete: results => importRulesToTable(results.data) });
      }
    }
    setImportLoading(false);
  };

  const onAddRuleButtonClick = () => {
    const lastRule = ArrayHelper.last(rules);
    setNextOrder(lastRule ? parseInt(lastRule.order) + 1 : 1);
    setModal(true);
  };

  const onClearAllButtonClick = () => setRules([])
  
  const handleExportFile = () => {
    if (isEmptyOrNull(rules)) {
      dispatch(setGlobalWarningMsg('You cannot export an empty table.'));
      return;
    }
    const currentRules = rules.map(el => ({
      order: el.order,
      origin_host: getOriginHostValue(el),
      from: el.from,
      to: el.to,
      code: el.code,
      type: el.type,
    }));
    exportCsvToFile(currentRules, `${website.slug}-redirects`)
  };

  const handleTemplateDownload = () => {
    let tempRules = [
      {
        order: 1,
        from: '/from-here',
        to: '/to-here-always',
        code: 301,
        type: 'strcmp',
      },
      {
        order: 2,
        from: '/from-this-page',
        to: '/to-temp-page',
        code: 302,
        type: 'strcmp',
      },
      {
        order: 3,
        from: '/not-found',
        to: '',
        code: 404,
        type: 'strcmp',
      },
      {
        order: 4,
        from: '/author/(.*)',
        to: '',
        code: 444,
        type: 'regex',
      },
      {
        order: 5,
        from: '^/([0-9]+)$',
        to: '/page/$1',
        code: 302,
        type: 'regex',
      },
    ];
    exportCsvToFile(tempRules, `${env.getBrandShortName()}-redirects-template`);
  };

  const actions = [
    {
      value: 'Edit',
      onClick: item => {
        item.origin_host = getOriginHostValue(item);
        setUpdate(true);
        setNextOrder(item.order);
        setDetails(item);
        setModal(true);
      },
    },
    {
      value: 'Remove',
      onClick: item => {
        const allRules = ArrayHelper.clone(rules);
        let filteredRules = ArrayHelper.filterOut(allRules, 'slug', item.slug);
        if (!isEmpty(filteredRules)) {
          let tempRules = sortByNumericField(filteredRules, 'order');
          let order = 1;
          for (const i in tempRules) {
            tempRules[i] = { ...tempRules[i], order: order++ };
          }
          setRules(tempRules);
        } else {
          setRules([]);
        }
      },
    },
  ];

  const headers = [
    JsxHelper.createTableTextHeader('order', 'Order', '10%'),
    JsxHelper.createTableTextHeaderWithCallback('origin_host', 'Origin Host', '19%', getOriginHostLabel),
    {
      name: 'From',
      selector: 'from',
      width: '22%',
      sortable: true,
      searchable: true,
      cell: row => {
        if (!row.from) {
          return '';
        }
        if (row.type === 'regex') {
          return row.from;
        }
        let warning = null;
        if (row.from.includes('?')) {
          const fromNoQuery = row.from.split('?')[0];
          const maskingRule = rules.find(r => fromNoQuery === r.from && r.order < row.order);
          if (maskingRule) {
            warning = `The rule will be masked by rule #${maskingRule.order}.`;
          }
        }
        return (
          <Fragment>
            <a
              href={`https://${getOriginHostDomain(row)}${row.from}`}
              target='_blank'
              rel='noopener noreferrer'
              className='class-link'>
              {row.from}
            </a>
            {warning && JsxHelper.createBubble({
              icon: 'warning',
              color: 'warning',
              tooltip: warning,
              customClass: 'margin-left-6'
            })}
          </Fragment>
        );
      },
      style: { marginRight: '5px', },
    },
    JsxHelper.createTableTextHeaderWithCallback('to', 'To', '22%', row => [301, 302].includes(parseInt(row.code)) ? row.to : '', null, { marginRight: '7px' }),
    JsxHelper.createTableRequestStatusHeader('code', '8%'),
    JsxHelper.createTableTextHeaderWithCallback('type', 'Type', '10%', row => types.find(t => t.value === row.type).label),
    JsxHelper.createTableActionsHeader(actions, '8%'), // Leaving 1% to compensate the margin right.
  ];

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Redirects</TitleBar.Title>
        <TitleBar.Actions>
          <input
            ref={fileUpload}
            id='import-csv'
            type='file'
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            accept='.csv'
          />
          {JsxHelper.createButton({
            label: 'Import',
            onClick: () => fileUpload.current.click(),
            loading: importLoading,
          })}
          {JsxHelper.createButton({
            label: 'Export',
            onClick: handleExportFile,
          })}
          {!isEmptyOrNull(rules) && JsxHelper.createButton({
            label: 'Clear',
            onClick: onClearAllButtonClick,
          })}
          {JsxHelper.createButton({
            label: 'Add',
            onClick: onAddRuleButtonClick,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        Efficiently manage and implement bulk 301, 302, 403, 404, and 444 redirects directly on Nginx, eliminating the need for WordPress plugins and enhancing performance and security.
        Download the{' '}
        <span 
          onClick={handleTemplateDownload}
          style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}>
          CSV template
        </span>
        {' '}for rules import.
      </p>
      <Content>
        <WPSDataTable columns={headers} body={rules} />
        {JsxHelper.createButton({
          label: 'Save',
          onClick: saveRules,
          loading,
        })}
      </Content>
      {modal && DialogHelper.inputs({
        title: update ? 'Edit Redirect Rule' : 'Add New Redirect Rule',
        onClose: () => {
          setModal(false);
          setDetails(initialState);
        },
        register,
        confirmBtn: update ? 'Edit' : 'Add',
        onConfirm: handleSubmit(onModalSubmit),
        inputs: [{
          label: 'Origin Host',
          required: true,
          type: 'select',
          name: 'origin_host',
          value: details.origin_host,
          options: ArrayHelper.buildSelectOptions(originHosts, 'label', 'value'),
          onChange: onModalInputChange,
          errors,
        }, {
          label: 'Type',
          type: 'select',
          name: 'type',
          value: details.type,
          options: ArrayHelper.buildSelectOptions(types, 'label', 'value'),
          onChange: onModalInputChange,
          errors,
        }, {
          label: 'From',
          type: 'text',
          name: 'from',
          value: details.from,
          onChange: onModalInputChange,
          required: true,
          errors,
        }, [301, 302].includes(parseInt(details.code)) ? {
          label: 'To',
          type: 'text',
          name: 'to',
          value: details.to,
          onChange: onModalInputChange,
          required: true,
          errors,
        } : false, {
          label : 'HTTP Status Code',
          name: 'code',
          type: 'select',
          value: details.code,
          options: ArrayHelper.buildSelectOptions(globalHelper.httpStatusCode, 'name', 'value'),
          onChange: onModalInputChange,
          errors,
        }, {
          label: 'Order',
          name: 'order',
          type: 'number',
          step: '1',
          min: '1',
          value: nextOrder,
          onChange: onModalInputChange,
          required: true,
          errors,
          ref: register({
            required: FormHelper.messages.required,
            validate: value => {
              let _orders = rules.map(r => parseInt(r.order));
              if (update) {
                _orders = _orders.filter(v => v !== parseInt(value));
              }
              let isTaken = _orders.includes(parseInt(value));
              if (isTaken) {
                return 'Inserted order is already taken by another rule.';
              }
            },
          }),
        }
      ].filter(Boolean)})}
    </Container>
  );
};

export default Redirects;
