import {
  CUSTOMER_SET_ALL,
  CUSTOMER_CREATE,
  CUSTOMER_UPDATE,
  CUSTOMER_DELETE,
  CUSTOMER_PAYMENT_METHOD_SETDEFAULT,
  CUSTOMER_PAYMENT_METHOD_CREATE,
  CUSTOMER_PAYMENT_METHOD_UPDATE,
  CUSTOMER_PAYMENT_METHOD_DELETE
} from './customerActions';
import { cloneDeep } from "lodash";

const customerReducer = (state = [], action) => {
  let customer, customers = [];
  switch (action.type) {
    case CUSTOMER_SET_ALL:
      return [...action.payload];

    case CUSTOMER_CREATE:
      return [...state, action.payload];

    case CUSTOMER_UPDATE:
      let filteredCustomers = state.filter(customer => customer.slug !== action.payload.slug);
      return [action.payload,...filteredCustomers];

    case CUSTOMER_DELETE:
      let newState = state.filter(customer => customer.slug !== action.payload.slug);
      return newState;

    case CUSTOMER_PAYMENT_METHOD_SETDEFAULT:
      customers = cloneDeep(state);
      customer = customers.find(customer => customer.slug === action.payload.slug);
      customer.payment_methods = action.payload.data;
      return customers;

    case CUSTOMER_PAYMENT_METHOD_CREATE:
      customers = cloneDeep(state);
      customer = customers.find(customer => customer.slug === action.payload.slug);
      customer.payment_methods =  action.payload.data
      return customers;

    case CUSTOMER_PAYMENT_METHOD_UPDATE:
      customers = cloneDeep(state);
      customer = customers.find(customer => customer.slug === action.payload.slug);
      customer.payment_methods =  action.payload.data
      return customers;

    case CUSTOMER_PAYMENT_METHOD_DELETE:
      customers = cloneDeep(state);
      customer = customers.find(customer => customer.slug === action.payload.slug);
      customer.payment_methods = action.payload.data;
      return customers;

    default:
      return state;
  }
};

export default customerReducer;
