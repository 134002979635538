import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WPSForm } from 'styles/layout/forms';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import { globalCustomDeploymentConfig } from 'store/setting/settingSelectors';
import { updateCustomDeploymentConfig } from 'store/setting/settingActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';

const CustomDeployment = () => {
  useTitle('Custom Website Deployment');
  const dispatch = useDispatch();
  const globalConfig = useSelector(globalCustomDeploymentConfig);
  const [details, setDetails] = useState({
    post_deploy_script: globalConfig.post_deploy_script || '',
  });
  const [loading, setLoading] = useState(false);
  const breadcrumbs = JsxHelper.createBreadcrumbs('Custom Deployment', 'settings');

  const submitGlobalSettings = () => {
    const data = {
      config: {...details}
    };
    setLoading(true);
    dispatch(updateCustomDeploymentConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'custom deployment settings', action: 'updated', plural: true })))
      .finally(() => setLoading(false));
  }

  return (
    <div className='global-settings'>
      <TitleBar className='titlebar'>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Custom Deployment</TitleBar.Title>
        <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
      {env.getBrandShortName()} offers customizable deployment solutions for your websites, enabling you to install plugins, configure themes, adjust settings, and much more. Explore the possibilities with custom deployment and streamline your site setup.
      {' '}{JsxHelper.createFaqLink('custom-deployment', 'Learn more about custom deployment')}.
      </p>
      <Content>
        <div style={{ maxWidth: '500px', marginTop: '20px' }}> {
          <WPSForm.RowItem margin='0px'>
            {JsxHelper.createTextareaInput({
              name: 'post_deploy_script',
              label: 'Post Deploy Script',
              labelClass: 'display-inline-block',
              class: 'secondary-box-select',
              tooltip: 'This script will run after the deployment process is complete. Use this to run WP CLI commands of your choice.',
              placeholder: ['wp plugin install hello-dolly;', 'wp plugin activate hello-dolly;'].join('\n'),
              value: details.post_deploy_script,
              onChange: (e) => setDetails({ ...details, post_deploy_script: e.target.value }),
            })}
          </WPSForm.RowItem>
        }</div>
        <div className='action-buttons display-flex-nowrap margin-bottom-30' style={{display: 'flex'}}>
          {JsxHelper.createButton({
            label: 'Save',
            loading,
            onClick: submitGlobalSettings,
          })}
        </div>
      </Content>
    </div>
  );
};

export default CustomDeployment;
