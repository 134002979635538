/*
Global Modal component
TODO: add a fixed position to document.body when open
To use: import {Modal} from this file and add it as JSX component with all the needed components inside
Add a Boolean value to the parent component to show/hide the modal
*/

import React, { Fragment, useRef, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import JsxHelper from 'helpers/jsx';

import { Container } from '../../styles/layout/modal';
import { WPSButton } from '../../styles/layout/buttons';
import { RingLoader } from '../../styles/layout/loaders';

const Context = React.createContext();

export const ModalProvider = ({ children }) => {
  const modalRef = useRef();
  const [context, setContext] = useState();

  // make sure re-render is triggered after initial
  // render so that modalRef exists
  useEffect(() => {
    setContext(modalRef.current);

  }, []);

  return (
    <Fragment>
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </Fragment>
  );
};

const Modal = ({
  onConfirm,
  onClose,
  title,
  subtitle,
  loading,
  children,
  noFooter,
  closeBtn,
  confirmBtn,
  disabled,
  disableButtons,
  buttons,
  confirmColor,
  dismissable,
  subject,
  icon,
  iconColor,
  open = false,
  ...props
}) => {
  const modalNode = useContext(Context);

  return modalNode
    ? ReactDOM.createPortal(
      <Container>
        <Container.ModalBox {...props}>
          {icon && (
            JsxHelper.createIcon({
              icon: icon,
              color: iconColor,
              style: { verticalAlign: 'middle', display: 'inline-block' }
            })
          )}
          {title && (
            <Container.ModalTitle className='modal-title' align={noFooter && 'center'}>{title}</Container.ModalTitle>
          )}
          {subtitle && (
            <Container.ModalSubtitle className='modal-subtitle' align={noFooter && 'center'}>{subtitle}</Container.ModalSubtitle>
          )}
          {subject && (
            <Container.ModalSubject className='modal-subject' align={noFooter && 'center'}>{subject}</Container.ModalSubject>
          )}
          <Container.ModalBody className='custom-scroll modal-custom-style'>{children}</Container.ModalBody>
          {!noFooter && (
            <Container.ModalFooter>
              {!!onClose && !dismissable && (
                <WPSButton
                  minWidth='80'
                  className='cancel--btn'
                  onClick={onClose}
                  disabled={loading || disableButtons}>
                  {closeBtn ? closeBtn : 'Cancel'}
                </WPSButton>
              )}
              {!!onConfirm && (
                <WPSButton
                  minWidth='80'
                  className={confirmColor ? confirmColor : 'save--btn'}
                  onClick={onConfirm}
                  disabled={loading || disabled || disableButtons}>
                  {loading ? <RingLoader color='light' /> : confirmBtn ? confirmBtn : 'Save'}
                </WPSButton>
              )}
              {buttons && buttons()}
            </Container.ModalFooter>
          )}
        </Container.ModalBox>
      </Container>,
      modalNode,
    )
    : null;
};

export default Modal;
