// Object helpers

import { diff } from 'json-diff';

const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
};

const hasKey = (obj, key) => {
    return (key in obj)
};

const first = (obj) => {
  return obj[Object.keys(obj)[0]];
};

const groupBy = function (xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const size = (obj) => Object.keys(obj).length;

const diffs = function (obj1, obj2) {
  const _diffs = diff(obj1, obj2);
  return _diffs || {};
}

const ObjectHelper = { clone, hasKey, groupBy, diffs, size, first };

export default ObjectHelper;
