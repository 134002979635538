import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import LegalService from 'services/legal';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { isEmpty } from 'helpers';
import { Col, Row } from 'styles/layout/grid';
import { RaisedCard, CardHeader, CardTitle, CardFooter } from 'styles/layout/cards';
import TinyMCE from 'components/Editor/TinyMCE';
import { WPSButton } from 'styles/layout/buttons';
import { RingLoader } from 'styles/layout/loaders';
import EditLegalTemplate from './edit';
import Tooltip from 'components/layout/tooltip';
import useTitle from 'hooks/useTitle';
import { useHistory } from 'react-router-dom';

const Legal = () => {
  useTitle('Terms & Conditions');
  const dispatch = useDispatch();
  const history = useHistory();
  const [legal, setLegal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      LegalService.fetchTermsAndConditions()
        .then(res => setLegal(res))
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => setLoading(false));
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleClose = useCallback(() => {
    setSelected(null);
  }, []);

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Terms & Conditions',
    },
  ];

  const handleBackBtnClick = e => {
    history.goBack();
  };

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Terms & Conditions
            {loading && <RingLoader color='primary' size='24' margin='0 0 0 16px' />}
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='back--btn' onClick={handleBackBtnClick}>
            Back
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <Row gutter={12}>
          {!loading &&
            !isEmpty(legal) &&
            legal.map((page, index) => (
              <Col cols={4} key={`legal-${index}`} margin='0 0 24px 0'>
                <RaisedCard>
                  <CardHeader color='primary'>
                    {page.title}
                    {!!page.tooltip && <Tooltip text={page.tooltip} />}
                  </CardHeader>
                  <CardTitle margin='36px 0 6px 0'>Body</CardTitle>
                  <TinyMCE value={page.body} hasToolbar={false} readonly={true} />
                  <CardFooter>
                    <WPSButton className='edit--btn' onClick={() => setSelected(page)}>Edit</WPSButton>
                  </CardFooter>
                </RaisedCard>
              </Col>
            ))}
        </Row>
      </Content>
      <EditLegalTemplate
        show={!!selected}
        value={selected}
        onClose={handleClose}
        setLegal={setLegal}
      />
    </Fragment>
  );
};

export default Legal;
