import React, { Fragment, useState, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSToggle } from 'styles/layout/forms';
import { websitesSelector } from 'store/website/websiteSelectors';
import ReportService from 'services/report';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import useOnClickOutside from 'utils/useOnClickOutside';
import {
  isEmpty,
  isEmptyOrNull,
  handleFilterURLParamsChanges,
  convertToFilterObject,
} from 'helpers';
import { AnalyticsCard } from 'styles/website/profile';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import useQuery from 'hooks/useQuery';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { timezoneSelector } from 'store/me/meSelectors';
import JsxHelper from 'helpers/jsx';
import WebsiteHelper from 'helpers/website';
import TableHelper from 'helpers/table';

const WebsiteIssues = () => {
  useTitle('Website issues');
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const [initLoaded, setInitLoaded] = useState(false);
  const [reports, setReports] = useState([]);
  const [uniqueReports, setUniqueReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtersToggle, setFiltersToggle] = useState(false);
  const [filters, setFilters] = useState({});
  const timezone = useSelector(timezoneSelector);
  const websites = useSelector(websitesSelector);
  const [statusCards, setStatusCards] = useState({
    issues: '',
    unique: '',
    warnings: 0,
    errors: 0,
  });
  const [uniqueOnly, setUniqueOnly] = useState(false);

  const filtersRef = useRef();
  useOnClickOutside(filtersRef, () => setFiltersToggle(false));

  // Initializes reports from response
  const initReports = res => {
    if (isEmptyOrNull(res)) {
      return;
    }
    setReports(res.filter(r => websites.find(w => w.slug === r.website_slug)));
  };

  // Status cards data.
  const updateStatsCards = () => {
    let issuesByApp = {};
    let uniqueItems = [];
    let warnings = 0;
    let errors = 0;
    reports.forEach(i => {
      // Count type
      if (i.type === 'warning') {
        warnings++;
      } else {
        errors++;
      }
      // Unique
      if (!issuesByApp[i.website_slug]) {
        issuesByApp[i.website_slug] = [i.issue];
        uniqueItems.push(i);
      } else if (!issuesByApp[i.website_slug].includes(i.issue)) {
        issuesByApp[i.website_slug].push(i.issue);
        uniqueItems.push(i);
      }
    });
    setStatusCards({
      issues: reports.length,
      unique: uniqueItems.length,
      warnings,
      errors,
    });
    setUniqueReports(uniqueItems);
  };

  useEffect(() => {
    // Load issues when page opens
    if (!initLoaded) {
      fetchNginxErrors();
      setInitLoaded(true);
    }
    // Set filters for issues
    if (!isEmpty(reports)) {
      const level = reports.map(r => r.type);
      setFilters(prev => ({
        ...prev,
        ...convertToFilterObject(query, 'Severity', new Set(level)),
      }));
      updateStatsCards();
    }
    // eslint-disable-next-line
  }, [reports]);

  // Handle filters add/remove from URL.
  useEffect(() => {
    handleFilterURLParamsChanges(filters, history);
    // eslint-disable-next-line
  }, [filters]);

  const fetchNginxErrors = () => {
    setLoading(true);
    setReports([]);
    setUniqueReports([]);
    const data = {
      flat_array: 1,
    };
    ReportService.listAppsNginxErrors(data)
      .then(res => initReports(res))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  // Update state when changing a filter checked state.
  const onClickFilter = e => {
    // id: filter.value, name: filter.name
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  const headers = [
    {
      name: '',
      selector: 'type',
      sortable: true,
      searchable: true,
      width: '5%',
      cell: row => JsxHelper.createIcon({
        tooltip: row.type,
        color: row.type === 'warning' ? 'warning' : 'danger',
        icon: row.type === 'warning' ? 'warning' : 'danger',
      }),
    },
    {
      name: 'Time',
      selector: 'datetime',
      sortable: true,
      searchable: true,
      width: '10%',
      cell: (row) => JsxHelper.createTableDateTimeCell(row.datetime, timezone),
    },
    {
      name: 'Website',
      selector: 'website_slug',
      sortable: true,
      searchable: true,
      width: '22%',
      cell: (row) => {
        const website = websites.find(w => w.slug === row.website_slug);
        if (website) {
          TableHelper.customizeCellValue(row, 'website_slug', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
        }
        return website && JsxHelper.createTableWebsiteCell({
          website,
        });
      },
    },
    JsxHelper.createTableHttpRequestHeader('request', 8, '25%'),
    JsxHelper.createTableLongTextHeader('message', 'Message', '38%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Website Issues',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Website Issues</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createFilterIcon({
            setShowFilters: setFiltersToggle,
            toggle: filtersRef,
            showFilters: filtersToggle,
            filters: filters,
            onClickFilter: onClickFilter,
            onClick: () => setFiltersToggle(true),
          })}
          {JsxHelper.createButton({
            label: 'Load items',
            onClick: fetchNginxErrors,
            loading: loading,
          })}
          {JsxHelper.createButton({
            label: 'Back',
            onClick: history.goBack,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <AnalyticsCard>
          <li>
            <h4>{JsxHelper.createIcon({
              icon: 'incidents',
            })} Issues</h4>
            <span>{statusCards.issues || 0}</span>
          </li>
          <li>
            <h4>{JsxHelper.createIcon({
              icon: 'error',
              color: 'danger',
            })} Errors</h4>
            {JsxHelper.createText({
              text: statusCards.errors,
            })}
          </li>
          <li>
            <h4>{JsxHelper.createIcon({
              icon: 'warning',
              color: 'warning',
            })} Warnings</h4>
            {JsxHelper.createText({
              text: statusCards.warnings,
            })}
          </li>
          <li>
            <WPSToggle type='checkbox' onChange={() => setUniqueOnly(!uniqueOnly)} />
            <h4>{JsxHelper.createIcon({
              icon: 'unique',
            })} Unique</h4>
            <span>{statusCards.unique || 0}</span>
          </li>
        </AnalyticsCard>
        <WPSDataTable
          loading={loading}
          columns={headers}
          body={uniqueOnly ? uniqueReports : reports}
          dataKey='id'
        />
      </Content>
    </Fragment>
  );
};

export default WebsiteIssues;
