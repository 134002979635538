import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { changelogSelector } from 'store/notification/notificationSelectors';
import JsxHelper from 'helpers/jsx';

const ChangelogNotifications = () => {
  const changelog = useSelector(changelogSelector);
  const changelogData = changelog ? changelog.slice(0, 5) : [];

  return(
    <Fragment>
      <table className='notifications-table' id='changelog-table'>
        <colgroup>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <tbody>
          {changelogData.map((item, index) => {
            item.lines = item.message.split('\n').filter(line => !!line.trim());
            const hasNewFeatures = item.lines.some(line => line.includes('Added:') || line.includes('New:'));
            const iconData = {
              icon: hasNewFeatures ? 'newRelease' : 'bugFix',
              color: hasNewFeatures ? 'primary' : 'warning',
              tooltip: hasNewFeatures ? 'New features are available as part of this release.' : 'Only bug fixes are included in this release.',
            };
            return (
              <tr key={index} className='notification-row'>
                <td className='notification-content'>
                  <div className='notification-label'>{item.title}</div>
                  <div className='notification-text'>{item.lines.map((line, index) =>
                    <span className='notification-line' key={index}>{line}</span>
                  )}</div>
                </td>
                <td className='notification-icon'>
                  {JsxHelper.createIcon(iconData)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
}

export default ChangelogNotifications;