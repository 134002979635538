import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { fetchUserActivites } from 'services/user';
import { useLocation } from 'react-router-dom';
import UserHelper from 'helpers/user';
import { WPSButton } from 'styles/layout/buttons';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { useDispatch, useSelector } from 'react-redux';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import WPSTableloader from 'components/wpstaq/WPSDataTable/WPSTableloader';
import DataTable from 'react-data-table-component';
import JsxHelper from 'helpers/jsx';
import { timezoneSelector } from 'store/me/meSelectors';
import DialogHelper from 'helpers/dialog';

const viewerDefaults = { title: '', isOpen: false, data: {} };
const Activity = () => {
  useTitle('User Activity');

  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const [viewer, setViewer] = useState({ ...viewerDefaults });
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const timezone = useSelector(timezoneSelector);
  const [data, setData] = useState({
    user_slug: state.slug,
    page: 1,
    limit: 10,
  });

  const fetchActivities = async () => {
    setLoading(true);
    try {
      const res = await fetchUserActivites({
        user_slug: state.slug,
        page: data.page,
        limit: data.limit,
      });
      setActivities([...activities, ...res]);
      setData(prev => ({ ...prev, page: prev.page + 1 }));
    } catch (err) {
      dispatch(setGlobalErrorMsg(err));
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line
  }, []);

  const handleCellClick = (_title, value) => setViewer({
    title: _title,
    isOpen: true,
    data: value || {},
  });

  const handleViewerClose = useCallback(() => {
    setViewer({ ...viewerDefaults });
  }, []);

  const headers = UserHelper.getActivityTableHeaders(handleCellClick, timezone, true);

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Users',
      link: '/users',
    },
    {
      text: state.slug,
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Activity History</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='action-read' onClick={fetchActivities} loading={loading}>
            Load more
          </WPSButton>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {loading ? (
          <DataTable
            columns={headers}
            subHeader
            persistTableHead
            progressPending={true}
            progressComponent={<WPSTableloader />}
          />
        ) : (
          <WPSDataTable columns={headers} body={activities} dataKey="guid" />
        )}
      </Content>
      {viewer.isOpen && DialogHelper.jsonViewer({
        onClose: handleViewerClose,
        data: viewer.data,
      })}
    </Fragment>
  );
};

export default Activity;
