import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import useQuery from 'hooks/useQuery';
import ArrayHelper from '../../../helpers/array';
import TableHelper from '../../../helpers/table';
import { viewPreferencesSelector } from 'store/global/globalSelectors';
import { updateMyViewPreferences } from 'store/global/globalActions';
import { useDeepCompareEffect } from 'utils/useDeepCompare';
import { ActiveFilters } from 'styles/layout/tables';
import { isEmpty, isNotEmpty, isString, isNullOrUndefined } from '../../../helpers';
import WPSTableloader from './WPSTableloader';
import './WPSDataTable.css';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
const TextField = styled.input`
  min-height: 38px;
  width: 200px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0 32px 0 16px;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
`;

const FilterComponent = ({ inputSearchText, onFilter }) => (
  <Fragment>
    <TextField
      id='search'
      type='text'
      maxLength='100'
      placeholder='Search table...'
      value={inputSearchText}
      onChange={onFilter}
    />
  </Fragment>
);

const WPSDataTable = ({
  name,
  body,
  columns,
  loading,
  dataKey,
  noSearchOnTable,
  rowsPerPage,
  hidePagination,
  customClass,
  preserveViewPreferences,
}) => {
  const [inputSearchText, setInputSearchText] = useState('');
  const [bodyData, setBodyData] = useState(null);
  const [parentBodyData, setParentBodyData] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const viewPreferences = useSelector(viewPreferencesSelector);
  const dispatch = useDispatch();

  const filters = useRef([]);
  const filtersColumns = useRef([]);

  const queryFilter = useQuery().getAll('filter');
  const queryFilterTrigger = useRef(1);
  const history = useHistory();

  const isUpdated = useRef(false);
  useEffect(() => {
    if (!isNotEmpty(body)) {
      return;
    }
    if (!isEmpty(parentBodyData) && deepCompareEquals(parentBodyData, body)) {
      return;
    }
    setBodyData([...body]);
    setParentBodyData([...body]);
    for (const i in body) {
      for (const j in columns) {
        if (columns[j].hasOwnProperty('cell')) {
          columns[j].cell(body[i]);
        }
      }
    }
    // trigger search to save the current state when redux data update
    if (!noSearchOnTable && !isEmpty(inputSearchText) && isEmpty(queryFilter)) {
      const searchData = {
        target: {
          value: inputSearchText,
        },
      };
      handleSearch(searchData);
    }
    // trigger filter handler to save the current state when redux data update
    if (!isEmpty(queryFilter) && isEmpty(inputSearchText)) {
      queryFilterTrigger.current++;
    }
    // eslint-disable-next-line
  }, [body]);

  useEffect(() => {
    if (tableLoading && bodyData) {
      setTableLoading(false);
    }
    // eslint-disable-next-line
  }, [bodyData]);

  useDeepCompareEffect(() => {
    if (bodyData) {
      filterQueryData();
    }
    // eslint-disable-next-line
  }, [queryFilter, history.location.search, queryFilterTrigger.current]);

  const deepCompareEquals = (a, b) => {
    let modA = a;
    let modB = b;
    if (modA && (typeof modA === 'object' || Array.isArray(modA))) {
      modA = JSON.stringify(a);
    }
    if (modB && (typeof modB === 'object' || Array.isArray(modB))) {
      modB = JSON.stringify(b);
    }
    return modA === modB;
  };

  const getKeyFromColumns = name => {
    // Find column by name or selector.
    const column = columns.find(column => {
      if (column.name && column.name.toLowerCase() === name.toLowerCase()) {
        return true;
      } else if (column.selector && column.selector.toLowerCase() === name.toLowerCase()) {
        return true;
      } else if (!column.name && !column.selector) {
        // window.logHelper.error('Column name or selector not found in the table.', column);
      }
      return false;
    });
    if (column) {
      return column.selector;
    }
    window.logHelper.error(`Column ${name} not found in the table.`);
    return null;
  };

  // get all possible combinations
  const cartesian = (...args) => {
    let r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (let j = 0, l = args[i].length; j < l; j++) {
        let a = arr.slice(0);
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  };

  const filterQueryData = () => {
    isUpdated.current = true;
    let _filters = [];
    let _filtersColumns = [];
    let result = [];
    if (queryFilter.length > 0) {
      setInputSearchText('');
      for (let q in queryFilter) {
        const filter = queryFilter[q].split(':');
        if (!_filtersColumns.includes(filter[0])) {
          _filtersColumns.push(filter[0]);
        }
      }
      filtersColumns.current = [..._filtersColumns];

      for (let f in queryFilter) {
        const filter = queryFilter[f].split(':');
        if (!_filters.includes(filter[1])) {
          _filters.push(filter[1]);
        }
      }
      filters.current = [..._filters];

      let combinedFilters = [];
      let arr = [];

      for (let c = 0; c < queryFilter.length; c++) {
        if (c === 0) {
          arr = queryFilter.filter(item => item.split(':')[0] === queryFilter[c].split(':')[0]);
          combinedFilters.push(arr);
          arr = [];
        } else {
          let currentFilter = queryFilter[c].split(':')[0];
          let previousFilter = queryFilter[c - 1].split(':')[0];
          if (currentFilter !== previousFilter) {
            arr = queryFilter.filter(item => item.split(':')[0] === currentFilter);
            combinedFilters.push(arr);
            arr = [];
          }
        }
      }
      let finalCombinedFilters = cartesian(...combinedFilters);

      let finalData = [...body];
      let _key = 'slug';
      if (dataKey) {
        _key = dataKey;
      }
      for (let r in finalData) {
        // eslint-disable-next-line
        for (let j in filtersColumns.current) {
          for (let i in finalCombinedFilters) {
            let isItemFound = true;
            // Iterate over all filters and check if item is found in all filters.
            // Note that finalCombinedFilters[i] is an array of filters with the
            // following format: [column1:value1, column2:value2, ...].
            for (let f in finalCombinedFilters[i]) {
              // Get the keys that correspond to the column name that was passed
              // in the filter (convertToFilterObject function's name parameter)
              // including the "custom_" prefix for custom columns.
              const columnName = finalCombinedFilters[i][f].split(':')[0];
              const key = getKeyFromColumns(columnName);
              const customKey = `custom_${key}`;
              let item = { ...finalData[r] };
              let columnKeys = [ key ];
              if (item.hasOwnProperty(customKey)) {
                columnKeys.push(customKey);
              }
              // Iterate over all keys and check if the item is found in any of them.
              let foundInColumn = false;
              for (const columnKey of columnKeys) {
                if (!item[columnKey]) {
                  continue;
                }
                const itemValue = item[columnKey].toString().toLowerCase();
                const filterValue = finalCombinedFilters[i][f].split(':')[1].toString().toLowerCase();
                if (itemValue === filterValue) {
                  foundInColumn = true;
                }
              }
              // If the item is not found in any of the keys, then it is not found in the filter.
              if (!foundInColumn) {
                isItemFound = false;
                break;
              }
            }
            // If the item is found in all filters, then add it to the result.
            if (isItemFound && !ArrayHelper.findObject(result, finalData[r], _key)) {
              result.push(finalData[r]);
            }
          }
        }
      }
      setBodyData([...result]);
    } else {
      if (isEmpty(inputSearchText)) {
        setBodyData([...body]);
      }
      filters.current = [];
      filtersColumns.current = [];
    }
    isUpdated.current = false;
  };

  const handleSearch = e => {
    isUpdated.current = true;
    const searchedText = !isEmpty(e) && !isEmpty(e.target) ? e.target.value : inputSearchText;
    const searchableColumns = [];
    for (let r in columns) {
      if (columns[r].searchable) {
        searchableColumns.push(columns[r].selector);
      }
    }
    if (!isEmpty(searchedText)) {
      if (!isEmpty(queryFilter)) {
        history.replace({
          search: '',
        });
      }
      if (!body) {
        return;
      }
      let results = [];
      let myData = [...body];
      for (let i in myData) {
        for (let j in searchableColumns) {
          const key = getKeyFromColumns(searchableColumns[j]);
          const customKey = `custom_${key}`;
          let item = { ...myData[i] };
          let itemDataToSearchIn = '';
          if (item.hasOwnProperty(customKey)) {
            itemDataToSearchIn = item[customKey] ? item[customKey].toString() : '';
          } else {
            itemDataToSearchIn = item[key] ? item[key].toString() : '';
          }
          if (itemDataToSearchIn.toLowerCase().includes(searchedText.toString().toLowerCase())) {
            results.push(myData[i]);
            break;
          }
        }
      }
      setBodyData([...results]);
    } else {
      setInputSearchText('');
      setBodyData([...body]);
    }
    isUpdated.current = false;
  };
  const delayedHandleChange = debounce(e => handleSearch(e), 500);

  const searchHandler = e => {
    if (!isEmpty(e) && !isEmpty(e.target)) {
      if (!isEmpty(e.target.value)) {
        setInputSearchText(e.target.value);
        let eventData = { id: e.id, target: e.target };
        delayedHandleChange(eventData);
      } else {
        setInputSearchText('');
        setBodyData([...body]);
      }
    }
  };

  const subHeaderComponentMemo = () => {
    return <FilterComponent onFilter={e => searchHandler(e)} inputSearchText={inputSearchText} />;
  };
  
  const convertStringNumbers = (data, key) => {
    for (const i in data) {
      if (isString(data[i][key]) && !isNaN(data[i][key])) {
        data[i][key] = parseFloat(data[i][key]);
      }
    }
    return data;
  }

  const handleSort = (header, type) => {
    // Clone the data array in order to avoid mutating the original array.
    const _bodyData = [...bodyData];
    // If a sortable field is defined, use it. Otherwise, use the custom selector if
    // defined in any of the columns. Otherwise, use the selector.
    let sortableValue = header.sortableValue
      ? header.sortableValue
      : _bodyData.some(item => item.hasOwnProperty(`custom_${header.selector}`)) ? `custom_${header.selector}` : header.selector;
    // Clone the array and convert string numbers to numbers.
    let sortingResult = convertStringNumbers(_bodyData, sortableValue);
    // Set the sort direction.
    const ascending = type === 'asc';
    // Sort the array in ascending order where null/undefined come last.
    sortingResult.sort((_a, _b) => {
      let a = _a[sortableValue];
      let b = _b[sortableValue];
      if (isNullOrUndefined(a)) return 1;
      if (isNullOrUndefined(b)) return -1;
      if (a < b) return ascending ? -1 : 1;
      if (a > b) return ascending ? 1 : -1;
      return 0;
    })
    // Update the state.
    setBodyData([...sortingResult]);
  };

  const onChangeRowsPerPage = (rowsPerPage) => {
    if (preserveViewPreferences) {
      // If name is not defined, do not update the view preferences.
      if (!name) {
        window.logHelper.warning('Table name is not defined. Cannot update view preferences.');
        return;
      }
      // Update the view preferences only if the rows per page is different from the current one
      // and has a preservable value.
      const currentRowsPerPage = getRowsPerPage();
      if (!TableHelper.DEFAULT_ROWS_PER_PAGE_OPTIONS.includes(rowsPerPage) || rowsPerPage === currentRowsPerPage) {
        return;
      }
      // Update the view preferences.
      const data = TableHelper.updateTableViewPreferences(viewPreferences, name, rowsPerPage);
      dispatch(updateMyViewPreferences(data))
        .then(() => window.logHelper.info(`Table ${name} rows per page view preferences updated to ${rowsPerPage}.`))
        .catch(error => window.logHelper.error(`Error updating table ${name} rows per page view preferences.`, error));
    }
  }

  const getRowsPerPage = () => rowsPerPage ? rowsPerPage : TableHelper.getTableViewsPerPage(viewPreferences, name)

  return (
    <Fragment>
      {queryFilter.length > 0 && (
        <ActiveFilters style={{ display: 'inline-flex' }}>
          Active filters:
          {queryFilter.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ActiveFilters>
      )}
      {!tableLoading && !loading ? (
        <DataTable
          className={(!isEmpty(bodyData) ? 'data-table-main-class' : 'data-table-main-class table-rows-empty') + ' ' + (customClass||'')}
          keyField={dataKey ? dataKey : 'slug'}
          overflowY={true}
          overflowYOffset= '0px'
          striped
          columns={columns}
          data={bodyData}
          pagination={hidePagination ? false : true}
          paginationRowsPerPageOptions={TableHelper.DEFAULT_ROWS_PER_PAGE_OPTIONS}
          paginationPerPage={getRowsPerPage()}
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            noRowsPerPage: false,
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          subHeader={noSearchOnTable ? false : true}
          subHeaderComponent={noSearchOnTable ? null : subHeaderComponentMemo()}
          onSort={(data, type) => handleSort(data, type)}
          defaultSortAsc
          persistTableHead
          responsive={true}
        />
      ) : (
        <DataTable
          className='data-table-main-class'
          keyField={dataKey ? dataKey : 'slug'}
          columns={columns}
          subHeader={noSearchOnTable ? false : true}
          subHeaderComponent={noSearchOnTable ? null : subHeaderComponentMemo()}
          persistTableHead
          progressPending={true}
          paginationPerPage={getRowsPerPage()}
          progressComponent={<WPSTableloader />}
        />
      )}
    </Fragment>
  );
};

export default WPSDataTable;
