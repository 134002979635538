import React, { useEffect, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar, GroupTitle } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleWebsiteDebugMode } from 'store/website/websiteActions';
import { useState } from 'react';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
} from 'store/global/globalActions';
import { WPSButton } from 'styles/layout/buttons';
import WebsiteService from 'services/website';
import { InlineLoader } from 'styles/layout/loaders';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import { timezoneSelector } from 'store/me/meSelectors';
import { isEmptyOrNull } from 'helpers';
import StringHelper from 'helpers/string';
import LogHelper from 'helpers/log';
import DialogHelper from 'helpers/dialog';
import useModal from 'hooks/useModal';

const Debug = ({ website }) => {
  useTitle('Website Debug');
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(website.debug_mode);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [logType, setLogType] = useState('nginx-access');
  const [nginxLog, setNginxLog] = useState([]);
  const [nginxError, setNginxError] = useState([]);
  const [phpFpmLog, setPhpFpmLog] = useState([]);
  const [wpDebugLogs, setWpDebugLogs] = useState([]);
  const [cloudFrontLogs, setCloudFrontLogs] = useState([]);
  const [serverLogsLoaded, setServerLogsLoaded] = useState(false);
  const [awsLogsLoaded, setAwsLogsLoaded] = useState(false);
  const modalDialog = useModal();
  const mounted = useRef(true);
  const timezone = useSelector(timezoneSelector);
  const TABLE_CUSTOM_CLASS = 'log-table';
  const TABS_INFO = [
    { title: 'Nginx Access', value: 'nginx-access', desc: 'View logs of HTTP(s) requests to your site generated by Nginx.' },
    { title: 'Nginx Errors', value: 'nginx-error', desc: 'View logs of errors generated by Nginx.'},
    { title: 'PHP-FPM Access', value: 'phpfpm-access', desc: 'View logs of PHP-FPM requests to your site that bypassed Nginx.' },
    { title: 'WordPress', value: 'wp-debug', desc: 'View logs generated by WordPress when debug mode is enabled.', warning: 'Only unique WordPress logs are displayed.' },
    { title: 'Amazon CloudFront', value: 'cloudfront', disabled: isEmptyOrNull(website.cdns), desc: 'View logs of HTTP(s) requests to your site generated by Amazon CloudFront.' },
  ]

  const TABS_INFO_MAP = TABS_INFO.reduce((acc, tab) => {
    acc[tab.value] = tab;
    return acc;
  }, {});

  const fetchAppLogs = (_logType, _force) => {
    setLogType(_logType);

    if (_logType === 'cloudfront') {
      if (!awsLogsLoaded || _force) {
        fetchAwsLogs();
      }
    } else {
      // Fetch server logs if not loaded or force refresh.
      if (!serverLogsLoaded || _force) {
        fetchServerLogs();
      }
      // For WordPress logs, show warning if debug mode is off.

      if (_logType === 'wp-debug' && !website.debug_mode) {
        DialogHelper.warning(modalDialog, '<b>Debug Mode Disabled</b>', `Enable WP_DEBUG using the button above, refresh the error page on your website, and then click the Refresh button to view the logs.`);
      }
    }
  }

  const fetchAwsLogs = () => {
    setLoadingRefresh(true);
    const data = { website_slug: website.slug };
    WebsiteService.fetchAppAwsLogs(data)
      .then(res => {
        setCloudFrontLogs(res.cloudfront_log);
        setAwsLogsLoaded(true);
      })
      .catch(err => {
        dispatch(setGlobalErrorMsg(err));
      })
      .finally(() => {
        setLoadingRefresh(false);
      });
  }

  const fetchServerLogs = () => {
    setLoadingRefresh(true);
    const data = { website_slug: website.slug };
    WebsiteService.fetchAppServerLogs(data)
      .then(res => {
        setNginxError(res.nginx_error);
        setNginxLog(res.nginx_log);
        setWpDebugLogs(res.wp_debug_log);
        setPhpFpmLog(res.phpfpm_log);
        setServerLogsLoaded(true);
      })
      .catch(err => {
        dispatch(setGlobalErrorMsg(err));
      })
      .finally(() => {
        setLoadingRefresh(false);
      });
  };

  // Fetch logs on component mount.
  useEffect(() => {
    if (mounted.current) {
      fetchServerLogs();
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // Update toggle state.
  useEffect(() => {
    if (mounted.current) {
      setToggle(website.debug_mode);
    }
    return () => {
      mounted.current = false;
    };
  }, [website]);

  // Dispatch toggle debug mode.
  const toggleMode = () => {
    setLoadingToggle(true);
    const data = { website_slug: website.slug, mode: toggle ? 'off' : 'on' };
    dispatch(toggleWebsiteDebugMode(data))
      .then(res => {
        setToggle(res.debug_mode);
        dispatch(
          setGlobalSuccessMsg({
            id: website.slug,
            model: 'debug mode',
            action: res.debug_mode ? 'activated' : 'deactivated',
          }),
        );
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingToggle(false));
  };

  // Table header templates.

  const clientHeader = (selector, label) => ({
    name: label || 'Client',
    selector: selector,
    sortable: true,
    searchable: true,
    width: '11%',
    cell: row => JsxHelper.createBubble({
      text: row[selector],
      customClass: 'margin-0',
      background: 'info',
      prettify: false,
      small: true,
    })
  });

  // Table headers.

  const nginxAccessHeaders = [
    JsxHelper.createTableRequestTimeHeader('time', 'YYYY-MM-DD HH:mm:ss', timezone),
    clientHeader('client'),
    JsxHelper.createTableHttpRequestHeader('request', 15),
    JsxHelper.createTableRequestStatusHeader(),
    JsxHelper.createTableTextHeaderWithCallback('sentBytes', 'Sent Data', '13%', row => StringHelper.convertBytes(row.sentBytes)),
  ];

  const nginxErrorHeaders = [
    JsxHelper.createTableRequestTimeHeader('datetime', 'YYYY-MM-DD HH:mm:ss', timezone), // 12%
    clientHeader('client'), // 11%
    JsxHelper.createTableHttpRequestHeader('request', 16), // 30%
    JsxHelper.createTableTextHeaderWithCallback('messages', 'Messages', '45%', row => JsxHelper.createText({
      text: row.messages.join('\n'),
      style: { fontSize: '12px'}
    })),
  ];

  const cloudFrontHeaders = [
    JsxHelper.createTableRequestTimeHeader('DateTime', null, timezone),
    clientHeader('IPAddress'),
    JsxHelper.createTableHttpRequestHeader('Object'),
    JsxHelper.createTableRequestStatusHeader('HttpStatus'),
    JsxHelper.createTableTextHeader('EdgeLocation', 'Edge Location', '13%'),
    JsxHelper.createTableTextHeader('EdgeResult', 'Edge Result', '13%'),
  ];

  const phpfpmLogHeaders = [
    JsxHelper.createTableRequestTimeHeader('started_at', 'YYYY-MM-DD HH:mm:ss', timezone),
    clientHeader('real_ip'),
    JsxHelper.createTableTextHeader('duration', 'Duration (s)', '9%'),
    JsxHelper.createTableHttpRequestHeader('uri'),
    JsxHelper.createTableRequestStatusHeader(),
    JsxHelper.createTableTextHeaderWithCallback('cpu', 'CPU Usage', '10%', row => row.cpu + '%'),
  ];

  const wpHeaders = [
    JsxHelper.createTableRequestTimeHeader('date', 'YYYY-MM-DD HH:mm:ss', timezone),
    JsxHelper.createTableTextHeaderWithCallback('type', 'Severity', '10%', row => JsxHelper.createBubble({
      text: StringHelper.toText(row.type),
      background: LogHelper.getTypeColor(row.type),
      customClass: 'margin-0',
      small: true,
    })),
    JsxHelper.createTableTextHeaderWithCallback('text', 'Message', '72%', row => JsxHelper.createText({
      text: row.text,
      style: { fontSize: '12px'}
    })),
  ];

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>{TABS_INFO_MAP[logType].title}</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton
            onClick={toggleMode}
            className={toggle ? 'success--btn' : 'warning--btn'}
            disabled={loadingRefresh}
            loading={loadingToggle}>
            {toggle ? 'Disable' : 'Enable'} WP_DEBUG
          </WPSButton>
          <WPSButton
            className='primary--btn'
            disabled={loadingToggle}
            onClick={() => fetchAppLogs(logType, true)}
            loading={loadingRefresh}>
            Refresh
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        <span>{TABS_INFO_MAP[logType].desc}</span>
        <br />
        <span>For more info on the logs and how to download them, please refer to our {JsxHelper.createFaqLink('server-logs', 'Knowledge Base')}.</span>
      </p>
      <Content>
        <GroupTitle>
          <div style={{ textTransform: 'none' }}>
            {TABS_INFO_MAP[logType].warning && (
              <div className='warning-box'><strong>WARNING:</strong> {TABS_INFO.find(tab => tab.value === logType).warning}</div>
            )}
          </div>
          <GroupTitle.Filters>
            {TABS_INFO.filter(tab => !tab.disabled).map(tab => (
              <button
                key={tab.value}
                className={logType === tab.value ? 'active' : ''}
                onClick={() => fetchAppLogs(tab.value)}>
                {tab.title}
              </button>
            ))}
          </GroupTitle.Filters>
        </GroupTitle>

        {!loadingRefresh && logType === 'nginx-error' && (
          <WPSDataTable customClass={TABLE_CUSTOM_CLASS} columns={nginxErrorHeaders} body={nginxError} />
        )}
        {!loadingRefresh && logType === 'nginx-access' && (
          <WPSDataTable customClass={TABLE_CUSTOM_CLASS} columns={nginxAccessHeaders} body={nginxLog} />
        )}
        {!loadingRefresh && logType === 'phpfpm-access' && (
          <WPSDataTable customClass={TABLE_CUSTOM_CLASS} columns={phpfpmLogHeaders} body={phpFpmLog} />
        )}
        {!loadingRefresh && logType === 'cloudfront' && (
          <WPSDataTable customClass={TABLE_CUSTOM_CLASS} columns={cloudFrontHeaders} body={cloudFrontLogs} />
        )}
        {!loadingRefresh && logType === 'wp-debug' && (
          <WPSDataTable customClass={TABLE_CUSTOM_CLASS} columns={wpHeaders} body={wpDebugLogs} />
        )}
        {loadingRefresh && (
          <div style={{ textAlign: 'center', marginTop: '24px' }}>
            <InlineLoader color='primary' />
          </div>
        )}
      </Content>
    </Container>
  );
};

export default Debug;
