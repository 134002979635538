import { WIDGET_SET_ALL } from './widgetActions';

const widgetReducer = (state = [], action) => {
  switch (action.type) {
    case WIDGET_SET_ALL:
      return [...action.payload];
    default:
      return state;
  }
};

export default widgetReducer;
