import { client, postRequest, getRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Logs in a user and returns JWT token and API details.
 *
 * @param   {string}  email
 * @param   {string}  password
 * @return  {Array}
 */
const login = ({ email, password }) => {
  return postRequest({
    url: env.API_LOGIN,
    data: { email, password },
  });
};

const loginTwoFactor = ({ two_factor_auth_token, user_slug, two_factor_code }) => {
  return postRequest({
    url: env.API_LOGIN_2FA,
    data: { two_factor_auth_token, user_slug, two_factor_code },
  });
}

/**
 * Returns the user's initial UI data.
 *
 * @return  {Array}
 */
const me = () => {
  // Set default auth token for all future requests
  client.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  const user_data_url = `${env.BASE_API_URL}/${localStorage.getItem('api_prefix')}me/startup`;
  return getRequest({
    url: user_data_url,
  });
};

/**
 * Logs out the user and invalidates token.
 *
 * @return  {Array}
 */
const logout = () => {
  return postRequest({
    url: env.API_LOGOUT,
  });
};

// Get servers/websites updates.
const fetchStatusUpdates = data => {
  return getRequest({
    url: env.buildApiEndpoint('me/recent-updates'),
    data,
    timeout:'10000' // 10 seconds
  });
};

// Update my view preferences
const updateViewPreferences = data => {
  return postRequest({
    url: env.buildApiEndpoint('me/preferences'),
    data,
  });
};

// Update my profile data.
const updateProfile = data => {
  return postRequest({
    url: env.buildApiEndpoint('me/profile'),
    data,
  });
};

// Reset my password.
const resetPassword = data => {
  return postRequest({
    url: env.buildApiEndpoint('me/reset-password'),
    data,
  });
};

const MeService = {
  login,
  loginTwoFactor,
  me,
  logout,
  fetchStatusUpdates,
  updateViewPreferences,
  updateProfile,
  resetPassword,
};

export default MeService;
