import { createSelector } from 'reselect';


// Input
const globalSettings = state => state.global_settings;

// Selectors
export const globalServerSettingsSelector = createSelector(
  globalSettings,
  settings => settings.servers,
);

export const globalWebsiteSettingsSelector = createSelector(
  globalSettings,
  settings => settings.websites,
);

export const globalEmailTemplatesSelector = createSelector(
  globalSettings,
  settings => settings.email_templates || [],
);

export const globalWPDefines = createSelector(
  globalSettings,
  settings => settings.websites ? settings.websites.wp_global_defines || [] : [],
);

export const globalNginxConfig = createSelector(
  globalSettings,
  settings => settings.websites ? settings.websites.global_nginx_config || {} : {},
);

export const globalCustomDeploymentConfig = createSelector(
  globalSettings,
  settings => settings.websites ? settings.websites.custom_deployment_config || {} : {},
);

export const globalAutoUpdaterConfig = createSelector(
  globalSettings,
  settings => settings.packages ? settings.packages.global_auto_updater_config || {} : {},
);

export const globalClientReportConfigSelector = createSelector(
  globalSettings,
  settings => settings.websites ? settings.websites.client_report_config || {} : {},
);

export const globalSmtpMailerConfigSelector = createSelector(
  globalSettings,
  settings => settings.websites ? settings.websites.global_smtp_mailer_config || [] : [],
);

export const emailCustomizations = createSelector(
  globalSettings,
  settings => settings.customizations ? settings.customizations.email_style_options || [] : [],
);

