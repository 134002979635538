import {
  DNS_ZONE_SET_ALL,
  DNS_ZONE_CREATE,
  DNS_ZONE_UPDATE,
  DNS_ZONE_DELETE,
  DNS_ZONE_RECORD_CREATE,
  DNS_ZONE_RECORD_DELETE
} from './dnsZoneActions';
import { isEmptyOrNull } from '../../helpers';
import ArrayHelper from '../../helpers/array';

const dnsZoneReducer = (state = [], action) => {
  switch (action.type) {
    case DNS_ZONE_SET_ALL:
      return [...action.payload];

    case DNS_ZONE_CREATE:
      return [...state, action.payload];

    case DNS_ZONE_UPDATE:
      let updated = action.payload;
      // Add from websites
      if (!isEmptyOrNull(action.payload.dns_zone)) {
        updated = action.payload.dns_zone;
      }
      return ArrayHelper.update(state, 'slug', updated);

    case DNS_ZONE_DELETE:
      let newState = state.filter(zone => zone.slug !== action.payload.slug);
      return newState;

    case DNS_ZONE_RECORD_CREATE:
      let prevState = state.filter(zone => zone.slug !== action.payload.slug);
      return [...prevState, action.payload];

    case DNS_ZONE_RECORD_DELETE:
      const oldZones = state.filter(z => z.slug !== action.payload.dns_zone_slug);
      let newZone = state.find(z => z.slug === action.payload.dns_zone_slug);
      let _records = newZone.records.map(r => {
          if (r.name === action.payload.name && r.type === action.payload.type){
            return null;
          }else{
            return r;
          }
        }).filter(el=> el!== null)
      newZone = {
        ...newZone,
        records: _records
      };

      return [...oldZones, newZone];

    default:
      return state;
  }
};

export default dnsZoneReducer;
