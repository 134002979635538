import styled from 'styled-components';

const getRowMargin = (spacing) => {
  let margin = "0px";
  if(spacing) {
    margin = `0 ${-spacing}px`
  }
  return margin;
}
const getColumnPadding = (spacing) => {
  let padding = null;
  if(spacing) {
    padding = `0 ${spacing}px`
  }
  return padding;
}

const getColumnWidth = (cols, item) => item ? "100%" : `${(100/12) * cols}%`;

const getColumnFlex = (cols, item) => {
  let flex = null;
  if(item) {
    flex = `1 1 ${getColumnWidth(cols, item)}`;
  } else if(cols) {
    flex = `1 1 ${getColumnWidth(cols, item)}`;
  }
  return flex;
}

export const Row = styled.div`
  display: flex;
  margin: ${({ spacing }) => getRowMargin(spacing) };
  flex-wrap: wrap;
  > * {
    margin: ${({ spacing }) => getColumnPadding(spacing) };
  }
  > * {
    padding: ${({ gutter }) => getColumnPadding(gutter) };
  }
`

export const Col = styled.div`
  flex: ${({ cols, item }) => getColumnFlex(cols, item)};
  max-width: ${({ cols, item }) => getColumnWidth(cols, item)};
  margin: ${({ margin }) => margin};
`

export const Spacer = styled.div`
margin: 10px 0;
`