import { createSelector } from 'reselect';
import ArrayHelper from '../../helpers/array';

/*
  Input
*/
const dnsZones = state => state.dns_zones;

/*
  Selectors
*/

// Return all dns zones
export const dnsZonesSelector = createSelector(dnsZones, dnsZones => dnsZones);

// Return dns zone by slug
export const dnsZoneBySlug = slug =>
  createSelector(dnsZones, dnsZones => ArrayHelper.find(dnsZones, 'slug', slug));
