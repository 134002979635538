/*
------------------------- Tabs Component ---------------------------------
To use, import Tabs into a file and inside it include ONLY 2 types of children (Tabs.Tab, Tabs.Panel).
Each Tab and Panel must have the same name prop to connect the panel to its corresponding tab.
You can pass an initialValue prop to the Tabs component that indicates which tab to start on, if
nothing is provided it will start at the 1st tab.

NOTE: The tabs component can only render in 2 ways either map (loop) for tabs and another loop for panels
or just add tabs and panels without loops
*/

import React, { createContext, useState, useContext } from 'react';
import { Container, TabsList, TabsPanel } from '../../styles/wpstaq/tabs';

const TabContext = createContext();

// Tabs component
const Tabs = ({ initialValue, tabs, panels, ...rest }) => {
  const [activeTab, changeTab] = useState(initialValue);

  return (
    <TabContext.Provider value={{ activeTab, changeTab }}>
      <Container {...rest}>
        <TabsList>{tabs()}</TabsList>
        <TabsPanel>{panels()}</TabsPanel>
      </Container>
    </TabContext.Provider>
  );
};

// Tabs single Tab component, returns a list item
export const Tab = ({ name, onClick = () => {}, children, ...rest }) => {
  const { activeTab, changeTab } = useContext(TabContext);

  const classNames = `${activeTab === name ? 'active' : ''}`;

  const handleClick = e => {
    changeTab(name);
    onClick(e);
  };

  return (
    <li className={classNames} onClick={handleClick} {...rest}>
      {children}
    </li>
  );
};

// Tabs Panel component, returns a div with all panel children
export const TabPanel = ({ name, children, ...rest }) => {
  const { activeTab } = useContext(TabContext);

  return activeTab === name && <div {...rest}>{children}</div>;
};

Tabs.Tab = Tab;
Tabs.Panel = TabPanel;

export default Tabs;
