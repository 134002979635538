import React, { useState } from 'react';
import { WPSForm, WPSInput } from '../../styles/layout/forms';
import { WPSButton } from '../../styles/layout/buttons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createNewRegion, updateRegion } from '../../store/region/regionActions';
import { setGlobalSuccessMsg } from '../../store/global/globalActions';
import { useHistory } from 'react-router-dom';
import { getRegionBySlug } from '../../store/region/regionSelectors';

const CreateRegion = ({ slug }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const regionUpdate = useSelector(getRegionBySlug(slug));

  const [region, setRegion] = useState({
    region_slug: regionUpdate ? regionUpdate.slug : '',
    display_name: regionUpdate ? regionUpdate.display_name : '',
    erm_endpoint: regionUpdate ? regionUpdate.erm_endpoint : '',
    description: regionUpdate ? regionUpdate.description : '',
  });
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    const { name, value } = e.target;
    setRegion(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(createNewRegion(region))
      .then(res => {
        history.push('/regions');
        dispatch(setGlobalSuccessMsg({ id: res.display_name, model: 'region', action: 'created' }));
      })
      .finally(() => setLoading(false));
  };

  const onUpdate = () => {
    setLoading(true);
    dispatch(updateRegion(region))
      .then(res => {
        history.push('/regions');
        dispatch(setGlobalSuccessMsg({ id: res.display_name, model: 'region', action: 'updated' }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <WPSForm onSubmit={handleSubmit(regionUpdate ? onUpdate : onSubmit)}>
      <label htmlFor='region_slug'>Region ID</label>
      <WPSInput name='region_slug' value={region.region_slug} onChange={onChange} />

      <label htmlFor='display_name'>Name</label>
      <WPSInput name='display_name' value={region.display_name} onChange={onChange} />

      <label htmlFor='erm_endpoint'>ERM Endpoint</label>
      <WPSInput name='erm_endpoint' value={region.erm_endpoint} onChange={onChange} />

      <label htmlFor='description'>Description</label>
      <WPSInput name='description' value={region.description} onChange={onChange} />
      <WPSButton type='submit' className='save--btn max-width-150' loading={loading}>
        Save
      </WPSButton>
    </WPSForm>
  );
};

export default CreateRegion;
