import React from 'react';
import styled, { css } from 'styled-components';
import Dropdown from 'react-kiwi-dropdown';
import verticalDotsSvg from "assets/dotsVerticle.svg"

const StyledDropdown = styled(Dropdown)`
  ${props => css`
    position: relative;
    .KIWI-button {
      margin:0 !important;
      background: none;
      border: none;
      padding: ${props.padding || '8px'};
      .KIWI-single-arrow {
        position: relative;
        border: none;
        border-radius: 50%;
        width : 18px;
        height: 18px;
        background-size: contain;
        background: url(${props.dotsType});  
      }
    }
    .KIWI-option {
      white-space: nowrap;
    }
  `}
`;

const WPSDropdown = ({ options, onChange, padding }) => 
  <StyledDropdown options={options} onChange={onChange} dotsType={verticalDotsSvg} padding={padding} />;

export default WPSDropdown;