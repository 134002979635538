import React from 'react';
import env from 'config/env';

const SERVICE_NAME = 'Cloudflare';
const LABEL_DNS_SERVICE = `${SERVICE_NAME} DNS`;
const LABEL_WEBSITE_SERVICE = `${SERVICE_NAME} Website`;
const TOOLTIP_NEW_ZONE = `This will create a new ${LABEL_WEBSITE_SERVICE} and assign the domain to it. After the creation, you will be provided with the ${SERVICE_NAME} nameservers to update your domain DNS records.`
const getDeleteZoneModalHeader = (_delete) => <span>This action will <b>{_delete ? 'delete and disconnect' : 'disconnect'}</b> the {LABEL_WEBSITE_SERVICE} from the website{_delete ? ' and delete it from the Cloudflare account' : ''}.</span>;
const getDeleteZoneConfirmation = (name, disconnectOnly) => disconnectOnly
    ? `This will disconnect your ${LABEL_DNS_SERVICE} from the ${env.getBrandShortName()} dashboard, but it will not affect the Cloudflare account.`
    : `This will permanently delete the ${LABEL_DNS_SERVICE} for ${name} from both the ${env.getBrandShortName()} dashboard and Cloudflare's end.`

const CloudflareHelper = {
    SERVICE_NAME,
    LABEL_DNS_SERVICE,
    LABEL_WEBSITE_SERVICE,
    TOOLTIP_NEW_ZONE,
    getDeleteZoneModalHeader,
    getDeleteZoneConfirmation
};

export default CloudflareHelper;
