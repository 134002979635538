import { createSelector } from 'reselect';

/*
  Getters
*/
const userRole = state => state.auth.me.role;
const isAuthed = state => state.auth.isAuthed;
const authError = state => state.auth.authError;
/*
  Selectors
*/
export const isAuthedSelector = createSelector(isAuthed, status => status);
export const authErrorSelector = createSelector(authError, error => error);
export const roleSelector = createSelector(userRole, role => role);
