import React, { useState } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import UploadSQL from "../../../components/website/backups/UploadSQL";

const ImportSQL = ({ website }) => {
  useTitle('Import SQL File');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState([false, false, false]);

  const handleFileUpload = (e) => {
  //
  }

  return (
  <Container className='margin-0'>
    <TitleBar className='titlebar'>
    <TitleBar.Title>Import SQL File</TitleBar.Title>
    </TitleBar>
    <p className='color-primary subheader'>
      Import a SQL file to your website's database by uploading a file or providing a URL.
      If you have a large database, we highly recommend using a URL to import your SQL file.
      Before importing, a full backup of your database will be created.
    </p>
    <Content style={{ maxidth: '1140px', display: 'flex', flexWrap: 'wrap' }}>
    <UploadSQL onChange={handleFileUpload} />
    </Content>
  </Container>
  );
};

export default ImportSQL;
