import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import env from 'config/env';

const BillingPartner = () => {
  const history = useHistory();

  const partnerCards = [
    {
      title: 'Details',
      name: 'details',
      desc: 'Save your business details that will appear on the generated invoices.',
      icon: 'infoDark',
      btnText: 'Update',
    },
    {
      title: 'Invoices',
      name: 'invoices',
      desc: 'See invoices generated periodically from your subscriptions.',
      icon: 'invoice',
      btnText: 'View',
    },
    {
      title: 'Price List',
      name: 'plans',
      desc: `Subscribe to plans offered by ${env.getBrandShortName()} for recurring usage of its services.`,
      icon: 'plans',
      btnText: 'View',
    },
    {
      title: 'Subscriptions',
      name: 'subscriptions',
      desc: 'Manage your current subscriptions to Staq services.',
      icon: 'subscriptions',
      btnText: 'Manage',
    },
    {
      title: 'Payment Methods',
      name: 'payment',
      desc: 'Manage the credit cards used to charge your invoices by Staq.',
      icon: 'billing',
      btnText: 'Manage',
    },
    {
      title: 'Payment Intents',
      name: 'payment-intents',
      desc: 'See the process of collecting your payments.',
      icon: 'paymentintent',
      btnText: 'View',
    },
  ];
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
    },
  ];

  const handleOnClick = e => {
    if (e) {
      history.push({
        pathname: `/billing/${e}`,
      });
    }
  };
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Billing</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {partnerCards.map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              btnText={el.btnText}
              onClick={e => handleOnClick(e)}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default BillingPartner;
