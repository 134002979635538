import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { isString } from 'helpers';

const ReadMore = ({ text, customStyle, limit }) => {
  const [showMore, setShowMore] = useState(false);
  const [firstText, setFirstText] = useState('');
  const [lastText, setLastText] = useState('');
  const [charCount] = useState(limit || 100); // Number of characters to show initially.

  useEffect(() => {
    if (text && isString(text) && text.length > charCount) {
      setFirstText(text.substring(0, charCount));
      setLastText(text.substring(charCount));
    } else {
      setFirstText(text);
    }
    // eslint-disable-next-line
  }, []);

  if (!text) {
    return <div></div>;
  }

  customStyle = customStyle || {};

  return (
    <Fragment>
      <span style={customStyle}>{firstText}</span>
      {!showMore && text.length > charCount && <span>..</span>}
      <span style={{
        ...customStyle,
        display: `${!showMore ? 'none' : 'inline'}`
      }}>{lastText}</span>
      {text.length > charCount && (
        <button style={customStyle} className='read-more-btn' onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Hide' : 'Read More'}
        </button>
      )}
    </Fragment>
  );
};

export default ReadMore;
