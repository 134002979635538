import { getRequest } from '../config/axiosRequest';
import env from '../config/env';

const listStaqWAFBannedIPs = data => {
  return getRequest({
    url: env.buildApiEndpoint(`security/banned-ips`),
    data,
  });
};

const listWPVulnerablePackages = data => {
  return getRequest({
    url: env.buildApiEndpoint(`security/wp-vulnerability`),
    data,
  });
};

const SecurityService = {
  listStaqWAFBannedIPs,
  listWPVulnerablePackages
};

export default SecurityService;