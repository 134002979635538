import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import { websitesSelector } from 'store/website/websiteSelectors';
import ReportService from 'services/report';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import TableHelper from 'helpers/table';
import PackageHelper from 'helpers/package';
import WebsiteHelper from 'helpers/website';
import 'components/package/packages.css';

const VulnerablePackages = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const allWebsites = useSelector(websitesSelector);
  const mounted = useRef(true)

  useEffect(() => {
    fetchPackages();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const fetchPackages = () => {
    const data = {
        limit: 1000,
        search: null,
        vulnerable_only: true,
    }
    setLoading(true);
    ReportService.filterAppsInstalledPacakges(data)
      .then((res) => setTableData(res.map(item => ({...item, key: StringHelper.randomSlug()}))))
      .finally(() => setLoading(false));
  }

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Reports',
      link: '/reports',
    },
    {
      text: 'Vulnerable Packages',
      link: '#'
    }
  ];

  const headers = [
    PackageHelper.renderScopeHeader(),
    {
      name: 'Website',
      selector: 'website',
      searchable: true,
      width: '25%',
      cell: (row) => {
        const website = allWebsites.find(w => w.slug === row.website_slug);
        if (website) {
          TableHelper.customizeCellValue(row, 'website', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
        }
        return JsxHelper.createTableWebsiteCell({
          website,
          paddingRight: '10px',
        })
      }
    },
    {
      name: 'Installed Package',
      selector: 'display_name',
      searchable: true,
      width: '32%',
      cell: (row) => JsxHelper.createTableMultiLineCell({
        header: row.display_name,
        subheader: `${StringHelper.capitalizeFirstLetter(row.type)} version: ${row.version}`,
        paddingRight: '10px',
      }),
    },
    {
      name: 'Vulnerability',
      selector: 'description',
      width: '38%',
      cell: (row) => JsxHelper.createTableMultiLineCell({
        header: row.vulnerability.version,
        subheader: row.vulnerability.description,
      })
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Vulnerable Packages</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          customClass='package-history-table'
          columns={headers}
          body={tableData}
          loading={loading}
          rowsPerPage={100}
          dataKey={'key'}
        />
      </Content>
    </Fragment>
  );
}

export default VulnerablePackages;
