import ObjectHelper from './object';
import ArrayHelper from './array';
import { isEmptyOrNull } from "helpers";

// Tells whether the model should be updated
const shouldUpdate = function(current, updated, key) {
  const diffs = ObjectHelper.diffs(current, updated);
  if (isEmptyOrNull(diffs)) {
    // window.logHelper.debug(`No update diffs for ${key} ${updated[key]}: none.`);
    return false;
  } else if (ObjectHelper.size(diffs) === 1 && ObjectHelper.hasKey(diffs, 'updated_at')) {
    // window.logHelper.debug(`No update diffs for ${key} ${updated[key]}: updated_at.`);
    return false;
  }
  // window.logHelper.debug(`Found diffs for ${key} ${updated[key]}:`, diffs);
  return true;
}

// Updates the state of the model
const update = (state, key, value) => {
  // Check if the item exists
  const current = state.find(item => item[key] === value[key]);
  if (isEmptyOrNull(current)) {
    window.logHelper.warning(`Update failed: original item not found.`, value[key]);
    return state;
  }
  // Check whether to update the item
  if (!shouldUpdate(current, value, key)) {
    return state;
  }
  // Update the item at index
  return ArrayHelper.update(state, key, value);
};

const ReducerHelper = {
  update
};

export default ReducerHelper;
