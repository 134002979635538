import React, { useState, useRef } from 'react';
import { HelpButton, NotificationBarList } from 'styles/layout/userTopBar';
import Icon from './icon';
import useOnClickOutside from 'utils/useOnClickOutside';
import UrlHelper from 'helpers/url';
import ReactTooltip from 'react-tooltip';

const Help = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const listItems = [
    {
      icon: 'faq',
      value: 'Knowledge Base',
      onClick: () => open && UrlHelper.open('https://www.wpstaq.com/knowledge')
    },
    {
      icon: 'chat',
      value: 'Contact',
      onClick: () => open && UrlHelper.open('https://www.wpstaq.com/contact')
    }
  ];

  return (
    <HelpButton onClick={() => setOpen(true)}>
      <Icon tag='help' />
      {open && (
        <NotificationBarList ref={ref}>
          {listItems.map(i => (
            <NotificationBarList.Item onClick={i.onClick} key={i.value} icon={i.icon}>
              {i.value}
            </NotificationBarList.Item>
          ))}
          <ReactTooltip />
        </NotificationBarList>
      )}
    </HelpButton>
  );
};

export default Help;
