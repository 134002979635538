import React, { Fragment, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { useState } from 'react';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import SecurityService from 'services/security';
import StringHelper from 'helpers/string';
import PackageHelper from 'helpers/package';
import JsxHelper from 'helpers/jsx';
import 'components/package/packages.css';

const WPVulnerability = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true)

  const fetchData = () => {
    setLoading(true);
    const data = {
      limit: 1000
    }
    SecurityService.listWPVulnerablePackages(data).then(res => {
      setTableData(res);
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Reports',
      link: '/reports',
    },
    {
      text: 'WordPress Vulnerability',
      link: '/reports/wp-vulnerability',
    }
  ];

  const headers = [
    PackageHelper.renderTypeHeader(' '),
    JsxHelper.createTableTitleHeader('name', 'Package', '30%', 'created_at'),
    JsxHelper.createTableTextHeader('version', 'Version', '8%'),
    {
      name: 'Status',
      selector: 'status',
      width: '8%',
      sortable: true,
      cell: row => JsxHelper.createIcon({
        icon: row.status === 'fixed' ? 'success' : 'danger',
        color: row.status === 'fixed' ? 'success' : 'danger',
        tooltip: StringHelper.toText(row.status),
        customClass: 'margin-auto',
      }),
    },
    JsxHelper.createTableLongTextHeader('description', 'Description', '35%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>WordPress Vulnerability</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable customClass={'packages-table'} loading={loading} columns={headers} body={tableData} />
      </Content>
    </Fragment>
  );
};

export default WPVulnerability;
