import React from 'react';
import { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useSelector, useDispatch } from 'react-redux';
import { dnsZonesSelector } from 'store/dnsZone/dnsZoneSelectors';
import { deleteDNSZone } from 'store/dnsZone/dnsZoneActions';
import { isEmpty } from 'helpers';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg,
} from 'store/global/globalActions';
import { useHistory } from 'react-router-dom';
import useConfirm from 'hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import Can from 'utils/can';
import UserHelper from 'helpers/user';
import { websitesSelector } from 'store/website/websiteSelectors';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { unassignDNSZone } from 'store/website/websiteActions';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';

const DNSZonesIndex = () => {
  useTitle('DNS Zones');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const dnsZones = useSelector(dnsZonesSelector);
  const websites = useSelector(websitesSelector);

  const getWebsite = slug => {
    return websites.find(e => e.dns_zone_slug === slug) || null;
  };

  const getRecordsPageLink = (slug) => `/dns-zones/${slug}/records`;

  const unassignWebsite = (dnsZone) => {
    const website = getWebsite(dnsZone.slug);
    if (!website) {
      dispatch(setGlobalErrorMsg('DNS Zone has no website assigned.'));
      return;
    }
    dispatch(setGlobalPleaseWaitMsg({
      id: WebsiteHelper.getLabel(website),
      model: 'website',
      action: 'unassigned',
      from: dnsZone.name
    }));

    const data = {
      website_slug: website.slug,
      dns_zone_slug: dnsZone.slug,
    };
    dispatch(unassignDNSZone(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({
           id: WebsiteHelper.getLabel(website),
           model: 'website',
           action: 'unassigned',
           from: dnsZone.name 
        }));
      });
  }

  const actions = [
    {
      value: 'Records',
      onClick: item => {
        history.push({
          pathname: getRecordsPageLink(item.slug),
          state: item.slug,
        });
      },
    },
    {
      value: 'Unassign Website',
      onClick: unassignWebsite,
      doHide: item => !getWebsite(item.slug),
    },
    {
      value: 'Delete',
      doHide: item => !UserHelper.hasPermissions(`dns-zone:delete:${item.slug}`),
      onClick: item => DialogHelper
        .confirmDelete(confirm, item.name, 'DNS zone')
        .then(() => {
          dispatch(setGlobalPleaseWaitMsg({ id: item.name, model: 'DNS zone', action: 'deleted' }));
          dispatch(deleteDNSZone(item))
            .then(() => dispatch(setGlobalSuccessMsg({ id: item.name, model: 'DNS zone', action: 'deleted' })),)
            .catch(err => dispatch(setGlobalErrorMsg(err)));
        })
    },
  ];

  const headers = [
    {
      name: 'Name',
      selector: 'name',
      width: '35%',
      sortable: true,
      searchable: true,
      cell: (row) => JsxHelper.createTableMultiLineCell({
        header: row.name,
        subheader: isEmpty(row.records) ? 'No records found' : `${row.records.length} records`,
        subheaderLink: getRecordsPageLink(row.slug),
        subheaderClass: 'inactive-font-color',
      })
    },
    {
      name: 'Website',
      selector: 'slug',
      width: '35%',
      sortable: true,
      searchable: true,
      cell: row => {
        const website = getWebsite(row.slug);
        const links = !website ? [] : [{
            label: website.slug,
            link: `/websites/${website.slug}/domains`
        }];
        return JsxHelper.createTableCellMultiLinks({
          links,
          default: 'No website assigned'
        });
      },
    },
    JsxHelper.createTableActionsHeader(actions, '30%'),
  ];

  
  const goToDnsSetupPage = () => {
    history.push({
      pathname: `/dns-zones/setup`,
    });
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'DNS Zones',
      link: '/dns-zones',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>DNS Zones</TitleBar.Title>
        <TitleBar.Actions>
          <Can
            perform='dns-zone:create:*'
            yes={() => JsxHelper.createButton({ label: 'Create DNS Zone', onClick: goToDnsSetupPage })}
          />
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable columns={headers} body={dnsZones} />
      </Content>
    </Fragment>
  );
};

export default DNSZonesIndex;
