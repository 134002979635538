import { createSelector } from 'reselect';
import DateHelper from 'helpers/date';

// State
const me = state => state.me;

// Selectors
export const meSelector = createSelector(me, me => me);
export const timezoneSelector = createSelector(me, me => me.timezone || DateHelper.localTimezone);
export const userSlug = createSelector(me, me => me.slug);
export const partnerSelector = createSelector(me, me => {
  if (me.role === 'partner') {
    return me;
  } else if (me.role === 'employee') {
    return me.parent;
  }
  return null;
});
export const integrationSelector = createSelector(me, me => me.integrations || {});
export const metadataSelector = createSelector(me, me => me.metadata || {});
