import { postRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Redirect to stripe for authorization
 *
 * @param   {string} responseType
 * @param  {string} clientId
 * @param  {string} scope
 * @return  void
 */
export const redirect = ({ responseType, clientId, scope }) => {
  const url = `https://connect.stripe.com/oauth/authorize?response_type=${responseType}&client_id=${clientId}&scope=${scope}`;
  window.location = url;
};

/**
 * Connect with stripe
 *
 * @param   {string} code
 * @return  {Object}
 */
export const connect = code => {
  return postRequest({
    url: env.buildApiEndpoint(`stripe/connect`),
    data: { code },
  });
};

/**
 * Connect from stripe
 *
 * @return  {Object}
 */
export const disconnect = () => {
  return postRequest({
    url: env.buildApiEndpoint(`stripe/disconnect`),
  });
};

/**
 * Update stripe
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`stripe/settings`),
    data,
  });
};

/**
 * Add Payment Method
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const addPaymentMethod = data => {
  return postRequest({
    url: env.buildApiEndpoint(`stripe/${data.customer_slug}/payment-method`),
    data,
  });
};

/**
 * Edit Payment Method
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const editPaymentMethod = data => {
  return postRequest({
    url: env.buildApiEndpoint(`stripe/${data.customer_slug}/payment-method/${data.guid}`),
    data,
  });
};

/**
 * Delete Payment Method
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const deletePaymentMethod = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`stripe/${data.customer_slug}/payment-method/${data.guid}`),
    data,
  });
};
