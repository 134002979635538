import styled from 'styled-components';

export const FileData = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    margin-bottom: 8px;
    margin-left: 70px;
    h4 {
      flex: 1;
    }
    span {
      flex: 3;
      display: block;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  .msg {
    color: ${props => props.theme.color.danger};
    display: flex;
    align-items: center;
    margin-left: 16px;
    svg {
      font-size: 24px;
    }
  }
`;
