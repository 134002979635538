import React, { Fragment } from 'react';
import { TitleBar } from '../../styles/layout/titlebar';
import { WPSButton } from '../../styles/layout/buttons';
import { useHistory } from 'react-router-dom';
import useTitle from 'hooks/useTitle';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import 'components/stepper/stepper.css';
import env from 'config/env';

const CreateWebsite = () => {
  useTitle('Website Create');
  const history = useHistory();

  const options = [
    {
      title: 'New Site',
      desc: 'Create a brand new WordPress site with your customized settings.',
      icon: 'createSite',
      btnText: 'Create',
      hide: false,
      page: 'create',
    },
    {
      title: 'Migrate',
      desc: `Migrate a remote WordPress site using the ${env.getBrandShortName()} Migrator plugin.`,
      icon: 'migrateSite',
      btnText: 'Migrate',
      hide: false,
      page: 'migrate',
    },
    {
      title: 'Clone',
      desc: `Clone an existing site on your ${env.getBrandShortName()} account to a new site.`,
      icon: 'cloneSite',
      btnText: 'Clone',
      page: 'clone',
    },
    {
      title: 'Staging',
      desc: `Create a staging site to test changes before pushing them to your live site.`,
      icon: 'stagingSite',
      btnText: 'Create',
      page: 'staging',
    },
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Website',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Website</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='back--btn' onClick={history.goBack}>
            Back
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <div id="new-website-options">
        {
          options.map((e, index) => {
            return (
              !e.hide && <AdvancedTabs
              key={index}
              type={e.type || null}
              name={e.name || e.title}
              desc={e.desc}
              descColor={e.descColor ? e.descColor : null}
              descPrefix={e.descPrefix ? e.descPrefix : null}
              icon={e.icon}
              title={e.title}
              btnText={e.btnText}
              btnDisabled={e.btnDisabled || false}
              loading={e.loading ? e.loading : null}
              toolTipText={e.toolTipText ? e.toolTipText : null}
              secondBtn={e.secondBtn ? e.secondBtn : null}
              secondBtnLoading={e.secondBtnLoading}
              onClick={() => history.push({ pathname: `/website/${e.page}` })}
              spinnerText={e.spinnerText}
              spinnerWait={e.spinnerWait}
              spinner={e.spinner}
              statusTooltip={e.statusTooltip}
              statusColor={e.statusColor}
              onSecondBtnClick={e.btn2Click}
            />
          )})
        }
      </div>
    </Fragment>
  );
};

export default CreateWebsite;
