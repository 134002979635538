import React, { useEffect, useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Container } from 'styles/website/profile';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { isEmpty } from 'helpers';
import ArrayHelper from 'helpers/array';
import StringHelper from 'helpers/string';
import WebsiteService from 'services/website';
import JsxHelper from 'helpers/jsx';

const DatabaseStatistics = ({ website }) => {
  const [tableRows, setTableRows] = useState([]);
  const [databaseName, setDatabaseName] = useState('');
  const [databaseSize, setDatabaseSize] = useState(0);
  const [wpTablesCount, setWpTablesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (website) {
      fetchDatabaseInfo();
    }
    // eslint-disable-next-line
  }, [website]);

  const fetchDatabaseInfo = () => {
    setLoading(true);
    WebsiteService.getDatabaseInfo({ website_slug: website.slug })
      .then(response => {
        initTableRows(response.tables);
        setWpTablesCount(getWordPressTables().length);
        setDatabaseName(response.name);
        setDatabaseSize(StringHelper.convertBytes(response.size));
      })
      .finally(() => setLoading(false));
  };

  const getWordPressTables = () => {
    return [
      '_commentmeta',
      '_comments',
      '_links',
      '_options',
      '_postmeta',
      '_posts',
      '_termmeta',
      '_terms',
      '_term_relationships',
      '_term_taxonomy',
      '_usermeta',
      '_users',
    ];
  };

  const initTableRows = (tables) => {
    let _rows = [];
    if (!isEmpty(tables)) {
      _rows = Object.entries(tables).map(([key, value]) => {
        return { name: key, size: value };
      });
      _rows = ArrayHelper.generateUniqueField(_rows, 'name', 'rowId');
    }
    setTableRows(_rows);
  }

  const headers = [
    JsxHelper.createTableTextHeaderWithCallback(
      'name',
      'Table Name',
      '25%',
      (row) => JsxHelper.createTableHeaderCell({ header: row.name })
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'size',
      'Size',
      '25%',
      (row) => StringHelper.convertBytes(row.size)
    ),
  ];

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title>Database</TitleBar.Title>
      </TitleBar>
      {JsxHelper.createAnalyticsCards({
        items: [
          { icon: 'localstorage', title: 'DB Name', value: databaseName },
          { icon: 'database', title: 'DB Size', value: databaseSize },
          { icon: 'databaseTable', title: 'Total Tables', value: tableRows.length},
          { icon: 'wordpress', title: 'WordPress Tables', value: wpTablesCount},
          { icon: 'plugins', title: 'Plugin Tables', value: tableRows.length - wpTablesCount}
        ]
      })}
      <WPSDataTable
        columns={headers}
        body={tableRows}
        loading={loading}
        noSearchOnTable={true}
        rowsPerPage={100}
        dataKey='rowId'
      />
    </Container>
  );
};

export default DatabaseStatistics;
