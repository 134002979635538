import {
  SET_INPUT_RULES,
  SET_GLOBAL_LOADER,
  SET_VIEW_PREFERENCES,
  MESSAGE_SET,
  MESSAGE_REMOVE,
  SET_SECONDARY_SIDERBAR,
  NOTIFICATION_CREATE,
} from './globalActions';

// Global initial state
const initialState = {
  app_loader: true,
  message: {
    color: 'danger',
    text: '',
  },
  notifications: [],
  secondary_sidebar: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_LOADER:
      return {
        ...state,
        app_loader: action.payload,
      };

    case SET_INPUT_RULES:
      return {
        ...state,
        input_rules: action.payload,
      };

    case SET_VIEW_PREFERENCES:
      return {
        ...state,
        view_preferences: action.payload,
      };

    case MESSAGE_SET:
      return {
        ...state,
        message: action.payload,
      };

    case MESSAGE_REMOVE:
      return {
        ...state,
        message: {
          color: 'danger',
          text: '',
        },
      };

    case SET_SECONDARY_SIDERBAR:
      return {
        ...state,
        secondary_sidebar: action.payload,
      };

    case NOTIFICATION_CREATE:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };

    default:
      return state;
  }
};

export default globalReducer;
