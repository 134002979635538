import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'helpers';
import useQuery from 'hooks/useQuery';
import { useDeepCompareEffect } from 'utils/useDeepCompare';

export const WPSList = styled.ul`
  position: absolute;
  top: 110%;
  right: 0;
  background: white;
  box-shadow: 3px 3px 8px -3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border.primary};
  min-width: 200px;
  z-index: 1048;
  li {
    font-size: 13px;
    color: black;
    &:not(:first-child) {
      border-top: 1px solid ${({ theme }) => theme.table.oddRows};
    }
  }
  label {
    cursor: pointer;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
    overflow-wrap: break-word;
    &:hover {
      background: ${({ theme }) => theme.table.oddRows};
    }
  }
  input {
    margin-left: 10px;
  }
  .title {
    position: relative;
    cursor: default;
    text-align: left;
    padding: 8px;
    .sub-menu {
      position: absolute;
      top: 0;
      left: -101%;
      display: none;
      background: white;
      z-index: -1;
      width: 100%;
      background: white;
      box-shadow: 3px 3px 8px -3px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.border.primary};
      min-width: 200px;
    }
    &:hover {
      background: ${({ theme }) => theme.table.oddRows};
      .sub-menu {
        display: block;
      }
    }
  }
`;

export const WPSFilters = ({ changeFilters, show, toggle, filters }) => {
  const [filtersList, setFiltersList] = useState([]);
  const queryFilter = useQuery().getAll('filter');
  useEffect(() => {
    setFiltersList([...Object.entries(filters)])
    // eslint-disable-next-line
  }, [filters]);

  useDeepCompareEffect(() => {
    for(let i in filtersList){
      for(let j in filtersList[i][1]){
        if(filtersList[i][1][j] && queryFilter.includes(filtersList[i][1][j].value)){
          filtersList[i][1][j].is_checked = true;
        }else{
          filtersList[i][1][j].is_checked = false;
        }
      }
    }
    // eslint-disable-next-line
  }, [queryFilter]);
  return (
    show &&
    !isEmpty(filters) && (
      <WPSList ref={toggle}>
        {filtersList.map((item) => {
          const title = item[0];
          const filters = item[1];
          return (
            <li key={title} className='title'>
              {title}
              <ul className='sub-menu'>
                {filters.map(f => (
                  <li key={f.value}>
                    <label htmlFor={f.value}>
                      {f.name}
                      <input
                        name={title}
                        id={f.value}
                        type='checkbox'
                        onChange={changeFilters}
                        defaultChecked={f.is_checked}
                      />
                    </label>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </WPSList>
    )
  );
};

export const OrderedList = styled.ul`
  li {
    display: flex;
    line-height: 1.4;

    span:first-child {
      margin-right: 2px;
      min-width: 12px;
    }
    .link {
      color: ${({ theme }) => theme.color.link};
    }
  }
`;
