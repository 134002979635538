import React, { Fragment, useState, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  listServerInstances,
  cloneServerInstance,
  setServerPrimaryInstance,
  deleteServerInstance,
} from 'store/server/serverActions';
import {
  setGlobalPleaseWaitMsg,
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
} from 'store/global/globalActions';
import { serverInstancesBySlug } from 'store/server/serverSelectors';
import ServerService from 'services/server';
import useConfirm from 'hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import ArrayHelper from 'helpers/array';
import { isEmpty } from 'helpers';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const ServerInstance = () => {
  useTitle('Server instances');
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { params } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [instances, setInstances] = useState(useSelector(serverInstancesBySlug(params.slug)));
  const [instanceToClone, setInstanceToClone] = useState(null);
  const [details, setDetails] = useState({
    confirm: '',
    switch_only: '',
  });
  const [primaryModal, setPrimaryModal] = useState(false);
  const [selectedInstance, setSelectedInstace] = useState(null);
  const serversTypes = [{ name: 't3.small' }, { name: 't3.medium' }, { name: 't3.large' }];

  useEffect(() => {
    if (isEmpty(instances)) {
      fetchInstances(false);
    }
    // eslint-disable-next-line
  }, []);

  // Init list of instances
  const fetchInstances = () => {
    const data = {
      server_slug: params.slug,
    };
    setTableLoading(true);
    dispatch(listServerInstances(data))
      .then((res) => setInstances(res))
      .finally(() => setTableLoading(false));
  }

  // Init API data
  const initData = data => {
    return {
      server_slug: params.slug,
      ...data,
    };
  };

  const onCloneOptionsChange = e => {
    const { name, value } = e.target;
    setInstanceToClone(prev => ({ ...prev, [name]: value }));
  };

  const onSuccess = (id, action) => {
    let _model = action === 'scheduled' ? 'Instance data sync' : 'Instance';
    dispatch(setGlobalSuccessMsg({ id, model: _model, action }));
  };

  const onError = err => dispatch(setGlobalErrorMsg(err));

  // Handle instance clone
  const cloneInstance = instance => {
    if (loading) return;
    setLoading(true);
    dispatch(setGlobalPleaseWaitMsg({ id: instance.instance_id, model: 'Instance', action: 'cloned' }));
    const data = initData({ instance_id: instance.instance_id, instance_type: instance.instance_type });
    dispatch(cloneServerInstance(data))
      .then(() => onSuccess(instance.instance_id, 'cloned'))
      .catch(err => onError(err))
      .finally(() => {
        setLoading(false);
        setCloneModal(false);
      });
  };

  // Handle set primary instance
  const setPrimaryInstance = () => {
    if (loading) return;
    setLoading(true);
    const data = initData({
      instance_id: selectedInstance.instance_id,
      switch_only: details.switch_only,
    });
    dispatch(setServerPrimaryInstance(data))
      .then(() => {
        onSuccess(selectedInstance.instance_id, 'set as primary');
        setPrimaryModal(false);
      })
      .catch(err => onError(err))
      .finally(() => setLoading(false));
  };

  // Handle instance reboot
  const rebootInstance = instance => {
    if (loading) return;
    setLoading(true);
    dispatch(setGlobalPleaseWaitMsg({ id: instance.instance_id, model: 'Instance', action: 'rebooted' }));
    const data = initData({ instance_id: instance.instance_id });
    ServerService.rebootInstance(data)
      .then(() => onSuccess(instance.instance_id, 'rebooted'))
      .catch(err => onError(err))
      .finally(() => setLoading(false));
  };

  // Handle deleting an instance
  const deleteInstance = instance => {
    if (loading) return;
    setLoading(true);
    dispatch(setGlobalPleaseWaitMsg({ id: instance.instance_id, model: 'Instance', action: 'deleted' }));
    const data = initData({ instance_id: instance.instance_id });
    dispatch(deleteServerInstance(data))
      .then(() => onSuccess(instance.instance_id, 'deleted'))
      .catch(err => onError(err))
      .finally(() => setLoading(false));
  };

  // Handle stopping an instance
  const stopInstance = instance => {
    if (loading) return;
    setLoading(true);
    dispatch(setGlobalPleaseWaitMsg({ id: instance.instance_id, model: 'Instance', action: 'stopped' }));
    const data = initData({ instance_id: instance.instance_id });
    ServerService.stopInstance(data)
      .then(() => onSuccess(instance.instance_id, 'stopped'))
      .catch(err => onError(err))
      .finally(() => setLoading(false));
  };

  // Handle starting an instance
  const startInstance = instance => {
    if (loading) return;
    setLoading(true);
    dispatch(setGlobalPleaseWaitMsg({ id: instance.instance_id, model: 'Instance', action: 'started' }));
    const data = initData({ instance_id: instance.instance_id });
    ServerService.startInstance(data)
      .then(() => onSuccess(instance.instance_id, 'started'))
      .catch(err => onError(err))
      .finally(() => setLoading(false));
  };

  const actions = [
    {
      value: 'Clone',
      doHide: instance => !instance.is_primary,
      onClick: instance => {
        setInstanceToClone(instance);
        setCloneModal(true);
      },
    },
    {
      value: 'Set Primary',
      doHide: instance => instance.is_primary,
      onClick: instance => {
        setPrimaryModal(true);
        setSelectedInstace(instance);
        setDetails(prev => ({ ...prev, confirm: '' }));
      },
    },
    {
      value: 'Volumes',
      onClick: instance => {
        history.push({
          pathname: `instances/${instance.instance_id}/volumes`,
          state: { volumes: instance.volumes, server: params.slug, id: instance.instance_id },
        });
      },
    },
    {
      value: 'Start',
      onClick: startInstance,
    },
    {
      value: 'Reboot',
      onClick: instance => DialogHelper
        .confirmAction(confirm, 'reboot', instance.instance_id, 'instance')
        .then(() => rebootInstance(instance))
    },
    {
      value: 'Stop',
      onClick: instance => DialogHelper
        .confirmAction(confirm, 'stop', instance.instance_id, 'instance')
        .then(() => stopInstance(instance))
    },
    {
      value: 'Delete',
      doHide: instance => instance.is_primary,
      onClick: instance => DialogHelper
        .confirmDelete(confirm, instance.instance_id, 'instance')
        .then(() => deleteInstance(instance))
    },
  ];

  const headers = [
    JsxHelper.createTableTitleHeader('instance_id', 'Instance', '18%', 'instance_type'),
    JsxHelper.createTableBinaryBubbleHeader('is_primary', 'Primary', '10%'),
    JsxHelper.createTableLongTextHeader('public_dns', 'Public DNS', '25%'),
    JsxHelper.createTableCopyButtonHeader(dispatch, 'public_ipv4', 'Public IPv4', '15%'),
    JsxHelper.createTableLongTextHeader('image_id', 'Image ID', '20%'),
    JsxHelper.createTableActionsHeader(actions, '12%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: params.slug,
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Server Instances</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createRefreshButton({
            loading: tableLoading,
            onClick: fetchInstances,
          })}
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          loading={tableLoading}
          columns={headers}
          body={instances || []}
          dataKey='instance_id'
          noSearchOnTable={true}
        />
      </Content>
      {cloneModal && DialogHelper.inputs({
        title: 'Clone Instance',
        onClose: () => setCloneModal(false),
        onConfirm: () => cloneInstance(instanceToClone),
        loading,
        confirmBtn: 'Clone',
        closeBtn: 'Close',
        inputs: [
          {
            label: 'Instance type',
            type: 'select',
            name: 'instance_type',
            value: instanceToClone.instance_type,
            options: ArrayHelper.buildSelectOptions(serversTypes, 'name', 'name'),
            onChange: onCloneOptionsChange,
            required: true,
          }
        ]
      })}
      {primaryModal && DialogHelper.inputs({
        title: 'Set Primary Instance',
        onClose: () => setPrimaryModal(false),
        onConfirm: setPrimaryInstance,
        loading,
      })}
    </Fragment>
  );
};

export default ServerInstance;
