import React, { useState } from 'react';
import { useEffect } from 'react';
import { isEmpty } from 'helpers';
import StringHelper from 'helpers/string';
import ChartHelper from 'helpers/chart';
import { ChartsContainer } from 'styles/website/profile';

const S3Tab = ({ data, contentType }) => {
  const [requests, setRequests] = useState([]);
  const [bandwidth, setBandwidth] = useState([]);
  const REQUEST_KEYS = [
    { label: 'To Internet', key: 'out_to_internet' },
    { label: 'From Internet', key: 'in_from_internet' },
    { label: 'To CloudFront', key: 'out_to_cloudfront' },
  ];
  const BANDWIDTH_KEYS = [
    { label: 'To Internet', key: 'out_to_internet' },
    { label: 'From Internet', key: 'in_from_internet' },
    { label: 'To CloudFront', key: 'out_to_cloudfront' },
  ];

  // Get the data ready for charts.
  useEffect(() => {
    if (!isEmpty(data)) {
      // bandwidth
      setBandwidth(data.bandwidth.map(i => ({
        date: i.date,
        out_to_cloudfront: StringHelper.convertDown('B2MB', i.out_to_cloudfront),
        in_from_internet: StringHelper.convertDown('B2MB', i.in_from_internet),
        out_to_internet: StringHelper.convertDown('B2MB', i.out_to_internet),
      })));
      // requests
      setRequests(data.requests);
    }
  }, [data]);

  return (
    <ChartsContainer>
      {contentType === 'bandwidth' 
        ? ChartHelper.renderGraph('Bandwidth Usage (MB)', bandwidth, BANDWIDTH_KEYS, 'MB')
        : ChartHelper.renderGraph('Requests', requests, REQUEST_KEYS)
      }
    </ChartsContainer>
  );
};

export default S3Tab;
