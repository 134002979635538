import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import StringHelper from 'helpers/string';
import UrlHelper from 'helpers/url';
import UserService from 'services/user';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import JsxHelper from 'helpers/jsx';
import UserHelper from 'helpers/user';

const UserChildren = () => {
  useTitle('Users');
  const dispatch = useDispatch();
  const [ users, setUsers ] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const role = UrlHelper.getQueryParam('role') || 'employee';

  const fetchChildren = async () => {
    if (!state) {
      dispatch(setGlobalErrorMsg('Illegal user access.'));
      return;
    }
    setLoading(true);
    try {
      const res = await UserService.listChildren({
        parent_slug: state.slug,
        role: role,
      });
      setUsers(res);
    } catch (err) {
      dispatch(setGlobalErrorMsg(err));
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchChildren();
    // eslint-disable-next-line
  }, []);


  const headers = [
    {
      name: 'Name',
      selector: 'display_name',
      sortable: true,
      searchable: true,
      width: '30%',
      cell: (row) => {
        TableHelper.customizeCellValue(row, 'display_name', row.display_name + ' ' + row.email);
        return JsxHelper.createTableMultiLineCell({
          header: row.display_name,
          subheader: row.email,
        })
      }
    },
    {
      name: 'Role',
      selector: 'sub_role',
      sortable: true,
      width: '15%',
      cell: row => JsxHelper.createBubble({
        text: StringHelper.slugToText(row.sub_role || ''),
        background: UserHelper.getRoleColor(row.sub_role, row),
        customClass: 'margin-0',
        small: true,
      }),
    },
    {
      name: 'Status',
      selector: 'verification_status',
      sortable: true,
      searchable: true,
      width: '15%',
      cell: row => JsxHelper.createBubble({
        text: row.verification_status,
        sentence: true,
        background: row.verification_status === 'verified' ? 'success' : 'warning',
        customClass: 'margin-0',
        small: true,
      }),
    },
  ]

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Users',
      link: '/users',
    },
    {
      text: state ? state.slug : '-',
    },
    {
      text: 'Users',
    }
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          <div style={{ display: 'flex' }}>
            Users
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content className='main-users-table'>
        <WPSDataTable loading={loading} columns={headers} body={users} />
      </Content>
    </Fragment>
  );
};

export default UserChildren;
