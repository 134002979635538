import React, { Fragment } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import { useHistory } from 'react-router-dom';
import UserHelper from 'helpers/user';

const PartnerReports = () => {
  const history = useHistory();

  const cards = [
    {
      title: `Website Issues`,
      name: 'website-issues',
      desc: `View recently reported website errors and warnings by your WordPress sites.`,
      icon: 'website_issues',
      btnText: 'View',
    },
    {
      title: `Vulnerable Packages`,
      name: 'vulnerable-packages',
      desc: `View all plugins and themes that have known vulnerabilities in order to take action.`,
      icon: 'leaks',
      btnText: 'View',
    },
    {
      title: `Package Updates History`,
      name: 'package-updates-history',
      desc: `View the history of plugins and themes updates, including the update status and other details.`,
      icon: 'history',
      btnText: 'View',
    },
    {
      title: `Available Package Updates`,
      name: 'available-package-updates',
      desc: `View the plugins and themes that have updates available across all your websites.`,
      icon: 'update',
      btnText: 'View',
    },
    {
      title: 'Business Performance',
      name: 'business-performance',
      desc: 'Monitor your business performance and financial health with a graphical overview.',
      icon: 'analytics',
      doHide: !UserHelper.isPartner(),
      btnText: 'View',
    },
    {
      title: `Client Reports`,
      name: 'client-reports',
      desc: `Manage your Client PDF Reports on generated to keep them informed about their website health.`,
      icon: 'pdf',
      doHide: !UserHelper.hasPermissions(`client-pdf-report:read:*`),
      btnText: 'Manage',
    },
    {
      title: `Subscriptions High Usage`,
      name: 'subscriptions-high-usage',
      doHide: !UserHelper.isPartner(),
      desc: `View the subscription features that have high usage and can incur additional charges.`,
      icon: 'trendUp',
      btnText: 'View',
    },
  ];
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Reports',
    },
  ];

  const handleOnClick = e => {
    if (e) {
      history.push({
        pathname: `/reports/${e}`,
      });
    }
  };
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Reports</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {cards.filter(c => !c.doHide).map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              loading={el.loading}
              btnText={el.btnText}
              btnDisabled={el.btnDisabled}
              onClick={el.onClick || (e => handleOnClick(e))}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default PartnerReports;
