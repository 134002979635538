import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import SavePlan from 'components/plan/save';
import { useLocation } from 'react-router-dom';
import { Content } from 'styles/globalStyles';
import useTitle from 'hooks/useTitle';
import JsxHelper from 'helpers/jsx';

const PlanUpdate = () => {
  useTitle('Update Plan');
  const location = useLocation();

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Billing',
    },
    {
      text: 'Plans',
      link: '/billing/plans',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Update Plan</TitleBar.Title>
        <TitleBar.Actions>
        {JsxHelper.createBackButton('/billing/plans')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <SavePlan plan={location.state} />
      </Content>
    </Fragment>
  );
};

export default PlanUpdate;
