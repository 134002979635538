import React from 'react';
import styled, { css } from 'styled-components';
import { RingLoader } from './loaders';
import Icon from '../../components/layout/icon';
import Dropdown from 'react-kiwi-dropdown';

const WPSDropdownStyle = styled(Dropdown)`
  position: relative;
  border-radius: 5px !important;
  .KIWI-button {
    margin: 0;
    .KIWI-single-arrow {
      margin: 0 0 0 5px;
    }
  }
  .KIWI-option {
    white-space: nowrap;
    background: #fff;
    &:hover {
      background: #f5f5f5;
    }
    &.selected {
      background: #f5f5f5;
    }
  }
  .KIWI-option-list {
    width: 100%;
    margin-top: 4px !important;
    border-radius: 5px !important;
  }
`

export const WPSDropdown = ({ children, ...rest }) => {
  return (
    <WPSDropdownStyle {...rest}>
      {children}
    </WPSDropdownStyle>
  );
}

const WPSButtonStyle = styled.button`
  display: flex;
  min-height: 34px;
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'auto')};
  font-size: ${props => props.size && props.size};
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin};
  background: ${props =>
    props.color ? props.theme.background[props.color] : props.theme.background.primary};
  color: ${({ textColor, theme }) => (textColor ? theme.color[textColor] : theme.color.light)};
  text-transform: ${({ transform = 'capitalize' }) => transform};
  padding: 9px 16px;
  border-radius: ${props => props.theme.border.radius};
  &:disabled {
    color: #575757 !important;;
    background: ${props => props.theme.background.disabled} !important;
  }
  svg {
    margin-right: 8px;
    font-size: 16px;
  }
  ${({ variant, theme }) =>
    variant === 'text' &&
    css`
      background: none;
      padding: 0;
      display: inline;
      color: ${theme.color.link};
      min-height: 0;
    `}
`;

export const WPSButton = ({ loading, loadingColor, RingLoaderMargin, children, disabled, icon, ...rest }) => (
  <WPSButtonStyle {...rest} disabled={disabled ? disabled : loading}>
    {icon && !loading && <Icon tag={icon} />}
    {loading && <RingLoader color={loadingColor ? loadingColor : 'light'} margin={loadingColor ? RingLoaderMargin ? '-3px 28px 0 0px' :'-4px 8px 0 0' : '0px 8px 0 0'} />}
    {children}
  </WPSButtonStyle>
);

export const WPSIconBtn = styled.button`
  color: rgb(62, 195, 255);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  width: 34px;
  height: 34px;
  ${({ margin }) => css`
    margin: margin;
  `}
`;

export const AcademyBtn = styled.button`
  color: inherit;
  display: inline-flex;
  position: relative;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 33px;
    transform: translate(-50%, -50%);
  }
  .info {
    color: white;
    font-size: 13px;
    left: 42%;
  }
`;

export const BtnGroup = styled.div`
  display: flex;
  position: relative;
  .arrow {
    width: 41px;
  }
  button {
    display: flex;
    background: white;
    justify-content: center;
    width: 100%;
    border: 1px solid ${props => props.theme.border.primary};
    padding: 4px 12px;
  }
  button:first-child {
    margin-right: -1px;
  }
  ul {
    position: absolute;
    width: max-content;
    left: auto;
    right: 28px;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid ${props => props.theme.border.primary};
    z-index: 10;
    li {
      display: block;
      padding: 0px;
      cursor: pointer;
      &:hover {
        background: ${props => props.theme.background.primaryHover};
        button{
          background: ${props => props.theme.background.primaryHover};
        }
      }
      button{
        padding: 8px;
        border: 0;
        background: white;
        cursor: pointer;
        font-size: inherit;
        color: inherit;
        text-align: left;
        display: block;
        &:hover {
          background: ${props => props.theme.background.primaryHover};
        }
      }
    }
  }
`;

const WPSBubbleStyle = styled.span`
  display: ${({ display }) => (display ? display : 'flex')};
  padding: ${({ padding }) => (padding ? padding : '4px 8px')};
  border-radius: ${props => props.theme.border.radius};
  background: ${props => props.background && props.theme.background[props.background]};
  color: ${props => (props.color ? props.theme.color[props.color] : props.theme.color.light)};
  margin: ${({ margin }) => (margin ? margin : '0 6px')};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  width: fit-content;
  svg, .ring-loader {
    margin-right: ${({ iconSpacing }) => (iconSpacing ? `${iconSpacing}px` : '8px')};
  }
`;

export const WPSBubble = ({ children, icon, loading, ...rest }) => {
  const { background } = { ...rest };
  return (
    <WPSBubbleStyle {...rest}>
      {icon && !loading && <Icon tag={icon} />}
      {loading && (
        <RingLoader
          size={!background ? '14' : '16'}
          color={background ? 'light' : 'link'}
          margin='0 8px 0 0'
        />
      )}
      {children}
    </WPSBubbleStyle>
  );
};
