import React from 'react';
import Modal from 'components/layout/modal';
import { PaymentTable } from 'styles/layout/tables';

const PlanDetails = ({ open, plan, setOpen }) => {

  const basePrice = `$${plan.price} / ${plan.interval}`

  return (
    open && (
      <Modal title={plan.plan_name} subtitle={basePrice} onClose={() => setOpen(!open)}>
        <PaymentTable style={{ margin: '5px 0' }}>
          {plan.features.map((f, i) => (
            <li key={i}>
              <span className='title'>{f.name}</span>
              <span className='price'>
                {f.value}
                {f.allow_extra_units && (
                  <span className='note'>
                    ${f.extra_unit_price} for each additional {f.unit}
                  </span>
                )}
              </span>
            </li>
          ))}
        </PaymentTable>
      </Modal>
    )
  );
};

export default PlanDetails;
