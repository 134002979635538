import React, { useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSLabel, WPSCheckbox, WPSInput, ErrorMsg } from 'styles/layout/forms';
import Tooltip from 'components/layout/tooltip';
import { useForm } from 'react-hook-form';
import { WPSButton } from 'styles/layout/buttons';
import { Row } from 'styles/layout/grid';
import FormHelper from 'helpers/form';
import WebsiteService from 'services/website';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import { Container } from 'styles/website/profile';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import ArrayHelper from 'helpers/array';
import JsxHelper from 'helpers/jsx';

const DatabaseSearch = ({ slug }) => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    website_slug: slug,
    search: '',
    regex: false,
  });
  const [output, setOutput] = useState(null);
  const [showOutputTable, setShowOutputTable] = useState(false);

  const onChange = e => {
    const { name, value, checked, type } = e.target;
    const _value = type === 'checkbox' ? checked : value;
    setDetails(prev => ({ ...prev, [name]: _value }));
  };

  const onSubmit = () => {
    setLoading(true);
    WebsiteService.databaseSearch(details)
      .then(res => {
        setOutput(res);
        setShowOutputTable(true);
        setGlobalSuccessMsg({ model: 'Database search', action: 'done' });
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  const outputHeaders = [
    JsxHelper.createTableTextHeader('table', 'Table', '15%'),
    JsxHelper.createTableTextHeader('column', 'Column', '15%'),
    JsxHelper.createTableTextHeader('row_id', 'Row ID', '15%'),
    JsxHelper.createTableTextHeaderWithCallback('match', 'Match', '55%', row => {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: row.match.replace(new RegExp(details.search, 'gi'), '<strong>$&</strong>'),
          }}
        />
      );
    })
  ];

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title> Search</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader padding-left-0'>
        Searches through all the database tables for a given string. The search is case-insensitive.
      </p>
      <WPSForm>
        <WPSLabel required>
          String
          {errors.search && <ErrorMsg>{errors.search.message}</ErrorMsg>}
        </WPSLabel>
        <WPSInput
          className='width-30'
          type='text'
          name='search'
          value={details.search}
          onChange={onChange}
          ref={register({ required: FormHelper.messages.required })}
        />
        <WPSForm.RowItem direction='row' minHeight='50' className='margin-left-0'>
          <WPSCheckbox type='checkbox' name='regex' checked={details.regex} onChange={onChange} />
          <span className='margin-left-6'>Regex</span>
          <Tooltip
            place='right'
            text='Runs the search as a regular expression (without delimiters).<br /> The search becomes case-sensitive (i.e. no PCRE flags are added).'
          />
        </WPSForm.RowItem>
      </WPSForm>
      <Row className='action-buttons'>
        <WPSButton
          className='execute--btn'
          type='button'
          onClick={handleSubmit(onSubmit)}
          loading={loading}>
          Search
        </WPSButton>
      </Row>
      {showOutputTable && (
        <WPSDataTable
          columns={outputHeaders}
          body={ArrayHelper.generateUniqueField(output, 'row_id', 'rowId') || []}
          noSearchOnTable={true}
          rowsPerPage={100}
          dataKey='rowId'
        />
      )}
    </Container>
  );
};

export default DatabaseSearch;
