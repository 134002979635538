import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { createUser, updateUser, updatePartnerBillingCredits } from 'store/user/userActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserFormFields from './formFields';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import { WPSForm } from 'styles/layout/forms';
import UserService from 'services/user';
import DialogHelper from 'helpers/dialog';
import { isEmptyOrNull } from 'helpers';

// Create/update user of type (Admin/Partner).
const SaveUser = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit, triggerValidation } = useForm({ reValidateMode: 'onSubmit' });
  const initialState = {
    display_name: user ? user.display_name : '',
    email: user ? user.email : '',
    role: user ? user.role : 'partner',
    first_name: user ? user.first_name || '' : '',
    last_name: user ? user.last_name || '' : '',
    verify_email: true,
  };

  const [details, setDetails] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [{ password, password2 }, setPassword] = useState({
    password: '',
    password2: '',
  });
  const [billingCreditsModal, setBillingCreditsModal] = useState(false);
  const [billingCredits, setBillingCredits] = useState('');
  const [billingCreditsLoading, setBillingCreditsLoading] = useState(false);

  const onChange = e => {
    const { type, name, value, checked } = e.target;
    const _value = type === 'checkbox' ? checked : value;
    if (name === 'password' || name === 'password2') {
      setPassword(prev => ({ ...prev, [name]: value }));
    }
    setDetails(prev => ({ ...prev, [name]: _value }));
  };

  const userRoleOptions = {
    can_edit: !user,
    input_name: 'role',
    options: [
      { value: 'admin', text: 'Admin' },
      { value: 'partner', text: 'Partner' },
      { value: 'agent', text: 'Agent' },
    ],
  };

  const initFormData = () => {
    return {
      user_slug: user ? user.slug : null,
      display_name: details.display_name,
      email: details.email,
      role: details.role,
      first_name: details.first_name,
      last_name: details.last_name,
      verify_email: details.verify_email,
    };
  };

  // On success handler
  const onSuccess = id => {
    dispatch(setGlobalSuccessMsg({ id, model: 'user', action: user ? 'updated' : 'created' }));
    setLoading(false);
    history.push('/users');
  };

  // Form submit handler
  const onSubmit = () => {
    setLoading(true);
    const data = initFormData();

    // Create new user.
    if (!user) {
      // Remove the user slug field when creating a new user.
      delete data.user_slug;
      if (['admin', 'partner', 'agent'].includes(data.role)) {
        dispatch(createUser(data))
          .then(res => onSuccess(res.email))
          .catch(() => setLoading(false));
      } else {
        setLoading(false);
        dispatch(setGlobalErrorMsg('Invalid user role'));
        return;
      }
    }

    // Update current user.
    if (user) {
      if (['admin', 'partner', 'agent'].includes(data.role)) {
        dispatch(updateUser(data))
          .then(res => onSuccess(res.email))
          .catch(() => setLoading(false));
      } else {
        setLoading(false);
        dispatch(setGlobalErrorMsg('Invalid user role'));
        return;
      }
    }
  };

  const updatePassword = async () => {
    const valid = await triggerValidation(['password', 'password2']);
    if (valid) {
      setPasswordLoading(true);
      const data = { user_slug: user.slug, new_password: password };
      UserService.resetPassword(data)
        .then(() => dispatch(setGlobalSuccessMsg({ model: 'user', id: user.email, action: 'updated' })))
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          setPasswordLoading(false);
          setPasswordModal(false);
        });
    }
  };

  const onBillingCreditsChange = e => {
    const { name, value } = e.target;
    if (name === 'billing_credits') {
      setBillingCredits(value);
    }
  };

  const updateBillingCredits = async () => {
    const valid = await triggerValidation(['billing_credits']);
    if (!valid || user.role !== 'partner') {
      return;
    }
    setBillingCreditsLoading(true);
    const data = { partner_slug: user.slug, billing_credits: billingCredits };
    dispatch(updatePartnerBillingCredits(data))
      .then(res => onSuccess(res.email))
      .finally(() => {
        setBillingCreditsLoading(false);
        setBillingCreditsModal(false);
      })
  }

  return (
    <WPSForm onSubmit={handleSubmit(onSubmit)}>
      <UserFormFields
        errors={errors}
        onChange={onChange}
        register={register}
        details={details}
        user={user}
        userRoles={userRoleOptions}
        showVerifyEmail={details.role === 'partner'}
      />
      {!UserHelper.isAdminOrAgent() ? JsxHelper.createButton({
        type: 'submit',
        loading,
        label: 'Save',
        style: { width: 'auto', margin: '24px auto auto 0px' }
      }) : (
        <div style={{ display: 'flex', alignItems: 'flex-end' }} className='action-buttons'>
          {JsxHelper.createButton({
            classes: '',
            type: 'submit',
            loading,
            label: 'Save',
            style: { width: 'auto', minWidth: '120px' }
          })}
          {user && JsxHelper.createButton({
            classes: '',
            label: 'Update password',
            onClick: () => setPasswordModal(true),
          })}
          {UserHelper.isAdmin() && user && user.role === 'partner' && JsxHelper.createButton({
            classes: 'warning--btn',
            label: 'Update billing credits',
            onClick: () => setBillingCreditsModal(true),
          })}
        </div>
      )}
      {passwordModal && DialogHelper.inputs({
        title: 'Change Password',
        onClose: () => setPasswordModal(false),
        onConfirm: updatePassword,
        confirmBtn: 'Change',
        loading: passwordLoading,
        register,
        inputs: [{
          label: 'New password',
          name: 'password',
          value: password,
          type: togglePassword ? 'text' : 'password',
          onChange,
          required: true,
          minLength: 8,
          errors,
          togglePassword,
          setTogglePassword,
        }, {
          label: 'Confirm new password',
          name: 'password2',
          type: 'password',
          value: password2,
          onChange,
          required: true,
          ref: register({
            required: FormHelper.messages.required,
            validate: value => {
              if (value !== password) return 'Password does not match';
            },
          }),
          errors,
        }]
      })}
      {billingCreditsModal && DialogHelper.inputs({
        title: 'Update Billing Credits',
        onClose: () => setBillingCreditsModal(false),
        onConfirm: updateBillingCredits,
        confirmBtn: 'Update',
        loading: billingCreditsLoading,
        inputs: [{
          label: 'Current credits amount (USD)',
          name: 'current_credits',
          value: isEmptyOrNull(user.billing_credits) ? 0 : user.billing_credits,
          disabled: true,
        }, {
          label: 'New credits amount (USD)',
          name: 'billing_credits',
          value: billingCredits,
          required: true,
          onChange: onBillingCreditsChange,
          ref: register({
            required: FormHelper.messages.required,
            validate: value => {
              if (isNaN(value)) return 'Invalid amount';
            },
          }),
          errors,
        }]
      })}
    </WPSForm>
  );
};

export default SaveUser;
