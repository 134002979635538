import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import UserHelper from 'helpers/user';
import SaveUser from 'components/user/save';
import SaveEmployee from 'components/employee/save';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const UserCreate = () => {
  useTitle('User Create');
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Users',
      link: '/users',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          <div style={{ display: 'flex' }}>
            Add New User
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>{UserHelper.isAdmin() ? <SaveUser /> : <SaveEmployee />}</Content>
    </Fragment>
  );
};

export default UserCreate;
