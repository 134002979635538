import React, { Fragment, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useDispatch } from 'react-redux';
import { fetchAdminPaymentIntents } from 'store/billing/billingActions';
import { useState } from 'react';
import UserHelper from 'helpers/user';
import useTitle from 'hooks/useTitle';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';

const PaymentIntents = ({ customer }) => {
  const pageTitle = 'Payment Intents';
  useTitle(pageTitle);
  const dispatch = useDispatch();
  const [intentsData, setIntentsData] = useState([]);
  const [data, setData] = useState({
    partner_slug: null,
    page: 1,
    limit: 10,
  });
  const [partnerData, setPartnerData] = useState({
    page: 1,
    limit: 10,
  });
  const [customerData, setCustomerData] = useState({
    customer_slug: null,
    page: 1,
    limit: 10,
  });
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const intentsType = !customer ? 'partner' : 'customer';
  const mounted = useRef(true);

  // Fetch all Payment Intents on mount. (if not previously fetched).
  const fetchData = () => {
    setLoading(true);
    if (UserHelper.isAdmin()) {
      if (data.page === 1) {
        setTableLoading(true);
      }
      dispatch(fetchAdminPaymentIntents(data))
        .then(res => {
          if (mounted.current) {
            setIntentsData(intentsData.concat(res));
            let myData = { ...data };
            myData.page = myData.page + 1;
            setData(myData);
            if (res.length < 10) {
              setDisabled(true);
            }
          }
        })
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
            setTableLoading(false);
          }
        });
    } else if (intentsType === 'partner') {
      if (partnerData.page === 1) {
        setTableLoading(true);
      }
      dispatch(fetchAdminPaymentIntents(partnerData))
        .then(res => {
          if (mounted.current) {
            setIntentsData(intentsData.concat(res));
            let myData = { ...partnerData };
            myData.page = myData.page + 1;
            setPartnerData(myData);
            if (res.length < 10) {
              setDisabled(true);
            }
          }
        })
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
            setTableLoading(false);
          }
        });
    } else {
      if (customerData.page === 1) {
        setTableLoading(true);
      }
      dispatch(fetchAdminPaymentIntents(customerData,true))
        .then(res => {
          if (mounted.current) {
            let myData = { ...customerData };
            myData.page = myData.page + 1;
            setCustomerData(myData);
            setIntentsData(intentsData.concat(res));
            if (res.length < 10) {
              setDisabled(true);
            }
          }
        })
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
            setTableLoading(false);
          }
        });
    } // eslint-disable-next-line
  };

  useEffect(() => {
    fetchData();

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const headers = [
    JsxHelper.createTableTimeHeader('created_at'),
    JsxHelper.createTableBinaryBubbleHeader('succeeded', 'Status', '8%'),
    JsxHelper.createTableTextHeader('last4', 'Card Last 4', '10%'),
    JsxHelper.createTableTextHeaderWithCallback(
      'amount',
      'Amount',
      '10%',
      (row) => `${row.amount} ${row.currency.toUpperCase()}`
    ),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: !customer ? 'Billing' : 'Clients',
      link: `/${!customer ? 'billing' : 'clients'}`,
    },
    {
      text: pageTitle,
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>{pageTitle}</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createLoadMoreButton({
            loading,
            disabled,
            onClick: () => fetchData(),
          })}
          {JsxHelper.createBackButton(`/${!customer ? 'billing' : 'clients'}`)}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
            columns={headers}
            body={intentsData}
            loading={tableLoading}
            dataKey='guid'
          />
      </Content>
    </Fragment>
  );
};

export default PaymentIntents;
