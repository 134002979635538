import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory, NavLink } from 'react-router-dom';
import { SecondNav } from '../../styles/layout/sidebar';
import { IoMdClose } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import WebsiteHelper from 'helpers/website';

const SecondaryNav = ({ links, backURL }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const handleOnClick = () => backURL ? history.push(backURL) : WebsiteHelper.goToIndex(history);

  return (
    <SecondNav>
      <button id='close__btn' data-tip='Go back' data-for='close__btn' className='close' onClick={handleOnClick}>
        <IoMdClose />
        <ReactTooltip id='close__btn' />
      </button>
      
      {links.map(link => (
        <li key={link.name}>
          <NavLink to={`${url}${link.slug}`}>{link.name}</NavLink>
        </li>
      ))}
      
    </SecondNav>
  );
};

SecondaryNav.propTypes = {
  links: PropTypes.array.isRequired,
};

export default SecondaryNav;
