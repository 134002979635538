import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { WPSForm, WPSLabel, WPSInput } from 'styles/layout/forms';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { useState } from 'react';
import { WPSButton } from 'styles/layout/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import { serversListSelector } from 'store/server/serverSelectors';
import { regionsListSelector } from 'store/region/regionSelectors';
import { isEmpty } from 'helpers';
import ArrayHelper from 'helpers/array';
import JsxHelper from 'helpers/jsx';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { useForm } from 'react-hook-form';
import SecurityService from 'services/security';
import StaqWAFBannedIPs from 'components/security/StaqWAFBannedIPs';
import env from 'config/env';

const Firewall = () => {
  const PAGE_SIZE = 1000;

  const adminReports = [
    {name:`${env.getBrandShortName()} WAF: Banned IPs`,value:'staq_waf_banned_ips'},
  ];

  const { handleSubmit, register } = useForm({ reValidateMode: 'onSubmit' });
  const dispatch = useDispatch();
  const regions = useSelector(regionsListSelector);
  const [reportGenerated, setReportGenerated] = useState(false);
  const allWebsites = useSelector(websitesSelector);
  const servers = [{ label: 'All', value: 'all'}].concat(useSelector(serversListSelector));
  const websites = [{ label: 'All', value: 'all'}].concat(allWebsites.map(w => ({ label: w.slug, value: w.slug })));
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    region_slug: isEmpty(regions) ? '' : regions[0].value,
    page: 1,
    limit: PAGE_SIZE,
    report_name: 'staq_waf_banned_ips',
    server: 'all',
    website: 'all',
    ban_code: '',
    ip_address: ''
  });
  const [reportName, setReportName] = useState('');
  const [reportData, setReportData] = useState([]);

  const refreshReport = () => {
    onSubmit();
  }

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'website' && value !== 'all') {
      // In order for "server" selector to be re-rendered we had to add
      // "key" to force re-rendering it.
      const _website = allWebsites.find(w => w.slug === value);
      setDetails(prev => ({ ...prev, server: _website.server_slug }));
    }
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = e => {
    setLoading(true);

    const data = {
      region_slug: details.region_slug,
      report_name: details.report_name,
      website_slug: details.website === 'all' ? null : details.website,
      server_slug: details.server === 'all' ? null : details.server,
      page: details.page,
      limit: details.limit,
      ban_code: details.ban_code,
      ip_address: details.ip_address
    }

    SecurityService.listStaqWAFBannedIPs(data).then(res => {
      if (res) {
        setReportData([...res]);
        setReportName(details.report_name);
        setReportGenerated(true);
      }
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => {
      setLoading(false);
    });
  };

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Reports',
      link: '/reports',
    },
    {
      text: 'Firewall',
      link: '/reports/firewall',
    }
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Firewall</TitleBar.Title>
        <TitleBar.Actions>
          {reportGenerated && <WPSButton
            className={'refresh--btn'}
            disabled={loading}
            loading={loading}
            onClick={refreshReport}>
            Refresh
          </WPSButton>}
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {!reportGenerated && <WPSForm
          style={{ maxWidth: '50%', marginBottom: '50px' }}
          onSubmit={handleSubmit(onSubmit)}>
          <WPSForm.Fieldset>
            <legend>Report Filters</legend>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel required>
                  Region
                </WPSLabel>
                <WPSSelect
                  name='region_slug'
                  value={details.region_slug}
                  options={regions}
                  onChange={onChange}
                  required={true}
                  register={register}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='1'>
                <WPSLabel required>Report</WPSLabel>
                <WPSSelect
                  name='report_name'
                  value={details.report_name}
                  options={ArrayHelper.buildSelectOptions(
                    adminReports,
                    'name',
                    'value',
                  )}
                  onChange={onChange}
                />
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>
                  Server
                </WPSLabel>
                <WPSSelect
                  name='server'
                  key={details.server}
                  disabled={details.website !== 'all'}
                  value={details.server}
                  options={ArrayHelper.buildSelectOptions(
                    servers,
                    'label',
                    'value',
                  )}
                  onChange={onChange}
                  isSearchable={true}
                  isMultiSelect={false}
                  required={true}
                  register={register}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>
                  Website
                </WPSLabel>
                <WPSSelect
                  name='website'
                  value={details.website}
                  options={ArrayHelper.buildSelectOptions(
                    websites,
                    'label',
                    'value',
                  )}
                  onChange={onChange}
                  isSearchable={true}
                  isMultiSelect={false}
                  required={true}
                  register={register}
                />
              </WPSForm.RowItem>
            </WPSForm.Row>
            <WPSForm.Row>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>
                  IP Address
                </WPSLabel>
                <WPSInput
                  type='text'
                  name='ip_address'
                  defaultValue={details.ip_address}
                  onBlur={onChange}
                />
              </WPSForm.RowItem>
              <WPSForm.RowItem flex='1'>
                <WPSLabel>
                  Ban Code
                </WPSLabel>
                <WPSInput
                  type='text'
                  name='ban_code'
                  defaultValue={details.ban_code}
                  onBlur={onChange}
                />
              </WPSForm.RowItem>
            </WPSForm.Row>
            <div style={{ display: 'flex' }}>
              <WPSButton
                type='submit'
                className='action--read'
                style={{ maxWidth: '120px' }}
                disabled={isEmpty(details)}
                loading={loading}>
                Generate
              </WPSButton>
            </div>
          </WPSForm.Fieldset>
        </WPSForm>}
        {reportName === 'staq_waf_banned_ips' && <StaqWAFBannedIPs
            dispatch={dispatch}
            reportName={reportName}
            loading={loading}
            tableData={reportData}
            setTableData={setReportData}
            showWebsite={true}
          />
        }
      </Content>
    </Fragment>
  );
};

export default Firewall;
