import React from 'react';
import { sortDataAlph, isNullOrUndefined } from 'helpers';
import Select from 'react-select';
import './WPSSelect.css';
import { Fragment } from 'react';
import StringHelper from 'helpers/string';
const WPSSelect = ({
  key,
  name,
  value,
  options,
  disabled,
  isMultiSelect,
  noneOption,
  noneText,
  onChange,
  required,
  refId,
  register,
  placeholder,
  sortOff,
  isSearchable,
  closeMenuOnSelect,
  selectClass,
  withGroups,
}) => {
  const handleLocalOnChange = e => {
    const data = {
      target: {},
    };
    if (isMultiSelect) {
      data.target.name = name;
      data.target.values = e ? [...e] : [];
    } else {
      data.target = {
        name: name ? name : e.name,
        value: e.value,
        label: e.label,
      };
    }

    onChange(data);
  };

  const getOption = validate => {
    let option = null;
  
    if (withGroups) { // e.g. [{label: 'Group 1', options: [{label: 'Option 1', value: 'option_1'}]}
      let myOptions = [];
      for (const i in options) {
        myOptions = [...myOptions, ...options[i].options];
      }
      option = myOptions.find(el => el.value === value);
    } else { // e.g. [{label: 'Option 1', value: 'option_1'}]
      if (isMultiSelect) {
        option = options.filter(el => value.includes(el.value));
      } else {
        option = options.find(el => el.value === value);
      }
    }
  
    return option
      ? validate ? option.value : option
      : validate ? '' : null;
  };

  const getSelectOptions = () => {
    let optionsData = sortOff ? options : sortDataAlph(options);
    if (noneOption) {
      optionsData.unshift({
        label: noneText ? noneText : 'None',
        value: '',
      });
    }
    return optionsData;
  };

  const getKey = () => {
    // Fixes issues with rendering: https://github.com/JedWatson/react-select/issues/1581
    return StringHelper.randomString(10);
  }

  return (
    <Fragment>
      {/* Hidden input to validate Select errors */}
      {required && (
        <input
          id={refId}
          name={refId}
          type='text'
          value={!isNullOrUndefined(value) ? getOption(true) : ''}
          onChange={() => true}
          style={{ display: 'none' }}
          ref={register({ required: 'Please select an option' })}
        />
      )}
      <Select
        key={key || getKey()}
        className={selectClass ? `${selectClass} select-container` : 'select-container'}
        name={name}
        options={getSelectOptions()}
        defaultValue={isNullOrUndefined(value) ? null : getOption()}
        isDisabled={disabled}
        isMulti={isMultiSelect ? true : false}
        closeMenuOnSelect={closeMenuOnSelect === false ? false : true}
        blurInputOnSelect={closeMenuOnSelect === false ? false : true}
        onChange={handleLocalOnChange}
        isSearchable={isSearchable === true ? true : false}
        placeholder={placeholder ? placeholder : 'Select...'}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
      />
    </Fragment>
  );
};

export default WPSSelect;
