import { createSelector } from 'reselect';

/*
  Input
*/
const platformOptions = state => state.platform_options;
/*
Selectors
*/
export const stripeClientIdSelector = createSelector(platformOptions, options => options && options.stripe_client_id);
export const stripePubKeySelector = createSelector(platformOptions, options => options && options.stripe_publishable_key);
export const migratorPluginUrlSelector = createSelector(platformOptions, options => options && options.migrator_plugin_url);
export const defaultPhpVersion = createSelector(platformOptions, options => options && options.default_php_version);
