import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Global store
import store from './store';

// Application entry
import App from './App';

// Global Objects
import LogHelper from './helpers/log';

// Global Stlying
import Theme from './styles/theme';
import 'normalize.css';
import 'react-json-inspector/json-inspector.css';
import 'index.css';

// Providers
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from './components/layout/modal';
import { ConfirmContextProvider } from './components/layout/confirmDialog';
import { ModalContextProvider } from './components/layout/modalDialog';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <ModalProvider>
        <ModalContextProvider>
          <ConfirmContextProvider>
            <App />
          </ConfirmContextProvider>
        </ModalContextProvider>
      </ModalProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// Attach global objects to window so they are accessible
// across the whole application
window.logHelper = LogHelper;
