import React, { useState } from 'react';

import image1 from 'assets/login1.png';
import image2 from 'assets/login2.png';
import image3 from 'assets/login3.png';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin: auto 0;
  height: 350px;
`;

const Slider = styled.ul`
  position: relative;
  height: 100%;
  overflow: hidden;

  &.step_1 {
    li:nth-child(1) {
      transform: translateX(100%);
    }

    li:nth-child(2) {
      transform: translateX(200%);
    }

    li:nth-child(3) {
      transform: translateX(300%);
    }
  }
  &.step_2 {
    li:nth-child(1) {
      transform: translateX(0);
    }

    li:nth-child(2) {
      transform: translateX(100%);
    }

    li:nth-child(3) {
      transform: translateX(200%);
    }
  }
  &.step_3 {
    li:nth-child(1) {
      transform: translateX(-100%);
    }

    li:nth-child(2) {
      transform: translateX(0);
    }

    li:nth-child(3) {
      transform: translateX(100%);
    }
  }
`;

const Slide = styled.li`
  height: 100%;
  width: 33.33333%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.35s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  img {
    margin: 0;
    width: 50%;
    height: 50%;
    object-fit: contain;
    opacity: 0.5;
    transition: all 0.35s ease;
  }
  h2 {
    color: white;
    opacity: 0;
    /* margin-top: 24px; */
    transition: all 0.35s ease;
  }

  &.active {
    cursor: default;
    img {
      margin: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      object-fit: contain;
    }
    h2 {
      opacity: 1;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 24px 0;
  button {
    border: 2px solid white;
    color: lightblue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin: 0 8px;
    background: transparent;
    transition: all 0.35s ease;

    &.active {
      background: #6bc5bb;
    }
  }
`;

const LoginSlider = () => {
  const [slide, setSlide] = useState(2);

  const scroll = s => {
    setSlide(s);
  };

  return (
    <Container>
      <Slider className={`step_${slide}`}>
        <Slide onClick={() => scroll(1)} className={slide === 1 ? 'active' : ''}>
          <img src={image2} alt='' />
          <h2>Collaborate</h2>
        </Slide>
        <Slide onClick={() => scroll(2)} className={slide === 2 ? 'active' : ''}>
          <img src={image1} alt='' />
          <h2>Analyze</h2>
        </Slide>
        <Slide onClick={() => scroll(3)} className={slide === 3 ? 'active' : ''}>
          <img src={image3} alt='' />
          <h2>Design</h2>
        </Slide>
      </Slider>

      <Buttons>
        <button onClick={() => scroll(1)} className={slide === 1 ? 'active' : ''}></button>
        <button onClick={() => scroll(2)} className={slide === 2 ? 'active' : ''}></button>
        <button onClick={() => scroll(3)} className={slide === 3 ? 'active' : ''}></button>
      </Buttons>
    </Container>
  );
};

export default LoginSlider;
