import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeFormCard from './stripeFormCard';

const StripeForm = React.memo(({ setStripeRes, setStep, stripeKey, setAddNewCard }) => {
  const stripePromise = loadStripe(stripeKey);
  return (
    <Elements stripe={stripePromise}>
      <StripeFormCard setAddNewCard={setAddNewCard} setStripeRes={setStripeRes} setStep={setStep} />
    </Elements>
  );
});

export default StripeForm;
