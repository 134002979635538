import axios from 'axios';
import config from './env';

export const client = axios.create({
  baseURL: config.API_ENDPOINT
});

const request = options => {
  const onSuccess = response => response.data;
  const onError = error => {
    if (typeof error !== 'object') {
      error = { message: error };
    }
    error.response
      ? console.error('Error', error.response) // Server response
      : console.error('Error message', error.message); // Error message (no response)
    return Promise.reject(error.response || error.message);
  };
  return client(options).then(onSuccess).catch(onError);
};

export const getRequest = options => {
  options.method = 'GET';
  options.params = options.data;
  return request(options);
};

export const postRequest = options => {
  options.method = 'POST';
  return request(options);
};

export const deleteRequest = options => {
  options.method = 'DELETE';
  return request(options);
};

export const downloadRequest = options => {
  options.method = 'GET';
  options.responseType = options.data.blob ? 'blob' : 'json';
  options.params = options.data;
  return client(options).then(response => {
    // Find the download url in the response object
    const urlKey = (options.data ? options.data.download_url : null) || 'download_url';

    // If the download url is in the response object, use it (e.g. package files).
    // Otherwise, create a blob url from the response data (e.g. SSH key file).
    const url = (response.data ? response.data[urlKey] : null) || window.URL.createObjectURL(new Blob([response.data]));

    // Try to guess file name if not provided.
    if (!options.data && !options.data.file_name && response.data[options.data[urlKey]]) {
      const downloadUrl = response.data[options.data[urlKey]];
      // Remove query string
      const urlParts = downloadUrl.split('?');
      const urlWithoutQueryString = urlParts[0];
      // Get file name
      const fileName = urlWithoutQueryString.split('/').pop();
      options.data.file_name = fileName || 'undefined.txt';
    }

    const link = document.createElement('a');
    link.href = url;
    const filename = options.data.file_name || 'undefined.txt';
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  });
};
