import { LOGIN, LOGOUT, SET_AUTH_ERROR, SET_AUTH_DATA } from './authActions';

// User module initial State object
const initialState = {
  authError: null,
  me: {},
  isAuthed: false,
  apiPrefix: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem('user_data', JSON.stringify(action.payload));
      localStorage.setItem('access_token', action.payload.access_token);
      localStorage.setItem('api_prefix', action.payload.api_path_prefix);
      return {
        authError: null,
        isAuthed: true,
        me: action.payload,
        apiPrefix: action.payload.api_path_prefix
      };

    case SET_AUTH_DATA:
      return {
        ...state,
        isAuthed: true,
        me: JSON.parse(localStorage.getItem('user_data')),
        apiPrefix: JSON.parse(localStorage.getItem('user_data')).api_path_prefix
      };

    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        isAuthed: false,
        apiPrefix: null,
        authError: null,
        me: {}
      };

    case SET_AUTH_ERROR:
      localStorage.clear();
      return {
        ...state,
        me: {},
        authError: action.payload
      };

    default:
      return state;
  }
};

export default authReducer;
