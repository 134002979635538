import { isEmptyOrNull, isString } from "helpers";

// Reference:
// https://stackoverflow.com/questions/9804777/how-to-test-if-a-string-is-json-or-not
const isJson = item => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

const toJson = value => {
  if (isJson(value)) {
    return JSON.parse(value);
  }
  return value;
}

const toNumeric = value => {
  return parseFloat(value.match(/\d+/), 10);
}

// https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
const numberFormat = (number, decimals, dec_point, thousands_sep) => {
  decimals = decimals || 2;
  var n = !isFinite(+number) ? 0 : +number, 
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      toFixedFix = function (n, prec) {
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          var k = Math.pow(10, prec);
          return Math.round(n * k) / k;
      },
      s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

const is = (item) => {
  return typeof item === 'string' || item instanceof String;
}

const startsWith = (str, prefix) => {
  return (new RegExp("^" + prefix + ".*")).test(str);
}

const maybePluraize = (text, count, verbize, dryRun) => {
  count = count || 0;
  const plural = count > 1 || count === 0;
  const noun = plural ? text + 's' : text;
  if (verbize === 'past') {
    const verb = !dryRun ? (plural ? 'were' : 'was') : 'would have been';
    return `${count} ${noun} ${verb}`
  } else if (verbize) {
    const verb = !dryRun ? (plural ? 'are' : 'is') : 'would be';
    return `${count} ${noun} ${verb}`
  }
  return noun;
}

const slugify = (str, replaceWith) => {
  replaceWith = replaceWith || '';
  return str
    .toLowerCase()
    .replace(/ /g, replaceWith)
    .replace(/[^-\w]+/g, replaceWith)
    .replace(/-+/g, '-');
};

const removeWhitespaces = (str) => {
  return str.replace(/\s+/g, ' ').trim()
}

const capitalizeFirstLetter = (str, allWords) => {
  if (isEmptyOrNull(str)) {
    return str;
  }
  if (allWords) {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1); });
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const lowercaseFirstLetter = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

const randomSlug = (length) => {
  length = length || 8;
  const startWith = 'abcdefghijklmnopqrstuvwxyz';
  const slug = startWith.charAt(Math.floor(Math.random() * startWith.length));
  return slug + randomString(length - 1);
}

const randomString = length => {
  length = length || 8;
  return Math.random().toString(36).substring(2, length + 2);
};

const toText = (str) => {
  if (isEmptyOrNull(str)) {
    return '';
  } else if (!isString(str)) {
    window.logHelper.error('toText() expects a string as parameter');
    return '';
  }
  const text = str
    .toLowerCase()
    .replace(/[-_]+/g, ' ')
    .trim();
  return capitalizeFirstLetter(text);
}

const slugToText = (slug) => {
  const slugParts = slug.split('_');
  let text = '';
  for (const part of slugParts) {
    text += ' ' + capitalizeFirstLetter(part)
  }
  return text.trim();
}

const trimLeft = function (str, ch) {
  return str.replace(new RegExp("^[" + ch + "]+"), "");
};

const trimRight = function (str, ch) {
  return str.replace(new RegExp("[" + ch + "]+$"), "");
};

const trim = (str, ch) => {
  return trimRight(trimLeft(str, ch), ch);
};

const secondsToStr = (seconds, decimalPlaces) => {
  function numberEnding(number) {
    return number > 1 ? 's' : '';
  }
  decimalPlaces = decimalPlaces || 2;
  let temp = seconds;
  const years = (temp / 31536000).toFixed(decimalPlaces);
  if (years > 1) {
    return years + ' year' + numberEnding(years);
  }
  const days = ((temp %= 31536000) / 86400).toFixed(decimalPlaces);
  if (days > 1) {
    return days + ' day' + numberEnding(days);
  }
  const hours = ((temp %= 86400) / 3600).toFixed(decimalPlaces);
  if (hours > 1) {
    return hours + ' hour' + numberEnding(hours);
  }
  const minutes = ((temp %= 3600) / 60).toFixed(decimalPlaces);
  if (minutes > 1) {
    return minutes + ' minute' + numberEnding(minutes);
  }
  const secs = temp % 60;
  if (secs > 0) {
    return secs + ' second' + numberEnding(secs);
  }
  return 'less than a second';
};

const KB2B = 1024;

const convertBytes = size => {
  if (isNaN(size)) {
    return size;
  }
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'EB', 'ZB', 'YB'];
  const base = KB2B;
  const unit = size > 0 ? Math.min(parseInt(Math.log(size) / Math.log(base)), units.length - 1) : 0;
  const converted = size > 0 ? parseFloat(size / Math.pow(base, unit)).toFixed(2) : size;
  return `${converted} ${units[unit]}`;
};

const convertKBytes = size => convertBytes(size * KB2B)

const convertBytesToGB = bytes => Math.ceil(bytes / KB2B / KB2B / KB2B);

const convertToHumanReadableSize = (size, unit) => {
  const units = ['B', 'KB', 'MB', 'GB'];
  const unitIndex = units.findIndex(u => unit === u);
  if (unitIndex === -1) {
    return null;
  }
  if (units.length - 1 === unitIndex) {
    return `${size} ${unit}`;
  }
  const rule = `${units[unitIndex]}2${units[unitIndex + 1]}`
  const newSize = convertDown(rule, size);
  if (newSize > KB2B) {
    return convertToHumanReadableSize(newSize, units[unitIndex + 1]);
  }
  return `${newSize} ${units[unitIndex + 1]}`;
}

const convertDown = (rule, size) => {
  if (isNaN(size)) {
    return size;
  }
  switch (rule.toUpperCase()) {
    case 'B2KB':
      return parseFloat(size / KB2B).toFixed(0);
    case 'B2MB':
      return parseFloat(size / (KB2B * KB2B)).toFixed(2);
    case 'KB2MB':
      return parseFloat(size / KB2B).toFixed(2);
    case 'KB2GB':
      return parseFloat(size / (KB2B * KB2B)).toFixed(2);
    case 'MB2GB':
      return parseFloat(size / KB2B).toFixed(2);
    default:
      return 0;
  }
};

// Return the file extension as string
const getFileExtension = fileName => {
  let file = fileName.split('.');
  return file.pop();
};

// Convert all sizes to bytes
const convertToBytes = size => {
  const _sizeVal = size.split(` `)[0];
  const _sizeType = size.split(` `)[1];
  switch (_sizeType) {
    case 'TB':
      return _sizeVal * KB2B * KB2B * KB2B * KB2B
    case 'GB':
      return _sizeVal * KB2B * KB2B * KB2B
    case 'MB':
      return _sizeVal * KB2B * KB2B
    case 'KB':
      return _sizeVal * KB2B
    case 'B':
      return Number(_sizeVal)
    default:
      return -1
  }
};

// Limit the number of characters in a string.
const limit = (text, limit) => {
  limit = limit || 50;
  if(!isEmptyOrNull(text)){
    return text.length > limit ? text.substr(0, limit) + '...' : text;
  }
  return text;
};

// Convert ascii to html entities
const prettifyText = (text) => {
  text += '';
  const replacements = [
    ['&', '&amp;'],
    ['<', '&lt;'],
    ['>', '&gt;'],
    ['"', '&quot;'],
    ['\'', '&#039;'],
  ];
  for (const replacement of replacements) {
    text = text.replace(replacement[1], replacement[0]);
  }
  return text;
};

const hash = (text) => {
  let hash = 0;
  text = text || '';
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i); // 0 - 65535
    hash += char;
  }
  return hash;
}

const StringHelper = {
  is,
  isJson,
  toJson,
  toNumeric,
  slugify,
  randomSlug,
  randomString,
  trim,
  trimLeft,
  trimRight,
  secondsToStr,
  convertBytes,
  convertKBytes,
  convertBytesToGB,
  convertDown,
  convertToHumanReadableSize,
  getFileExtension,
  removeWhitespaces,
  capitalizeFirstLetter,
  lowercaseFirstLetter,
  slugToText,
  toText,
  startsWith,
  convertToBytes,
  limit,
  maybePluraize,
  prettifyText,
  hash,
  numberFormat,
};

export default StringHelper;