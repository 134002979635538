import React, { Fragment, useState, useEffect, useRef } from 'react';
import { StepperStep } from 'styles/website/profile';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import UrlHelper from 'helpers/url';
import ArrayHelper from 'helpers/array';
import UserHelper from 'helpers/user';
import DialogHelper from 'helpers/dialog';
import { isEmptyOrNull, getErrorMsg } from 'helpers';
import { WPSButton, WPSBubble } from 'styles/layout/buttons';
import { WPSForm, WPSLabel, WPSInput, ErrorMsg, WPSCheckbox } from 'styles/layout/forms';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalInfoMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import useModal from 'hooks/useModal';
import { Content } from 'styles/globalStyles';
import env from 'config/env';
import ReactTooltip from 'react-tooltip';
import PackageService from 'services/package';
import PackageCard from '../card'
import { createPartnerPackageFromGit } from 'store/partnerPackage/partnerPackageActions';
import { createPrivatePackageFromGit } from 'store/website/websiteActions';
import { userSlug, partnerSelector } from 'store/me/meSelectors';
import { activeWebsite } from 'store/website/websiteSelectors';
import { useHistory } from 'react-router-dom';
import 'components/stepper/stepper.css';

const ConnectGit = ({ type }) => {
  useTitle('Connect via Git');

  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const websiteSlug = UrlHelper.getQueryParam('website');
  const website = useSelector(activeWebsite(websiteSlug));

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(false);
  const [disabledStepsData, setDisabledStepsData] = useState([]);
  const myUserSlug = useSelector(userSlug);
  const myPartner = useSelector(partnerSelector);

  const mounted = useRef(true);
  const stepperRef = useRef();
  const disabledStepsElements = useRef([]);
  const stepToStateColors = useRef([]);

  const [sshLoading, setSshLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputError, setInputError] = useState(false);
  const [repositoryUrl, setRepositoryUrl] = useState('');
  const [productionBranch, setProductionBranch] = useState('');
  const [sshPublicKey, setSshPublicKey] = useState(false);
  const [sshKeysId, setSshKeysId] = useState(false);
  const [webhookApiUrl, setWebhookApiUrl] = useState(false);
  const [webhookApiKey, setWebhookApiKey] = useState(false);
  const [packageData, setPackageData] = useState(false);
  const [confirmWebhookAdd, setConfirmWebhookAdd] = useState(false);
  const [gitService, setGitService] = useState('bitbucket');

  const gitServiceOptions = [
    {
      name: 'Bitbucket',
      value: 'bitbucket',
      placeholder: 'https://bitbucket.org/MyAgency/my-agency-plugin',
      regexp: 'https://bitbucket.org/(.*?)/([^/]+)/?', 
    },
    {
      name:'GitHub',
      value:'github',
      placeholder: 'https://github.com/MyAgency/my-agency-plugin',
      regexp: 'https://github.com/(.*?)/([^/]+)/?', 
    },
  ];

  const renderBitbucketSshKeyInstructions = () => {
    const urlPath = '/admin/access-keys/';
    const pageName = 'Access keys';
    const keyText = 'Add key';
    const urlHtml = JsxHelper.createSubheaderLink(repositoryUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
    return (<div className={sshPublicKey ? '' : 'disabled-font-color'}>
      <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
      <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>{keyText}</b> button.</p>
      <p>&nbsp;&nbsp;&nbsp;b. <strong>Label</strong> the key with anything (e.g. <b><span style={{fontStyle: 'italic'}}>{env.getBrandShortName().toLowerCase()}-api</span></b>).</p>
      <p>&nbsp;&nbsp;&nbsp;c. <strong>Copy & paste</strong> the SSH key from step 1 to the <b>Key</b> field.</p>
      <p>&nbsp;&nbsp;&nbsp;d. <strong>Save</strong> the SSH key.</p>
    </div>)
  }

  const renderGithubSshKeyInstructions = () => {
    const urlPath = '/settings/keys/';
    const pageName = 'Deploy keys';
    const keyText = 'Add deploy key';
    const urlHtml = JsxHelper.createSubheaderLink(repositoryUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
    return (<div className={sshPublicKey ? '' : 'disabled-font-color'}>
      <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
      <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>{keyText}</b> button.</p>
      <p>&nbsp;&nbsp;&nbsp;b. <strong>Label</strong> the key with anything (e.g. <b><span style={{fontStyle: 'italic'}}>{env.getBrandShortName().toLowerCase()}-api</span></b>).</p>
      <p>&nbsp;&nbsp;&nbsp;c. <strong>Copy & paste</strong> the SSH key from step 1 to the <b>Key</b> field.</p>
      <p>&nbsp;&nbsp;&nbsp;d. <strong>Validate</strong> that the <b>Allow write access</b> checkbox is unchecked.</p>
      <p>&nbsp;&nbsp;&nbsp;e. <strong>Save</strong> the SSH key.</p>
    </div>)
  }

  const renderSshKeyInstructions = () => {
    const value = getGitServiceOption().value;
    if (value === 'bitbucket') {
      return renderBitbucketSshKeyInstructions();
    } else if (value === 'github') {
      return renderGithubSshKeyInstructions();
    }
    return '';
  }

  const renderBitbucketWebhookInstructions = () => {
    const urlPath = '/admin/webhooks/';
    const pageName = 'Webhooks';
    const urlHtml = JsxHelper.createSubheaderLink(repositoryUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
    return (<div className={webhookApiUrl ? '' : 'disabled-font-color'}>
      <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
      <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>Add webhook</b> button.</p>
      <p>&nbsp;&nbsp;&nbsp;b. <strong>Set</strong> the title to anything (e.g. <b><span style={{fontStyle: 'italic'}}>{env.getBrandShortName().toLowerCase()}-api</span></b>).</p>
      <p>&nbsp;&nbsp;&nbsp;c. <strong>Copy & paste</strong> the secure URL from step 1 to the <b>URL</b> field.</p>
      <p>&nbsp;&nbsp;&nbsp;d. <strong>Validate</strong> the <b>Active</b> checkbox under Status is checked.</p>
      <p>&nbsp;&nbsp;&nbsp;e. <strong>Validate</strong> only the <b>Push</b> checkbox under Triggers is checked.</p>
      <p>&nbsp;&nbsp;&nbsp;f. <strong>Save</strong> the webhook.</p>
      <p>&nbsp;&nbsp;&nbsp;g. <strong>Validate</strong> your webhook is added under <b>Repository hooks</b>.</p>
    </div>)
  }

  const renderKnowledgeBaseLink = () => {
    let path = 'how-to-setup-bitbucket-with-staq';
    if (gitService === 'github') {
      path = 'how-to-setup-github-with-staq';
    }

    return (<p style={{marginTop: "4px"}}>
    For further setup instructions check out our {JsxHelper.createFaqLink(path, 'knowledge base')}.
    </p>)
  }

  const renderGithubWebhookInstructions = () => {
    const urlPath = '/settings/hooks/';
    const pageName = 'Webhooks';
    const urlHtml = JsxHelper.createSubheaderLink(repositoryUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
    return (<div className={webhookApiUrl ? '' : 'disabled-font-color'}>
      <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
      <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>Add webhook</b> button.</p>
      <p>&nbsp;&nbsp;&nbsp;b. <strong>Copy & paste</strong> the secure URL from step 1 to the <b>Payload URL</b> field.</p>
      <p>&nbsp;&nbsp;&nbsp;c. <strong>Leave</strong> the <b>Secret</b> field empty.</p>
      <p>&nbsp;&nbsp;&nbsp;d. <strong>Validate</strong> only the <b>Just the push event.</b> checkbox under Triggers is checked.</p>
      <p>&nbsp;&nbsp;&nbsp;e. <strong>Validate</strong> the <b>Active</b> checkbox is checked.</p>
      <p>&nbsp;&nbsp;&nbsp;f. <strong>Click</strong> the <b>Add webhook</b> button to save the webhook.</p>
      <p>&nbsp;&nbsp;&nbsp;g. <strong>Validate</strong> your webhook is added to the {urlHtml} page.</p>
    </div>)
  }

  const renderWebhookInstructions = () => {
    const value = getGitServiceOption().value;
    if (value === 'bitbucket') {
      return renderBitbucketWebhookInstructions();
    } else if (value === 'github') {
      return renderGithubWebhookInstructions();
    }
    return '';
  }

  const getGitServiceOption = () => {
    return ArrayHelper.find(gitServiceOptions, 'value', gitService);
  }

  const getRepositoryUrlExample = () => {
    return getGitServiceOption().placeholder;
  }

  const getRepositoryUrlRegExp = () => {
    return getGitServiceOption().regexp;
  }

  const initStepper = (step) => {
    let disabledSteps = []; // Starts with index 0 (step 1 = index 0)
    let stepToState = { 1: 'complete', 2: 'complete', 3: 'complete' }

    disabledSteps = createDisabledSteps(step);

    // Set disabled steps
    setDisabledStepsData(disabledSteps);
    disabledStepsElements.current = disabledSteps;
    stepToStateColors.current = stepToState;

    // Initialize steps and go to initial
    stepperSteps[step - 1].onClick();

    // Set initial step at the end of conditions
    updateCurrentStep(step);
  }

  useEffect(() => {
    initStepper(1);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    history.goBack();
  }

  const onInstallPackage = () => {
    let apiParams = packageData;

    if (UserHelper.isAdminOrAgent()) {
      if (!website) {
        DialogHelper.error(modalDialog, 'Not supported from Admin interface.');
        return;
      }
      apiParams.partner_slug = website.partner_slug;
    } else {
      apiParams.partner_slug = UserHelper.isPartner() ? myUserSlug : myPartner.slug;
    }

    apiParams.repository_url = repositoryUrl;
    apiParams.repository_branch = productionBranch;
    apiParams.ssh_keys_id = sshKeysId;
    apiParams.webhook_api_key = webhookApiKey;

    setLoading(true);

    if (website) {
      apiParams.website_slug = website.slug;
      dispatch(createPrivatePackageFromGit(apiParams))
        .then(goBack).finally(() => setLoading(false));
    } else {
      dispatch(createPartnerPackageFromGit(apiParams))
        .then(goBack).finally(() => setLoading(false));
    }
  }

  const onAddSSHKeys = () => {
    const re = new RegExp(getRepositoryUrlRegExp());
    if (!re.test(repositoryUrl)) {
      setInputError('Repository URL format is invalid.');
      return;
    }
    const matches = repositoryUrl.match(re);
    setRepositoryUrl(StringHelper.trimRight(matches[0], '/'));
    goToStep(2);
  }

  const onGenerateKeys = () => {
    setSshLoading(true);
    PackageService.generateGitKeys()
      .then(res => {
        setSshPublicKey(res.ssh_public_key);
        setSshKeysId(res.ssh_keys_id);
        setWebhookApiUrl(res.webhook_api_url);
        setWebhookApiKey(res.webhook_api_key);
        copyText('SSH key', res.ssh_public_key);
      }).catch(error => {
        DialogHelper.error(modalDialog, getErrorMsg(error));
      }).finally(() => setSshLoading(false));
  }

  const onConnectRepository = () => {
    setLoading(true);
    const data = {
      repository_url: repositoryUrl,
      repository_branch: productionBranch,
      ssh_keys_id: sshKeysId,
    }

    PackageService.fetchFromGit(data)
      .then(res => {
        setPackageData({
          folder_name: res.FolderName,
          parent_folder_name: res.ParentThemeDir ? res.ParentThemeDir : null,
          display_name: res.Name,
          dir_size: res.DirSizeInBytes,
          local_src: res.LocalSource,
          src: res.Source,
          version: res.Version,
          type: res.Type,
          description: res.Description,
          official_uri: res.ThemeURI || res.PluginURI || res.URI,
          author: res.Author,
          author_uri: res.AuthorURI,
          is_git: true
        });
        goToStep(3);
      }).catch(error => {
        DialogHelper.error(modalDialog, getErrorMsg(error));
      }).finally(() => setLoading(false));
  }

  const goToStep = async (step) => {
    initStepper(step);
  }

  const initStep = stepNum => {
    for (var i = 1; i <= 3; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!disabledStepsElements.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add(`${stepToStateColors.current[i]}-color`);
      }
    }
    setCurrentStepForData(stepNum);
  };

  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Set Git Repository',
      onClick: () => initStep(1),
    },
    {
      title: 'Add SSH Keys',
      onClick: () => initStep(2),
    },
    {
      title: 'Review ' + StringHelper.capitalizeFirstLetter(type),
      onClick: () => initStep(3),
    },
    {
      title: 'Add Webhook',
      onClick: () => initStep(4),
    }
  ]);

  const createDisabledSteps = (start) => {
    let steps = [];
    for (let i = start; i <= stepperSteps.length; i++) {
      steps.push(i);
    }
    return steps;
  }

  const updateCurrentStep = newStep => {
    setCurrentStep(newStep + 1);
    setCurrentStepForData(newStep);
  };

  const copyText = async (type, text, dontTrimDot) => {
    if (!dontTrimDot) {
      text = StringHelper.trimRight(text, '.');
    }
    await navigator.clipboard.writeText(text);
    dispatch(setGlobalInfoMsg(`${type} copied to clipboard`));
  };

  return (
    <Fragment>
      <Content id='connect-git-wizard'>
        <Fragment>
          <div ref={stepperRef} className='stepper'>
            {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
          </div>
          {currentStepForData === 1 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                <p className='step-title'>
                  Configure your {type}'s Git Repository you want {env.getBrandShortName()} to watch in order to automatically sync your changes.
                  Note that it is important to insert the exact production branch name, otherwise the changes will be ignored.
                  {renderKnowledgeBaseLink()}
                </p>
                <div className='notice'>
                  <WPSForm.RowItem>
                    <WPSLabel required>
                      <strong>Select Git service</strong>
                    </WPSLabel>
                    <WPSSelect
                      name='git_service'
                      value={gitService}
                      options={ArrayHelper.buildSelectOptions(gitServiceOptions, 'name', 'value')}
                      onChange={e => setGitService(e.target.value) }
                      disabled={!isEmptyOrNull(sshPublicKey)}
                    />
                  </WPSForm.RowItem>
                  <WPSForm.RowItem>
                    <WPSLabel required>
                      <strong>Enter the repository URL</strong>
                      {inputError && <ErrorMsg>{inputError}</ErrorMsg>}
                    </WPSLabel>
                    <WPSInput
                      type='text'
                      placeholder={getRepositoryUrlExample()}
                      value={repositoryUrl}
                      disabled={!isEmptyOrNull(sshPublicKey)}
                      onChange={e => setRepositoryUrl(e.target.value)}
                    />
                  </WPSForm.RowItem>
                  <WPSForm.RowItem style={{ position: 'relative', marginLeft: '0px' }}>
                    <WPSLabel required>
                      <strong>Enter the production branch</strong>
                    </WPSLabel>
                    <WPSInput
                      type='text'
                      placeholder='e.g. master'
                      value={productionBranch}
                      onChange={e => setProductionBranch(e.target.value)}
                    />
                  </WPSForm.RowItem>
                </div>
                <WPSButton
                  type='button'
                  className='next--btn'
                  disabled={loading || sshLoading || isEmptyOrNull(repositoryUrl) || isEmptyOrNull(productionBranch)}
                  onClick={() => onAddSSHKeys()}
                  margin='24px 0 0 7px'>
                  Add SSH Keys
                </WPSButton>
              </StepperStep>
            </WPSForm>
          )}

          {currentStepForData === 2 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                <p className='step-title'>
                  Follow the steps below in order to grant {env.getBrandShortName()} a read-only access to your repository via SSH keys.
                  To make the process easier and more secure, we will generate the SSH public and private keys for you in order to save
                  you from encrypting and storing them. All you need to do is to copy & paste the generated key to your repository.
                  {renderKnowledgeBaseLink()}
                </p>
                <div className='ns-steps'>
                  <p>1. <strong>Generate</strong> secure SSH keys by clicking the following button:</p>
                  {!sshPublicKey && <div className='ns-record'>{JsxHelper.createBubble({
                    background: 'warning',
                    icon: 'lock',
                    padding: '4px 8px',
                    loading: sshLoading,
                    onClick: onGenerateKeys,
                    text: 'Generate SSH key',
                  })}</div>}
                  {sshPublicKey && <div className='ns-record'>{JsxHelper.createCopyButton({
                    label: 'Copy SSH key',
                    value: sshPublicKey,
                    dispatch,
                  })}</div>}
                  {renderSshKeyInstructions()}
                </div>
                <div className='display-flex action-buttons'>
                  <WPSButton
                    type='button'
                    className='next--btn'
                    loading={loading}
                    disabled={!sshPublicKey}
                    onClick={onConnectRepository}
                    margin='24px 0 0 7px'>
                    Connect Repository
                  </WPSButton>
                </div>
              </StepperStep>
            </WPSForm>
          )}

          {currentStepForData === 3 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                <p className='step-title'>
                  Hooray! We have successfully connected to your repository. Please review the {type} details and make sure they are correct.
                  Once you have reviewed the {type}, you can continue to add a Webhook so {env.getBrandShortName()} can be notified whenever a new code is pushed to your repository.
                  {renderKnowledgeBaseLink()}
                </p>
                <div className='ns-steps'>
                  <PackageCard fileData={packageData}/>
                </div>
                <div className='display-flex'>
                  <WPSButton
                    type='button'
                    className='next--btn'
                    onClick={() => goToStep(4)}
                    margin='0 0 0 7px'>
                    Add Webhook
                  </WPSButton>
                </div>
              </StepperStep>
            </WPSForm>
          )}

          {currentStepForData === 4 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                <p className='step-title'>
                  Follow the steps below in order to make {env.getBrandShortName()} automatically receive notifications when a new code is pushed to your repository. This way {env.getBrandShortName()} can keep your {type}'s up to date with the latest version of your production branch.
                  {renderKnowledgeBaseLink()}
                </p>
                <div className='ns-steps'>
                  <p>1. <strong>Copy</strong> the unique <b>API endpoint</b> to your {type} by clicking the following button:</p>
                  {webhookApiUrl && <div className='ns-record'>
                    <WPSBubble
                      data-tip='Click to copy'
                      color='light'
                      background='info'
                      className='btn-xs'
                      icon='copy'
                      onClick={() => copyText('Secure URL', webhookApiUrl)}>
                      Copy secure URL
                    </WPSBubble>
                    <ReactTooltip />
                  </div>}
                  {renderWebhookInstructions()}
                </div>
                <div className='display-flex action-buttons'>
                  {!confirmWebhookAdd && <label className='confirmwh-label' htmlFor='confirmwh'>
                    <WPSCheckbox
                      type='checkbox'
                      id='confirmwh'
                      checked={confirmWebhookAdd}
                      onChange={() => setConfirmWebhookAdd(true)}
                    />
                    I <b>confirm</b> that I added the Webhook as instructed above.
                  </label>}
                  {confirmWebhookAdd && <WPSButton
                    type='button'
                    className='upload--btn'
                    loading={loading}
                    disabled={!webhookApiUrl}
                    onClick={onInstallPackage}
                    margin='24px 0 0 7px'>
                    Install {StringHelper.capitalizeFirstLetter(type)}
                  </WPSButton>}
                </div>
              </StepperStep>
            </WPSForm>
          )}
        </Fragment>
      </Content>
    </Fragment>
  );
};

export default ConnectGit;
