import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Can from '../../utils/can';
import { WPS_LOGO, WPS_LOGO_FULL } from '../../assets/wps_logo';
import Icon from './icon';
import { Container, Logo, Nav } from '../../styles/layout/sidebar';
import { useSelector } from 'react-redux';
import { secondaryNavState } from '../../store/global/globalSelectors';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';

const SideBar = () => {
  const isSecondNav = useSelector(secondaryNavState);

  /**
   * Notes:
   *
   * - id:         must match the icon at icon.js
   *
   * - permission: at least one of the permissions must be met. For example, an "employee"
   *               doesn't have "billing:view-sidebar" but must have "invoice:read:*" or
   *               "payment-method:read:*" to view the "Billing" menu item.
   */
  const links = [
    { id: 'dashboard', name: 'Dashboard', permission: 'dashboard:view-sidebar', path: '/' },
    { id: 'users', name: 'Users', permission: 'user:view-sidebar', path: '/users' },
    { id: 'regions', name: 'Regions', permission: 'region:view-sidebar', path: '/regions' },
    { id: 'servers', name: 'Servers', permission: 'server:view-sidebar', path: '/servers' },
    { id: 'database', name: 'Databases', permission: 'database:view-sidebar', path: '/databases' },
    { id: 'websites', name: 'Websites', permission: 'website:view-sidebar', path: '/websites' },
    { id: 'reports', name: 'Reports', permission: 'reports:view-sidebar', path: '/reports' },
    { id: 'themes', name: 'Global Themes', permission: 'theme:view-sidebar', path: '/themes' },
    { id: 'plugins', name: 'Global Plugins', permission: 'plugin:view-sidebar', path: '/plugins' },
    { id: 'wordpress', name: 'WordPress', permission: 'wordpress:view-sidebar', path: '/wordpress' },
    { id: 'settings', name: 'Global Settings', permission: 'setting:view-sidebar', path: '/settings' },
    { id: 'dns_zones', name: 'DNS Zones', permission: 'dns-zone:view-sidebar', path: '/dns-zones' },
    { id: 'customers', name: 'Clients', permission: 'customer:view-sidebar', path: '/clients' },
    { id: 'notifications', name: 'Notifications', permission: 'notification:view-sidebar', path: '/notifications' },
    { id: 'billing', name: 'Billing', permission: 'billing:view-sidebar,invoice:read:*,payment-method:read:*', path: '/billing' },
    { id: 'tools', name: 'Tools', permission: 'tools:view-sidebar', path: '/tools' },
  ];

  return (
    <Container style={{ zIndex: 200 }} secondary={isSecondNav}>
      <Link to='/'>
        <Logo secondary={isSecondNav}>{isSecondNav ? <WPS_LOGO /> : <WPS_LOGO_FULL />}</Logo>
      </Link>
      <Scrollbars>
        <Nav secondary={isSecondNav}>
          {links.map((link, index) => (
            <Can
              key={link.id + index + '_can'}
              perform={link.permission}
              yes={() => (
                <li data-tip={link.name} data-for={link.id + index }>
                  <NavLink exact to={link.path}>
                    <Icon tag={link.id} />
                    <span>{link.name}</span>
                  </NavLink>
                </li>
              )}
            />
          ))}
          {isSecondNav && links.map((link,index) => (<ReactTooltip key={link.id + index} id={link.id + index} place='right'>{link.name}</ReactTooltip>))}
        </Nav>
      </Scrollbars>
    </Container>
  );
};

export default SideBar;
