import { createSelector } from 'reselect';
import ArrayHelper from 'helpers/array';
import WebsiteHelper from 'helpers/website';
import { isWebsiteBusy } from 'helpers';

/*
  Input
*/
const websites = state => state.websites;

/*
  Selectors
*/

// Return all websites for signed In user
export const websitesSelector = createSelector(websites, websites => websites);
export const liveWebsitesSelector = createSelector(websites, websites => websites.filter(website => website.is_live));

// Return selected website
export const activeWebsite = slug => {
  return createSelector(websites, websites => ArrayHelper.find(websites, 'slug', slug));
};

// Return websites by server slug
export const websitesByServer = serverSlug => {
  return createSelector(websites, websites => ArrayHelper.filterIn(websites, 'server_slug', serverSlug));
};

// Return an object with label and plugin slug for plugins multi-select dropdown
export const websitesListSelector = createSelector(websites, websites =>
  websites.map(item => {
    return { label: `${WebsiteHelper.getLabel(item)} (${item.default_domain})`, value: item.slug };
  })
);

// Return an array of children websites for a given website slug
export const websiteChildrenSelector = slug => {
  return createSelector(websites, websites => ArrayHelper.filterIn(websites, 'parent_slug', slug));
}

// Return a boolean if a website is busy
export const busyWebsites = createSelector(websites, websites =>
  websites.some(website => isWebsiteBusy(website))
);
