import { dispatchGetPromise, dispatchPostPromise, dispatchDeletePromise } from '../../helpers';
import BillingService from '../../services/billing';
import SubscriptionService from '../../services/subscription';
import PlanService from '../../services/plan';

/*
  Action Types
*/
export const BILLING_PLANS_SET = 'BILLING_PLANS_SET';
export const BILLING_SET_ALL = 'BILLING_SET_ALL';
export const BILLING_SUBSCRIPTION_SET_ALL = 'BILLING_SUBSCRIPTION_SET_ALL';
export const BILLING_INVOICE_SET_ALL = 'BILLING_INVOICE_SET_ALL';
export const BILLING_ADMIN_DETAILS_UPDATE = 'BILLING_ADMIN_DETAILS_UPDATE';
export const BILLING_PARTNER_DETAILS_UPDATE = 'BILLING_PARTNER_DETAILS_UPDATE';
export const BILLING_SUBSCRIPTION_CREATE = 'BILLING_SUBSCRIPTION_CREATE';
export const BILLING_SUBSCRIPTION_UPDATE = 'BILLING_SUBSCRIPTION_UPDATE';
export const BILLING_SUBSCRIPTION_ADMIN_UPDATE = 'BILLING_SUBSCRIPTION_ADMIN_UPDATE';
export const BILLING_SUBSCRIPTION_RENAME = 'BILLING_SUBSCRIPTION_RENAME';
export const BILLING_SUBSCRIPTION_CANCEL = 'BILLING_SUBSCRIPTION_CANCEL';
export const BILLING_SUBSCRIPTION_DELETE = 'BILLING_SUBSCRIPTION_DELETE';

export const BILLING_PLAN_CREATE = 'BILLING_PLAN_CREATE';
export const BILLING_PLAN_UPDATE = 'BILLING_PLAN_UPDATE';
export const BILLING_PLAN_DELETE = 'BILLING_PLAN_DELETE';

// payment method actions
export const BILLING_PAYMENT_METHOD_SETDEFAULT = 'BILLING_PAYMENT_METHOD_SETDEFAULT';
export const BILLING_PAYMENT_INTENTS_SET_ALL = 'BILLING_PAYMENT_INTENTS_SET_ALL';

/*
  Action Creators (Admin)
*/

// Create a new plan.
export const createPartnerPlan = data => {
  return dispatchPostPromise(PlanService.create(data), BILLING_PLAN_CREATE);
};

// Update a specified plan.
export const updatePlan = data => {
  return dispatchPostPromise(PlanService.update(data), BILLING_PLAN_UPDATE);
};

// Delete a specified plan.
export const deletePlan = plan => {
  const data = { plan_slug: plan.slug };
  return dispatchDeletePromise(PlanService.delete(data), BILLING_PLAN_DELETE, plan);
};

// Update platform details
export const savePlatformDetails = data => {
  return dispatchPostPromise(BillingService.savePlatformDetails(data), BILLING_ADMIN_DETAILS_UPDATE);
};

/*
  Action Creators (Partner)
*/

// Save business details
export const saveDetails = data => {
  return dispatchPostPromise(BillingService.save(data), BILLING_PARTNER_DETAILS_UPDATE);
};

// Fetch all susbcriptions
export const fetchSubscriptions = data => {
  return dispatchGetPromise(SubscriptionService.fetch(data), BILLING_SUBSCRIPTION_SET_ALL);
};

// Create new susbcription for admins only.
export const createSubscription = data => {
  return dispatchGetPromise(SubscriptionService.create(data), BILLING_SUBSCRIPTION_CREATE);
};

// Update selected subscription by guid
export const updatePartnerSubscription = data => {
  return dispatchPostPromise(
    SubscriptionService.update(data),
    BILLING_SUBSCRIPTION_ADMIN_UPDATE,
  );
};

// Cancel a partner susbcription
export const cancelPartnerSubscription = data => {
  return dispatchPostPromise(
    SubscriptionService.cancel(data),
    BILLING_SUBSCRIPTION_ADMIN_UPDATE,
  );
};

// UnCancel a partner susbcription
export const unCancelPartnerSubscription = data => {
  return dispatchPostPromise(
    SubscriptionService.uncancel(data),
    BILLING_SUBSCRIPTION_ADMIN_UPDATE,
  );
};

// Delete a partner subscirption
export const deletePartnerSubscription = data => {
  return dispatchDeletePromise(
    SubscriptionService.forceDelete(data),
    BILLING_SUBSCRIPTION_DELETE,
    data,
  );
};

// Create new subscription for the selected plan
export const newSubscription = data => {
  return dispatchPostPromise(BillingService.createSubscription(data), BILLING_SUBSCRIPTION_CREATE);
};

// Update selected subscription by guid
export const updateSubscription = data => {
  return dispatchPostPromise(BillingService.updateSubscription(data), BILLING_SUBSCRIPTION_UPDATE);
};

// Rename specified subscription by guid
export const renameSubscription = data => {
  return dispatchPostPromise(BillingService.updateSubscription(data), BILLING_SUBSCRIPTION_RENAME);
};

// Cancel a specified subscription
export const cancelSubscription = data => {
  return dispatchPostPromise(BillingService.cancelSubscription(data), BILLING_SUBSCRIPTION_CANCEL);
};

// Default Partner Payment Method
export const defaultPartnerPaymentMethod = data => {
  return dispatchPostPromise(
    BillingService.editPartnerPaymentMethod(data),
    BILLING_PAYMENT_METHOD_SETDEFAULT,
  );
};

// Add Partner Payment Method
export const addPartnerPaymentMethod = data => {
  return dispatchPostPromise(
    BillingService.addPartnerPaymentMethod(data));
};

// Edit Partner Payment Method
export const editPartnerPaymentMethod = data => {
  return dispatchPostPromise(BillingService.editPartnerPaymentMethod(data));
};

// Remove Partner Payment Method
export const deletePartnerPaymentMethod = data => {
  return dispatchDeletePromise(BillingService.deletePartnerPaymentMethod(data));
};

// List Partner Payment Methods
export const listPartnerPaymentMethods = data => {
  return dispatchGetPromise(BillingService.fetchPartnerPaymentMethods(data));
};

// List all inovices.
export const getAllInovices = data => {
  return dispatchGetPromise(BillingService.fetchPartnerInovices(data), BILLING_INVOICE_SET_ALL);
};

// List all Payment Intents.
export const fetchAdminPaymentIntents = (data,isCustomer) => {
  return dispatchGetPromise(BillingService.fetchAdminPaymentIntents(data,isCustomer), BILLING_PAYMENT_INTENTS_SET_ALL);
};
