import { postRequest } from 'config/axiosRequest';
import env from 'config/env';

const verifyEmail = data => {
  return postRequest({
    url: env.guestApiEndpoint('users/verify-email'),
    data,
  });
};

const passwordResetEmail = data => {
  return postRequest({
    url: env.guestApiEndpoint('users/send-password-reset-email'),
    data,
  });
};

const validateResetToken = data => {
  return postRequest({
    url: env.guestApiEndpoint('users/validate-password-reset-token'),
    data,
  });
};

const resendVerificationEmail = data => {
  return postRequest({
    url: env.guestApiEndpoint('users/send-verification-email'),
    data,
  });
};

const resetPassword = data => {
  return postRequest({
    url: env.guestApiEndpoint('users/reset-password'),
    data,
  });
};

const verifyProfileSetupToken = data => {
  return postRequest({
    url: env.guestApiEndpoint('customers/validate-profile-setup-request-token'),
    data,
  });
};

const processProfileSetupRequest = data => {
  return postRequest({
    url: env.guestApiEndpoint('customers/process-profile-setup-request'),
    data,
  });
};

const sendNewSecureLink = data => {
  return postRequest({
    url: env.guestApiEndpoint('customers/renew-profile-setup-request-token'),
    data,
  });
};

const GuestService = {
  verifyEmail,
  passwordResetEmail,
  validateResetToken,
  resetPassword,
  verifyProfileSetupToken,
  processProfileSetupRequest,
  resendVerificationEmail,
  sendNewSecureLink
};

export default GuestService;
