import {
  BILLING_SET_ALL,
  BILLING_PLANS_SET,
  BILLING_SUBSCRIPTION_CREATE,
  BILLING_SUBSCRIPTION_UPDATE,
  BILLING_SUBSCRIPTION_ADMIN_UPDATE,
  BILLING_SUBSCRIPTION_RENAME,
  BILLING_SUBSCRIPTION_CANCEL,
  BILLING_SUBSCRIPTION_SET_ALL,
  BILLING_PLAN_CREATE,
  BILLING_PLAN_UPDATE,
  BILLING_PLAN_DELETE,
  BILLING_SUBSCRIPTION_DELETE,
  BILLING_ADMIN_DETAILS_UPDATE,
  BILLING_PARTNER_DETAILS_UPDATE,
} from './billingActions';
import ArrayHelper from '../../helpers/array';

const billingReducer = (state = { subscriptions: [] }, action) => {
  switch (action.type) {
    case BILLING_SET_ALL:
      return { ...state, ...action.payload };

    case BILLING_PLANS_SET:
      return {
        ...state,
        pricing_plans: action.payload,
      };

    case BILLING_SUBSCRIPTION_CREATE:
      if (action.payload.partner_subscription) {
        return {
          ...state,
          subscriptions: [...state.subscriptions, action.payload.partner_subscription],
        };
      } else {
        return {
          ...state,
          subscriptions: [...state.subscriptions, action.payload],
        };
      }

    case BILLING_ADMIN_DETAILS_UPDATE:
      return {
        ...state,
        invoice_details: action.payload,
      };
    
    case BILLING_PARTNER_DETAILS_UPDATE:
      return {
        ...state,
        details: action.payload,
      };

    case BILLING_SUBSCRIPTION_UPDATE:
      const updatedSubscriptions = state.subscriptions.filter(
        subscription => subscription.guid !== action.payload.parent.guid,
      );
      return {
        ...state,
        subscriptions: [...updatedSubscriptions, action.payload.parent, action.payload],
      };

    case BILLING_SUBSCRIPTION_ADMIN_UPDATE:
      return {
        ...state,
        subscriptions: ArrayHelper.update(state.subscriptions, 'guid', action.payload),
      };

    case BILLING_SUBSCRIPTION_DELETE:
      return {
        ...state,
        subscriptions: ArrayHelper.filterOut(state.subscriptions, 'guid', action.payload.guid),
      };

    case BILLING_SUBSCRIPTION_RENAME:
      const newState = state.subscriptions.filter(
        subscription => subscription.guid !== action.payload.guid,
      );

      return {
        ...state,
        subscriptions: [...newState, action.payload],
      };

    case BILLING_SUBSCRIPTION_SET_ALL:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case BILLING_SUBSCRIPTION_CANCEL:
      const newSubscriptions = state.subscriptions.filter(
        subs => subs.guid !== action.payload.guid,
      );
      return {
        ...state,
        subscriptions: [...newSubscriptions, action.payload],
      };

    case BILLING_PLAN_CREATE:
      return {
        ...state,
        plans: [...state.plans, action.payload],
      };

    case BILLING_PLAN_UPDATE:
      const prevPlans = ArrayHelper.filterOut(state.plans, 'slug', action.payload.slug);
      return {
        ...state,
        plans: [...prevPlans, action.payload],
      };

    case BILLING_PLAN_DELETE:
      const newPlans = ArrayHelper.filterOut(state.plans, 'slug', action.payload.slug);
      return {
        ...state,
        plans: newPlans,
      };

    default:
      return state;
  }
};

export default billingReducer;
