import React, { useState, useEffect, Fragment } from 'react';
import Icon from 'components/layout/icon';
import { WPSButton, WPSBubble } from 'styles/layout/buttons';
import { WPSInput } from 'styles/layout/forms';
import Tooltip from 'components/layout/tooltip';
import StripeConnect from 'components/stripe/stripeconnect';
import StripeDisconnect from 'components/stripe/stripedisconnect';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import ReactTooltip from 'react-tooltip';

const AdvancedTabs = props => {
  const [currentLoading, setCurrentLoading] = useState(props.loading || false);
  const components = {
    Connect: StripeConnect,
    Disconnect: StripeDisconnect,
  };
  const CustomComponent = components[props.customComponentContent];

  const handleOnClick = e => {
    setCurrentLoading(true);
    props.onClick(e);
  };
  const handleOnSecondBtnClick = e => {
    setCurrentLoading(true);
    props.onSecondBtnClick(e);
  };

  useEffect(() => {
    setCurrentLoading(props.loading);
  }, [props.loading]);
  return (
    <div className={`elements-box ${props.name}-box ${props.customClass||''}`}>
      {props.icon && <div className='box-icon'>
        <Icon tag={props.icon} className='box-icon' />
      </div>}
      <div className='box-title'>{props.title}</div>
      {props.customComponentContent && <CustomComponent />}
      {props.desc && !props.customComponentContent && (
        <div className='description'>
          {props.descColor ? (
            <div>
              {props.descPrefix && <span className='desc-prefix'>{props.descPrefix}</span>}
              <span className='desc-main' style={{ color: props.descColor }}>
                {props.desc}
                {props.toolTipText && <Tooltip text={props.toolTipText} />}
              </span>
            </div>
          ) : (
            <div>
              {props.desc} {props.toolTipText && <Tooltip text={props.toolTipText} />}
            </div>
          )}
        </div>
      )}
      {!props.customComponentContent ? (
        props.type === 'selection' ? (
          <div className='select-parent'>
            {props.selectType && props.selectType === 'textBox' ? (
              <Fragment>
                <WPSInput
                  required
                  type='text'
                  placeholder={props._placeholder ? props._placeholder : ''}
                  style={{ width: '90%' }}
                  name={props.name}
                  value={props.inputValue ? props.inputValue : ''}
                  onChange={e => props.onChange(e)}
                />
              </Fragment>
            ) : (
              <WPSSelect
                selectClass='box-select'
                options={props.options}
                onChange={e => props.onChange(e)}
                value={props.value ? props.value.value : null}
              />
            )}
            <WPSButton
              loading={props.loading && currentLoading}
              disabled={
                (props.btnDisabled) ||
                (props.loading && currentLoading) ||
                (props.selectType === 'textBox' && props.inputValue.length === 0)
              }
              loadingColor='light'
              onClick={() => handleOnClick(props.name)}
              className='selection-box-btn'>
              {props.btnText ? props.btnText : 'Save'}
            </WPSButton>
          </div>
        ) : (props.type === 'status' ? (
          <div className='status-advanced-tab' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            {props.spinner && (
              <Fragment>
                <span className='loadingspinner'></span>
                <span className='loading-status-text'>{props.spinnerText}{props.spinnerWait && <span className='loading-wait-text'>The process can take a few minutes.</span>}</span>

              </Fragment>
            )}
            {!props.spinner && (
              <Fragment>
                <div className='status-data' style={{minWidth: '100%'}}>
                  <WPSBubble
                    className='status-icon'
                    display='inline'
                    color={props.statusColor || 'success'}
                    icon={props.statusColor || 'success'}
                    data-for="card_status"
                    data-tip={props.statusTooltip}></WPSBubble>
                  {props.statusTooltip && <ReactTooltip id="card_status" />}
                </div>
              </Fragment>
            )}
            <div className='display-flex action-buttons' style={{width: '100%'}}>
              {props.btnText && <WPSButton
                disabled={props.btnDisabled}
                loading={props.loading}
                onClick={() => handleOnClick(props.name)}
                className={`selection-box-btn status-btn ${!props.secondBtn?'margin-sides-auto':''}`}>
                {props.btnText}
              </WPSButton>}
              {props.secondBtn && (
                <WPSButton
                  loadingColor='light'
                  RingLoaderMargin={true}
                  loading={props.secondBtnLoading}
                  disabled={props.secondBtnLoading}
                  onClick={() => handleOnSecondBtnClick(props.secondBtn)}
                  className={`selection-box-second-btn status-btn${!props.btnText?' margin-sides-auto':''}`}>
                  {props.secondBtn}
                </WPSButton>
              )}
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {props.secondBtn && (
              <WPSButton
                loadingColor='light'
                RingLoaderMargin={true}
                loading={props.secondBtnLoading}
                disabled={props.secondBtnLoading}
                onClick={() => handleOnSecondBtnClick(props.secondBtn)}
                className='selection-box-second-btn'>
                {props.secondBtn}
              </WPSButton>
            )}
            {props.btnText && (
              <Fragment>
                {props.btnDisabledTip && props.btnDisabled ? (<Fragment>
                  <button
                    disabled={true}
                    className="selection-box-btn btn-disabled-tooltip-fix">
                    <div data-for={`btn-tooltip-${props.name}`} data-tip={props.btnDisabledTip} data-tip-disable={false}>
                      {props.btnText}
                    </div>
                  </button>
                  <ReactTooltip class='btn-disabled-tooltip' id={`btn-tooltip-${props.name}`} place='right'/>
                </Fragment>) : (
                <WPSButton
                  loading={props.loading && currentLoading}
                  disabled={props.btnDisabled || (props.loading && currentLoading)}
                  loadingColor='light'
                  onClick={() => handleOnClick(props.name)}
                  className={
                    props.secondBtn
                      ? 'selection-box-btn secondBtnCustom'
                      : `selection-box-btn ${props.firstBtnColor ? props.firstBtnColor : ''}`
                  }>
                  {props.btnText ? props.btnText : 'Actions'}
                </WPSButton>)}
              </Fragment>)}
          </div>
        ))
      ) : null}
    </div>
  );
};

export default AdvancedTabs;
