import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Can from 'utils/can';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import { usersSelector } from 'store/user/userSelectors';
import UserHelper from 'helpers/user';
import StringHelper from 'helpers/string';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg,
  setGlobalWarningMsg,
} from 'store/global/globalActions';
import useConfirm from 'hooks/useConfirm';
import UserService from 'services/user';
import { getAllUsers, deleteUser, setPartnerCustomWPAdminLoginUser, updateUserTwoFactorStatus } from 'store/user/userActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import { useForm } from 'react-hook-form';
import {exportCsvToFile, isEmptyOrNull} from 'helpers';

const Users = () => {
  useTitle('Users');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const users = useSelector(usersSelector);
  const [ loading, setLoading ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ modalLoading, setModalLoading ] = useState(false);
  const [ wpLoginUser, setWPLoginUser ] = useState(null);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  // Fetch all users. 
  const fetchUsers = () => {
    setLoading(true);
    const data = {};
    if (UserHelper.isAdminOrAgent()) {
      data.roles = ['admin', 'partner', 'agent', 'employee'];
    }
    dispatch(getAllUsers(data)).finally(() => setLoading(false));
  }

  const handleWPLoginUserUpdate = () => {
    setModalLoading(true);
    const data = { partner_slug: modal.slug, user_login: wpLoginUser };
    dispatch(setPartnerCustomWPAdminLoginUser(data))
      .then(() => {
        setModal(false);
        setModalLoading(false);
        dispatch(setGlobalSuccessMsg({ model: 'Login user', action: 'updated', forId: modal.email }));
      })
      .finally(() => setModalLoading(false));
  }

  const toggleTwoFactorStatus = (user) => {
    const data = { user_slug: user.slug, two_factor_enabled: !user.two_factor_enabled };
    dispatch(setGlobalPleaseWaitMsg({ model: 'Two-factor', action: 'updating', forId: user.email }));
    dispatch(updateUserTwoFactorStatus(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Two-factor', action: 'updated', forId: user.email })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  }

  const actions = [
    {
      value: 'Update',
      doHide: (item) => !UserHelper.hasPermissions(`user:update:${item.slug}`),
      onClick: user => {
        history.push({
          pathname: `users/${user.slug}/update`,
          state: user,
        });
      },
    },
    {
      value: 'Set WP Login User',
      doHide: (item) => !UserHelper.isAdminOrAgent() || item.role !== 'partner',
      onClick: user => {
        setModal(user);
        setWPLoginUser(user.custom_wp_admin_user);
      },
    },
    {
      value: 'Enable 2FA',
      onClick: user => toggleTwoFactorStatus(user),
      doHide: user =>   !UserHelper.hasPermissions(`user:update:${user.slug}`) ||
                        user.two_factor_enabled ||
                        (user.role === 'admin' && !UserHelper.isAdmin())
    },
    {
      value: 'Disable 2FA',
      onClick: user => toggleTwoFactorStatus(user),
      doHide: user =>   !UserHelper.hasPermissions(`user:update:${user.slug}`) ||
                        !user.two_factor_enabled ||
                        (user.role === 'admin' && !UserHelper.isAdmin())
    },
    {
      value: 'Activity',
      onClick: user => {
        history.push({
          pathname: `users/${user.slug}/activity`,
          state: user,
        });
      },
    },
    {
      value: 'Employees',
      doHide: user => !UserHelper.isAdminOrAgent() || user.role !== 'partner',
      onClick: user => {
        history.push({
          pathname: `users/${user.slug}/children`,
          state: user,
          search: '?role=employee'
        });
      },
    },
    {
      value: 'Resend Verification Email',
      doHide: user => user.verification_status === 'verified',
      onClick: user => {
        const data = { user_slug: user.slug };
        UserService.resendVerificationEmail(data)
          .then(() => dispatch(setGlobalSuccessMsg({ model: 'Verification email', action: 'sent', to: user.email })),)
          .catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      value: 'Send Password Reset Email',
      onClick: user => {
        const data = { user_slug: user.slug };
        UserService.sendPasswordResetEmail(data)
          .then(() =>
            dispatch(
              setGlobalSuccessMsg({
                model: 'Password reset email',
                action: 'sent',
                to: user.email,
              }),
            ),
          )
          .catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      value: 'Subscriptions',
      doHide: item => !UserHelper.isAdminOrAgent() || item.role !== 'partner',
      onClick: user => {
        history.push({
          pathname: `/billing/subscriptions`,
          search: `&filter=Subscriber:${user.display_name}`,
        });
      },
    },
    {
      value: 'Delete',
      doHide: (item) => !UserHelper.hasPermissions(`user:delete:${item.slug}`),
      onClick: user => DialogHelper
        .confirmDelete(confirm, user.email, 'user')
        .then(() => {
          dispatch(setGlobalPleaseWaitMsg({ id: user.email, model: 'user', action: 'deleted' }));
          dispatch(deleteUser(user))
            .then(() => dispatch(setGlobalSuccessMsg({ id: user.email, model: 'user', action: 'deleted' })))
            .catch(err => dispatch(setGlobalErrorMsg(err)))
        })
    },
  ];

  const headers = [
    {
      name: 'Name',
      selector: 'display_name',
      sortable: true,
      searchable: true,
      width: '30%',
      cell: (row) => {
        TableHelper.customizeCellValue(row, 'display_name', row.display_name + ' ' + row.email);
        return JsxHelper.createTableMultiLineCell({
          header: row.display_name,
          subheader: row.email,
        })
      }
    },
    UserHelper.isAdminOrAgent() && {
      name: 'Role',
      selector: 'role',
      width: '12%',
      sortable: true,
      searchable: true,
      cell: row => JsxHelper.createBubble({
        text: StringHelper.slugToText(row.role || ''),
        background: UserHelper.getRoleColor(row.role, row),
        customClass: 'margin-0',
        small: true,
      }),
    },
    UserHelper.isPartner() && {
      name: 'Role',
      selector: 'sub_role',
      sortable: true,
      searchable: true,
      width: '12%',
      cell: row => JsxHelper.createBubble({
        text: StringHelper.slugToText(row.sub_role || ''),
        background: UserHelper.getRoleColor(row.sub_role, row),
        customClass: 'margin-0',
        small: true,
      }),
    },
    {
      name: 'Status',
      selector: 'verification_status',
      sortable: true,
      searchable: true,
      width: '12%',
      cell: row => JsxHelper.createBubble({
        text: row.verification_status,
        sentence: true,
        background: row.verification_status === 'verified' ? 'success' : 'warning',
        customClass: 'margin-0',
        small: true,
      }),
    },
    UserHelper.isAdminOrAgent() && JsxHelper.createTableTimeHeader('last_login', null, '14%', 'Last Login', 'No login yet.'),
    {
      name: 'Two Factor',
      selector: 'two_factor_enabled',
      sortable: true,
      width: '12%',
      cell: row => JsxHelper.createBubble({
        text: row.two_factor_enabled ? 'Enabled' : 'Disabled',
        background: row.two_factor_enabled ? 'success' : 'warning',
        customClass: 'margin-0',
        small: true,
      }),
    },
    JsxHelper.createTableActionsHeader(actions, UserHelper.isAdminOrAgent() ? '20%' : '34%'),
  ].filter(Boolean);

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Users',
    },
  ];

  const exportCSVFile = () => {
    if (isEmptyOrNull(users)) {
      dispatch(setGlobalWarningMsg('You cannot export an empty table.'));
      return;
    }
    const _data = users.map(user => ({
      'Display Name': user.display_name,
      'First Name': user.first_name,
      'Last Name': user.last_name,
      'Email': user.email,
      'Role': user.role,
      'Last Login (UTC)': user.last_login || '',
      'Verification Status': user.verification_status,
    }));
    exportCsvToFile(_data, 'users');
  };

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          <div style={{ display: 'flex' }}>
            Users
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {UserHelper.isAdminOrAgent() && JsxHelper.createButton({
            label: 'Load All',
            onClick: fetchUsers,
            loading,
          })}
          {UserHelper.isAdminOrAgent() && JsxHelper.createButton({
            label: 'Export',
            onClick: exportCSVFile,
          })}
          <Can
            perform='user:create:*'
            yes={() => <Link to='/users/create'>{JsxHelper.createButton({ label: 'Add User' })}</Link>}
          />
        </TitleBar.Actions>
      </TitleBar>
      <Content className='main-users-table'>
        <WPSDataTable columns={headers} body={users} loading={loading} />
      </Content>
      {modal && DialogHelper.inputs({
        title: 'Set Default WP Login',
        icon: 'edit',
        iconColor: 'success',
        btnText: 'Edit',
        header: <span>The current WordPress login user for <b>{modal.display_name || modal.email}</b> is set to "<b>{modal.custom_wp_admin_user || modal.slug}</b>"{!modal.custom_wp_admin_user ? ' (default)' : ''}.
                  <br/><br/>
                  <div className='warning-box'>Note that changing this will <b>not affect any existing users</b>, but will be the default for new users.</div>
                </span>,
        onConfirm: handleSubmit(handleWPLoginUserUpdate),
        onClose: () => setModal(false),
        loading: modalLoading,
        register,
        inputs: [{
          name: 'user_login',
          label: 'Username',
          placeholder: `Enter new user login for ${modal.slug}`,
          value: wpLoginUser,
          onChange: (e) => setWPLoginUser(e.target.value),
          errors,
          required: true,
          minLength: 5,
        }]
      })}
    </Fragment>
  );
};

export default Users;
