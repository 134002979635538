import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { getRedisStats } from 'store/server/serverActions';
import { Content } from 'styles/globalStyles';
import { WPSButton } from 'styles/layout/buttons';
import { TitleBar } from 'styles/layout/titlebar';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { serverBySlug } from 'store/server/serverSelectors';
import JsxHelper from 'helpers/jsx';
import StringHelper from 'helpers/string';
import WebsiteHelper from 'helpers/website';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';

const ServerRedisStats = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { params } = useRouteMatch();
  const [tableData, setTableData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const server = useSelector(serverBySlug(params.slug));
  const mounted = useRef(true);
  const cardStyle = {marginBottom:'25px', justifyContent: 'center', fontSize:'12px'};

  const fetchData = () => {
    setLoading(true);
    const data = { 
      server_slug: server.slug
    }
    dispatch(getRedisStats(data))
      .then(res => {
        const statsData = res.stats;
        setCardsData(statsData.server)
        if (statsData && statsData.apps) {
          let appsData = [];
          Object.keys(statsData.apps).forEach(slug => {
            let row = statsData.apps[slug];
            row.slug = slug;
            appsData.push(row);
          });
          setTableData(appsData);
        }
      }).catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const headers = [
    JsxHelper.createTableTextHeaderWithCallback(
      'slug',
      'Website',
      '25%',
      (row) => <span className='class-link' onClick={() => WebsiteHelper.goTo(history, row.slug)}>{row.slug}</span>,
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'used_memory',
      'Used Memory',
      '20%',
      (row) => {
        const perc = ((row.used_memory / row.uncompressed_memory) * 100).toFixed(2);
        return <div>
          <span>{row.used_memory_human}</span><br/>
          <span className='font-italic wps-icon-small'>Unarchived: {row.uncompressed_memory_human} ({perc}%)</span>
        </div>
      },
    ),
    JsxHelper.createTableTextHeader('keys_count', 'Keys', '15%', '-', true),
    JsxHelper.createTableTextHeader('compression', 'Compression', '15%'),
    JsxHelper.createTableTextHeader('serializer', 'Serializer', '15%'),
    JsxHelper.createTableTextHeader('prefix', 'Prefix', '10%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: params.slug,
    },
  ];

  return (
    <Fragment>
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>Redis Statistics</TitleBar.Title>
          <TitleBar.Actions>
            <WPSButton
              className='refresh--btn'
              onClick={fetchData}
              disabled={loading}>
              Refresh
            </WPSButton>
            {JsxHelper.createBackButton()}
          </TitleBar.Actions>
        </TitleBar>
        <Content>
          {cardsData && !loading && JsxHelper.createAnalyticsCards({
            customStyle: cardStyle,
            items: [{
              title: 'System Memory',
              value: cardsData.total_system_memory_human,
            }, {
              title: 'Max Memory Allowed',
              value: `${cardsData.maxmemory_human} (${cardsData.maxmemory_policy})`,
            }, {
              title: 'Max Memory Policy',
              value: cardsData.maxmemory_policy,
            }, {
              title: 'Used Memory',
              value: cardsData.used_memory_human,
            }, {
              title: 'Used Memory Peak',
              value: `${cardsData.used_memory_peak_human} (${cardsData.used_memory_peak_perc})`,
            }, {
              title: 'Used Memory RSS',
              value: cardsData.used_memory_rss_human,
            }, {
              title: 'Used Memory Dataset',
              value: `${StringHelper.convertBytes(cardsData.used_memory_dataset)} (${cardsData.used_memory_dataset_perc})`,
            }, {
              title: 'Used Memory Scripts',
              value: cardsData.used_memory_scripts,
            }]
          })}
          <WPSDataTable
            dataKey='id'
            loading={loading}
            columns={headers}
            noSearchOnTable={false}
            body={tableData}
            rowsPerPage={100}
          />
        </Content>
    </Fragment>
  );
};

export default ServerRedisStats;
