import React, { useState, useEffect, Fragment } from 'react';
import useQuery from 'hooks/useQuery';
import { AuthContainer, ImageWrapper, FormWrapper } from 'styles/auth';
import EmailSuccess from 'assets/email_success.png';
import EmailFailed from 'assets/email_failed.png';
import { WPS_LOGO_FULL } from 'assets/wps_logo';
import { RingLoader } from 'styles/layout/loaders';
import { WPSButton } from 'styles/layout/buttons';
import { Link } from 'react-router-dom';
import GuestService from 'services/guest';
import useTitle from 'hooks/useTitle';
import { InlineLoader } from 'styles/layout/loaders';

const EmailVerification = () => {
  useTitle('Email Verification');
  const query = useQuery();

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resendLoading, setResendLoading] = useState(false)
  const [resentEmail, setResetEmail] = useState(false)

  useEffect(() => {
    const token = query.get('token');
    const fetchData = () => {
      const data = { token };
      GuestService.verifyEmail(data)
        .then(res => setResponse(res))
        .catch(err => setError(err.data))
        .finally(() => setLoading(false));
    };
    if (token) {
      fetchData();
    } else {
      setError({ error: 'Invalid token' });
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleResendVerificationEmail = e => {
    setResendLoading(true);
    const data = { email: query.get('email') };
    if (!data.email) {
      setError('Oops! Something is incorrect.');
      setResendLoading(false);
      return;
    }
    GuestService.resendVerificationEmail(data)
      .then(res => setResetEmail(res))
      .catch(err => setError(err.data))
      .finally(() => setResendLoading(false));
  };

  return (
    <AuthContainer>
      <ImageWrapper>
        <img src={response ? EmailSuccess : EmailFailed} alt='email verification' />
        <WPS_LOGO_FULL />
      </ImageWrapper>
      <FormWrapper>
        <WPS_LOGO_FULL inverted />
        {loading && (
          <Fragment>
            <h1>Verifying email...</h1>
            <RingLoader color='primary' size='40' margin='16px 0' />
          </Fragment>
        )}
        {response && (
          <Fragment>
            <h1>You are almost there!</h1>
            <p>Another email has been sent to you with the temporary login details.</p>
            <p>Please check your inbox before you continue to dashboard.</p>
            <Link to='/'>
              <WPSButton className='primary--btn'>Go to dashboard</WPSButton>
            </Link>
          </Fragment>
        )}
        {error && (
          <Fragment>
            <h1>{error.error}</h1>
            {!resentEmail && (
              <WPSButton className='info--btn' disabled={resendLoading} onClick={handleResendVerificationEmail}>
                {resendLoading ? <InlineLoader /> : 'Resend Verification Email'}
              </WPSButton>
            )}
            {resentEmail && (
              <div className='message success'>
                Check your email inbox and click the link in the email you received to verify your email.
              </div>
            )}
          </Fragment>
        )}
      </FormWrapper>
    </AuthContainer>
  );
};

export default EmailVerification;
