// Define Role based access control as 'Page:Action' pairs
// Static rules don't require any data

const makeRules = (actions, scope, types) => {
  // Support for arrays/string
  if (typeof actions === 'string' || actions instanceof String) {
    actions = actions.split(',')
  }
  let permissions = [];
  for (const type of types) {
    for (const action of actions) {
      const permission = `${type}:${action}:${scope}`;
      permissions.push(permission)
    }
  }
  return permissions;
}

const rules = {
  admin: {
    static:
      makeRules('view-sidebar', '*', [ 
        'dashboard',
        'user',
        'region',
        'server',
        'database',
        'website',
        'theme',
        'plugin',
        'billing',
        'email',
        'domain',
        'setting',
        'wordpress',
        'dns-zone',
        'reports',
        'tools',
        'legal',
        'plan',
        'notification',
      ]).concat(makeRules(['create', 'read', 'update', 'delete'], '*', [
        'website',
        'region',
        'server',
        'user',
        'plugin',
        'dns-zone',
        'theme',
        'wordpress',
        'partner-package',
        'plan-feature',
        'client-pdf-report',
        'payment-method',
      ]))
  },

  partner: {
    static:
      makeRules('view-sidebar', '*', [ 
        'dashboard',
        'billing',
        'user',
        'dns-zone',
        'website',
        'customer',
        'plugin',
        'theme',
        'setting',
        'tools',
        'reports',
        'legal',
      ]).concat(makeRules(['create', 'read', 'update', 'delete'], '*', [
        'website',
        'user',
        'dns-zone',
        'customer',
        'partner-package',
        'plan',
        'client-pdf-report',
        'payment-method',
      ]))
  },

  employee: {
    static:
      makeRules('view-sidebar', '*', [ 
        'dashboard',
        'website',
        'reports',
        'theme',
        'plugin',
        'dns-zone',
        'tools',
      ])
  },

  agent: {
    static:
      makeRules('view-sidebar', '*', [ 
        'dashboard',
        'user',
        'server',
        'database',
        'website',
        'theme',
        'plugin',
        'dns-zone',
        'reports',
        'plan',
        'notification',
        'tools',
      ]).concat(makeRules(['create', 'read'], '*', [
        'website',
        'plugin',
        'dns-zone',
        'theme',
        'wordpress',
        'partner-package',
        'client-pdf-report',
      ])).concat(makeRules(['update', 'delete'], '*', [
        'plugin',
        'theme',
        'wordpress',
        'partner-package',
        'client-pdf-report',
      ])).concat(makeRules(['update'], '*', [
        'website',
        'dns-zone',
      ]))
  },
};

export default rules;
