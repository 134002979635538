import { createSelector } from 'reselect';

/*
  Input
*/
const globalPackages = state => state.global_packages;

/*
  Theme Selectors
*/
export const globalThemesSelector = createSelector(globalPackages, packages =>
  packages.filter(item => item.type === 'theme'),
);

/*
  Plugin Selectors
*/
export const globalPluginsSelector = createSelector(globalPackages, packages =>
  packages.filter(item => item.type === 'plugin'),
);

export const globalPluginsListSelector = createSelector(globalPluginsSelector, plugins =>
  plugins
    .filter(p => !p.must_install)
    .map(item => {
      return {
        label: `${item.display_name} (v${item.version})`,
        value: item.slug,
        key: item.folder_name,
      };
    }),
);

/*
  Core Selectors
*/
export const globalCoresSelector = createSelector(globalPackages, packages =>
  packages.filter(item => item.type === 'core'),
);
