import StringHelper from './string';

const setQueryParam = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  } else {
    var hash =  '';
    if( uri.indexOf('#') !== -1 ){
        hash = uri.replace(/.*#/, '#');
        uri = uri.replace(/#.*/, '');
    }
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    return uri + separator + key + "=" + value + hash;
  }
}

const getQueryParam = (key, defaultValue) => {
  defaultValue = defaultValue || null;
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key) || defaultValue;
}

const areEquals = (url1, url2) => {
  return StringHelper.trimRight(url1, '.') === StringHelper.trimRight(url2, '.');
}

const open = (url) => window.open(url, '_blank')

const UrlHelper = { setQueryParam, getQueryParam, areEquals, open };

export default UrlHelper;
