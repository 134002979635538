import React from 'react';
import { Route } from 'react-router-dom';
import UserHelper from 'helpers/user';

import AdminPlanCreate from 'pages/billing/admin/createPlan';
import AdminPlanUpdate from 'pages/billing/admin/updatePlan';
import DetailsPartner from 'pages/billing/partner/details';
import PaymentPartner from 'pages/billing/payment';
import PlanPartner from 'pages/billing/partner/plan';
import SubscriptionPartner from 'pages/billing/partner/subscription';
import BillingSetup from 'pages/billing/billingSetup';
import DetailsAdmin from 'pages/billing/admin/details';
import PlanAdmin from 'pages/billing/admin/plan';
import SubscriptionAdmin from 'pages/billing/admin/subscription';

import CustomerBillingSetup from 'pages/customer/customerBillingSetup';
import Invoice from 'pages/billing/invoice';
import PaymentIntents from 'pages/billing/paymentIntents';
import SubscriptionUsage from 'pages/subscription/subscriptionUsage';

import PartnersUsage from 'pages/reports/admin/partners-usage';
import FirewallReport from 'pages/reports/admin/firewall';
import WPVulnerabilityReport from 'pages/reports/admin/wp-vulnerability';
import WebsiteIssuesReport from 'pages/reports/partner/website-issues';
import VulnerablePackagesReport from 'pages/reports/common/vulnerable-packages';
import PackageUpdatesHistoryReport from 'pages/reports/common/package-updates-history';
import AvailablePackageUpdatesReport from 'pages/reports/common/available-package-updates';
import ClientReports from 'pages/reports/common/client-reports';
import BusinessPerformance from 'pages/reports/common/business-performance';
import SubscriptionsHighUsage from 'pages/reports/common/subscriptions-high-usage';

import ActivityLogs from 'pages/tool/ActivityLogs/ActivityLogs';
import Script from 'pages/tool/script';

const CustomRoutes = () => {
  return (
    <>
      {UserHelper.isAdmin() ? (
        <>
          <Route exact path='/billing/details'>
            <DetailsAdmin />
          </Route>
          <Route exact path='/billing/invoices'>
            <Invoice />
          </Route>
          <Route exact path='/billing/subscriptions'>
            <SubscriptionAdmin />
          </Route>
          <Route exact path='/billing/plans'>
            <PlanAdmin />
          </Route>
          <Route exact path={'/billing/plans/:planSlug/update'}>
            <AdminPlanUpdate />
          </Route>
          <Route exact path={'/billing/plans/create'}>
            <AdminPlanCreate />
          </Route>
          <Route exact path='/billing/payment-intents'>
            <PaymentIntents />
          </Route>
          <Route exact path='/billing/subscriptions/:subscriptionName/usage'>
            <SubscriptionUsage type='partner' />
          </Route>
          <Route exact path='/reports/firewall'>
            <FirewallReport />
          </Route>
          <Route exact path='/reports/wp-vulnerability'>
            <WPVulnerabilityReport />
          </Route>
          <Route exact path='/reports/package-updates-history'>
            <PackageUpdatesHistoryReport />
          </Route>
          <Route exact path='/reports/vulnerable-packages'>
            <VulnerablePackagesReport />
          </Route>
          <Route exact path='/reports/available-package-updates'>
            <AvailablePackageUpdatesReport />
          </Route>
          <Route exact path='/reports/client-reports'>
            <ClientReports />
          </Route>
          <Route exact path='/reports/business-performance'>
            <BusinessPerformance />
          </Route>
          <Route exact path='/reports/partners-usage'>
            <PartnersUsage />
          </Route>
          <Route exact path='/tools/activity-logs'>
            <ActivityLogs />
          </Route>
          <Route exact path='/tools/run-shell-script'>
            <Script />
          </Route>
          <Route exact path='/reports/subscriptions-high-usage'>
            <SubscriptionsHighUsage />
          </Route>
        </>
      ) : UserHelper.isPartner() ? (
        <>
          <Route exact path='/billing/details'>
            <DetailsPartner />
          </Route>
          <Route exact path='/billing/invoices'>
            <Invoice />
          </Route>
          <Route exact path='/billing/subscriptions'>
            <SubscriptionPartner />
          </Route>
          <Route exact path='/billing/subscriptions/:subscriptionName/usage'>
            <SubscriptionUsage type='partner' />
          </Route>
          <Route exact path='/billing/plans'>
            <PlanPartner />
          </Route>
          <Route exact path='/billing/payment'>
            <PaymentPartner />
          </Route>
          <Route exact path='/billing/payment-intents'>
            <PaymentIntents />
          </Route>
          <Route exact path='/billing-setup'>
            <BillingSetup />
          </Route>
          <Route exact path='/client-billing-setup'>
            <CustomerBillingSetup />
          </Route>
          <Route exact path='/reports/website-issues'>
            <WebsiteIssuesReport />
          </Route>
          <Route exact path='/reports/package-updates-history'>
            <PackageUpdatesHistoryReport />
          </Route>
          <Route exact path='/reports/vulnerable-packages'>
            <VulnerablePackagesReport />
          </Route>
          <Route exact path='/reports/available-package-updates'>
            <AvailablePackageUpdatesReport />
          </Route>
          <Route exact path='/reports/client-reports'>
            <ClientReports />
          </Route>
          <Route exact path='/reports/business-performance'>
            <BusinessPerformance />
          </Route>
          <Route exact path='/reports/subscriptions-high-usage'>
            <SubscriptionsHighUsage />
          </Route>
        </>
      ) : UserHelper.isEmployee() ? (
        <>
          <Route exact path='/billing/invoices'>
            <Invoice />
          </Route>
          <Route exact path='/billing/payment'>
            <PaymentPartner />
          </Route>
          <Route exact path='/reports/package-updates-history'>
            <PackageUpdatesHistoryReport />
          </Route>
          <Route exact path='/reports/vulnerable-packages'>
            <VulnerablePackagesReport />
          </Route>
          <Route exact path='/reports/available-package-updates'>
            <AvailablePackageUpdatesReport />
          </Route>
          <Route exact path='/reports/website-issues'>
            <WebsiteIssuesReport />
          </Route>
          <Route exact path='/reports/client-reports'>
            <ClientReports />
          </Route>
        </>
      ) : UserHelper.isAgent() ? (
        <>
          <Route exact path='/billing/subscriptions/:subscriptionName/usage'>
            <SubscriptionUsage type='partner' />
          </Route>
          <Route exact path='/reports/firewall'>
            <FirewallReport />
          </Route>
          <Route exact path='/reports/wp-vulnerability'>
            <WPVulnerabilityReport />
          </Route>
          <Route exact path='/reports/package-updates-history'>
            <PackageUpdatesHistoryReport />
          </Route>
          <Route exact path='/reports/vulnerable-packages'>
            <VulnerablePackagesReport />
          </Route>
          <Route exact path='/reports/available-package-updates'>
            <AvailablePackageUpdatesReport />
          </Route>
          <Route exact path='/reports/client-reports'>
            <ClientReports />
          </Route>
          <Route exact path='/reports/subscriptions-high-usage'>
            <SubscriptionsHighUsage />
          </Route>
        </>
      ) : null}
    </>
  );
};

export default CustomRoutes;
