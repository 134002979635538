import { createSelector } from 'reselect';

/*
  Input
*/
const users = state => state.users || [];
const partners = state => state.users.filter(user => user.role === 'partner');

/*
  Selectors
*/
export const usersSelector = createSelector(users, users => users);

export const usersByResource = (type, scope) => {
  return createSelector(users, users => {
    let usersByType = [];
    users.forEach(u => {
      const found = u.permissions && u.permissions.some(p => (p.type === type && p.scope === '*') || p.scope === scope);
      if (found) {
        usersByType.push(u);
      }
    });
    return usersByType;
  });
};

export const partnersSelector = createSelector(partners, partners => partners);

export const getPartnerBySlug = slug =>
  createSelector(partners, partners => partners.filter(partner => partner.slug === slug)[0]);
