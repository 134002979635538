import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { updateWebsiteHideLoginConfig } from 'store/website/websiteActions';
import { WPSForm } from 'styles/layout/forms';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import { Container } from 'styles/website/profile';
import WebsiteHelper from 'helpers/website';
import JsxHelper from 'helpers/jsx';
import useTitle from 'hooks/useTitle';
import env from 'config/env';

const HideLogin = ({ website }) => {
  useTitle('Website Hide Login');
  const dispatch = useDispatch();
  const current = website ? WebsiteHelper.getHideLoginConfig(website) : {};
  const [details, setDetails] = useState(current);
  const [loading, setLoading] = useState(false);
  const [showResetButton, setShowResetButton] = useState(true);
  const INPUT_OPTIONS = WebsiteHelper.getHideLoginConfigOptions();

  const shouldDisplayOption = (item) => {
    if (item.name === 'invalid_login_slug') {
      let value = details.invalid_login_behaviour;
      if (!value) {
        value = INPUT_OPTIONS.find(item => item.name === 'invalid_login_behaviour').default;
      }
      return value === 'redirect';
    }
    return !item.hide;
  }

  const onChange = (e, name) => {
    const value = e.target ? e.target.value : e;
    setDetails({ ...details, [name]: value });
    setShowResetButton(true);
  }

  const resetDefaults = () => {
    const newDetails = {};
    INPUT_OPTIONS.forEach(item => {
      newDetails[item.name] = item.default;
    });
    setDetails(newDetails);
    setShowResetButton(false);
  }

  const onSubmit = async () => {
    setLoading(true);
    const config = {};
    INPUT_OPTIONS.forEach(item => {
      if (item.name === 'invalid_login_slug' && details.invalid_login_behaviour === 'deny') {
        config[item.name] = 'wpstaq.deny';
      } else {
        config[item.name] = details[item.name] || item.default;
      }
    });
    const data = { ...config, website_slug: website.slug };
    dispatch(updateWebsiteHideLoginConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'hide login config', forId: WebsiteHelper.getLabel(website), action: 'updated' })))
      .finally(() => setLoading(false));
  };

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Hide Login Page</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
      {env.getBrandShortName()} automatically renames the wp-login.php file for improved security. For further protection, you can configure additional settings below to hide the wp-admin directory and establish a custom redirect for unauthorized login attempts, enhancing your WordPress site's security.
      </p>
      <Content>
        <div style={{ maxWidth: '500px', marginTop: '20px' }}> {
          INPUT_OPTIONS.filter(shouldDisplayOption).map((item, index) =>
            <WPSForm.RowItem key={index} margin='0px'>
              {(item.options || item.type === 'select') && JsxHelper.createSelectInput({
                label: item.title,
                tooltip: item.desc,
                sortOff: true,
                options: item.options,
                class: 'secondary-box-select',
                value: details[item.name] || item.default,
                onChange: (e) => onChange(e, item.name),
              })}
              {item.type === 'text' && JsxHelper.createTextInput({
                type: 'text',
                class: 'secondary-box-select',
                label: item.title,
                placeholder: item.placeholder,
                tooltip: item.desc,
                value: details[item.name] || item.default,
                onChange: (e) => onChange(e, item.name),
              }, index)}
            </WPSForm.RowItem>
          )}
        </div>
        <div className='action-buttons display-flex-nowrap margin-bottom-30' style={{display: 'flex'}}>
          {JsxHelper.createButton({
            label: 'Save',
            loading,
            onClick: onSubmit,
          })}
          {showResetButton ? JsxHelper.createButton({
            label: 'Reset',
            onClick: resetDefaults,
            classes: 'warning--btn',
          }) : null}
        </div>
      </Content>
    </Container>
  );
};

export default HideLogin;
