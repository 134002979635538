import React from 'react';
import ContentLoader from 'react-content-loader';

const PackageDataLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={184}
    viewBox='0 0 400 160'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'>
    <rect x='0' y='1' rx='5' ry='5' width='60' height='10' />
    <rect x='0' y='31' rx='5' ry='5' width='60' height='10' />
    <rect x='0' y='61' rx='5' ry='5' width='60' height='10' />
    <rect x='0' y='91' rx='5' ry='5' width='60' height='10' />
    <rect x='0' y='121' rx='5' ry='5' width='60' height='10' />
    <rect x='0' y='151' rx='5' ry='5' width='60' height='10' />
    <rect x='80' y='1' rx='5' ry='5' width='200' height='10' />
    <rect x='80' y='31' rx='5' ry='5' width='200' height='10' />
    <rect x='80' y='61' rx='5' ry='5' width='200' height='10' />
    <rect x='80' y='91' rx='5' ry='5' width='200' height='10' />
    <rect x='80' y='121' rx='5' ry='5' width='200' height='10' />
    <rect x='80' y='151' rx='5' ry='5' width='200' height='10' />
  </ContentLoader>
);

export default PackageDataLoader;
