import React, { Fragment } from 'react';
import { useState } from 'react';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg
} from 'store/global/globalActions';
import { WPSBubble } from 'styles/layout/buttons';
import WebsiteService from 'services/website';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableActions from 'components/wpstaq/WPSDataTable/WPSTableActions';
import FirewallHelper from 'helpers/firewall';
import DateHelper from 'helpers/date';
import ReactTooltip from 'react-tooltip';
import env from 'config/env';
import UserHelper from 'helpers/user';
import WebsiteHelper from 'helpers/website';

const MUPluginBannedIPs = ({ dispatch, setTableData, tableData, loading, website, rowsPerPage }) => {
  const [lockedIPs, setLockedIPs] = useState([]);
  
  const websiteFirewallRemoveBan = (row, whitelist) => {
    const data = {
      website_slug: website.slug,
      ip_address: row.ip_address,
      reason: `Unbanned by ${UserHelper.getEmail()} via ${env.getBrandShortName()} dashboard.`,
      whitelist: whitelist,
    };
    if (lockedIPs.includes(row.ip_address)) {
      dispatch(setGlobalPleaseWaitMsg(`The IP ${row.ip_address} is already being processed.`))
      return;
    };
    setLockedIPs(prev => [...prev, row.ip_address]);
    const action = whitelist ? 'removed and whitelisted' : 'removed';
    dispatch(setGlobalPleaseWaitMsg({ id: row.ip_address, model: 'IP address', action, onId: WebsiteHelper.getLabel(website) }));
    WebsiteService.removeBannedIP(data)
    .then(() => {
      dispatch(setGlobalSuccessMsg({ id: row.ip_address, model: 'IP address', action, onId: WebsiteHelper.getLabel(website) }));
      setTableData(prev => prev.map(item => {
        if (item.ip_address === row.ip_address) {
          item.unbanned_at = DateHelper.toUTCFormat();
          item.unban_reason = data.reason;
        }
        return item;
      }));
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => {
      setLockedIPs(prev => prev.filter(item => item !== row.ip_address))
    });
  }

  const actions = [
    {
      value: 'Remove & Whitelist',
      onClick: row => websiteFirewallRemoveBan(row, true),
      doHide: row => !!row.unbanned_at
    },
    {
      value: 'Remove Only',
      onClick: row => websiteFirewallRemoveBan(row, false),
      doHide: row => !!row.unbanned_at
    },
  ];

  const headers = [
    {
      name: 'IP',
      selector: 'ip_address',
      searchable: true,
      width: '12%',
    },
    {
      name: 'Origin',
      selector: 'country',
      width: '6%',
      style: {
        paddingLeft: '5px !important',
      },
      cell: row => FirewallHelper.displayCountryFlag(row),
    },
    {
      name: 'Code',
      selector: 'code',
      width: '14%',
      cell: row => {
        return <Fragment>
          <WPSBubble
            style={{margin: '4px 0 0 3px'}}
            display='inline'
            padding='0'
            margin='0'
            color='info'
            data-for={row.id}
            data-tip={row.pretty_reason}>
            {row.code}
          </WPSBubble>
          <ReactTooltip id={row.id} />
        </Fragment>
      }
    },
    {
      name: 'Hits',
      selector: 'hits',
      width: '7%',
      style: {
        paddingLeft: '10px !important',
      },
    },
    {
      name: 'Status',
      selector: 'status',
      searchable: true,
      width: '11%',
      cell: row => FirewallHelper.displayRowStatus(row, 'website')
    },
    {
      name: 'Ban Time',
      selector: 'banned_at',
      searchable: true,
      width: '16%',
      cell: row => DateHelper.toHumanDateTime(row.banned_at)
    },
    {
      name: 'Unban Time',
      selector: 'unbanned_at',
      searchable: true,
      width: '16%',
      cell: row => {
        if (!row.unbanned_at && !row.expires_at) {
          return 'Never';
        }
        return DateHelper.toHumanDateTime(row.unbanned_at || row.expires_at)
      }
    },
    {
      name: '',
      width: '18%',
      button: true,
      cell: row => {
        return <TableActions
          actions={actions}
          item={row}
          customStyle={{ maxWidth: '200px', minWidth: 'unset' }}
        />;
      },
    },
  ];


  return (
    <Fragment>
      <WPSDataTable
        dataKey='id'
        loading={loading}
        columns={headers}
        body={tableData}
        rowsPerPage={rowsPerPage}
      />
    </Fragment>
  );
};

export default MUPluginBannedIPs;
