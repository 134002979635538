import React, { useState } from 'react';
import { NotificationsList, NotificationItem } from 'styles/layout/notifications';
import { useRef } from 'react';
import useOnClickOutside from 'utils/useOnClickOutside';
import { useSelector } from 'react-redux';
import { notificationsSelector } from 'store/global/globalSelectors';
import { isEmpty } from 'helpers';
import Icon from './icon';

const Notifications = () => {
  const [open, setOpen] = useState(false);

  const notifications = useSelector(notificationsSelector);

  // Handle on Click outside menu
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));
  return (
    <NotificationsList onClick={() => setOpen(true)}>
      <Icon tag='bell' />
      {open && (
        <ul ref={ref} className='custom-scroll'>
          {!isEmpty(notifications) ? (
            notifications.map(n => (
              <NotificationItem key={n.slug} color={n.color} text={n.text} time={n.time} />
            ))
          ) : (
            <NotificationItem noItems />
          )}
        </ul>
      )}
    </NotificationsList>
  );
};

export default Notifications;
