import { postRequest, getRequest } from 'config/axiosRequest';
import env from 'config/env';

// Run EWS test scripts
const runEWSTest = data => {
  return postRequest({
    url: env.buildApiEndpoint('tools/ews-tests'),
    data,
  });
};

// Run ERM test scripts
const runERMTest = data => {
  return postRequest({
    url: env.buildApiEndpoint('tools/erm-tests'),
    data,
  });
};

// Run EGM test scripts
const runEGMTest = data => {
  return postRequest({
    url: env.buildApiEndpoint('tools/egm-tests'),
    data,
  });
};

// Run a script on EWS
const runEWSScript = data => {
  return postRequest({
    url: env.buildApiEndpoint('tools/ews-script'),
    data,
  });
};

// Run a script on EWS
const runERMScript = data => {
  return postRequest({
    url: env.buildApiEndpoint('tools/erm-script'),
    data,
  });
};

// Run a script on EWS
const runEGMScript = data => {
  return postRequest({
    url: env.buildApiEndpoint('tools/egm-script'),
    data,
  });
};

// Get Activity Logs
const getActivityLogs = data => {
  return getRequest({
    url: env.buildApiEndpoint('tools/activity-logs'),
    data,
  });
};

const ToolService = {
  runEWSTest,
  runERMTest,
  runEGMTest,
  runEWSScript,
  runERMScript,
  runEGMScript,
  getActivityLogs
};

export default ToolService;
