import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import UserHelper from 'helpers/user';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import env from 'config/env'

const BillingEmployee = () => {
  const history = useHistory();

  const employeeCards = [
    UserHelper.hasPermissions(`invoice:read:*`) ? {
      title: 'Invoices',
      name: 'invoices',
      desc: 'See invoices generated periodically from your subscriptions.',
      icon: 'invoice',
      btnText: 'View',
    } : null,
    UserHelper.hasPermissions(`payment-method:read:*`) ? {
      title: 'Payment Methods',
      name: 'payment',
      desc: `Manage the credit cards used to charge your invoices by ${env.getBrandShortName()}.`,
      icon: 'billing',
      btnText: 'Manage',
    } : null,
  ].filter(Boolean);
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
    },
  ];

  const handleOnClick = e => {
    if (e) {
      history.push({
        pathname: `/billing/${e}`,
      });
    }
  };
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Billing</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {employeeCards.map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              btnDisabled={el.btnDisabled}
              icon={el.icon}
              title={el.title}
              btnText={el.btnText}
              onClick={e => handleOnClick(e)}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default BillingEmployee;
