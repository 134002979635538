import React, { Fragment, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { subscriptionsSelector } from 'store/billing/billingSelectors';
import { cancelSubscription, renameSubscription, fetchSubscriptions } from 'store/billing/billingActions';
import { useLocation, useHistory } from 'react-router-dom';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import useConfirm from 'hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { isEmpty } from 'helpers';
import { WPSButton } from 'styles/layout/buttons';
import { handleFilterURLParamsChanges, convertToFilterObject } from 'helpers';
import useOnClickOutside from 'utils/useOnClickOutside';
import useQuery from 'hooks/useQuery';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import PlanHelper from 'helpers/plan';
import JsxHelper from 'helpers/jsx';
import StringHelper from 'helpers/string';
import DialogHelper from 'helpers/dialog';

const Subscription = () => {
  useTitle('Subscriptions');
  const dispatch = useDispatch();
  const location = useLocation();
  const confirm = useConfirm();
  const query = useQuery();
  const history = useHistory();
  const [filteredSubscriptions, setFilteredSubscriptions] = useState();
  const [subscription, setSubscription] = useState({ name: '', guid: '', plan_slug: '' });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resourcesTableData, setResourcesTableData] = useState(null);
  const [selectedResourceItem, setSelectedResourceItem] = useState('');

  const allSubscriptions = useSelector(subscriptionsSelector);

  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  // Handle on Click outside for the filters menu
  const filtersRef = useRef();
  useOnClickOutside(filtersRef, () => setShowFilters(false));
  
  useEffect(() => {
    setLoading(true);
    const data = { subscriber_type: 'partner' };
    dispatch(fetchSubscriptions(data))
      .then(() => setLoading(false))
      .catch(err => dispatch(setGlobalErrorMsg(err)));

    // eslint-disable-next-line
  }, []);
  // Change subscriptions list after a state update
  useEffect(() => {
    setFilteredSubscriptions(allSubscriptions);
    if (!isEmpty(allSubscriptions)) {
      // Filter options
      const planNames = allSubscriptions.map(p => p.plan_name);
      const status = allSubscriptions.map(p => StringHelper.toText(p.status));
      setFilters(prev => ({
        ...prev,
        ...convertToFilterObject(query, 'Plan', new Set(planNames)),
        ...convertToFilterObject(query, 'Status', new Set(status)),
      }));
    }
    // eslint-disable-next-line
  }, [allSubscriptions]);

  // Get the filtered subscriptions list by query params
  useEffect(() => {
    if (location.state) {
      const subscriptions = allSubscriptions.filter(s => s.plan_subcategory_slug === location.state.plan);
      setFilteredSubscriptions(subscriptions);
    } else {
      setFilteredSubscriptions(allSubscriptions);
    }
    // eslint-disable-next-line
  }, [location]);

  // Handle filters add/remove from URL.
  useEffect(() => {
    handleFilterURLParamsChanges(filters, history);
    // eslint-disable-next-line
  }, [filters]);

  // Handle subscription cancellation
  const handleSubscriptionCancel = subscription => {
    const data = { guid: subscription.guid };
    dispatch(cancelSubscription(data));
  };

  // Handle rename modal submit
  const handleOnConfirm = () => {
    setLoading(true);
    dispatch(renameSubscription(subscription))
      .then(() => {
        setModal(false);
        setLoading(false);
        dispatch(setGlobalSuccessMsg({ model: 'subscription', action: 'renamed' }));
      })
      .catch(err => {
        setLoading(false);
        dispatch(setGlobalErrorMsg(err));
      });
  };

  const handleSubscriptionRename = item => {
    setModal(true);
    setSubscription(prev => ({ ...prev, plan_slug: item.plan_slug, guid: item.guid }));
  };

  const actions = [
    {
      value: 'Usage History',
      onClick: item => {
        history.push({
          pathname: `/billing/subscriptions/${item.guid}/usage`,
          search: `?guid=${item.guid}&from=${item.usage_start_date}`,
        });
      },
    },
    {
      value: 'Resources',
      doHide: item => isEmpty(item.resources),
      onClick: item => {
        if (!isEmpty(item.resources)) {
          setSelectedResourceItem(item);
          setResourcesTableData([...item.resources]);
        }
      },
    },
    {
      value: 'Cancel',
      doHide: () => true,
      onClick: item => DialogHelper
        .confirmAction(confirm, 'cancel', item.name, 'subscription')
        .then(() => handleSubscriptionCancel(item))
    },
    {
      value: 'Rename',
      onClick: handleSubscriptionRename,
    },
  ];

  const headers = [
    JsxHelper.createTableTitleHeader('name', 'Subscription', '25%', 'plan_name'),
    JsxHelper.createTableStatusHeader('status', PlanHelper.getSubscriptionStatusColor, true),
    JsxHelper.createTablePeriodHeader('starts_at', 'ends_at', 'Billing Period'),
    JsxHelper.createTableBinaryBubbleHeader('auto_renew', 'Auto Renew', '10%'),
    JsxHelper.createTableHiddenHeader('plan_name', 'Plan'),
    JsxHelper.createTableActionsHeader(actions, '35%'),
  ];

  const onChange = e => {
    const { value } = e.target;
    setSubscription(prev => ({ ...prev, name: value }));
  };

  const onBackButtonClick = () => {
    setResourcesTableData(null);
    setSelectedResourceItem('');
  };

  // Update state when changing a filter checked state.
  const onClickFilter = e => {
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
      link: '/billing',
    },
    {
      text: 'Subscriptions',
    },
  ];

  const resourcesBreadcrumb = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
      link: '/billing/subscriptions',
    },
    {
      text: 'Subscriptions',
      link: '#',
    },
  ];

  const resourcesTableHeaders = [
    JsxHelper.createTableTitleHeader('slug', 'Resource', '25%', 'type'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={resourcesTableData ? resourcesBreadcrumb : breadcrumbs}>
          {resourcesTableData ? selectedResourceItem.plan_name : 'Subscriptions'}
        </TitleBar.Title>
        <TitleBar.Actions>
          {!resourcesTableData ? JsxHelper.createFilterIcon({
            setShowFilters,
            filters,
            showFilters,
            onClickFilter,
            filtersRef,
          }) : null}
          {resourcesTableData ? (
            <WPSButton
              className='back--btn' 
              style={{ margin: '0 5px 22px auto' }}
              onClick={onBackButtonClick}>
              Back
            </WPSButton>
          ) : JsxHelper.createBackButton('/billing')}
        </TitleBar.Actions>
      </TitleBar>

      <Content>
        {resourcesTableData ? (
          <Fragment>
            <WPSDataTable
              columns={resourcesTableHeaders}
              body={resourcesTableData}
              noSearchOnTable={true}
              rowsPerPage={100}
            />
          </Fragment>
        ) : (
          <WPSDataTable
            columns={headers}
            body={PlanHelper.sortActiveFirst(filteredSubscriptions) || []}
            loading={loading}
            dataKey='guid'
          />
        )}
        {modal && DialogHelper.inputs({
          title: 'Rename subscription',
          onClose: () => setModal(false),
          onConfirm: handleOnConfirm,
          confirmBtn: 'Rename',
          loading,
          inputs: [{
            label: 'Subscription name',
            name: 'name',
            value: subscription.name,
            type: 'text',
            required: true,
            onChange,
          }]
        })}
      </Content>
    </Fragment>
  );
};

export default Subscription;
