import store from '../store';
import DateHelper from 'helpers/date'

// Form validation default messages
const messages = {
  required: 'Required field',
  allRequired: 'All fields required',
  minLength: length => `Minimum length of ${length} characters`,
  minNumber: number => `Minimum value of ${number}`,
  maxNumber: number => `Maximum value of ${number}`,
  invalidDomain: 'Please enter a valid domain name',
  invalidExpression: 'Invalid cron syntax',
  invalidWPOption: 'Can only contain letters, numbers, "_" and "-"',
  invalidWPDefine: 'Can only contain uppercase letters, numbers and "_"',
};

const createSelectOption = label => ({
  label,
  value: label,
});

// Return regex object.
const regex = {
  // Accepted domain regex.
  get domain() {
    const state = store.getState();
    return new RegExp(state.global.input_rules.valid_domain_regex.slice(1, -1));
  },
  // Accepted slug regex.
  get slug() {
    const state = store.getState();
    return new RegExp(state.global.input_rules.valid_slug_regex.slice(1, -1));
  },
  get wpOption() {
    const state = store.getState();
    return new RegExp(state.global.input_rules.valid_wp_option.slice(1, -1));
  },
  get wpDefine() {
    const state = store.getState();
    return new RegExp(state.global.input_rules.valid_wp_define.slice(1, -1));
  },
  get cronExp() {
    return new RegExp('^(((([\\*]{1}){1})|((\\*\\/){0,1}(([0-9]{1}){1}|(([1-5]{1}){1}([0-9]{1}){1}){1}))) ((([\\*]{1}){1})|((\\*\\/){0,1}(([0-9]{1}){1}|(([1]{1}){1}([0-9]{1}){1}){1}|([2]{1}){1}([0-3]{1}){1}))) ((([\\*]{1}){1})|((\\*\\/){0,1}(([1-9]{1}){1}|(([1-2]{1}){1}([0-9]{1}){1}){1}|([3]{1}){1}([0-1]{1}){1}))) ((([\\*]{1}){1})|((\\*\\/){0,1}(([1-9]{1}){1}|(([1-2]{1}){1}([0-9]{1}){1}){1}|([3]{1}){1}([0-1]{1}){1}))|(jan|feb|mar|apr|may|jun|jul|aug|sep|okt|nov|dec)) ((([\\*]{1}){1})|((\\*\\/){0,1}(([0-7]{1}){1}))|(sun|mon|tue|wed|thu|fri|sat)))$');
  }
};

// Return price value to 2 decimal places.
const normalizePrice = price => {
  if (!isNaN(price)) {
    return parseFloat(price).toFixed(2).toLocaleString();
  }
  return '';
};

// Return today's date formatted (YYYY-MM-DD)
const getTodayDate = () => {
  try {
    return DateHelper.now().format('YYYY-MM-DD')
  } catch (error) {
    window.logHelper.error(`Failed to get Today Date, ${error}`);
    return '';
  }
}

// Turn booleans to intergers.
const switchBooleans = data => {
  let newObject = {};
  Object.entries(data).forEach(([key, value]) => {
    let _value = value;
    if (typeof value === 'boolean') {
      _value = value ? 1 : 0;
    }
    newObject[key] = _value;
  });
  return newObject;
};

const FormHelper = {
  messages,
  normalizePrice,
  getTodayDate,
  regex,
  switchBooleans,
  createSelectOption
};

export default FormHelper;