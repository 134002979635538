// Global Can component to be used for Role access control
// Can component is based on Render props pattern and returns yes and no props

import UserHelper from 'helpers/user';

// Can component
const Can = props => {
  if (UserHelper.hasPermissions(props.perform)) {
    return props.yes();
  }
  return props.no();
};

// If porps aren't defined return Null
Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
