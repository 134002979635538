import { createSelector } from 'reselect';

const widgets = state => state.widgets;

// Helper
const getWidget = (_widgets, name) => (_widgets || []).find(w => w.name === name);

export const resourceUsageTrendsWidgetSelector = createSelector(widgets, (widgets) => getWidget(widgets, 'resource_usage_trends'));
export const recentPackageUpdatesWidgetSelector = createSelector(widgets, (widgets) => getWidget(widgets, 'recent_package_updates'));
export const customerDuePaymentWidgetSelector = createSelector(widgets, (widgets) => getWidget(widgets, 'customer_due_payment'));
export const partnerRevenueSummaryWidgetSelector = createSelector(widgets, (widgets) => getWidget(widgets, 'partner_revenue_summary'));