import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory, NavLink  } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { isFunction } from 'helpers';

const SecondaryNav = ({ website, link, index, backURL }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const fullPath = history.location.pathname.split('/');
    const currentPathName = `/${fullPath.pop()}`
    for(let i in link.items){
      const itemLink = link.items[i].link;
      const _itemLink = isFunction(itemLink) ? itemLink(website) : itemLink;
      const currentLinkName = `/${_itemLink.split('/').pop()}`
      if(currentLinkName === currentPathName){
        setIsCollapsed(true);
      }
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const handleOnClick = () => backURL ? history.push(backURL) : history.goBack();

  return (
    <Fragment>
      {index === 0 && (
        <button
          id='close__btn'
          data-tip='Go back'
          data-for='close__btn'
          className='close'
          onClick={handleOnClick}>
          <IoMdClose />
          <ReactTooltip id='close__btn' />
        </button>
      )}
      <li>
        {link.solidLink ? (
          <NavLink to={`${url}${link.solidLink}`}>
            {link.title}
          </NavLink>
        ) : (
          <Fragment>
            <div className='pointer primary-item' onClick={() => setIsCollapsed(!isCollapsed)}>
              <span className={isCollapsed ? 'arrow-up' : 'arrow-down'}>{link.title}</span>
            </div>
            {link.items.map((item, index2) => {
              if (item.doHide && item.doHide(website)) {
                return null;
              }
              const _url = isFunction(item.isAbsolute) && item.isAbsolute(website) ? '' : url;
              const _link = isFunction(item.link) ? item.link(website) : item.link;
              return <div
                className={isCollapsed ? 'pointer secondary-item' : 'link-hidden'}
                key={item.name + index2}>
                <NavLink to={`${_url}${_link}`}>{item.name}</NavLink>
              </div>
            })}
          </Fragment>
        )}
      </li>
    </Fragment>
  );
};

SecondaryNav.propTypes = {
  link: PropTypes.object.isRequired,
};

export default SecondaryNav;
