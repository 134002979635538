import React, { useState, createContext, Fragment, useRef, useEffect } from 'react';
import DialogHelper from 'helpers/dialog';

// Confirm modal component
const Confirm = props => {
  const {
    open,
    title,
    header,
    loading,
    onConfirm,
    onClose,
    icon,
    iconColor,
    confirmBtn,
    confirmColor,
    keyword,
    customClassName,
  } = props;

  const confirmWord = keyword || 'confirm';
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(true);

  // Change disabled state when the input value for the confirm word changes.
  // Used to determine the modal button disabled state.
  useEffect(() => {
    setDisabled(inputValue !== confirmWord);
    // eslint-disable-next-line
  }, [inputValue]);

  // Clear the input value on modal cancel or confirm.
  useEffect(() => {
    setInputValue('');
    // eslint-disable-next-line
  }, [open]);

  // Handle on change for the modal confirm input.
  const onChange = e => {
    const { value } = e.target;
    setInputValue(value);
  };

  return open && DialogHelper.inputs({
    title: title,
    header: header,
    icon: icon,
    iconColor: iconColor,
    loading: loading,
    disabled: keyword === false ? false : disabled,
    inputs: keyword === false ? [] : [{
      type: 'text',
      placeholder: `Please type "${confirmWord}" to confirm`,
      value: inputValue,
      onChange: onChange,
    }],
    btnText: confirmBtn || 'Confirm',
    btnColor: confirmColor || 'primary--btn',
    onConfirm: onConfirm,
    onClose: onClose,
    customClassName: customClassName || '',
  });
};

export const ConfirmContext = createContext();

export const ConfirmContextProvider = ({ children }) => {
  const [confirmState, setConfirmState] = useState(null);

  const awaitPromiseRef = useRef();

  const openConfirmation = options => {
    setConfirmState(options);
    return new Promise((resolve, reject) => {
      awaitPromiseRef.current = { resolve, reject };
    });
  };

  const handleOnClose = () => {
    if (confirmState.catchOnCancel && awaitPromiseRef.current) {
      awaitPromiseRef.current.reject();
    }

    setConfirmState(null);
  };

  const handleOnConfirm = () => {
    if (!confirmState.confirmWord && awaitPromiseRef.current) {
      awaitPromiseRef.current.resolve();
    }
    if (confirmState.confirmWord) {
      const value = document.getElementById('confirm-word').value;
      if (confirmState.confirmWord !== value) {
        return;
        // awaitPromiseRef.current.reject();
      } else {
        awaitPromiseRef.current.resolve();
      }
    }

    setConfirmState(null);
  };

  return (
    <Fragment>
      <ConfirmContext.Provider value={openConfirmation} children={children} />
      <Confirm
        open={Boolean(confirmState)}
        onConfirm={handleOnConfirm}
        onClose={handleOnClose}
        confirmWord={confirmState ? confirmState.confirmWord : null}
        {...confirmState}
      />
    </Fragment>
  );
};
