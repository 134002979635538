import React from 'react';
import { DomainStep } from 'styles/website/profile';
import SMTPMailer from 'components/website/advanced/smtpMailer';
import 'components/stepper/stepper.css';

const SMTPStep = ({ website, nextBtnHandler }) => {

  return <DomainStep>
    <SMTPMailer website={website} stepperView={true} onWebsiteUpdate={nextBtnHandler} />
  </DomainStep>;
}

export default SMTPStep;