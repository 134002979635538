import { USER_SET_ALL, USER_CREATE, USER_UPDATE, USER_DELETE } from './userActions';
import ArrayHelper from '../../helpers/array';

const userReducer = (state = [], action) => {
  switch (action.type) {
    case USER_SET_ALL:
      return [...action.payload];

    case USER_CREATE:
      return [...state, action.payload];

    case USER_UPDATE:
      return ArrayHelper.update(state, 'slug', action.payload);

    case USER_DELETE:
      return state.filter(user => user.slug !== action.payload.slug);

    default:
      return state;
  }
};

export default userReducer;
