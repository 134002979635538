import React, { useState, Fragment } from 'react';
import { DomainStep } from 'styles/website/profile';
import { WPSForm } from 'styles/layout/forms';
import { useDispatch } from 'react-redux';
import {
  fetchWebsite,
  fetchWebsiteSsl,
  requestWebsiteSsl
} from 'store/website/websiteActions';
import JsxHelper from 'helpers/jsx';
import useModal from 'hooks/useModal';
import { useForm } from 'react-hook-form';
import WPSTableloader from 'components/wpstaq/WPSDataTable/WPSTableloader';
import 'components/stepper/stepper.css';
import ArrayHelper from 'helpers/array';
import DialogHelper from 'helpers/dialog';
import StringHelper from 'helpers/string';
import useConfirm from 'hooks/useConfirm';
import Modal from 'components/layout/modal';

const SSLStepV2 = ({ website, nextBtnHandler }) => {
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const confirm = useConfirm();
  const ssl = website && ArrayHelper.first(website.ssl_certificates);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [isCustomSslInstalled, setIsCustomSslInstalled] = useState(false);
  const [customSslLoading, setCustomSslLoading] = useState(false);
  const [revokeSslLoading, setRevokeSslLoading] = useState(false);
  const [progressText, setProgressText] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [installCustomSslModal, setInstallCustomSslModal] = useState(false);
  const [verifyDomainsLoading, setVerifyDomainsLoading] = useState(false);
  const [currentValidateDomain, setCurrentValidateDomain] = useState(null);
  const [currentValidateMethod, setCurrentValidateMethod] = useState(null);

  console.log('SSLStepV2.app', website);
  console.log('SSLStepV2.ssl', ssl);
  
  // Handlers

  const onVerifyDomains = () => {
    //
  }

  const onRevokeCustomSsl = () => {

    //
  }

  const onCreateSsl = (website, isCustom = false) => {
    //
  }


  const onChangeCustomSslField = (e) => {
    const { name, value } = e.target;
    setInstallCustomSslModal({
      ...installCustomSslModal,
      [name]: value,
    });
  }

  const toggleInstallCustomSslModal = (mode) => {
    setInstallCustomSslModal(mode ? {
      certificate: '',
      private_key: '',
      ca_bundle: '',
    } : false);
  }

  // Getters

  const getDomainStatus = (domain) => {
    const verificationDetails  = ssl && ssl.domains_verification;
    if (!verificationDetails) {
      return null;
    } else if (verificationDetails.success || domain === 'nizar.heywpstaq.com') {
      return { success: true };
    }
    const details = verificationDetails.error && verificationDetails.error.details ? verificationDetails.error.details[domain] : null;
    if (details && details.validation_successful) {
      return { success: true };
    } else {
      return { success: false, error: `Failed to verify domain ownership. Are you sure the it is pointing to the website?`};
    }
  }
  
  // Renders

  const renderInfoRow = (label, value, _class) => <div className={`info-row ${_class || ''}`}>
    <span className='info-label'>{label}</span>
    <span className='info-line'>{value}</span>
  </div>;

  const renderDomains = () => {
    return ssl.domains.map((domain, index) => {
      const status = getDomainStatus(domain);
      const tooltip = status ? (status.success ? 'Verified' : status.error) : 'Unknown';
      const statusColor = status ? (status.success ? 'success' : 'danger') : 'warning';
      return <div key={index} className='domain'>
        {JsxHelper.createIcon({ icon: statusColor, tooltip, color: statusColor })}
        <span className='domain-name'>{domain}</span>
        {!status.success && <span className='domain-error'>
          <span className='domain-status'>{status.error}</span>
          <span onClick={() => setCurrentValidateDomain(domain)} className='domain-action'>
          {JsxHelper.createButton({
            label: `Advanced`,
            classes: 'text--btn',
            loading: verifyDomainsLoading,
            disabled: customSslLoading,
            onClick: () => setCurrentValidateDomain(domain),
          })}
          </span>
        </span>}
      </div>
    });
  }

  const renderStatus = () => {
    let color = 'warning';
    let statusText = 'Unknown';
    switch (ssl.status) {
      case 'draft':
        statusText = 'Pending Verification';
        break;
      case 'pending_validation':
        statusText = 'Pending Validation';
        break;
      case 'revoked':
      case 'expired':
        color = 'danger';
        statusText = StringHelper.capitalize(ssl.status);
        break;
      default:
        color = 'success';
        statusText = 'Issued';
        break;
    }
    return JsxHelper.createBubble({
      text: statusText,
      background: color,
      small: true,
    })
  }

  const renderDetails = () => ssl ? <Fragment>
    {renderInfoRow('Issuer', ssl.provider)}
    {renderInfoRow('Domains', renderDomains(), 'domains')}
    {renderInfoRow('Status', renderStatus())}
    {ssl.status === 'issued' && renderInfoRow('Expiry Date', ssl.valid_to)}
  </Fragment> : null;

  const renderProgress = (text, loading) => <div className='info-row'>
    {loading && <WPSTableloader/>}
    <span style={{display:'block', textAlign: 'center'}}>{text}</span>
  </div>

  return (<Fragment>
    <DomainStep>
      <WPSForm.Fieldset style={{ marginTop: '0px' }}>
        <legend>SSL Certificate Details</legend>
        <WPSForm.RowItem className='website-id-container'>
          <div id="ssl-info" className='info-container'>
            {!progressText && renderDetails()}
            {progressText && renderProgress(progressText, progressLoading)}
          </div>
        </WPSForm.RowItem>
      </WPSForm.Fieldset>
      <div className='action-buttons' style={{ display: 'flex', marginTop: '35px' }}>
        {/* Free SSL */}
        {!isCustomSslInstalled && ssl.status !== 'issued' && JsxHelper.createButton({
          label: `Verify Domains`,
          loading: verifyDomainsLoading,
          disabled: customSslLoading,
          onClick: onVerifyDomains,
        })}
        {/* Custom SSL */}
        {!nextBtnHandler && JsxHelper.createButton({
          label: `${isCustomSslInstalled ? 'Renew' : 'Install'} Custom Certificate`,
          loading: customSslLoading,
          disabled: revokeSslLoading,
          classes: 'alt--btn',
          onClick: () => toggleInstallCustomSslModal(true)
        })}
        {isCustomSslInstalled && JsxHelper.createButton({
          label: `Revoke Certificate`,
          loading: revokeSslLoading,
          disabled: customSslLoading,
          onClick: onRevokeCustomSsl,
        })}
        {/* Next Button */}
        {nextBtnHandler ? JsxHelper.createButton({
          label: 'Next',
          onClick: nextBtnHandler,
        }) : null}
      </div>
    </DomainStep>
    {currentValidateDomain && DialogHelper.inputs({
      title: 'Validate Domain Ownership',
      icon: 'checkcircle',
      iconColor: 'info',
      btnText: 'Close',
      header: <div>Select the validation method you would prefer to use to verify the <b>{currentValidateDomain}</b> domain ownership.</div>,
      onClose: () => setCurrentValidateDomain(false),
      inputs: [{
        name: 'method',
        label: 'Validation Method',
        options: [
          { label: 'CNAME', value: 'cname' },
          { label: 'Email', value: 'email' },
        ],
        value: currentValidateMethod,
        onChange: (e) => setCurrentValidateMethod(e.target.value),
        type: 'select',
      }],
    })}
    {installCustomSslModal && DialogHelper.inputs({
      title: 'Upload Custom SSL Certificate',
      icon: 'create',
      iconColor: 'success',
      btnText: 'Upload',
      onConfirm: handleSubmit(() => onCreateSsl(website, true)),
      onClose: () => toggleInstallCustomSslModal(false),
      register,
      inputs: [{
        name: 'certificate',
        label: 'Signed Certificate (e.g. certificate.crt)',
        placeholder: `Copy and paste the signed certificate here`,
        value: installCustomSslModal.certificate,
        type: 'textarea',
        required: true,
        onChange: onChangeCustomSslField,
        errors,
      }, {
        name: 'private_key',
        label: 'Private Key (e.g. private.key)',
        placeholder: `Copy and paste the private key here`,
        value: installCustomSslModal.private_key,
        type: 'textarea',
        onChange: onChangeCustomSslField,
        required: true,
        errors,
      }, {
        name: 'ca_bundle',
        label: 'CA Bundle (optional)',
        placeholder: `Copy and paste the CA bundle here`,
        value: installCustomSslModal.ca_bundle,
        type: 'textarea',
        onChange: onChangeCustomSslField,
        errors,
      }]
    })}
  </Fragment>);
}

export default SSLStepV2;