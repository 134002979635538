import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Container, DomainStep } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import { WPSForm, WPSLabel, WPSInput, WPSCheckboxLabel, WPSCheckbox } from 'styles/layout/forms';
import { useDispatch, useSelector } from 'react-redux';
import useConfirm from 'hooks/useConfirm';
import { setGlobalSuccessMsg, setGlobalInfoMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { dnsZonesSelector, dnsZoneBySlug } from 'store/dnsZone/dnsZoneSelectors';
import { websitesSelector } from 'store/website/websiteSelectors';
import { useForm } from 'react-hook-form';
import { assignDNSZone, unassignDNSZone, createWebsiteCloudflareZone, connectWebsiteCloudflareZone, deleteWebsiteCloudflareZone } from 'store/website/websiteActions';
import CloudflareService from 'services/cloudflare';
import { testDNSNameservers } from 'store/dnsZone/dnsZoneActions';
import env from '../../../config/env';
import DnsManager from 'components/website/dnsManager';
import { useHistory } from 'react-router-dom';
import { isEmpty, isEmptyOrNull, isWebsiteBusy } from 'helpers';
import ArrayHelper from 'helpers/array';
import UrlHelper from 'helpers/url';
import DnsHelper from 'helpers/dnszone';
import StringHelper from 'helpers/string';
import DomainHelper from 'helpers/domain';
import CloudflareHelper from 'helpers/cloudflare';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import SslStep from 'components/website/goLive/sslStep';
import SmtpStep from 'components/website/goLive/smtpStep';
import ReviewStep from 'components/website/goLive/reviewStep';
import Icon from 'components/layout/icon';
import globalHelper from 'helpers/globalHelper';
import staqLogo from 'assets/wps_logo.svg';
import useModal from 'hooks/useModal';
import DialogHelper from 'helpers/dialog';
import JsxHelper from 'helpers/jsx';
import WebsiteHelper from 'helpers/website';
import 'components/stepper/stepper.css';

const GoLive = ({ website }) => {
  useTitle('Website Go Live');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const modalDialog = useModal();
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  // Get all dns zones
  const allDNSZones = useSelector(dnsZonesSelector);
  // Get all dns zones assigned to some website
  const assignedDNSZones = useSelector(websitesSelector)
    .filter(w => !isEmptyOrNull(w.dns_zone_slug))
    .map(w => w.dns_zone_slug);
  // Filter assigned zones from all dns zones
  const unassignedDNSZones = useSelector(dnsZonesSelector).filter(
    d => !assignedDNSZones.includes(d.slug),
  );
  const [details, setDetails] = useState({
    website_slug: website.slug,
    dns_zone_slug: '',
    domain_input: '',
    dns_provider: globalHelper.dnsProviders[0].value,
    cloudflare_account_id: '',
    cloudflare_zone_id: '',
    cloudflare_zone_name: '',
  });
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [websiteDomains, setWebsiteDomains] = useState([]);
  const [currentDNSZone, setCurrentDNSZone] = useState(useSelector(dnsZoneBySlug(website.dns_zone_slug)));
  const [DNSItemValues, setDNSItemValues] = useState([]);
  const [user3rdPartyDomain, setUser3rdPartyDomain] = useState(website.is_live ? website.registrable_domain : '');
  const [valid3rdPartyDomain, setValid3rdPartyDomain] = useState(false);
  const [assignDefaultARecords, setAssignDefaultARecords] = useState(true);
  const [modal, setModal] = useState(false);
  const [cloudflareData, setCloudflareData] = useState(null);
  const cloudflareZone = WebsiteHelper.getCloudflareZone(website);

  const [currentStep, setCurrentStep] = useState(1);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [currentStepForData, setCurrentStepForData] = useState(false);
  const [disabledStepsData, setDisabledStepsData] = useState(null);
  const disabledStepsElements = useRef([]);
  const stepToStateColors = useRef([]);
  const [loading, setLoading] = useState(false);
  const [unassignLoading, setUnassignLoading] = useState(false);
  const [requireDbChanges, setRequireDbChanges] = useState(false);
  const [autoChangedStep, setAutoChangedStep] = useState(false);

  const dnsProviderTooltip = `It is highly recommended to use ${env.getBrandShortName()} as your DNS manager so we can manage the DNS records on your behalf and automate various processes.`;
  const origin = UrlHelper.getQueryParam('origin');
  const stepperRef = useRef();
  const [userApproved3rdPartyDns, setUserApproved3rdPartyDns] = useState(false);
  const [userModifiedDomains, setUserModifiedDomains] = useState(false);

  const initWebsiteDomains = (_domains) => {
    _domains = ArrayHelper.uniqueDeep(_domains, 'domain');
    setWebsiteDomains(_domains);
  }

  const initDnsProvider = () => {
    if (website.dns_zone_slug) {
      return 'staq_dns';
    } else if (cloudflareZone) {
      return 'cloudflare';
    }
    return website.is_live ? '3rd_party_dns' : 'staq_dns';
  };
  const [dnsProvider, setDnsProvider] = useState(initDnsProvider());

  const initStepper = (_website, step) => {
    // Auto set suggested domains if website is on dev
    if (step === 2 && !_website.is_live) {
      if (!userModifiedDomains) {
        // Only set domains if not modified by the user to prevent changing states when moving to different steps.
        if (cloudflareZone) {
          // If the Cloudflare zone is not active, show a warning.
          if (cloudflareZone.status !== 'active') {
            DialogHelper.warning(modalDialog, `<b>${CloudflareHelper.LABEL_DNS_SERVICE} Not Active</b>`, `The Go-Live process lets you assign a Cloudflare domain to your website, but you need to update the <b>${cloudflareZone.name}</b> nameservers to activate Cloudflare services for this domain.`);
          }
          // Get the configured domains from the Cloudflare zone
          const _dnsRecords = cloudflareZone.dns_records
            .filter(r => ['A', 'CNAME'].includes(r.type) && (r.name.startsWith('www.') || r.name === cloudflareZone.name))
            .map(r => ({ domain: r.name, is_default: r.name === cloudflareZone.name }));
          // Configure domains from Cloudflare zone
          if (isEmptyOrNull(_dnsRecords)) {
            const domain1 = StringHelper.trimRight(cloudflareZone.name, '.');
            const domain2 = `www.${domain1}`;
            const _domains = [ { domain: domain1, is_default: true }, { domain: domain2, is_default: false } ];
            initWebsiteDomains(_domains);
            window.logHelper.info('Generated domains for Cloudflare zone.', _domains, cloudflareZone);
          } else {
            // Set default domain if not set.
            if (!_dnsRecords.find(d => d.is_default)) {
              _dnsRecords[0].is_default = true;
            }
            // Sort domains by default first
            initWebsiteDomains(_dnsRecords.sort((a, b) => a.is_default ? -1 : 1));
            window.logHelper.info('Configured domains from Cloudflare zone.', _dnsRecords, cloudflareZone);
          }
        } else if (currentDNSZone) {
          const domain1 = StringHelper.trimRight(currentDNSZone.name, '.');
          const domain2 = `www.${domain1}`;
          const _domains = [ { domain: domain1, is_default: true }, { domain: domain2, is_default: false } ];
          initWebsiteDomains(_domains);
          window.logHelper.info('Generated domains for DNS zone.', _domains, currentDNSZone);
        } else if (!isEmptyOrNull(user3rdPartyDomain)) {
          const domain1 = user3rdPartyDomain.replace(/^(www\.)/, '');
          const domain2 = `www.${domain1}`;
          const _domains = [ { domain: domain1, is_default: true }, { domain: domain2, is_default: false } ];
          initWebsiteDomains(_domains);
          window.logHelper.info('Generated domains for 3rd party domain.', _domains, user3rdPartyDomain);
        } else {
          window.logHelper.warning('No domains to set for DNS manager.');
        }
      }
    } else {
      initWebsiteDomains(_website.domains.map(domain => ({ domain, is_default: domain === _website.default_domain })));
    }
    // Determine initial step and step states
    const completeState = 'complete';
    let stepToState = { 1: completeState, 2: completeState, 3: completeState, 4: completeState, 5: completeState };
    let initialStep = 1;
    if (step) {
      initialStep = step;
    } else if (_website.is_live) {
      if (_website.domains_ssl && _website.domains_ssl.status === 'valid') {
        initialStep = 5;
      } else {
        initialStep = 3;
      }
    }

    // Check whether we need to recalculate disabled steps
    const resetDisabledSteps = true;
    if (resetDisabledSteps) {
      // Set disabled steps
      let data = null;
      if (initialStep === 6) {
        data = [];
      } else if (initialStep === 5) {
        data = [5];
      } else if (initialStep === 4) {
        data = [4, 5];
      } else if (initialStep === 3) {
        data = [3, 4, 5].filter(i => i !== initialStep - 1);
      } else if (initialStep === 2) {
        data = [2, 3, 4, 5].filter(i => i !== initialStep - 1);
      } else {
        initialStep = 1; // default
        data = [1, 2, 3, 4, 5].filter(i => i !== initialStep - 1);
      }
      setDisabledStepsData(data);
      disabledStepsElements.current = data;
    }

    // Initialize steps and go to initial
    stepToStateColors.current = stepToState;
    stepperSteps[initialStep - 1].onClick();

    // Set initial step at the end of conditions
    updateCurrentStep(initialStep);
  };

  useEffect(() => {
    // If the site has a Cloudflare zone, go to domains or SSL setup.
    // To prevent auto-step change, only do this on the first load or if the data is not set.
    if (cloudflareZone && (currentStepForData === 1 || !cloudflareData)) {
      setDetails(prev => ({
        ...prev,
        cloudflare_zone_id: cloudflareZone.zone_id,
        cloudflare_account_id: cloudflareZone.account_id,
        cloudflare_zone_name: cloudflareZone.name,
        dns_provider: 'cloudflare'
      }));
      setCloudflareData(prev => ({ ...prev, assign_existing_zone: true }));
      onSelectCloudflareDNS();
      initStepper(website, website.is_live ? 3 : 2);
    }
    // If request coming from another page
    else if (origin && !autoChangedStep) {
      setAutoChangedStep(true); // Prevent changing the step
      if (origin === 'dnszone-setup') {
        initStepper(website, 2);
      }
    }
    // Go to the current step in order to prevent changing the step
    // and surprise the user in case the website object is refreshed
    else {
      initStepper(website, currentStepForData);
    }
    // eslint-disable-next-line
  }, [website]);

  useEffect(() => {
    if (currentDNSZone && !isEmpty(currentDNSZone.records)) {
      for (const i in currentDNSZone.records) {
        if (currentDNSZone.records[i].type === 'NS') {
          setDNSItemValues(currentDNSZone.records[i].values);
        }
      }
    }
    setSelectedDomain(currentDNSZone ? StringHelper.trimRight(currentDNSZone.name, '.') : null);
    // eslint-disable-next-line
  }, [currentDNSZone]);

  const initStep = stepNum => {
    for (var i = 1; i <= 5; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!disabledStepsElements.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add(`${stepToStateColors.current[i]}-color`);
      }
    }
    setCurrentStepForData(stepNum);
  };

  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'DNS Provider',
      onClick: () => initStep(1),
    },
    {
      title: 'DNS Manager',
      onClick: () => initStep(2),
    },
    {
      title: 'SSL Certificate',
      onClick: () => initStep(3),
    },
    {
      title: 'DB Search & Replace',
      onClick: () => initStep(4),
    },
    {
      title: 'SMTP',
      onClick: () => initStep(5),
    },
    {
      title: 'Review',
      onClick: () => initStep(6),
    },
  ]);

  const updateCurrentStep = current => {
    setCurrentStep(current + 1);
    setCurrentStepForData(current);
  };

  const onSelectDnsProvider = e => {
    const { name } = e.target;
    setDnsProvider(name);
    if (name === 'cloudflare') {
      onSelectCloudflareDNS();
    }
  };

  const onApproveUsing3rdPartyDns = e => {
    const { checked } = e.target;
    setUserApproved3rdPartyDns(checked);
  };

  const on3rdPartyDomainChange = e => {
    const { value } = e.target;
    setUser3rdPartyDomain(value);
    setUserModifiedDomains(false);
  };

  const onClickNextButton = () => {
    if (dnsProvider === '3rd_party_dns' && !DomainHelper.isRegistrableDomain(user3rdPartyDomain)) {
      setValid3rdPartyDomain(true);
      return;
    }
    setValid3rdPartyDomain(false);
    initStepper(website, currentStepForData + 1);
  };

  const onDomainsSaved = (_website, error) => {
    if (!isEmptyOrNull(error) || isEmptyOrNull(_website)) {
      return;
    }
    initStepper(_website, currentStepForData + 1);
    setShowNextBtn(true);
  }

  const onDefaultDomainChange = (newDomain, oldDomain) => {
    setRequireDbChanges(newDomain !== oldDomain);
  };

  const onDnsZoneChange = e => {
    const value = e.target.value;
    const domainName = e.target.label;
    setSelectedDomain(StringHelper.trimRight(domainName, '.'));
    setDetails(prev => ({ ...prev, dns_zone_slug: value, domain_input: domainName }));
  };

  const onCreateDnsZone = () => {
    history.push({
      pathname: `/dns-zones/setup`,
      search: `?website=${website.slug}&defaultARecord=${website.public_ipv4}&origin=go-live`
    });
  }

  const onCheckNameservers = async () => {
    setLoading(true);
    const data = {
      dns_zone_slug: currentDNSZone.slug
    }
    dispatch(testDNSNameservers(data))
    .then((result) => DnsHelper.openNSCheckDialog(modalDialog, assignDNSZone.name, result.success))
    .finally(() => setLoading(false));
  }

  const onAssignDns = () => {
    setLoading(true);
    const data = {
      website_slug: website.slug,
      dns_zone_slug: details.dns_zone_slug,
      a_records: !assignDefaultARecords ? [] : [ '', 'www' ]
    };
    // Assign selected DNS zone to website.
    dispatch(assignDNSZone(data))
      .then(res => {
        const _currentDnsZone = allDNSZones.find(el => el.slug === res.dns_zone_slug);
        if (_currentDnsZone) {
          setCurrentDNSZone(_currentDnsZone);
        }
        dispatch(setGlobalSuccessMsg({id: selectedDomain, model: 'DNS zone', action: 'assigned', to: website.slug }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickUnassignExistingDns = () => DialogHelper
    .confirmAction(confirm, 'unassign', currentDNSZone.name, 'DNS record')
    .then(() => {
      setUnassignLoading(true);
      const data = {
        website_slug: website.slug,
        dns_zone_slug: website.dns_zone_slug,
      };
      dispatch(unassignDNSZone(data))
        .then(() => {
          dispatch(
            setGlobalSuccessMsg({
              id: currentDNSZone.name,
              model: 'DNS zone',
              action: 'unassigned',
              from: website.slug,
            }),
          );
          setCurrentDNSZone(null);
        }).finally(() => setUnassignLoading(false))
    })

  // CLOUDFLARE

  const onSelectCloudflareDNS = () => {
    if (!cloudflareData) {
      CloudflareService.listAccounts({ partner_slug: website.partner_slug }).then((accounts) => {
        setCloudflareData(prev => ({ ...prev, accounts }));
        if (!isEmpty(accounts)) {
          // If the website has a Cloudflare zone, select it and manipulate the UI to have the zone
          // and account selected but disabled.
          if (!isEmpty(cloudflareZone)) {
            setDetails(prev => ({ ...prev, cloudflare_account_id: cloudflareZone.account_id }));
            setCloudflareData(prev => {
              const accountIndex = prev.accounts.findIndex(a => a.account_id === cloudflareZone.account_id);
              prev.accounts[accountIndex].zones = [cloudflareZone];
              prev.assign_existing_zone = true;
              return prev;
            });
          } else {
            setCloudflareData(prev => ({ ...prev, assign_existing_zone: true }));
            onSelectCloudflareAccount(accounts[0].account_id, accounts);
          }
        } else {
          DialogHelper.warning(
            modalDialog,
            'No Cloudflare accounts found.',
            'Please connect a Cloudflare account first.',
            {
              btnText: 'Connect Cloudflare',
              btnColor: 'info',
              btnOnClick: (onClose) => {
                history.push('/settings/cloudflare');
                onClose();
              }
            }
          );
          setDetails(prev => ({ ...prev, cloudflare_account_id: '', dns_provider: globalHelper.dnsProviders[0].value }));
          setCloudflareData(null);
          setDnsProvider(globalHelper.dnsProviders[0].value);
        }
      });
    }
  }

  const getCurrentCloudflareZones = (accountId, _accounts) => {
    accountId = accountId || details.cloudflare_account_id;
    return (_accounts || cloudflareData.accounts).find(a => a.account_id === accountId)?.zones;
  }

  const onSelectCloudflareAccount = (accountId, _accounts, forceReload) => {
    setDetails(prev => ({ ...prev, cloudflare_account_id: accountId }));
    const zones = forceReload ? false : getCurrentCloudflareZones(accountId, _accounts);
    if (zones) {
      window.logHelper.info(`Cloudflare zones already loaded for account ${accountId}`);
      if (!isEmpty(zones)) {
        setDetails(prev => ({ ...prev, cloudflare_zone_id: zones[0].zone_id }));
      }
      return; // Zones already loaded for this account
    }
    // Load zones for the selected account
    window.logHelper.info(`Loading Cloudflare zones for account ${accountId}`);
    setCloudflareData(prev => ({ ...prev, assignable_zones_loading: true }));
    const data = { account_id: accountId, website_slug: website.slug };
    CloudflareService.listAccountAssignableZones(data).then((zones) => {
      setCloudflareData(prev => { 
        const accountIndex = prev.accounts.findIndex(a => a.account_id === accountId);
        prev.accounts[accountIndex].zones = zones;
        return prev;
      });
      if (!isEmpty(zones)) {
        setDetails(prev => ({ ...prev, cloudflare_zone_id: zones[0].zone_id }));
      } else {
        DialogHelper.warning(modalDialog, `No assignable ${CloudflareHelper.LABEL_DNS_SERVICE} found for this account.`, 'Please create a new one or use a different account.');
        setDetails(prev => ({ ...prev, cloudflare_zone_id: '' }));
      }
    })
    .catch(error => DialogHelper.error(modalDialog, error))
    .finally(() => setCloudflareData(prev => ({ ...prev, assignable_zones_loading: false })));
  }

  const connectCloudflare = () => {
    setCloudflareData(prev => ({ ...prev, connect_loading: true }));
    dispatch(connectWebsiteCloudflareZone({ website_slug: website.slug, new_zone_id: details.cloudflare_zone_id, account_id: details.cloudflare_account_id, force: true }))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'connected' }));
        onClickNextButton();
      })
      .catch((error) => DialogHelper.error(modalDialog, error))
      .finally(() => setCloudflareData(prev => ({ ...prev, connect_loading: false })));
  }

  const createNewCloudflareZone = () => {
    setCloudflareData(prev => ({ ...prev, connect_loading: true }));
    const data = { website_slug: website.slug, name: details.cloudflare_zone_name, account_id: details.cloudflare_account_id };
    dispatch(createWebsiteCloudflareZone(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'created' }));
      })
      .catch((error) => DialogHelper.error(modalDialog, error))
      .finally(() => setCloudflareData(prev => ({ ...prev, connect_loading: false })));
  }

  const handleCloudflareConnectOrCreate = () => {
    if (!cloudflareData) {
      return;
    }
    if (isWebsiteBusy(website)) {
      dispatch(setGlobalInfoMsg({ text: 'Website is busy. Please wait a few seconds then try again.' }));
    } else if (cloudflareData.assign_existing_zone && !details.cloudflare_zone_id) {
      dispatch(setGlobalInfoMsg({ text: `Please provide a ${CloudflareHelper.LABEL_WEBSITE_SERVICE}.` }));
    } else if (!cloudflareData.assign_existing_zone && !details.cloudflare_zone_name) {
      dispatch(setGlobalInfoMsg({ text: 'Domain name cannot be empty.' }));
    } else if (cloudflareData.assign_existing_zone) {
      connectCloudflare();
    } else {
      createNewCloudflareZone();
    }
  };

  const handleCloudflareDisconnect = () => {
    setModal({ name: 'cloudflare-delete', delete_zone: false });
  }

  const handleCloudflareDelete = () => {
    setModal(prev => ({ ...prev, loading: true }));
    dispatch(deleteWebsiteCloudflareZone({ website_slug: website.slug, zone_id: cloudflareZone.zone_id, disconnect_only: !modal.delete_zone }))
    .then(() => {
      dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'disconnected' }));
      setDetails(prev => ({ ...prev, cloudflare_zone_id: '', cloudflare_zone_name: '' }));
      window.logHelper.info(`Cloudflare zone ${cloudflareZone.name} (${cloudflareZone.account_id}) disconnected from website ${website.slug}`);
      onSelectCloudflareAccount(details.cloudflare_account_id, null, true);
    })
    .catch((error) => DialogHelper.error(modalDialog, error))
    .finally(() => setModal(false));
  }

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Go Live</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
      Bring your website live by setting up your custom domain(s) and SSL certificate.
      </p>
      <Content id='go-live-wizard'>
        <Fragment>
          <div
            ref={stepperRef}
            className='go-live-stepper'
            style={{ marginBottom: '15px' }}>
            {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
          </div>

          {currentStepForData === 1 && (
            <WPSForm className='go-live-form margin-bottom-30' onSubmit={e => e.preventDefault()}>
              <DomainStep>
                <div className='dns-setup-step'>
                  {!website.dns_zone_slug && !cloudflareZone && (
                    <WPSForm.Fieldset style={{ marginTop: '0px' }}>
                      <legend>DNS Provider</legend>
                      <WPSForm.RowItem className='website-id-container'>
                        <div className='display-flex'>
                          {JsxHelper.createLabelWithTooltip('Choose your DNS provider:', dnsProviderTooltip)}
                        </div>
                        <div className='flags-container'>
                          <div className='flag-item dns-zone'>
                            <img src={staqLogo} alt='Internal DNS' />
                            <label htmlFor={globalHelper.dnsProviders[0].value}>{globalHelper.dnsProviders[0].name}</label>
                            <input
                              name={globalHelper.dnsProviders[0].value}
                              type='radio'
                              checked={dnsProvider === globalHelper.dnsProviders[0].value}
                              onChange={onSelectDnsProvider}
                            />
                          </div>
                          <div className='flag-item cloudflare'>
                            <Icon tag='cloudflare' />
                            <label htmlFor={globalHelper.dnsProviders[1].value}>{globalHelper.dnsProviders[1].name}</label>
                            <input
                              name={globalHelper.dnsProviders[1].value}
                              type='radio'
                              checked={dnsProvider === globalHelper.dnsProviders[1].value}
                              onChange={onSelectDnsProvider}
                            />
                          </div>
                          <div className='flag-item external'>
                            <Icon tag='external' />
                            <label htmlFor={globalHelper.dnsProviders[2].value}>{globalHelper.dnsProviders[2].name}</label>
                            <input
                              name={globalHelper.dnsProviders[2].value}
                              type='radio'
                              checked={dnsProvider === globalHelper.dnsProviders[2].value}
                              onChange={onSelectDnsProvider}
                            />
                          </div>
                        </div>
                      </WPSForm.RowItem>
                    </WPSForm.Fieldset>
                  )}
                  {dnsProvider === 'staq_dns' && !website.dns_zone_slug && (
                    <WPSForm.Fieldset className='margin-top-24'>
                      <legend>{env.getBrandShortName()} DNS</legend>
                      <WPSForm.RowItem className='website-id-container'>
                        <div className='display-flex'>
                          <WPSLabel htmlFor='dns_zone_slug'>
                            Select an existing DNS zone:
                          </WPSLabel>
                        </div>
                        <WPSSelect
                          selectClass='dns-select'
                          name='dns_zone_slug'
                          value={details.dns_zone_slug}
                          options={ArrayHelper.buildSelectOptions(unassignedDNSZones, 'name', 'slug')}
                          onChange={onDnsZoneChange}
                          isSearchable={true}
                        />
                      </WPSForm.RowItem>
                      {selectedDomain && <WPSForm.RowItem direction='row' className='custom-row'>
                        <WPSCheckboxLabel disabled={loading}>
                          <WPSCheckbox
                            type='checkbox'
                            checked={assignDefaultARecords}
                            onChange={() => setAssignDefaultARecords(!assignDefaultARecords)}
                          />
                        </WPSCheckboxLabel>
                        <div className='custom-checkbox-label'>
                          <span>Auto create <b>{selectedDomain}</b> and <b>www.{selectedDomain}</b> as A records and point them to my website inside {env.getBrandShortName()} DNS.</span>
                        </div>
                      </WPSForm.RowItem>}
                      <div className='display-flex action-buttons' style={{marginTop: !selectedDomain ? '24px' : 0}}>
                        {selectedDomain && <WPSButton
                          type='button'
                          className='update--btn'
                          onClick={handleSubmit(onAssignDns)}
                          loading={loading}>
                          Assign DNS zone
                        </WPSButton>}
                        {!selectedDomain && <WPSButton
                          type='button'
                          className='create--btn'
                          onClick={handleSubmit(onCreateDnsZone)}
                          disabled={loading}>
                          Create new DNS zone
                        </WPSButton>}
                      </div>
                    </WPSForm.Fieldset>
                  )}
                  {dnsProvider === 'staq_dns' && website.dns_zone_slug && (
                    <Fragment>
                      <p className='step-title'>
                        Point your <b>{selectedDomain}</b> domain to {env.getBrandShortName()} by changing its nameservers.
                        Nameservers play a crucial role in the Domain Name System (DNS) by directing the global database to the correct location of a website.
                        Follow the steps below to complete this process.
                      </p>
                      <div className='ns-steps'>
                        <p>1. <strong>Find out</strong> the registrar of {<b>{selectedDomain}</b>} via <a className='goto-link' target="__blank" href={`https://mxtoolbox.com/SuperTool.aspx?action=whois:${selectedDomain}&run=toolpage`}>WHOIS?</a>.</p>
                        <p>2. <strong>Log in</strong> to your account and go to the DNS Manager.</p>
                        <p>3. <strong>Remove</strong> your old nameservers.</p>
                        <div>
                          <p>4. <strong>Add</strong> the following nameservers:<br/></p>
                            <span style={{ width: '10%', display: 'inline-block' }}>
                              {DNSItemValues.map(i => DnsHelper.normalizeNSValue(i)).map(nsValue => (
                                <div className='ns-record' key={nsValue}>
                                  {JsxHelper.createCopyButton({ dispatch, value: nsValue, type: 'NS Record' })}
                                </div>
                              ))}
                            </span>
                        </div>
                        <p>5. <strong>Save</strong> your changes and wait until the DNS is propagated.</p>
                        <p className='step-note'>Note that it may take up to 24 hours for the changes to propagate.</p>
                      </div>
                      <div className='action-buttons' style={{ display: 'flex', marginTop: '35px' }}>
                        <WPSButton
                          type='button'
                          className='unassign--btn'
                          onClick={onClickUnassignExistingDns}
                          loading={unassignLoading}>
                          Unassign DNS Zone
                        </WPSButton>
                        <WPSButton
                          type='button'
                          className='success--btn'
                          onClick={onCheckNameservers}
                          loading={loading}
                          disabled={loading || unassignLoading}>
                          Check Nameservers
                        </WPSButton>
                        <WPSButton
                          type='button'
                          className='next--btn'
                          onClick={onClickNextButton}
                          disabled={unassignLoading}>
                          Next
                        </WPSButton>
                      </div>
                    </Fragment>
                  )}
                  {dnsProvider === 'cloudflare' && (
                    <WPSForm.Fieldset className='margin-top-24'>
                      <legend>Cloudflare</legend>
                      {!cloudflareData && <WPSForm.RowItem>{JsxHelper.createLoadingSpinner()}</WPSForm.RowItem>}
                      {cloudflareData && <WPSForm.RowItem>
                        {JsxHelper.createSelectInput({
                          label: 'Select an account:',
                          name: 'cloudflare_account',
                          options: ArrayHelper.buildSelectOptions(cloudflareData?.accounts || [], 'name', 'account_id'),
                          value: details.cloudflare_account_id,
                          onChange: (e) => onSelectCloudflareAccount(e.target.value),
                          disabled: !isEmpty(cloudflareZone),
                          isSearchable: true,
                        })}
                        {cloudflareData.assign_existing_zone ? JsxHelper.createSelectInput({
                          label: 'Select a domain:',
                          name: 'cloudflare_zone_id',
                          options: ArrayHelper.buildSelectOptions(getCurrentCloudflareZones() || [], 'name', 'zone_id'),
                          placeholder: cloudflareData.assignable_zones_loading ? 'Loading...' : null,
                          value: details.cloudflare_zone_id,
                          onChange: e => setDetails(prev => ({ ...prev, cloudflare_zone_id: e.target.value })),
                          disabled: !isEmpty(cloudflareZone),
                          isSearchable: true,
                        }) : null}
                        {!cloudflareData.assign_existing_zone ? JsxHelper.createTextInput({
                          label: 'Enter your domain name:',
                          name: 'cloudflare_zone_name',
                          placeholder: 'example.com',
                          value: details.cloudflare_zone_name,
                          onChange: e => {
                            const domain = e.target.value;
                            setDetails(prev => ({ ...prev, cloudflare_zone_name: domain }))
                          },
                        }) : null}
                        {JsxHelper.createCheckbox({
                          label: `Create a new ${CloudflareHelper.LABEL_DNS_SERVICE}`,
                          checked: !cloudflareData.assign_existing_zone,
                          disabled: !isEmpty(cloudflareZone),
                          onChange: e => {
                            const checked = e.target.checked;
                            setCloudflareData(prev => ({ ...prev, assign_existing_zone: !checked }));
                          }
                        })}
                      </WPSForm.RowItem>}
                      <div className='action-buttons margin-top-24'>
                        {!cloudflareZone && JsxHelper.createButton({
                          label: cloudflareData && cloudflareData.assign_existing_zone ? 'Connect' : 'Create',
                          onClick: handleCloudflareConnectOrCreate,
                          disabled: cloudflareData
                            ? (cloudflareData.assign_existing_zone ? cloudflareData.assignable_zones_loading || !details.cloudflare_zone_id : !details.cloudflare_zone_name)
                            : true,
                          loading: cloudflareData && cloudflareData.connect_loading,
                        })}
                        {cloudflareZone && JsxHelper.createButton({
                          label: 'Disconnect',
                          onClick: handleCloudflareDisconnect,
                          loading: cloudflareData && cloudflareData.disconnect_loading,
                        })}
                      </div>
                    </WPSForm.Fieldset>
                  )}
                  {dnsProvider === '3rd_party_dns' && (
                    <WPSForm.Fieldset className='margin-top-24'>
                      <legend>DNS Zone</legend>
                      <WPSForm.RowItem>
                        <WPSLabel>
                          Enter your domain name:
                          {valid3rdPartyDomain && (
                            <span style={{ color: '#FF6969' }}>
                              Enter root domain only (e.g. example.com)
                            </span>
                          )}
                        </WPSLabel>
                        <WPSInput
                          required
                          type='text'
                          name='3rd_party_domain'
                          placeholder='example.com (do not include www or http://)'
                          value={user3rdPartyDomain}
                          onChange={on3rdPartyDomainChange}
                        />
                      </WPSForm.RowItem>
                      <WPSForm.RowItem direction='row' className='custom-row'>
                        <WPSCheckboxLabel disabled={loading} style={{marginTop: '-3px'}}>
                          <WPSCheckbox
                            type='checkbox'
                            id='approve_3rd_party_dns'
                            checked={userApproved3rdPartyDns}
                            onChange={onApproveUsing3rdPartyDns}
                          />
                        </WPSCheckboxLabel>
                        <div className='custom-checkbox-label'>
                          <span>
                            I have access to my domain registrar's DNS Manager (find out <a className='goto-link' target="__blank" href={`https://mxtoolbox.com/SuperTool.aspx?action=whois:${user3rdPartyDomain}&run=toolpage`}>WHOIS?</a>).
                          </span>
                          <span style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                            All website domains must point to:
                            {JsxHelper.createCopyButton({
                              dispatch,
                              value: website.public_ipv4,
                              style: {padding: '2px 5px'}
                            })}
                          </span>
                        </div>
                      </WPSForm.RowItem>
                      <div className='action-buttons'>
                        {JsxHelper.createButton({
                          label: 'Next',
                          onClick: onClickNextButton,
                          disabled: !userApproved3rdPartyDns || loading
                        })}
                      </div>
                    </WPSForm.Fieldset>
                  )}
                </div>
              </DomainStep>
            </WPSForm>
          )}
          {currentStepForData === 2 && (
            <WPSForm className='go-live-form dns-manager-step' onSubmit={e => e.preventDefault()}>
              <DnsManager
                website={website}
                domains={websiteDomains}
                onDefaultDomainChange={onDefaultDomainChange}
                onDomainsChange={() => setUserModifiedDomains(true)}
                onSaveCompletedHandler={onDomainsSaved}
                hideNextButton={!showNextBtn}
              />
            </WPSForm>
          )}
          {currentStepForData === 3 && (
            <WPSForm className='go-live-form' onSubmit={e => e.preventDefault()}>
              <SslStep website={website} nextBtnHandler={onClickNextButton} noAutoConfig={true} />
            </WPSForm>
          )}
          {currentStepForData === 4 && (
            <WPSForm
              className='go-live-form db-replacements-step'
              onSubmit={e => e.preventDefault()}>
              <DomainStep>
                <div className={`${requireDbChanges?'success':'notice'}-box margin-left-6`}>
                  <p>{requireDbChanges
                    ? 'The database is being updated with the new domain(s). You may proceed to the next step.'
                    : 'No database changes are required for this website to go live.'
                  }</p>
                </div>
                <div className='action-buttons' style={{ display: 'flex', marginTop: '32px' }}>
                  {JsxHelper.createButton({
                    label: 'Next',
                    onClick: onClickNextButton
                  })}
                </div>
              </DomainStep>
            </WPSForm>
          )}
          {currentStepForData === 5 && (
            <WPSForm className='go-live-form' onSubmit={e => e.preventDefault()}>
              <SmtpStep website={website} nextBtnHandler={onClickNextButton} />
            </WPSForm>
          )}
          {currentStepForData === 6 && (
            <WPSForm className='go-live-form' onSubmit={e => e.preventDefault()}>
              <ReviewStep website={website} />
            </WPSForm>
          )}
        </Fragment>
        {modal && modal.name === 'cloudflare-delete' && DialogHelper.inputs({
          title: `Disconnect ${CloudflareHelper.LABEL_WEBSITE_SERVICE} `,
          icon: 'cloudflare',
          iconColor: 'warning',
          confirmBtn: 'Disconnect',
          btnColor: 'warning',
          onConfirm: handleCloudflareDelete,
          onClose: () => setModal(false),
          loading: modal.loading,
          header: CloudflareHelper.getDeleteZoneModalHeader(),
        })}
      </Content>
    </Container>
  );
};

export default GoLive;
