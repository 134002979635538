import { REGION_SET_ALL, REGION_CREATE, REGION_DELETE, REGION_UPDATE } from './regionActions';

const regionReducer = (state = [], action) => {
  switch (action.type) {
    case REGION_SET_ALL:
      return [...action.payload];

    case REGION_CREATE:
      return [...state, action.payload];

    case REGION_UPDATE:
      let previousState = state.filter(user => user.slug !== action.payload.slug);
      return [...previousState, action.payload];

    case REGION_DELETE:
      let filteredState = state.filter(region => region.slug !== action.payload.slug);
      return filteredState;

    default:
      return state;
  }
};

export default regionReducer;
