import React from 'react';
import { WPSButton } from 'styles/layout/buttons';
import { useHistory } from 'react-router-dom';

const BackButton = ({path}) => {
  const history = useHistory();

  const handleBackBtnClick = () => {
    if(path){
      history.push({ pathname: path });
    }else{
      history.goBack();
    }
  };
  return (
    <WPSButton className='back--btn' onClick={() => handleBackBtnClick()}>
      Back
    </WPSButton>
  );
};

export default BackButton;
