import { postRequest } from '../config/axiosRequest';
import env from '../config/env';


//  Save Error Information
const saveError = data => {
  return postRequest({
    url: env.buildApiEndpoint('frontend/report-error'),
    data,
  });
};


const ErrorsReportsService = { saveError };

export default ErrorsReportsService;
