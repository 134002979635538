import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { newSubscription, updateSubscription } from 'store/billing/billingActions';
import {
  pricingPlanBySubcategory,
  subscriptionsSelector,
} from 'store/billing/billingSelectors';
import { TitleBar } from 'styles/layout/titlebar';
import PricingPlans from 'components/billing/pricingPlans';
import { useState } from 'react';
import Modal from 'components/layout/modal';
import { WPSForm, WPSInput, ErrorMsg } from 'styles/layout/forms';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { isEmpty } from 'helpers';
import { useForm } from 'react-hook-form';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import useModal from 'hooks/useModal';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const Plan = () => {
  useTitle('Price List');
  const modalDialog = useModal();
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onSubmit' });
  const [subscription, setSubscription] = useState({
    guid: '',
    plan_slug: '',
    name: '',
    upgrade_only: false,
    plan_name: '',
  });
  const [selectedPlan, setSelectedPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  // Pricing plans categories selectors.
  const websiteHostingShared = useSelector(pricingPlanBySubcategory('website_hosting_shared'));
  const websiteHostingPrivate = useSelector(pricingPlanBySubcategory('website_hosting_private'));
  const websiteHosting = websiteHostingShared.concat(websiteHostingPrivate);

  const allSubscriptions = useSelector(subscriptionsSelector);

  // Handle on click for Subscribe button for each plan (Card Subscribe btn)
  // Each card returns its pricing plan with the onClick handler.
  const handleOnClick = plan => {
    // Find all subscriptions for the selected plan.
    const activeSubs = allSubscriptions.filter(s => s.plan_slug === plan.slug && s.is_active);
    // Create a new subscription if the there are no active ones or the plan allows multiple subscriptions.
    if (isEmpty(activeSubs) || plan.allow_multi_subs) {
      // Create a new subscription
      setSubscription(prev => ({
        ...prev,
        plan_slug: plan.slug,
        guid: '',
        name: plan.name,
        plan_name: '',
        upgrade_only: false,
      }));
      // Otherwise, if the plan allows only upgrades then update the previous subscription to the newly selected plan.
    } else if (plan.upgrade_only) {
      // The subscription guid should be the currently active subscription guid.
      const activeSub = activeSubs[0];
      setSubscription(prev => ({
        ...prev,
        plan_slug: plan.slug,
        guid: activeSub.guid,
        name: plan.name,
        plan_name: activeSub.plan_name,
        upgrade_only: true,
      }));
    } else {
      DialogHelper.warning(
        modalDialog,
        'This plan does not allow multiple subscriptions.',
        'Please cancel the active subscription and try again.'
      );
      // Do not open modal nor set the selected plan to state.
      return;
    }
    setSelectedPlan(plan);
    setModal(true);
  };

  const onSuccess = () => {
    dispatch(setGlobalSuccessMsg({ model: 'subscription' }));
    setLoading(false);
    setModal(false);
  };

  const onError = () => {
    setLoading(false);
    setModal(false);
  };

  // Post request to create a new subscription with the selected plan (Modal Save btn).
  const handleOnSubscribe = () => {
    setLoading(true);
    // There is already an active subscription.
    if (subscription.guid) {
      dispatch(updateSubscription(subscription))
        .then(() => onSuccess())
        .catch(() => onError());
    } else {
      // There are no active subscriptions
      dispatch(newSubscription(subscription))
        .then(() => onSuccess())
        .catch(() => onError());
    }
  };

  // On input change for modal input
  const onChange = e => {
    const { name, value } = e.target;
    setSubscription(prev => ({ ...prev, [name]: value }));
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link:'/'
    },
    {
      text: 'Billing',
      link:'/billing'
    },
    {
      text: 'Price List',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Price List</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/billing')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <PricingPlans type='website' plans={websiteHosting} handleOnClick={handleOnClick} />
        {modal && (
          <Modal
            title={!subscription.upgrade_only
                ? `${selectedPlan.name} plan for $${selectedPlan.price}/${selectedPlan.interval}`
                : 'Switch plan'}
            className='input-modal'
            icon={'create'}
            iconColor={'success'}
            onConfirm={handleSubmit(handleOnSubscribe)}
            onClose={() => setModal(false)}
            confirmBtn={subscription && subscription.upgrade_only ? 'Switch' : 'Subscribe'}
            loading={loading}>
            <WPSForm onSubmit={e => e.preventDefault()}>
              {subscription.upgrade_only ? (
                <div>
                  Switch from {subscription.plan_name} plan to {selectedPlan.name} plan for $
                  {selectedPlan.price}/{selectedPlan.interval}
                </div>
              ) : (
                <Fragment>
                  <label htmlFor='name' required>
                    Subscription name
                    {errors.name && <ErrorMsg>{errors.name.message}</ErrorMsg>}
                  </label>
                  <WPSInput
                    type='text'
                    name='name'
                    value={subscription.name}
                    onChange={onChange}
                    ref={register({ required: 'This field is required' })}
                  />
                </Fragment>
              )}
            </WPSForm>
          </Modal>
        )}
      </Content>
    </Fragment>
  );
};

export default Plan;
