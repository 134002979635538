import React from 'react';
import { InlineLoader } from '../../../styles/layout/loaders';

const TableLoader = ({ headers, actions }) => (
  <div style={{ textAlign: 'center',margin: '20px 0px' }}>
    <InlineLoader color='info' />
  </div>
);

export default TableLoader;
