import React, { Fragment, useState, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useHistory } from 'react-router-dom';
import {
  customerPlansSelector,
  customerSubscriptions,
} from 'store/customerBilling/customerBillingSelectors';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'components/layout/modal';
import { WPSForm } from 'styles/layout/forms';
import { customersSelector } from 'store/customer/customerSelectors';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { websitesSelector } from 'store/website/websiteSelectors';
import {
  fetchCustomerSubscriptions,
  createNewSubscription,
  updateCustomerSubscription,
} from 'store/customerBilling/customerBillingActions';
import { useForm } from 'react-hook-form';
import { isEmpty, sortByField, isEmptyOrNull } from 'helpers';
import FormHelper from 'helpers/form';
import StringHelper from 'helpers/string';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import ReactTooltip from 'react-tooltip';
import useConfirm from 'hooks/useConfirm';
import {
  deleteCustomerSubscription,
  cancelCustomerSubscription,
  unCancelCustomerSubscription,
} from 'store/customerBilling/customerBillingActions';
import { handleFilterURLParamsChanges, convertToFilterObject } from 'helpers';
import useOnClickOutside from 'utils/useOnClickOutside';
import useQuery from 'hooks/useQuery';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import ArrayHelper from 'helpers/array';
import PlanHelper from 'helpers/plan';
import { customerBillingPeriodStartDate } from 'store/customerBilling/customerBillingSelectors';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';
import TableHelper from 'helpers/table';
import DialogHelper from 'helpers/dialog';

const Subscription = () => {
  useTitle('Assigned Plans');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const query = useQuery();
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });
  const plans = useSelector(customerPlansSelector);
  const customers = useSelector(customersSelector);
  const [modal, setModal] = useState(false);
  const [customerWebsites, setCustomerWebsites] = useState([]);
  const subscriptions = useSelector(customerSubscriptions);
  const periodStartDate = useSelector(customerBillingPeriodStartDate);
  const allWebsites = useSelector(websitesSelector);
  const [assignableResources, setAssignableResources] = useState([]);

  const initialState = {
    plan_slug: '',
    name: '',
    customer_slug: '',
    resource_type: 'website',
    resource_slug: '',
    starts_at: FormHelper.getTodayDate() || '',
  };
  const [apiParams, setApiParams] = useState(initialState); // The subscription being created (new)
  const [assignedPlan, setAssignedPlan] = useState(null); // The subscription being edited
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  // Handle on Click outside for the filters menu
  const filtersRef = useRef();

  useOnClickOutside(filtersRef, () => setShowFilters(false));

  // Fetch all customer subscriptions.
  useEffect(() => {
    if (isEmptyOrNull(subscriptions)) {
      window.logHelper.info('Subscriptions2:', subscriptions);
      fetchSubscriptions();
    } else {
      window.logHelper.info('Subscriptions1:', subscriptions);
      initAssignableWebsites(subscriptions);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(subscriptions)) {
      // Filter options
      const subscriber_email = subscriptions.map(p => p.subscriber_email);
      const plan_name = subscriptions.map(p => p.plan_name);
      const status = subscriptions.map(p => StringHelper.toText(p.status));
      setFilters(prev => ({
        ...prev,
        ...convertToFilterObject(query, 'Plan', new Set(plan_name)),
        ...convertToFilterObject(query, 'Client', new Set(subscriber_email)),
        ...convertToFilterObject(query, 'Status', new Set(status)),
      }));
      window.logHelper.info('Subscriptions3:', subscriptions);
      initAssignableWebsites(subscriptions);
    }
    // eslint-disable-next-line
  }, [subscriptions]);

  // Handle filters add/remove from URL.
  useEffect(() => {
    if (!isEmpty(subscriptions) && !loading) {
      handleFilterURLParamsChanges(filters, history);
    }
    // eslint-disable-next-line
  }, [filters]);

  const fetchSubscriptions = () => {
    setLoading(true);
    const data = { subscriber_type: 'customer' };
    dispatch(fetchCustomerSubscriptions(data))
      .then(initAssignableWebsites)
      .finally(() => setLoading(false));
  };

  // Init assignable resources by 
  const initAssignableWebsites = (_subscriptions) => {
    _subscriptions = _subscriptions || subscriptions;
    let assignables = [];
    for (const w of allWebsites) {
      const isReserved = _subscriptions.some(s => s.is_active && s.resources.some(r => r.slug === w.slug && r.type === 'website'));
      if (!isReserved) {
        assignables.push(w);
      }
    }
    setAssignableResources(ArrayHelper.uniqueDeep(assignables, 'slug'));
  };

  // Update state when changing a filter checked state.
  const onClickFilter = e => {
    // id: filter.value, name: filter.name
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  const onChange = e => {
    const { name, value } = e.target;
    setApiParams(prev => ({ ...prev, [name]: value }));
    if (name === 'customer_slug') {
      filterWebsiteOptions(value);
    } else if (name === 'plan_slug') {
      onSelectPlan(value);
      filterWebsiteOptions(apiParams.customer_slug);
    }
  };

  const onSelectPlan = planSlug => {
    const resourceType = getResourceType(planSlug);
    setApiParams(prev => ({ ...prev, resource_type: resourceType }));
  };

  // Returns the resource type: 'website', 'email' or 'domain'.
  // The category slug starts with <resource_type>_[...]
  const getResourceType = planSlug => {
    const plan = plans.find(p => p.slug === planSlug);
    if (!plan) {
      return null;
    }
    return plan.category_slug.split('_')[0];
  };

  const onSelectWebsite = e => {
    const { name, value } = e.target;
    setApiParams(prev => ({ ...prev, [name]: value }));
  };

  const onSaveSubscription = () => {
    setSaveLoading(true);

    if (!assignedPlan) {
      // Create new subscription
      dispatch(createNewSubscription(apiParams))
        .then(res => {
          dispatch(
            setGlobalSuccessMsg({
              id: res.name,
              model: 'Subscription',
              action: 'created',
            }),
          );
        })
        .finally(() => {
          setSaveLoading(false);
          setModal(false);
          // removing the website from the dropdown websites in the modal
          // it no longer unassigned
          let _customerWebsites = [...customerWebsites];
          let mySiteIndex = customerWebsites.findIndex(
            el => el.resource_slug === apiParams.resource_slug,
          );
          if (mySiteIndex !== -1) {
            _customerWebsites.splice(mySiteIndex, 1);
          }
          setCustomerWebsites([..._customerWebsites]);
          setApiParams(initialState);
        });
    } else {
      // Update existing Subscription
      dispatch(updateCustomerSubscription(apiParams))
        .then(res => {
          dispatch(
            setGlobalSuccessMsg({
              id: res.name,
              model: 'Subscription',
              action: 'updated',
            }),
          );
        })
        .finally(() => {
          setSaveLoading(false);
          setModal(false);
          setApiParams(initialState);
          setAssignedPlan(null);
        });
    }
  };

  const onClickAssignNewPlan = () => {
    if (!isEmpty(plans)) {
      setModal(true);
    }
  };

  const onClickUpdateAssignedPlan = item => {
    // Partners can only update "name", "start date" and set "resource"
    // if the resource is not set yet (otherwise, it will be disabled)
    filterWebsiteOptions(item.subscriber_slug);
    setAssignedPlan(item);
    setApiParams({
      plan_slug: item.plan_slug,
      guid: item.guid,
      name: item.name,
      customer_slug: item.subscriber_slug,
      resource_type: getResourceType(item.plan_slug),
      resource_slug: !isEmptyOrNull(item.resources) ? item.resources[0].slug : null,
      starts_at: item.starts_at,
    });
    setModal(true);
  };

  // Filters websites by showing websites that are not assigned to customer
  // yet or assigned to the provided customer
  const filterWebsiteOptions = _customerSlug => {
    const customerSlug = _customerSlug || apiParams.customer_slug;
    let websites = [];
    for (const i in assignableResources) {
      if (!assignableResources[i].customer || assignableResources[i].customer.slug === customerSlug) {
        websites.push(assignableResources[i]);
      }
    }
    // Sort by assigned customers first
    const mapper = w => !!w.customer;
    const sortedWebsites = sortByField(websites, mapper, true);
    setCustomerWebsites(sortedWebsites);
  };

  const handleCloseModal = () => {
    setModal(false);
    setApiParams(initialState);
    setAssignedPlan(null);
  };

  const getTableData = () => {
    let _data = ArrayHelper.generateUniqueField(subscriptions, 'name', 'rowId');
    _data = PlanHelper.sortActiveFirst(_data);
    return _data;
  };

  const getWebsiteOptions = (assignedPlan) => {
    // List the assigned website as the only option (disabled anyway).
    if (assignedPlan && !isEmptyOrNull(assignedPlan.resources)) {
      return [{
        value: apiParams.resource_slug,
        label: apiParams.resource_slug,
        name: 'resource_slug',
      }]
    }
    // List all unassigned websites if no plan is assigned
    return customerWebsites.map(el => ({
      value: el.slug,
      label: el.slug,
      name: 'resource_slug',
    }))
  }

  const actions = [
    {
      value: 'Update',
      onClick: onClickUpdateAssignedPlan,
    },
    {
      value: 'Usage History',
      onClick: item => {
        history.push({
          pathname: `/clients/${item.guid}/usage`,
          search: `?guid=${item.guid}&from=${item.usage_start_date}`,
        });
      },
    },
    {
      value: 'Cancel',
      doHide: item => item.canceled_at,
      onClick: item => DialogHelper
        .confirmAction(confirm, 'cancel', item.name, 'subscription', 'Warning: Canceling the subscription does not exclude it from the next billing cycle. To avoid charges, please delete it instead.')
        .then(() => {
          item.canceled_at = !item.canceled_at;
          dispatch(cancelCustomerSubscription({ guid: item.guid })).then(() =>
            dispatch(setGlobalSuccessMsg({ id: item.name, model: 'subscription', action: 'cancelled' })),
          );
        })
    },
    {
      value: 'Uncancel',
      doHide: item => !item.canceled_at,
      onClick: item => DialogHelper
        .confirmAction(confirm, 'uncancel', item.name, 'subscription')
        .then(() => {
          item.canceled_at = !item.canceled_at;
          dispatch(unCancelCustomerSubscription({ guid: item.guid })).then(() =>
            dispatch(setGlobalSuccessMsg({ id: item.name, model: 'subscription', action: 'uncancelled' })),
          );
        })
    },
    {
      value: 'Delete',
        onClick: item => DialogHelper
          .confirmDelete(confirm, item.name, 'subscription')
          .then(() => dispatch(deleteCustomerSubscription({ guid: item.guid })).then(() =>
            dispatch(setGlobalSuccessMsg({ id: item.name, model: 'subscription', action: 'deleted' }))
          ))
    },
  ];

  const headers = [
    {
      name: 'Plan',
      selector: 'plan_name',
      sortable: true,
      searchable: true,
      width: '25%',
      cell: (row) => {
        TableHelper.customizeCellValue(row, 'plan_name', row.plan_name);
        return JsxHelper.createTableMultiLineCell({
          header: row.name,
          subheader: row.plan_name,
        })
      }
    },
    {
      name: 'Client',
      selector: 'subscriber_email',
      width: '25%',
      sortable: true,
      searchable: true,
      cell: (row) => JsxHelper.createTableMultiLineCell({
        header: row.subscriber_display_name,
        subheader: row.subscriber_email,
      })
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      searchable: true,
      width: '10%',
      cell: row => JsxHelper.createBubble({
        text: StringHelper.toText(row.status),
        background: PlanHelper.getSubscriptionStatusColor(row.status),
      }),
    },
    {
      name: 'Resources',
      selector: 'resource_slugs',
      sortable: true,
      searchable: true,
      width: '15%',
      cell: row => {
        const resources = row.resources || [];
        TableHelper.customizeCellValue(row, 'resource_slugs', resources.map(r => r.slug).join(', '));
        const links = (resources || []).map(resource => ({
          label: resource.slug,
          link: `/websites/${resource.slug}/overview`,
        }));
        return JsxHelper.createTableCellMultiLinks({ links });
      },
    },
    JsxHelper.createTablePeriodHeader('starts_at', 'ends_at', 'Billing Period'),
    JsxHelper.createTableActionsHeader(actions, '5%'),
  ];

  const breadcrumbs = JsxHelper.createBreadcrumbs('Assigned Plans', 'Clients');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Assigned Plans</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createFilterIcon({
            setShowFilters,
            showFilters,
            filters,
            onClickFilter,
            filtersRef
          })}
          {JsxHelper.createButton({
            label: 'Assign Plan',
            onClick: onClickAssignNewPlan,
            disabled: isEmpty(plans),
            tooltip: isEmpty(plans) ? 'You must create at least one plan before assigning a customer.' : null,
          })}
          {isEmpty(plans) && <ReactTooltip />}
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          columns={headers}
          body={getTableData()}
          loading={loading}
          dataKey='rowId'
        />
        {modal && (
          <Modal
            title={assignedPlan ? 'Update Subscription' : 'Create New Subscription'}
            confirmBtn={assignedPlan ? 'Update' : 'Create'}
            className='input-modal'
            icon={'create'}
            iconColor={'success'}
            onClose={() => handleCloseModal()}
            onConfirm={handleSubmit(onSaveSubscription)}
            loading={saveLoading}
            confirmColor='save--btn'>
            <WPSForm onSubmit={e => e.preventDefault()}>
              {JsxHelper.createSelectInput({
                label: 'Plan',
                name: 'plan_slug',
                value: apiParams.plan_slug,
                options: ArrayHelper.buildSelectOptions(plans, 'name', 'slug'),
                onChange,
                disabled: assignedPlan,
                required: true,
                errors,
                refId: 'plan_slug',
                register,
              })}
              {JsxHelper.createSelectInput({
                label: 'Client',
                name: 'customer_slug',
                value: apiParams.customer_slug,
                options: ArrayHelper.buildSelectOptions(customers, 'display_name', 'slug'),
                onChange: onSelectWebsite,
                disabled: assignedPlan && !isEmptyOrNull(assignedPlan.subscriber_slug),
                required: true,
                errors,
                refId: 'customer_slug',
                register,
              })}
              {JsxHelper.createTextInput({
                label: 'Subscription name',
                name: 'name',
                value: apiParams.name,
                onChange,
                required: true,
                errors,
                refId: 'name',
                register,
              })}
              {apiParams.resource_type === 'website' && (
                <Fragment>
                  {JsxHelper.createSelectInput({
                    label: 'Plan type',
                    name: 'resource_type',
                    value: apiParams.resource_type,
                    options: ArrayHelper.buildSelectOptions(globalHelper.resourceTypes, 'name', 'value'),
                    onChange,
                    disabled: assignedPlan,
                    required: true,
                    errors,
                    refId: 'resource_type',
                    register,
                  })}
                  {JsxHelper.createSelectInput({
                    label: 'Website',
                    name: 'resource_slug',
                    value: apiParams.resource_slug,
                    options: getWebsiteOptions(assignedPlan),
                    onChange: onSelectWebsite,
                    disabled: assignedPlan && !isEmptyOrNull(assignedPlan.resources),
                    required: !assignedPlan,
                    errors,
                    refId: 'resource_slug',
                    register,
                    maxMenuHeight: 250,
                  })}
                </Fragment>
              )}
              {JsxHelper.createDateInput({
                label: 'Start date',
                name: 'starts_at',
                value: apiParams.starts_at,
                min: periodStartDate,
                onChange,
                required: true,
                errors,
                refId: 'starts_at',
                register,
              })}
            </WPSForm>
          </Modal>
        )}
      </Content>
    </Fragment>
  );
};

export default Subscription;
