import { createSelector } from 'reselect';

/*
  Input
*/
const customers = state => state.customers;

/*
  Selectors
*/
export const customersSelector = createSelector(customers, customers => customers);

// Get specified customer by slug
export const getCustomerBySlug = customerSlug =>
  createSelector(
    customers,
    customers => customers.filter(customer => customer.slug === customerSlug)[0]
  );

  // Get specified customer PaymentMethods
export const getCustomerPaymentMethods = customerSlug =>
  createSelector(
    customers,
    customers => {
      const customer = customers.find(customer => customer.slug === customerSlug)
      return customer && customer.payment_methods ? customer.payment_methods : []
    }
  );
