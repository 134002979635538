import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalCoresSelector } from 'store/globalPackage/globalPackageSelector';
import { updateWebsiteWPVersion, updateWebsiteWPCoreAutoUpdate } from 'store/website/websiteActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import useConfirm from 'hooks/useConfirm';
import { WPSForm, WPSLabel } from 'styles/layout/forms';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';
import Tooltip from 'components/layout/tooltip';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import JsxHelper from 'helpers/jsx';

const UpgradeWordpress = ({ website }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const globalCorePackages = useSelector(globalCoresSelector);
  const [loading, setLoading] = useState(false);
  const wpVersion = website.wp_version;
  const autoUpdate = website.wp_core_auto_update || false;
  const [config, setConfig] = useState({
    wp_version: wpVersion,
    auto_update: autoUpdate,
  });

  const onUpdateVersion = () => {
    const action = config.wp_version > wpVersion ? 'upgrade' : 'downgrade';
    DialogHelper
      .confirmAction(confirm, action, '', 'WordPress version', `This will ${action} your WordPress version from v${wpVersion} to v${config.wp_version}.`)
      .then(() => {
        setLoading(true);
        const data = {
          website_slug: website.slug,
          wp_version: config.wp_version,
          auto_update: config.auto_update,
        };
        dispatch(updateWebsiteWPVersion(data))
          .then(() => dispatch(setGlobalSuccessMsg({ model: 'WordPress version', onId: WebsiteHelper.getLabel(website), action: action + 'd' })))
          .finally(() => setLoading(false));
      })
  };

  const onUpdateAutoUpdate = () => {
    const action = config.auto_update ? 'enable' : 'disable';
    const data = {
      website_slug: website.slug,
      auto_update: config.auto_update,
    };
    setLoading(true);
    dispatch(updateWebsiteWPCoreAutoUpdate(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Core auto-update', onId: WebsiteHelper.getLabel(website), action: action + 'd' })))
      .finally(() => setLoading(false));
  }

  const configData = [
    {
      name: 'version',
      title: 'WordPress Version',
      desc: 'Update the core version of your WordPress installation.',
      value: config.wp_version,
      options: globalCorePackages.map(el => {
        return { label: el.version, value: el.version };
      }),
      onChange: e => setConfig({ ...config, wp_version: e.target.value }),
      disabled: loading,
    },
    {
      name: 'autoUpdate',
      title: 'Auto Update',
      desc: 'Enable or disable automatic updates for WordPress core.',
      value: config.auto_update,
      options: [
        { label: 'Enabled', value: true },
        { label: 'Disabled', value: false },
      ],
      onChange: e => setConfig({ ...config, auto_update: e.target.value }),
      disabled: loading,
    }
  ]

  const onSubmit = () => {
    if (config.wp_version !== wpVersion) {
      onUpdateVersion();
    }
    // The update version includes the auto-update so no need to update it separately
    // if the version is updated.
    else if (config.auto_update !== autoUpdate) {
      onUpdateAutoUpdate();
    }
  };

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title> WordPress Version </TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        Update the core version of your WordPress installation and control automatic updates to keep your website secure and up-to-date.
      </p>
      <Content id='wp-core-options'>
        <div style={{ maxWidth: '500px', marginTop: '20px' }}>
          {
            configData.filter(item => !item.hide).map((item, index) => {
              return (
                <WPSForm.RowItem key={index} margin='0px'>
                  <div className='display-inline-block'>
                    <WPSLabel className='display-inline-block'>{item.title}</WPSLabel>
                    <Tooltip place='right' text={item.desc} />
                  </div>
                  <WPSSelect
                    selectClass='secondary-box-select'
                    value={item.value}
                    options={item.options}
                    onChange={item.onChange}
                    sortOff={true}
                  />
                </WPSForm.RowItem>
              );

            })
          }
        </div>
        <div className='action-buttons display-flex-nowrap margin-bottom-30' style={{display: 'flex'}}>
          {JsxHelper.createButton({
            label: 'Save',
            loading,
            disabled: loading || (config.wp_version === wpVersion && config.auto_update === autoUpdate),
            onClick: onSubmit,
          })}
        </div>
      </Content>
    </Container>
  );
};

export default UpgradeWordpress;
