import React from 'react';
import env from 'config/env';
import { WPSToggle } from 'styles/layout/forms';
import { WPSBubble } from 'styles/layout/buttons';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/layout/icon';
import StringHelper from './string';
import JsxHelper from './jsx';
import TableHelper from './table';
import WebsiteHelper from './website';
import DialogHelper from './dialog';
import ArrayHelper from 'helpers/array';
import { uniqBy } from 'lodash';
import { isEmptyOrNull, versionCompare, isObject } from 'helpers';

/* Constants */
const STATUS_UPDATED = 'updated';
const STATUS_UPDATING = 'updating';
const STATUS_QUEUED = 'queued';
const STATUS_FAILED = 'failed';

/* Getters */
const normalizeData = (data, activeList, managed, isInstalled) => {
  // Set common data for managed and unmanaged packages.
  const normalized = {
    type: data.type,
    folder_name: data.name || data.folder_name || (managed ? managed.folder_name : null),
    author: data.author,
    author_uri: data.author_uri,
    display_name: data.display_name,
    version: data.version,
    vulnerability: data.vulnerability || false,
    is_installed: isInstalled || false,
    scope: data.scope || 'wordpress',
  };
  // Set is active if the package is in the active list after formatting the folder name.
  normalized.is_active = activeList.includes(normalized.folder_name);
  // Set the latest version info if available.
  if (data.update && versionCompare(data.update, data.version) > 0) {
    normalized.latest_version = data.update;
    normalized.latest_version_url = data.update_url;
  }
  // If the managed data is available, then set the managed fields.
  if (managed) {
    normalized.slug = managed.slug;
    normalized.zip_size = managed.zip_size;
    normalized.dir_size = managed.dir_size;
    normalized.is_git = managed.is_git;
    normalized.flat_compress = managed.flat_compress;
    // If the package is managed and latest version is not set, try to set it from the managed data.
    if (!normalized.latest_version && managed.latest_version && versionCompare(managed.latest_version, data.version) > 0) {
      normalized.latest_version = managed.latest_version;
      normalized.latest_version_url = managed.latest_version_url;
    }
    // Set the must install fields if available.
    if (normalized.scope !== 'wordpress') {
      normalized.must_install = managed.must_install;
      normalized.must_install_exclude = managed.must_install_exclude || [];
    }
  }
  // Set a slug if it's not available. This is useful for loading spinner and other actions.
  if (!normalized.slug) {
    normalized.slug = StringHelper.randomSlug();
  }
  return normalized;
}
const isUnmanaged = (item) => !item.scope || item.scope === 'wordpress';
const isGlobal = (item) => item.scope === 'global';
const isPartner = (item) => item.scope === 'partner';
const isGlobalOrPartner = (item) => isGlobal(item) || isPartner(item);
const isManaged = (item) => isGlobalOrPartner(item) || item.scope === 'private';
const isInstalled = (item) => item.is_installed;
const isGit = (item) => item.is_git;
const scopeCompare = (item1, item2) => {
  const ranks = { wordpress: 4, private: 3, partner: 2, global: 1 };
  // Positive if item1 is greater, negative if item2 is greater, 0 if equal.
  // Example1: item1 is 'wordpress' and item2 is 'global', then the result is 3.
  // Example2: item1 is 'partner' and item2 is 'private', then the result is -1.
  return ranks[item2.scope || 'wordpress'] - ranks[item1.scope || 'wordpress'];
}
const securityCompare = (item1, item2) => {
    // Packages with vulnerabilities first, then with updates available and then the rest.
    if (item1.vulnerability && !item2.vulnerability) {
      return -1;
    } else if (!item1.vulnerability && item2.vulnerability) {
      return 1;
    } else if (item1.latest_version && !item2.latest_version) {
      return -1;
    } else if (!item1.latest_version && item2.latest_version) {
      return 1;
    }
    return 0;
}
const activesList = (type, website, installedData, installedUpdatedAt) => {
  if (!website || !isObject(website)) {
    window.logHelper.error('An empty or non-object website was passed to activesList().');
    return [];
  }
  const websiteUpdatedAt = website.updated_at;
  // If the installed packages are older, use the website active packages.
  if (installedUpdatedAt && websiteUpdatedAt && websiteUpdatedAt > installedUpdatedAt) {
    // If the type is plugin and active_plugins is empty, bypass the website active plugins
    // because it is probably corrupted.
    if (type !== 'plugin' || !isEmptyOrNull(website.active_plugins)) {
      return type === 'plugin' ? website.active_plugins : [ website.active_theme ];
    } else {
      window.logHelper.warn(`The active plugins is empty on ${website.slug}. Using the installed packages instead.`);
    }
  }
  // Otherwise, use the installed packages to get the active ones.
  return installedData.filter(p => p.status === 'active' && (!p.type || p.type === type)).map(p => p.name);
}
const normalize = (type, website, installed, globals, partners) => {
  // The result will include all packages in the website in a standard format.
  // The source of truth is the data returned from "listInstalledPackages".
  // However, uninstalled partner and global packages should also be visible in the table
  // so that they can be activated.
  const normalized = [];
  // Parse the installed packages.
  const installedData = installed[type + 's']?.data || [];
  const installedUpdatedAt = installed[type + 's']?.updated_at_str || null;
  // Another thing to consider is that the website has "active_plugins", but also the
  // installed packages have "state" which indicates whether the package is active or
  // not. For now, we consider "active_plugins" as the source of truth for the active state
  // of the packages and we should include it in the normalized data.
  const activePackages = activesList(type, website, installedData, installedUpdatedAt);
  // Prepare the list of managed packages in one array.
  const allManaged = ArrayHelper.reverse(uniqBy([...website.private_packages, ...partners, ...globals], 'folder_name'))
                                .filter(p => p.type === type);
  // Iterate through the installed packages and normalize them. Note that the installed data
  // is usually enough, but for the managed ones there are a few more fields to be be added
  // to the normalized data.
  for (const unmanaged of installedData) {
    const managed = allManaged.find(m => m.folder_name === unmanaged.name);
    const item = normalizeData(unmanaged, activePackages, managed, true);
    normalized.push(item);
  }
  // For the managed packages that are not installed, we should add them to the normalized
  // list as well. This is important because the user should be able to activate them.
  for (const managed of allManaged) {
    if (!normalized.some(n => n.folder_name === managed.folder_name)) {
      const item = normalizeData(managed, activePackages, managed);
      normalized.push(item);
    }
  }
  return normalized;
}
const getSourceURLType = (item) => {
  if (!item.url) {
    return 'unknown';
  } else if (item.url.includes('.s3.ap-southeast-2.amazonaws.com/public/temp/')) {
    return 'fileupload'
  }
  return !item.scope || isUnmanaged(item) ? 'public' : item.scope;
}
const getActionFromURL = (item) => {
  if (getSourceURLType(item) === 'fileupload') {
    return `File Upload`;
  }
  return item.action.includes('create') ? 'Create' : 'Update';
}

/* Other */
const pageHeader = (type, globalLink) => <p className='color-primary subheader'>
  {env.getBrandShortName()} simplifies plugin management across multiple websites with
  defining{' '}
  <span onClick={globalLink} className='subheader-link'>Global {StringHelper.capitalizeFirstLetter(type)}s</span>.
  No need to install common {type}s individually on each site. Upload and edit site-specific plugins easily
  through {env.getBrandShortName()}'s File Manager, no FTP/SSH clients required.
</p>

const sortPackages = (packages, onlySecurityCompre) => {
  if (onlySecurityCompre) {
    return packages.sort(securityCompare);
  }
  const actives = (packages.filter(p => p.is_active).sort(scopeCompare)).sort(securityCompare);
  const inactives = (packages.filter(p => !p.is_active).sort(scopeCompare)).sort(securityCompare);
  return [...actives, ...inactives];
}

/* Updater */
const filterWebsiteUpdatables = (packages) => packages.filter(p =>
  !isGit(p) && p.latest_version_url && ['wordpress', 'private'].includes(p.scope)
);
const setUpdaterItemStatus = (current, item, error) => {
  return [...current.map(p => {
    if (p.slug === item.slug) {
      if (!error) {
        p.update_status = STATUS_UPDATED;
        p.version = item.latest_version || item.version;
        p.latest_version = null;
        p.latest_version_url = null;
      } else {
        p.update_status = STATUS_FAILED;
        p.update_error = error;
      }
    }
    return {...p};
  })];
}
const setManageUpdatesMode = (website, type, setUpdaterStatus, setManageUpdatesMode, packages, modalDialog) => {
  const globalsNotice = website
    ? `For global ${type}s, please visit the <b>Global ${StringHelper.capitalizeFirstLetter(type)}s</b> page.`
    : '';
  // Get list of packages that have an update available (regardless of the update URL).
  const haveUpdatesAvailable = packages.filter(p => p.latest_version);
  const haveGlobalUpdatesAvailable = haveUpdatesAvailable.filter(isGlobalOrPartner);
  // If no packages are available for updates, show a message and return.
  if (isEmptyOrNull(haveUpdatesAvailable)) {
    DialogHelper.info(
      modalDialog,
      `No ${type}s are available for updates.`,
      haveGlobalUpdatesAvailable ? globalsNotice : ''
    );
    return;
  }
  // If website mode and the updates available are only for global or partner packages, show a message and return.
  if (website && haveUpdatesAvailable.every(isGlobalOrPartner)) {
    DialogHelper.info(
      modalDialog,
      'No website-level updates are available.',
      globalsNotice
    );
    return;
  }
  // If some packages are available for updates, but not all can be updated, show an adequate message.
  const canBeUpdated = website ? filterWebsiteUpdatables(packages) : [];
  if (canBeUpdated.length !== haveUpdatesAvailable.length) {
    const suffix = 'and require updates directly from the WordPress Dashboard.';
    if (canBeUpdated.length === 0) {
      DialogHelper.info(
        modalDialog,
        `None of the${website?' website-level':''} ${type}s can be updated through the Update Manager ${suffix}`,
        haveGlobalUpdatesAvailable ? globalsNotice : ''
      );
      return; // If none of the packages can be updated, return.
    } else {
      DialogHelper.info(
        modalDialog,
        `Some of the${website?' website-level':''} ${type}s were omitted from the update queue ${suffix}`,
        haveGlobalUpdatesAvailable ? globalsNotice : ''
      );
    }
  }
  // Set up the update queuee.
  const updatesQueue = canBeUpdated.map(p => {
    p.update_status = null;
    p.update_error = null;
    return p;
  });
  setUpdaterStatus(updatesQueue);
  // Set the mode to manage updates.
  setManageUpdatesMode(true);
}
/* Cell Styling */
const getMustInstallStyle = (_package, _website) => {
  const result = {
    icon: 'openLock',
    colorClass: 'inactive-font-color',
    tooltip: _package.type === 'plugin'
      ? 'This plugin is not set to install and activate automatically'
      : ''
  }
  if (_package.must_install) {
    result.colorClass = 'success-font-color';
    result.tooltip = _package.type === 'plugin'
      ? 'This plugin is set to install and activate automatically'
        + (_website
            ? '. However, if you deactivate it manually for this website, it will no longer install or activate automatically.'
            : ' across all websites. However, for any specific website where you prefer not to install it, you can manually deactivate it on that website.'
          )
      : '';
    result.icon = 'closedLock';
    const excluded = _website ? (_package.must_install_exclude || []) : [];
    if (_website && excluded.includes(_website.slug)) {
      result.tooltip = 'This plugin is set to install and activate automatically, but it is excluded for this website.';
      result.colorClass = 'warning-font-color';
      result.icon = 'openLock';
    }
  }
  return result;
}
const getManagementStyle = (_package) => {
  const result = {
    color: 'primary',
    icon: 'wordpress',
    tooltip: `Only managed for this site (Website-level)`,
  }
  if (isGit(_package)) {
    result.icon = 'git';
    result.color = 'danger';
    result.tooltip = `Managed from a Git repository`;
  } else if (_package.scope && _package.scope !== 'wordpress') {
    result.icon = 'globalManage';
    if (_package.scope === 'private') {
      result.icon = 'localManage';
      result.tooltip = `Managed for this site only (Website-level)`;
    } else if (_package.scope === 'partner') {
      result.tooltip = `Managed for all your sites (Global-level)`;
    } else if (_package.scope === 'global') {
      result.color = 'alt';
      result.tooltip = `Managed for all sites (${env.getBrandShortName()}-level)`;
    }
  }
  result.colorClass = `${result.color}-font-color`;
  return result;
};
const renderInstalledVersionCell = (row) => <span className='version-cell'>{row.version}</span>
const renderLatestVersionCell = (row) => <span className='version-cell'>{row.latest_version || row.version}</span>
/* Table Cells */
const renderGlobalAutoUpdateCell = (row, callback, loadingSlug) => {
  return <div className='auto-update-cell'>
    <WPSToggle
      type='checkbox'
      className={loadingSlug === row.slug ? 'loadingspinner' : ''}
      name={row.name}
      id={row.slug}
      checked={row.auto_update}
      onChange={e => callback(row, e.target.checked)}
    />
  </div>
}
const renderAutoUpdateCell = (website, autoUpdaterConfig, row, callback, loadingSlug, partnerPackages) => {
  if (isGlobal(row)) {
    return <div></div>
  }
  let enabled = false;
  let supported = true;
  if (isManaged(row)) {
    const partnerPackage = partnerPackages.find(p => p.slug === row.slug);
    supported = false;
    enabled = partnerPackage ? partnerPackage.auto_update : false;
  } else if (!isEmptyOrNull(website.auto_updates)) {
    enabled = !!website.auto_updates.find(u => u.type === row.type && u.name === row.folder_name);
  }
  let globalUpdateMask = false;
  if (supported && autoUpdaterConfig && autoUpdaterConfig.websites_update_status ===  'enabled') {
    const includedWebsites = autoUpdaterConfig.include_websites || [];
    const excludedWebsites = autoUpdaterConfig.exclude_websites || [];
    const included = includedWebsites.includes('all') || includedWebsites.includes(website.slug);
    const excluded = excludedWebsites.includes(website.slug);
    globalUpdateMask = included && !excluded;
  }
  const disabled = globalUpdateMask || !supported;
  let tooltip = isGit(row)
    ? `Automatic updates are not available for ${row.type}s managed via Git.`
    : `To adjust global ${row.type}s preferences, visit Global ${StringHelper.capitalizeFirstLetter(row.type)}s.`;
  if (supported && globalUpdateMask) {
    tooltip = `Update is automatically enabled. To adjust preferences, visit Global Settings > Auto Updater.`;
  }
  return !disabled ? (<div className='auto-update-cell'>
    <WPSToggle
      type='checkbox'
      className={loadingSlug === row.slug ? 'loadingspinner' : ''}
      name={row.name}
      id={row.slug}
      checked={enabled}
      onChange={e => callback(row, e.target.checked)}
    />
  </div>) : (<div className='auto-update-cell'>
    <div
      data-for={`${row.slug}_aupdate`}
      data-tip={tooltip}>
      <WPSToggle
        type='checkbox'
        className={loadingSlug === row.slug ? 'loadingspinner' : ''}
        name={row.name}
        id={row.slug}
        checked={enabled || (globalUpdateMask && supported)}
        disabled={true}
      />
      {row.slug && <ReactTooltip id={`${row.slug}_aupdate`} />}
    </div>
  </div>);
}
const renderMustInstallCell = (row, website) => {
  const style = getMustInstallStyle(row, website);
  const tooltip = style.tooltip;
  return (
    <span
      className={`must_install-cell ${style.colorClass}`}
      data-for={`${row.slug}_must_install`}
      data-tip={tooltip}>
      <Icon tag={style.icon} />
      {tooltip && <ReactTooltip id={`${row.slug}_must_install`} />}
    </span>
  );
}
const renderManagementCell = (row) => {
  const style = getManagementStyle(row);
  return (
    <span
      className={`scope-cell ${style.colorClass}`}
      data-for={`${row.slug}_scope`}
      data-tip={style.tooltip}>
      <Icon tag={style.icon} />
      {style.tooltip && <ReactTooltip id={`${row.slug}_scope`} />}
    </span>
  );
}
const renderActivateCell = (row, callback, loadingSlug) => {
  return (
    <WPSToggle
      type='checkbox'
      style={row.style || {}}
      className={(loadingSlug === row.slug ? 'loadingspinner' : '') + ' activate-cell'}
      name={row.display_name}
      id={row.slug}
      checked={row.is_active}
      disabled={row.is_active && row.type === 'theme'}
      onChange={(e) => callback(row, e.target.checked, e)}
    />
  );
}
const renderVersionCell = (row) => {
  let newVersionText = '';
  if (row.latest_version) {
    if (row.latest_version_url) {
      newVersionText = `Version ${row.latest_version} is available.`;
    } else {
      newVersionText = `Version ${row.latest_version} is available but only installable from the WordPress admin dashboard.`;
    }
  }
  let icon = { icon: 'warning', color: 'warning' };
  if (row.vulnerability) {
    newVersionText += (newVersionText ? '<br/><br/>' : '');
    newVersionText += `This ${row.type} has a known security vulnerability in versions ${row.vulnerability.version}.`;
    newVersionText += `<br/>To avoid security risks, please update to the latest version.`;
    icon = { icon: 'danger', color: 'danger' };
  }
  return (
    <div className='version-cell' style={row.style || {}}>
      <span>{row.version}</span>
      {newVersionText && JsxHelper.createBubble({
        icon: icon.icon,
        color: icon.color,
        tooltip: newVersionText,
      })}
    </div>
  );
}
const renderDisplayCell = (row) => {
  // If row author is a <a href="link">Author</a>, then extract the link and name.
  const authorLinkMatch = row.author.match(/<a href="(.*)">(.*)<\/a>/);
  if (authorLinkMatch) {
    row.author = authorLinkMatch[2];
    row.author_uri = authorLinkMatch[1];
  }
  return JsxHelper.createTableMultiLineCell({
    header: row.display_name,
    subheader: row.author_uri ? row.author : `By ${row.author}`,
    subheaderLink: row.author_uri || null,
    subheaderLinkPrefix: 'By ',
  })
}
const renderUpdateStatusCell = (row) => {
  const _class = '';
  if (row.update_status === STATUS_UPDATED) {
    return (<div className={_class}>
      <WPSBubble className='margin-0' display='inline' color='light' background={'success'}>
        Updated
      </WPSBubble>
    </div>);
  }
  if (row.update_status === STATUS_UPDATING) {
    return (<div className={_class}>
      <WPSBubble className='margin-0' display='inline' color='light' background={'primary'}>
        Updating...
      </WPSBubble>
    </div>);
  }
  if (row.update_status === STATUS_QUEUED) {
    return (<div className={_class}>
      <WPSBubble className='margin-0' display='inline' color='light' background={'info'}>
        Queued
      </WPSBubble>
    </div>);
  }
  if (row.update_status === STATUS_FAILED) {
    return (<div className={_class}>
      <WPSBubble
        className='margin-0'
        display='inline'
        color='light'
        background={'danger'}
        data-for={`${row.slug}_status_reason`}
        data-tip={row.update_error}
      >Failed</WPSBubble>
      {row.update_error && <ReactTooltip id={`${row.slug}_status_reason`} />}
    </div>);
  }
  return (<div className={_class}>
    <WPSBubble className='margin-0' display='inline' color='light' background={'warning'}>
      Not updated
    </WPSBubble>
  </div>);
}
const renderSizeCell = (row) => {
  return <span className='size-cell'>{isGit(row) ? row.dir_size : row.zip_size || '-'}</span>
}
const renderActionCell = (row) => {
  let updatedBy = row.user_email ? `By ${row.user_email}` : `Automatic`;
  if (row.reason) {
    updatedBy += ` ${row.reason.includes('vulnerable') ? 'security' : (row.reason.includes('direct') ? row.type : 'global')} update`;
  }
  return JsxHelper.createTableMultiLineCell({
    header: getActionFromURL(row),
    subheader: updatedBy,
  });
}
const renderSourceCell = (row) => {
  const type = getSourceURLType(row);
  const isGlobal = row.scope === 'global';
  const isPartner = row.scope === 'partner';
  const isGit = row.is_git;
  return JsxHelper.createIcon({
    icon: type === 'fileupload' ? 'fileupload' : (isGlobal || isPartner ? 'duplicate' : (isGit ? 'git' : 'linkicon')),
    tooltip: type === 'public' ? row.url : (type === 'fileupload' ? 'File upload' : (isGlobal || isPartner ? `Copied from a global ${row.type}` : (isGit ? 'Managed via Git' : 'Unknown'))),
    color: isGlobal ? 'alt' : (isPartner ? 'primary' : 'info'),
  });
}
const renderTypeIconCell = (row) => {
  return JsxHelper.createIcon({
    icon: row.type + 's',
    tooltip: row.type,
    color: row.type === 'plugin' ? 'info' : 'primary',
    style: { fontSize: '18px' },
  });
}
/* Table Header Items */
const renderScopeHeader = (managedOnly) => ({
  name: ' ', // For the sorting icon to appear
  selector: 'scope',
  sortable: true,
  searchable: true,
  width: '5%',
  minWidth: '5%',
  style: { margin: 'inherit !important' },
  cell: (row) => managedOnly
    ? renderManagementCell({...row, scope: row.website_slug ? 'private' : 'partner'})
    : renderManagementCell(row)
});
const renderDisplayNameHeader = (type, customWidth) => ({
  name: type ? StringHelper.toText(type) : 'Package',
  selector: 'display_name',
  sortable: true,
  searchable: true,
  width: customWidth || '26%',
  cell: renderDisplayCell,
});
const renderVersionHeader = () => ({
  name: 'Version',
  selector: 'version',
  searchable: true,
  width: '10%',
  cell: renderVersionCell,
});
const renderMustInstallHeader = (label, website) =>  ({
  name: label || 'Must Install',
  selector: 'must_install',
  width: '10%',
  sortable: true,
  cell: (row) => renderMustInstallCell(row, website)
});
const renderGlobalAutoUpdateHeader = (callback, loadingSlug) => ({
  name: 'Auto Update',
  selector: 'auto_update',
  sortable: true,
  width: '10%',
  cell: (row) => renderGlobalAutoUpdateCell(row, callback, loadingSlug),
});
const renderAutoUpdateHeader = (website, autoUpdaterConfig, callback, loadingSlug, partnerPackages) => ({
  name: 'Auto Update',
  selector: 'auto_update',
  sortable: true,
  width: '10%',
  cell: row => renderAutoUpdateCell(website, autoUpdaterConfig, row, callback, loadingSlug, partnerPackages),
});
const renderIsActiveHeader = (callback, loadingSlug) => ({
  name: 'Active',
  selector: 'is_active',
  sortable: true,
  width: '10%',
  cell: row => renderActivateCell(row, callback, loadingSlug),
});
const renderSourceCellHeader = () => ({
  name: 'Source',
  selector: 'source',
  width: '10%',
  cell: renderSourceCell,
});
const renderVersionUpdateHeader = () => ({
  name: 'Update',
  selector: 'version',
  searchable: true,
  width: '12%',
  cell: (row) => JsxHelper.createTableCellFromTo({
    from: row._from,
    to: row._to,
  }),
});
const renderUpdateStatusHeader = () => ({
  name: 'Status',
  selector: 'update_status',
  width: '15%',
  cell: renderUpdateStatusCell,
});
const renderSizeHeader = () => ({
  name: 'Size',
  selector: 'zip_size',
  width: '10%',
  cell: renderSizeCell,
});
const renderTypeHeader = (label) => ({
  name: label || 'Type',
  selector: 'type',
  width: '5%',
  cell: renderTypeIconCell,
});
const renderWebsiteHeader = (websites, width) => ({
  name: 'Website',
  selector: 'website_slug',
  width: width || '30%',
  sortable: true,
  searchable: true,
  cell: (row) => {
    const website = websites.find(w => w.slug === row.website_slug);
    if (website) {
      TableHelper.customizeCellValue(row, 'website_slug', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
    }
    return JsxHelper.createTableWebsiteCell({ website, })
  },
})
/* Table Headers */
const activeOnHeaders = (websites) => [
  renderScopeHeader(),
  {
    name: 'Website',
    selector: 'website_slug',
    sortable: true,
    searchable: true,
    width: '28%',
    cell: (row) => {
      const website = websites.find(w => w.slug === row.website_slug);
      if (website) {
        TableHelper.customizeCellValue(row, 'website_slug', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
      }
      return JsxHelper.createTableWebsiteCell({
        website,
      })
    },
  },
  renderVersionHeader(),
];

const manageUpdatesHeaders = (type, setUpdaterStatus, updateQueueItem, updatingAll) => [
  renderScopeHeader(), // 5%
  renderDisplayNameHeader(StringHelper.toText(type)), // 26%
  renderSourceCellHeader(), // 10%
  renderVersionUpdateHeader(), // 12%
  renderUpdateStatusHeader(), // 15%
  {
    name: '',
    selector: 'version',
    width: '32%',
    cell: (row) => (row.update_status !== STATUS_UPDATED && row.update_status !== STATUS_UPDATING)
    ? TableHelper.createActionIcon('close', () => setUpdaterStatus(prev => prev.filter(p => p.slug !== row.slug)))
    : (row.update_status === PackageHelper.STATUS_FAILED ? TableHelper.createActionIcon('refresh', updateQueueItem(row)) : null),
  },
];

const PackageHelper = {
  // Getters
  isManaged,
  isUnmanaged,
  isInstalled,
  isGlobalOrPartner,
  isGlobal,
  isPartner,
  isGit,
  scopeCompare,
  normalize,
  normalizeData,
  activesList,
  getSourceURLType,
  // Cells
  renderAutoUpdateCell,
  renderMustInstallCell,
  renderActivateCell,
  renderVersionCell,
  renderDisplayCell,
  renderUpdateStatusCell,
  renderInstalledVersionCell,
  renderLatestVersionCell,
  renderSizeCell,
  renderActionCell,
  renderTypeIconCell,
  renderSourceCell,
  renderManagementCell,
  // Header Items
  renderScopeHeader,
  renderDisplayNameHeader,
  renderVersionHeader,
  renderMustInstallHeader,
  renderAutoUpdateHeader,
  renderGlobalAutoUpdateHeader,
  renderIsActiveHeader,
  renderSourceCellHeader,
  renderVersionUpdateHeader,
  renderUpdateStatusHeader,
  renderSizeHeader,
  renderTypeHeader,
  renderWebsiteHeader,
  // Others
  pageHeader,
  sortPackages,
  // Headers
  activeOnHeaders,
  manageUpdatesHeaders,
  // Updater
  STATUS_FAILED,
  STATUS_QUEUED,
  STATUS_UPDATED,
  STATUS_UPDATING,
  setUpdaterItemStatus,
  setManageUpdatesMode,
};

export default PackageHelper;
