import { useEffect, useRef, useCallback } from 'react';

function deepCompareEquals(a, b){
  let modA = a;
  let modB = b;
  if(modA && (typeof modA === "object" || Array.isArray(modA))) {
    modA = JSON.stringify(a);
  }
  if(modB && (typeof modB === "object" || Array.isArray(modB))) {
    modB = JSON.stringify(b);
  }
  return modA === modB;
}

function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export function useDeepCompareCallback(callback, dependencies) {
  return useCallback(callback, useDeepCompareMemoize(dependencies))
}