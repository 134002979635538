import React, { Fragment, useState, useCallback } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import StripePaymentModal from 'components/stripe/stripePaymentModal';
import useConfirm from 'hooks/useConfirm';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  addCustomerPaymentMethod,
  defaultCustomerPaymentMethod,
  deleteCustomerPaymentMethod,
} from 'store/customer/customerActions';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { integrationSelector } from 'store/me/meSelectors';
import StripeConnect from 'components/stripe/stripeconnect';
import { addPaymentMethod, editPaymentMethod, deletePaymentMethod } from 'services/stripe';
import { getCustomerPaymentMethods } from 'store/customer/customerSelectors';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const PaymentMethods = () => {
  useTitle('Client Credit Cards');
  const dispatch = useDispatch();
  const [stripeForm, setStripeForm] = useState(false);
  const { params } = useRouteMatch();
  const patmentMethods = useSelector(getCustomerPaymentMethods(params.customerSlug));
  const confirm = useConfirm();
  const integrations = useSelector(integrationSelector);

  const openStripeForm = useCallback(() => {
    setStripeForm(true);
  }, []);

  const closeStripeForm = useCallback(() => {
    setStripeForm(false);
  }, []);

  const onPaymentMethodCreated = useCallback(
    async data => {
      // Check if the returned payload has any error or undefined
      if (!data || !data.data || data.data.error) {
        return false;
      }
      // Call API to add payment method
      const res = await addPaymentMethod({
        ...data,
        customer_slug: params.customerSlug,
      });
      // Add to redux store
      await dispatch(
        addCustomerPaymentMethod({
          data: res,
          slug: params.customerSlug,
        }),
      );
      return true;
    },
    [params.customerSlug, dispatch],
  );

  const removeItem = useCallback(
    async item => {
      try {
        await DialogHelper.confirmDelete(confirm, item.last4, 'Credit Card');
        const res = await deletePaymentMethod({
          customer_slug: params.customerSlug,
          guid: item.guid,
        });
        await dispatch(
          deleteCustomerPaymentMethod({
            data: res,
            slug: params.customerSlug,
          }),
        );
        dispatch(setGlobalSuccessMsg({ model: 'credit-card', action: 'deleted' }));
      } catch (error) {
        dispatch(setGlobalErrorMsg(error));
      }
    },
    [confirm, dispatch, params.customerSlug],
  );

  const setAsDefault = useCallback(
    async item => {
      try {
        const res = await editPaymentMethod({
          customer_slug: params.customerSlug,
          guid: item.guid,
          is_default: true,
        });
        await dispatch(
          defaultCustomerPaymentMethod({
            data: res,
            slug: params.customerSlug,
          }),
        );
        dispatch(setGlobalSuccessMsg({ model: 'credit-card', action: 'updated' }));
      } catch (error) {
        dispatch(setGlobalErrorMsg(error));
      }
    },
    [dispatch, params.customerSlug],
  );

  const actions = [
    {
      value: 'Set Default',
      onClick: setAsDefault,
      doHide: item => item.is_default,
    },
    {
      value: 'Delete',
      onClick: removeItem,
    },
  ];

  const headers = [
    JsxHelper.createTableCreditCardTypeIconHeader('type', '8%'),
    JsxHelper.createTableTextHeader('last4', 'Last 4', '8%'),
    JsxHelper.createTableStatusHeader('status', null, true, '10%'),
    JsxHelper.createTableTextHeaderWithCallback('exp_year', 'Expiry', '10%', (row) => JsxHelper.createBubble({
      text: row.exp_month + ' / ' + row.exp_year,
      background: 'info',
      small: true,
    })),
    JsxHelper.createTableBinaryBubbleHeader('is_default', 'Default', '10%'),
    JsxHelper.createTableActionsHeader(actions, '53%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title>Credit Cards</TitleBar.Title>
        <TitleBar.Actions>
          {integrations.stripe && integrations.stripe.publishable_key && (
            JsxHelper.createButton({ label: 'Add New Card', onClick: openStripeForm })
          )}
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {integrations.stripe && integrations.stripe.publishable_key ? (
          <Fragment>
            <WPSDataTable
              columns={headers}
              body={patmentMethods}
              noSearchOnTable={true}
              rowsPerPage={100}
              dataKey='guid'
            />
            <StripePaymentModal
              show={stripeForm}
              onClose={closeStripeForm}
              stripeKey={integrations.stripe.publishable_key}
              onPaymentMethodCreated={onPaymentMethodCreated}
            />
          </Fragment>
        ) : (
          <StripeConnect />
        )}
      </Content>
    </Fragment>
  );
};

export default PaymentMethods;
