import React, { useState, useEffect, Fragment } from 'react';
import useQuery from 'hooks/useQuery';
import { AuthContainer, ImageWrapper, FormWrapper } from 'styles/auth';
import { useForm } from 'react-hook-form';
import { ErrorMsg, WPSLabel, WPSForm, WPSInput } from 'styles/layout/forms';
import EmailFailed from 'assets/email_failed.png';
import { WPS_LOGO_FULL } from 'assets/wps_logo';
import { RingLoader } from 'styles/layout/loaders';
import { WPSButton } from 'styles/layout/buttons';
import { InlineLoader } from 'styles/layout/loaders';
import GuestService from 'services/guest';
import { Link } from 'react-router-dom';
import useTitle from 'hooks/useTitle';

const PasswordReset = () => {
  useTitle('Password Reset');
  const query = useQuery();
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [user, setUser] = useState({ token: '', email: '', password: '', password2: '' });
  const [valid, setValid] = useState(false);
  const [error, setError] = useState();
  const [response, setResponse] = useState({
    success: false,
    message: '',
  });

  useEffect(() => {
    const token = query.get('token');
    const email = query.get('email');
    setUser(prev => ({ ...prev, token, email }));
    if (token && email) {
      const data = { token, email };
      GuestService.validateResetToken(data)
        .then(() => setValid(true))
        .catch(err => setError(err.data))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  const handleOnChange = e => {
    const { name, value } = e.target;
    setUser(prev => ({ ...prev, [name]: value }));
  };

  const resetPassword = () => {
    setBtnLoading(true);
    const data = { email: user.email, token: user.token, new_password: user.password };

    GuestService.resetPassword(data)
      .then(() =>
        setResponse({ success: true, message: 'Password has been successfully reset.' }),
      )
      .catch(err => setResponse({ success: false, message: err.data.error }))
      .finally(() => setBtnLoading(false));
  };

  return (
    <AuthContainer>
      <ImageWrapper>
        <img src={EmailFailed} alt='password reset' />
        <WPS_LOGO_FULL />
      </ImageWrapper>
      <FormWrapper flex='1'>
        <WPS_LOGO_FULL inverted />
        {!response.success && (
          <Fragment>
            {loading ? (
              <Fragment>
                <h1>Validating token...</h1>
                <RingLoader color='primary' size='40' margin='16px 0' />
              </Fragment>
            ) : valid ? (
              <Fragment>
                <h1>Reset your password</h1>
                <WPSForm onSubmit={handleSubmit(resetPassword)} style={{ minWidth: '320px' }}>
                  <WPSLabel htmlFor='password' required>
                    Password {errors.password && <ErrorMsg>{errors.password.message}</ErrorMsg>}
                  </WPSLabel>
                  <WPSInput
                    type='password'
                    name='password'
                    value={user.password}
                    onChange={handleOnChange}
                    ref={register({ required: 'This field is required' })}
                  />
                  <WPSLabel htmlFor='password2' required>
                    Confirm Password
                    {errors.password2 && <ErrorMsg>{errors.password2.message}</ErrorMsg>}
                  </WPSLabel>
                  <WPSInput
                    type='password'
                    name='password2'
                    value={user.password2}
                    onChange={handleOnChange}
                    ref={register({
                      required: 'This field is required',
                      validate: value => {
                        if (value !== user.password) {
                          return 'Password confirmation does not match';
                        }
                      },
                    })}
                  />

                  <WPSButton className='info--btn' disabled={btnLoading}>
                    {btnLoading ? <InlineLoader /> : 'Reset Password'}
                  </WPSButton>
                </WPSForm>
              </Fragment>
            ) : (
              <h1>{error ? error.error : 'An unknown error occurred'}</h1>
            )}
          </Fragment>
        )}
        {response.message && (
          <div className={`message ${response.success ? 'success' : 'error'}`}>
            {response.message}
          </div>
        )}
        {response.success && (
          <Link to='/login' className='secondary-action'>
            Go to login page
          </Link>
        )}
      </FormWrapper>
    </AuthContainer>
  );
};

export default PasswordReset;
