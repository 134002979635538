import React, { useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSLabel, WPSInput, ErrorMsg } from 'styles/layout/forms';
import { useForm } from 'react-hook-form';
import { WPSButton } from 'styles/layout/buttons';
import { TableWrapper } from 'styles/website/index';
import { Row } from 'styles/layout/grid';
import FormHelper from 'helpers/form';
import WebsiteService from 'services/website';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import { Container } from 'styles/website/profile';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { getErrorMsg, isEmptyOrNull } from 'helpers';
import useModal from 'hooks/useModal';
import DialogHelper from 'helpers/dialog';
import DateHelper from 'helpers/date';
import JsxHelper from 'helpers/jsx';

const DatabaseRunQueries = ({ slug }) => {
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    website_slug: slug,
    sql: '',
  });
  const [output, setOutput] = useState(null);
  const [showOutputTable, setShowOutputTable] = useState(false);
  const [outputHeaders, setOutputHeaders] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');
  const [outputInfo, setOutputInfo] = useState('');
  const [tableMinWidth, setTableMinWidth] = useState('800px');

  const onChange = e => {
    const { name, value } = e.target;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  const showOutputinModal = data => DialogHelper.plaintext(modalDialog, data.toString());

  const reCalculateTableWidth = columnsCount => {
    // set width 150px for every column
    const width = columnsCount * 150 + 'px';
    setTableMinWidth(width);
  };

  const initTableHeaders = keys => {
    const columns = keys.map((keyEl) => ({
      name: keyEl,
      selector: keyEl,
      sortable: true,
      searchable: true,
      cell: row => {
        if (!isEmptyOrNull(row[keyEl]) && row[keyEl].toString().length > 100) {
          return JsxHelper.createIcon({
            icon: 'expand',
            color: 'info',
            customClass: 'margin-left-25-perc',
            onClick: () => showOutputinModal(row[keyEl])
          })
        } else {
          return row[keyEl];
        }
      },
    }));
    setOutputHeaders(columns);
  };

  const initDataForTable = _data => {
    let values = Object.values(_data);
    let keys = Object.keys(_data);
    if (keys.length > 4) {
      reCalculateTableWidth(keys.length);
    }
    initTableHeaders(keys);
    let rowItem = {};

    let _formattedData = [];
    for (let i in values[0]) {
      for (let key in keys) {
        rowItem[keys[key]] = values[key][i];
      }
      _formattedData.push(rowItem);
      rowItem = {};
    }
    setOutput(_formattedData);
    setShowOutputTable(true);
  };

  const onSubmit = () => {
    setLoading(true);
    setWarningMessage('');
    setShowOutputTable(false);
    setOutputInfo(false);
    const startedAt = DateHelper.now();
    WebsiteService.databaseRunQuery(details)
      .then(res => {
        if (!isEmptyOrNull(res.table_data)) {
          initDataForTable(res.table_data);
        }
        if (!isEmptyOrNull(res.warnings)) {
          setWarningMessage(res.warnings[0]);
        }
        setGlobalSuccessMsg({ model: 'Database query', action: 'executed' });
        setOutputInfo({ message: `Query was successfully executed in ${DateHelper.now().diff(startedAt, 'seconds')} seconds.` });
      })
      .catch(err => {
        dispatch(setGlobalErrorMsg(err));
        let errorMsg = getErrorMsg(err);
        if (errorMsg.includes('You have an error in your SQL syntax')) {
          errorMsg += ' Are you sure you are using MariaDB syntax?';
        }
        setOutputInfo({ error: `Query failed to execute: ${errorMsg}` });
      }).finally(() => setLoading(false));
  };

  const showWarningMessage = () => DialogHelper.info(modalDialog, warningMessage);

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title>Run SQL Query</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader padding-left-0'>
        Execute SQL queries using {JsxHelper.createLink('https://mariadb.com/kb/en/sql-statements/', 'MariaDB syntax', 'font-bold font-underlined')}; for a list of supported tables, {JsxHelper.createRelativeLink(`/websites/${slug}/advanced/tables`, 'click here', 'font-bold font-underlined')}.
      </p>
      <WPSForm onSubmit={e => e.preventDefault()}>
        <WPSLabel required>
          SQL query:
          {errors.sql && <ErrorMsg>{errors.sql.message}</ErrorMsg>}
        </WPSLabel>
        <WPSInput
          className='width-30'
          type='text'
          name='sql'
          value={details.sql}
          onChange={onChange}
          ref={register({ required: FormHelper.messages.required })}
        />
      </WPSForm>
      <Row className='action-buttons'>
        <WPSButton
          className='execute--btn'
          type='button'
          onClick={handleSubmit(onSubmit)}
          loading={loading}>
          Execute
        </WPSButton>
        {!isEmptyOrNull(warningMessage) && (
          <WPSButton className='notice--btn' type='button' onClick={showWarningMessage}>
            Notice
          </WPSButton>
        )}
      </Row>
      {outputInfo && <div className='margin-top-20'>
        <p className='subheader padding-left-0'>
          {outputInfo.message && <span className='success-font-color'>{outputInfo.message}</span>}
          {outputInfo.error && <span className='danger-font-color'>{outputInfo.error}</span>}
        </p>
      </div>}
      {showOutputTable && (
        <TableWrapper width={tableMinWidth}>
          <WPSDataTable
            customClass={'margin-top-20 table-small-font'}
            columns={outputHeaders}
            body={output || []}
            noSearchOnTable={true}
            rowsPerPage={100}
          />
        </TableWrapper>
      )}
    </Container>
  );
};

export default DatabaseRunQueries;
