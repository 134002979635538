import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// Components
import Can from '../../utils/can';
// Styles
import { TitleBar } from '../../styles/layout/titlebar';
import { WPSButton } from '../../styles/layout/buttons';
import { useSelector } from 'react-redux';
import { globalCoresSelector } from '../../store/globalPackage/globalPackageSelector';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { sortByField } from 'helpers';
import JsxHelper from 'helpers/jsx';

const Plugins = () => {
  useTitle('WordPress');
  const data = useSelector(globalCoresSelector);
  const headers = [
    JsxHelper.createTableTimeHeader('created_at', null, '200px', 'Upload Time'),
    JsxHelper.createTableTextHeader('version', 'Version', '200px'),
    JsxHelper.createTableTextHeader('zip_size', 'Size', '200px'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'WordPress',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Wordpress</TitleBar.Title>
        <TitleBar.Actions>
          <Can
            perform='wordpress:create:*'
            yes={() => (
              <NavLink to='/wordpress/create'>
                <WPSButton className='upload--btn'>+ Upload New Version</WPSButton>
              </NavLink>
            )}
          />
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable columns={headers} body={sortByField(data,'version','desc')} />
      </Content>
    </Fragment>
  );
};

export default Plugins;
