import { postRequest } from 'config/axiosRequest';
import env from 'config/env';

const generateToken = data => {
  return postRequest({
    url: env.buildApiEndpoint('migrator-plugin/token'),
    data,
  });
};

const MigratorService = { generateToken };

export default MigratorService;
