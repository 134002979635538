import React, { useState, useEffect, useRef, Fragment } from 'react';
import { WPSForm } from 'styles/layout/forms';
import { useDispatch } from 'react-redux';
import JsxHelper from 'helpers/jsx';
import SendGridService from 'services/sendgrid';
import WebsiteHelper from 'helpers/website';
import SMTPHelper from 'helpers/smtp';
import SendGridHelper from 'helpers/sendgrid';
import DialogHelper from 'helpers/dialog';
import env from 'config/env';
import { setGlobalErrorMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import { isEmptyOrNull, displayResponseError, isObject } from 'helpers';
import { updateWebsiteSendGridSMTPConfig } from 'store/website/websiteActions';
import useModal from 'hooks/useModal';
import UserHelper from 'helpers/user';
import 'components/stepper/stepper.css';

const SMTPForm = ({ website, onSaveSMTPSettings, onAccountsLoaded }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const mounted = useRef(true);
  const modalDialog = useModal();
  const websiteSMTPConfig = WebsiteHelper.getSMTPMailerConfig(website);
  const hasSMTPConfig = websiteSMTPConfig && isObject(websiteSMTPConfig) && websiteSMTPConfig.mail;
  const [sendGridData, setSendGridData] = useState({
    loading: false,
    loaded: false,
    accounts: [],
    from_email_prefix: hasSMTPConfig && websiteSMTPConfig.mail.from_email ? websiteSMTPConfig.mail.from_email.split('@')[0] : 'noreply',
    from_email_name: hasSMTPConfig && websiteSMTPConfig.mail.from_name ? websiteSMTPConfig.mail.from_name : 'No Reply',
  });

  useEffect(() => {
    fetchSendGridAccounts();
    return () => {
      mounted.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const handleErrorResponse = (error) => displayResponseError(modalDialog, error);

  // ========================================
  // SendGrid
  // ========================================

  // Getters

  const getCurrentSendGridAccount = () => {
    if (isEmptyOrNull(sendGridData.selectedAccount)) return null;
    return sendGridData.accounts.find(acc => acc.email === sendGridData.selectedAccount);
  }

  const getWebsiteSendGridAuthDomain = () => {
    const account = getCurrentSendGridAccount();
    if (!account) return null;
    return account.auth_domains.find(domain => domain.domain === website.registrable_domain);
  }

  // API

  const fetchSendGridAccounts = () => {
    if (sendGridData.loading || sendGridData.loaded) return;
    setSendGridData(prev => ({ ...prev, loading: true }));
    let data = {};
    if (UserHelper.isAdminOrAgent()) {
      data.partner_slug = website.partner_slug;
    }
    SendGridService.listAccounts(data)
      .then(accounts => {
        if (!mounted.current) return;
        onAccountsLoaded && onAccountsLoaded(accounts);
        if (!isEmptyOrNull(accounts)) {
          setSendGridData(prev => ({ ...prev, selectedAccount: accounts[0].email }));
        }
        setSendGridData(prev => ({ ...prev, accounts, loaded: true }));
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => mounted.current && setSendGridData(prev => ({ ...prev, loading: false })));
  };

  const connectSendGridAccount = () => {
    if (modal.loading) return;
    setModal({ ...modal, loading: true });
    SendGridService.connectAccount({ api_key: modal.api_key })
    .then(newAccount => {
      if (!mounted.current) return;
      setSendGridData(prev => ({
        ...prev,
        accounts: [...prev.accounts, newAccount],
        selectedAccount: newAccount.email
      }));
      setModal(false);
    })
    .catch(handleErrorResponse)
    .finally(() => mounted.current && setModal(prev => ({ ...prev, loading: false })));
  }

  const authenticateDomain = () => {
    if (sendGridData.authenticating) return;
    setSendGridData(prev => ({ ...prev, authenticating: true }));
    const account = getCurrentSendGridAccount();
    if (!account) return;
    SendGridService.authenticateDomain({ guid: account.guid, domain: website.registrable_domain, automated_security: true })
      .then((account) => {
        if (!mounted.current) return;
        // Update account with new auth domain.
        setSendGridData(prev => ({
          ...prev,
          accounts: prev.accounts.map(acc => acc.email === account.email ? account : acc)
        }));
        window.logHelper.info('Domain authenticated:', account);
      })
      .catch(handleErrorResponse)
      .finally(() => mounted.current && setSendGridData(prev => ({ ...prev, authenticating: false })));
  }

  const verifyDomainAuth = (item) => {
    if (sendGridData.validating) return;
    setSendGridData(prev => ({ ...prev, validating: true }));
    const account = getCurrentSendGridAccount();
    if (!account) return;
    SendGridService.verifyDomainRecords({ guid: account.guid, domain_id: item.id })
      .then((account) => {
        if (!mounted.current) return;
        // Update account with new auth domain.
        setSendGridData(prev => ({
          ...prev,
          accounts: prev.accounts.map(acc => acc.email === account.email ? account : acc)
        }));
        window.logHelper.info('Domain verified:', account);
      })
      .catch(handleErrorResponse)
      .finally(() => mounted.current && setSendGridData(prev => ({ ...prev, validating: false })));
  }

  const saveSendGridSMTPSettings = () => {
    if (sendGridData.saving) return;
    const fromPrefix = sendGridData.from_email_prefix;
    if (isEmptyOrNull(fromPrefix) || /[@. ]/.test(fromPrefix)) {
      return DialogHelper.warning(modalDialog, 'Please enter a valid from email without the domain.');
    }
    const fromName = sendGridData.from_email_name;
    if (isEmptyOrNull(fromName)) {
      return DialogHelper.warning(modalDialog, 'Please enter a valid from name.');
    }
    const account = getCurrentSendGridAccount();
    if (!account) {
      return DialogHelper.warning(modalDialog, 'Please select a SendGrid account.');
    }
    const authDomain = getWebsiteSendGridAuthDomain();
    if (!authDomain || !authDomain.valid) {
      return DialogHelper.warning(modalDialog, 'Please authenticate the domain before saving the settings.');
    }
    const data = {
      website_slug: website.slug,
      guid: account.guid,
      from_email: `${fromPrefix}@${authDomain.domain}`,
      from_name: fromName,
    };
    setSendGridData(prev => ({ ...prev, saving: true }));
    dispatch(updateWebsiteSendGridSMTPConfig(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'SendGrid SMTP settings', onId: WebsiteHelper.getLabel(website), action: 'saved', plural: true, }));
        onSaveSMTPSettings && onSaveSMTPSettings();
      })
      .catch(handleErrorResponse)
      .finally(() => mounted.current && setSendGridData(prev => ({ ...prev, saving: false })))
  }

  // Render

  const renderConnectSendGridAccount = () =>
    <div className='margin-top-24'>
      <p className='color-primary'>
        Connect your SendGrid account to {env.getBrandShortName()} to automate your SendGrid workflow and send emails easily.
      </p>
      {JsxHelper.createButton({
        label: 'Connect Account',
        onClick: () => setModal({ name: 'create-sendgrid-account', loading: false, api_key: '' }),
        disabled: modal.loading || UserHelper.isAdminOrAgent(),
        style: { marginTop: '20px' }
      })}
    </div>;

  const renderSendGridExistingValidDomain = (authDomain) => 
    <Fragment>
      {JsxHelper.createSuccessBox(<div>Your <b>{authDomain.domain}</b> domain has been verified by SendGrid, and SMTP settings are configured in the {SMTPHelper.PLUGIN_NAME} plugin automatically.</div>)}
    </Fragment>
  
  const renderSendGridExistingInvalidDomain = (authDomain) =>
    <Fragment>
      {JsxHelper.createWarningBox(<div>Your <b>{authDomain.domain}</b> domain has not been verified yet. Please verify the DNS records and try again.</div>, true)}
      <div className='action-buttons display-flex-nowrap'>
        {JsxHelper.createButton({
          label: 'View DNS Records',
          onClick: () => SendGridHelper.openDNSRecordsModal(modalDialog, dispatch, verifyDomainAuth, authDomain),
          style: { marginLeft: 0 }
        })}
        {JsxHelper.createButton({
          label: 'Verify DNS Records',
          classes: 'warning--btn',
          onClick: () => verifyDomainAuth(authDomain),
          loading: sendGridData.validating,
        })}
      </div>
    </Fragment>

  const renderSendGridNewDomain = () =>
    <Fragment>
      {JsxHelper.createTipBox(<div>This process will set up 'Sender Authentication' in SendGrid for <b>{website.registrable_domain}</b> domain. This authentication allows SendGrid to send emails securely on behalf of your domain.</div>, true)}
      {JsxHelper.createButton({
        classes: 'info--btn',
        label: 'Add Domain',
        onClick: authenticateDomain,
        loading: sendGridData.authenticating,
        style: { marginTop: '20px' }
      })}
    </Fragment>

  const renderSendGridAccount = () => {
    const authDomain = getWebsiteSendGridAuthDomain();
    return <div className='margin-top-24'>
      {authDomain
        ? (authDomain.valid ? renderSendGridExistingValidDomain(authDomain) : renderSendGridExistingInvalidDomain(authDomain))
        : renderSendGridNewDomain()}
    </div>
  }

  const renderSendGridAccounts = () => <Fragment>
    {JsxHelper.createSelectInput({
      label: 'Select SendGrid Account',
      name: 'sendgrid_account',
      options: sendGridData.accounts.map(account => ({ value: account.email, label: account.email })),
      onChange: e => setSendGridData(prev => ({ ...prev, selectedAccount: e.target.value })),
      value: sendGridData.selectedAccount,
      style: { marginTop: '20px' }
    })}
    {renderSendGridAccount(sendGridData.selectedAccount)}
  </Fragment>;

  const renderSendGridSMTPSettings = (authDomain) => 
    <WPSForm.Row className='smtp-mailer smtp-integration'>
      <WPSForm.RowItem>
        {JsxHelper.createTextInput({
          class: 'input-wrapped',
          label: 'From email',
          name: 'from_email_prefix',
          value: sendGridData.from_email_prefix,
          suffix: `@${authDomain.domain}`,
          tooltip: 'The prefix for the email address that will be used to send emails from your domain.',
          onChange: e => {
            const value = e && e.target ? e.target.value : '';
            setSendGridData(prev => ({ ...prev, from_email_prefix: value }));
          }
        })}
      </WPSForm.RowItem>
      <WPSForm.RowItem>
        {JsxHelper.createTextInput({
          name: 'from_email_name',
          label: 'From name',
          value: sendGridData.from_email_name,
          tooltip: 'The name that will be displayed as the sender of the email.',
          onChange: e => {
            const value = e && e.target ? e.target.value : '';
            setSendGridData(prev => ({ ...prev, from_email_name: value }));
          }
        })}
      </WPSForm.RowItem>
    </WPSForm.Row>

  const renderSendGridIntegration = () => {
    if (sendGridData.loading) {
      return JsxHelper.createTableLoader('Loading SendGrid accounts...');
    } else if (isEmptyOrNull(sendGridData.accounts)) {
      return renderConnectSendGridAccount();
    } else {
      return renderSendGridAccounts();
    }
  }

  const renderSendGridContent = () => {
    const authDomain = getWebsiteSendGridAuthDomain();
    return <div className='margin-top-24 margin-bottom-24'>
      <WPSForm.Fieldset>
        <legend>SendGrid</legend>
        {renderSendGridIntegration()}
      </WPSForm.Fieldset>
      {authDomain && authDomain.valid && <Fragment>
        <WPSForm.Fieldset>
          <legend>Mail</legend>
          {renderSendGridSMTPSettings(authDomain)}
        </WPSForm.Fieldset>
        {JsxHelper.createButton({
          label: 'Save',
          style: { marginTop: '20px' },
          loading: sendGridData.saving,
          onClick: saveSendGridSMTPSettings
        })}
      </Fragment>}
    </div>;
  }

  // -------- RENDER --------

  return (<Fragment><div className='sendgrid-content'>
    {renderSendGridContent()}
  </div>
  {modal && modal.name === 'create-sendgrid-account' && DialogHelper.inputs({
    title: 'Connect SendGrid Account',
    className: 'instruct-modal',
    onClose: () => setModal(false),
    onConfirm: connectSendGridAccount,
    loading: modal.loading,
    icon: 'sendgrid',
    iconColor: 'info',
    confirmBtn: 'Connect',
    confirmColor: 'success',
    disabled: isEmptyOrNull(modal.api_key),
    header: SendGridHelper.renderConnectSteps(),
    inputs: [{
      label: 'API Key',
      name: 'api_key',
      placeholder: 'Enter your SendGrid API key',
      value: modal.api_key,
      onChange: e => {
        const apiKey = e && e.target ? e.target.value : '';
        setModal(prev => ({ ...prev, api_key: apiKey }))
      },
      required: true,
    },
  ]})}
  </Fragment>);
}

export default SMTPForm;