import React from 'react';
import styled from 'styled-components';
import Icon from 'components/layout/icon';

export const ImportantNoticeBar = styled.div`
  min-height: 46px;
  background: ${props => props.theme.background[props.background]};
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-size: 16px;
  color: white;
  z-index: 100;
`;

export const UserTopBar = styled.div`
  min-height: 46px;
  background: #f0f0f0;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  z-index: 100;
`;

export const NotificationBarList = styled.ul`
  position: absolute;
  right: 0;
  top: 110%;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  z-index: 130;
  min-width: 200px;
  padding: 12px;
  border-radius: 4px;
  text-align: left;

  li {
    color: ${({ theme }) => theme.color.primary};
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      font-size: 14px;
      margin-right: 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.table.oddRows};
    }

    &:hover {
      background: #f1f4ff;
    }
  }
`;

export const WarningBox = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 8px;
  color: #8a6d3b;
  text-align: justify;
  font-size: 15px;
  line-height: 1.3em;
  background-color: #fcf8e3;
  border-color: #faebcc;
  border: 1px solid bisque;
  border-radius: 4px;

  .title {
    font-weight: bold;
    font-size: 16px;
  }
`;

const Item = ({ icon, children, ...rest }) => {
  return (
    <li {...rest}>
      {icon && <Icon tag={icon} />}
      {children}
    </li>
  );
};

NotificationBarList.Item = Item;

export const HelpButton = styled.button`
  position: relative;
  width: 26px;
  height: 26px;
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  margin-right: 12px;
  svg {
    font-size: 18px;
  }
`;
