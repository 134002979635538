import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { customersSelector } from 'store/customer/customerSelectors';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import Can from 'utils/can';
import { deleteCustomer } from 'store/customer/customerActions';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalWarningMsg,
} from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import CustomerService from 'services/customer';
import { customerSubscriptions } from 'store/customerBilling/customerBillingSelectors';
import { fetchCustomerSubscriptions } from 'store/customerBilling/customerBillingActions';
import { isEmpty, handleFilterURLParamsChanges, convertToFilterObject, isEmptyOrNull } from 'helpers';
import { Content } from 'styles/globalStyles';
import { websitesSelector } from 'store/website/websiteSelectors';
import { integrationSelector } from 'store/me/meSelectors';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { fetchWebsites } from 'store/website/websiteActions';
import useOnClickOutside from 'utils/useOnClickOutside';
import useQuery from 'hooks/useQuery';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import useConfirm from 'hooks/useConfirm';
import DialogHelper from 'helpers/dialog';

const Account = () => {
  useTitle('Client List');
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const confirm = useConfirm();
  const customers = useSelector(customersSelector);
  const websites = useSelector(websitesSelector);
  const integrations = useSelector(integrationSelector);
  const [subscriptions, setSubscriptions] = useState(useSelector(customerSubscriptions));
  const [customerToWebsites, setCustomerToWebsites] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState({});
  const [copyLoading, setCopyLoading] = useState({});
  const mounted = useRef(true);

  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  // Handle on Click outside for the filters menu
  const filtersRef = useRef();
  useOnClickOutside(filtersRef, () => setShowFilters(false));

  // Fetch all customer subscriptions.
  useEffect(() => {
    if (isEmptyOrNull(subscriptions)) {
      fetchSubscriptionsData();
    } else {
      initCustomerToWebsites(subscriptions);
    }

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(customers)) {
      // Filter options
      const name = customers.map(p => p.display_name);
      const profile = customers.map(row => {
        const status = row.metadata.profile_setup_status;
        let text = 'Incomplete';
        if (status === 'unread') {
          text = 'Unread';
        } else if (status === 'read') {
          text = 'In Progress';
        } else if (status === 'completed') {
          text = 'Completed';
        }
        return text;
      });
      setFilters(prev => ({
        ...prev,
        ...convertToFilterObject(query, 'Client', new Set(name)),
        ...convertToFilterObject(query, 'Profile Setup', new Set(profile)),
      }));
    }
    // eslint-disable-next-line
  }, [customers]);

  // Handle filters add/remove from URL.
  useEffect(() => {
    if (!isEmpty(customers)) {
      handleFilterURLParamsChanges(filters, history);
    }
    // eslint-disable-next-line
  }, [filters]);

  // Fetch all customer subscriptions.
  const fetchSubscriptionsData = () => {
    let data = {
      plan_slug: '',
      subscriber_type: 'customer'
    };
    setFetchLoading(true);
    dispatch(fetchCustomerSubscriptions(data))
      .then((res) => {
        setSubscriptions(res);
        initCustomerToWebsites(res);
      })
      .finally(() => setFetchLoading(false));
  }

  const initCustomerToWebsites = (_subscriptions) => {
    let customerToWebsites = {};
    for (const s of _subscriptions) {
      if (!s.resources) {
        window.logHelper.warning('Subscription has no resources', s);
        continue;
      }
      for (const r of s.resources) {
        if (r.type === 'website') {
          const w = websites.find(_w => _w.slug === r.slug);
          if (!w) {
            continue;
          }
          if (customerToWebsites[s.subscriber_slug]) {
            customerToWebsites[s.subscriber_slug].push(w);
          } else {
            customerToWebsites[s.subscriber_slug] = [w];
          }
        }
      }
    }
    setCustomerToWebsites(customerToWebsites);
  };

  // Update state when changing a filter checked state.
  const onClickFilter = e => {
    // id: filter.value, name: filter.name
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  const handleDeleteCustomer = (customer) => {
    dispatch(deleteCustomer(customer))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: customer.email, model: 'client', action: 'deleted' }));
        dispatch(fetchWebsites());
      })
  };

  const copyText = async text => {
    await navigator.clipboard.writeText(text);
  };

  const handleCopyProfileSetupLink = customer => {
    if (copyLoading[customer.slug]) {
      setGlobalWarningMsg('Profile setup link is being generated...');
      return;
    }
    setCopyLoading(prev => ({ ...prev, [customer.slug]: true }));
    const data = {
      customer_slug: customer.slug,
    };
    CustomerService.copyProfileSetupRequestUrl(data)
      .then(res => {
        copyText(res.secure_url);
        dispatch(
          setGlobalSuccessMsg(
            `A secure link has been generated and copied to clipboard and is valid for ${res.ttl_in_hours} hours.`,
          ),
        );
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => {
        setCopyLoading(prev => ({ ...prev, [customer.slug]: false }));
      });
  };

  const handleSendProfileSetupRequest = customer => {
    if (sendLoading[customer.slug]) {
      setGlobalWarningMsg('Send profile setup email is being sent...');
      return;
    }
    setSendLoading(prev => ({ ...prev, [customer.slug]: true }));
    const data = {
      customer_slug: customer.slug,
    };
    CustomerService.sendProfileSetupRequestEmail(data)
      .then(() =>
        dispatch(
          setGlobalSuccessMsg({
            model: 'profile setup request',
            action: 'sent',
            to: customer.email,
          }),
        ),
      )
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => {
        setSendLoading(prev => ({ ...prev, [customer.slug]: false }));
      });
  };

  const actions = [
    {
      value: 'Update',
      onClick: customer => {
        history.push({
          pathname: '/clients/update',
          state: { slug: customer.slug },
        });
      },
    },
    {
      value: 'Invoices',
      onClick: customer => {
        history.push({
          pathname: `/clients/invoices`,
          search: `&filter=Client:${customer.email}`,
          state: { routing: true },
        });
      },
    },
    {
      value: 'Credit Cards',
      doHide: () => !integrations.stripe,
      onClick: customer => {
        history.push({
          pathname: `/clients/${customer.slug}/payments`,
        });
      },
    },
    {
      value: 'Send Profile Setup Email',
      doHide: () => !integrations.stripe,
      onClick: customer => handleSendProfileSetupRequest(customer),
    },
    {
      value: 'Create Profile Setup URL',
      doHide: () => !integrations.stripe,
      onClick: customer => handleCopyProfileSetupLink(customer),
    },
    {
      value: 'Assigned Plans',
      onClick: customer => history.push({
        pathname: `/clients/subscriptions`,
        search: `&filter=Client:${customer.email}`,
      }),
    },
    {
      value: 'Delete',
      onClick: customer => DialogHelper
        .confirmDelete(confirm, customer.email, 'client')
        .then(() => handleDeleteCustomer(customer))
    },
  ];

  const columns = [
    JsxHelper.createTableTitleHeader('display_name', 'Client', '30%', 'email'),
    {
      name: 'Profile Setup',
      selector: 'metadata',
      sortable: true,
      customSort: true,
      searchable: true,
      width: '15%',
      cell: row => {
        const status = row.metadata.profile_setup_status;
        let text = 'Incomplete';
        let tooltip = "The profile setup request hasn't been sent yet nor marked as completed.";
        let background = 'danger';
        if (status === 'unread') {
          background = 'warning';
          text = 'Unread';
          tooltip = "The request has been sent but the client hasn't started the setup process yet.";
        } else if (status === 'read') {
          background = 'warning';
          text = 'In Progress';
          tooltip = "The client has started the setup process but hasn't finished yet.";
        } else if (status === 'completed') {
          text = 'Completed';
          tooltip = 'The client has completed the profile setup process.';
          background = 'success';
        }
        TableHelper.customizeCellValue(row, 'metadata', text);
        return JsxHelper.createBubble({
          text,
          tooltip,
          background,
          small: true
        });
      },
    },
    {
      name: 'Websites',
      selector: 'slug',
      searchable: true,
      width: '20%',
      cell: row => {
        let customerWebsites = customerToWebsites[row.slug];
        TableHelper.customizeCellValue(row, 'slug', '');
        for (const i in customerWebsites) {
          row.custom_slug += customerWebsites[i].slug;
        }
        const links = isEmptyOrNull(customerWebsites) ? [] : customerWebsites.map(w => ({
          label: w.slug,
          link: `/websites/${w.slug}/domains`,
        }));
        return JsxHelper.createTableCellMultiLinks({
          links,
          default: 'No websites assigned',
          circle: true,
        });
      },
    },
    JsxHelper.createTableActionsHeader(actions, '35%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Client List',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Client List</TitleBar.Title>
        <TitleBar.Actions>{JsxHelper.createFilterIcon({
            setShowFilters,
            showFilters,
            filtersRef,
            onClickFilter,
            filters,
          })}
          {JsxHelper.createRefreshButton({
            onClick: () => fetchSubscriptionsData(true),
            loading: fetchLoading,
          })}
          <Can
            perform='customer:create:*'
            yes={() => (
              <Link to='/clients/create'>
                <WPSButton className='add--btn'>Add Client</WPSButton>
              </Link>
            )}
          />
          {JsxHelper.createBackButton('/clients')}
        </TitleBar.Actions>
      </TitleBar>
      <Content className='customer-list-table'>
        <WPSDataTable loading={fetchLoading} columns={columns} body={customers} />
      </Content>
    </Fragment>
  );
};

export default Account;
