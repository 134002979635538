import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import SavePlan from 'components/plan/save';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const PlanCreate = () => {
  useTitle('Create Client Plan');
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Plans',
      link: '/clients/plans',
    },
    {
      text: 'Create',
    },
  ];
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Create New Plan</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/clients/plans')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <SavePlan />
      </Content>
    </Fragment>
  );
};

export default PlanCreate;
