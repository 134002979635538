import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import { PieChart, Pie, Cell } from "recharts";
import themes from 'styles/theme.js'

const SitesMetrics = ()=>{
  const websites = useSelector(websitesSelector);
  const liveSites = websites.filter(w => w.is_live).length;
  const stagingSites = websites.filter(w => w.is_staging).length;
  const devSites = websites.length - liveSites - stagingSites;

  const data = [
    { name: 'Live', value: liveSites, color: themes.color.success, percent: websites.length > 0 ? liveSites / websites.length : 0 },
    { name: 'Dev', value: devSites, color: themes.color.warning, percent: websites.length > 0 ? devSites / websites.length : 0 },
    { name: 'Staging', value: stagingSites, color: themes.color.alternative, percent: websites.length > 0 ? stagingSites / websites.length : 0 }
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (<Fragment>
    <div className='pie-summary'>
      <ul>
        {data.map(item => <li key={item.name} style={{color: item.color}}>{item.name}: {item.value} ({(item.percent * 100).toFixed(0)}%)</li>)}
      </ul>
    </div>
    <PieChart width={270} height={180}>
      <Pie
        data={data}
        outerRadius={90}
        label={renderCustomizedLabel}
        animationBegin={0}
        animationDuration={1000}
        animationEasing="ease-out"
        labelLine={false}
        dataKey="value">
          {data.map((entry) => <Cell key={`cell-${entry.name}`} fill={entry.color} />)}
      </Pie>
    </PieChart>
  </Fragment>)
}

export default SitesMetrics;