import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGlobalSuccessMsg,
  setGlobalPleaseWaitMsg,
  setGlobalErrorMsg,
  setGlobalWarningMsg,
} from 'store/global/globalActions';
import {
  restoreWebsiteBackup,
  fetchWebsiteBackups,
  createWebsiteBackup,
  fetchWebsiteSyncRequests,
} from 'store/website/websiteActions';
import useConfirm from 'hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { isEmpty, isEmptyOrNull } from 'helpers';
import DateHelper from 'helpers/date';
import useModal from 'hooks/useModal';
import { timezoneSelector } from 'store/me/meSelectors';
import WebsiteService from 'services/website';
import env from 'config/env';
import DialogHelper from 'helpers/dialog';
import ArrayHelper from 'helpers/array';
import ObjectHelper from 'helpers/object';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import WebsiteHelper from 'helpers/website';
import UserHelper from 'helpers/user';
import TableHelper from 'helpers/table';
import { useHistory } from 'react-router-dom';

const WebsiteBackups = ({ website }) => {
  useTitle('Website Backups');
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const modalDialog = useModal();
  const history = useHistory();
  const RETENTION_DAYS = 14;
  const FREQUENCY_HOURS = 24;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingBackups, setLoadingBackups] = useState(false);
  const [fetchingBackups, setFetchingBackups] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [restoreItem, setRestoreItem] = useState(false);
  const [downloadItem, setDownloadItem] = useState(false);
  const [userNotes, setUserNotes] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [websiteBackups, setWebsiteBackups] = useState(website.backups);
  const [activeRestoreRequest, setActiveRestoreRequest] = useState(false);
  const [restoreHistoryView, setRestoreHistoryView] = useState(false);
  const [restoreHistoryData, setRestoreHistoryData] = useState([]);
  const [loadingRestoreHistory, setLoadingRestoreHistory] = useState(false);
  const [loadingDBTables, setLoadingDBTables] = useState(false);
  const [allDBTableOptions, setAllDBTableOptions] = useState([]);
  const [includeDBTableUserOptions, setIncludeDBTableUserOptions] = useState([]);
  const [excludeDBTableUserOptions, setExcludeDBTableUserOptions] = useState([]);
  const [isTempManualBackup, setIsTempManualBackup] = useState(false);
  const timezone = useSelector(timezoneSelector);
  const backupTypes = {
    'database': 'Database',
    'themes': 'Themes',
    'plugins': 'Plugins',
    'content': 'User Content'
  }
  const mounted = useRef(true);

  useEffect(() => {
    fetchDBTables();
    fetchWebsiteBackupsData();
    fetchWebsiteSyncRequestsData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const fetchDBTables = () => {
    setLoadingDBTables(true);
    WebsiteService.getDatabaseInfo({ website_slug: website.slug })
      .then(response => {
        const dbTables = response.tables; // Table name to size in bytes.
        const options = [];
        for (const table in dbTables) {
          options.push({ name: table, label: `${table} (${StringHelper.convertToHumanReadableSize(dbTables[table], 'B')})`, size: dbTables[table]});
        }
        setAllDBTableOptions(options.sort((a, b) => b.size - a.size));
      })
      .finally(() => setLoadingDBTables(false));
  };

  const fetchWebsiteSyncRequestsData = (silent) => {
    if (!mounted.current) {
      window.logHelper.info('Component is unmounted. Skipping sync requests fetch.');
      return;
    }
    const data = {
      website_slug: website.slug,
      days: 1000,
    };
    !silent && setLoadingRestoreHistory(true);
    dispatch(fetchWebsiteSyncRequests(data))
      .then(res => {
        const requests = (res || []).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setRestoreHistoryData(requests);
        const activeRequest = requests.find(item => !['completed', 'failed'].includes(item.status));
        setActiveRestoreRequest(activeRequest);
        if (activeRequest) {
          waitRefreshRestoreStatus();
        }
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => !silent && setLoadingRestoreHistory(false))
  };

  const prepareBackupRows = (backups) => {
    // Remove all restore requests.
    backups = backups.filter(backup => backup.type !== 'request' || backup.action !== 'restore');
    // Remove all backup requests that were completed.
    backups = backups.filter(backup => backup.status !== 'completed');
    // Group all rows by requested_at.
    const requestToRows = {};
    backups.forEach(backup => {
      const key = backup.group_id;
      if (!requestToRows.hasOwnProperty(key)) {
        requestToRows[key] = [];
      }
      requestToRows[key].push(backup);
    });
    // Flatten rows.
    const rows = [];
    Object.keys(requestToRows).forEach(key => {
      const firstRow = requestToRows[key][0];
      const type = firstRow.type;
      const savePath = firstRow.save_path;
      rows.push({
        row_id: key,
        requested_at: firstRow.requested_at,
        type: type,
        items: requestToRows[key],
        full: type === 'request' ||
              (type === 'db' && isEmptyOrNull(firstRow.db_exclude_tables) && isEmptyOrNull(firstRow.db_include_tables)) ||
              (type === 'files' && savePath.includes('.full.')),
      })
    });
    // Sort rows by requested_at.
    rows.sort((a, b) => {
      return new Date(b.requested_at) - new Date(a.requested_at);
    });
    return rows;
  };

  const fetchWebsiteBackupsData = (applyLoading) => {
    if (fetchingBackups) {
      window.logHelper.warning('Website backups are already being fetched.');
      return;
    }
    applyLoading = applyLoading === undefined ? true : applyLoading;
    const data = {
      website_slug: website.slug,
    };
    applyLoading && setLoadingBackups(true);
    setFetchingBackups(true);
    dispatch(fetchWebsiteBackups(data))
      .then(res => {
        if (isEmpty(res)) {
          DialogHelper.info(modalDialog, 'Website has no backups.');
        } else {
          // Display the backups in the table.
          setWebsiteBackups(prepareBackupRows(res));
          // Get the list of all backups that are being taken right now in order to know whether the list should be refreshed.
          const requests = res.filter(b => b.type === 'request' && !['completed', 'failed'].includes(b.status) && b.action === 'backup');
          if (!isEmpty(requests)) {
            waitRefreshBackupsData();
          }
        }
      })
      .finally(() => {
        applyLoading && setLoadingBackups(false);
        setFetchingBackups(false);
      });
  };

  const waitRefreshBackupsData = () => {
    setTimeout(() => {
      window.logHelper.info(`Checking active requests for website ${website.slug}`);
      fetchWebsiteBackupsData(false);
    }, 10000);
  }

  const restoreBackup = row => {
    if (isEmptyOrNull(userTypes)) {
      dispatch(setGlobalErrorMsg('Please select at least one backup type.'));
      return;
    }
    if (activeRestoreRequest || loadingBtn) {
      dispatch(setGlobalWarningMsg('Restore request is in progress. Please wait.'));
      return;
    }
    const data = { website_slug: website.slug, files_info: [] };
    row.items.forEach(item => {
      if (item.type === 'files' && userTypes.includes(item.sub_type)) {
        data.files_info.push({
          uri: item.save_path,
          type: item.sub_type,
        });
      } else if (item.type === 'db') {
        data.files_info.push({
          uri: item.save_path,
          type: 'database',
        });
      }
    });
    setLoadingBtn(true);
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'backup', action: 'restored' }));
    dispatch(restoreWebsiteBackup(data))
      .then(() => {
        setActiveRestoreRequest(true);
        waitRefreshRestoreStatus();
        dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'backup', action: 'restored' }));
      }).catch(err => {
        dispatch(setGlobalErrorMsg(err));
      }).finally(() => {
        setLoadingBtn(false);
        setRestoreItem(false);
      });
  };

  const waitRefreshRestoreStatus = () => {
    setTimeout(() => {
      window.logHelper.info(`Checking restore status for website ${website.slug}`);
      fetchWebsiteSyncRequestsData(true);
    }, 5000);
  }

  const createManualBackup = () => {
    if (isEmpty(userTypes)) {
      dispatch(setGlobalErrorMsg('Please select at least one backup type.'));
      return; 
    }
    setLoadingBtn(true);
    const data = {
      website_slug: website.slug,
      notes: userNotes,
      types: userTypes,
      is_temp_manual: isTempManualBackup,
      db_include_tables: includeDBTableUserOptions,
      db_exclude_tables: excludeDBTableUserOptions,
    };
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'backup', action: 'created' }));
    dispatch(createWebsiteBackup(data))
      .then((res) => {
        // If there are errors, show the first one.
        if (!isEmptyOrNull(res.errors)) {
          const error = ObjectHelper.first(res.errors);
          DialogHelper.error(modalDialog, error);
        }
        // Reload the backups in case of success.
        if (!isEmptyOrNull(res.responses.database) || !isEmptyOrNull(res.responses.files)) {
          fetchWebsiteBackupsData();
        }
      })
      .finally(() => {
        setLoadingBtn(false);
        setUserNotes('');
        setUserTypes([]);
        setCreateModal(false);
      });
  };

  const goToImportSQL = () => history.push(`/websites/${website.slug}/import-sql`);
  const goToRemoteStorage = () => history.push('/settings/remote-backup-storage');
  const displayRestoreHistory = () => setRestoreHistoryView(true);

  const onNotesChange = e => setUserNotes(e.target.value);
  
  const onTypeChange = e => {
    const value = e.target.id;
    if (userTypes.includes(value)) {
      setUserTypes(userTypes.filter(item => item !== value));
    } else {
      setUserTypes([...userTypes, value]);
    }
  };

  const getRestoreTypes = (row) => {
    const types = [];
    row.items.forEach(item => {
      if (item.type === 'files') {
        types.push({
          value: item.sub_type,
          requested_at: DateHelper.convertToTimezone(item.requested_at, timezone, 'DD MMM YYYY, hh:mm A'),
        });
      } else if (item.type === 'db') {
        types.push({
          value: 'database',
          requested_at: DateHelper.convertToTimezone(item.requested_at, timezone, 'DD MMM YYYY, hh:mm A'),
        });
      }
    });
    return types;
  }

  const downloadPackage = (savePath, type) => {
    const data = {
      website_slug: website.slug,
      save_path: savePath,
      type: type,
    };
    setLoadingBtn(true);
    WebsiteService.downloadBackup(data).then(response => {
      window.open(response.presigned_url, '_blank', 'noopener,noreferrer');
    }).catch(error => {
      dispatch(setGlobalErrorMsg(error));
    }).finally(() => {
      setLoadingBtn(false);
      setDownloadItem(false);
    })
  };

  const maybeHideFileActions = (row, type) => {
    if (row.type !== 'files') {
      return true;
    }
    return isEmptyOrNull(row.items) || !row.items.some(item => item.sub_type === type);
  };
  
  const actions = [
    {
      value: 'Restore',
      doHide: row => row.type === 'request',
      onClick: setRestoreItem,
    },
    {
      value: 'Download Plugins',
      doHide: row => maybeHideFileActions(row, 'plugins'),
      onClick: row => {
        const plugins = row.items.find(item => item.sub_type === 'plugins');
        setDownloadItem({ save_path: plugins.save_path, type: 'files', text: 'plugins' });
      },
    },
    {
      value: 'Download Themes',
      doHide: row => maybeHideFileActions(row, 'themes'),
      onClick: row => {
        const themes = row.items.find(item => item.sub_type === 'themes');
        setDownloadItem({ save_path: themes.save_path, type: 'files', text: 'themes' });
      },
    },
    {
      value: 'Download Content',
      doHide: row => maybeHideFileActions(row, 'content'),
      onClick: row => {
        const content = row.items.find(item => item.sub_type === 'content');
        setDownloadItem({ save_path: content.save_path, type: 'files', text: 'content' });
      },
    },
    {
      value: 'Download Database',
      doHide: row => row.type !== 'db',
      onClick: row => {
        const db = row.items.find(item => item.type === 'db');
        setDownloadItem({ save_path: db.save_path, type: 'database', text: 'database' });
      }
    },
    UserHelper.isAdminOrAgent() ? {
      value: 'Copy Plugins S3 Path',
      doHide: row => maybeHideFileActions(row, 'plugins'),
      onClick: row => {
        const plugins = row.items.find(item => item.sub_type === 'plugins');
        JsxHelper.copyToClipboard(dispatch, plugins.save_path, 'Plugins S3 path')
      },
    } : null,
    UserHelper.isAdminOrAgent() ? {
      value: 'Copy Themes S3 Path',
      doHide: row => maybeHideFileActions(row, 'themes'),
      onClick: row => {
        const themes = row.items.find(item => item.sub_type === 'themes');
        JsxHelper.copyToClipboard(dispatch, themes.save_path, 'Themes S3 path')
      },
    } : null,
    UserHelper.isAdminOrAgent() ? {
      value: 'Copy Content S3 Path',
      doHide: row => maybeHideFileActions(row, 'content'),
      onClick: row => {
        const content = row.items.find(item => item.sub_type === 'content');
        JsxHelper.copyToClipboard(dispatch, content.save_path, 'Content S3 path')
      },
    } : null,
    UserHelper.isAdminOrAgent() ? {
      value: 'Copy S3 Path',
      doHide: row => row.type !== 'db',
      onClick: row => {
        const db = row.items.find(item => item.type === 'db');
        const s3Path = `${db.save_path}/${db.app}.sql.gz`;
        JsxHelper.copyToClipboard(dispatch, s3Path, 'S3 path')
      }
    } : null,
    {
      value: 'Delete',
      doHide: row => (!UserHelper.isAdminOrAgent() && row.items[0].is_auto) || row.type === 'request',
      onClick: row => DialogHelper
        .confirmDelete(confirm, website.slug, 'backup')
        .then(() => {
          dispatch(setGlobalPleaseWaitMsg({ model: 'Backup', action: 'deleted', forId: WebsiteHelper.getLabel(website) }));
          const data = {
            website_slug: website.slug,
            save_paths: row.items.map(item => item.save_path),
          };
          WebsiteService.deleteBackups(data).then(() => {
            setWebsiteBackups(websiteBackups.filter(item => row.row_id !== item.row_id));
            dispatch(setGlobalSuccessMsg({ model: 'Backup', action: 'deleted', forId: WebsiteHelper.getLabel(website) }));
          }).catch(error => dispatch(setGlobalErrorMsg(error)));
        })
    },
  ].filter(Boolean);

  const headers = [
    JsxHelper.createTableTimeHeader('requested_at', timezone, '14%'),
    {
      name: <span>Data (<span style={{fontSize: '12px'}}>click to download</span>)</span>,
      selector: 'items',
      width: '20%',
      sortable: true,
      cell: row => {
        const bubbles = [];
        row.items.forEach(item => {
          if (item.type === 'files') {
            bubbles.push({
              text: item.sub_type,
              size: item.content_size,
              color: item.sub_type === 'plugins' ? 'success' : (item.sub_type === 'themes' ? 'warning' : 'info'),
              tip: `Content size: ${item.content_size} (archived: ${item.size}).`,
              downloadable: true,
              save_path: item.save_path,
              type: 'files',
            })
          } else if (item.type === 'db') {
            bubbles.push({
              text: 'database',
              size: item.db_size,
              color: 'primary',
              tip: `Content size: ${item.db_size} (archived: ${item.size}).`,
              downloadable: true,
              save_path: item.save_path,
              type: 'database',
            })
          } else if (item.type === 'request' && item.action === 'backup') {
            item.job_args.subtypes.forEach(subtype => {
              // If the item was already succeeded, don't show the bubble as we expect a backup row.
              if (item.metadata && item.metadata.succeeded && item.metadata.succeeded[subtype]) {
                return;
              }
              // If the item was already failed, add the bubble with the error message.
              if (item.status === 'failed') {
                bubbles.push({
                  text: subtype,
                  color: 'danger',
                  tip: 'Request failed. Please try again or contact support.',
                  downloadable: false,
                });
                return;
              }
              // Otherwise, set the bubble as in progress.
              bubbles.push({
                text: subtype,
                color: 'disabled',
                tip: `In progress`,
                downloadable: false,
              })
            })
          }
        });
        return bubbles.map((bubble) => JsxHelper.createBubble({
          text: bubble.text,
          background: bubble.color,
          tooltip: bubble.tip,
          small: true,
          margin: 0,
          onClick: bubble.downloadable 
            ? () => setDownloadItem({ save_path: bubble.save_path, type: bubble.type, text: bubble.text })
            : null,
        }))
      }
    },
    JsxHelper.createTableTextHeaderWithCallback('size', 'Size', '10%', row => {
      let total = 0;
      row.items.forEach(item => {
        if (item.type === 'files') {
          total += item.content_size_bytes;
        } else if (item.type === 'db') {
          total += item.db_size_bytes;
        }
      });
      TableHelper.customizeCellValue(row, 'size', total);
      return total ? StringHelper.convertBytes(total) : '-';
    }),
    JsxHelper.createTableTextHeaderWithCallback('type', 'Type', '13%', row => {
      let text = 'Full';
      let tooltip = 'Full backup';
      let background = 'success';
      if (!row.full) {
        background = 'alternative';
        // Non-full database backups means custom tables were selected.
        if (row.type === 'db') {
          text = 'Custom Tables';
          if (row.items[0].db_exclude_tables) {
            tooltip = `Excluded: ${row.items[0].db_exclude_tables.join(', ')}`;
          } else if (row.items[0].db_include_tables) {
            tooltip = `Included: ${row.items[0].db_include_tables.join(', ')}`;
          }
        } else {
          text = 'Incremental';
          tooltip = 'Incremental backup';
        }
      }
      return JsxHelper.createBubble({
        text,
        background,
        tooltip,
        small: true,
        margin: 0,
      })
    }),
    {
      name: 'Notes',
      selector: 'notes',
      width: '28%',
      searchable: true,
      cell: row => {
        const isAuto = row.items[0].is_auto;
        const isTemp = isAuto ? true : row.items[0].is_temp;
        const notes = row.items[0].notes;
        const userEmail = row.items[0].user_email;
        if (row.type === 'request') {
          return 'In progress...';
        } else if (isAuto) {
          return 'Automated backup';
        }
        
        let _notes = notes || (userEmail ? `Created by ${userEmail}` : 'Manual backup');
        
        return <div className='backup-notes'>
          <div className='notes'>{_notes}</div>
          <div className='ttl'>{isTemp ? 'Temporary' : 'Permanent'} backup</div>
        </div>;
      }
    },
    JsxHelper.createTableActionsHeader(actions, '15%'),
  ];

  const restoreHistoryHeaders = [
    JsxHelper.createTableTimeHeader('created_at', timezone, '13%'),
    JsxHelper.createTableTextHeaderWithCallback('type', 'Action', '10%', row => {
      const sameWebsite = row.src_app_slug === row.dst_app_slug;
      const type = sameWebsite ? 'Restore' : 'Sync';
      const tooltip = !sameWebsite ? `Synced from another website (${row.src_app_slug})` : 'Restored from a backup';
      return JsxHelper.createBubble({
        text: type,
        background: sameWebsite ? 'primary' : 'alternative',
        tooltip: tooltip,
        small: true,
      });
    }),
    JsxHelper.createTableStatusHeader('status', null, true),
    JsxHelper.createTableTextHeaderWithCallback('timeline', 'Events', '67%', row => <div className='cell-wrapper'>
      {JsxHelper.createEventLogTable(row.timeline || [], timezone)}
    </div>, false)
  ];

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>{restoreHistoryView ? 'Restore History' : 'Website Backups'}</TitleBar.Title>
        <TitleBar.Actions>
          {!restoreHistoryView ? <Fragment>
            {JsxHelper.createButton({
              label: 'Restore History',
              classes: 'primary--btn',
              loading: loadingRestoreHistory,
              disabled: isEmptyOrNull(restoreHistoryData),
              onClick: displayRestoreHistory,
            })}
            {UserHelper.hasAccessToBetaFeatures() && JsxHelper.createButton({
              label: 'Remote Storage',
              classes: 'warning--btn',
              onClick: goToRemoteStorage,
            })}
            {UserHelper.hasAccessToBetaFeatures() && JsxHelper.createButton({
              label: 'Import SQL',
              classes: 'alt--btn',
              onClick: goToImportSQL,
            })}
            {JsxHelper.createButton({
              label: 'Create Backup',
              disabled: activeRestoreRequest,
              onClick: () => setCreateModal(true),
            })}
          </Fragment> : <Fragment>
            {JsxHelper.createRefreshButton({
              loading: loadingRestoreHistory,
              onClick: () => fetchWebsiteSyncRequestsData(false),
            })}
            {JsxHelper.createCloseButton({
              onClick: () => setRestoreHistoryView(false),
            })}
          </Fragment>}
        </TitleBar.Actions>
      </TitleBar>
      {!restoreHistoryView && <p className='color-primary subheader'>
        {env.getBrandShortName()} provides automated website backups every {FREQUENCY_HOURS} hours with a {RETENTION_DAYS}-day retention period.
        Automated file backups (content, plugins, themes) are incremental and only contain the changes since the last automated backup.
        You can also create permanent manual backups with notes for your own reference.
        {activeRestoreRequest && <span className='progress-box'>
        Restoring backup is in progress...
        </span>}
      </p>}
      <Content>
        {!restoreHistoryView && <WPSDataTable columns={headers} body={websiteBackups || []} loading={loadingBackups} dataKey='row_id' />}
        {restoreHistoryView && <WPSDataTable columns={restoreHistoryHeaders} body={restoreHistoryData || []} loading={loadingRestoreHistory} dataKey='row_id' noSearchOnTable={true}  />}
      </Content>
      {createModal && DialogHelper.inputs({
        icon: 'backup',
        title: 'Create Website Backup',
        btnText: 'Create',
        onConfirm: createManualBackup,
        onClose: () => setCreateModal(false),
        loading: loadingBtn,
        header: <div className='warning-box'><strong>WARNING: </strong> Additional storage charges for manual backups may apply, depending on your hosting plan.</div>,
        inputs: [{
          type: 'checkbox',
          value: 'database',
          label: backupTypes['database'],
          checked: userTypes.includes('database'),
          onChange: onTypeChange,
        }, userTypes.includes('database') && !loadingDBTables && isEmptyOrNull(excludeDBTableUserOptions) ? {
          type: 'select',
          class: 'db-extra-input',
          name: 'include_tables',
          label: 'Tables',
          labelTooltip: WebsiteHelper.tooltips.db_include_tables,
          options: ArrayHelper.buildSelectOptions(allDBTableOptions, 'label', 'name'),
          value: includeDBTableUserOptions,
          sortOff: true,
          isMultiSelect: true,
          isSearchable: true,
          onChange: e => {
            const values = e.target.values;
            setIncludeDBTableUserOptions(values.map(item => item.value));
            setExcludeDBTableUserOptions([]);
          }
        } : null, userTypes.includes('database') && !loadingDBTables && isEmptyOrNull(includeDBTableUserOptions) ? {
          type: 'select',
          class: 'db-extra-input',
          name: 'exclude_tables',
          label: 'Exclude Tables',
          labelTooltip: WebsiteHelper.tooltips.db_exclude_tables,
          isMultiSelect: true,
          options: ArrayHelper.buildSelectOptions(allDBTableOptions, 'label', 'name'),
          value: excludeDBTableUserOptions,
          sortOff: true,
          isSearchable: true,
          onChange: e => {
            const values = e.target.values;
            setExcludeDBTableUserOptions(values.map(item => item.value));
            setIncludeDBTableUserOptions([]);
          }
        } : null].concat(Object.keys(backupTypes).filter(t => t !== 'database').map((value) => ({
          type: 'checkbox',
          value: value,
          label: backupTypes[value],
          checked: userTypes.includes(value),
          onChange: onTypeChange,
        })).concat([{
          type: 'text',
          placeholder: 'Notes (optional)',
          value: userNotes,
          onChange: onNotesChange,
        }, {
          type: 'checkbox',
          name: 'is_temp_manual',
          label: `Auto-delete backup after ${RETENTION_DAYS} days`,
          labelTooltip: WebsiteHelper.tooltips.is_temp_manual,
          checked: isTempManualBackup,
          onChange: e => setIsTempManualBackup(e.target.checked),
        }])).filter(Boolean),
      })}
      {restoreItem && DialogHelper.inputs({
        icon: 'restore',
        iconColor: 'warning',
        title: 'Restore Website Backup',
        btnText: 'Restore',
        btnColor: 'execute--btn',
        onConfirm: () => restoreBackup(restoreItem),
        onClose: () => setRestoreItem(false),
        loading: loadingBtn,
        disabled: isEmptyOrNull(userTypes),
        header: <p>
          Please note that restoring a backup will overwrite your current website data.
          <br/><br/>
          If you have made any changes to your website since the backup was taken, they will be lost.
          You are advised to take a backup of your current website before restoring a backup.
          <br/><br/>
          Choose the components you want to restore:
        </p>,
        inputs: (getRestoreTypes(restoreItem).map((component) => ({
          type: 'checkbox',
          value: component.value,
          label: <div>{StringHelper.capitalizeFirstLetter(component.value)}&nbsp;-&nbsp;<span style={{fontSize: '12px'}}>{component.requested_at}</span></div>,
          checked: userTypes.includes(component.value),
          onChange: onTypeChange,
        }))),
      })}
      {downloadItem && DialogHelper.inputs({
        icon: 'download',
        iconColor: 'primary',
        title: `Download ${StringHelper.capitalizeFirstLetter(downloadItem.text)} Backup`,
        btnText: 'Download',
        btnColor: 'primary--btn',
        onConfirm: () => downloadPackage(downloadItem.save_path, downloadItem.type),
        onClose: () => setDownloadItem(false),
        loading: loadingBtn,
        header: <p>
          You are about to download a backup of your website {downloadItem.text} to your computer in a compressed
          format. Please note that this may take a few minutes depending on the size of the archive.
          <br/><br/>
          <strong>Warning:</strong> additional bandwidth charges may apply, depending on your hosting plan.
        </p>,
        inputs: [],
    })}
    </Container>
  );
};

export default WebsiteBackups;
