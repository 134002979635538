import React, { useEffect, useRef } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar, GroupTitle } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import {
  setGlobalErrorMsg,
} from 'store/global/globalActions';
import { WPSButton } from 'styles/layout/buttons';
import WebsiteService from 'services/website';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import StaqWAFBannedIPs from 'components/security/StaqWAFBannedIPs';
import MUPluginBannedIPs from 'components/security/MUPluginBannedIPs';
import StringHelper from 'helpers/string';
import env from 'config/env';
import { isEmptyOrNull } from 'helpers';

const BannedIPs = ({ website }) => {
  useTitle('Website Banned IPs');
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const [firewallType, setFirewallType] = useState('website');
  const [fetchLoading, setFetchLoading]  = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const PAGE_SIZE = 100;

  // Server Firewall
  const [serverFirewallBannedIPs, setServerFirewallBannedIPs] = useState([]);
  const [serverApiParams, setServerApiParams] = useState({
    page: 1,
    limit: PAGE_SIZE,
    firewall: 'staq-waf',
    hasMore: true,
  });
  const fetchServerFirewallBannedIPs = (page) => {
    const data = { website_slug: website.slug, ...serverApiParams };
    if (page) {
      data.page = page;
    }
    WebsiteService.listBannedIPs(data)
    .then(res => {
      if (isEmptyOrNull(res)) {
        setServerApiParams(prev => ({ ...prev, fetched: true, hasMore: false }));
        return;
      }
      setServerFirewallBannedIPs(prev => prev.concat(res.map(item => {
        item.id = StringHelper.randomSlug();
        return item;
      })));
      setServerApiParams(prev => ({
        ...prev,
        page: data.page + 1,
        fetched: true,
        hasMore: res.length >= PAGE_SIZE,
      }));
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => {
      setFetchLoading(false);
      setRefreshLoading(false);
    });
  }

  // Website Firewall
  const [websiteFirewallBannedIPs, setWebsiteFirewallBannedIPs] = useState([]);
  const [websiteApiParams, setWebsiteApiParams] = useState({
    page: 1,
    limit: PAGE_SIZE,
    firewall: 'staq-mu',
    hasMore: true,
  });

  const fetchWebsiteFirewallBannedIPs = (page) => {
    const data = { website_slug: website.slug, ...websiteApiParams };
    if (page) {
      data.page = page;
    }
    WebsiteService.listBannedIPs(data)
    .then(res => {
      if (isEmptyOrNull(res)) {
        setWebsiteApiParams(prev => ({ ...prev, fetched: true, hasMore: false }));
        return;
      }
      setWebsiteFirewallBannedIPs(prev => prev.concat(res.map(item => {
        item.id = StringHelper.randomSlug();
        return item;
      })));
      setWebsiteApiParams(prev => ({
        ...prev,
        page: data.page + 1,
        fetched: true,
        hasMore: res.length >= PAGE_SIZE,
      }));
    })
    .catch(err => dispatch(setGlobalErrorMsg(err)))
    .finally(() => {
      setFetchLoading(false);
      setRefreshLoading(false);
    });
  }

  // Common
  const getFirewallTitle = () => StringHelper.capitalizeFirstLetter(firewallType) + ' Firewall';
  const canLoadMore = () => {
    return firewallType === 'server' ? serverApiParams.hasMore : websiteApiParams.hasMore;
  }
  const refreshBannedIPs = (firewallType) => {
    if (firewallType === 'server') {
      setServerFirewallBannedIPs([]);
      setServerApiParams(prev => ({ ...prev, page: 1, fetched: false }));
    } else {
      setWebsiteFirewallBannedIPs([]);
      setWebsiteApiParams(prev => ({ ...prev, page: 1, fetched: false }));
    }
    setRefreshLoading(true);
    fetchBannedIPs(firewallType, false, 1); // Reset pages
  }

  const fetchBannedIPs = (type, ignoreIfFetched, page) => {
    setFirewallType(type);
    if (ignoreIfFetched && (type === 'server' ? serverApiParams.fetched : websiteApiParams.fetched)) {
      return;
    }
    setFetchLoading(true);
    if (type === 'server') {
      fetchServerFirewallBannedIPs(page);
    } else {
      fetchWebsiteFirewallBannedIPs(page);
    }
  }

  // Fetch logs on component mount.
  useEffect(() => {
    if (mounted.current) {
      fetchBannedIPs(firewallType, false);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>
          <div>Firewall</div>
        </TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton
            className='refresh--btn'
            disabled={fetchLoading || refreshLoading}
            onClick={() => refreshBannedIPs(firewallType)}
            loading={refreshLoading}>
            Refresh
          </WPSButton>
          <WPSButton
            className='primary--btn'
            disabled={!canLoadMore() || fetchLoading || refreshLoading}
            onClick={() => fetchBannedIPs(firewallType, false)}
            loading={!refreshLoading && fetchLoading}>
            Load More
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
      Get insights into malicious traffic on your website by viewing the record of banned IP addresses. The list includes IPs blocked at the server level by the <b>{env.getBrandShortName()} WAF</b> firewall and those blocked at the website level by the <b>{env.getBrandShortName()} Hosting</b> plugin. Easily investigate and address false positives for non-spam IPs, and unblock them if necessary.
      </p>
      <Content>
        <GroupTitle>
          <h3 style={{ textTransform: 'none' }}>{getFirewallTitle()}</h3>
          <GroupTitle.Filters>
            <button
              className={firewallType === 'website' ? 'active' : ''}
              onClick={() => fetchBannedIPs('website', true)}>
              Website Firewall
            </button>
            <button
              className={firewallType === 'server' ? 'active' : ''}
              onClick={() => fetchBannedIPs('server', true)}>
              Server Firewall
            </button>
          </GroupTitle.Filters>
        </GroupTitle>

        {firewallType === 'server' ? (
          <StaqWAFBannedIPs
            dispatch={dispatch}
            loading={fetchLoading}
            setTableData={setServerFirewallBannedIPs}
            tableData={serverFirewallBannedIPs}
            rowsPerPage={25}
          />
        ) :
          <MUPluginBannedIPs
            dispatch={dispatch}
            website={website}
            loading={fetchLoading}
            setTableData={setWebsiteFirewallBannedIPs}
            tableData={websiteFirewallBannedIPs}
            rowsPerPage={25}
          />
        }
      </Content>
    </Container>
  );
};

export default BannedIPs;
