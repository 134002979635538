
const textToSymbol = text => {
  switch (text) {
    case 'gte':
      return '>=';
    case 'gt':
      return '>';
    case 'lt':
      return '<';
    case 'lte':
      return '<=';
    case 'eq':
      return '=';
    default:
      return text;
  }
};

const toFloat = (value, decimalPlaces) => value.toFixed(decimalPlaces || 2)

const MathHelper = {
  textToSymbol,
  toFloat
};

export default MathHelper;
