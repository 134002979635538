import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { SQTooltip } from '../layout/tooltip';
import { WPSBubble } from '../../styles/layout/buttons';
import styled, { css } from 'styled-components';

// let tinymce;
const EditorContainer = styled.div`
  ${props =>
    props.readonly &&
    css`
      background: #fafbfd;
      .tox-tinymce {
        border: 0;
      }
      .tox .tox-edit-area__iframe {
        background: none;
      }
      .tox-statusbar {
        display: none !important;
      }
    `}
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const TinyMCE = props => {
  const { hasToolbar = true, readonly, actions = [], onChange, minHeight = 300, ...rest } = props;

  const handleActionClick = a => e => {
    const editor = window.tinymce.activeEditor;
    if (editor) {
      editor.selection.setContent(a.value);
      if (onChange) onChange(editor.getContent());
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleChange = (content, editor) => {
    if (editor && onChange) {
      onChange(editor.getContent());
    }
  };

  const onFocus = () => {
    const editor = window.tinymce.activeEditor;
    if (editor && editor.getBody() && readonly) {
      editor.getBody().setAttribute('contenteditable', false);
    }
  };

  const setup = editor => {
    /* An autocompleter that allows you to insert special characters */
    if (actions && actions.length && editor) {
      editor.ui.registry.addAutocompleter('specialvars', {
        ch: '{',
        minChars: 0,
        columns: 1,
        fetch: pattern => {
          const matchedChars = actions.filter(char => {
            return char.value.indexOf(pattern) !== -1;
          });
          return new window.tinymce.util.Promise(function (resolve) {
            const results = matchedChars.map(function (char) {
              return {
                value: char.value,
                text: char.label,
              };
            });
            resolve(results);
          });
        },
        onAction: function (autocompleteApi, rng, value) {
          editor.selection.setRng(rng);
          editor.insertContent(value);
          autocompleteApi.hide();
        },
      });
    }
  };

  return (
    <EditorContainer readonly={readonly}>
      <Editor
        {...rest}
        init={{
          menubar: false,
          plugins: !hasToolbar
            ? []
            : [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
          toolbar: !hasToolbar
            ? false
            : `undo redo | formatselect | bold italic backcolor |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | removeformat | help | code`,
          branding: false,
          min_height: minHeight,
          setup,
          body_class: 'custom-tiny-mce',
        }}
        onEditorChange={handleChange}
        onFocus={onFocus}
      />
      <ActionContainer>
        {actions.map((a) => (
          <SQTooltip key={`action-${a.label}`} title={a.tooltip}>
            <WPSBubble
              background='info'
              tabIndex='-1'
              color='light'
              textColor='dark'
              onClick={handleActionClick(a)}
              transform={null}>
              {a.label}
            </WPSBubble>
          </SQTooltip>
        ))}
      </ActionContainer>
    </EditorContainer>
  );
};

export default TinyMCE;
