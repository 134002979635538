import React, { Fragment } from 'react';
import { useState } from 'react';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg
} from 'store/global/globalActions';
import { WPSBubble } from 'styles/layout/buttons';
import WebsiteService from 'services/website';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableActions from 'components/wpstaq/WPSDataTable/WPSTableActions';
import FirewallHelper from 'helpers/firewall';
import DateHelper from 'helpers/date';
import ReactTooltip from 'react-tooltip';
import env from 'config/env';
import UserHelper from 'helpers/user';

const StaqWAFBannedIPs = ({ dispatch, setTableData, tableData, loading, rowsPerPage, showWebsite }) => {
  const [lockedIPs, setLockedIPs] = useState([]);
  
  const actions = [
    {
      value: 'Report Not Spam',
      onClick: row => {
        const data = {
          website_slug: row.app,
          ip_address: row.ip,
          reason: `Reported as not spam by ${UserHelper.getEmail()} via ${env.getBrandShortName()} dashboard.`
        };
        if (lockedIPs.includes(row.ip)) {
          dispatch(setGlobalPleaseWaitMsg(`The IP ${row.ip} is already being processed.`))
          return;
        };
        setLockedIPs(prev => [...prev, row.ip]);
        dispatch(setGlobalPleaseWaitMsg(`The IP ${row.ip} is being reported.`))
        WebsiteService.reportNotSpam(data)
        .then(res => {
          dispatch(setGlobalSuccessMsg({ id: row.ip, model: 'IP address', action: 'reported as not spam', onId: row.app }));
          setTableData(prev => prev.map(item => {
            if (item.ip === row.ip) {
              item.status = 'reported_not_spam';
            }
            return item;
          }));
        })
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          setLockedIPs(prev => prev.filter(item => item !== row.ip))
        });
      },
      doHide: row => row.status !== 'banned' || row.code === 'AlreadyBannedByMUPlugin',
    },
  ];

  const headers = [
    {
      name: 'IP',
      selector: 'ip',
      searchable: true,
      width: '12%',
      cell: row => {
        return <Fragment>
          <WPSBubble
            style={{margin: '4px 0 0 3px'}}
            display='inline'
            padding='0'
            margin='0'
            color='info'
            data-for={row.id}
            data-tip={row.metadata && row.metadata.host ? row.metadata.host : 'Failed to resolve hostname.'}>
            {row.ip}
          </WPSBubble>
          <ReactTooltip id={row.id} />
        </Fragment>
      }
    },
    {
      name: 'Origin',
      selector: 'country',
      width: (showWebsite ? 6 : 7) + '%', // -1
      searchable: true,
      sortable: true,
      style: {
        paddingLeft: '5px !important',
      },
      cell: row => FirewallHelper.displayCountryFlag(row),
    },
    showWebsite ? {
      name: 'Server',
      selector: 'cluster',
      width: '8%',
    } : null,
    showWebsite ? {
      name: 'Website',
      searchable: true,
      selector: 'app',
      width: '13%',
    } : null,
    {
      name: 'Code',
      selector: 'code',
      searchable: true,
      width: (showWebsite ? 18 : 24) + '%', // -6
      cell: row => {
        let reason = row.pretty_reason;
        if (row.code === 'BlacklistedIP' && UserHelper.isAdmin()) {
          reason = `(${row.source}) ${reason}`
        }
        return <Fragment>
          <WPSBubble
            style={{margin: '4px 0 0 3px'}}
            display='inline'
            padding='0'
            margin='0'
            color='info'
            data-for={row.id}
            data-tip={reason}>
            {row.code}
          </WPSBubble>
          <ReactTooltip id={row.id} />
        </Fragment>
      }
    },
    {
      name: 'Hits',
      selector: 'hits',
      width: (showWebsite ? 6 : 8) + '%', // -2
      style: {
        paddingLeft: '8px !important',
      },
    },
    {
      name: 'Status',
      selector: 'status',
      searchable: true,
      width: (showWebsite ? 9 : 11) + '%', // -3
      cell: row => FirewallHelper.displayRowStatus(row, 'server')
    },
    {
      name: 'Ban Time',
      selector: 'created_at',
      searchable: true,
      width: (showWebsite ? 14 : 20) + '%', // -5
      cell: row => DateHelper.toHumanDateTime(row.created_at)
    },
    {
      name: '',
      width: (showWebsite ? 14 : 18) + '%', // -4
      button: true,
      cell: row => {
        return <TableActions
          actions={actions}
          item={row}
          customStyle={{ maxWidth: '200px', minWidth: 'unset', marginRight: '5px' }}
        />;
      },
    },
  ].filter(item => !!item);

  return (
    <Fragment>
      <WPSDataTable
        dataKey='id'
        loading={loading}
        columns={headers}
        body={tableData}
        rowsPerPage={rowsPerPage || 25}
      />
    </Fragment>
  );
};

export default StaqWAFBannedIPs;
