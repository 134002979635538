import { useState, useEffect } from 'react';
import { isEmptyOrNull } from 'helpers';
import MathHelper from 'helpers/math';
import DateHelper from 'helpers/date';
import JsxHelper from 'helpers/jsx';

const SystemSummaryCards = ({ website, stats }) => {
  const [details, setDetails] = useState({
    total_requests: 0,
    total_requests_duration: 0,
    total_requests_cpu_time: 0,
    total_requests_cpu_perc: 0,
    total_pools: 0,
  });

  // Prepare data.
  useEffect(() => {
    let totalRequests = 0;
    let totalRequestsTime = 0;
    let totalRequestsCpuTime = 0;
    let totalPools = 0;

    if (!isEmptyOrNull(stats)) {
      for (const row of stats) {
        totalRequests += row.requests;
        totalRequestsTime += row.requests_duration;
        totalRequestsCpuTime += row.requests_cpu_time;
        totalPools += row.total_processes;
      }
    }

    const totalRequestsCpuPerc = MathHelper.toFloat(totalRequestsCpuTime / totalRequestsTime * 100, 2);

    setDetails({
      total_requests: totalRequests,
      total_requests_duration: MathHelper.toFloat(totalRequestsTime, 2),
      total_requests_cpu_time: MathHelper.toFloat(totalRequestsCpuTime, 2),
      total_requests_cpu_perc: isNaN(totalRequestsCpuPerc) ? 0 : totalRequestsCpuPerc,
      total_pools: totalPools,
    });
  }, [stats, website]);

  return JsxHelper.createAnalyticsCards({
    items: [
      { icon: 'php', title: 'Total PHP Processes', value: details.total_pools },
      { icon: 'wordpress', title: 'Total Requests', value: details.total_requests },
      { icon: 'stopwatch', title: 'Requests Runtime', value: DateHelper.humanizePrecisly(details.total_requests_duration) },
      { icon: 'cpu', title: 'Total CPU Time', value: `${DateHelper.humanizePrecisly(details.total_requests_cpu_time)} (${details.total_requests_cpu_perc}%)` },
    ]
  });
};

export default SystemSummaryCards;
