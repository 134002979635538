import React, { Fragment, useState, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSFileInput } from 'styles/layout/forms';
import { WPSButton } from 'styles/layout/buttons';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { meSelector } from 'store/me/meSelectors';
import FormHelper from 'helpers/form';
import { updateMyProfile } from 'store/me/meActions';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import MeService from 'services/me';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import DialogHelper from 'helpers/dialog';
import DateHelper from 'helpers/date';
import JsxHelper from 'helpers/jsx';
import { isEmptyOrNull } from 'helpers';

const ProfileIndex = () => {
  useTitle('My Profile');
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, triggerValidation, unregister } = useForm({
    reValidateMode: 'onSubmit',
  });

  const me = useSelector(meSelector);

  const [details, setDetails] = useState({
    display_name: me.display_name,
    first_name: me.first_name,
    last_name: me.last_name,
    email: me.email,
    role: me.role,
    photo_name: '',
    password: '',
    two_factor_enabled: me.two_factor_enabled || false,
    timezone: isEmptyOrNull(me.timezone) ? 'UTC' : me.timezone,
  });

  const [imageURL, setImageURL] = useState(me.photo_url);
  const [imgFile, setImgFile] = useState();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [{ password, password2 }, setPassword] = useState({
    password: '',
    password2: '',
  });

  // Allow the user to submit an empty password to keep the old one.
  useEffect(() => {
    if (details.password.length === 0) {
      unregister('password');
    }
    // eslint-disable-next-line
  }, [details]);

  // Handle logo upload
  const handleFileUpload = e => {
    const { files } = e.target;
    if (files[0] !== undefined) {
      // Save file to state
      setDetails(prev => ({ ...prev, photo_name: files[0].name }));
      setImgFile(files[0]);

      // Display selected image.
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        setImageURL(fr.result);
      });
    } else {
      setDetails(prev => ({ ...prev, photo_name: '' }));
      setImgFile('');
    }
  };

  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'password' || name === 'password2') {
      setPassword(prev => ({ ...prev, [name]: value }));
    }
    console.log('before', details)
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  // Create form data to submit
  const createFormData = () => {
    let formData = new FormData();
    for (let key in details) {
      if (key !== 'password') {
        formData.append(key, details[key]);
      }
    }
    if (imgFile) {
      formData.append('photo_file', imgFile);
    }
    return formData;
  };

  const onSubmit = () => {
    setLoading(true);
    const data = createFormData();
    dispatch(updateMyProfile(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'your profile', action: 'updated' })))
      .finally(() => setLoading(false));
  };

  const updatePassword = async () => {
    const valid = await triggerValidation(['password', 'password2']);
    if (valid) {
      setPasswordLoading(true);
      const data = { new_password: password };
      MeService.resetPassword(data)
        .then(() => dispatch(setGlobalSuccessMsg({ model: 'password', action: 'updated' })))
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => {
          setPasswordLoading(false);
          setModal(false);
        });
    }
  };

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title>My Profile</TitleBar.Title>
      </TitleBar>
      <Content>
        <WPSForm onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
          <label>Photo</label>
          <WPSFileInput htmlFor='user_image' style={{ marginTop: '0' }}>
            {imageURL && <img src={imageURL} alt='' />}
            <input
              type='file'
              id='user_image'
              accept='.jpeg, .png, .jpg, .gif, .svg'
              onChange={handleFileUpload}
            />
            <span className='btn'>Browse</span>
            <span>{details.photo_name ? details.photo_name : 'No file chosen..'}</span>
          </WPSFileInput>
          {JsxHelper.createTextInput({
            label: 'Display name',
            value: details.display_name,
            onChange,
            name: 'display_name',
            required: true,
            errors,
            ref: register({ required: FormHelper.messages.required }),
          })}
          <WPSForm.Row>
            <WPSForm.RowItem>
              {JsxHelper.createTextInput({
                label: 'First name',
                value: details.first_name,
                onChange,
                name: 'first_name',
                required: true,
                errors,
                ref: register({ required: FormHelper.messages.required }),
              })}
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              {JsxHelper.createTextInput({
                label: 'Last name',
                value: details.last_name,
                onChange,
                name: 'last_name',
                required: true,
                errors,
                ref: register({ required: FormHelper.messages.required }),
              })}
            </WPSForm.RowItem>
          </WPSForm.Row>

          {JsxHelper.createTextInput({
            label: 'Email address',
            value: details.email,
            disabled: true,
          })}

          {JsxHelper.createSelectInput({
            label: 'Two-Factor Authentication',
            name: 'two_factor_enabled',
            value: details.two_factor_enabled,
            options: [{ value: false, label: 'Disabled' }, { value: true, label: 'Enabled' }],
            onChange,
          })}

          {JsxHelper.createSelectInput({
            label: 'Timezone',
            name: 'timezone',
            value: details.timezone,
            options: DateHelper.timezoneSelectOptions,
            onChange: onChange,
            isSearchable: true,
          })}

          <div style={{ display: 'flex', alignItems: 'flex-end' }} className='action-buttons'>
            <WPSButton
              className='save--btn'
              type='submit'
              loading={loading}
              style={{ width: 'auto', minWidth: '120px' }}>
              Save
            </WPSButton>
            <WPSButton className='sensitive--btn' type='button' onClick={() => setModal(true)}>
              Update password
            </WPSButton>
          </div>
        </WPSForm>
        {modal && DialogHelper.inputs({
          title: 'Change Password',
          onClose: () => setModal(false),
          onConfirm: handleSubmit(updatePassword),
          confirmBtn: 'Change',
          register,
          loading: passwordLoading,
          inputs: [{
            label: 'New password',
            name: 'password',
            value: password,
            type: togglePassword ? 'text' : 'password',
            onChange,
            minLength: 8,
            required: true,
            errors,
            togglePassword,
            setTogglePassword,
          }, {
            label: 'Confirm new password',
            name: 'password2',
            type: 'password',
            value: password2,
            onChange,
            required: true,
            ref: register({
              required: FormHelper.messages.required,
              validate: value => {
                if (value !== password) return 'Password does not match';
              },
            }),
            errors,
          }]
        })}
      </Content>
    </Fragment>
  );
};

export default ProfileIndex;
