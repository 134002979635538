import React, { useState, Fragment } from 'react';
import { AuthContainer, ImageWrapper, FormWrapper } from 'styles/auth';
import { useForm } from 'react-hook-form';
import { ErrorMsg, WPSLabel, WPSForm, WPSInput } from 'styles/layout/forms';
import EmailFailed from 'assets/email_failed.png';
import { WPS_LOGO_FULL } from 'assets/wps_logo';
import { WPSButton } from 'styles/layout/buttons';
import { InlineLoader } from 'styles/layout/loaders';
import useTitle from 'hooks/useTitle';
import MeService from 'services/me';
import { setGlobalSuccessMsg, setGlobalErrorMsg, getGlobalData, setGlobalLoader } from 'store/global/globalActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAuthData } from 'store/auth/authActions';



const PasswordReset = () => {
  useTitle('User Reset Temporary Password');
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [user, setUser] = useState({ token: '', email: '', password: '', password2: '' });
  const history = useHistory();


  const handleOnChange = e => {
    const { name, value } = e.target;
    setUser(prev => ({ ...prev, [name]: value }));
  };

  const resetPassword = () => {
    setBtnLoading(true);
    const data = { new_password: user.password };
    MeService.resetPassword(data)
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'password', action: 'updated' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => {
        setBtnLoading(false);
        const token = localStorage.getItem('access_token');
        if (token) {
          dispatch(setAuthData());
          dispatch(getGlobalData());
          history.push('/');
        } else {
          dispatch(setGlobalLoader(false));
        }
      });
  };

  return (
    <AuthContainer>
      <ImageWrapper>
        <img src={EmailFailed} alt='reset temporary password' />
        <WPS_LOGO_FULL />
      </ImageWrapper>
      <FormWrapper flex='1'>
        <WPS_LOGO_FULL inverted />

        <Fragment>
          <h1>Reset temporary password</h1>
          <WPSForm onSubmit={handleSubmit(resetPassword)} style={{ minWidth: '320px' }}>
            <WPSLabel htmlFor='password' required>
              Password {errors.password && <ErrorMsg>{errors.password.message}</ErrorMsg>}
            </WPSLabel>
            <WPSInput
              type='password'
              name='password'
              value={user.password}
              onChange={handleOnChange}
              ref={register({
                required: 'This field is required',
                validate: value => {
                  if (value && value.length < 8) {
                    return 'Minimum length of 8 characters';
                  }
                },
              })}
            />
            <WPSLabel htmlFor='password2' required>
              Confirm Password
                {errors.password2 && <ErrorMsg>{errors.password2.message}</ErrorMsg>}
            </WPSLabel>
            <WPSInput
              type='password'
              name='password2'
              value={user.password2}
              onChange={handleOnChange}
              ref={register({
                required: 'This field is required',
                validate: value => {
                  if (value !== user.password) {
                    return 'Password does not match';
                  }
                },
              })}
            />

            <WPSButton className='info--btn' disabled={btnLoading}>
              {btnLoading ? <InlineLoader /> : 'Reset Password'}
            </WPSButton>
          </WPSForm>
        </Fragment>
      </FormWrapper>
    </AuthContainer>
  );
};

export default PasswordReset;
