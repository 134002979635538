import React, { useState, useEffect } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm } from 'styles/layout/forms';
import { useSelector, useDispatch } from 'react-redux';
import { dnsZonesSelector } from 'store/dnsZone/dnsZoneSelectors';
import { isEmpty } from 'helpers';
import { assignDNSZone } from 'store/website/websiteActions';
import {
  setGlobalSuccessMsg,
} from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import dnsHelper from 'helpers/dnszone';
import Can from 'utils/can';
import { websitesSelector } from 'store/website/websiteSelectors';
import ArrayHelper from 'helpers/array';
import UrlHelper from 'helpers/url';
import WebsiteHelper from 'helpers/website';
import { useHistory } from 'react-router-dom';
import DialogHelper from 'helpers/dialog';
import JsxHelper from 'helpers/jsx';
import useModal from 'hooks/useModal';

const DNS = ({ website }) => {
  useTitle('Website DNS Zone');

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedDnsZoneSlug, setSelectedDnsZoneSlug] = useState(null);
  const backTo = UrlHelper.getQueryParam('origin');
  const cloudflareZone = WebsiteHelper.getCloudflareZone(website);
  const modalDialog = useModal();

  const assignedDnsZones = useSelector(websitesSelector)
    .filter(w => !isEmpty(w.dns_zone_slug))
    .map(el => el.dns_zone_slug);
  const unassignedDnsZones = useSelector(dnsZonesSelector).filter(e => !assignedDnsZones.includes(e.slug));

  useEffect(() => {
    if (website.dns_zone_slug) {
      WebsiteHelper.goTo(history, website.slug);
    } else if (cloudflareZone) {
      DialogHelper.warning(modalDialog, 'DNS Zone Not Configurable', 'This website is hosted on Cloudflare. DNS zone configuration is not allowed.', {
        btnOnClick: (onClose) => {
          WebsiteHelper.goTo(history, website.slug);
          onClose();
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const goToWebsiteCdnPage = () => WebsiteHelper.goTo(history, website.slug, 'cdn');

  const goToDnsSetupPage = () => {
    history.push({
      pathname: `/dns-zones/setup`,
      search: `?website=${website.slug}&defaultARecord=${website.public_ipv4}`
    });
  }

  const goToDnsRecordsPage = () => {
    const dnsZoneSlug = website.dns_zone_slug || selectedDnsZoneSlug;
    if (dnsZoneSlug) {
      history.push({
        pathname: `/dns-zones/${dnsZoneSlug}/records`,
        state: dnsZoneSlug
      });
    }
  }

  const onDnsZoneChange = (e) => {
    const slug = e.target.value;
    if (slug) {
      setSelectedDnsZoneSlug(slug);
    }
  }

  // Handle select/add dns zone.
  const onSubmit = () => {
    setLoading(true);
    const data = {
      website_slug: website.slug,
      dns_zone_slug: selectedDnsZoneSlug,
    };
    // Assign selected DNS zone to website.
    dispatch(assignDNSZone(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({
          model: 'DNS zone',
          action: 'assigned',
          to: website.slug,
        }));
        if (backTo === 'cdn-setup') {
          goToWebsiteCdnPage();
        } else {
          goToDnsRecordsPage();
        }
      })
      .finally(() => {
        setLoading(false);
        setModal(false);
      });
  };

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>DNS Zone</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        {dnsHelper.description}
      </p>
      <Content>
        <WPSForm>
          <Can
            perform={`website:update:${website.slug}`}
            yes={() => <div className='display-flex action-buttons'>
              {JsxHelper.createButton({ label: 'Create New DNS Zone', onClick: goToDnsSetupPage })}
              {JsxHelper.createButton({ label: 'Assign DNS Zone', onClick: () => setModal(true), classes: 'primary--btn' })}
            </div>}
          />
        </WPSForm>
        {modal && DialogHelper.inputs({
          title: 'Assign DNS Zone',
          confirmBtn: 'Assign',
          icon: 'create',
          iconColor: 'success',
          header: <span>Select DNS Zone to assign to <b>{website.slug}</b>:</span>,
          loading,
          disabled: !selectedDnsZoneSlug,
          inputs: [
            {
              type: 'select',
              name: 'dns_zone_slug',
              value: selectedDnsZoneSlug,
              options: ArrayHelper.buildSelectOptions(unassignedDnsZones, 'name', 'slug'),
              onChange: onDnsZoneChange,
              isSearchable: true,
            },
          ],
          onConfirm: onSubmit,
          onClose: () => setModal(false),
        })}
      </Content>
    </Container>
  );
};

export default DNS;
