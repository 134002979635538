import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { recentPackageUpdatesWidgetSelector } from 'store/widget/widgetSelectors';
import { websitesSelector } from 'store/website/websiteSelectors';
import JsxHelper from 'helpers/jsx';
import DateHelper from 'helpers/date';
import WebsiteHelper from 'helpers/website';
import { isEmptyOrNull } from 'helpers';
import StringHelper from 'helpers/string';
import env from 'config/env';

const PluginUpdatesMetrics = () => {
  const widget = useSelector(recentPackageUpdatesWidgetSelector);
  const websites = useSelector(websitesSelector);
  const widgetData = widget ? widget.data.slice(0, 5) : [];

  const buildMessage = (item) => {
    let action = item.action;
    const date = item.created_at ? DateHelper.getDateOnly(item.created_at) : '';
    if (action.startsWith('upload:')) {
      action = action.split(':')[1];
    }
    const prefix = `${isEmptyOrNull(date) ? '' : `[${date}]`} ${action}`;
    if (!item.old_version) {
      return <span>{prefix} <b>{item.display_name}</b> {item.type}: {item.new_version}</span>
    }
    return <span>{prefix} <b>{item.display_name}</b> {item.type}: {item.old_version} {JsxHelper.ARROW_SYMBOL} {item.new_version || 'N/A'}</span>
  }

  return(
    <Fragment>
      {!isEmptyOrNull(widgetData) ? <table className='notifications-table'>
        <colgroup>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <tbody>
          {widgetData.map((item, index) => {
            const website = WebsiteHelper.findWebsite(websites, item.website_slug);
            return (
              <tr key={index} className='notification-row'>
                <td className='notification-content'>
                  <div className='notification-label'>{website
                    ? JsxHelper.createWebsiteLink(website)
                    : JsxHelper.createRelativeLink(`${item.type}s`, `Global ${StringHelper.capitalizeFirstLetter(item.type)}`)
                  }</div>
                  <div className='notification-text'>{buildMessage(item)}</div>
                </td>
                <td className='notification-icon'>
                  {JsxHelper.createIcon({
                    icon: item.status === 'success' ? 'success' : 'danger',
                    color: item.status === 'success' ? 'success' : 'danger',
                    tooltip: item.error || StringHelper.capitalizeFirstLetter(item.status),
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> : <Fragment>
                  <div className='text-align-center' style={{margin: '40px auto 0 auto'}}>No data available.</div>
                  {JsxHelper.createButton({
                    label: 'Get started',
                    classes: 'create-light--btn selection-box-btn',
                    style: { border: '2px solid transparent', 'margin': '25px auto' }, // Force same as Stripe buttons.
                    onClick: () => {
                      const url = env.getKnowledgeBaseUrl('wordpress-automatic-plugin-updater');
                      window.open(url, '_blank');
                    }
                  })}
                </Fragment>}
    </Fragment>
  );
}

export default PluginUpdatesMetrics;