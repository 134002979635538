import styled from 'styled-components';
import React, { Fragment } from 'react';
import moment from 'moment';
import { FaCopy } from 'react-icons/fa';
// import Icon from 'components/layout/icon';

export const NotificationsList = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  z-index: 1100;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.primary};
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.table.oddRows};
  }
  svg {
    font-size: 23px;
  }

  ul {
    /* border-radius: 4px; */
    /* padding: 12px; */
    position: absolute;
    top: 110%;
    min-width: 300px;
    right: 0;
    background: white;
    z-index: 1110;
    max-height: 270px;
    overflow-y: auto;
    cursor: default;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  }
`;

const ItemStyle = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 12px 18px;
  /* align-items: center; */
  /* padding: 8px 12px; */
  color: black;
  font-size: 13px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.table.oddRows};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: ${props =>
      props.color ? props.theme.background[props.color] : props.theme.background.danger};
  }
  /* & > div {
    display: flex;
    flex-direction: column;
  }
  svg {
    font-size: 14px;
    margin-right: 8px;
    min-width: 14px;
    color: ${({ color, theme }) => theme.color[color]};
  } */
  .notification-text {
    display: flex;
    max-width: 40ch;
    svg {
      font-size: 16px;
      margin-right: 6px;
      min-width: 16px;
      cursor: pointer;
    }
    p {
      text-align: left;
      /* max-width: 35ch; */
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .notification-time {
    margin-top: 8px;
    align-self: flex-end;
    color: #7d7d7d;
    font-size: 11px;
  }
  .copy-text {
    
  }
`;

export const NotificationItem = ({ time, text, color, noItems }) => {
  const _text =
    text && text.length > 100
      ? text.replace("<span style='font-weight: bold'>", '').replace('</span>', '')
      : text;

  const copyText = async () => {
    await navigator.clipboard.writeText(_text);
  };

  return !noItems ? (
    <ItemStyle color={color}>
      {/* <Icon tag='circle' /> */}
      {/* <div> */}
      <span className='notification-text'>
        {text.length > 100 ? (
          <Fragment>
            <p
              className='copy-text'
              dangerouslySetInnerHTML={{ __html: `${text.substring(0, 100)}...` }}
            />
            <FaCopy onClick={copyText} />
          </Fragment>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </span>
      <span className='notification-time'>{moment(time).fromNow()}</span>
      {/* </div> */}
    </ItemStyle>
  ) : (
    <ItemStyle color='info'>
      <span>No items to display</span>
    </ItemStyle>
  );
};
