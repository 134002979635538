import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Container, CDNStatus } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { isEmptyOrNull, isWebsiteBusy } from 'helpers';
import StringHelper from 'helpers/string';
import WebsiteHelper from 'helpers/website';
import { WPSBubble } from 'styles/layout/buttons';
import {
  WPSForm,
  WPSLabel,
  WPSInputFix,
  WPSInput,
  WPSCheckboxLabel,
  WPSCheckbox,
} from 'styles/layout/forms';
import { useDispatch } from 'react-redux';
import useConfirm from 'hooks/useConfirm';
import { setGlobalInfoMsg, setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'components/layout/tooltip';
import {
  syncWebsiteAcmCertificate,
  syncWebsiteCdn,
  deleteWebsiteCdnCache,
  fetchWebsite,
} from 'store/website/websiteActions';
import DialogHelper from 'helpers/dialog';
import JsxHelper from 'helpers/jsx';
import useModal from 'hooks/useModal';
import env from 'config/env';
import 'components/stepper/stepper.css';

const Cdn = ({ website }) => {
  useTitle('Website CDN Settings');
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const modalDialog = useModal();

  const registrableDomain = website.registrable_domain;
  const cdnDeploymentError = website.metadata.cdn_deployment_error;
  const dnsCheckboxAlert = `The domain manager can be called Admin Panel, Domain Manager, Control Panel, Zone Edit, DNS Management or Advanced Settings.`;

  // We use states as the background process might change the redux
  // object of the ssl/cdn.
  const [cdnHostName, setCdnHostName] = useState(null);
  const [currentSsl, setCurrentSsl] = useState(null);
  const [sslCheckbox, setSslCheckbox] = useState(false);
  const [cdnCheckbox, setCdnCheckbox] = useState(false);
  const [showSslDnsRecords, setShowSslDnsRecords] = useState(false);
  const [showCdnDnsRecords, setShowCdnDnsRecords] = useState(false);
  const [activeCdn, setActiveCdn] = useState(null);

  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [sslSpinnerLoading, setSslSpinnerLoading] = useState(false);
  const [cdnSpinnerLoading, setCdnSpinnerLoading] = useState(false);
  
  const stepperRef = useRef();

  const initStepper = (website) => {
    const sslCert = WebsiteHelper.getCdnCacheSsl(website);
    const cdnDist = getWebsiteCdn(website);
    setCdnCheckbox(false);
    setSslCheckbox(false);

    if (isEmptyOrNull(sslCert)) {
      goBackToCdnCard();
      return;
    } else {
      setCurrentSsl(sslCert);
      setSslCheckbox(sslCert.status === 'ISSUED' && sslCert.validation_status === 'ok');
      setCdnHostName(sslCert.domain.replace(`.${registrableDomain}`, ''));
    }

    if (cdnDist) {
      setActiveCdn(cdnDist);
      if (cdnDist.domain_status === 'ok') {
        setCdnCheckbox(true);
      }
    }

    // Select step based on ssl + cdn statuses
    let initialStep = false;
    let stepToState = { 1: 'complete', 2: 'complete', 3: 'complete' };

    // Conditions for step 1
    if (sslCert.status !== 'ISSUED' || sslCert.validation_status !== 'ok') {
      stepToState[1] = 'warning';
      initialStep = 1;
    } else if (cdnDeploymentError && !cdnDist) {
      stepToState[1] = 'danger';
      initialStep = 1;
    }

    // Conditions for step 2
    if (sslCert.status === 'ISSUED') {
      if (sslCert.validation_status === 'ok') {
        initialStep = 2;
      } else if (cdnDist) {
        if (cdnDist.status === 'impaired') {
          initialStep = 2;
          stepToState[2] = 'danger';
        } else if (cdnDist.status === 'initializing') {
          initialStep = 2;
        }
      } else {
        initialStep = 2;
      }
    }

    // Conditions for step 3
    if (cdnDist) {
      if (cdnDist.domain_status !== 'ok') {
        stepToState[2] = 'warning';
      } else if (cdnDist.status === 'ok') {
        initialStep = 3;
      } else if (sslCert.status === 'ISSUED' && cdnDist.domain_status === 'ok') {
        initialStep = 3;
      } else if (cdnDist && cdnDeploymentError) {
        stepToState[3] = 'danger';
        initialStep = 3;
      }
    }

    // Only at init determine disabled steps
    let data = null;
    if (initialStep === 3 || (cdnDist && ['ok-has-warning', 'ok'].includes(cdnDist.status))) {
      data = [];
    } else if (initialStep === 2) {
      data = [1, 2].filter(i => i !== initialStep - 1);
    } else {
      initialStep = 1; // default
      data = [0, 1, 2].filter(i => i !== initialStep - 1);
    }
    setDisabledStepsData(data);
    disabledStepsElements.current = data;
    stepToStateColors.current = stepToState;

    // Initialize steps and go to initial
    stepperSteps[initialStep - 1].onClick();

    // Set initial step at the end of conditions
    updateCurrentStep(initialStep);
  }

  useEffect(() => {
    initStepper(website);
    // eslint-disable-next-line
  }, [website]);

  const initStep = stepNum => {
    for (var i = 1; i <= 3; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!disabledStepsElements.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add(`${stepToStateColors.current[i]}-color`);
      }
    }
    setCurrentStepForData(stepNum);
  };

  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Domain Validation',
      onClick: () => initStep(1),
    },
    {
      title: 'CloudFront',
      onClick: () => initStep(2),
    },
    {
      title: 'Deployment',
      onClick: () => initStep(3),
    },
  ]);

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(false);
  const [disabledStepsData, setDisabledStepsData] = useState([]);
  const disabledStepsElements = useRef([]);
  const stepToStateColors = useRef([]);

  const updateCurrentStep = newStep => {
    setCurrentStep(newStep + 1);
    setCurrentStepForData(newStep);
  };

  const goBackToCdnCard = () => {
    history.goBack();
  };

  const getWebsiteCdn = (_website) => {
    if (isEmptyOrNull(_website)) {
      _website = website;
    }
    return !isEmptyOrNull(_website.cdns) ? _website.cdns[0] : null;
  }

  const showAddCnameAlert = (domain) => DialogHelper.info(
    modalDialog,
    `The <b>${domain}</b> domain record has not propagated yet. You may have added the CNAME record correctly and it is being validated in the background.`,
    'Please wait a few minutes and try again.'
  )

  const shouldReloadObject = (o, n, fieldsToCheck) => {
    if (isEmptyOrNull(o) && isEmptyOrNull(n)) {
      return false;
    }
    if (isEmptyOrNull(o) || isEmptyOrNull(n)) {
      return true;
    }
    for (const field of fieldsToCheck) {
      if (o[field] !== n[field]) {
        return true;
      }
    }
    return false;
  }

  const onCheckCdnDomainStatus = () => {
    // Do not change state if checked
    if (cdnCheckbox) {
      return;
    }
    setCdnSpinnerLoading(true);
    const data = {
      website_slug: website.slug,
      used_as: 'cache'
    };
    dispatch(syncWebsiteCdn(data))
      .then((websiteResponse) => {
        const cdnResponse = getWebsiteCdn(websiteResponse);
        if (isEmptyOrNull(cdnResponse) || cdnResponse.domain_status !== 'ok') {
          showAddCnameAlert(currentSsl.domain);
        } else {
          setShowCdnDnsRecords(false);
        }
      })
      .finally(() => setCdnSpinnerLoading(false));
  };

  const onCheckSslDomainStatus = () => {
    // Do not change state if checked or validation status is ok.
    // Note that we don't check if ISSUED status because an SSL
    // might be issued but domain is not properly configured.
    if (sslCheckbox) {
      return;
    }

    // Call API to get the latest ssl details
    setSslSpinnerLoading(true);
    const data = {
      website_slug: website.slug,
      used_by: 'cdn_cache'
    };
    dispatch(syncWebsiteAcmCertificate(data))
      .then((websiteResponse) => {
        const sslResponse = WebsiteHelper.getCdnCacheSsl(websiteResponse);
        if (isEmptyOrNull(sslResponse) || sslResponse.validation_status !== 'ok') {
          showAddCnameAlert(registrableDomain);
        } else if (sslResponse.status === 'ISSUED') {
          setShowSslDnsRecords(false);
        }
      })
      .finally(() => setSslSpinnerLoading(false));
  };

  const isSslStatusFailed = () => {
    return (
      currentSsl &&
      ['INACTIVE', 'EXPIRED', 'VALIDATION_TIMED_OUT', 'REVOKED', 'FAILED'].includes(
        currentSsl.status,
      )
    );
  };

  const getDeploymentStatus = () => {
    if (!activeCdn) {
      return 'Not available';
    }
    let deploymentStatus = activeCdn.deployment_status;
    if (deploymentStatus === 'InProgress') {
      deploymentStatus = 'In progress';
    }
    return StringHelper.toText(deploymentStatus);
  }

  const getConnectivityStatus = () => {
    if (!activeCdn) {
      return 'Not available';
    }
    let cdnStatus = activeCdn.status;
    if (cdnStatus === 'ok') {
      cdnStatus = 'good';
    } else if (cdnStatus === 'ok-has-warning') {
      cdnStatus = 'good but has issues';
    }
    return StringHelper.toText(cdnStatus);
  }

  // Delete CDN
  const deleteCDN = () => {
    if (isWebsiteBusy(website)) {
      return;
    }
    DialogHelper
        .confirmDelete(confirm, activeCdn.domain, 'CDN')
        .then(() => {
          setLoading(true);
          const data = { website_slug: website.slug };
          dispatch(deleteWebsiteCdnCache(data))
            .then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'CDN', action: 'deleted' })))
            .catch(() => setLoading(false));
        })
  };

  const refreshCDN = () => {
    const data = {
      website_slug: website.slug,
      check_status: true,
    }
    setRefreshLoading(true);
    dispatch(fetchWebsite(data))
      .finally(() => setRefreshLoading(false))
      .catch((error) => dispatch(setGlobalErrorMsg(error)));
  };

  const copyText = async (type, text, dontTrimDot) => {
    if (!dontTrimDot) {
      text = StringHelper.trimRight(text, '.');
    }
    await navigator.clipboard.writeText(text);
    dispatch(setGlobalInfoMsg(`${type} copied to clipboard`));
  };

  return (
    <Container>
      <TitleBar className='titlebar'>
        <TitleBar.Title>{currentSsl ? currentSsl.domain : ''}</TitleBar.Title>
        <TitleBar.Actions>
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        Use the steps below in order to set up and see the status of your Amazon ACM Certificate and
        Amazon CloudFront CDN services.
      </p>
      <Content>
        <Fragment>
          <div
            ref={stepperRef}
            className='stepper'
            style={{ marginLeft: '0px', marginBottom: '15px' }}>
            {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
          </div>

          {cdnDeploymentError && (
            <WPSForm className='step-custom-form'>
              <CDNStatus>
                <WPSBubble
                  display='block'
                  background='danger'
                  color='light'
                  icon='danger'
                  data-for='cdn_error'
                  data-tip={cdnDeploymentError.solution}>
                  {cdnDeploymentError.error}
                </WPSBubble>
                <ReactTooltip id='cdn_error' />
              </CDNStatus>
            </WPSForm>
          )}

          {currentStepForData === 1 && (
            <WPSForm className='step-custom-form'>
              <CDNStatus>
                {
                  // If SSL certificate was issued and status is ok then display a success message.
                  currentSsl.status === 'ISSUED' && currentSsl.validation_status === 'ok' && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='success'
                        color='light'
                        icon='success'
                        data-for='ssl_domain'
                        data-tip=''>
                        Your domain has been validated and an SSL certificate has been issued.
                      </WPSBubble>
                    </div>
                  )
                }

                {
                  // If the validation has issues after the SSL has been issued then display
                  // the reason behind the issue.
                  currentSsl.status === 'ISSUED' && currentSsl.validation_status !== 'ok' && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='warning'
                        color='light'
                        icon='warning'
                        data-for='domain_validation_status'
                        data-tip=''>
                        {currentSsl.validation_status_reason}
                      </WPSBubble>
                    </div>
                  )
                }

                {
                  // If the SSL validation timed out then display an error message.
                  isSslStatusFailed() && (
                    <div className='notice'>
                      <WPSBubble
                        style={{ marginTop: '5px' }}
                        display='block'
                        background='danger'
                        color='light'
                        icon='info'
                        data-tip=''>
                        The domain ownership DNS validation has failed ({currentSsl.status}). You
                        must revoke the SSL certificate and start the validation process again.
                      </WPSBubble>
                    </div>
                  )
                }

                {
                  // If the DNS Zone is auto managed and the status is pending validation then
                  // show a message telling the user domain is being validated automatically.
                  !showSslDnsRecords &&
                  currentSsl.validation.auto_managed &&
                  (currentSsl.status === 'PENDING_VALIDATION' || currentSsl.validation_status !== 'ok') && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='info'
                        color='light'
                        icon='info'
                        data-tip=''>
                        Since the DNS Zone is hosted with {env.getBrandShortName()}, the CDN domain is being validated
                        in the background and no action is required from you. Please wait a few
                        minutes and try again.
                      </WPSBubble>
                      <WPSForm.RowItem direction='row'>
                        <WPSCheckboxLabel disabled={loading}>
                          <WPSCheckbox
                            type='checkbox'
                            id='check_cdn_status'
                            checked={showSslDnsRecords}
                            onChange={() => { setShowSslDnsRecords(true); setSslCheckbox(false); }}
                          />
                          &nbsp; I want to configure the CNAME record manually.
                        </WPSCheckboxLabel>
                      </WPSForm.RowItem>
                    </div>
                  )
                }

                {
                  // If the DNS Zone is not auto managed then show the CNAME records that the user
                  // has to add to the DNS manager with a checkbox that makes it easier for the user
                  // to notice the setup steps need to be taken.
                  (showSslDnsRecords ||
                  (!currentSsl.validation.auto_managed &&
                    currentSsl.status !== 'VALIDATION_TIMED_OUT' &&
                    (currentSsl.status === 'PENDING_VALIDATION' || currentSsl.validation_status !== 'ok')
                  )) && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='info'
                        color='light'
                        icon='todo'
                        data-tip=''>
                        Add the following {currentSsl.validation.options[0].ResourceRecord.Type}{' '}
                        record to the DNS configuration of your domain. The recommended TTL is 300s.
                      </WPSBubble>
                      <WPSForm.RowItem style={{ position: 'relative' }}>
                        <WPSLabel>
                          <strong>Name/Host</strong>
                        </WPSLabel>
                        <WPSInput
                          type='text'
                          value={currentSsl.validation.options[0].ResourceRecord.Name}
                          disabled={true}
                          className='step-copy-input'
                        />
                        <WPSBubble
                          color='link'
                          icon='copy'
                          padding='0'
                          margin='0'
                          className='step-copy-icon'
                          onClick={() =>
                            copyText(
                              'Name/Host value',
                              currentSsl.validation.options[0].ResourceRecord.Name.replace(
                                `.${registrableDomain}`,
                                '',
                              )
                            )
                          }>
                        </WPSBubble>
                      </WPSForm.RowItem>
                      <WPSForm.RowItem style={{ position: 'relative' }}>
                        <WPSLabel>
                          <strong>Pointing To</strong>
                        </WPSLabel>
                        <WPSInput
                          type='text'
                          value={currentSsl.validation.options[0].ResourceRecord.Value}
                          disabled={true}
                          className='step-copy-input'
                        />
                        <WPSBubble
                          color='link'
                          icon='copy'
                          padding='0'
                          margin='0'
                          className='step-copy-icon'
                          onClick={() =>
                            copyText(
                              'Validation record value',
                              currentSsl.validation.options[0].ResourceRecord.Value,
                            )
                          }>
                        </WPSBubble>
                      </WPSForm.RowItem>
                      {!sslSpinnerLoading && !sslCheckbox && (
                        <WPSForm.RowItem direction='row' className='custom-row'>
                          <WPSCheckboxLabel disabled={loading}>
                            <WPSCheckbox
                              type='checkbox'
                              id='check_ssl_status'
                              checked={sslCheckbox}
                              onChange={onCheckSslDomainStatus}
                            />
                          </WPSCheckboxLabel>
                          <div className='custom-checkbox-label'>
                            <span>
                              I added the CNAME record to the DNS Manager for {registrableDomain}.
                            </span>
                          </div>
                          <Tooltip place='right' text={dnsCheckboxAlert} />
                        </WPSForm.RowItem>
                      )}
                      {sslSpinnerLoading && (
                        <WPSForm.RowItem direction='row'>
                          <span className='loadingspinner'></span>
                          <span style={{margin:'6px 0 0 15px'}}>Validating CNAME record...</span>
                        </WPSForm.RowItem>
                      )}
                    </div>
                  )
                }
              </CDNStatus>
            </WPSForm>
          )}
          {currentStepForData === 2 && (
            <WPSForm className='step-custom-form'>
              <CDNStatus>
                {
                  // If the cdn doens't exist and the SSL issued then we need to display
                  // a message to user that the cdn is being deployed.
                  !activeCdn && !cdnDeploymentError && currentSsl.status === 'ISSUED' && (
                    <Fragment>
                      <div className='notice ok-notice'>
                        <WPSBubble
                          display='block'
                          background='info'
                          color='light'
                          icon='info'
                          data-tip=''>
                          {currentSsl.validation.auto_managed
                            ? `Since the DNS Zone is hosted with ${env.getBrandShortName()}, the CDN is being deployed in the background and no action is required from you. `
                            : 'The CDN is being deployed in the background. '}
                          Please wait a few minutes until more details appear and try again.
                        </WPSBubble>
                      </div>
                      {
                        !cdnSpinnerLoading &&
                        !cdnCheckbox &&
                        !cdnDeploymentError && (
                          <WPSForm.RowItem direction='row' className='custom-row'>
                            <WPSCheckboxLabel disabled={loading}>
                              <WPSCheckbox
                                type='checkbox'
                                id='check_cdn_status'
                                checked={cdnCheckbox}
                                onChange={onCheckCdnDomainStatus}
                              />
                            </WPSCheckboxLabel>
                            <div className='custom-checkbox-label'>
                              <span>
                                Refresh deployment data
                              </span>
                            </div>
                          </WPSForm.RowItem>
                        )
                      }

                      {
                        cdnSpinnerLoading && (
                          <WPSForm.RowItem direction='row'>
                            <span className='loadingspinner'></span>
                            <span style={{margin:'6px 0 0 15px'}}>Refreshing deployment data...</span>
                          </WPSForm.RowItem>
                        )
                      }
                    </Fragment>
                  )
                }

                {
                  // If the cdn's status is ok then display a success message.
                  activeCdn && activeCdn.domain_status === 'ok' && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='success'
                        color='light'
                        icon='success'
                        data-for='cdn_domain_status'
                        data-tip=''>
                        Your CDN domain already points to the {activeCdn.default_domain}.
                      </WPSBubble>
                    </div>
                  )
                }

                {
                  // If the cdn is impaired then display the status reason.
                  activeCdn && activeCdn.status === 'impaired' && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='danger'
                        color='light'
                        icon='danger'
                        data-for='cdn_impaired_status'
                        data-tip=''>
                        {activeCdn.status_reason}
                      </WPSBubble>
                    </div>
                  )
                }

                {
                  // If the cdn exists and domain status is not ok and the domain is managed
                  // then display a notice.
                  !showCdnDnsRecords &&
                    currentSsl.validation.auto_managed &&
                    activeCdn &&
                    activeCdn.domain_status !== 'ok' && (
                      <div className='notice'>
                        <WPSBubble
                          display='block'
                          background='info'
                          color='light'
                          icon='info'
                          data-tip=''>
                          Since the DNS Zone is hosted with {env.getBrandShortName()}, the CDN domain record is being set
                          to point to {activeCdn.default_domain} CNAME record in the background and
                          no action is required from you. Please wait a few minutes and try again.
                        </WPSBubble>

                        <WPSForm.RowItem direction='row'>
                          <WPSCheckboxLabel disabled={loading}>
                            <WPSCheckbox
                              type='checkbox'
                              id='check_cdn_status'
                              checked={showCdnDnsRecords}
                              onChange={() => { setShowCdnDnsRecords(true); setCdnCheckbox(false); }}
                            />
                            &nbsp; I want to configure the CNAME record manually.
                          </WPSCheckboxLabel>
                        </WPSForm.RowItem>
                      </div>
                    )
                }

                {
                  // If the cdn exists and domain status is not ok but the domain is not managed
                  // then display instructions to add CNAME record.
                  (showCdnDnsRecords ||
                    (!currentSsl.validation.auto_managed &&
                      activeCdn &&
                      activeCdn.domain_status !== 'ok')) && (
                    <div className='notice'>
                      <WPSBubble
                        display='block'
                        background='primary'
                        color='light'
                        icon='todo'
                        data-tip=''>
                        Add the following <strong>CNAME</strong> record to the DNS configuration of
                        your domain in order to connect your site to the CDN. The recommended TTL is
                        300s.
                      </WPSBubble>
                      <WPSForm.RowItem style={{ position: 'relative' }}>
                        <WPSLabel>
                          <strong>Name/Host</strong>
                        </WPSLabel>
                        <WPSInput
                          type='text' value={activeCdn.domain}
                          disabled={true}
                          className='step-copy-input'
                        />
                        <WPSBubble
                          color='link'
                          icon='copy'
                          padding='0'
                          margin='0'
                          className='step-copy-icon'
                          onClick={() =>
                            copyText(
                              'Name/Host value',
                              activeCdn.domain.replace(`.${registrableDomain}`, '')
                            )
                          }>
                        </WPSBubble>
                      </WPSForm.RowItem>
                      <WPSForm.RowItem style={{ position: 'relative' }}>
                        <WPSLabel>
                          <strong>Pointing To</strong>
                        </WPSLabel>
                        <WPSInput
                          type='text'
                          value={activeCdn.default_domain}
                          disabled={true}
                          className='step-copy-input'
                        />
                        <WPSBubble
                          color='link'
                          icon='copy'
                          padding='0'
                          margin='0'
                          className='step-copy-icon'
                          onClick={() => copyText(
                              'CloudFront domain',
                              activeCdn.default_domain
                            )
                        }>
                        </WPSBubble>
                      </WPSForm.RowItem>
                      {!cdnSpinnerLoading && !cdnCheckbox && (
                        <WPSForm.RowItem direction='row' className='custom-row'>
                          <WPSCheckboxLabel disabled={loading}>
                            <WPSCheckbox
                              type='checkbox'
                              id='check_cdn_status'
                              checked={cdnCheckbox}
                              onChange={onCheckCdnDomainStatus}
                            />
                          </WPSCheckboxLabel>
                          <div className='custom-checkbox-label'>
                            <span>
                              I added the CNAME record to the DNS Manager for {registrableDomain}.
                            </span>
                          </div>
                          <Tooltip place='right' text={dnsCheckboxAlert} />
                        </WPSForm.RowItem>
                      )}
                      {cdnSpinnerLoading && (
                        <WPSForm.RowItem direction='row'>
                          <span className='loadingspinner'></span>
                          <span style={{margin:'6px 0 0 15px'}}>Validating CNAME record...</span>
                        </WPSForm.RowItem>
                      )}
                    </div>
                  )
                }
              </CDNStatus>
            </WPSForm>
          )}
          {currentStepForData === 3 && (
            <WPSForm className='step-custom-form'>
              <CDNStatus>
                {activeCdn.status === 'ok' && (
                  <div className='notice'>
                    <WPSBubble
                      display='block'
                      background='success'
                      color='light'
                      icon='success'
                      data-for='cdn_status'
                      data-tip=''>
                      Your CDN is up and running.
                    </WPSBubble>
                  </div>
                )}

                <WPSForm.RowItem className='margin-left-6'>
                  <WPSLabel>
                    <strong>Domain Name</strong>
                  </WPSLabel>
                  <WPSInputFix>
                    <WPSInput type='text' value={cdnHostName} disabled={true} />
                    <span className='suffix'>.{registrableDomain}</span>
                  </WPSInputFix>
                </WPSForm.RowItem>
                <WPSForm.RowItem>
                  <WPSLabel>
                    <strong>Deployment Status</strong>
                  </WPSLabel>
                  <WPSInput
                    type='text'
                    value={getDeploymentStatus()}
                    disabled={true}
                  />
                </WPSForm.RowItem>
                <WPSForm.RowItem>
                  <WPSLabel>
                    <strong>Connectivity Status</strong>
                  </WPSLabel>
                  <WPSInput
                    type='text'
                    value={getConnectivityStatus()}
                    disabled={true}
                  />
                </WPSForm.RowItem>
                <WPSForm.RowItem className='inline-action-buttons'>
                  {JsxHelper.createButton({
                    label: 'Delete CDN',
                    onClick: deleteCDN,
                    loading,
                  })}
                  {JsxHelper.createButton({
                    label: 'Refresh Status',
                    onClick: refreshCDN,
                    loading: refreshLoading,
                    disabled: loading,
                  })}
                </WPSForm.RowItem>
              </CDNStatus>
            </WPSForm>
          )}
        </Fragment>
      </Content>
    </Container>
  );
};

export default Cdn;
