// Logger helpers
import env from 'config/env'

// References:
// https://stackoverflow.com/questions/30935336/official-information-on-arguments-in-es6-arrow-functions

const getTypeColor = (type) => {
  type = (type || '').toLowerCase();
  let color = 'info';
  if (['error', 'fail', 'fatal'].includes(type)) {
    color = 'danger';
  } else if (['warn', 'warning'].includes(type)) {
    color = 'warning';
  } else if (['debug'].includes(type)) {
    color = 'alternative';
  }
  return color;
}

const _log = function (level, ...args) {
  var prefix = '[' + new Date().toLocaleString() + '] ' + env.getBrandShortName() + '.' + level + ': ';
  if (args.length > 0 && typeof args[0] === 'string') {
    args[0] = prefix + args[0]
  } else {
    args.unshift(prefix);
  }
  console.log(...args)
}

const debug = function (...args) {
  if (env.DEBUG_MODE) {
    _log('DEBUG', ...args)
  }
}

const info = function (...args) {
  _log('INFO', ...args)
}

const warning = function (...args) {
  _log('WARNING', ...args)
}
const warn = warning;

const error = function (...args) {
  _log('ERROR', ...args)
}

const LogHelper = { debug, info, warning, warn, error, getTypeColor };

export default LogHelper;
