import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { ChartsContainer } from 'styles/website/profile';

const PoolRequestTab = ({ stats }) => {
  return (
    <ChartsContainer>
      <div>
        <h3 className='color-primary'>Total Requests</h3>
        <LineChart
          width={2000}
          height={400}
          data={stats}
          margin={{ top: 12, right: 7, left: 5, bottom: 30 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='timeframe' />
          <YAxis />
          <Tooltip />
          <Legend align='right' wrapperStyle={{ bottom: 0 }} />
          <Line
            type='monotone'
            name='Total requests'
            dataKey='requests'
            stroke='#82ca9d'
            unit=''
          />
        </LineChart>
      </div>
    </ChartsContainer>
  );
};

export default PoolRequestTab;
