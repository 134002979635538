/*
Global Modal component
TODO: add a fixed position to document.body when open
To use: import {Modal} from this file and add it as JSX component with all the needed components inside
Add a Boolean value to the parent component to show/hide the modal
*/

import React from 'react';
import ReactDOM from 'react-dom';

import { Container } from '../../styles/layout/persistentmodal';
import { WPSButton } from '../../styles/layout/buttons';
import { RingLoader } from '../../styles/layout/loaders';

const Modal = ({
  onConfirm,
  onClose,
  title,
  loading,
  children,
  noFooter,
  closeBtn,
  confirmBtn,
  disabled,
  disableButtons,
  buttons,
  open = false,
  ...props
}) => {
  return ReactDOM.createPortal(
    <Container show={open}>
      <Container.ModalBox {...props}>
        {title && <Container.ModalTitle align={noFooter && 'center'}>{title}</Container.ModalTitle>}
        <Container.ModalBody className='custom-scroll'>{children}</Container.ModalBody>
        <Container.ModalFooter>
          <WPSButton
            minWidth='80'
            className='cancel--btn'
            onClick={onClose}
            style={{ color: '#575757', border: '1px solid #575757' }}
            disabled={loading || disableButtons}>
            {closeBtn ? closeBtn : 'Cancel'}
          </WPSButton>
          {buttons && buttons()}
          <WPSButton
            minWidth='80'
            className='success--btn'
            onClick={onConfirm}
            disabled={loading || disabled || disableButtons}>
            {loading ? <RingLoader color='light' /> : confirmBtn ? confirmBtn : 'Save'}
          </WPSButton>
        </Container.ModalFooter>
      </Container.ModalBox>
    </Container>,
    document.body,
  );
};

export default Modal;
