import React from 'react';
import UserHelper from '../../helpers/user';

import BillingAdmin from './admin';
import BillingPartner from './partner';
import BillingEmployee from './employee';

const BillingIndex = () => {

  return UserHelper.isAdmin() ? (
      <BillingAdmin />
  ) : UserHelper.isPartner() ? (
      <BillingPartner />
  ) : UserHelper.isEmployee()  ? (
      <BillingEmployee />
  ) : null;
};

export default BillingIndex;
