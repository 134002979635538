import React from 'react';
import StringHelper from 'helpers/string';
import { FileData } from 'styles/package';
import { WPSCard } from 'styles/layout/cards';
import Icon from '../../components/layout/icon';

const PackageCard = ({ fileData }) => {
  return (
    <WPSCard className='file-uploader' style={{ background: 'transparent' }}>
      <FileData className='file-data-info'>
        <div className="package-card-title">
          <Icon tag='wordpress_simple' />
          <span>{fileData.type === 'theme' ? 'Theme Information' : 'Plugin Information'}</span>
        </div>
        <div className="package-metadata">
          <li>
            <h4>Name:</h4>
            <span>{fileData.display_name}</span>
          </li>
          <li>
            <h4>Version:</h4>
            <span>{fileData.version}</span>
          </li>
          {fileData.description && <li>
            <h4>Description:</h4>
            <span>{fileData.description}</span>
          </li>}
          {fileData.is_git && <li>
            <h4>Folder size:</h4>
            <span>{StringHelper.convertBytes(fileData.dir_size)}</span>
          </li>}
          {!fileData.is_git && fileData.zip_size && <li>
            <h4>File size:</h4>
            <span>{StringHelper.convertBytes(fileData.zip_size)}</span>
          </li>}
          <li>
            <h4>Author:</h4>
            <span>{fileData.author}</span>
          </li>
          <li>
            <h4>Author URI:</h4>
            <span>{fileData.author_uri}</span>
          </li>
          {fileData.official_uri && <li>
            <h4>Official URI:</h4>
            <span>{fileData.official_uri}</span>
          </li>}
      </div>
      </FileData>
    </WPSCard>
  );
}

export default PackageCard;
