import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { WPSForm } from 'styles/layout/forms';
import { useSelector, useDispatch } from 'react-redux';
import useTitle from 'hooks/useTitle';
import JsxHelper from 'helpers/jsx';
import WebsiteOptionsTrait from './WebsiteOptionsTrait';
import { websitesListSelector, websitesSelector } from 'store/website/websiteSelectors';
import { OrderedList } from 'styles/layout/lists';
import 'components/stepper/stepper.css';
import { useHistory } from 'react-router-dom';
import useModal from 'hooks/useModal';

const WebsiteStaging = () => {
  useTitle('Website Staging');
  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const websiteOptions = useSelector(websitesListSelector).filter(w => !w.is_staging);
  const [website, setWebsite] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const websites = useSelector(websitesSelector);

  const handleOnChange = e => {
    const { name } = e.target;
    let value = e.target.value.toLowerCase();
    if (name === 'website_slug') {
      WebsiteOptionsTrait.displayWarningIfOriginHasPremiumConfig(value, websites, modalDialog);
      if (!website.staging_slug || website.staging_slug.endsWith('-staging')) {
        setWebsite(prev => ({ ...prev, staging_slug: `${value}-staging`  }));
      }
    }
    setWebsite(prev => ({ ...prev, [name]: value }));
  };

  const onDeploySite = () => WebsiteOptionsTrait.onStaging(website, history, dispatch, setLoading);

  const breadcrumbs = JsxHelper.createBreadcrumbs('Create Staging Environment', 'website');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Create Staging Environment</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ position: 'relative' }}>
        <WPSForm
          onSubmit={handleSubmit(onDeploySite)}
          noValidate
          style={{ maxWidth: '1000px', marginTop: '20px' }}>
          <Fragment>
            <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px', marginTop: '0px' }}>
              <legend>Important Notes</legend>
              <WPSForm.Row>
                <OrderedList className='ol-instructions'>
                  <li>
                    <span>1.</span>
                    Settings for PHP, Nginx, WP Config, CRON, and Object Cache settings won't be copied; you can modify them later
                  </li>
                  <li>
                    <span>2.</span>
                    The same PHP version will be used for the new site to avoid compatibility issues; you can change it later.
                  </li>
                  <li>
                    <span>3.</span>
                    During the database copy process, old URLs are automatically replaced with new ones, including CDN links.
                  </li>
                  <li>
                    <span>4.</span>
                    Staging sites limit CDN use, domain keywords, S3 integration, and backup frequency.
                  </li>
                  <li>
                    <span>5.</span>
                    Staging sites are for test purposes only, not for live use, and have a visitor cap.
                  </li>
                </OrderedList>
              </WPSForm.Row>
            </WPSForm.Fieldset>
            <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px' }}>
              <legend>Origin Site</legend>
              {WebsiteOptionsTrait.renderSelectInputRowItem('Select origin site', 'website_slug', website, websiteOptions, true, handleOnChange)}
            </WPSForm.Fieldset>
            {WebsiteOptionsTrait.renderEnterAppID(website, false, false, handleOnChange, 'staging_slug')}
            {WebsiteOptionsTrait.renderLightButton('Deploy', handleSubmit(onDeploySite), loading)}
          </Fragment>
        </WPSForm>
      </Content>
    </Fragment>
  );
};

export default WebsiteStaging;
