import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import JsxHelper from 'helpers/jsx';
import { getStateColor } from 'helpers';

const ServerVolumes = () => {
  useTitle('Instance Volumes');
  const { state } = useLocation();

  const headers = [
    JsxHelper.createTableTimeHeader('create_time'),
    JsxHelper.createTableTextHeaderWithCallback('size', 'Size', '10%', row => `${row.size} GB`),
    {
      name: 'Modification state',
      selector: 'modification_state',
      width: '17%',
      cell: row => {
        let _value = row.modification_state;
        if (row.modification_state) {
          if (
            ['modifying', 'optimizing'].includes(row.modification_state) &&
            row.modification_progress
          ) {
            _value = `${row.modification_state.replace('-', ' ')} (${row.modification_progress}%)`;
          } else {
            _value = row.modification_state.replace('-', ' ');
          }
        } else {
          _value = 'ready';
        }
        return JsxHelper.createBubble({
          background: getStateColor(row.modification_state),
          text: _value,
          small: true,
        })
      },
    },
    JsxHelper.createTableStatusHeader('state', null, true),
    JsxHelper.createTableStatusHeader('status', null, true),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: state.server,
      link: `/servers/${state.server}/instances/${state.instance_id}`,
    },
    {
      text: state.id,
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Instance Volumes</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          columns={headers}
          body={state.volumes}
          dataKey='create_time'
          noSearchOnTable={true}
        />
      </Content>
    </Fragment>
  );
};

export default ServerVolumes;
