import React, { Fragment, useState, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { dnsZoneBySlug } from 'store/dnsZone/dnsZoneSelectors';
import { WPSButton } from 'styles/layout/buttons';
import { createDNSRecord, deleteDNSRecord, testDNSNameservers } from 'store/dnsZone/dnsZoneActions';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg,
} from 'store/global/globalActions';
import { getErrorMsg, isEmpty } from 'helpers';
import FormHelper from 'helpers/form';
import useConfirm from 'hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import Can from 'utils/can';
import dnsHelper from 'helpers/dnszone';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import ArrayHelper from 'helpers/array';
import useModal from 'hooks/useModal';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';
import DNSRecordModal from './recordModal';
import { websitesSelector } from 'store/website/websiteSelectors';
import { unassignDNSZone } from 'store/website/websiteActions';
import { useParams, useHistory } from 'react-router-dom';

const DNSZoneRecord = () => {
  useTitle('DNS Zone Records');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const confirm = useConfirm();
  const modalDialog = useModal();
  const { slug } = useParams();
  
  const dnsZone = useSelector(dnsZoneBySlug(slug || location.state));
  const dnsZoneRecords = ArrayHelper.generateUniqueField(dnsZone ? dnsZone.records : [], 'name', 'rowId');
  const initialState = dnsHelper.recordInitialState(dnsZone ? dnsZone.slug : null);

  const [record, setRecord] = useState(initialState);
  const [editFlag, setEditFlag] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [unassignLoading, setUnassignLoading] = useState(false);
  const [checkNsLoading, setCheckNsLoading] = useState(false);
  const [isTemplateModal, setIsTemplateModal] = useState(false);
  const [website, setWebsite] = useState(useSelector(websitesSelector).find(w => dnsZone && w.dns_zone_slug === dnsZone.slug));

  const goBackToDnsZones = () => {
    history.push({
      pathname: `/dns-zones`,
    });
  }

  useEffect(() => {
    if (!dnsZone) {
      window.logHelper.error('DNS Zone not found.');
      goBackToDnsZones();
    }
    // eslint-disable-next-line
  }, []);

  const createFromTemplate = async (template) => {
    let templateValues;
    if (template === dnsHelper.templates.GOOGLE_WORKSPACE) {
      templateValues = {
        dns_zone_slug: dnsZone.slug,
        name: '',
        type: 'MX',
        ttl: '300',
        values: dnsHelper.googleValues.map(el => {
          return { value: el };
        }),
      };
      templateValues = dnsHelper.initRecordFormData(templateValues, dnsZone);
      const duplicate = dnsHelper.checkIfRecordDuplicate(templateValues, dnsZoneRecords, dnsZone.name, true);
      if (!isEmpty(duplicate)) {
        DialogHelper.warning(modalDialog, `<b>${duplicate.type}</b> record <b>${duplicate.name}</b> conflicts with a template record.`);
        return;
      }
      setModalLoading(true);
      dispatch(createDNSRecord(templateValues))
        .then(() => {
          dispatch(setGlobalSuccessMsg({ id: templateValues.name, model: 'DNS record' }));
          setModalLoading(false);
          setModal(false);
        })
        .catch(err => {
          dispatch(setGlobalErrorMsg(err));
          setModalLoading(false);
        });
    } else if (template === dnsHelper.templates.OUTLOOK365_MAIL_SERVICE) {
      templateValues = dnsHelper.outlookMailServiceValues.map(el => {
        if (el.type === 'SRV') {
          el.values = [];
          const _val = `${el.priority} ${el.weight} ${el.port} ${el.hostName}`;
          el.values.push({ value: _val });
        } else {
          el.values = [];
          el.values.push({ value: el.value });
        }
        return dnsHelper.initRecordFormData(el, dnsZone);
      });
      const duplicate = dnsHelper.checkIfTemplateHasDuplicates(templateValues, dnsZoneRecords, dnsZone.name);
      if (!isEmpty(duplicate)) {
        DialogHelper.warning(modalDialog, `<b>${duplicate.type}</b> record <b>${duplicate.name}</b> conflicts with a template record.`);
        return;
      }
      setModalLoading(true);
      for (let i in templateValues) {
        let res = await createRequest(templateValues[i]);
        if (res) {
          dispatch(setGlobalSuccessMsg({ id: templateValues[i].name, model: 'DNS record' }));
        }
      }
      setModalLoading(false);
      setModal(false);
    }
  };

  const createRequest = async data => {
    await dispatch(createDNSRecord(data))
      .then(() => {
        return true;
      })
      .catch(err => {
        dispatch(setGlobalErrorMsg(err));
        return false;
      });
  };

  // Handle save record.
  const onSubmit = (record, template) => {
    if (isTemplateModal) {
      createFromTemplate(template);
    } else if (!isEmpty(record.values)) {
      // Check if the record already exists in the DNS zone
      const _data = {...record};
      const duplicate = dnsHelper.checkIfRecordDuplicate(_data, dnsZone.records, dnsZone.name, !editFlag);
      if (!isEmpty(duplicate)) {
        const recordDomain = dnsHelper.normalize(`${_data.name}.${dnsZone.name}`);
        DialogHelper.warning(modalDialog, `<b>${_data.type}</b> record <b>${recordDomain}</b> already exists.`, `Use the <b>Edit</b> button to update an existing record.`);
        return;
      }
      // Send API request to add record
      setModalLoading(true);
      const data = dnsHelper.initRecordFormData(record, dnsZone);
      dispatch(createDNSRecord(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: data.name, model: 'DNS record' }));
        setModal(false);
      })
      .catch(err => {
        const message = getErrorMsg(err);
        if (message.includes('CharacterStringTooLong')) {
          DialogHelper.knowledgeBasePopup(modalDialog, 'txt-record-in-dns-characterstringtoolong', 'Record value is too long.');
        } else {
          DialogHelper.error(modalDialog, message);
        }
      })
      .finally(() => setModalLoading(false));
    }
  };

  const handleDelete = item => {
    const data = {
      ...item,
      dns_zone_slug: record.dns_zone_slug,
      ttl: item.ttl || 0
    };
    dispatch(setGlobalPleaseWaitMsg({ model: 'DNS record', action: 'deleted' }));
    dispatch(deleteDNSRecord(data))
      .then(() => dispatch(setGlobalSuccessMsg({ id: item.name, model: 'DNS record', action: 'deleted' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  };

  const handleUnassignWebsite = () => {
    setUnassignLoading(true);
    const data = {
      website_slug: website.slug,
      dns_zone_slug: dnsZone.slug,
    };
    // Assign selected DNS zone to website.
    dispatch(unassignDNSZone(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({
          id: dnsZone.name,
          model: 'DNS zone',
          action: 'unassigned',
          to: WebsiteHelper.getLabel(website),
        }));
        setWebsite(null);
      })
      .finally(() => {
        setUnassignLoading(false);
        setModal(false);
      });
  }

  const onCheckNameservers = async () => {
    setCheckNsLoading(true);
    const data = {
      dns_zone_slug: dnsZone.slug
    }
    dispatch(testDNSNameservers(data))
    .then((result) => dnsHelper.openNSCheckDialog(modalDialog, dnsZone.name, result.success))
    .finally(() => setCheckNsLoading(false));
  }

  const handleAddRecord = type => {
    setModal(true);
    setEditFlag(false);
    setRecord(initialState);
    if (type === 'record') {
      setIsTemplateModal(false);
    } else if (type === 'template') {
      setIsTemplateModal(true);
    }
  };

  const actions = [
    {
      value: 'Edit',
      doHide: item => {
        if (['NS', 'SOA'].includes(item.type) || item.is_alias) {
          return true;
        }
        return !UserHelper.hasPermissions(`dns-zone:update:${dnsZone.slug}`);
      },
      onClick: item => {
        const _data = {
          ...item,
          name: dnsHelper.getShortRecordName(item.name, dnsZone.name),
          values: item.values.map(e => FormHelper.createSelectOption(e)),
        };
        setRecord(_data);
        setEditFlag(true);
        setModal(true);
      },
    },
    {
      value: 'Delete',
      doHide: item => {
        if (['NS', 'SOA'].includes(item.type) || item.is_alias) {
          return true;
        }
        return !UserHelper.hasPermissions(`dns-zone:update:${dnsZone.slug}`);
      },
      onClick: item => DialogHelper
        .confirmDelete(confirm, item.name, 'DNS record')
        .then(() => handleDelete(item))
    },
  ];

  const headers = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      searchable: true,
      width: '34%',
      cell: (row) => JsxHelper.createTableMultiLineCell({
        headerStyle: { marginRight: '25px' },
        header: row.name,
        subheader: dnsHelper.getRecordTypeText(row.type),
        prettify: false
      })
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      searchable: true,
      width: '8%',
      cell: row => JsxHelper.createText({
        text: row.type,
      }),
    },
    {
      name: 'TTL',
      selector: 'ttl',
      sortable: true,
      searchable: true,
      width: '8%',
      cell: row => JsxHelper.createText({
        text: row.ttl ? dnsHelper.getTTLReadableValue(row.ttl) : '-',
      }),
    },
    JsxHelper.createTableDNSRecordsHeader(dispatch, 'Values', '42%'),
    JsxHelper.createTableActionsHeader(actions, '8%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'DNS Zones',
      link: '/dns-zones',
    },
    {
      text: dnsZone ? dnsZone.name : '',
    },
  ];

  return (dnsZone ? <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>DNS Records</TitleBar.Title>
        <TitleBar.Actions>
          <Can
            perform={`dns-zone:update:${dnsZone.slug}`}
            yes={() => (
              <Fragment>
                <WPSButton className='alt--btn' loading={checkNsLoading} onClick={onCheckNameservers}>
                  Check Nameservers
                </WPSButton>
                {website && <WPSButton loading={unassignLoading} className='unassign--btn' onClick={() => handleUnassignWebsite()}>
                  Unassign Website
                </WPSButton>}
                <WPSButton className='tmplt--btn' onClick={() => handleAddRecord('template')}>
                  Templates
                </WPSButton>
                <WPSButton className='add--btn' onClick={() => handleAddRecord('record')}>
                  Add New Record
                </WPSButton>
              </Fragment>
            )}
          />
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {website && <span className='color-primary' style={{ display: 'flex', marginTop: '7px', marginLeft: '6px' }}>
          <span style={{ padding: '3px' }}>Assigned website (<span style={{fontWeight:'bold'}}>{WebsiteHelper.getLabel(website)}</span>) IP address:</span>
          {JsxHelper.createCopyButton({
            value: website.public_ipv4,
            dispatch,
          })}
        </span>}
        <WPSDataTable
          columns={headers}
          body={dnsZoneRecords}
          dataKey='rowId'
        />
        {modal && (
          <DNSRecordModal
            isEdit={editFlag}
            isTemplate={isTemplateModal}
            onSubmit={onSubmit}
            setRecord={setRecord}
            record={record}
            setModal={setModal}
            loading={modalLoading}
            domainName={dnsZone.name}
            dnsSlug={dnsZone.slug}
          >
          </DNSRecordModal>
        )}
      </Content>
    </Fragment> : null
  );
};

export default DNSZoneRecord;
