import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import {
  WPSForm,
  WPSLabel,
  WPSInput,
  WPSFileInput,
  ErrorMsg,
} from 'styles/layout/forms';
import { useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import { WPSButton } from 'styles/layout/buttons';
import Tooltip from 'components/layout/tooltip';
import { savePlatformDetails } from 'store/billing/billingActions';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { useDispatch, useSelector } from 'react-redux';
import { adminInvoiceDetails } from 'store/billing/billingSelectors';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import ArrayHelper from 'helpers/array';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';

const Details = () => {
  useTitle('Billing details');
  const dispatch = useDispatch();
  const savedDetails = useSelector(adminInvoiceDetails);
  const { handleSubmit, errors, register } = useForm({ reValidateMode: 'onSubmit' });

  const [details, setDetails] = useState({
    company_name: savedDetails ? savedDetails.company_name : '',
    company_phone: savedDetails ? savedDetails.company_phone : '',
    company_logo_file: '',
    company_logo_name: '',
    company_url: savedDetails ? savedDetails.company_url : '',
    company_address: savedDetails ? savedDetails.company_address : '',
    company_tax_id: savedDetails ? savedDetails.company_tax_id : '',
    company_tax_type: savedDetails ? savedDetails.company_tax_type : '',
    company_city: savedDetails ? savedDetails.company_city : '',
    company_state: savedDetails ? savedDetails.company_state : '',
    company_postcode: savedDetails ? savedDetails.company_postcode : '',
    company_country: savedDetails ? savedDetails.company_country : '',
  });
  const [logoFile, setLogoFile] = useState();
  const [imageURL, setImageURL] = useState(savedDetails ? savedDetails.company_logo_url : null);
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    const { name, value } = e.target;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  // Handle logo upload
  const handleFileUpload = e => {
    const { files } = e.target;
    if (files[0] !== undefined) {
      // Save file to state
      setDetails(prev => ({ ...prev, company_logo_name: files[0].name }));
      setLogoFile(files[0]);

      // Display selected image.
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        setImageURL(fr.result);
      });
    } else {
      setDetails(prev => ({ ...prev, company_logo_name: '' }));
      setLogoFile('');
    }
  };

  // Create form data to submit
  const createFormData = () => {
    let formData = new FormData();
    for (const key in details) {
      formData.append(key, details[key]);
    }
    if (logoFile) {
      formData.append('company_logo_file', logoFile);
    }
    return formData;
  };

  const onSubmit = () => {
    setLoading(true);
    const data = createFormData();
    dispatch(savePlatformDetails(data))
      .then(() =>
        dispatch(setGlobalSuccessMsg({ model: 'details', action: 'updated', plural: true })),
      )
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
      link: '/billing',
    },
    {
      text: 'Details',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          <div>
            Details
            <Tooltip
              place='right'
              text='Update the platforms company details that appear on the generated invoices to partner
            users.'
            />
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/billing')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSForm onSubmit={handleSubmit(onSubmit)}>
          <label>Company Logo</label>
          <WPSFileInput htmlFor='company_logo_file' style={{ marginTop: '0' }}>
            {imageURL && <img src={imageURL} alt='' />}
            <input
              type='file'
              id='company_logo_file'
              accept='.jpeg, .png, .jpg, .gif, .svg'
              onChange={handleFileUpload}
            />
            <span className='btn'>Browse</span>
            <span>
              {details.company_logo_name ? details.company_logo_name : 'No file chosen..'}
            </span>
          </WPSFileInput>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel required>
                Company Name
                {errors.company_name && <ErrorMsg>{errors.company_name.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='company_name'
                value={details.company_name}
                ref={register({ required: FormHelper.messages.required })}
                onChange={onChange}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='company_url' required>
                Website
                {errors.company_url && <ErrorMsg>{errors.company_url.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='company_url'
                value={details.company_url}
                onChange={onChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel required>Country</WPSLabel>
              <CountryDropdown
                id='company_country'
                value={details.company_country}
                classes='custom-select'
                whitelist={['US', 'AU']}
                onChange={val => setDetails(prev => ({ ...prev, company_country: val }))}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel required>State</WPSLabel>
              <RegionDropdown
                id='company_state'
                classes='custom-select'
                value={details.company_state}
                country={details.company_country}
                blankOptionLabel='Select State'
                defaultOptionLabel='Select State'
                onChange={val => setDetails(prev => ({ ...prev, company_state: val }))}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel required>City</WPSLabel>
              <WPSInput
                type='text'
                name='company_city'
                value={details.company_city}
                onChange={onChange}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel required>Postcode</WPSLabel>
              <WPSInput
                type='text'
                name='company_postcode'
                value={details.company_postcode}
                onChange={onChange}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel required>Address</WPSLabel>
              <WPSInput
                type='text'
                name='company_address'
                value={details.company_address}
                onChange={onChange}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel>Phone</WPSLabel>
              <WPSInput
                type='text'
                name='company_phone'
                value={details.company_phone}
                onChange={onChange}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='company_tax_type' required>
                Tax Number Type
              </WPSLabel>
              <WPSSelect
                name='company_tax_type'
                value={details.company_tax_type}
                options={ArrayHelper.buildSelectOptions(globalHelper.taxesTypes, 'name', 'value')}
                onChange={onChange}
                sortOff={true}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='company_tax_id' required>
                Tax Number
                {errors.company_tax_id && <ErrorMsg>{errors.company_tax_id.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='company_tax_id'
                value={details.company_tax_id}
                onChange={onChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSButton type='submit' className='save--btn max-width-150' loading={loading}>
            Save
          </WPSButton>
        </WPSForm>
      </Content>
    </Fragment>
  );
};

export default Details;
