import React, { useState, Fragment, useCallback } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import TinyMCE from 'components/Editor/TinyMCE';
import EditEmailTemplate from './editemaitemplatemodal';
import EmailService from 'services/emailtemplate';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader, CardTitle, CardText, CardFooter, RaisedCard } from 'styles/layout/cards';
import { Row, Col } from 'styles/layout/grid';
import { RingLoader } from 'styles/layout/loaders';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { globalEmailTemplatesSelector } from 'store/setting/settingSelectors';
import { Content } from 'styles/globalStyles';
import { resetEmailTemplate } from 'store/setting/settingActions';
import Tooltip from 'components/layout/tooltip';
import UserHelper from 'helpers/user';
import useTitle from 'hooks/useTitle';
import { useHistory } from 'react-router-dom';

const EmailTemplates = () => {
  useTitle('Email Templates');
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [selectedTemplate, selectTemplate] = useState(null);
  const [resetting, setResetting] = useState(false);

  const globalTemplates = useSelector(globalEmailTemplatesSelector);

  const handlClose = useCallback(() => {
    selectTemplate(null);
  }, []);

  const testEmail = async template => {
    setSendingEmail(true);
    try {
      const data = {
        key: template.key,
        sample_vars: template.sample_vars,
      };
      const res = await EmailService.test(template.key, data);
      const sentTo = res.sent_to.join(', ');
      dispatch(setGlobalSuccessMsg({ model: 'Preview email', action: 'sent', to: sentTo }));
    } catch (err) {
      dispatch(setGlobalErrorMsg(err));
    }
    setSendingEmail(false);
  };

  const handleResetTemplate = key => {
    setResetting(true);
    dispatch(resetEmailTemplate({ key }))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Email template', action: 'reset' })))
      .finally(() => setResetting(false));
  };

  const handleBackBtnClick = e => {
    history.goBack();
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Email Templates',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Email Templates</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='back--btn' onClick={handleBackBtnClick}>
            Back
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <Row gutter={12}>
          {!!globalTemplates &&
            globalTemplates.map((template, i) => {
              return (
                <Col cols={4} key={`email-template-${i}`} margin='0 0 24px 0' className='email-card'>
                  <RaisedCard>
                    <CardHeader style={{paddingRight:'0px', minHeight:'50px', display: 'inline-block', verticalAlign: 'top'}} color='primary' className='email-title'>
                      <span style={{lineHeight:'1.4'}}>{template.title} 
                      <Tooltip
                        place='right'
                        text={template.description}
                      />
                      </span>
                    </CardHeader>
                    <CardTitle className='email-subject' style={{minHeight:'62px'}}>
                      Subject
                      <CardText>{template.subject}</CardText>
                    </CardTitle>
                    <CardTitle margin='36px 0 6px 0'>Body</CardTitle>
                    <TinyMCE
                      value={template.content}
                      hasToolbar={false}
                      readonly={true}
                    />
                    <CardFooter className='action-buttons'>
                      <WPSButton className='edit--btn' disabled={sendingEmail} onClick={() => selectTemplate(template)}>Edit</WPSButton>
                      { UserHelper.isAdmin() && (
                        <WPSButton
                          className='success--btn'
                          onClick={() => testEmail(template)}
                          disabled={sendingEmail}>
                          {sendingEmail ? <RingLoader color='light' /> : 'Test'}
                        </WPSButton>
                      )}
                      <WPSButton
                        type='button'
                        className='warning--btn'
                        minWidth='80'
                        disabled={resetting}
                        onClick={() => handleResetTemplate(template.key)}>
                        {resetting ? <RingLoader color='light' margin='0 8px 0 0' /> : 'Reset'}
                      </WPSButton>
                    </CardFooter>
                  </RaisedCard>
                </Col>
              );
            })}
        </Row>
        <EditEmailTemplate
          show={!!selectedTemplate}
          value={selectedTemplate}
          onClose={handlClose}
        />
      </Content>
    </Fragment>
  );
};

export default EmailTemplates;
