import { getRequest, postRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Get all plans.
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const fetchPlansByCategory = data => {
  return getRequest({
    url: env.buildApiEndpoint('plans'),
    data
  });
};

/**
 * Creates a new plan.
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('plans'),
    data
  });
};

/**
 * Update the specified plan.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`plans/${data.plan_slug}`),
    data
  });
};

/**
 * Delete the specified plan.
 *
 * @param   {String}  planSlug
 */
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`plans/${data.plan_slug}`),
    data
  });
};

const PlanService = { fetchPlansByCategory, create, update, delete: del };

export default PlanService;
