import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import { websitesSelector, websitesListSelector } from 'store/website/websiteSelectors';
import ClientReportService from 'services/clientReport';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import DateHelper from 'helpers/date';
import { useForm } from 'react-hook-form';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import WebsiteHelper from 'helpers/website';
import { useDispatch } from 'react-redux';
import { timezoneSelector } from 'store/me/meSelectors';
import ArrayHelper from 'helpers/array';
import { updateClientReportConfig } from 'store/setting/settingActions';
import { globalClientReportConfigSelector } from 'store/setting/settingSelectors';
import UserHelper from 'helpers/user';
import TableHelper from 'helpers/table';
import { isEmptyOrNull } from 'helpers';
import { partnerBillingDetails } from 'store/billing/billingSelectors';
import { useHistory } from 'react-router-dom';

const ClientReports = () => {
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onSubmit' });
  const [loadingPDFs, setLoadingPDFs] = useState(false);
  const [loadingReceivers, setLoadingReceivers] = useState([false]);
  const modalDialog = useModal();
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [receiversData, setReceiversData] = useState([]);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [manageReceiversMode, setManageReceiversMode] = useState(false);
  const history = useHistory();
  const confirm = useConfirm();
  const timezone = useSelector(timezoneSelector);
  const allWebsites = useSelector(websitesSelector);
  const reportConfig = useSelector(globalClientReportConfigSelector);
  const billingDetails = useSelector(partnerBillingDetails);
  const hasUpdatePermissions = UserHelper.isPartner() || UserHelper.hasPermissions(`client-pdf-report:update:*`);
  const hasDeletePermissions = UserHelper.isPartner() || UserHelper.hasPermissions(`client-pdf-report:delete:*`);
  const websitesSelectOptions = useSelector(websitesListSelector).filter(option => {
    const website = allWebsites.find(w => w.slug === option.value);
    return website && website.is_live;
  });
  const mounted = useRef(true)
  const PAGE_LIMIT = 100;
  const EXCLUDE_PAGE_OPTIONS = [
    { label: 'Table of Contents', value: 'overview' },
    { label: 'Summary', value: 'summary' },
    { label: 'Active Plugins', value: 'active_plugins' },
    { label: 'Analytics', value: 'analytics' },
    { label: 'Updates', value: 'updates' },
    { label: 'Firewall', value: 'firewall' },
    { label: 'Backups', value: 'backups' },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch all PDF reports.
    fetchReports();
    // Ask to complete billing details.
    maybeAskToCompleteBillingDetails();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const maybeAskToCompleteBillingDetails = () => {
    if (UserHelper.isPartner() && (!billingDetails || !billingDetails.logo_url)) {
      DialogHelper.popup(
        modalDialog,
        'Fill Details',
        () => history.push({ pathname: '/billing/details', }),
        'Oops! client reports are missing your details.',
        'Please complete filling your details so client reports can include your logo and contact information.',
        { closeBtn: false, modalCloseIcon: true },
        'warning'
      );
    }
  }

  const fetchReceivers = () => {
    // Fetch all clients (only once).
    if (isEmptyOrNull(receiversData)) {
      const data = {}
      setLoadingReceivers(true);
      ClientReportService.listReceivers(data)
        .then((res) => setReceiversData(res))
        .catch((err) => dispatch(setGlobalErrorMsg(err)))
        .finally(() => setLoadingReceivers(false));
    }
  }

  const fetchReports = () => {
    const data = {
      page,
      limit: PAGE_LIMIT,
    }
    setLoadingPDFs(true);
    ClientReportService.listPDFs(data)
      .then((res) => {
        if (res.length < PAGE_LIMIT) {
          setDisableLoadMore(true);
        }
        setPage(page + 1);
        setReportsData(res);
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingPDFs(false));
  }
  
  // Settings

  const onClickSettings = () => {
    setModal({
      name: 'settings',
      enabled: reportConfig.enabled || false,
      exclude_pages: reportConfig.exclude_pages ? reportConfig.exclude_pages : [],
      send_emails_day: reportConfig.send_emails_day || 1,
    });
  }

  const switchTable = () => {
    // Fetch all receivers if not already fetched.
    !manageReceiversMode && fetchReceivers();
    // Switch table.
    setManageReceiversMode(!manageReceiversMode);
  }
  
  // Create Client / Preview / Send Email

  const prepareEmails = (emails) => 
    ArrayHelper.unique(emails.split(',').map(e => e.trim().toLowerCase()).filter(Boolean));
  
  const onClickCreateClient = () => setModal({
    name: 'upsert-client',
    emails: '',
    website_slug: '',
  })

  const onClickCreatePreview = () => setModal({
    name: 'create-preview',
    website_slug: '',
    start_date: DateHelper.now().subtract(5, 'day').format('YYYY-MM-DD'),
    end_date: DateHelper.now().subtract(1, 'day').format('YYYY-MM-DD'),
  })

  const onClickSendEmail = (item) => setModal({
    name: 'send-email',
    client_name: '',
    client_emails: '',
    guid: item.guid,
  })

  const handleModalChange = (e) => {
    const { name, value, values } = e.target;
    if (modal.name === 'settings' && ['exclude_pages'].includes(name)) {
      if (values.includes('all')) {
        setModal(prev => ({ ...prev, [name]: ['all'] }));
      } else {
        setModal(prev => ({ ...prev, [name]: values.map(o => o.value) }));
      }
      return;
    }
    setModal({ ...modal, [name]: value })
  }

  const handleAddClientSubmit = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = {
      website_slug: modal.website_slug,
      name: modal.client_name,
      emails: prepareEmails(modal.client_emails),
      guid: modal.guid || null,
    }
    const apiFunc = modal.guid ? ClientReportService.updateReceiver : ClientReportService.createReceiver;
    apiFunc(data)
      .then((res) => {
        if (modal.guid) {
          setReceiversData(prev => prev.map(r => r.guid === res.guid ? res : r));
        } else {
          setReceiversData(prev => [res, ...prev]);
        }
        setModal(false);
        const website = allWebsites.find(w => w.slug === modal.website_slug);
        dispatch(setGlobalSuccessMsg({ model: 'client', action: 'saved', forId: website ? website.slug : '' }));
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setModal(prev => ({ ...prev, loading: false })));
  }
  
  const handleSendEmailSubmit = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = {
      emails: prepareEmails(modal.client_emails),
      guid: modal.guid,
    }
    ClientReportService.sendPDF(data)
      .then(() => {
        setModal(false);
        dispatch(setGlobalSuccessMsg({ model: 'report', action: 'sent' }));
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setModal(prev => ({ ...prev, loading: false })));
  }

  const handleSettingsSubmit = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const config = {...modal};
    delete config.loading;
    delete config.name;
    dispatch(updateClientReportConfig({ config })).then(() => {
      setModal(false);
      dispatch(setGlobalSuccessMsg({ model: 'report settings', action: 'updated' }));
    })
    .catch((err) => dispatch(setGlobalErrorMsg(err)))
    .finally(() => setModal(prev => ({ ...prev, loading: false })));
  }

  const handleCreatePreviewSubmit = () => {
    // Verify website exists.
    if (!modal.website_slug) {
      DialogHelper.error(modalDialog, 'Please select a website.');
      return;
    }
    const website = allWebsites.find(w => w.slug === modal.website_slug);
    if (!website) {
      DialogHelper.error(modalDialog, 'Website not found.');
      return;
    }
    // Verify the start date is not more than one month ago.
    if (DateHelper.now().subtract(33, 'day').isAfter(modal.start_date)) {
      DialogHelper.error(modalDialog, 'Start date cannot be more than one month ago.');
      return;
    }
    // Verify the end date is not in the future.
    if (DateHelper.now().isBefore(modal.end_date)) {
      DialogHelper.error(modalDialog, 'End date cannot be in the future.');
      return;
    }
    // Create report asyncronously.
    const label = WebsiteHelper.getLabel(website);
    dispatch(setGlobalPleaseWaitMsg({ model: 'report', action: 'created', forId: label }));
    const data = {
      website_slug: modal.website_slug,
      start_date: modal.start_date,
      end_date: modal.end_date,
      type: 'preview',
    }
    setModal(false);
    ClientReportService.createPDF(data)
      .then((res) => {
        setReportsData(prev => [res, ...prev])
        dispatch(setGlobalSuccessMsg({ model: 'report', action: 'created', forId: label }));
      });
  }

  const downloadPDF = (item) => {
    const data = {
      website_slug: item.website_slug,
      guid: item.guid,
    };
    ClientReportService.downloadPDF(data);
  }

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Reports',
      link: '/reports',
    },
    {
      text: 'Client Reports',
      link: '#'
    }
  ];

  // Clients Table

  const clientsActions = [
    {
      value: 'Update',
      doHide: () => !hasUpdatePermissions,
      onClick: receiver => {
        setModal({
          name: 'upsert-client',
          website_slug: receiver.website_slug,
          client_name: receiver.name,
          client_emails: receiver.emails.join(', '),
          guid: receiver.guid,
        });
      }
    },
    {
      value: 'Delete',
      doHide: () => !hasDeletePermissions,
      onClick: receiver => {
        const website = allWebsites.find(w => w.slug === receiver.website_slug);
        DialogHelper.confirmDelete(confirm, receiver.name, 'client')
          .then(() => {
            dispatch(setGlobalPleaseWaitMsg({ model: 'client', action: 'deleted', forId: website ? website.slug : '' }));
            ClientReportService.deleteReceiver({ website_slug: receiver.website_slug, guid: receiver.guid})
          }).then(() => {
            setReceiversData(prev => prev.filter(r => r.guid !== receiver.guid));
            dispatch(setGlobalSuccessMsg({ model: 'client', action: 'deleted', forId: website ? website.slug : '' }));
          });
      }
    }
  ];

  const receiversHeader = [
    JsxHelper.createTableTextHeaderWithCallback('name', 'Client', '25%', (row) => {
      TableHelper.customizeCellValue(row, 'name', row.name + ' ' + row.emails.join(' '));
      return JsxHelper.createTableMultiLineCell({
        header: row.name,
        prettify: false,
        subheader: row.emails.join(', '),
        subheaderClass: 'text-word-break',
        style: { paddingRight: '5px' }
      })
    }),
    {
      name: 'Website',
      selector: 'website_slug',
      width: '30%',
      sortable: true,
      searchable: true,
      cell: (row) => {
        const website = allWebsites.find(w => w.slug === row.website_slug);
        if (website) {
          TableHelper.customizeCellValue(row, 'website_slug', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
        }
        return JsxHelper.createTableWebsiteCell({ website })
      },
    },
    JsxHelper.createTableBinaryBubbleHeader(
      'enabled',
      'Status',
      '15%',
      // If the current user is an admin or agent, don't check global settings.
      (row) => row.is_active && (reportConfig.enabled || UserHelper.isAdminOrAgent()),
      true,
      'Enabled',
      'Disabled'
    ),
    JsxHelper.createTableTimeHeader('last_sent_at', timezone, '15%', 'Last Emailed', 'Never'),
    JsxHelper.createTableActionsHeader(clientsActions, '15%'),
  ];

  // Reports Table

  const reportsActions = [{
    value: 'Send Email',
    onClick: onClickSendEmail,
  }, {
    value: 'Download',
    onClick: downloadPDF,
  }, {
    value: 'Delete',
    onClick: item => {
      const website = allWebsites.find(w => w.slug === item.website_slug);
      DialogHelper.confirmDelete(confirm, WebsiteHelper.getLabel(website), 'report')
        .then(() => {
          dispatch(setGlobalPleaseWaitMsg({ model: 'report', action: 'deleted', forId: website ? website.slug : '' }));
          ClientReportService.deletePDF({ website_slug: item.website_slug, guid: item.guid});
        }).then(() => {
          setReportsData(prev => prev.filter(r => r.guid !== item.guid));
          dispatch(setGlobalSuccessMsg({ model: 'report', action: 'deleted', forId: website ? website.slug : '' }));
        });
    }
  }]

  const reportsHeader = [
    JsxHelper.createTableTimeHeader('created_at', timezone, '13%', 'Create Time'),
    JsxHelper.createTablePeriodHeader('start_date', 'end_date', 'Report Period', '20%'),
    {
      name: 'Website',
      selector: 'website_slug',
      sortable: true,
      searchable: true,
      width: '28%',
      cell: (row) => JsxHelper.createTableWebsiteCell({
        website: allWebsites.find(w => w.slug === row.website_slug),
        paddingRight: '10px',
      }),
    },
    {
      name: <span>Type (<span style={{fontSize: '12px'}}>click to download</span>)</span>,
      selector: 'items',
      width: '27%',
      cell: row => JsxHelper.createButton({
        label: StringHelper.toText(row.type),
        classes: row.type === 'preview' ? 'primary--btn' : 'success--btn',
        small: true,
        onClick: () => downloadPDF(row),
      })
    },
    JsxHelper.createTableActionsHeader(reportsActions, '12%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>{manageReceiversMode ? 'Client List' : 'PDF Reports'}</TitleBar.Title>
        <TitleBar.Actions>
          {hasUpdatePermissions ? JsxHelper.createButton({ label: 'Global Settings', classes: 'primary--btn', onClick: onClickSettings }) : null}
          {manageReceiversMode ? JsxHelper.createButton({ label: 'PDF Reports', classes: 'warning--btn', onClick: switchTable }) : null}
          {manageReceiversMode && JsxHelper.createButton({ label: 'Add Client', classes: 'create--btn', onClick: onClickCreateClient })}
          {!manageReceiversMode ? JsxHelper.createButton({ label: 'Client List', classes: 'warning--btn', onClick: switchTable }) : null}
          {!manageReceiversMode && hasUpdatePermissions && JsxHelper.createButton({ label: 'Create Preview', classes: 'create--btn', onClick: onClickCreatePreview })}
          {!manageReceiversMode && !disableLoadMore && JsxHelper.createLoadMoreButton({ loading: loadingPDFs, onClick: fetchReports, className: 'alt--btn' })}
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {!manageReceiversMode && <WPSDataTable columns={reportsHeader} body={reportsData} loading={loadingPDFs} rowsPerPage={PAGE_LIMIT} dataKey={'guid'} />}
        {manageReceiversMode && <WPSDataTable columns={receiversHeader} body={receiversData} loading={loadingReceivers} rowsPerPage={PAGE_LIMIT} dataKey={'guid'} />}
      </Content>
      {modal && modal.name === 'settings' && DialogHelper.inputs({
        title: 'Settings',
        icon: 'settings',
        iconColor: 'primary',
        btnColor: 'primary',
        btnText: 'Save',
        loading: modal.loading,
        onConfirm: handleSubmit(handleSettingsSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'enabled',
          type: 'select',
          label: 'Monthly Report Status',
          value: modal.enabled,
          options: [{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }],
          onChange: handleModalChange,
          required: true,
          errors,
        },
        modal.enabled ? {
          name: 'send_emails_day',
          type: 'number',
          label: 'Send Emails Day [1-3]',
          labelTooltip: 'The day of the month to send the report. This option allows you to review the reports before they are sent to clients.',
          value: modal.send_emails_day,
          onChange: handleModalChange,
          required: true,
          min: 1,
          max: 3,
          errors,
        } : null,
        modal.enabled ? {
          name: 'exclude_pages',
          type: 'select',
          label: 'Exclude Pages From Report',
          value: modal.exclude_pages,
          isMultiSelect: true,
          options: ArrayHelper.buildSelectOptions(EXCLUDE_PAGE_OPTIONS, 'label', 'value'),
          onChange: handleModalChange,
          required: true,
          errors,
        } : null
      ].filter(Boolean)})}
      {modal && modal.name === 'upsert-client' && DialogHelper.inputs({
        title: `${modal.guid ? 'Edit' : 'Add'} Client`,
        icon: modal.guid ? 'edit' : 'create',
        header: <p>Assign a website to a client. The client will receive a monthly report in the beginning of each month.</p>,
        iconColor: 'success',
        btnColor: 'success',
        btnText: 'Save',
        loading: modal.loading,
        onConfirm: handleSubmit(handleAddClientSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'website_slug',
          type: 'select',
          label: 'Website',
          value: modal.website_slug,
          options: websitesSelectOptions,
          onChange: handleModalChange,
          required: true,
          disabled: modal.guid,
          errors,
        }, {
          name: 'client_name',
          type: 'text',
          label: 'Client Name',
          labelTooltip: 'The name has no effect on the email, it is only for your reference on this page.',
          value: modal.client_name,
          onChange: handleModalChange,
          required: true,
          errors,
        }, {
          name: 'client_emails',
          type: 'text',
          label: 'Client Emails (comma separated)',
          value: modal.client_emails,
          onChange: handleModalChange,
          required: true,
          errors,
        }]
      })}
      {modal && modal.name === 'create-preview' && DialogHelper.inputs({
        title: 'Create Preview Report',
        icon: 'create',
        iconColor: 'success',
        btnColor: 'success',
        btnText: 'Create',
        header: <p>This report is for testing only and won't notify clients. Monthly reports auto-generate from the 1st to the last day.</p>,
        loading: modal.loading,
        onConfirm: handleSubmit(handleCreatePreviewSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'website_slug',
          type: 'select',
          label: 'Website',
          value: modal.website_slug,
          options: websitesSelectOptions,
          onChange: handleModalChange,
          required: true,
          errors,
        }, {
          name: 'start_date',
          label: 'Start Date',
          type: 'date',
          value: modal.start_date,
          onChange: handleModalChange,
          required: true,
          errors,
        }, {
          name: 'end_date',
          label: 'End Date',
          type: 'date',
          value: modal.end_date,
          onChange: handleModalChange,
          required: true,
          errors,
        }]
      })}
      {modal && modal.name === 'send-email' && DialogHelper.inputs({
        title: 'Send Report Email',
        icon: 'email',
        iconColor: 'success',
        btnColor: 'success',
        btnText: 'Send',
        loading: modal.loading,
        header: <p>This will send the same report that the client receives monthly.</p>,
        onConfirm: handleSubmit(handleSendEmailSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'client_emails',
          type: 'text',
          label: 'Emails (comma separated)',
          value: modal.client_emails,
          onChange: handleModalChange,
          required: true,
          errors,
        }
      ]})}
    </Fragment>
  );
}

export default ClientReports;
