import React, { Fragment } from 'react';
import GitConnectPackage from '../../components/package/git-connect';
import { TitleBar } from '../../styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { WPSButton } from 'styles/layout/buttons';
import { useHistory } from 'react-router-dom';

const PluginGitConnect = () => {
  useTitle('Connect Plugin via Git Repository');
  const history = useHistory();

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Plugins',
      link: '/plugins',
    },
    {
      text: 'Connect via Git',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Connect via Git Repository</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='back--btn' onClick={() => history.goBack()}>
            Back
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <GitConnectPackage type='plugin' />
      </Content>
    </Fragment>
  );
};

export default PluginGitConnect;
