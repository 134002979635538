import React, { Fragment, useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm, WPSInput, WPSFileInput, ErrorMsg, WPSLabel } from 'styles/layout/forms';
import { WPSButton } from 'styles/layout/buttons';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useDispatch, useSelector } from 'react-redux';
import { saveDetails } from 'store/billing/billingActions';
import { useForm } from 'react-hook-form';
import Tooltip from 'components/layout/tooltip';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { partnerBillingDetails } from 'store/billing/billingSelectors';
import { partnerSelector } from 'store/me/meSelectors';
import { isEmpty } from 'helpers';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import ArrayHelper from 'helpers/array';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import globalHelper from 'helpers/globalHelper';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';

const Detail = ({ isCustomPage, handleStepperNext }) => {
  useTitle('Billing Details');
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });

  const billingDetails = useSelector(partnerBillingDetails);
  const partner = useSelector(partnerSelector);

  const [details, setDetails] = useState({
    business_name: !isEmpty(billingDetails) ? billingDetails.name : '',
    business_org_type: 'company',
    business_logo_name: '',
    business_email: !isEmpty(billingDetails) ? billingDetails.email : partner.email,
    business_url: !isEmpty(billingDetails) ? billingDetails.url : '',
    business_tax_id: !isEmpty(billingDetails) ? billingDetails.tax_id : '',
    business_tax_type: !isEmpty(billingDetails) ? billingDetails.tax_type : '',
    business_address_1: !isEmpty(billingDetails) ? billingDetails.address.address_1 : '',
    business_address_2: !isEmpty(billingDetails) ? billingDetails.address.address_2 : '',
    business_city: !isEmpty(billingDetails) ? billingDetails.address.city : '',
    business_state: !isEmpty(billingDetails) ? billingDetails.address.state : '',
    business_postcode: !isEmpty(billingDetails) ? billingDetails.address.postcode : '',
    business_country: !isEmpty(billingDetails) ? billingDetails.address.country : 'Australia',
    business_phone: !isEmpty(billingDetails) ? billingDetails.phone : '',
  });

  const [logoFile, setLogoFile] = useState();
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(
    !isEmpty(billingDetails) ? billingDetails.logo_url : null,
  );

  const handleOnChange = e => {
    const { name, value } = e.target;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  // Create form data to submit
  const createFormData = () => {
    let formData = new FormData();
    for (let key in details) {
      formData.append(key, details[key]);
    }
    if (logoFile) {
      formData.append('business_logo_file', logoFile);
    }
    return formData;
  };

  // Handle logo upload
  const handleFileUpload = e => {
    const { files } = e.target;
    if (files[0] !== undefined) {
      // Save file to state
      setDetails(prev => ({ ...prev, business_logo_name: files[0].name }));
      setLogoFile(files[0]);

      // Display selected image.
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        setImageURL(fr.result);
      });
    } else {
      setDetails(prev => ({ ...prev, business_logo_name: '' }));
      setLogoFile('');
    }
  };

  const onSubmit = () => {
    setLoading(true);
    const data = createFormData();
    dispatch(saveDetails(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'details', plural: true }));
        if (isCustomPage) {
          handleStepperNext();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Billing',
      link: '/billing',
    },
    {
      text: 'Details',
    },
  ];

  return (
    <Fragment>
      {!isCustomPage && (
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>
            <div>
              Details
              <Tooltip text='The details will be displayed on the generated partner invoices.' />
            </div>
          </TitleBar.Title>
          <TitleBar.Actions>
            {JsxHelper.createBackButton()}
          </TitleBar.Actions>
        </TitleBar>
      )}
      <Content className={isCustomPage ? 'padding-0' : ''}>
        <WPSForm onSubmit={handleSubmit(onSubmit)}>
          <label>Business Logo</label>
          <WPSFileInput htmlFor='business_logo_file' style={{ marginTop: '0' }}>
            {imageURL && <img src={imageURL} alt='' />}
            <input
              type='file'
              id='business_logo_file'
              accept='.jpeg, .png, .jpg, .gif, .svg'
              onChange={handleFileUpload}
            />
            <span className='btn'>Browse</span>
            <span>
              {details.business_logo_name ? details.business_logo_name : 'No file chosen..'}
            </span>
          </WPSFileInput>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_name' required>
                Business Name
                {errors.business_name && <ErrorMsg>{errors.business_name.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='business_name'
                value={details.business_name}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_phone' required>
                Phone
                {errors.business_phone && <ErrorMsg>{errors.business_phone.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='business_phone'
                value={details.business_phone}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_url' required>
                Website Address
                {errors.business_url && <ErrorMsg>{errors.business_url.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='business_url'
                value={details.business_url}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_email' required>
                Billing Email Address
                {errors.business_email && <ErrorMsg>{errors.business_email.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='email'
                name='business_email'
                value={details.business_email}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_address_1' required>
                Address Line 1
                {errors.business_address_1 && (
                  <ErrorMsg>{errors.business_address_1.message}</ErrorMsg>
                )}
              </WPSLabel>
              <WPSInput
                type='text'
                name='business_address_1'
                value={details.business_address_1}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_address_2'>Address Line 2</WPSLabel>
              <WPSInput
                type='text'
                name='business_address_2'
                value={details.business_address_2}
                onChange={handleOnChange}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_city' required>
                City
                {errors.business_city && <ErrorMsg>{errors.business_city.message}</ErrorMsg>}
              </WPSLabel>
              <WPSInput
                type='text'
                name='business_city'
                value={details.business_city}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_postcode' required>
                Zip/Post Code
                {errors.business_postcode && (
                  <ErrorMsg>{errors.business_postcode.message}</ErrorMsg>
                )}
              </WPSLabel>
              <WPSInput
                type='text'
                name='business_postcode'
                value={details.business_postcode}
                onChange={handleOnChange}
                ref={register({ required: 'This field is required' })}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_state' required>
                State
              </WPSLabel>
              <RegionDropdown
                id='business_state'
                classes='custom-select'
                blankOptionLabel='Select State'
                defaultOptionLabel='Select State'
                value={details.business_state}
                country={details.business_country}
                onChange={val => setDetails(prev => ({ ...prev, business_state: val }))}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_country' required>
                Country
              </WPSLabel>
              <CountryDropdown
                id='business_country'
                value={details.business_country}
                classes='custom-select'
                whitelist={['US', 'AU', 'NZ', 'IL', 'CA', 'GB', 'SG', 'IN', 'PK', 'AE', 'MY']}
                onChange={val => setDetails(prev => ({ ...prev, business_country: val }))}
              />
            </WPSForm.RowItem>
          </WPSForm.Row>
          <WPSForm.Row>
            <WPSForm.RowItem>
              <WPSLabel htmlFor='business_tax_type'>
                Business Registration Number
                <Tooltip
                  place='right'
                  text={
                    `The business registration number will appear on the generated invoices for your clients. You can leave this field empty if you don't use ${env.getBrandShortName()} to bill your clients.`
                  }
                />
              </WPSLabel>
              <WPSSelect
                name='business_tax_type'
                value={details.business_tax_type}
                options={ArrayHelper.buildSelectOptions(globalHelper.taxesTypes, 'name', 'value')}
                onChange={handleOnChange}
                sortOff={true}
              />
            </WPSForm.RowItem>
            <WPSForm.RowItem className='id-field-container'>
              {details.business_tax_type && (
                <Fragment>
                  <div className='display-flex'>
                    <WPSLabel htmlFor='business_tax_id'>
                      {details.business_tax_type.toUpperCase()} Number
                    </WPSLabel>
                  </div>
                  <WPSInput
                    type='text'
                    name='business_tax_id'
                    value={details.business_tax_id}
                    onChange={handleOnChange}
                  />
                </Fragment>
              )}
            </WPSForm.RowItem>
          </WPSForm.Row>
          {!isCustomPage ? (
            <WPSButton type='submit' className='save--btn max-width-150' loading={loading}>
              Save
            </WPSButton>
          ) : (
            <WPSButton
              className='next-light--btn margin-top-bottom-24'
              loading={loading}
              onClick={()=>handleSubmit(onSubmit)}>
              Next
            </WPSButton>
          )}
        </WPSForm>
      </Content>
    </Fragment>
  );
};

export default Detail;
