import StringHelper from './string';
import { isEmpty } from '.';
import DialogHelper from './dialog';
import env from 'config/env';

const description = `Powered by Amazon Route53, a DNS Zone allows you to handle your DNS setup directly from ${env.getBrandShortName()}. In addition, by assigning a DNS Zone to your website, ${env.getBrandShortName()} will automate certain processes such as the domain validation during the CDN deployment and save you from the manual hassles.`;

const multiValuesAllowed = (type) => {
  return !['CNAME'].includes(type);
};

const googleValues = [
  '1 ASPMX.L.GOOGLE.COM',
  '5 ALT1.ASPMX.L.GOOGLE.COM',
  '5 ALT2.ASPMX.L.GOOGLE.COM',
  '10 ALT3.ASPMX.L.GOOGLE.COM',
  '10 ALT4.ASPMX.L.GOOGLE.COM',
];

const getRecordTypeText = (type) => {
  const types = {
    A: 'IPv4 address record (A)',
    AAAA: 'IPv6 address record (AAAA)',
    CNAME: 'Canonical name record (CNAME)',
    MX: 'Mail exchange record (MX)',
    TXT: 'Text record (TXT)',
    SRV: 'Service locator (SRV)',
    SPF: 'Sender Policy Framework (SPF)',
    PTR: 'Pointer record (PTR)',
    NS: 'Name server record (NS)',
    SOA: 'Start of authority record (SOA)',
  };
  return types[type] || '';
};

const outlookMailServiceValues = [{
    type: 'CNAME',
    value: 'autodiscover.outlook.com.',
    ttl: '300',
    name: 'autodiscover'
  },
  {
    type: 'CNAME',
    value: 'sipdir.online.lync.com.',
    ttl: '300',
    name: 'sip'
  },
  {
    type: 'CNAME',
    value: 'webdir.online.lync.com.',
    ttl: '300',
    name: 'lyncdiscover'
  },
  {
    type: 'CNAME',
    value: 'enterpriseregistration.windows.net.',
    ttl: '300',
    name: 'enterpriseregistration'
  },
  {
    type: 'CNAME',
    value: 'enterpriseenrollment-s.manage.microsoft.com.',
    ttl: '300',
    name: 'enterpriseenrollment'
  },
  {
    type: 'TXT',
    value: 'v=spf1 include:spf.protection.outlook.com -all',
    ttl: 3600,
    name: ''
  },
  {
    type: 'SRV',
    name: '_sip._tls',
    ttl: 300,
    priority: 100,
    weight: 1,
    port: 443,
    hostName: 'sipdir.online.lync.com.'
  },
  {
    type: 'SRV',
    name: '_sipfederationtls._tcp',
    ttl: 300,
    priority: 100,
    weight: 1,
    port: 5061,
    hostName: 'sipfed.online.lync.com.'
  },

]

const typeTemplate = [{
    name: 'Google Workspace Mail Service',
    value: 'google_workspace'
  },
  {
    name: 'Outlook365 Mail Service',
    value: 'outlook_mail_service'
  },
];

const templates = {
  GOOGLE_WORKSPACE: typeTemplate[0].value,
  OUTLOOK365_MAIL_SERVICE: typeTemplate[1].value
}

const typeOptions = {
  A: {
    name: 'IPv4 Address',
    format: '',
    example: '192.0.0.1',
  },
  AAAA: {
    name: 'IPv6 Address',
    format: '',
    example: '2001:0db8::8a2e:0370:bab5',
  },
  CNAME: {
    name: 'Canonical Name',
    format: '',
    example: 'www.example.com',
  },
  MX: {
    name: 'Mail Exchange',
    format: '[priority] [mail server host name]',
    example: '10 mailserver.example.com',
  },
  TXT: {
    name: 'Text',
    format: '',
    example: 'Sample Text Entries',
  },
  SRV: {
    name: 'Service Location Locator',
    format: '[priority] [weight] [port] [server host name]',
    example: '1 10 5269 xmpp-server.example.com',
  },
  SPF: {
    name: 'Sender Policy Framework',
    format: '',
    example: 'v=spf1 ip4:192.168.0.1/16-all',
  },
  PTR: {
    name: 'Pointer',
    format: '',
    example: 'www.example.com',
  },
};

const ttlOptions = [{
    value: '300',
    text: '5 minutes'
  },
  {
    value: '1800',
    text: '30 minutes'
  },
  {
    value: '3600',
    text: '1 hour'
  },
  {
    value: '14400',
    text: '4 hours'
  },
  {
    value: 'custom',
    text: 'Custom (seconds)'
  },
];

const recordInitialState = (dnsSlug, values) => ({
  dns_zone_slug: dnsSlug || null,
  name: '',
  type: 'A',
  ttl: '300',
  values: values || [],
  inputValue: '',
});

const getTypesOptions = () => {
  let data = Object.entries(typeOptions).map(([key, value]) => {
    return {
      name: `${key} - ${value.name}`,
      value: key,
    };
  });
  return data;
};

const normalizeNSValue = value => StringHelper.trimRight(value, '.');

const getShortRecordName = (subdomain, domain) => {
  subdomain = normalizeNSValue(subdomain);
  domain = normalizeNSValue(domain);
  const indexOfDomain = subdomain.indexOf(domain);
  if (indexOfDomain === 0) {
    return '';
  }
  return subdomain.slice(0, indexOfDomain - 1);
};

const autoFixRecordFormData = data => {
  if (data.type === 'TXT') {
    for (const i in data.values) {
      // Replace bad double quotes
      let value = data.values[i];
      for (const ch of ['”', '“']) {
        if (value.startsWith(ch) || value.endsWith(ch)) {
          value = StringHelper.trim(value, ch);
        }
      }
      // Apply double quotes if not applied
      if (!value.startsWith('"') && !value.endsWith('"')) {
        value = '"' + value + '"';
      }
      data.values[i] = value;
    }
  }
  return data;
};

const initRecordFormData = (record, dnsZone) => {
  if (record.name === '@') {
    record.name = '';
  }
  const data = {
    dns_zone_slug: record.dns_zone_slug ? record.dns_zone_slug : dnsZone.slug,
    name: isEmpty(record.name) || record.name === '' ? dnsZone.name : `${record.name}.${dnsZone.name}`,
    type: record.type,
    ttl: record.ttl === 'custom' ? record.custom_ttl : record.ttl,
    values: !isEmpty(record.values) ? record.values.map(v => v.value) : [],
  };

  return autoFixRecordFormData(data);
};

const normalize = (d) => StringHelper.trim(d, '.');

const isEqual = (d1, d2) => {
  return normalize(d1) === normalize(d2);
}

const isEqualOrSubdomain = (subdomain, domain) => {
  return isEqual(subdomain, domain) || subdomain.endsWith(`.${domain}`);
}

const checkIfTemplateHasDuplicates = (records, allRecords, dnsZoneName) => {
  for (const record of records) {
    const duplicate = checkIfRecordDuplicate(record, allRecords, dnsZoneName, true);
    if (!isEmpty(duplicate)) {
      return duplicate;
    }
  }
  return false;
}

const checkIfRecordDuplicate = (record, allRecords, dnsZoneName, isNewRecord) => {
  // Append dns to subdomain name
  let _record = { ...record }
  if (_record.name === '@') {
    _record.name = '';
  }
  if (!_record.name.endsWith(dnsZoneName)) {
    _record.name = `${_record.name}.${dnsZoneName}`;
  }

  // Iterate over existing records and compare
  const duplicate = allRecords.find(r => {
    if (isEqual(r.name, _record.name) && r.type === _record.type) {
      if (isNewRecord) {
        return true; // New record does not exist in allRecords
      }
      return r.slug !== _record.slug; // Skip same record
    }
    return false;
  });
  return duplicate;
}

const getTTLReadableValue = (value) => {
  switch (value) {
    case '300':
      return '5 minutes';
    case '900':
      return '15 minutes';
    case '1800':
      return '30 minutes';
    case '3600':
      return '1 hour';
    case '14400':
      return '4 hours';
    case '172800':
      return '2 days';
    default:
      return `${value} seconds`
  }
}

const openNSCheckDialog = (modalDialog, domain, result, onClose) => {
  let params = { modalCloseIcon: false, };
  if (onClose) {
    params.onClose = onClose;
    params.btnText = 'Close';
    params.btnOnClick = onClose;
  }
  return result
    ? DialogHelper.success(modalDialog, 'Nameservers have been propagated successfully.', '', params)
    : DialogHelper.warning(
      modalDialog,
      'Your NS records have not been propagated yet (it may take up to 24 hours).',
      'You can check the nameservers status via <a class="goto-link" target="__blank" href="https://mxtoolbox.com/SuperTool.aspx?action=whois:' + domain + '&run=toolpage">WHOIS?</a>.',
      params
    );
}
const dnsHelper = {
  multiValuesAllowed,
  googleValues,
  outlookMailServiceValues,
  typeTemplate,
  typeOptions,
  recordInitialState,
  initRecordFormData,
  ttlOptions,
  getShortRecordName,
  normalizeNSValue,
  getTypesOptions,
  checkIfRecordDuplicate,
  normalize,
  isEqual,
  isEqualOrSubdomain,
  getTTLReadableValue,
  templates,
  checkIfTemplateHasDuplicates,
  description,
  getRecordTypeText,
  openNSCheckDialog
};

export default dnsHelper;