import { postRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Creates a new region.
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('regions'),
    data,
  });
};

/**
 * Update the specified region.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`regions/${data.region_slug}`),
    data,
  });
};

/**
 * Delete the specified region.
 *
 * @param   {String}  regionSlug
 */
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`regions/${data.region_slug}`),
    data,
  });
};

// Sync a region's EWSM code
const syncEWSM = data => {
  return postRequest({
    url: env.buildApiEndpoint(`regions/${data.region_slug}/sync-ewsm-code`),
    data,
  });
};

const RegionService = { create, update, delete: del, syncEWSM };

export default RegionService;
