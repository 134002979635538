import React, { useState, Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useHistory } from 'react-router-dom';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import env from 'config/env';
import ArrayHelper from 'helpers/array';
import { timezoneSelector } from 'store/me/meSelectors';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';

const RemoteBackupStorage = props => {
  useTitle('Remote Storage');

  const dispatch = useDispatch();
  const history = useHistory();
  const [modalLoading, setModalLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isHistoryPage, setIsHistoryPage] = useState(false);
  const [modal, setModal] = useState(false);
  const [remoteBackups, setRemoteBackups] = useState([]);
  const [backupRules, setBackupRules] = useState([{
    provider: 'Amazon S3',
    websites: 'All',
    frequency: 'Daily',
    action: 'Upload all default backups',
    status: 'active',
  }, {
    provider: 'Google Cloud Storage',
    websites: 'Website1, Website2',
    frequency: 'Weekly',
    action: 'Create full backups',
    status: 'active',
  }, {
    provider: 'Dropbox',
    websites: 'Website3',
    frequency: 'Monthly',
    action: 'Upload default database backups only',
    status: 'paused',
  }]);
  const [providerDetails, setProviderDetails] = useState({
    name: 's3',
    access_key_id: '',
    access_key_secret: '',
    service_account_key: '',
  });
  const timezone = useSelector(timezoneSelector);

  // Constants

  const breadcrumbs = JsxHelper.createBreadcrumbs('Remote Backup Storage', 'settings');

  const STORAGE_PROVIDERS = [
    { label: 'Amazon S3', value: 's3', authType: 'access_key'  },
    { label: 'Google Cloud Storage', value: 'gcs', authType: 'service_account' },
    { label: 'Dropbox', value: 'dropbox', authType: 'access_key' },
  ];

  // Helpers

  const showAccessKeyInput = () => STORAGE_PROVIDERS.find(p => p.value === providerDetails.name)?.authType === 'access_key'
  
  // Callbacks

  const onClickAddProvider = () => setModal(true);

  const onClickMangeProviders = () => {
  }

  const onSubmitRule = () => {
  }
  
  // Table Headers

  const historyHeaders = [
    JsxHelper.createTableTimeHeader('uploaded_at', timezone, '13%', 'Upload Time'),
    JsxHelper.createTableTextHeaderWithCallback('provider', 'Provider', '20%', (row) => {
      //
    }),
    JsxHelper.createTableTextHeaderWithCallback('types', 'Data', '20%', (row) => {
      //
    }),
    JsxHelper.createTableTextHeaderWithCallback('status', 'Status', '10%', (row) => {
      //
    }),
    JsxHelper.createTableTextHeader('size', 'Size', '10%'),
  ];

  const ruleActions = [
    {
      value: 'History',
      onClick: row => {
        setIsHistoryPage(true);
        setTableLoading(true);
      }
    },
    {
      value: 'Edit',
      onClick: row => {
        //
      }
    },
    {
      value: 'Pause',
      onClick: row => {
        //
      }
    },
    {
      value: 'Delete',
      onClick: row => {
        //
      }
    },
  ];

  const ruleHeaders = [
    JsxHelper.createTableTextHeaderWithCallback(
      'provider',
      'Provider',
      '20%',
      (row) => JsxHelper.createTableMultiLineCell({
        header: row.provider,
        subheader: '...sometext...',
      })
    ),
    JsxHelper.createTableTextHeader('websites', 'Websites', '20%'),
    JsxHelper.createTableTextHeader('frequency', 'Frequency', '10%'),
    JsxHelper.createTableTextHeader('action', 'Action', '30%'),
    JsxHelper.createTableStatusHeader('status'),
    JsxHelper.createTableActionsHeader(ruleActions, '10%'),
  ];

  // Render

  return (
    <div className='global-settings'>
      <TitleBar className='titlebar'>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Remote Backup Storage</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({
            label: 'Add Provider',
            classes: 'primary--btn',
            onClick: onClickAddProvider,
          })}
          {JsxHelper.createButton({
            label: 'Manage Providers',
            classes: 'warning--btn',
            onClick: onClickMangeProviders,
          })}
          {JsxHelper.createButton({
            label: 'Create Rule',
            onClick: onClickAddProvider,
          })}
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        Configure remote storage services for {env.getBrandShortName()} to connect to and store your site backups.
        We currently support <strong>Amazon S3</strong>, <strong>Google Cloud Storage</strong>, and <strong>Dropbox</strong>.
      </p>
      <Content>
        {isHistoryPage && <WPSDataTable columns={historyHeaders} body={remoteBackups || []} loading={tableLoading} dataKey='row_id' rowsPerPage={25} />}
        {!isHistoryPage && <WPSDataTable columns={ruleHeaders} body={backupRules || []} loading={tableLoading} noSearchOnTable={true} dataKey='row_id' rowsPerPage={25} />}
        {modal && DialogHelper.inputs({
          title: 'Add Storage Provider',
          confirmBtn: 'Create',
          icon: 'create',
          iconColor: 'success',
          onConfirm: onSubmitRule,
          onClose: () => setModal(false),
          modalLoading,
          inputs: [
            {
              type: 'select',
              name: 'provider',
              label: 'Storage Provider',
              options: ArrayHelper.buildSelectOptions(STORAGE_PROVIDERS, 'label', 'value'),
              value: providerDetails.name,
              required: true,
              onChange: e => {
                const value = e.target.value;
                setProviderDetails({
                  ...providerDetails,
                  name: value,
                });
              }
            },
            showAccessKeyInput() ? {
              type: 'text',
              name: 'access_key_id',
              label: 'Access Key',
              placeholder: 'Enter your access key',
              value: providerDetails.access_key_id,
              required: true,
            } : null,
            showAccessKeyInput() ? {
              type: 'text',
              name: 'access_key_secret',
              label: 'Secret Key',
              placeholder: 'Enter your secret key',
              value: providerDetails.access_key_secret,
              required: true,
            } : null,
            !showAccessKeyInput() ? {
              type: 'textarea',
              name: 'service_account',
              label: 'Service Account Key File',
              placeholder: 'Copy and paste your service account key file',
              value: providerDetails.service_account_key,
              required: true,
            } : null,
          ],
        })}
      </Content>
    </div>
  );
};

export default RemoteBackupStorage;
