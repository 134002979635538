// Array helpers

// Given an array of objects, and make new unique value/key/field that those objects don't have
// arr : array of objects
// key : field name to concate with (Only String or Number)
// newKey : new field name
// main usage for the data table that contain object without unique value/key/field
const generateUniqueField = (arr, key, newKey) => {
  return arr.map((el, index) => {
    return {
      ...el,
      [newKey]: el[key] ? el[key] + index : newKey + index,
    };
  });
};

const buildSelectOptions = (arr, itemLabel, itemValue, noneText, noneValue) => {
  itemLabel = itemLabel || 'name';
  itemValue = itemValue || 'value';
  const options = arr.map(el=>{
    return {label: el[itemLabel], value: el[itemValue], isDisabled: el.isDisabled? el.isDisabled : false}
  })
  if (noneText) {
    options.unshift({label: noneText, value: noneValue || ''});
  }
  return options;
};

// Given an array of objects, try to find an object with the specified key that equals the value
const findObject = (arr, el, key) => {
  return arr.find(obj => obj[key] === el[key]);
};

// Return an array of value/label for the select component
// Input original array (arr), requested value and label key pairs.
// Returns an array.
const getSelectOptions = (arr, value, label) => {
  return arr.map(item => ({
    value: item[value],
    label: item[label],
  }));
};

// Return the first AND ONLY object from an array where the key value equals value.
// TO USE: pass in the array to filter and the item key and value required.
// Returns an object.
const find = (arr, key, value) => {
  return arr.find(item => item[key] === value);
};

// Filter an object out of an array.
// Returns an array.
// TODO: Add the ability to filter with a callback function.
const filterOut = (arr, key, value) => {
  return arr.filter(item => item[key] !== value);
};

// Get objects that satisfy the condition.
// Returns an array.
// TODO: Add the ability to filter with a callback function.
const filterIn = (arr, key, value) => {
  return arr.filter(item => item[key] === value);
};

// Return a new array with the updated object at the same index.
const update = (arr, key, value) => {
  return arr.map(item => (item[key] === value[key] ? value : item));
};

// Return a new array with the reversed order.
const reverse = arr => arr.reverse();

// Return the first element of an array.
const first = arr => {
  if (!arr || arr.length === 0) {
    return null;
  }
  const [firstElement] = arr;
  return firstElement;
};

const uniqueDeep = (arr, key) => {
  const seen = new Set();
  return arr.filter(item => {
    const k = item[key];
    if (k === undefined) return true;
    return seen.has(k) ? false : seen.add(k);
  });
}
const last = arr => arr && arr.length ? arr[arr.length - 1] : null;
const unique = arr => [...new Set(arr)];
const clone = data => JSON.parse(JSON.stringify(data));
const areEquals = (arr1, arr2) => arr1.length === arr2.length && arr1.filter(x => arr2.includes(x));

const indexBy = (arr, key) => {
  return arr.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};

const ArrayHelper = {
  buildSelectOptions,
  getSelectOptions,
  find,
  filterOut,
  filterIn,
  update,
  reverse,
  first,
  last,
  clone,
  unique,
  uniqueDeep,
  findObject,
  areEquals,
  generateUniqueField,
  indexBy,
};

export default ArrayHelper;
