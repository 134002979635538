import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import { websitesSelector } from 'store/website/websiteSelectors';
import ReportService from 'services/report';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import TableHelper from 'helpers/table';
import PackageHelper from 'helpers/package';
import WebsiteHelper from 'helpers/website';
import 'components/package/packages.css';

const AvailablePackageUpdates = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const allWebsites = useSelector(websitesSelector);
  const mounted = useRef(true)

  useEffect(() => {
    fetchPackages();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const fetchPackages = () => {
    const data = {
        limit: 1000,
        search: null,
        updatable_only: true,
    }
    setLoading(true);
    ReportService.filterAppsInstalledPacakges(data)
      .then((res) => {
        setTableData(res.map(item => {
          item.key = StringHelper.randomSlug();
          item.latest_version = item.update || null;
          item.latest_version_url = item.update_url || null;
          return item;
        }));
      })
      .finally(() => setLoading(false));
  }

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Reports',
      link: '/reports',
    },
    {
      text: 'Available Package Updates',
      link: '#'
    }
  ];

  const headers = [
    PackageHelper.renderScopeHeader(),
    {
      name: 'Website',
      selector: 'website',
      searchable: true,
      width: '27%',
      cell: (row) => {
        const website = allWebsites.find(w => w.slug === row.website_slug);
        if (website) {
          TableHelper.customizeCellValue(row, 'website', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
        }
        return JsxHelper.createTableWebsiteCell({
          website,
          paddingRight: '10px',
        })
      }
    },
    PackageHelper.renderDisplayNameHeader('Package', '32%'),
    {
      name: 'Version Update',
      selector: 'version',
      searchable: true,
      width: '15%',
      cell: (row) => JsxHelper.createTableCellFromTo({
        from: row.version,
        to: row.latest_version,
        icon: !row.latest_version_url ? 'warning' : null,
        iconColor: !row.latest_version_url ? 'warning' : null,
        tooltip: !row.latest_version_url ? 'The new version can only be installed from the WordPress dashboard.' : null,
      }),
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Available Packages Updates</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          customClass='package-history-table'
          columns={headers}
          body={tableData}
          loading={loading}
          rowsPerPage={100}
          dataKey={'key'}
        />
      </Content>
    </Fragment>
  );
}

export default AvailablePackageUpdates;
