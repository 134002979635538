import React, { useState, createContext, Fragment, useRef } from 'react';
import DynamicModal from '../../components/wpstaq/DynamicModal/DynamicModal';

// Confirm modal component
const ModalDialog = ({
  open,
  line1,
  line2,
  btnText,
  btnColor,
  headerTitle,
  iconSpinner,
  btnIcon,
  hideCloseBtn,
  closeBtn,
  closeBtnColor,
  btnOnClick,
  textAlign,
  onClose,
  type,
  btnsStyle,
  btnsAlign,
  customLineOneTextClass,
  hideIcon,
  editorMode,
  editorConfig,
  modalCloseIcon,
  preventLine1Parsing,
  preventLine2Parsing,
  hideHeader,
  rawHtml,
  customClass,
}) => {
  const warningData = {
    icon: 'warning',
    iconColor: 'warning',
    btnsClass: 'warning--btn',
  };
  const infoData = {
    icon: 'info',
    iconColor: 'info',
    btnsClass: 'info--btn',
  };
  const successData = {
    icon: 'success',
    iconColor: 'success',
    btnsClass: 'success--btn',
  };
  const dangerData = {
    icon: 'danger',
    iconColor: 'danger',
    btnsClass: 'danger--btn',
  };
  const loadingData = {
    icon: 'arrowsLoading',
    iconColor: 'info',
    btnsClass: 'info--btn',
  };
  const getTypeData = () => {
    switch (type) {
      case 'warning':
        return warningData;
      case 'info':
        return infoData;
      case 'success':
        return successData;
      case 'danger':
        return dangerData;
      case 'loading':
        return loadingData;
      default:
        return infoData;
    }
  };
  return (
    open && (
      <DynamicModal
        line1={line1}
        line2={line2}
        {...getTypeData()}
        btnColor={btnColor}
        closeBtn={closeBtn}
        headerTitle={headerTitle}
        iconSpinner={iconSpinner}
        btnText={btnText}
        btnIcon={btnIcon}
        hideCloseBtn={hideCloseBtn}
        closeBtnColor={closeBtnColor}
        customLineOneTextClass={customLineOneTextClass}
        btnsStyle={btnsStyle}
        btnsAlign={btnsAlign}
        hideIcon={hideIcon}
        editorMode={editorMode}
        textAlign={textAlign}
        editorConfig={editorConfig}
        modalCloseIcon={modalCloseIcon}
        preventLine1Parsing={preventLine1Parsing}
        preventLine2Parsing={preventLine2Parsing}
        btnOnClick={(onClose) => btnOnClick(onClose)}
        btnOnClose={() => onClose()}
        hideHeader={hideHeader}
        rawHtml={rawHtml}
        customClass={customClass}
      />
    )
  );
};

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [confirmState, setConfirmState] = useState(null);

  const awaitPromiseRef = useRef();

  const openConfirmation = options => {
    setConfirmState(options);
    return new Promise((resolve, reject) => {
      awaitPromiseRef.current = { resolve, reject };
    });
  };

  const handleOnConfirm = () => {
    awaitPromiseRef.current.resolve();
    setConfirmState(null);
  };

  const handleOnClose = () => {
    if (confirmState.catchOnCancel && awaitPromiseRef.current) {
      awaitPromiseRef.current.reject();
    }
    setConfirmState(null);
  };

  return (
    <Fragment>
      <ModalContext.Provider value={openConfirmation} children={children} />
      <ModalDialog
        open={Boolean(confirmState)}
        btnOnClick={handleOnConfirm}
        onClose={handleOnClose}
        {...confirmState}
      />
    </Fragment>
  );
};
