import React, { Fragment, useEffect, useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import { setGlobalInfoMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { installPackage } from 'store/website/websiteActions';
import useModal from 'hooks/useModal';
import { isEmptyOrNull, delayPromise, versionCompare, isNullOrUndefined, getErrorMsg } from 'helpers';
import DialogHelper from 'helpers/dialog';
import ArrayHelper from 'helpers/array';
import StringHelper from 'helpers/string';
import { Container } from 'styles/website/profile';
import { WPSButton } from 'styles/layout/buttons';
import JsxHelper from 'helpers/jsx';
import TableHelper from 'helpers/table';
import PackageHelper from 'helpers/package';
import WebsiteHelper from 'helpers/website';
import ReportService from 'services/report';
import 'components/package/packages.css';
import UserHelper from 'helpers/user';

const PackageConverter = ({globalPackage}) => {
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const websites = useSelector(websitesSelector);

  const type = globalPackage.type;
  const version = globalPackage.version;
  const slug = globalPackage.slug;

  const [loading, setLoading] = useState(false);
  const [convertablePackages, setConvertablePackages] = useState(null);
  const [countConverting, setCountConverting] = useState(0);
  const [convertingAllCount, setConvertingAllCount] = useState(0);
  const [convertAll, setConvertAll] = useState(false);

  const DRY_RUN = false;
  const MAX_CONC_CONVERSIONS = 3;
  const STATUS_FAILED = 'failed';
  const STATUS_QUEUED = 'queued';
  const STATUS_CONVERTING = 'converting';
  const STATUS_CONVERTED = 'converted';

  useEffect(() => {
    if (isNullOrUndefined(convertablePackages)) {
      if (!globalPackage.partner_slug) {
        DialogHelper.warning(modalDialog, `Only partner ${type}s can be converted.`);
        return;
      }
      setLoading(true);
      const data = {
        search: globalPackage.display_name || globalPackage.folder_name,
        package_type: type,
        fields: [ 'version', 'status', 'scope', 'name', 'display_name', 'updated_at' ],
      };
      if (UserHelper.isAdminOrAgent() && PackageHelper.isPartner(globalPackage)) {
        data.partner_slug = globalPackage.partner_slug;
      }
      ReportService.filterAppsInstalledPacakges(data).then(packages => {
        packages = packages.map(p => ({ ...p, slug: p.slug || StringHelper.randomSlug() }))
        setConvertablePackages(packages.filter(p => 
          (PackageHelper.isUnmanaged(p) || PackageHelper.scopeCompare(globalPackage, p) > 0) &&
          p.display_name === globalPackage.display_name // prevents issues e.g. "Yoast SEO" and "Yoast SEO Premium"
        ));
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [convertablePackages]);

  useEffect(() => {
    // If convert all is true, convert next packages.
    if (convertAll && countConverting < MAX_CONC_CONVERSIONS) {
      convertNextPackages();
    }
    // eslint-disable-next-line
  }, [convertingAllCount, convertablePackages, countConverting]);

  const isActivePackage = (websiteSlug) => {
    const website = websites.find(w => w.slug === websiteSlug);
    return WebsiteHelper.isActivePackage(website, globalPackage, convertablePackages);
  }

  const convertingCount = (convertablePackages || []).filter(p => {
    return p.convert_status === STATUS_QUEUED || p.convert_status === STATUS_CONVERTING;
  }).length;

  const convertedCount = (convertablePackages || []).filter(p => {
    return p.convert_status === STATUS_CONVERTED;
  }).length;
  const totalCount = (convertablePackages || []).length;

  const queueActivePackages = () => {
    const queuedPackages = (convertablePackages || [])
      .filter(p => isActivePackage(p.website_slug))
      .map(p => {
        if ([STATUS_CONVERTED, STATUS_CONVERTING, STATUS_QUEUED].includes(p.convert_status)) {
          return p; // Already handled
        }
        return { ...p, convert_status: STATUS_QUEUED };
      });
    setConvertAll(true);
    setConvertablePackages(queuedPackages);
    setConvertingAllCount(1);
  };

  const convertNextPackages = () => {
    const queuedPackages = (convertablePackages || []).filter(p => p.convert_status === STATUS_QUEUED);
    if (!isEmptyOrNull(queuedPackages)) {
      const nextPackages = queuedPackages.slice(0, MAX_CONC_CONVERSIONS - countConverting);
      window.logHelper.info(`Converting ${nextPackages.length} packages...`);
      nextPackages.forEach(p => convertPackage(p));
    }
  };

  const convertPackage = async (item) => {
    updateConvertingStatus(item);
    if (!DRY_RUN) {
      dispatch(installPackage({
        'website_slug': item.website_slug,
        'package_slug': slug,
      })).then(() => updateConvertedStatus(item))
        .catch(err => {
          updateFailedStatus(item, getErrorMsg(err));
          dispatch(setGlobalErrorMsg(err));
        });
    } else {
      delayPromise(3000).then(() => updateConvertedStatus(item));
    }
  };

  const updateFailedStatus = async (item, err) => {
    updatePackageConvertStatus(item, STATUS_FAILED);
    setConvertablePackages(prev => prev.map(p => {
      if (p.slug === item.slug) {
        return { ...p, status_reason: err };
      }
      return p;
    }))
    setCountConverting(prev => prev - 1);
  }

  const updateConvertingStatus = async (item) => {
    updatePackageConvertStatus(item, STATUS_CONVERTING);
    setCountConverting(prev => prev + 1);
  }

  const updateConvertedStatus = async (item) => {
    item.version = version;
    updatePackageConvertStatus(item, STATUS_CONVERTED);
    setCountConverting(prev => prev - 1);
    if (convertAll) {
      setConvertingAllCount(prev => prev + 1);
    }
  }

  const updatePackageConvertStatus = (item, status) => {
    if (!item) {
      window.logHelper.info(`Updating ${item.website_slug} ${type} status to ${status}.`);
      return;
    }
    window.logHelper.info(`Updating ${item.website_slug} ${type} status to ${status}.`);
    item = { ...item, convert_status: status };
    setConvertablePackages(prev => [...ArrayHelper.update(prev, 'slug', item)]);
  };

  const stopConvertion = () => {
    const unqueuedPackages = convertablePackages.map(p => {
      if (p.convert_status === STATUS_QUEUED) {
        window.logHelper.info(`Stopped converting ${p.slug} ${type} (status=${p.convert_status}).`);
        return { ...p, convert_status: '' };
      } else {
        return p;
      }
    });
    setConvertAll(false);
    setConvertablePackages(unqueuedPackages);
  };

  const convertWithConfirm = (row, line1, line2) => 
    DialogHelper.warning(modalDialog, line1, line2, {
      btnText: 'Confirm',
      btnOnClick: onClose => {
        convertPackage(row);
        onClose();
      },
    })

  const sendConvertRequest = (row) => {
    const isNewer = versionCompare(version, row.version) < 0;
    if (countConverting >= MAX_CONC_CONVERSIONS && row.convert_status !== STATUS_CONVERTING) {
      dispatch(setGlobalInfoMsg(`You can only convert ${MAX_CONC_CONVERSIONS} ${type} at a time.`));
    } else if (!isActivePackage(row.website_slug)) {
      const message = `The ${type} is not active on the website. By converting it, the ${type} will be activated. Are you sure you want to convert this ${type}?`;
      convertWithConfirm(row, message);
    } else if (isNewer) {
      const line1 = `This version is newer than the global one.`;
      const line2 = `Are you sure you want to convert this ${type}?`;
      convertWithConfirm(row, line1, line2);
    } else {
      convertPackage(row);
    }
  }

  const renderStatusCell = (row) => {
    const details = row.convert_status === STATUS_CONVERTED ? [ 'Converted', 'success' ]
                  : row.convert_status === STATUS_CONVERTING ? [ 'Converting...', 'primary' ]
                  : row.convert_status === STATUS_QUEUED ? [ 'Queued', 'info' ]
                  : row.convert_status === STATUS_FAILED ? [ 'Failed', 'danger' ]
                  : [ 'Not converted', 'warning' ];
    return JsxHelper.createBubble({
      background: details[1],
      text: details[0],
      small: true,
      customClass: 'update-status-cell',
      tooltip: row.convert_status === STATUS_FAILED ? row.status_reason : null,
    });
  }

  const converterActions = [
    {
      value: 'Retry',
      doHide: row => isEmptyOrNull(row.convert_status) || row.convert_status !== STATUS_FAILED,
      onClick: row => sendConvertRequest(row, true),
    },
    {
      value: 'Exclude',
      onClick: row => setConvertablePackages((convertablePackages || []).filter(p => p.slug !== row.slug)),
      doHide: row => row.convert_status === STATUS_CONVERTED || row.convert_status === STATUS_CONVERTING,
    },
    {
      value: 'Convert',
      doHide: row => !isEmptyOrNull(row.convert_status) || row.convert_status === STATUS_FAILED,
      onClick: row => sendConvertRequest(row),
    },
  ];

  const converterHeaders = [
    PackageHelper.renderScopeHeader(),
    PackageHelper.renderWebsiteHeader(websites),
    {
      name: 'Version',
      selector: 'version',
      width: '10%',
      sortable: true,
      cell: row => {
        const installedVersion = row.version;
        const isNewer = versionCompare(version, installedVersion) < 0;
        return (
          <div className='version-cell'>
            {row.convert_status === STATUS_CONVERTED ? version : row.version}
            {isNewer && JsxHelper.createBubble({
              icon: 'warning',
              color: 'warning',
              tooltip: `The installed ${type} version is newer than the global one.`,
            })}
          </div>
        );
      },
    },
    {
      name: 'Status',
      selector: 'status',
      width: '15%',
      cell: renderStatusCell,
    },
    {
      name: 'Active',
      selector: 'is_active',
      sortable: true,
      searchable: true,
      width: '10%',
      cell: row => {
        const isActive = isActivePackage(row.website_slug);
        row = TableHelper.customizeCellValue(row, 'is_active', isActive ? 'Yes' : 'No');
        return JsxHelper.createBubble({
          background: isActive ? 'success' : 'primary',
          text: isActive ? 'Yes' : 'No',
          small: true,
          customClass: 'active-cell',
        })
      },
    },
    JsxHelper.createTableActionsHeader(converterActions, '30%'),
  ];

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar padding-0' style={{ minHeight: '54px' }}>
        <TitleBar.Title style={{ fontSize: '20px' }}>{globalPackage.display_name} v{version}</TitleBar.Title>
        <TitleBar.Actions>
          <Fragment>
            {convertingCount > 0 && convertAll ? (
              <div style={{ float: 'right', marginBottom: '10px' }}>
                <div style={{ padding: '8px', display: 'inline-block' }}>
                  Converted {convertedCount} / {totalCount} {type}s
                </div>
                <WPSButton
                  style={{ float: 'right' }}
                  className='danger--btn'
                  onClick={() => stopConvertion()}>
                  Stop
                </WPSButton>
              </div>
            ) : !convertAll || (convertedCount !== totalCount && convertingCount !== totalCount) ? (
              (!isEmptyOrNull(convertablePackages) && <WPSButton
                style={{ textTransform: 'inherit', float: 'right', marginBottom: '10px' }}
                className='action--btn'
                onClick={queueActivePackages}>
                Convert Active {StringHelper.capitalizeFirstLetter(type)}s
              </WPSButton>)
            ) : (
              <></>
            )}
          </Fragment>
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader' style={{ 'padding': '0' }}>
        Converting {type}s can impact both your website's functionality and appearance.
        Be sure to review your website once the conversion is complete.
        Please note that currently, only acitve {type}s can be auto-converted.
      </p>
      <WPSDataTable
        customClass={'packages-table'}
        columns={converterHeaders}
        body={convertablePackages}
        loading={loading || isNullOrUndefined(convertablePackages)}
        noSearchOnTable={false}
        rowsPerPage={100}
        dataKey='website_slug'
      />
    </Container>
  );
};

export default PackageConverter;
