import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { stripeClientIdSelector } from 'store/platform/platformSelectors';
import { useQueryResult } from 'hooks/useQuery';
import { WPSButton } from 'styles/layout/buttons';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { setIntegration } from 'store/me/meActions';
import { useHistory } from 'react-router-dom';
import { redirect, connect } from 'services/stripe';

const StripeConnect = ({isCustomPage}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stripeClientId = useSelector(stripeClientIdSelector);
  const query = useQueryResult();

  const connectStripe = () => {
    setLoading(true);
    const responseType = 'code';
    const scope = 'read_write';
    redirect({
      responseType,
      scope,
      clientId: stripeClientId,
    });
  };

  const connectStripeAccount = async code => {
    try {
      setLoading(true);
      const res = await connect(code);
      dispatch(setIntegration(res.integrations));
      dispatch(setGlobalSuccessMsg({ model: 'Stripe account', action: 'integrated' }));
      setLoading(false);
      history.replace(history.location.pathname);
    } catch (err) {
      setLoading(false);
      dispatch(setGlobalErrorMsg(err));
    }
  };

  useEffect(() => {
    if (query.scope && query.code) {
      connectStripeAccount(query.code);
    }
  }, []); //eslint-disable-line

  return (
    <>
      {!isCustomPage && <div className='description'>
        <div>Integrate your Stripe account and start receiving payments from your clients.</div>
      </div>}
      <div>
        {!isCustomPage && (
          <WPSButton
            loading={loading}
            disabled={loading}
            loadingColor='light'
            onClick={() => connectStripe()}
            className='selection-box-btn'>
            Connect
          </WPSButton>
        )}
        {isCustomPage && (
          <div className='display-flex-center-start margin-top-20'>
        <WPSButton
          className='create-light--btn margin-right-25'
          loading={loading}
          loadingColor='light'
          disabled={loading}
          onClick={connectStripe}>
          Connect
        </WPSButton>
        <WPSButton className='next-light--btn' disabled={true}>
          Next
        </WPSButton>
          </div>
        )}
      </div>
    </>
  );
};

export default StripeConnect;
