import {
  CUSTOMER_BILLING_SET_ALL,
  CUSTOMER_BILLING_PLAN_CREATE,
  CUSTOMER_BILLING_PLAN_UPDATE,
  CUSTOMER_BILLING_PLAN_DELETE,
  CUSTOMER_BILLING_SET_SUBSCRIPTIONS,
  CUSTOMER_BILLING_SUBSCRIPTION_CREATE,
  CUSTOMER_BILLING_SETTING_UPDATE,
  CUSTOMER_BILLING_SUBSCRIPTION_DELETE,
  CUSTOMER_BILLING_SUBSCRIPTION_CANCEL,
  CUSTOMER_BILLING_SUBSCRIPTION_UNCANCEL,
  CUSTOMER_BILLING_SUBSCRIPTION_UPDATE,
} from './customerBillingActions';
import ArrayHelper from '../../helpers/array';

const customerBillingReducer = (state = { subscriptions: [] }, action) => {
  switch (action.type) {
    case CUSTOMER_BILLING_SET_ALL:
      return {
        ...state,
        ...action.payload,
      };

    case CUSTOMER_BILLING_PLAN_CREATE:
      return {
        ...state,
        plans: [...state.plans, action.payload],
      };

    case CUSTOMER_BILLING_PLAN_UPDATE:
      const updatedPlans = state.plans.filter(plan => plan.slug !== action.payload.slug);
      return {
        ...state,
        plans: [...updatedPlans, action.payload],
      };

    case CUSTOMER_BILLING_PLAN_DELETE:
      const updatedState = state.plans.filter(plan => plan.slug !== action.payload.slug);

      return {
        ...state,
        plans: updatedState,
      };

    case CUSTOMER_BILLING_SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case CUSTOMER_BILLING_SUBSCRIPTION_DELETE:
      return {
        ...state,
        subscriptions: ArrayHelper.filterOut(state.subscriptions, 'guid', action.payload.guid),
      };

    case CUSTOMER_BILLING_SUBSCRIPTION_CANCEL:
      let updatedSubscriptions = [...state.subscriptions];
      let updateItemIndex = updatedSubscriptions.findIndex(
        item => item.guid === action.payload.guid,
      );
      if (updateItemIndex !== -1) {
        updatedSubscriptions[updateItemIndex] = { ...action.payload };
      }
      return {
        ...state,
        subscriptions: [...updatedSubscriptions],
      };
    case CUSTOMER_BILLING_SUBSCRIPTION_UPDATE:
      let customerUpdatedSubscriptions = [...state.subscriptions];
      let customerUpdateItemIndex = customerUpdatedSubscriptions.findIndex(
        item => item.guid === action.payload.guid,
      );
      if (customerUpdateItemIndex !== -1) {
        customerUpdatedSubscriptions[customerUpdateItemIndex] = { ...action.payload };
      }
      return {
        ...state,
        subscriptions: [...customerUpdatedSubscriptions],
      };

    case CUSTOMER_BILLING_SUBSCRIPTION_UNCANCEL:
      let _updatedSubscriptions = [...state.subscriptions];
      let _updateItemIndex = _updatedSubscriptions.findIndex(
        item => item.guid === action.payload.guid,
      );
      if (_updateItemIndex !== -1) {
        _updatedSubscriptions[_updateItemIndex] = { ...action.payload };
      }
      return {
        ...state,
        subscriptions: [..._updatedSubscriptions],
      };

    case CUSTOMER_BILLING_SUBSCRIPTION_CREATE:
      if (action.payload.customer_subscription) {
        return {
          ...state,
          subscriptions: [...state.subscriptions, action.payload.customer_subscription],
        };
      } else {
        return {
          ...state,
          subscriptions: [...state.subscriptions, action.payload],
        };
      }

    case CUSTOMER_BILLING_SETTING_UPDATE:
      return {
        ...state,
        settings: action.payload,
      };

    default:
      return state;
  }
};

export default customerBillingReducer;
