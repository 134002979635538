import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AuthContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.background.info};
  /* overflow: hidden; */
  svg {
    margin-top: auto;
    margin-bottom: 24px;
    max-width: 180px;
    max-height: 54px;
  }
  img {
    margin: auto;
  }

  .slide-container {
    width: 100%;
  }
  .custom-slider {
    padding: 50px 220px 100px;
  }
  .custom-slider svg {
    fill: white;
  }
  .custom-slider circle {
    width: 20px;
    height: 20px;
  }
  .custom-slider .slider-slide:focus {
    outline: none;
  }
  .custom-slider .slider-control-bottomcenter {
    top: 410px;
  }
  .custom-slider .slider-control-bottomcenter svg {
    margin-left: 15px;
  }
  .custom-slider .slider-control-centerright button {
    display: none;
  }
  .custom-slider .slider-control-centerright button {
    display: none;
  }
  .custom-slider .slider-control-centerleft button {
    display: none;
  }

  .side-bar {
    margin: auto;
    h1 {
      text-align: center;
      margin-top: 12px;
      font-weight: bold;
      color: white;
    }
  }
`;

export const FormWrapper = styled.div`
  flex: ${props => (props.flex ? props.flex : '2')};
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin: 25px 0 24px 0;
    font-size: 30px;
    font-weight: bold;
  }
  button {
    margin-top: 36px;
    min-width: 200px;
  }
  svg {
    max-width: 180px;
    max-height: 54px;
  }
  svg.logo-full {
    margin-top: 25%;
  }
  .secondary-action {
    color: ${({ theme }) => theme.color.link};
    text-decoration: underline;
    cursor: pointer;
    margin-top: 36px;
    display: inline-block;
  }
  .message {
    padding: 12px;
    background: #ececec;
    width: 360px;
    margin-top: 24px;
    &.success {
      border-left: 3px solid rgb(101, 204, 113);
    }
    &.error {
      border-left: 3px solid rgb(200, 1, 1);
    }
  }
  p.notes {
    max-width: 52ch;
    text-align: justify;
    font-size: 15px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  p.notes a {
    text-decoration: underline;
    color: #21279D;
  }
  .step-1 p.notes {
    margin-bottom: 0px !important;
  }
  .step-4 p.notes {
    margin-bottom: 35px !important;
  }
  .step-4 button[type='submit'] {
    width: 100%;
  }
  .agree-label, .newcard-label {
    display: flex;
    align-items: center;
    margin: 30px 0 8px 0;
    font-size: 11px;
    cursor: pointer;
    input {
      margin-right: 6px;
    }
  }
  .default-cc-tip {
    pointer-events: none;
    cursor: default;
    text-decoration: underline !important;
    color: black !important;
  }
  .terms-conds {
    color: ${({ theme }) => theme.color.link};
    text-decoration: underline;
    cursor: pointer;
    margin-top: 24px;
    margin-bottom: 50px;
    text-align: center;
    display: block;
    color: #21279D;
  }
  .payment-form {
    margin-top: 5%;
    max-width: 460px;
    input[type='text'] {
      min-width: 300px;
      height: auto;
    }
    button {
      align-self: center;
      margin-top: 6px;
      text-align: center;
      margin: auto;
    },
    button.prev {
      margin-right: 3px !important;
      width: 100%;
    }
    button.next {
      margin-left: 3px !important;
      width: 100%;
    }
    li {
      padding-bottom: 2px;
    }
    p.title {
      font-weight: bold;
    }
    .form-container {
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 6px;
        margin-top: 12px;
        display: flex;
        span {
          margin-left: 6px;
        }
      }
      .row {
        display: flex;
        span {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        span:first-child {
          flex: 2;
          margin-right: 8px;
        }
        input {
          min-width: auto;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 910px) {
    .payment-form {
    margin-top: 5%;
    max-width: 460px;
    padding: 0px 10px;
    
    .step-2 , .step-3 , .step-3 > div , .step-3 fieldset{
      width: 100%;
      min-width: auto !important;
    }
    input[type='text'] {
      min-width: auto;
      height: auto;
    }
    button {
      align-self: center;
      margin-top: 6px;
      text-align: center;
      margin: auto;
      min-width: auto !important;
    },
    button.prev {
      margin-right: 3px !important;
      width: 100%;
    }
    button.next {
      margin-left: 3px !important;
      width: 100%;
    }
    li {
      padding-bottom: 2px;
      min-width: auto;
    }
    p.title {
      font-weight: bold;
    }
    .form-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        margin-bottom: 6px;
        margin-top: 12px;
        display: flex;
        span {
          margin-left: 6px;
        }
      }
      .row {
        display: flex;
        span {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        span:first-child {
          flex: 2;
          margin-right: 8px;
        }
        input {
          min-width: auto;
          width: 100%;
        }
      }
    }
  }
    }
`;
