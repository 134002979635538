import React from "react"
import Icon from '../../layout/icon';

const UploadSQL = props => {

  const handleOnInput = e => e.target.id === 'sql_file' ? e.preventDefault() : props.onChange(e);
  const handleOnClick = id => document.getElementById(id).click()
  const placeholder = 'https://example.com/wp-content/example.sql';

  return (
    <div className="file-uploader">
      <div className="option file-option">
        <Icon className="icon" tag='fileupload' />
        <span>Upload File</span>
        <button
          className="create-light--btn"
          onClick={() => handleOnClick('sql_file')}>Browse
        </button>
        <input
          id='sql_file'
          className='display-none'
          type='file'
          accept='.sql, .sql.gz'
          onChange={props.onChange} />
      </div>
      <div className="option file-option">
        <span className='url-option'>...or upload via URL</span>
        <input
          className='download-url'
          id='download_url'
          placeholder={placeholder}
          onChange={handleOnInput} />
      </div>
    </div>
  )
};

export default UploadSQL;