import React, { useEffect, useState, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from "react-redux"
import CheckoutForm from './stripecheckoutform';
import Modal from 'components/layout/modal';
import { WPSForm, WPSToggle, ErrorMsg } from 'styles/layout/forms';
import { Spacer } from "styles/layout/grid";
import { useForm } from 'react-hook-form';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import env from 'config/env';

const defaultDetails = {
  is_default: false,
  agreeterms: false
}

const Payment = ({ show, onClose, stripeKey, onPaymentMethodCreated }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const stripeForm = useRef();
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });
  const [details, setDetails] = useState({ ...defaultDetails })

  useEffect(() => {
    setStripePromise(loadStripe(stripeKey));
  }, [stripeKey]);

  const handleClose = () => {
    if (onClose) {
      onClose()
    };
  };

  const onSubmit = async () => {
    if (stripeForm && stripeForm.current) {
      try {
        setLoading(true);
        const { paymentMethod } = await stripeForm.current.createPaymentMethod();
        if (onPaymentMethodCreated) {
          const res = await onPaymentMethodCreated({
            is_default: details.is_default,
            data: paymentMethod
          });
          if (!res) {
            setLoading(false);
            return;
          }
        }
        if (onClose) {
          onClose();
        }
        setDetails({ ...defaultDetails });
      } catch (err) {
        dispatch(setGlobalErrorMsg(err));
      }
    }
    setLoading(false);
  }

  const onChange = e => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  return (
    show ? <Modal
      title='Payment Method'
      onClose={handleClose}
      onConfirm={handleSubmit(onSubmit)}
      loading={loading}
    >

      {stripePromise && <Elements stripe={stripePromise}>
        <CheckoutForm ref={stripeForm} />
        <Spacer />
        <WPSForm.RowItem direction='row' minHeight='0' margin="0">
          <WPSToggle type='checkbox' name='is_default' onChange={onChange} checked={details.is_default}/>
          <span>Default</span>
        </WPSForm.RowItem>
        <Spacer />
        <WPSForm.RowItem direction='row' minHeight='0' margin="0">
          <WPSToggle 
            type='checkbox'
            name='agreeterms'
            onChange={onChange}
            ref={register({ required: 'This field is required' })}
            checked={details.agreeterms}
          />
          <span>Agree on Terms</span>
          {errors.agreeterms && <ErrorMsg>{errors.agreeterms.message}</ErrorMsg>}
        </WPSForm.RowItem>
        <Spacer />
        <p>I authorize WP {env.getBrandShortName()} to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</p>
      </Elements>}
      
    </Modal> : null
  );
};

export default Payment;