import React, { useState, useEffect, Fragment } from 'react';
import { DomainStep } from 'styles/website/profile';
import { WPSButton, WPSBubble } from 'styles/layout/buttons';
import { WPSForm } from 'styles/layout/forms';
import { useDispatch } from 'react-redux';
import {
  fetchWebsite
} from 'store/website/websiteActions';
import { isEmptyOrNull } from 'helpers';
import WebsiteHelper from 'helpers/website';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import WPSTableloader from 'components/wpstaq/WPSDataTable/WPSTableloader';
import env from 'config/env';
import { useHistory } from 'react-router-dom';
import 'components/stepper/stepper.css';

const ReviewStep = ({ website, showCache, hideStaqPanelBtn }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [statusDetails, setStatusDetails] = useState(false);
  const [sslPageOpened, setSslPageOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!sslPageOpened) {
      onRefreshWebsite();
      setSslPageOpened(true);
    }
    // eslint-disable-next-line
  }, [website]);

  const onRefreshWebsite = () => {
    const data = {
      website_slug: website.slug,
      check_status: true,
      check_hosting_provider: true
    }
    setLoading(true);
    dispatch(fetchWebsite(data))
    .then(updateReview)
    .finally(() => setLoading(false));
  }

  const updateReview = (_website) => {
    let details = {}

    // Primary domain
    details['Set your primary domain'] = {
      text: <span>Your custom domain {JsxHelper.createLink(_website.default_domain)} has been set as the primary URL of your WordPress site.</span>,
      color: 'success'
    }

    // Alternative domains
    if (_website.domains.length > 1) {
      const altDomains = _website.domains.filter(d => d !== _website.default_domain).map(d => {
        return (<Fragment>
          {JsxHelper.createLink(d)}
          <br/>
        </Fragment>);
      });
      details['Set your alternative domains'] = {
        text: <span>If someone visits any of these domains, it will automatically redirect back to the primary domain:<br/>{JsxHelper.createItemsList(altDomains)}</span>,
        color: 'success'
      }
    }

    // SSL status
    const sslDetails = WebsiteHelper.parseSSL(_website.domains, _website.domains_ssl);
    const sslLabel = `Secure your domains with a SSL certificate`;
    if (sslDetails.status === 'not found') {
      details[sslLabel] = {
        text: <span>The SSL certificate was not generated and the connection to your site is insecure. However, if you already configured the DNS as instructed, then it might be due to the domain's DNS has not been propagated yet, but {env.getBrandShortName()} will attempt to <b>auto-generate the SSL</b> in the upcoming minutes to keep your visitors safe.</span>,
        color: 'danger'
      }
    } else if (sslDetails.status === 'valid' && isEmptyOrNull(sslDetails.missingDomains)) {
      details[sslLabel] = {
        text: !sslDetails.isCustom
          ? <span>Your SSL certificate has been generated successfully and covers all of your custom domains. All user connections to your site are encrypted and secure. The certificate is valid until <b>{sslDetails.validTo}</b> and will <b>auto-renew</b> by {env.getBrandShortName()}.</span>
          : <span>Your SSL certificate is valid and covers all of your custom domains. All user connections to your site are encrypted and secure. The certificate is valid until <b>{sslDetails.validTo}</b> and you will need to <b>renew</b> manually.</span>,
        color: 'success'
      }
    } else if (sslDetails.status === 'valid' && !isEmptyOrNull(sslDetails.missingDomains)) {
      const insecureDomains = sslDetails.missingDomains.map(d => {
        return (<Fragment>
          {JsxHelper.createLink(d)}
          <br/>
        </Fragment>);
      });
      details[sslLabel] = {
        text: !sslDetails.isCustom
          ? <span>Your SSL certificate is valid but does not cover the following domains:<br/>{JsxHelper.createItemsList(insecureDomains)}However, if you have already configured the DNS as instructed, then it might be due to the domain's DNS has not been propagated yet, but {env.getBrandShortName()} will attempt to <b>auto-generate the SSL</b> in the upcoming minutes to keep your visitors safe.</span>
          : <span>Your SSL certificate is valid but does not cover the following domains:<br/>{JsxHelper.createItemsList(insecureDomains)}You will need to <b>renew</b> your SSL certificate manually.</span>,
        color: 'warning'
      }
    } else {
      details[sslLabel] = {
        text: <span>{sslDetails.tooltip}</span>,
        color: sslDetails.modalParams.type
      }
    }

    // Site connection
    const statusLabel = 'Verify your site is connected';
    if (_website.status === 'ok') {
      details[statusLabel] = {
        text: <span>Your primary domain {JsxHelper.createLink(_website.default_domain)} is connected and your WordPress site is live.</span>,
        color: 'success'
      }
    } else {
      details[statusLabel] = {
        text: <span>{env.getBrandShortName()} couldn't verify that your custom domain {JsxHelper.createLink(_website.default_domain)} is connected to your WordPress site. Please wait a few moments for the system to auto-refresh or use the "<b>Refresh Status</b>" button below to refresh your site's status.</span>,
        color: 'danger'
      }
    }

    // SMTP status
    const smtpDetails = WebsiteHelper.getSMTPMailerConfig(_website);
    const smtpLabel = 'Configure SMTP for email delivery';
    const smtpDesc = <span>to ensure that your WordPress site can send emails to your users and customers. {JsxHelper.createFaqLink('smtp', 'Configure SMTP settings.')}</span>
    if (smtpDetails && smtpDetails.smtp && smtpDetails.smtp.pass && smtpDetails.smtp.user) {
      details[smtpLabel] = {
        text: <span>Your SMTP settings are configured and ready to send emails {smtpDesc}</span>,
        color: 'success'
      }
    } else {
      details[smtpLabel] = {
        text: <span>Your SMTP settings are not configured yet {smtpDesc}</span>,
        color: 'warning'
      }
    }

    // CDN deployment
    const cdnLabel = 'Deploy CDN for max load time & security';
    const cdnDesc = <span>from over 200+ AWS data centers worldwide - powered by AWS CloudFront CDN - ensuring high-speed and low-latency performance for your visitors regardless of their geolocation, resulting in a significantly improved TTFB and protection against DDoS and abusive bots.</span>
    if (isEmptyOrNull(_website.cdns)) {
      details[cdnLabel] = {
        text: <span>Serve your site {cdnDesc} <span onClick={goToCdnPath} className='goto-link'>Deploy your Next-Gen CDN.</span></span>,
        color: 'warning'
      }
    } else {
      details[cdnLabel] = {
        text: <span>Your site is being served {cdnDesc}</span>,
        color: 'success'
      }
    }

    // Cache status
    if (showCache) {
      const cacheLabel = 'Use static page cache & site optimizer';
      if (_website.site_cached_by) {
        details[cacheLabel] = {
          text: <span>Your site is being cached by <b>{_website.site_cached_by}</b> which is great. Do not forget to enable the {JsxHelper.createFaqLink('setup-speed-optimization', 'Speed Optimization')} and {JsxHelper.createFaqLink('image-compression', 'Image Compression')} powerful features provided by {env.getBrandShortName()} to speed up your site even more. {JsxHelper.createLink('pagespeed.web.dev', 'Measure site quality with PageSpeed Insights.')}</span>,
          color: 'success'
        }
      } else {
        details[cacheLabel] = {
          text: <span>Your site has no page cache installed which can affect the experience of your website visitors. {JsxHelper.createFaqLink('configure-cache', 'Enable cache for your website.')}</span>,
          color: 'danger'
        }
      }
    }

    // Customer plan
    if (UserHelper.isPartner()) {
      const agencyPlanLabel = 'Assign your site to an agency plan';
      if (!isEmptyOrNull(_website.customer_subscription_guid)) {
        details[agencyPlanLabel] = {
          text: <span>Your site is already assigned to an agency plan and you are ready to start billing your client.</span>,
          color: 'success'
        }
      } else {
        details[agencyPlanLabel] = {
          text: <span><span onClick={goToCustomersPath} className='goto-link'>Assign</span> your site to an agency plan of your choice and start billing your client for hosting. {JsxHelper.createFaqLink('assigning-a-customer-to-your-agency-custom-plan', 'See instructions.')}</span>,
          color: 'warning'
        }
      }
    }

    setStatusDetails(details);
  }

  const goToStaqPanel = () => {
    history.push({
      pathname: `/websites/${website.slug}/overview`,
    });
  }

  const goToCdnPath = () => {
    history.push({
      pathname: `/websites/${website.slug}/cdn`,
    });
  };

  const goToCustomersPath = () => {
    history.push({
      pathname: `/clients`,
    });
  };

  return (<DomainStep className='status-step'>
    <WPSForm.Fieldset style={{ marginTop: '0px', marginBottom: '24px' }}>
      <legend>Review & Checklist</legend>
      <WPSForm.RowItem>
        <Fragment>
          {!loading && statusDetails && Object.keys(statusDetails).map(k => (
            <div className='checklist-row' key={k}>
              <WPSBubble
                display='inline'
                padding='0'
                margin='0'
                color={statusDetails[k].color}
                icon={statusDetails[k].color}>
              </WPSBubble>
              <span className='checklist-label'>{k}</span>
              <span className='checklist-line'>{statusDetails[k].text}</span>
            </div>
          ))}
          {loading && (
            <WPSTableloader/>
          )}
        </Fragment>
      </WPSForm.RowItem>
    </WPSForm.Fieldset>
    <div className='action-buttons' style={{ display: 'flex', marginTop: '35px' }}>
      <WPSButton style={{marginLeft: 0}} className='refresh--btn' loading={loading} onClick={onRefreshWebsite}>
        Refresh Status
      </WPSButton>
      {!hideStaqPanelBtn && <WPSButton disabled={loading} className='info--btn' onClick={goToStaqPanel}>
        Continue To Staq Panel
      </WPSButton>}
    </div>
  </DomainStep>)
}

export default ReviewStep;
