import React, { Fragment, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import DashboardCards from 'components/dashboard/dashboardCards';
import UserHelper from 'helpers/user';
import DialogHelper from 'helpers/dialog';
import DateHelper from 'helpers/date';
import ArrayHelper from 'helpers/array';
import useModal from 'hooks/useModal';
import { hasPaymentMethodsSelector } from 'store/billing/billingSelectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginPopupSelector } from 'store/notification/notificationSelectors';

const Dashboard = () => {
  useTitle('Dashboard');
  const history = useHistory();
  const modalDialog = useModal();
  const hasPaymentMethods = useSelector(hasPaymentMethodsSelector);
  const loginPopups = useSelector(loginPopupSelector);

  useEffect(() => {
    const renderedLoginPopup = renderLoginPopupNotification();
    if (!renderedLoginPopup) {
      renderPaymentMethodWarning();
    }
    // eslint-disable-next-line
  }, []);

  const renderLoginPopupNotification = () => {
    // Get the first login popup.
    const popup = ArrayHelper.first(loginPopups);
    if (!popup) {
      return false;
    }
    // Verify it wasn't shown to this browser before.
    const userEmail = UserHelper.getEmail() || '';
    const storageKey = `popup:${popup.guid}:${userEmail}`
    if (localStorage.getItem(storageKey)) {
      return false;
    }
    // Render popup and remember rendering to avoid showing it to user again in the future.
    localStorage.setItem(storageKey, DateHelper.toUTCFormat());
    DialogHelper.info(modalDialog, popup.message, '', {
      hideIcon: true,
      hideCloseBtn: popup.message.includes('hide-close-btn'),
    });
    return true;
  }

  const renderPaymentMethodWarning = () => {
    const isUserJustLoggedIn = localStorage.getItem('user-logged-in');
    if (UserHelper.isPartner() && !hasPaymentMethods && isUserJustLoggedIn) {
      localStorage.removeItem('user-logged-in');
      DialogHelper.popup(
        modalDialog,
        'Complete Profile',
        () => history.push({ pathname: '/billing-setup', }),
        'Oops! You haven\'t completed your billing profile.',
        'Please complete your billing profile to avoid any service interruptions in the future.',
        { closeBtn: false, modalCloseIcon: true },
        'warning'
      );
      return true;
    }
    return false;
  }

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title>
          <div style={{ display: 'flex' }}>
            Welcome, {UserHelper.getFirstName() || UserHelper.getDisplayName()}!
          </div>
        </TitleBar.Title>
      </TitleBar>
      <Content>
        <DashboardCards />
      </Content>
    </Fragment>
  );
};

export default Dashboard;
