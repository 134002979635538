import React, { useState, useEffect } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import SendGridService from 'services/sendgrid';
import { useDispatch } from 'react-redux';
import useTitle from 'hooks/useTitle';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import DialogHelper from 'helpers/dialog';
import StringHelper from 'helpers/string';
import SendGridHelper from 'helpers/sendgrid';
import 'components/stepper/stepper.css';
import { displayResponseError, isEmptyOrNull } from 'helpers';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';

const SendGrid = () => {
  useTitle('SendGrid');
  const breadcrumbs = JsxHelper.createBreadcrumbs('SendGrid', 'settings');
  const dispatch = useDispatch();
  const [modalLoading, setModalLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [verifyDomainLoading, setVerifyDomainLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const [accounts, setAccounts] = useState([]);
  const [domains, setDomains] = useState([]);
  const [domainsMode, setDomainsMode] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null); // {guid: '', email: ''}
  const confirm = useConfirm();

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line
  }, []);

  const handleErrorResponse = (error) => displayResponseError(modalDialog, error);

  // ------------------ ACCOUNT ------------------

  const fetchAccounts = () => {
    setTableLoading(true);
    SendGridService.listAccounts()
      .then(data => setAccounts(data))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  };

  const connectAccount = () => {
    setModalLoading(true);
    SendGridService.connectAccount({ api_key: modal.api_key })
      .then(newAccount => {
        setAccounts(prev => [...prev, newAccount]);
        setModal(false);
      })
      .catch(handleErrorResponse)
      .finally(() => setModalLoading(false))
  };

  const disconnectAccount = account => {
    setTableLoading(true);
    SendGridService.disconnectAccount({ guid: account.guid })
      .then(() => setAccounts(prev => prev.filter(item => item.guid !== account.guid)))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  }

  const refreshAccount = account => {
    setTableLoading(true);
    SendGridService.refreshAccount({ guid: account.guid })
      .then(data => setAccounts(prev => prev.map(item => item.guid === data.guid ? data : item)))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  }

  const backToAccounts = () => {
    setDomains(false);
    setDomainsMode(false);
  }

  const accountActions = [
    {
      value: 'Refresh Account',
      onClick: refreshAccount,
    },
    {
      value: 'Authenticated Domains',
      doHide: item => isEmptyOrNull(item.auth_domains),
      onClick: item => {
        setCurrentAccount(item);
        setDomains(item.auth_domains);
        setDomainsMode(true);
      }
    },
    {
      value: 'Disconnect',
      onClick: item => DialogHelper
        .confirmAction(confirm, 'disconnect', item.name, 'SendGrid Account', `This will disconnect your account from the ${env.getBrandShortName()} dashboard, but it will not affect the account on SendGrid's end.`)
        .then(() => disconnectAccount(item))
    },
  ];

  const accountHeaders = [
    {
      name: 'Account',
      selector: 'email',
      searchable: true,
      width: '50%',
      cell: row => JsxHelper.createTableMultiLineCell({
        header: row.email,
      })
    },
    JsxHelper.createTableTextHeaderWithCallback('type', 'Authenticated Domains', '30%', (row) => {
      const authDomains = row.auth_domains;
      if (isEmptyOrNull(authDomains)) return 'No domains';
      const valid = authDomains.filter(d => d.valid).length;
      const pending = authDomains.length - valid;
      return <div>
        {valid ? JsxHelper.createIconWithText({
          className: 'margin-top-5',
          icon: 'success',
          textColor: 'success',
          text: `${valid} verified ${StringHelper.maybePluraize('domain', valid)}`,
        }) : null}
        {pending ? JsxHelper.createWarningBox(`${StringHelper.maybePluraize('domain', pending, true)} pending verification`, true) : null}
      </div>
    }),
    JsxHelper.createTableActionsHeader(accountActions, '20%'),
  ];

  // ------------------ AUTHENTICATED DOMAIN ------------------

  const authenticateDomain = () => {
    setModalLoading(true);
    SendGridService.authenticateDomain({ guid: currentAccount.guid, domain: modal.domain, automated_security: modal.automated_security })
      .then((account) => {
        setAccounts(prev => prev.map(a => a.guid === account.guid ? account : a));
        setDomains(account.auth_domains);
        setCurrentAccount(account);
        setModal(false);
      })
      .catch(handleErrorResponse)
      .finally(() => setModalLoading(false))
  }

  const verifyDomainAuth = (item) => {
    setVerifyDomainLoading(item.id);
    SendGridService.verifyDomainRecords({ guid: currentAccount.guid, domain_id: item.id })
      .then((account) => {
        setAccounts(prev => prev.map(a => a.guid === account.guid ? account : a));
        setDomains(account.auth_domains);
        setCurrentAccount(account);
      })
      .catch(handleErrorResponse)
      .finally(() => setVerifyDomainLoading(false))
  }

  const domainsActions = [
    {
      value: 'Verify Domain',
      onClick: verifyDomainAuth,
      doHide: item => item.valid,
    },
    {
      value: 'DNS Records',
      onClick: (item) => SendGridHelper.openDNSRecordsModal(modalDialog, dispatch, verifyDomainAuth, item),
    },
  ];

  const domainsHeaders = [
    JsxHelper.createTableTextHeaderWithCallback('domain', 'Domain', '50%', (row) => {
      const fullDomain = `${row.subdomain}.${row.domain}`;
      return JsxHelper.createLink(`${SendGridHelper.SENDGRID_APP_URL}/settings/sender_auth/domain/get/${row.id}`, fullDomain, 'class-link no-underline font-bold')
    }),
    JsxHelper.createTableTextHeaderWithCallback('status', 'Status', '20%', (row) =>
      verifyDomainLoading === row.id
      ? JsxHelper.createLoadingSpinner('Verifying...', 15, '0 10px 0 0', 0, { position: 'relative', top: '-2px' })
      : JsxHelper.createIconWithText({
          icon: row.valid ? 'success' : 'danger',
          textColor: row.valid ? 'success' : 'danger',
          text: row.valid ? 'Verified' : 'Failed',
        })
    ),
    JsxHelper.createTableActionsHeader(domainsActions, '30%'),
  ];

  return (
    <div className='global-settings'>
      <TitleBar className='titlebar'>
        <TitleBar.Title breadcrumbs={domainsMode ? breadcrumbs.concat([{text: 'Accounts', link: '#'}]) : breadcrumbs}>
          {domainsMode ? currentAccount.email : 'SendGrid Accounts'}
        </TitleBar.Title>
        <TitleBar.Actions>
          {domainsMode && JsxHelper.createButton({
            label: 'Authenticate Domain',
            onClick: () => setModal({ name: 'create-domain', domain: '', automated_security: true } ),
            classes: 'success--btn'}
          )}
          {!domainsMode && JsxHelper.createButton({
            label: 'Connect Account',
            onClick: () => setModal({ name: 'create-account', api_key: '' })}
          )}
          {!domainsMode ? JsxHelper.createBackButton() : JsxHelper.createButton({
            label: 'Back',
            onClick: backToAccounts
          })}
        </TitleBar.Actions>
      </TitleBar>
      {!domainsMode && <p className='color-primary subheader'>
        {env.getBrandShortName()} offers a seamless integration with <b>{JsxHelper.createLink('https://sendgrid.com', 'SendGrid')}</b> that enables you to configure your email settings directly from the dashboard.
      </p>}
      {domainsMode && <p className='color-primary subheader'>
        Manage your authenticated domains for the <b>{currentAccount.email}</b> SendGrid account.
      </p>}
      <Content>
        {domainsMode && <div id='sg-domains' style={{width: '60%'}}>
          <WPSDataTable
            columns={domainsHeaders}
            body={domains}
            noSearchOnTable={true}
            hidePagination={true}
            loading={tableLoading}
          />
        </div>}
        {!domainsMode && <div id='sg-accounts' style={{width: '60%'}}>
          <WPSDataTable
            columns={accountHeaders}
            body={accounts}
            noSearchOnTable={true}
            hidePagination={true}
            loading={tableLoading}
          />
        </div>}
      </Content>
      {modal && modal.name === 'create-account' && DialogHelper.inputs({
        title: 'Connect SendGrid Account',
        className: 'instruct-modal',
        onClose: () => setModal(false),
        onConfirm: connectAccount,
        loading: modalLoading,
        icon: 'sendgrid',
        iconColor: 'info',
        confirmBtn: 'Connect',
        confirmColor: 'success',
        disabled: isEmptyOrNull(modal.api_key),
        header: SendGridHelper.renderConnectSteps(),
        inputs: [{
          label: 'API Key',
          name: 'api_key',
          placeholder: 'Enter your SendGrid API key',
          value: modal.api_key,
          onChange: e => setModal({ ...modal, api_key: e.target.value }),
          required: true,
        },
      ]})}
      {modal && modal.name === 'create-domain' && DialogHelper.inputs({
        title: 'Authenticate Your Domain',
        onClose: () => setModal(false),
        onConfirm: authenticateDomain,
        loading: modalLoading,
        iconColor: 'info',
        confirmBtn: 'Create',
        confirmColor: 'success',
        disabled: isEmptyOrNull(modal.domain),
        header: JsxHelper.createTipBox(
          'Domain authentication removes the "via sendgrid.net" text that some inbox providers append to your from address.',
          true
        ),
        inputs: [{
          label: 'From Domain',
          name: 'domain',
          placeholder: 'Enter the domain you send emails from', 
          value: modal.domain,
          onChange: e => setModal({ ...modal, domain: e.target.value }),
          required: true,
        }, {
          label: 'Automated Security',
          name: 'automated_security',
          type: 'checkbox',
          tooltip: 'When Enabled, we automatically rotate your DKIM keys so that they are impossible to break.',
          checked: modal.automated_security,
          onChange: e => setModal({ ...modal, automated_security: e.target.checked }),
        }]
      })}
    </div>
  );
};

export default SendGrid;
