import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableView from 'components/website/tableView';
import { TitleBar } from 'styles/layout/titlebar';
import { websitesSelector } from 'store/website/websiteSelectors';
import { isEmpty, handleFilterURLParamsChanges, convertToFilterObject, saveToSessionStorage, getFromSessionStorage } from 'helpers';
import { orderBy } from 'lodash';
import { useRef } from 'react';
import useOnClickOutside from 'utils/useOnClickOutside';
import useQuery from 'hooks/useQuery';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import ArrayHelper from 'helpers/array';
import WebsiteHelper from 'helpers/website';
import StringHelper from 'helpers/string';
import { fetchWebsites } from 'store/website/websiteActions';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import Can from '../../utils/can';
import JsxHelper from 'helpers/jsx';
import UserHelper from 'helpers/user';

const Websites = () => {
  useTitle('Websites');
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const websites = useSelector(websitesSelector);

  // State
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [openSort, setOpenSort] = useState(getFromSessionStorage('openSort'));
  const [sort, setSort] = useState(WebsiteHelper.sortingTypes[0].value);
  const [refreshLoading, setRefreshLoading] = useState(false);

  // Handle on Click outside for the filters menu
  const ref = useRef();
  useOnClickOutside(ref, () => setShowFilters(false));

  // Find and set filters for websites.
  useEffect(() => {
    if (!isEmpty(websites)) {
      // Filter status
      const statuses = websites.map(w => StringHelper.capitalizeFirstLetter(w.status.toString()));
      const wpVersions = websites.map(w => w.wp_version.toString());
      const cachePlugins = websites.map(w => w.site_cached_by ? w.site_cached_by : 'No cache');
      const ssl = websites.map(w => WebsiteHelper.parseSSL(w.domains, w.domains_ssl).tooltip);
      const envName = websites.map(w => WebsiteHelper.getEnvName(w));
      const cdns = websites.map(w => {
        const cdn = ArrayHelper.first(w.cdns);
        return cdn ? cdn : WebsiteHelper.getCloudflareZone(w)
      }).map(cdn => WebsiteHelper.getCdnStatus(cdn).filter);
      const state = websites.map(w => StringHelper.capitalizeFirstLetter(w.state));
      const redis = websites.map(w => w.objectcache_config && w.objectcache_config.enabled === 'yes' ? 'Enabled' : 'Disabled');
      const phpVer = websites.map(w => w.php_version);
      const server = websites.map(w => w.server_slug);
      const partner = websites.map(w => w.partner_slug);
      const alerts = websites.map(w => WebsiteHelper.getAlertLevel(w));

      setFilters(prev => ({
        ...prev,
        ...(UserHelper.isAdminOrAgent() && !isEmpty(server) && convertToFilterObject(query, 'Server', new Set(server))),
        ...(UserHelper.isAdminOrAgent() && !isEmpty(partner) && convertToFilterObject(query, 'Partner', new Set(partner))),
        ...(!isEmpty(state) && convertToFilterObject(query, 'State', new Set(state))),
        ...(!isEmpty(statuses) && convertToFilterObject(query, 'Status', new Set(statuses))),
        ...(!isEmpty(cachePlugins) && convertToFilterObject(query, 'Cache', new Set(cachePlugins))),
        ...(!isEmpty(redis) && convertToFilterObject(query, 'Redis', new Set(redis))),
        ...(!isEmpty(wpVersions) && convertToFilterObject(query, 'WP Version', new Set(wpVersions))),
        ...(!isEmpty(ssl) && convertToFilterObject(query, 'SSL', new Set(ssl))),
        ...(!isEmpty(cdns) && convertToFilterObject(query, 'CDN', new Set(cdns))),
        ...(!isEmpty(envName) && convertToFilterObject(query, 'Environment', new Set(envName))),
        ...(!isEmpty(phpVer) && convertToFilterObject(query, 'PHP Version', new Set(phpVer))),
        ...(!isEmpty(alerts) && convertToFilterObject(query, 'Alerts', new Set(alerts))),
      }));
    }
    // eslint-disable-next-line
  }, []);

  // On sorting change.
  useEffect(() => {
    // Sort by key
    const sortParts = sort.split(':');
    const sortKey = sortParts[0];
    const sortOrder = sortParts[1] || 'asc';
    setData(orderBy(websites, [ sortKey ], [ sortOrder ]));
    setRefreshLoading(false);
    // eslint-disable-next-line
  }, [sort, websites]);

  // Handle filters add/remove from URL.
  useEffect(() => {
    if (!isEmpty(data)) {
      handleFilterURLParamsChanges(filters, history);
    }
    // eslint-disable-next-line
  }, [filters]);

  // Handle sorting change.
  const onChangeSorting = e => {
    const { value } = e.target;
    setSort(value);
    setRefreshLoading(true);
  };

  // Update state when changing a filter checked state.
  const onClickFilter = e => {
    // id: filter.value, name: filter.name
    const { id, name, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: prev[name].map(i => (i.value === id ? { ...i, is_checked: checked } : i)),
    }));
  };

  const refreshWebsites = () => {
    setRefreshLoading(true);
    dispatch(fetchWebsites())
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'Websites table', action: 'refreshed' }));
      })
      .finally(() => setRefreshLoading(false));
  };

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Websites',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Websites</TitleBar.Title>
        <TitleBar.Actions style={{ position: 'relative' }}>
          {openSort && JsxHelper.createSelectInput({
            name: 'sortBy',
            value: sort,
            options: ArrayHelper.buildSelectOptions(WebsiteHelper.sortingTypes, 'name', 'value'),
            onChange: onChangeSorting,
            class: 'websites-table-sort-select',
          })}
          {!openSort && JsxHelper.createBubble({
            padding: '0',
            margin: '0',
            color: 'light',
            icon: 'sort',
            customClass: 'websites-table-refresh-icon',
            onClick: () => {
              setOpenSort(true);
              saveToSessionStorage('openSort', true);
            },
          })}
          {JsxHelper.createFilterIcon({
            setShowFilters,
            filters,
            showFilters,
            onClickFilter,
            filtersRef: ref,
            style: { marginLeft: '0' },
          })}
          {JsxHelper.createBubble({
            padding: '0',
            margin: '6',
            color: 'light',
            icon: 'refresh',
            customClass: 'websites-table-refresh-icon',
            loading: refreshLoading,
            onClick: refreshWebsites,
          })}
          <Can
            perform='website:create:*'
            yes={() => JsxHelper.createButton({ label: 'Create Site', linkTo: `/website` })}
          />
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <TableView websites={data || []} search={true} loading={refreshLoading} />
      </Content>
    </Fragment>
  );
};

export default Websites;
