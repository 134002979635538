import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TiInfoOutline } from 'react-icons/ti';
import { WPSCard } from 'styles/layout/cards';
import { WPSButton } from 'styles/layout/buttons';
import PackageService from 'services/package';
import StringHelper from 'helpers/string';
import { useSelector, useDispatch } from 'react-redux';
import UserHelper from 'helpers/user';
import ArrayHelper from 'helpers/array';
import UrlHelper from 'helpers/url';
import DialogHelper from 'helpers/dialog';
import { userSlug, partnerSelector } from 'store/me/meSelectors';
import { useHistory } from 'react-router-dom';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import PackageDataLoader from 'styles/contentLoaders/package';
import {
  createGlobalPackage,
  updateGlobalPackage
} from 'store/globalPackage/globalPackageActions';
import {
  createPartnerPackage,
  updatePartnerPackage,
} from 'store/partnerPackage/partnerPackageActions';
import {
  installPackage,
  fetchWebsites,
} from 'store/website/websiteActions';
import { isEmpty, versionCompare, sortDataAlph } from 'helpers';
import { WPSLabel, WPSCheckbox } from '../../styles/layout/forms';
import UploadPackage from "./UploadPackage/UploadPackage";
import Icon from '../../components/layout/icon';
// import Stepper from 'react-stepper-hosrizontal';
import { websitesListSelector, activeWebsite } from 'store/website/websiteSelectors';
import './save.css';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { partnersSelector } from 'store/user/userSelectors';
import PackageCard from './card'
import useModal from 'hooks/useModal';

const SavePackage = ({ type, website, currentPackage, onClickCancel, postUploadSuccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  /* eslint-disable no-unused-vars */
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [warningMsg, setWarningMsg] = useState();
  const myUserSlug = useSelector(userSlug);
  const websitesSelectOptions = useSelector(websitesListSelector);
  const packageSite = useSelector(activeWebsite(website));
  const allPartners = useSelector(partnersSelector);
  const myPartner = useSelector(partnerSelector);
  const [uploadCardOptions, setUploadCardOptions] = useState({
    package_type: type
  });

  let partnerSlug = null; // Admin: global plugins
  if (UserHelper.isAdminOrAgent()) {
    partnerSlug = UrlHelper.getQueryParam('partner');
  } else if (UserHelper.isPartner()) {
    partnerSlug = myUserSlug; // Partner: partner plugins
  } else if (UserHelper.isEmployee()) {
    partnerSlug = myPartner.slug; // Employee: partner plugins (if permission given)
  }

  const initialState = {
    package_slug: currentPackage ? currentPackage.slug : null,
    folder_name: currentPackage ? currentPackage.folder_name : '',
    partner_slug: currentPackage ? currentPackage.partner_slug : partnerSlug,
    website_slug: currentPackage ? currentPackage.website_slug : '',
    display_name: currentPackage ? currentPackage.display_name : '',
    zip_size: currentPackage ? currentPackage.zip_size : '',
    dir_size: currentPackage ? currentPackage.dir_size : '',
    local_src: currentPackage ? currentPackage.local_src : '',
    src: currentPackage ? currentPackage.src : '',
    version: currentPackage ? currentPackage.version : '',
    type: currentPackage ? currentPackage.type : '',
    description: currentPackage ? currentPackage.description : '',
    must_install: currentPackage ? currentPackage.must_install : false,
    flat_compress: currentPackage ? currentPackage.flat_compress : false,
    official_uri: currentPackage ? currentPackage.official_uri : '',
    author: currentPackage ? currentPackage.author : '',
    author_uri: currentPackage ? currentPackage.author_uri : '',
    parent_folder_name: currentPackage ? currentPackage.parent_folder_name : '',
    activate_on_websites: [],
  };

  const [fileData, setFileData] = useState(initialState);

  // Handle fetch form data Submit
  const generateFormData = (type, source) => {
    // Check if file upload or external URL
    let formData = new FormData();
    if (type === 'file') {
      formData.append('file', source);
    }
    if (type === 'src') {
      formData.append('src', source);
    }
    return formData;
  };

  // Fetch form data and submit it to local state
  const fetchFormData = async data => {
    if (!data) {
      return;
    }
    await PackageService.fetch(data)
      .then(res => {
        // Set file info to state
        setFileData(prev => ({
          ...prev,
          folder_name: res.FolderName,
          parent_folder_name: res.ParentThemeDir ? res.ParentThemeDir : null,
          partner_slug: currentPackage ? currentPackage.partner_slug : partnerSlug,
          website_slug: website ? website : null,
          display_name: res.Name,
          zip_size: res.ZipSizeInBytes,
          dir_size: res.DirSizeInBytes,
          local_src: res.LocalSource,
          src: res.Source,
          version: res.Version,
          type: res.Type,
          description: res.Description,
          must_install: currentPackage ? currentPackage.must_install : false,
          flat_compress: res.FlatCompression,
          official_uri: res.ThemeURI || res.PluginURI || res.URI,
          author: res.Author,
          author_uri: res.AuthorURI,
          activate_on_websites: [],
        }));
        if (!currentPackage) {
          return; // A brand new upload
        } else if (res.FolderName !== currentPackage.folder_name) {
          DialogHelper.warning(modalDialog, `You uploaded a different ${res.Type} from the one being updated.`);
        } else if (versionCompare(res.Version, currentPackage.version) === 0) {
          DialogHelper.warning(modalDialog, `You uploaded a ${res.Type} that has the same version.`);
        } else if (versionCompare(res.Version, currentPackage.version) < 0) {
          DialogHelper.warning(modalDialog, `The uploaded ${res.Type} has an older version.`);
        }
      })
      .catch(err => {
        setFileData(initialState);
        dispatch(setGlobalErrorMsg(err));
      });

    // Handle Warning msg
    if (type === 'theme' && !fileData.Type === 'theme') {
      setWarningMsg('You are about to save a plugin');
    } else if (type === 'plugin' && fileData.Type === 'theme') {
      setWarningMsg('You are about to save a theme');
    }
    setLoading(false);
  };

  // Handle file upload process with local file or external URL
  const handleFileUpload = e => {
    const { value, files, id } = e.target;
    let formData;
    // Handle local file upload
    if (id === 'package_file') {
      setLoading(true);
      if (files.length > 0) {
        let name = files[0].name;
        // Check if the file is .zip
        if (StringHelper.getFileExtension(name) === 'zip') {
          // Create form data object and dispatch action
          formData = generateFormData('file', files[0]);
        }
      }

      // Handle upload from external URL
    } else if (id === 'download_url' && value.endsWith('.zip')) {
      setLoading(true);
      // Trim all spaces from the URL.
      const trimmedValue = value.replace(/\s+/g, '');
      if (StringHelper.getFileExtension(trimmedValue) === 'zip') {
        formData = generateFormData('src', trimmedValue);
      }
    }

    fetchFormData(formData);
  };

  // Navigate to the correct page after package update.
  const onUploadSuccess = (display) => {
    if (type === 'core' && !website) {
      history.push('/wordpress');
    } else if (!website) {
      history.push(`/${type}s`);
    } else {
      postUploadSuccess && postUploadSuccess();
    }
    setSaving(false);
    dispatch(setGlobalSuccessMsg({ id: display, model: type }));
  };

  const handleUpload = () => {
    setSaving(true);
    const id = `${fileData.display_name} v${fileData.version}`;
    if (UserHelper.isAdminOrAgent() && !website && !fileData.partner_slug) {
      // new global package
      if (!currentPackage) {
        dispatch(createGlobalPackage(fileData))
          .then(() => onUploadSuccess(id))
          .catch(() => setSaving(false));
      }
      // create global package
      if (currentPackage) {
        dispatch(updateGlobalPackage(fileData))
          .then(() => onUploadSuccess(id))
          .catch(() => setSaving(false));
      }
    } else if (fileData.partner_slug && !website) {
      // create partner package
      if (!currentPackage) {
        dispatch(createPartnerPackage(fileData))
          .then(() => onUploadSuccess(id))
          .catch(() => setSaving(false));
      }
      // update partner package
      if (currentPackage) {
        dispatch(updatePartnerPackage(fileData))
          .then(() => onUploadSuccess(id))
          .catch(() => setSaving(false));
      }
    } else if (website) {
      // make sure site details are set
      fileData.partner_slug = packageSite.partner_slug;
      fileData.website_slug = packageSite.slug;
      // create private package
      dispatch(installPackage(fileData))
        .then(() => onUploadSuccess(id))
        .catch(() => setSaving(false));
    }

    // Fetch websites again if a must install plugin uploaded
    // Or a package is activated on one or more websites.
    if (fileData.must_install || !isEmpty(fileData.activate_on_websites)) {
      setTimeout(() => {
        dispatch(fetchWebsites());
      }, 3000);
    }
  };
  const toggleActivateOnSingleWebsite = (e) => {
    const { checked } = e.target;
    setFileData(prev => ({ ...prev, activate: checked }));
  }
  const toggleMustInstall = (e) => {
    const { checked } = e.target;
    if (checked) {
      setFileData(prev => ({ ...prev, activate_on_websites: [] }));
    }
    setFileData(prevState => ({ ...prevState, must_install: checked }));
  }
  const handleActivateOnWebsites = options => {
    const slugs = !isEmpty(options) ? options.target.values.map(el=>el.value) : [];
    setFileData(prev => ({ ...prev, activate_on_websites: slugs }));
  };
  const handleSelectPartner = (e) => {
    const { value } = e.target;
    setFileData(prev => ({ ...prev, partner_slug: value }));
  }

  return (
    <section>
      <UploadPackage options={uploadCardOptions} onChange={handleFileUpload} />
      <Fragment>
        {loading && (
          <WPSCard>
            <PackageDataLoader />
          </WPSCard>
        )}
        {!loading && fileData.display_name && (
          <Fragment>
            <PackageCard fileData={fileData}/>
            {type !== 'core' && (
              <div className='package-options'>
                <div className="package-card-title">
                  <Icon tag='multi_settings' />
                  <span>{type === 'theme' ? 'Theme Options' : 'Plugin Options'}</span>
                </div>
                <div className="options">
                  {fileData.type !== 'core' && !website ? <div>
                  <WPSLabel htmlFor="must_install">{type === 'theme' ? 'Set as default:' : 'Must install:'}</WPSLabel>
                    <WPSCheckbox
                      type='checkbox'
                      name='must_install'
                      checked={fileData.must_install}
                      onChange={toggleMustInstall}
                    />
                    
                  </div> : null}
                  {website ? <div>
                    <WPSLabel htmlFor="activate_package">Activate:</WPSLabel>
                    <WPSCheckbox
                      type='checkbox'
                      name='activate_package'
                      onChange={toggleActivateOnSingleWebsite}
                    />
                  </div> : null}
                  {!website && !currentPackage && UserHelper.isAdminOrAgent() ?
                    <div className='select-package-option'>
                      <WPSLabel htmlFor="partner_slug_option">Assign to partner:</WPSLabel>
                       <WPSSelect
                        name='partner_slug_option'
                        id='partner_slug_option'
                        value={fileData.partner_slug}
                        options={ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug')}
                        isSearchable={true}
                        onChange={handleSelectPartner}
                        disabled={partnerSlug}
                      />
                    </div> : null}
                  {!website && !fileData.must_install && fileData.type === 'plugin' ?
                    <div className='select-package-option'>
                      <WPSLabel htmlFor="activate_on_websites">Activate on websites:</WPSLabel>
                       <WPSSelect
                        name='activate_on_websites'
                        id='activate_on_websites'
                        value={fileData.activate_on_websites}
                        options={sortDataAlph(websitesSelectOptions)}
                        isMultiSelect = {true}
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        onChange={handleActivateOnWebsites}
                      />
                    </div> : null}
                </div>
                {warningMsg && (<div className='msg'><TiInfoOutline />{warningMsg}</div>)}
              </div>
            )}
            <div className='action-buttons display-flex-nowrap' style={{display: 'flex'}}>
              <WPSButton className='save--btn' onClick={handleUpload} disabled={saving} loading={saving}>Save</WPSButton>
              {onClickCancel && <WPSButton className='cancel--btn' disabled={saving} onClick={() => onClickCancel(false)}>Cancel</WPSButton>}
            </div>
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

SavePackage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SavePackage;
