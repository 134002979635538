import React from 'react';
import styled, { css } from 'styled-components';

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 0;
`;

export const WPSForm = styled.form`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  align-items: ${props => (props.direction === 'row' ? 'center' : 'initial')};

  label:not(:first-of-type) {
    margin: 18px 0 12px 0;
  }
  label:first-of-type {
    margin-bottom: ${props => (props.direction === 'row' ? '0' : '12px')};
    margin-right: ${props => (props.direction === 'row' ? '24px' : '0')};
  }
  button[type='submit'] {
    width: 50%;
    margin-top: ${props => (props.direction === 'row' ? '0' : '24px')};
    margin-left: ${props => (props.direction === 'row' ? '12px' : '0')};
    max-width: ${props => props.direction === 'row' && '40px'};
  }
  .inline-button {
    cursor: pointer;
    color: ${props => props.theme.color.link};
  }
`;

const Row = styled.div`
  display: flex;
  /* margin-bottom: 24px; */
`;

const RowItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  align-items: ${props => (props.direction ? 'center' : 'initial')};
  min-height: ${props => (props.minHeight ? `${props.minHeight}px` : '81px')};
  flex: ${props => (props.flex ? props.flex : 1)};
  margin: ${({ margin = '0 6px' }) => margin};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const Fieldset = styled.fieldset`
  padding: 16px;
  margin-top: 24px;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
  legend {
    font-size: 16px;
    padding: 0 12px;
  }
`;

WPSForm.Row = Row;
WPSForm.RowItem = RowItem;
WPSForm.Fieldset = Fieldset;

export const WPSInput = styled.input`
  min-width: 180px;
  height: 100%;
  background: ${props => props.theme.background.light};
  padding: 8px;
  color: inherit;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
  &::placeholder {
    color: ${props => props.theme.color.placeholder};
    font-style: italic;
  }
  &[type='radio'] {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: 50%;
    appearance: none;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      background: ${props => props.theme.color.primary};
      border-radius: 50%;
      display: none;
    }
    &:checked {
      &::after {
        display: block;
      }
    }
  }
  &[type='date'] {
    max-height: 34px;
  }
  &:disabled {
    color: ${props => props.theme.color.placeholder};
  }
`;

export const WPSInputWrapper = styled.fieldset`
  min-width: 180px;
  background: ${props => props.theme.background.light};
  padding: 8px;
  color: inherit;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
`;

export const WPSTextArea = styled.textarea`
  min-width: 180px;
  background: ${props => props.theme.background.light};
  padding: 8px;
  color: inherit;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
  &:disabled {
    color: ${props => '#bebebe'};
  }
`;

export const WPSModalInput = styled.input`
  color: ${props => props.theme.color.light};
  padding: 8px 0;
  border: none;
  background: none;
  border-bottom: 1px solid white;
`;

export const WPSModalLabel = styled.label`
  margin-top: 18px;
  font-size: 14px;
  color: ${props => props.theme.color.light};
`;

export const WPSLabelStyle = styled.label`
  display: flex;
  align-items: center;
  color: ${props => (props.disabled ? props.theme.color.placeholder : 'auto')};
  span {
    margin-left: 6px;
  }
  i.required {
    color: ${props => props.theme.color.danger};
    margin: 0 3px;
  }
  ${props =>
    props.radioLabel
      ? `
        display: flex;
        border: 1px solid ${props.theme.border.primary};
        padding: 8px 12px;
        align-items: center;
        input {
          margin-right: 8px;
        }
        background: #fff;
        border-radius: 5px;
        `
      : `margin-top: 18px;
         font-size: 14px;
         `}
`;

export const WPSCheckboxLabel = styled.label`
  color: ${({ disabled }) => disabled ? '#bebebe' : null};
  display: flex;
  align-items: center;
`;

export const WPSLabel = ({ children, required, ...rest }) => {
  return (
    <WPSLabelStyle required {...rest}>
      {children}
      {required && <i className='required'>*</i>}
    </WPSLabelStyle>
  );
};

export const WPSRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    label:not(:first-child) {
      margin: 0 12px;
    }
    label:first-of-type {
      margin: 0;
    }
  }
`;

export const WPSSelect = styled.select`
  min-width: 180px;
  /* height: 100%; */
  background: white;
  padding: 7px;
  color: inherit;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
  &:disabled {
    color: ${props => props.theme.color.placeholder};
  }
`;

export const WPSFileInput = styled.label`
  display: flex;
  align-items: center;
  height: 85px;
  padding: 16px;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};
  background: white;
  cursor: pointer;
  img {
    max-height: 100%;
    object-fit: contain;
    margin-right: 16px;
  }
  .btn {
    min-width: 120px;
    text-align: center;
    display: block;
    color: ${props => props.theme.color.light};
    padding: 9px 16px;
    background: ${props => props.theme.background.success};
    border-radius: ${props => props.theme.border.radius};
    height: auto;
    margin-right: 24px;
  }
  &:hover {
    background: ${props => props.theme.background.primaryHover};
  }
  input[type='file'] {
    display: none;
  }
`;

export const WPSToggle = styled.input`
  position: relative;
  width: 36px;
  height: 20px;
  background-color: ${props => props.theme.color.checkboxOff};
  appearance: none;
  border-radius: 20px;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  margin-right: 16px;
  border: 1px solid ${props => props.theme.border.primary};
  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.color.checkboxDisabled} !important;
  }
  &:checked {
    background-color: ${props => props.theme.color.checkboxOn};
    &::after {
      left: 17px;
    }
  }
  &::after {
    position: absolute;
    content: '';
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 50%;
    transition: left 0.2s ease-out;
    border: 1px solid ${props => props.theme.border.primary};
  }
`;

export const ErrorMsg = styled.span`
  color: ${props => props.theme.color.danger};
  margin-left: 24px;
`;

export const InputTip = styled.span`
  color: ${props => props.theme.color.info};
  margin-top: 3px;
  display: block;
`;

export const WPSCheckbox = styled.input`
  position: relative;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: 1px;
  background: white;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #d3d3d3 !important;
      border: 0;
    `}
  &::after {
    display: none;
    position: absolute;
    content: '';
    top: 4px;
    left: 3px;
    width: 10px;
    height: 5px;
    border: 2px solid white;
    transform: rotate(-45deg);
    border-top: 0px;
    border-right: 0px;
  }
  &:checked {
    background: ${props => props.theme.background.info};
    &::after {
      display: block;
    }
  }
`;

// Create an input field with a prefix or a suffix
export const WPSInputFix = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.border.primary};
  border-radius: ${props => props.theme.border.radius};

  .prefix,
  .suffix {
    padding: 8px;
    color: ${props => props.theme.color.placeholder};
    background: white;
    border-top-right-radius: ${props => props.theme.border.radius};
    border-bottom-right-radius: ${props => props.theme.border.radius};
  }
  input {
    flex: 1;
    order: 2;
    border: none;
  }
  .prefix {
    order: 1;
  }
  .suffix {
    order: 3;
  }
`;
