import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { newsSelector } from 'store/notification/notificationSelectors';

const NewsNotifications = () => {
  const items = useSelector(newsSelector);
  const itemData = items ? items.slice(0, 5) : [];

  return(
    <Fragment>
      <table className='notifications-table' id='news-table'>
        <colgroup>
          <col/>
          <col/>
          <col/>
        </colgroup>
        <tbody>
          {itemData.map((item, index) => {
            return (
              <tr key={index} className='notification-row'>
                <td className='notification-content'>
                  <div className='notification-label'>{item.title}</div>
                  <div className='notification-text'>{item.message}</div>
                </td>
                <td className='notification-icon'></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
}

export default NewsNotifications;