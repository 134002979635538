import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import UserHelper from 'helpers/user';
import SaveUser from 'components/user/save';
import SaveEmployee from 'components/employee/save';
import { useLocation } from 'react-router-dom';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const UserUpdate = () => {
  useTitle('User Update');
  // Get user details from location.state object.
  const { state } = useLocation();

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Users',
      link: '/users',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>
          Update User
        </TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {UserHelper.isAdminOrAgent() ? <SaveUser user={state} /> : <SaveEmployee employee={state} />}
      </Content>
    </Fragment>
  );
};

export default UserUpdate;
