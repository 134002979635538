import React from 'react';
import { useSelector } from 'react-redux';
import JsxHelper from 'helpers/jsx';
import { websitesSelector } from 'store/website/websiteSelectors';
import WebsiteHelper from 'helpers/website';
import { useHistory } from 'react-router-dom';

const QuickAccess = () => {
  const history = useHistory();
  const websites = useSelector(websitesSelector);
  const [trigger, setTrigger] = React.useState(0);
  const websiteOptions = WebsiteHelper.buildQuickAccessOptions(websites);

  return (
    <div style={{marginRight: '10px'}} key={trigger}>
      {JsxHelper.createSelectInput({
        name: 'search',
        placeholder: 'Website Search',
        isSearchable: true,
        options: websiteOptions,
        value: '',
        onChange: (e) => {
          setTrigger(trigger + 1);
          const slug = e.target.value.split(' ')[0];
          WebsiteHelper.goTo(history, slug)
        },
      })}
    </div>
  );
};

export default QuickAccess;
