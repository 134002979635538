import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse
// the query string.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useQueryResult = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const query = {};
  for (const [key, value] of searchParams.entries()) {
    query[key] = value;
  }
  return query;
};

export default useQuery;
