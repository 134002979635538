import { dispatchPostPromise, dispatchDeletePromise, dispatchGetPromise } from 'helpers';
import WebsiteService from 'services/website';
import CloudflareService from 'services/cloudflare';
import PackageService from 'services/package';

/*
  Action Types
*/
import { CUSTOMER_BILLING_SUBSCRIPTION_CREATE } from '../customerBilling/customerBillingActions';
import { BILLING_SUBSCRIPTION_CREATE } from '../billing/billingActions';
import { DNS_ZONE_UPDATE } from '../dnsZone/dnsZoneActions';
import SendGridService from 'services/sendgrid';
export const WEBSITE_SET_ALL = 'WEBSITE_SET_ALL';
export const WEBSITE_CREATE = 'WEBSITE_CREATE';
export const WEBSITE_UPDATE = 'WEBSITE_UPDATE';
export const WEBSITE_RECENT_UPDATE = 'WEBSITE_RECENT_UPDATE';
export const WEBSITE_DELETE_RECENT_UPDATES = 'WEBSITE_DELETE_RECENT_UPDATES';
export const WEBSITE_PACKAGE_SET_ALL = 'WEBSITE_PACKAGE_SET_ALL';
export const WEBSITE_PACKAGE_CREATE = 'WEBSITE_PACKAGE_CREATE';
export const WEBSITE_PACKAGE_UPDATE = 'WEBSITE_PACKAGE_UPDATE';
export const WEBSITE_PACKAGE_DELETE = 'WEBSITE_PACKAGE_DELETE';
export const WEBSITE_DELETE = 'WEBSITE_DELETE';
export const WEBSITE_CDN_CREATE = 'WEBSITE_CDN_CREATE';
export const WEBSITE_CDN_UPDATE = 'WEBSITE_CDN_UPDATE';
export const WEBSITE_CDN_DELETE = 'WEBSITE_CDN_DELETE';
export const WEBSITE_SSL_UPDATE = 'WEBSITE_SSL_UPDATE';
export const SET_WEBSITE_BACKUPS = 'SET_WEBSITE_BACKUPS';

/*
  Action Creators
*/

// Fetch all websites
export const fetchWebsites = data => {
  return dispatchGetPromise(WebsiteService.fetchAll(data), WEBSITE_SET_ALL);
};

// Fetch all websites
export const fetchWebsite = data => {
  return dispatchGetPromise(WebsiteService.fetchSingle(data), WEBSITE_UPDATE);
};

// Edit website label
export const updateWebsiteLabel = data => {
  return dispatchPostPromise(WebsiteService.updateLabel(data), WEBSITE_UPDATE);
};

// Create new website for Partners
export const createPartnerWebsite = data => {
  return dispatchPostPromise(WebsiteService.create(data), [
    WEBSITE_CREATE,
    CUSTOMER_BILLING_SUBSCRIPTION_CREATE,
    BILLING_SUBSCRIPTION_CREATE
  ]);
};

// Clone a website for Partners
export const clonePartnerWebsite = data => {
  return dispatchPostPromise(WebsiteService.clone(data), [
    WEBSITE_CREATE,
    CUSTOMER_BILLING_SUBSCRIPTION_CREATE,
    BILLING_SUBSCRIPTION_CREATE
  ]);
};

// Create new staging website for Partners
export const createPartnerWebsiteStaging = data => {
  return dispatchPostPromise(WebsiteService.createStaging(data), WEBSITE_CREATE);
};

// Update the selected (by slug) website default domain or add a new one
export const updateWebsiteDomains = data => {
  return dispatchPostPromise(WebsiteService.updateDomains(data), WEBSITE_UPDATE);
};

// Update a website's active theme
export const updateActiveTheme = data => {
  return dispatchPostPromise(WebsiteService.activateTheme(data), WEBSITE_UPDATE);
};

// Retrieve a website's packages info
export const listInstalledPackages = data => {
  return dispatchGetPromise(WebsiteService.listInstalledPackages(data));
};

// Activate website plugin
export const activateWebsitePlugin = data => {
  return dispatchPostPromise(WebsiteService.activatePlugin(data), WEBSITE_UPDATE);
};

// Deactivate website plugin
export const deactivateWebsitePlugin = data => {
  return dispatchPostPromise(WebsiteService.deactivatePlugin(data), WEBSITE_UPDATE);
};

// Delete specified website
export const deleteWebsite = website => {
  const data = { website_slug: website.slug };
  return dispatchDeletePromise(WebsiteService.delete(data), WEBSITE_DELETE, website);
};

// Install an unmanaged package.
export const installPackage = data => {
  return dispatchPostPromise(WebsiteService.installPackage(data), WEBSITE_UPDATE);
};

// Uninstall an unmanaged package.
export const uninstallPackage = data => {
  return dispatchPostPromise(WebsiteService.uninstallPackage(data), WEBSITE_UPDATE);
};

// Create a private package
export const createPrivatePackageFromGit = data => {
  return dispatchPostPromise(PackageService.createFromGit(data), WEBSITE_PACKAGE_CREATE);
};

// Update the package auto update flag
export const setPackageAutoUpdate = data => {
  return dispatchPostPromise(WebsiteService.setPackageAutoUpdate(data), WEBSITE_UPDATE);
};

// Delete a private package
export const deletePrivatePackage = item => {
  const data = { package_slug: item.slug };
  return dispatchDeletePromise(PackageService.delete(data), WEBSITE_PACKAGE_DELETE, item);
};

// Restore a website's backup
export const restoreWebsiteBackup = data => {
  return dispatchPostPromise(WebsiteService.restoreBackup(data), WEBSITE_UPDATE);
};

// fetch website backups
export const fetchWebsiteBackups = data => {
  return dispatchGetPromise(WebsiteService.fetchWebsiteBackups(data));
};

// fetch website sync requests
export const fetchWebsiteSyncRequests = data => {
  return dispatchGetPromise(WebsiteService.fetchSyncRequests(data));
};

// Toggle a website's debug mode
export const toggleWebsiteDebugMode = data => {
  return dispatchPostPromise(WebsiteService.debug(data), WEBSITE_UPDATE);
};

// Create a manual backup
export const createWebsiteBackup = data => {
  return dispatchPostPromise(WebsiteService.createBackup(data));
};

// Assign a DNS zone to the website
export const assignDNSZone = data => {
  return dispatchPostPromise(WebsiteService.assignDNSZone(data), [ WEBSITE_UPDATE, DNS_ZONE_UPDATE ]);
};

// Unassign a DNS zone to the website
export const unassignDNSZone = data => {
  return dispatchPostPromise(WebsiteService.unassignDNSZone(data), WEBSITE_UPDATE);
};

// Purge website cachce
export const purgeWebsiteCache = data => {
  return dispatchPostPromise(WebsiteService.purgeCache(data), WEBSITE_UPDATE);
};

// Replace a website's cache HTML
export const replaceWebsiteCacheHTML = data => {
  return dispatchPostPromise(WebsiteService.replaceCacheHTML(data), WEBSITE_UPDATE);
};

// Update a website's redirects rules
export const updateWebsiteRedirectRules = data => {
  return dispatchPostPromise(WebsiteService.updateRedirectRules(data), WEBSITE_UPDATE);
};

// fetch website ssl
export const fetchWebsiteSsl = data => {
  return dispatchGetPromise(WebsiteService.fetchSsl(data), WEBSITE_SSL_UPDATE);
};
// fetch website ssl
export const requestWebsiteSsl = data => {
  return dispatchPostPromise(WebsiteService.requestSsl(data), WEBSITE_SSL_UPDATE);
};

// Issue a website ssl certificate
export const issueWebsiteAcmCertificate = data => {
  return dispatchPostPromise(WebsiteService.issueAcmCertificate(data), WEBSITE_UPDATE);
};

// Revoke a website SSL certificate
export const syncWebsiteAcmCertificate = data => {
  return dispatchPostPromise(WebsiteService.syncAcmCertificate(data), WEBSITE_UPDATE);
};

// Revoke a website SSL certificate
export const revokeWebsiteAcmCertificate = data => {
  return dispatchPostPromise(WebsiteService.revokeAcmCertificate(data), WEBSITE_UPDATE);
};

// Deploy a website CDN proxy
export const deployWebsiteCdnProxy = data => {
  return dispatchPostPromise(WebsiteService.deployCdnProxy(data), [ WEBSITE_UPDATE, DNS_ZONE_UPDATE ]);
};

// Delete a website CDN proxy
export const deleteWebsiteCdnProxy = data => {
  return dispatchPostPromise(WebsiteService.deleteCdnProxy(data), [ WEBSITE_UPDATE, DNS_ZONE_UPDATE ]);
};

// Deploy a website CDN cache
export const deployWebsiteCdnCache = data => {
  return dispatchPostPromise(WebsiteService.deployCdnCache(data), [ WEBSITE_UPDATE, DNS_ZONE_UPDATE ]);
};

// Delete a website CDN cache
export const deleteWebsiteCdnCache = data => {
  return dispatchDeletePromise(WebsiteService.deleteCdnCache(data), WEBSITE_UPDATE);
};

// Sync a website CDN
export const syncWebsiteCdn = data => {
  return dispatchPostPromise(WebsiteService.syncCdn(data), WEBSITE_UPDATE);
}

// Update a website Wordpress version
export const updateWebsiteWPVersion = data => {
  return dispatchPostPromise(WebsiteService.updateWPVersion(data), WEBSITE_UPDATE);
};

// Update a website Wordpress version
export const updateWebsiteWPCoreAutoUpdate = data => {
  return dispatchPostPromise(WebsiteService.updateWPCoreAutoUpdate(data), WEBSITE_UPDATE);
};

// Run a website's WP CLI command
export const runWebsiteWPCLICommand = data => {
  return dispatchPostPromise(WebsiteService.WPCLICommand(data), WEBSITE_UPDATE);
};

// Test the website's PHP version
export const testWebsitePhpVersion = data => {
  return dispatchPostPromise(WebsiteService.testPHPVersion(data));
};

// Update a website's PHP config
export const updateWebsitePHPConfig = data => {
  return dispatchPostPromise(WebsiteService.updatePHPConfig(data), WEBSITE_UPDATE);
};

// Update a website's Nginx config
export const updateWebsiteNginxConfig = data => {
  return dispatchPostPromise(WebsiteService.updateNginxConfig(data), WEBSITE_UPDATE);
};

// Update a website's hide login config
export const updateWebsiteHideLoginConfig = data => {
  return dispatchPostPromise(WebsiteService.updateHideLoginConfig(data), WEBSITE_UPDATE);
};

// Update a website's object cache config
export const updateWebsiteObjectCacheConfig = data => {
  return dispatchPostPromise(WebsiteService.updateObjectCacheConfig(data), WEBSITE_UPDATE);
};

// Update a website's SMTP Mail config
export const updateWebsiteSMTPConfig = data => {
  return dispatchPostPromise(WebsiteService.updateWebsiteSMTPConfig(data), WEBSITE_UPDATE);
};

// Install SMTP Mail plugin
export const installSMTPPluginOnWebsite = data => {
  return dispatchPostPromise(WebsiteService.installSMTPPluginOnWebsite(data), WEBSITE_UPDATE);
};

// Update a website's WP defines (PHP constants)
export const updateWebsiteWPDefines = data => {
  return dispatchPostPromise(WebsiteService.updateWPDefines(data), WEBSITE_UPDATE);
};

// Update a website's cron jobs
export const updateWebsiteCronJobs = data => {
  return dispatchPostPromise(WebsiteService.updateCronJobs(data), WEBSITE_UPDATE);
};

// Update a website's cron jobs
export const unassignCustomer = data => {
  return dispatchPostPromise(WebsiteService.unassignCustomer(data), WEBSITE_UPDATE);
};

// Connect existing Cloudflare zone
export const connectWebsiteCloudflareZone = data => {
  return dispatchPostPromise(CloudflareService.connectZone(data), WEBSITE_UPDATE);
};

// Connect new Cloudflare zone
export const createWebsiteCloudflareZone = data => {
  return dispatchPostPromise(CloudflareService.createZone(data), WEBSITE_UPDATE);
};

// Delete existing Cloudflare zone
export const deleteWebsiteCloudflareZone = data => {
  return dispatchPostPromise(CloudflareService.deleteZone(data), WEBSITE_UPDATE);
};

// Sync Cloudflare zone
export const syncWebsiteCloudflareZone = data => {
  return dispatchPostPromise(CloudflareService.syncZone(data), WEBSITE_UPDATE);
};

// Refresh Cloudflare zone
export const refreshWebsiteCloudflareZone = data => {
  return dispatchPostPromise(CloudflareService.refreshZone(data), WEBSITE_UPDATE);
};

// Update a website's SendGrid SMTP Mail config
export const updateWebsiteSendGridSMTPConfig = data => {
  return dispatchPostPromise(SendGridService.updateWebsiteSMTPConfig(data), WEBSITE_UPDATE);
};

