import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websitesListSelector } from 'store/website/websiteSelectors';
import { isEmpty, sortDataAlph } from 'helpers';
import { WPSButton } from 'styles/layout/buttons';
import useConfirm from 'hooks/useConfirm';
import WebsiteService from 'services/website';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { WPSForm, WPSToggle, WPSLabel } from 'styles/layout/forms';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useHistory } from 'react-router-dom';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import DialogHelper from 'helpers/dialog';
import JsxHelper from 'helpers/jsx';

const BulkReset = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();

  const [details, setDetails] = useState({
    website_slugs: [],
    reset_all: false,
  });

  const [loading, setLoading] = useState(false);

  const websites = useSelector(websitesListSelector);

  const onChange = e => {
    const { name, checked } = e.target;
    setDetails(prev => ({ ...prev, [name]: checked }));
  };

  const onChangeSelect = options => {
    const slugs = !isEmpty(options) ? options.target.values.map(o => o.value) : [];
    setDetails(prev => ({ ...prev, website_slugs: slugs }));
  };

  const submit = () => DialogHelper
    .confirmAction(confirm, 'reset', '', 'default credentials')
    .then(() => {
      setLoading(true);
      WebsiteService.bulkReset(details)
        .then(() => dispatch(setGlobalSuccessMsg({ model: 'credentials', action: 'reset' })))
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => setLoading(false));
    });

  const handleBackBtnClick = () => history.goBack();

  const breadcrumbs = JsxHelper.createBreadcrumbs('Bulk Reset Website Credentials', 'tools');

  return (
    <Container className='margin-0'>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Bulk Reset Website Credentials</TitleBar.Title>
        <TitleBar.Actions>
          <WPSButton className='back--btn' onClick={handleBackBtnClick}>
            Back
          </WPSButton>
        </TitleBar.Actions>
      </TitleBar>
      <WPSForm className='margin-24' style={{ marginTop: '50px' }}>
        <p className='color-primary'>Allows a bulk reset of credentials across multiple website.</p>
        <Fragment>
          <WPSLabel>Websites</WPSLabel>
          <WPSSelect
            name='bulk_websites'
            value={details.website_slugs}
            options={sortDataAlph(websites)}
            isMultiSelect={true}
            closeMenuOnSelect={false}
            onChange={onChangeSelect}
            disabled={details.reset_all}
            isSearchable={true}
          />
        </Fragment>

        <WPSForm.RowItem className='margin-0' direction='row' minHeight='45'>
          <WPSToggle name='reset_all' type='checkbox' onChange={onChange} />
          <span>Reset all websites</span>
        </WPSForm.RowItem>
      </WPSForm>
      <WPSButton
        className='margin-24 warning--btn'
        style={{ maxWidth: '120px'}}
        onClick={submit}
        loading={loading}>
        Reset
      </WPSButton>
    </Container>
  );
};

export default BulkReset;
