import { postRequest, deleteRequest, getRequest } from 'config/axiosRequest';
import env from 'config/env';

const listAccounts = (data) => {
  return getRequest({
    url: env.buildApiEndpoint('sendgrid/accounts'),
    data,
  });
}

const connectAccount = (data) => {
  return postRequest({
    url: env.buildApiEndpoint('sendgrid/accounts'),
    data,
  });
}

const disconnectAccount = (data) => {
  return deleteRequest({
    url: env.buildApiEndpoint('sendgrid/accounts/' + data.guid),
    data,
  });
}

const refreshAccount = (data) => {
  return postRequest({
    url: env.buildApiEndpoint('sendgrid/accounts/' + data.guid + '/sync'),
    data,
  });
}

const verifyDomainRecords = (data) => {
  return postRequest({
    url: env.buildApiEndpoint('sendgrid/accounts/' + data.guid + '/verify-domain'),
    data,
  });
}

const authenticateDomain = (data) => {
  return postRequest({
    url: env.buildApiEndpoint('sendgrid/accounts/' + data.guid + '/auth-domain'),
    data,
  });
}

const updateWebsiteSMTPConfig = (data) => {
  return postRequest({
    url: env.buildApiEndpoint('sendgrid/accounts/' + data.guid + '/smtp-config'),
    data,
  });
}

const SendGridService = {
  listAccounts,
  connectAccount,
  disconnectAccount,
  refreshAccount,
  verifyDomainRecords,
  authenticateDomain,
  updateWebsiteSMTPConfig,
};

export default SendGridService;
