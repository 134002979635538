import React from 'react';
import { Container } from '../../../styles/website/profile';
import { Content } from '../../../styles/globalStyles';
import WebsiteHelper from '../../../helpers/website';
import SPanelCard from '../../wpstaq/sPanelCard/sPanelCard';
import './WebsiteOverview.css';

const WebsiteOverview = ({ website }) => {

  return (
    <Container>
      <Content id="spanel" className='display-flex'>
        {WebsiteHelper.staqPanelItems.map((item, index) => {
          return <SPanelCard website={website} key={index} cardData={item} />
        })}
      </Content>
    </Container>
  );
};

export default WebsiteOverview;
