import { dispatchGetPromise, dispatchPostPromise, dispatchDeletePromise } from '../../helpers';
import PlanService from '../../services/plan';
import SubscriptionService from '../../services/subscription';
import BillingService from 'services/billing';

/*
  Action Types
*/
import { WEBSITE_UPDATE } from '../website/websiteActions';
import CustomerService from 'services/customer';

export const CUSTOMER_BILLING_SET_ALL = 'CUSTOMER_BILLING_SET_ALL';
export const CUSTOMER_BILLING_INVOICE_SET_ALL = 'CUSTOMER_BILLING_INVOICE_SET_ALL';
export const CUSTOMER_BILLING_SET_SUBSCRIPTIONS = 'CUSTOMER_BILLING_SET_SUBSCRIPTIONS';
export const CUSTOMER_BILLING_PLAN_CREATE = 'CUSTOMER_BILLING_PLAN_CREATE';
export const CUSTOMER_BILLING_PLAN_UPDATE = 'CUSTOMER_BILLING_PLAN_UPDATE';
export const CUSTOMER_BILLING_PLAN_DELETE = 'CUSTOMER_BILLING_PLAN_DELETE';
export const CUSTOMER_BILLING_SUBSCRIPTION_CREATE = 'CUSTOMER_BILLING_SUBSCRIPTION_CREATE';
export const CUSTOMER_BILLING_SETTING_UPDATE = 'CUSTOMER_BILLING_SETTING_UPDATE';
export const CUSTOMER_BILLING_SUBSCRIPTION_DELETE = 'CUSTOMER_BILLING_SUBSCRIPTION_DELETE';
export const CUSTOMER_BILLING_SUBSCRIPTION_UNCANCEL = 'CUSTOMER_BILLING_SUBSCRIPTION_UNCANCEL';
export const CUSTOMER_BILLING_SUBSCRIPTION_CANCEL = 'CUSTOMER_BILLING_SUBSCRIPTION_CANCEL';
export const CUSTOMER_BILLING_SUBSCRIPTION_UPDATE = 'CUSTOMER_BILLING_SUBSCRIPTION_UPDATE';

/*
  Action Creators
*/

// Create a new customer plan
export const createCustomerPlan = data => {
  return dispatchPostPromise(PlanService.create(data), CUSTOMER_BILLING_PLAN_CREATE);
};

// Update a plan
export const updateCustomerPlan = data => {
  return dispatchPostPromise(PlanService.update(data), CUSTOMER_BILLING_PLAN_UPDATE);
};

// Delete a customer plan
export const deletePlan = plan => {
  const data = { plan_slug: plan.slug };
  return dispatchDeletePromise(PlanService.delete(data), CUSTOMER_BILLING_PLAN_DELETE, plan);
};

// Fetch all customers subscriptions
export const fetchCustomerSubscriptions = data => {
  return dispatchGetPromise(SubscriptionService.fetch(data), CUSTOMER_BILLING_SET_SUBSCRIPTIONS);
};

// Create a new subscription
export const createNewSubscription = data => {
  return dispatchPostPromise(SubscriptionService.create(data), [
    CUSTOMER_BILLING_SUBSCRIPTION_CREATE,
    WEBSITE_UPDATE,
  ]);
};

// Update Customer subscription
export const updateCustomerSubscription = data => {
  return dispatchPostPromise(
    SubscriptionService.update(data),
    CUSTOMER_BILLING_SUBSCRIPTION_UPDATE,
  );
};

// Delete a customer subscirption
export const deleteCustomerSubscription = data => {
  return dispatchDeletePromise(
    SubscriptionService.forceDelete(data),
    CUSTOMER_BILLING_SUBSCRIPTION_DELETE,
    data,
  );
};

// Cancel a customer susbcription
export const cancelCustomerSubscription = data => {
  return dispatchPostPromise(
    SubscriptionService.cancel(data),
    CUSTOMER_BILLING_SUBSCRIPTION_CANCEL,
  );
};

// UnCancel a customer susbcription
export const unCancelCustomerSubscription = data => {
  return dispatchPostPromise(
    SubscriptionService.uncancel(data),
    CUSTOMER_BILLING_SUBSCRIPTION_UNCANCEL,
  );
};

// Update billing settings
export const updateCustomerBillingSettings = data => {
  return dispatchPostPromise(
    BillingService.updateBillingSettings(data),
    CUSTOMER_BILLING_SETTING_UPDATE,
  );
};

// List all customers inovices.
export const getAllCustomersInvoices = data => {
  return dispatchGetPromise(CustomerService.fetchInvoices(data), CUSTOMER_BILLING_INVOICE_SET_ALL);
};
