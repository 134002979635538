import React, { useState, useEffect, Fragment } from 'react';
import { Container } from 'styles/website/profile';
import { useDispatch } from 'react-redux';
import { updateWebsiteDomains } from 'store/website/websiteActions';
import { TitleBar } from 'styles/layout/titlebar';
import { useForm } from 'react-hook-form';
import FormHelper from 'helpers/form';
import UrlHelper from 'helpers/url';
import JsxHelper from 'helpers/jsx';
import WebsiteHelper from 'helpers/website';
import ArrayHelper from 'helpers/array';
import DomainHelper from 'helpers/domain';
import { setGlobalErrorMsg, setGlobalSuccessMsg, setResourceIsBusyMsg } from 'store/global/globalActions';
import { isEmptyOrNull, isEmpty, isWebsiteBusy } from 'helpers';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useHistory, useParams } from 'react-router-dom';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import DialogHelper from 'helpers/dialog';
import WebsiteService from 'services/website';

const Domains = ({ website }) => {
  useTitle('Website Domains');
  const dispatch = useDispatch();
  const history = useHistory();
  const { slug } = useParams();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmitNewDomain' });
  const [hasUserInput, setHasUserInput] = useState(false);
  const [websiteDomains, setWebsiteDomains] = useState([]);
  const [resolvedDomains, setResolvedDomains] = useState({});
  const [newDomain, setNewDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const cdnProxySsl = WebsiteHelper.getCdnProxySsl(website);

  // ------------------------------
  // LIFECYCLE
  // ------------------------------

  useEffect(() => {
    if (!website.is_live) {
      history.replace(`/websites/${slug}/go-live`, null);
    } else {
      const domains = website.domains.map(d => ({ domain: d, is_default: d === website.default_domain }));
      initWebsiteDomains(domains, true, true);
    }
    // eslint-disable-next-line
  }, [website]);

  // ------------------------------
  // INITIALIZERS
  // ------------------------------

  const initWebsiteDomains = (domains, pageLoad, resolve) => {
    // Ignore the refresh if the user has inputted domains to avoid losing the user's input.
    if (pageLoad && hasUserInput) {
      window.logHelper.warning(`${slug} website model updated but ignored refresh due to user input.`);
      return;
    }
    // If the user edited domains then enable this flag.
    if (!pageLoad) {
      setHasUserInput(true);
    }
    domains = ArrayHelper.uniqueDeep(domains || [], 'domain');
    setWebsiteDomains(domains);
    // Resolve the domains on page load only (otherwise it will be resolved on demand).
    if (pageLoad || resolve) {
      resolveDns(domains.map(d => d.domain));
    }
  }

  // ------------------------------
  // DNS RESOLVER
  // ------------------------------

  const resolveDns = async (domains, forceResolve, isRefresh) => {
    // If no domains, do not resolve.
    if (isEmptyOrNull(domains)) {
      return;
    }
    // If force, include all domains.
    let _resolvedDomains = resolvedDomains;
    let toResolve = domains.filter(d => !_resolvedDomains[d]);
    if (forceResolve) {
      setResolvedDomains({});
      _resolvedDomains = {};
      toResolve = domains;
    }
    // Resolve the domains.
    isRefresh && setRefreshLoading(true);
    if (!isEmpty(toResolve)) {
      WebsiteService.testDomains({ website_slug: website.slug, domains: toResolve })
        .then((data) => {
          window.logHelper.info('Resolved domains:', data);
          setResolvedDomains({ ..._resolvedDomains, ...data });
        }).catch((error) => dispatch(setGlobalErrorMsg(error)))
        .finally(() => isRefresh && setRefreshLoading(false));
    }
  };

  // ------------------------------
  // BUTTON HANDLERS
  // ------------------------------

  const saveDomains = () => {
    // If the website is busy, do not allow the user to save the domains.
    if (isWebsiteBusy(website)) {
      dispatch(setResourceIsBusyMsg({ id: WebsiteHelper.getLabel(website), model: 'website' }));
      return;
    }
    // Save the domains.
    const data = {
      website_slug: website.slug,
      domains: websiteDomains.map(data => data.domain),
      default_domain: ArrayHelper.find(websiteDomains, 'is_default', true).domain,
      delay_propagation: true,
    };
    setLoading(true);
    dispatch(updateWebsiteDomains(data))
      .then((_website) => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'domain', action: 'updated' }));
        _website.is_live && WebsiteHelper.goToAdvanced(history, website.slug, 'ssl', '?action=1&origin=domains');
      }).finally(() => setLoading(false));
  };

  const onSubmitNewDomain = () => onAddNewDomain({
    domain: newDomain,
    is_default: !websiteDomains.some(d => d.is_default),
  });

  const refreshDomainsDNS = async () => resolveDns(websiteDomains.map(d => d.domain), true, true);

  const revertToDev = () => {
    const domain = WebsiteHelper.getDevDomain(website);
    onAddNewDomain({ domain, is_default: true }, true);
  }

  const onAddNewDomain = async (formData, revert) => {
    revert = revert || false; // Whether this is coming from the revert button.
    const domain = formData.domain;
    // Set the loading state.
    setAddLoading(true);
    if (revert) {
      setTableLoading(true);
    }
    // Add the new domain.
    const data = { domain, is_default: revert };
    initWebsiteDomains(!revert ? [...websiteDomains, data] : [data], false, true);
    // Reset the form and loading state.
    setNewDomain('');
    setTableLoading(false);
    setAddLoading(false);
    setModal(false);
  };

  const handleSetAsPrimary = e => {
    if (e.domain) {
      const newDomains = websiteDomains.map(d => ({ ...d, is_default: d.domain === e.domain }));
      initWebsiteDomains(newDomains);
    }
  };

  // ------------------------------
  // TABLE PROPS
  // ------------------------------

  const actions = [
    {
      value: 'Remove',
      doHide: item => item.is_default || (cdnProxySsl && cdnProxySsl.alt_domains.some(d => UrlHelper.areEquals(item.domain, d))),
      onClick: item => {
        const newDomains = websiteDomains.filter(d => d.domain !== item.domain);
        setResolvedDomains(prev => {
          delete prev[item.domain];
          return prev;
        });
        initWebsiteDomains(newDomains);
      },
    },
  ];

  const headers = [
    JsxHelper.createTableTextHeader('domain', 'Domain', '35%'),
    JsxHelper.createTableTextHeaderWithCallback('domain', 'Points To', '25%', row => {
      const status = resolvedDomains[row.domain] || false; // false = loading
      return DomainHelper.renderDnsManagerPointsTo2(status);
    }),
    JsxHelper.createTableTextHeaderWithCallback('is_default', 'Default', '15%', (row) => {
      return DomainHelper.renderDnsManagerPrimaryButtonCell(row, handleSetAsPrimary);
    }),
    JsxHelper.createTableActionsHeader(actions, '25%'),
  ];

  return (
    <Container>
      <TitleBar className='titlebar'>
        <TitleBar.Title>
          Domains
          <span style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Fragment>
              Add your domains below and point them to:
              {JsxHelper.createCopyButton({ value: website.public_ipv4, dispatch })}
            </Fragment>
          </span>
        </TitleBar.Title>
        <TitleBar.Actions>
          {website.is_live && JsxHelper.createButton({
            label: 'Revert To Dev',
            classes: 'alt--btn',
            onClick: revertToDev,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable columns={headers} body={websiteDomains} loading={tableLoading} dataKey='domain' noSearchOnTable={true}/>
        <div className='display-flex action-buttons margin-bottom-30'>
          {JsxHelper.createButton({
            label: 'Add Domain',
            classes: 'info--btn',
            disabled: loading || tableLoading,
            onClick: () => setModal(true),
          })}
          {JsxHelper.createButton({
            label: 'Refresh DNS',
            onClick: refreshDomainsDNS,
            loading: refreshLoading,
            disabled: loading || tableLoading,
          })}
          {JsxHelper.createButton({
            onClick: saveDomains,
            disabled: tableLoading,
            loading,
            label: 'Save',
          })}
        </div>
        {modal && DialogHelper.inputs({
          title: 'Add New Domain',
          confirmBtn: 'Add',
          onClose: () => setModal(false),
          onConfirm: handleSubmit(onSubmitNewDomain),
          loading: addLoading,
          inputs: [{
            type: 'text',
            label: 'Domain name',
            name: 'domain',
            required: true,
            placeholder: `Example: ${website.is_live ? `www.${website.registrable_domain}` : 'mydomain.com'}`,
            value: newDomain,
            onChange:  e => setNewDomain(e.target.value),
            ref: register({
              required: FormHelper.messages.required,
              pattern: {
                value: FormHelper.regex.domain,
                message: FormHelper.messages.invalidDomain,
              },
            }),
            errors,
          }]
        })}
      </Content>
    </Container>
  );
};

export default Domains;
