import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { updateWebsiteObjectCacheConfig } from 'store/website/websiteActions';
import { WPSForm, WPSLabel } from 'styles/layout/forms';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import Tooltip from 'components/layout/tooltip';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import DialogHelper from 'helpers/dialog';
import JsxHelper from 'helpers/jsx';
import useModal from 'hooks/useModal';
import WebsiteHelper from 'helpers/website';
import WebsiteService from 'services/website';

const RedisObjectCache = ({ website }) => {
    const dispatch = useDispatch();
    const userConfig = website.objectcache_config || {};
    const modalDialog = useModal();

    const [saveLoading, setSaveLoading] = useState(false);
    const [flushLoading, setFlushLoading] = useState(false);
    const [details, setDetails] = useState({
        enabled: userConfig.enabled || 'no',
        max_storage: userConfig.max_storage || '10M',
    });
  
    let configData = WebsiteHelper.getRedisObjectCacheConfigOptions();
  
    for (const i in configData) {
      const configName = configData[i].name;
      // Add name field to options
      configData[i].options = configData[i].options.map(o => ({...o, name: configName}));
      // Set selected value
      const userValue = details[configName];
      const selectedOption = configData[i].options.find(o => o.value === userValue)
      configData[i].value = {
        value: selectedOption ? selectedOption.value : configData[i].options[0].value,
        label: selectedOption ? selectedOption.label : configData[i].options[0].label
      }
    }

    const flushCache = () => {
      const data = {
        website_slug: website.slug,
      };
      setFlushLoading(true);
      WebsiteService.flushRedisCache(data)
        .then(() => dispatch(setGlobalSuccessMsg({ model: 'Redis cache', action: 'flushed', forId: WebsiteHelper.getLabel(website) })))
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => setFlushLoading(false));
    }
  
    const onSubmit = () => {
      if (details.enabled === 'yes') {
        const link = JsxHelper.convertToString(JsxHelper.createPlansLink());
        DialogHelper.popup(
          modalDialog,
          'Confirm',
          updateConfig,
          `Enabling Redis Object Caching is free for the first 10MB. For additional Redis memory, charges may apply according to your plan.`,
          `For detailed information, please visit our ${link} page.`,
          {},
          'warning'
        );
      } else {
        updateConfig();
      }
    };

    const updateConfig = () => {
      const data = {
        website_slug: website.slug,
        ...details,
      };
      setSaveLoading(true);
      dispatch(updateWebsiteObjectCacheConfig(data))
        .then(() => dispatch(setGlobalSuccessMsg({ model: 'Redis configuration', onId: WebsiteHelper.getLabel(website), action: 'updated', plural: true })))
        .catch(err => dispatch(setGlobalErrorMsg(err)))
        .finally(() => setSaveLoading(false));
    };
  
    const onChange = e => {
      const { name, value } = e.target;
      setDetails(prev => ({ ...prev, [name]: value }));
    };
  
    return (
      <Container className='margin-24'>
        <TitleBar className='titlebar padding-0'>
          <TitleBar.Title> Object Cache</TitleBar.Title>
        </TitleBar>
        <p className='color-primary subheader padding-left-0'>
            The Object Cache is powered by Redis, a fast and efficient in-memory caching system. By caching objects in memory, Redis offers a persistent cache that reduces the number of database queries needed for website operation, resulting in improved website performance.
        </p>
        <div style={{ maxWidth: '500px', marginTop: '20px' }}>
          {
            configData.filter(item =>
                !item.hide &&
                (details.enabled === 'yes' || (details.enabled === 'no' && item.name === 'enabled'))
              ).map((item, index) => {
              return (
                <WPSForm.RowItem key={index} margin='0px'>
                  <div className='display-inline-block'>
                    <WPSLabel required className='display-inline-block'>{item.title}:</WPSLabel>
                    <Tooltip place='right' text={item.desc} />
                  </div>
                  <WPSSelect
                    selectClass='secondary-box-select'
                    value={item.value ? item.value.value : null}
                    options={item.options}
                    onChange={onChange}
                    sortOff={true}
                  />
                </WPSForm.RowItem>
              );
            })
          }
        </div>
        <div className='display-flex action-buttons'>
          {JsxHelper.createButton({
            label: 'Save',
            loading: saveLoading,
            disabled: flushLoading,
            onClick: onSubmit
          })}
          {JsxHelper.createButton({
            label: 'Flush',
            loading: flushLoading,
            disabled: userConfig.enabled !== 'yes' || saveLoading,
            onClick: flushCache
          })}
        </div>
      </Container>
    );
};

export default RedisObjectCache;
