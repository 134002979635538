import React, { Fragment } from 'react';
import SavePackage from 'components/package/save';
import { TitleBar } from 'styles/layout/titlebar';
import { useLocation } from 'react-router-dom';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';

const UpdateTheme = () => {
  useTitle('Theme update');
  const { state } = useLocation();

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Themes',
      link: '/themes',
    },
    {
      text: `Update > ${state ? (state.display_name || 'N/A') : 'Unknown'}`,
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Update Theme</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <SavePackage currentPackage={state} type='theme' />
      </Content>
    </Fragment>
  );
};

export default UpdateTheme;
