import { createSelector } from 'reselect';
import { isEmpty } from '../../helpers';
import DateHelper from '../../helpers/date';

/*
  Input
*/
const plans = state => state.customers_billing.plans;
const planCategories = state => state.customers_billing.plan_categories;
const subscriptions = state => state.customers_billing.subscriptions;
const billingSettings = state => state.customers_billing.settings;
const invoices = state => state.customers_billing.invoices;

/*
  Selectors
*/

// Return all plans
export const customerPlansSelector = createSelector(plans, plans => plans);

// Return all plans by plan category
export const customerWebsitePlansSelector = createSelector(plans, plans => {
    return isEmpty(plans) ? [] : plans.filter(p => p.category_type === 'website');
});

// Return all non-assinged plans
export const nonAssignedCustomerWebsitePlans = createSelector(plans, plans => {
    return isEmpty(plans) ? [] : plans.filter(p => !p.assignee_slug && p.category_type === 'website');
});

// Return all customer plan categories
export const customerPlanCategories = createSelector(
  planCategories,
  planCategories => planCategories,
);

// Return the current billing period start date
export const customerBillingPeriodStartDate = createSelector(billingSettings, settings => {
  const billingDay = settings.billing_day;
  let d = new Date();
  if (d.getDate() > billingDay) {
    // The billing day of this month
    d.setDate(billingDay);
  } else {
    // The billing date of the previous month
    d.setMonth(d.getMonth() - 1);
    d.setDate(billingDay);
  }
  return DateHelper.getDateOnly(d);
})

// Return all specified customer plans
export const plansByCustomerSlug = customerSlug =>
  createSelector(plans, plans => plans.filter(plan => plan.assigne_slug === customerSlug));

// Return all customers subscriptions
export const customerSubscriptions = createSelector(subscriptions, subscriptions => subscriptions);

// Global customer billing
export const customerBillingSettings = createSelector(billingSettings, settings => settings);

// Return all customers inovices.
export const customersInvoices = createSelector(invoices, invoices => invoices);
