import React from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import ReviewStep from 'components/website/goLive/reviewStep';
import { WPSForm } from 'styles/layout/forms';
import env from 'config/env';

const WebsiteReview = ({ website }) => {
  useTitle('Website Review');

  return (
    <Container id='go-live-wizard'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>
          Website Review
        </TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        We have built {env.getBrandShortName()} from bottom to top as to provide you with all the necessary tools that help you take your WordPress site to the next level.
        To make sure you are not missing any of the benefits provided by {env.getBrandShortName()}, we have prepared the checklist below as a reference to help you review the
        most critical points that can speed up your site and keep your visitors secure.
      </p>
      <Content>
        <WPSForm className=' go-live-form' onSubmit={e => e.preventDefault()}>
          <ReviewStep website={website} showCache={true} hideStaqPanelBtn={true} />
        </WPSForm>
      </Content>
    </Container>
  );
};

export default WebsiteReview;
