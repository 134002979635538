import React, { useEffect, useState, Fragment } from 'react';
import { AnalyticsCard } from 'styles/website/profile';
import { WPSButton } from 'styles/layout/buttons';
import Icon from 'components/layout/icon';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { useDispatch } from 'react-redux';
import UserHelper from 'helpers/user';
import ArrayHelper from 'helpers/array';
import ObjectHelper from 'helpers/object';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import BillingService from 'services/billing';

const BillingAnalytics = () => {
  const dispatch = useDispatch();
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [months, setMonths] = useState(0);

  useEffect(() => {
    if (UserHelper.isPartner() || UserHelper.isAdmin()) {
      fetchData(6);
    }
    // eslint-disable-next-line
  }, []);

  const parseData = (data) => {
    let analytics = {
      chartData: [],
      currency: data.currency,
      total_invoiced: 0,
      count_paid: 0,
      count_unpaid: 0,
      count_subscribers: data.invoices ? ArrayHelper.unique(data.invoices.map(invoice => invoice.id.split('-')[0])).length : 0,
    };

    const dateToInvoices = ObjectHelper.groupBy(data.invoices, 'period_end_date');
    for (const date in dateToInvoices) {
      let unpaidTotal = 0;
      let unpaidCount = 0;
      let paidTotal = 0;
      let paidCount = 0;
      // Get data from invoice period
      for (const invoice of dateToInvoices[date]) {
        if (invoice.is_paid) {
          paidCount += 1;
          paidTotal += parseFloat(invoice.total);
        } else {
          unpaidCount += 1;
          unpaidTotal += parseFloat(invoice.total);
        }
      }
      paidTotal = parseFloat(paidTotal.toFixed(2));
      unpaidTotal = parseFloat(unpaidTotal.toFixed(2));
      // Add to graph data
      analytics.total_invoiced += (paidTotal + unpaidTotal);
      analytics.count_paid += paidCount;
      analytics.count_unpaid += unpaidCount;
      analytics.chartData.push({
        date: date,
        total_paid: paidTotal,
        total_unpaid: unpaidTotal,
        total_invoiced: (unpaidTotal + paidTotal).toFixed(2),
        count_paid: paidCount,
        count_unpaid: unpaidCount,
        count_invoices: paidCount + unpaidCount
      })
    }

    analytics.total_invoiced = analytics.total_invoiced.toFixed(2)

    return analytics;
  }

  const fetchData = (monthsAgo) => {
    setLoading(true);
    setMonths(monthsAgo);
    BillingService.fetchAnalytics({ months_ago: monthsAgo })
      .then(res => {
        setAnalyticsData(parseData(res));
      })
      .catch(err => {
        dispatch(setGlobalErrorMsg(err));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      {loading || !analyticsData ? (<div style={{ textAlign: 'center' }}>
        <div className='loadingspinner' style={{ margin: 'auto', height: '60px', width: '60px' }}></div>
        <div style={{ marginTop: '15px', fontSize: '15px' }}>Report is loading...</div>
      </div>) :
      (<div style={{ width: '100%' }}>
        <AnalyticsCard iconWidth='30px' iconHeight='30px' titleMargin='margin'>
          <li>
            <Icon tag='currency' />
            <h4 style={{ margin: '8px auto 0px'}}>Currency</h4>
            <span style={{ margin: '5px auto' }}>{analyticsData.currency}</span>
          </li>
          <li>
            <Icon tag='users' />
            <h4 style={{ margin: '8px auto 0px'}}>{UserHelper.isAdmin() ? 'Partners' : 'Clients'}</h4>
            <span style={{ margin: '5px auto' }}>{analyticsData.count_subscribers}</span>
          </li>
          <li>
            <Icon tag='invoice' />
            <h4 style={{ margin: '8px auto 0px'}}>Total Invoiced</h4>
            <span style={{ margin: '5px auto' }}>{analyticsData.total_invoiced} {analyticsData.currency}</span>
          </li>
          <li>
            <Icon tag='billing' />
            <h4 style={{ margin: '8px auto 0px'}}>Paid Invoices</h4>
            <span style={{ margin: '5px auto' }}>{analyticsData.count_paid}</span>
          </li>
          <li>
            <Icon tag='unpaid' />
            <h4 style={{ margin: '8px auto 0px'}}>Unpaid invoices</h4>
            <span style={{ margin: '5px auto' }}>{analyticsData.count_unpaid}</span>
          </li>
        </AnalyticsCard>
        <div className='analytics-filters'>
          <WPSButton
            className={months === 3 ? 'primary--btn' : 'disabled--btn'}
            onClick={() => fetchData(3)}
            loading={months === 3 && loading}>
            3 Months
          </WPSButton>
          <WPSButton
            className={months === 6 ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6'}
            onClick={() => fetchData(6)}
            loading={months === 6 && loading}>
            6 Months
          </WPSButton>
          <WPSButton
            className={months === 12 ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6'}
            onClick={() => fetchData(12)}
            loading={months === 12 && loading}>
            1 Year
          </WPSButton>
          <WPSButton
            className={months === 36 ? 'primary--btn margin-left-6' : 'disabled--btn margin-left-6'}
            onClick={() => fetchData(36)}
            loading={months === 36 && loading}>
            3 Years
          </WPSButton>
        </div>
        <ResponsiveContainer width='100%' height={250}>
          <LineChart data={analyticsData.chartData}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date' />
            <YAxis />
            <Tooltip />
            <Legend align='right' wrapperStyle={{ bottom: 0 }} />
            <Line type='monotone' name='Total invoiced amount' dataKey='total_invoiced' nstroke='#DB2763'/>
            <Line type='monotone' name='Total paid amount' dataKey='total_paid' stroke='#8884d8' />
            <Line type='monotone' name='Total unpaid amount' dataKey='total_unpaid' stroke='#B0DB43'/>
            <Line type='monotone' name='Total invoices' dataKey='count_invoices' stroke='#82ca9d'/>
          </LineChart>
        </ResponsiveContainer>
      </div>)}
    </Fragment>
  );
};

export default BillingAnalytics;
