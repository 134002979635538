import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm  } from 'styles/layout/forms';
import WebsiteService from 'services/website';
import { Row } from 'styles/layout/grid';
import Modal from 'components/layout/modal';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { getErrorMsg, isEmptyOrNull } from 'helpers';
import StringHelper from 'helpers/string';
import DialogHelper from 'helpers/dialog';
import WebsiteHelper from 'helpers/website';
import useConfirm from 'hooks/useConfirm';
import { Container } from 'styles/website/profile';
import useModal from 'hooks/useModal';
import JsxHelper from 'helpers/jsx';
import FormHelper from 'helpers/form';
import LocalStorageHelper from 'helpers/localStorage';

const ModalList = styled.ul`
  li {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }
  strong {
    min-width: 115px;
  }
  span {
    padding-left: 8px;
  }
`;

const SearchReplace = ({ website }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const modalDialog = useModal();
  const BYTES_IN_GB = 1073741824;
  const REFRESH_WAIT = 8000; // 8 seconds
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmitSendRequest' });
  const [processLoading, setProcessLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [fetchStatusLoading, setFetchStatusLoading] = useState(false);
  let retryingFetch = false;
  const [modal, setModal] = useState(false);
  const urlFormats = WebsiteHelper.urlFormats(website);
  const [dbTables, setDbTables] = useState(false);
  const [dbSize, setDbSize] = useState(0);
  const [currentResult, setCurrentResult] = useState(false);
  const mounted = useRef(true);
  const [details, setDetails] = useState({
    old: '',
    new: '',
    dry_run: false,
    include_tables: [],
    all_tables: true,
  });

  // ------------------------------
  // Component Life Cycle
  // ------------------------------

  useEffect(() => {
    if (!dbTables) {
      fetchDatabaseInfo();
      loadCachedResult();
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, [website]);

  // ------------------------------
  // API Calls
  // ------------------------------

  const fetchDatabaseInfo = () => {
    WebsiteService.getDatabaseInfo({ website_slug: website.slug })
    .then(res => {
      // Set the tables and their sizes sorted by size descending
      setDbTables(Object.keys(res.tables).map(table => ({
        label: table,
        size: res.tables[table],
        sizeHuman: StringHelper.convertBytes(res.tables[table]),
      })).sort((a, b) => b.size - a.size));
      // Calculate the total size of the database
      setDbSize(Object.values(res.tables).reduce((acc, size) => acc + size, 0));
    })
  };

  const sendSearchReplaceRequest = () => {
    setProcessLoading(true);
    const data = {
      website_slug: website.slug,
      old: details.old,
      new: details.new,
      tables: details.include_tables,
      dry_run: details.dry_run,
    };
    updateResult('Sending request to your website...', 'in_progress');
    WebsiteService.sendSearchReplaceDBRequest(data)
      .then(() => {
        updateResult('Retrieving search/replace status...', 'in_progress', data);
        getSearchReplaceStatus()
      }).catch((err) => updateResult(getErrorMsg(err), 'failed', err))
  }

  const getSearchReplaceStatus = () => {
    if (!mounted.current) {
      window.logHelper.warning('The search/replace status was not fetched because the component was unmounted.');
      return;
    }
    if (fetchStatusLoading) {
      window.logHelper.warning('The search/replace status was not fetched because the previous request is still in progress.');
      return;
    }
    setFetchStatusLoading(true);
    WebsiteService.getSearchReplaceDBStatus({ website_slug: website.slug })
      .then((res) => {
        retryingFetch = false;
        handleStatusResponse(res);
      }).catch((err) => {
        if (retryingFetch) {
          updateResult(getErrorMsg(err), 'failed', err)
        } else {
          retryingFetch = true;
          updateResult('Retrying to fetch the search/replace status after failure...', 'in_progress', err);
          getSearchReplaceStatus();
        }
      }).finally(() => setFetchStatusLoading(false));
  }

  // ------------------------------
  // Handlers
  // ------------------------------

  const loadCachedResult = () => {
    const cachedResult = LocalStorageHelper.read(`${website.slug}:searchReplaceDB`);
    if (cachedResult && ['success', 'in_progress'].includes(cachedResult.status)) {
      setCurrentResult(cachedResult);
      window.logHelper.info('The search/replace status was loaded from the cache.', cachedResult);
      if (cachedResult.status === 'in_progress') {
        setProcessLoading(true);
        setTimeout(getSearchReplaceStatus, REFRESH_WAIT);
        if (cachedResult.details) {
          setDetails(cachedResult.details);
        }
      }
    }
  }

  const updateResult = (content, status, data) => {
    data = data || null; // For debugging purposes
    window.logHelper.info('The search/replace status was updated:', { content, status, data });
    // If the status is not failed, save the result to the cache.
    if (status !== 'failed') {
      LocalStorageHelper.write(`${website.slug}:searchReplaceDB`, { status, content, details }, 30);
    } else {
      LocalStorageHelper.remove(`${website.slug}:searchReplaceDB`);
    }
    // Set the loading state
    setProcessLoading(status === 'in_progress');
    // Update the current result
    setCurrentResult({ status, content })
  }

  const handleStatusResponse = (res) => {
    // If the job has finished, stop loading.
    if (['succeeded', 'failed', 'stopped'].includes(res.status)) {
      setProcessLoading(false);
    }
    // If the job succeeded, parse and display the result.
    const dryRun = res.dry_run;
    if (res.status === 'succeeded') {
      const totalRowsReplaced = Object.values(res.processed_tables).reduce((acc, table) => acc + table.rows_affected, 0);
      const html = `<div>
        <p>The last job finished at ${res.finished_at_str}.</p>
        <p>In total, ${StringHelper.maybePluraize('table', res.total_tables, 'past')} processed and ${StringHelper.maybePluraize('row', totalRowsReplaced, 'past', dryRun)} updated.</p>
      </div>`;
      updateResult(html, 'success');
    } else if (res.status === 'failed') {
      updateResult('The process failed. Please try again or check your WordPress site for errors.', 'failed');
    } else if (res.status === 'stopped') {
      updateResult('The process was stopped.', 'stopped');
    } else if (res.status === 'in_progress') {
      const totalRowsReplaced = Object.values(res.processed_tables).reduce((acc, table) => acc + table.rows_affected, 0);
      const html = `<div>
        <p>Processing ${res.current_table} table...</p>
        <p>So far, ${StringHelper.maybePluraize('table', Object.keys(res.processed_tables).length, 'past')} processed and ${StringHelper.maybePluraize('row', totalRowsReplaced, 'past', dryRun)} updated.</p>
      </div>`;
      updateResult(html, 'in_progress');
      setTimeout(getSearchReplaceStatus, REFRESH_WAIT);
    } else {
      window.logHelper.warning('The status response was not handled:', res);
    }
  };

  const validateDataBeforeSubmit = () => {
    // If the old has "/" in end but new doesn't or vice versa, show a warning
    if (details.old.endsWith('/') !== details.new.endsWith('/')) {
      return 'The old and new strings have different trailing slashes.';
    }
    // If the old begins with "https?:\/\/" but new doesn't or vice versa, show a warning
    const isOldHttp = /^https?:\/\//.test(details.old);
    const isNewHttp = /^https?:\/\//.test(details.new);
    if (isOldHttp !== isNewHttp) {
      if (isOldHttp) {
        const protocol = details.old.startsWith('https') ? 'https://' : 'http://';
        return `You are trying to replace a URL (starts with ${protocol}) with a non-URL.`;
      }
      const protocol = details.new.startsWith('https') ? 'https://' : 'http://';
      return `You are trying to replace a non-URL with a URL (starts with ${protocol}).`;
    }
    // If old or new use http protocol, suggest to use // instead which is protocol-relative URL.
    if (isOldHttp || isNewHttp) {
      return 'It is recommended to use protocol-relative URLs (//example.com) instead of http:// or https://.';
    }
    // If old / new strings are wrapped in quotes, show a warning that they shouldn't be.
    const quotes = ['"', "'"];
    for (const quote of quotes) {
      if (details.old.startsWith(quote) && details.old.endsWith(quote)) {
        return 'The old string does not need to be wrapped in quotes.';
      } else if (details.new.startsWith(quote) && details.new.endsWith(quote)) {
        return 'The new string does not need to be wrapped in quotes.';
      }
    }
    return false;
  }

  const doSubmit = () => {
    if (!details.all_tables && isEmptyOrNull(details.include_tables)) {
      DialogHelper.warning(modalDialog, 'Please select at least one table or select all tables.');
      return; // Don't proceed if no tables are selected
    } else if (details.dry_run) {
      sendSearchReplaceRequest(); // Dry run doesn't need confirmation
    } else {
      DialogHelper.confirmAction(confirm, 'replace', '', 'database entries').then(sendSearchReplaceRequest)
    }
  }

  const onSubmitSendRequest = () => {
    const warning = validateDataBeforeSubmit();
    if (warning) {
      DialogHelper.confirmAction(confirm, 'replace', '', 'database entries', warning, true).then(doSubmit);
    } else {
      doSubmit();
    }
  };

  const onSubmitStopRequest = () => {
    DialogHelper.confirmAction(confirm, 'stop', '', 'Search/Replace', 'This will stop the search/replace process.', true).then(() => {
      setStopLoading(true);
      updateResult('Stopping the process...', 'in_progress');
      WebsiteService.stopSearchReplaceDBProcess({ website_slug: website.slug })
        .then(() => {
          updateResult('The process was stopped.', 'stopped')
          setProcessLoading(false);
          getSearchReplaceStatus();
        })
        .catch((err) => updateResult(getErrorMsg(err), 'failed', err))
        .finally(() => setStopLoading(false));
    });
  };

  const onChange = e => {
    const { name, type } = e.target;
    const value = type === 'checkbox'
      ? e.target.checked
      : (name === 'include_tables' ? e.target.values.map(i => i.value) : e.target.value);
    if (name === 'all_tables' && value) {
      setDetails(prev => ({ ...prev, include_tables: [] }));
    }
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title> Search-Replace</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader padding-left-0'>
        Searches through all rows in your site's database tables and replaces appearances of the old string with the new string.
      </p>
      {dbSize > BYTES_IN_GB && (
        <div className='warning-box'><strong>WARNING: </strong> The database size is over 1 GB ({StringHelper.convertBytes(dbSize)}). It is recommended to only search-replace tables that are necessary.</div>
      )}
      <WPSForm>
        {JsxHelper.createTextareaInput({
          name: 'old',
          label: 'Old',
          value: details.old,
          disabled: processLoading,
          onChange,
          register,
          errors,
          required: true,
          placeholder: 'Old string to search for',
          rows: 3,
          ref: register({
            required: FormHelper.messages.required,
            minLength: {
              value: 5,
              message: FormHelper.messages.minLength(5),
            }
          })
        })}
        {JsxHelper.createTextareaInput({
          name: 'new',
          label: 'New',
          value: details.new,
          disabled: processLoading,
          onChange,
          register,
          errors,
          required: true,
          placeholder: 'New string to replace with',
          rows: 3,
          ref: register({
            required: FormHelper.messages.required,
            minLength: {
              value: 5,
              message: FormHelper.messages.minLength(5),
            }
          })
        })}
        {!details.all_tables ? JsxHelper.createSelectInput({
          name: 'include_tables',
          label: 'Tables',
          value: details.include_tables,
          multiSelect: true,
          onChange,
          tooltip: 'Select the tables you want to search/replace in.',
          disabled: processLoading,
          options: (dbTables || []).map(table => ({ value: table.label, label: `${table.label} (${table.sizeHuman})` }))
        }) : null}
        <div style={{marginTop: '18px'}}></div>
        {JsxHelper.createCheckbox({
          name: 'dry_run',
          label: 'Dry run',
          style: { marginTop: '24px !important' },
          class: 'default-checkbox-label',
          checked: details.dry_run,
          onChange,
          tooltip: 'Run the entire search/replace process and show report, but don’t save changes to the database.',
          disabled: processLoading,
        })}
        {JsxHelper.createCheckbox({
          name: 'all_tables',
          label: 'All tables',
          class: 'default-checkbox-label',
          checked: details.all_tables,
          onChange,
          tooltip: 'Run the search/replace process on all tables (slow on large databases).',
          disabled: processLoading,
        })}
      </WPSForm>
      <Row className='action-buttons'>
        {JsxHelper.createButton({
          label: 'Common Strings',
          classes: 'primary--btn',
          onClick: () => setModal(true)
        })}
        {JsxHelper.createButton({
          label: 'Search & Replace',
          classes: 'execute--btn',
          onClick: handleSubmit(onSubmitSendRequest),
          disabled: processLoading,
        })}
        {processLoading ? JsxHelper.createButton({
          label: 'Stop',
          onClick: onSubmitStopRequest,
          disabled: stopLoading,
        }) : null}
      </Row>
      {currentResult && <Row className='margin-top-12'>
        {JsxHelper.createResultBox(currentResult.status, currentResult.content)}
      </Row>}
      {modal && (
        <Modal
          title='Common Strings'
          confirmBtn='Ok'
          onClose={() => setModal(false)}
          closeBtn='Close'>
          <ModalList>
            {Object.entries(urlFormats).map(([key, value]) => (
              <li key={key}>
                <strong>{key.replace(/-/g, ' ')}:</strong>
                {JsxHelper.createCopyButton({label: value, dispatch, value})}
              </li>
            ))}
          </ModalList>
        </Modal>
      )}
    </Container>
  );
};

export default SearchReplace;
