import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { WPSForm } from 'styles/layout/forms';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { updateGlobalSMTPConfig } from 'store/setting/settingActions';
import { globalSmtpMailerConfigSelector } from 'store/setting/settingSelectors';
import JsxHelper from 'helpers/jsx';
import SMTPHelper from 'helpers/smtp';
import 'components/website/website.css';

const GlobalSMTPMailer = () => {
  useTitle('Global SMTP Settings');

  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  const inputFields = SMTPHelper.getInputFields();
  const breadcrumbs = JsxHelper.createBreadcrumbs('SMTP', 'settings');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(1);
  const [userConfig, setUserConfig] = useState({ smtp: {}, mail: {}, plugin: {} });
  const globalSMTPConfig = useSelector(globalSmtpMailerConfigSelector);

  const isUseSettingsEnabled = () => userConfig.plugin?.use_settings;
  const fillInputFields = (_initialConfig) => {
    const formData = SMTPHelper.buildUserConfig(inputFields, _initialConfig, globalSMTPConfig);
    setUserConfig(formData);
    setTriggerRerender(triggerRerender + 1)
  };

  useEffect(() => {
    fillInputFields(globalSMTPConfig);
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e, group) => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    let groupData = { ...userConfig[group], [name]: value };
    setUserConfig(prev => ({ ...prev, [group]: groupData }));
  };

  const onSubmit = () => {
    const data = {
      config: userConfig,
    };
    delete data.config.plugin.ignore_globals; // unnecessary field (used for website settings).
    setLoading(true);
    dispatch(updateGlobalSMTPConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'SMTP global settings', action: 'updated', plural: true})))
      .finally(() => setLoading(false));
  };

  return (
  <Fragment>
    <TitleBar className='titlebar'>
      <TitleBar.Title breadcrumbs={breadcrumbs}>SMTP Settings</TitleBar.Title>
      <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
    </TitleBar>
    <Content className='smtp-mailer'>
      <WPSForm id='smtp-mailer-form' key={triggerRerender} onSubmit={handleSubmit(onSubmit)} noValidate>
        {SMTPHelper.renderPluginFields(inputFields, userConfig, handleOnChange, true, isUseSettingsEnabled())}
        {isUseSettingsEnabled() && SMTPHelper.renderCustomFormFields(inputFields, userConfig, handleOnChange)}
        {JsxHelper.createButton({ label: 'Save', onClick: onSubmit, style: { margin: '24px auto auto 0px' }, loading: loading })}
      </WPSForm>
    </Content>
  </Fragment>
)};

export default GlobalSMTPMailer;
