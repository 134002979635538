import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { updateWebsiteNginxConfig } from 'store/website/websiteActions';
import { updateGlobalNginxConfig } from 'store/setting/settingActions';
import { globalNginxConfig } from 'store/setting/settingSelectors';
import { WPSForm } from 'styles/layout/forms';
import { Content } from 'styles/globalStyles';
import { TitleBar, GroupTitle } from 'styles/layout/titlebar';
import WebsiteHelper from 'helpers/website';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import { useSelector } from 'react-redux';
import { isEmptyOrNull } from 'helpers';
import { Container } from 'styles/website/profile';

const NginxConfig = ({ website }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const globalConfig = useSelector(globalNginxConfig);
  const isGlobalPage = !website;
  const websiteConfig = website ? WebsiteHelper.getNginxConfig(website) : {};
  const [customHeadersView, setCustomHeadersView] = useState(website && !isEmptyOrNull(websiteConfig.custom_headers));
  const NGINX_CONFIG = WebsiteHelper.getNginxConfigOptions();
  const NGINX_DESC = 'Boost your WordPress website security and performance by configuring Nginx settings. ' 
    + (website
      ? 'These settings will override the global settings.'
      : 'These settings will be applied to all websites by default, but can be overridden on a per-website basis.');
  const TABS_INFO = [
    website ? { title: 'Headers', value: 'headers' } : null,
    { title: 'Access Control', value: 'access' },
    website ? { title: 'Advanced', value: 'advanced' } : null,
  ].filter(Boolean);
  const [currentTab, setCurrentTab] = useState(TABS_INFO[0].value);
  const initialUserConfig = website ? {
    x_frame_options: websiteConfig.x_frame_options,
    x_xss_protection: websiteConfig.x_xss_protection,
    content_security_policy: websiteConfig.content_security_policy,
    referrer_policy: websiteConfig.referrer_policy,
    ssl_buffer_size: websiteConfig.ssl_buffer_size,
    fastcgi_buffers: websiteConfig.fastcgi_buffers,
    fastcgi_buffer_size: websiteConfig.fastcgi_buffer_size,
    allow_seo_robots: websiteConfig.allow_seo_robots || 'default',
    allow_xmlrpc: websiteConfig.allow_xmlrpc || 'default',
    custom_headers: websiteConfig.custom_headers || '',
  } : {
    allow_seo_robots: globalConfig.allow_seo_robots || 'no',
    allow_xmlrpc: globalConfig.allow_xmlrpc || 'no',
  };

  const breadcrumbs = JsxHelper.createBreadcrumbs('Nginx Config', 'settings');

  const initDetails = (config) => {
    for (const key of Object.keys(config)) {
      if (!config[key] && key !== 'custom_headers') {
        const option = NGINX_CONFIG.find(option => option.name === key);
        if (!option) {
          window.logHelper.error(`Nginx option ${key} not found as a valid option`, config);
          continue;
        }
        config[key] = option.default || (option.options && option.options[0].value) || null;
      }
    }
    return config;
  }
  const [details, setDetails] = useState(initDetails(initialUserConfig));

  let configData = NGINX_CONFIG.filter(item => website ? item : ['allow_seo_robots', 'allow_xmlrpc'].includes(item.name));

  for (const i in configData) {
    const configName = configData[i].name;
    // Add name field to options
    configData[i].options = configData[i].options.map(o => ({...o, name: configName}));
    // Set selected value
    const userValue = details[configName];
    const selectedOption = configData[i].options.find(o => o.value === userValue)
    configData[i].value = {
      value: selectedOption ? selectedOption.value : configData[i].options[0].value,
      label: selectedOption ? selectedOption.label : configData[i].options[0].label
    }
  }

  const onSubmit = () => {
    if (website) {
      submitWebsiteData();
    } else {
      submitGlobalData();
    }
  };

  const submitGlobalData = () => {
    const data = {
      ...details,
    };
    setLoading(true);
    dispatch(updateGlobalNginxConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Nginx configuration', action: 'updated', plural: true })))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  }

  const submitWebsiteData = () => {
    const data = {
      website_slug: website.slug,
      ...details,
    };
    setLoading(true);
    dispatch(updateWebsiteNginxConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Nginx configuration', onId: WebsiteHelper.getLabel(website), action: 'updated', plural: true })))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  }

  const onChange = e => {
    const { name, value } = e.target;
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Container className={isGlobalPage ? 'global-settings margin-0' : 'margin-24'}>
      <TitleBar className={isGlobalPage ? '' : 'titlebar padding-0'}>
        <TitleBar.Title breadcrumbs={isGlobalPage ? breadcrumbs : null}>Nginx Config</TitleBar.Title>
        {isGlobalPage && <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>}
      </TitleBar>
      <p className={`color-primary subheader ${isGlobalPage?'':'padding-left-0'}`}>{NGINX_DESC}</p>
      <Content className={!isGlobalPage?'padding-left-0':''}>
        <GroupTitle>
          <GroupTitle.Filters>
            {TABS_INFO.filter(tab => !tab.disabled).map(tab => (
              <button
                key={tab.value}
                className={currentTab === tab.value ? 'active' : ''}
                onClick={() => setCurrentTab(tab.value)}>
                {tab.title}
              </button>
            ))}
          </GroupTitle.Filters>
        </GroupTitle>
        <div style={{ maxWidth: '550px', marginTop: '10px' }}>
          {currentTab === 'headers' && !customHeadersView && !isEmptyOrNull(details.custom_headers) && (
            JsxHelper.createWarningBox('Custom headers are set. Managed headers will be ignored.')
          )}
          {!UserHelper.isAdminOrAgent() && currentTab === 'headers' && customHeadersView && !isEmptyOrNull(details.custom_headers) && (
            JsxHelper.createTipBox('Want to modify custom headers? Please contact support.', false, 'Notice')
          )}
          {!UserHelper.isAdminOrAgent() && currentTab === 'headers' && !customHeadersView && isEmptyOrNull(details.custom_headers) && (
            JsxHelper.createTipBox('Want to add custom headers? Please contact support.', false, 'Notice')
          )}
          {currentTab === 'headers' && customHeadersView ?
            JsxHelper.createTextareaInput({
              label: 'Custom Headers',
              tooltip: 'Add custom headers to the Nginx configuration and override the managed headers.<br><br>To remove custom headers, clear the field.<br><br>Separate headers with a new line.',
              name: 'custom_headers',
              value: details.custom_headers,
              labelClass: 'margin-bottom-12',
              placeholder: 'Add custom headers here...',
              disabled: !UserHelper.isAdminOrAgent(),
              onChange,
            }) : configData.filter(item => !item.hide && item.category === currentTab).map((item, index) => 
              <WPSForm.RowItem key={index} margin='0px'>
                {JsxHelper.createSelectInput({
                  label: item.title,
                  labelClass: 'margin-bottom-12',
                  tooltip: item.desc,
                  selectClass: 'secondary-box-select',
                  name: item.name,
                  value: item.value ? item.value.value : null,
                  options: item.options,
                  onChange,
                  sortOff: true,
                })}
              </WPSForm.RowItem>
          )}
        </div>
        <div className='action-buttons display-flex-nowrap margin-bottom-30' style={{display: 'flex'}}>
          {JsxHelper.createButton({ label: 'Save', loading, onClick: onSubmit, })}
          {UserHelper.isAdminOrAgent() && currentTab === 'headers' && JsxHelper.createButton({
            label: `${customHeadersView ? 'Managed' : 'Custom'} Headers`,
            classes: `${customHeadersView ? 'warning' : 'alt'}--btn`,
            onClick: () => setCustomHeadersView(!customHeadersView),
          })}
          {!UserHelper.isAdminOrAgent() && customHeadersView && currentTab === 'headers' && JsxHelper.createButton({
            label: 'Remove Custom Headers',
            onClick: () => {
              setDetails(prev => ({ ...prev, custom_headers: '' }));
              setCustomHeadersView(false);
            }
          })}
        </div>
      </Content>
    </Container>
  );
};

export default NginxConfig;
