import PackageService from '../../services/package';
import { dispatchPostPromise, dispatchDeletePromise } from '../../helpers';

/*
Action Types
*/
export const PARTNER_PACKAGE_SET_ALL = 'PARTNER_PACKAGE_SET_ALL';
export const PARTNER_PACKAGE_CREATE = 'PARTNER_PACKAGE_CREATE';
export const PARTNER_PACKAGE_UPDATE = 'PARTNER_PACKAGE_UPDATE';
export const PARTNER_PACKAGE_DELETE = 'PARTNER_PACKAGE_DELETE';

/*
  Action Creators
*/

export const createPartnerPackage = data => {
  return dispatchPostPromise(PackageService.create(data), PARTNER_PACKAGE_CREATE);
};

export const createPartnerPackageFromGit = data => {
  return dispatchPostPromise(PackageService.createFromGit(data), PARTNER_PACKAGE_CREATE);
};

export const updatePartnerPackage = data => {
  return dispatchPostPromise(PackageService.update(data), PARTNER_PACKAGE_UPDATE);
};

export const updatePartnerPackageFromUrl = data => {
  return dispatchPostPromise(PackageService.updateFromUrl(data), PARTNER_PACKAGE_UPDATE);
};

export const deletePartnerPackage = data => {
  return dispatchDeletePromise(PackageService.delete(data), PARTNER_PACKAGE_DELETE, data);
};

export const setPartnerPackageAutoUpdate = data => {
  return dispatchPostPromise(PackageService.setAutoUpdate(data), PARTNER_PACKAGE_UPDATE);
};
