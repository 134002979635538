// API Services

// Helpers
import { dispatchPostPromise, dispatchDeletePromise } from '../../helpers';
import DNSZoneService from '../../services/dnsZone';

/*
  Action Types
*/
export const DNS_ZONE_SET_ALL = 'DNS_ZONE_SET_ALL';
export const DNS_ZONE_CREATE = 'DNS_ZONE_CREATE';
export const DNS_ZONE_UPDATE = 'DNS_ZONE_UPDATE';
export const DNS_ZONE_DELETE = 'DNS_ZONE_DELETE';
export const DNS_ZONE_RECORD_CREATE = 'DNS_ZONE_RECORD_CREATE';
export const DNS_ZONE_RECORD_DELETE = 'DNS_ZONE_RECORD_DELETE';
export const WEBSITE_UPDATE = 'WEBSITE_UPDATE';

/*
  Action Creators
*/

// Create a new dns zone
export const fetchAll = () => {
  return dispatchPostPromise(DNSZoneService.fetchAll(), DNS_ZONE_SET_ALL);
};

// Create a new dns zone
export const createDNSZone = data => {
  return dispatchPostPromise(DNSZoneService.create(data), [
    DNS_ZONE_CREATE,
    WEBSITE_UPDATE
  ]);
};

// Delete a specified dns zone
export const deleteDNSZone = zone => {
  const data = { dns_zone_slug: zone.slug };
  return dispatchDeletePromise(DNSZoneService.delete(data), DNS_ZONE_DELETE, zone);
};

// Create a new record.
export const createDNSRecord = data => {
  return dispatchPostPromise(DNSZoneService.createRecord(data), DNS_ZONE_RECORD_CREATE);
};

// Delete a DNS record
export const deleteDNSRecord = data => {
  return dispatchDeletePromise(DNSZoneService.deleteRecord(data), DNS_ZONE_RECORD_DELETE, data);
};

// Tests DNS NS records
export const testDNSNameservers = data => {
  return dispatchPostPromise(DNSZoneService.testNameservers(data), []);
};
