import React, { useRef, Fragment, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import SecondaryNav from 'components/layout/secondaryNav';
import RoutesRenderer from 'components/RoutesRenderer';

const AdminSettings = props => {
  const { path } = useRouteMatch();
  const history = useHistory();

  // Navigate to the first page is on /settings
  useEffect(() => {
    if (path === history.location.pathname) {
      const firstLink = props.links[0].slug;
      history.push(`${path}${firstLink}`);
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const links = useRef(props.links);
  const routes = useRef(props.routes);
  const components = useRef({ ...props.components  });

  return (
    <Fragment>
      <SecondaryNav links={links.current} />
      <RoutesRenderer routes={routes.current} components={components.current} />
    </Fragment>
  );
};

export default AdminSettings;
