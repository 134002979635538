import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const _indexToStroke = (index) => {
  const colors = ['#8884d8', '#82ca9d', '#DB2763', '#B0DB43', '#900C3F', '#C492B1', '#F9EBEA' ];
  return colors[index % colors.length];
}

const _calcMaxDomain = (data) => {
  // Get the max value from the data.
  let max = 0;
  for (let i = 0; i < data.length; i++) {
    for(const key in data[i]) {
      if (key !== 'date') {
        const value = parseFloat(data[i][key]);
        if (value > max) {
          max = value;
        }
      }
    }
  }
  // Round the max value to the nearest 10, 100, or 1000.
  if (max < 10) {
    max = Math.ceil(max);
  } else if (max < 100) {
    max = Math.ceil(max / 10) * 10;
  } else {
    max = Math.ceil(max / 100) * 100;
  }
  return max;
}

const renderGraph = (title, data, keys, unit) => {
  return <div>
    <h3 className='color-primary'>{title}</h3>
    <LineChart
      width={600}
      height={400}
      data={data}
      margin={{ top: 5, right: 0, left: 0, bottom: 30 }}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='date' />
      <YAxis allowDecimals={true} domain={[0, _calcMaxDomain(data)]} />
      <Tooltip />
      <Legend align='right' wrapperStyle={{ bottom: 0 }} />
      {keys.map((keyData, i) => <Line key={i} type='monotone' name={keyData.label} dataKey={keyData.key} stroke={_indexToStroke(i)} unit={unit ? ` ${unit || 'MB'}` : ''} />)}
    </LineChart>
  </div>
}

const ChartHelper = {
  renderGraph
};  

export default ChartHelper;
