import React from 'react';
import ConnectGit from "./Git/Connect";

const GitConnectPackage = ({ type }) => {
  return (
    <section>
      <ConnectGit type={type} />
    </section>
  );
}

export default GitConnectPackage;