import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PackageService from 'services/package';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { timezoneSelector } from 'store/me/meSelectors';
import ArrayHelper from 'helpers/array';
import StringHelper from 'helpers/string';
import { getStatusColor } from 'helpers';
import JsxHelper from 'helpers/jsx';
import PackageHelper from 'helpers/package';

const PackageHistory = ({ packageSlug }) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const timezone = useSelector(timezoneSelector);
  const mounted = useRef(true)

  useEffect(() => {
    fetchHistoryRecords();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const fetchHistoryRecords = () => {
    const data = {
      package_slug: packageSlug,
    };
    setLoading(true);
    PackageService.getUpdateHistory(data)
      .then((res) => {
        setTableData(ArrayHelper.reverse(res));
      }).finally(() => {
        setLoading(false);
      });
  }

  const headers = [
    JsxHelper.createTableTimeHeader('created_at', timezone),
    {
      name: 'Action',
      selector: 'action',
      width: '18%',
      cell: PackageHelper.renderActionCell,
    },
    {
      name: 'Version',
      selector: 'old_version',
      width: '10%',
      cell: row => JsxHelper.createTableCellFromTo({
        from: row.old_version,
        to: row.new_version,
      }),
    },
    {
      name: 'Source',
      selector: 'source',
      width: '7%',
      cell: row => JsxHelper.createIcon({
        icon: row.url ? 'linkicon' : 'fileupload',
        tooltip: row.url ? row.url : 'File upload',
        color: row.url ? 'info' : 'primary',
      }),
    },
    {
      name: 'Status',
      selector: 'status',
      width: '7%',
      cell: row => {
        let tooltip = StringHelper.capitalizeFirstLetter(row.status);
        if (row.status === 'error' && row.error) {
          const reason = row.metadata ? row.metadata.post_healthcheck : null;
          if (reason) {
            tooltip = `${StringHelper.trimRight(row.error, '.')}: ${reason}`;
          }
        }
        return JsxHelper.createBubble({
          color: getStatusColor(row),
          icon: getStatusColor(row),
          tooltip
        });
      },
    },
  ];

  return (<WPSDataTable
    customClass='package-history-table'
    columns={headers}
    body={tableData}
    loading={loading}
    noSearchOnTable={true}
    rowsPerPage={100}
    dataKey={'created_at'}
  />)
}

export default PackageHistory;
