import { postRequest, getRequest } from 'config/axiosRequest';
import env from 'config/env';

// List terms and conditions pages
const fetchTermsAndConditions = data => {
  return getRequest({
    url: env.buildApiEndpoint('legal/terms-and-conditions'),
    data,
  });
};

// Save terms and conditions pages
const saveTermsAndConditions = data => {
  return postRequest({
    url: env.buildApiEndpoint(`legal/terms-and-conditions/${data.name}`),
    data,
  });
};

// Get terms and conditions page content
const fetchGuestPage = data => {
  return getRequest({
    url: env.guestApiEndpoint(`legal/terms-and-conditions/${data.page}`),
    data,
  });
};

const LegalService = { fetchTermsAndConditions, saveTermsAndConditions, fetchGuestPage };

export default LegalService;
