import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthed = useSelector(state => state.auth.isAuthed);
  return (
    <Route
      render={() => (isAuthed ? <Route {...rest}>{children}</Route> : <Redirect to={'/login'} />)}
    ></Route>
  );
};

export default PrivateRoute;
