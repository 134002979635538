import React from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { WPSInputWrapper, WPSForm, WPSLabel, WPSCheckbox, ErrorMsg } from 'styles/layout/forms';
import { WPSButton } from 'styles/layout/buttons';
import { useState } from 'react';

const StripeFormCard = ({ setStripeRes, setStep, setAddNewCard }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = {
    style: {
      base: {
        color: '#000',
      },
    },
  };

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    try {
      const res = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (res.error) {
        setError(res.error.message);
        return;
      }
      setStripeRes(res.paymentMethod);
    } catch (error) {
      setError(error.message || 'Oops! Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '300px' }}>
      <WPSLabel style={{marginTop:'5px'}} required>Card number</WPSLabel>
      <WPSInputWrapper>
        <CardNumberElement options={options} />
      </WPSInputWrapper>
      <WPSForm.Row>
        <WPSForm.RowItem>
          <WPSLabel required>Expiry date</WPSLabel>
          <WPSInputWrapper style={{ flex: '2' }}>
            <CardExpiryElement options={options} />
          </WPSInputWrapper>
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          <WPSLabel required>CVC</WPSLabel>
          <WPSInputWrapper style={{ flex: '1' }}>
            <CardCvcElement options={options} />
          </WPSInputWrapper>
        </WPSForm.RowItem>
      </WPSForm.Row>
      {error && <ErrorMsg style={{ textAlign: 'center', marginTop: '12px' }}>{error}</ErrorMsg>}
      {setAddNewCard && (
        <label className='newcard-label' htmlFor='newcard'>
          <WPSCheckbox
            type='checkbox'
            id='newcard'
            checked={false}
            onChange={() => setAddNewCard(false)}
          />
          I want to keep my default credit card.
        </label>
      )}
      {!setAddNewCard && (
        <div style={{marginBottom:'20px'}}></div>
      )}
      <div style={{display:'inline-flex',marginTop: '0px'}}>
        <WPSButton
          className='prev prev--btn'
          type='button'
          onClick={() => setStep(2)}>
          Previous
        </WPSButton>
        <WPSButton
          type='button'
          onClick={handleSubmit}
          disabled={!stripe}
          loading={loading}
          className='next success2'>
          Next
        </WPSButton>
        </div>
      </div>
  );
};

export default StripeFormCard;
