import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  customerPlansSelector,
  customerPlanCategories,
} from 'store/customerBilling/customerBillingSelectors';
import { deletePlan } from 'store/customerBilling/customerBillingActions';
import { customersSelector } from 'store/customer/customerSelectors';
import useConfirm from 'hooks/useConfirm';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const Plan = () => {
  useTitle('Agency Plans');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const customerPlans = useSelector(customerPlansSelector);
  const planCategories = useSelector(customerPlanCategories);
  const customers = useSelector(customersSelector);

  const actions = [
    {
      value: 'Subscribers',
      onClick: plan => {
        history.push(`${plan.slug}/subscriptions`);
      },
    },
    {
      value: 'Update',
      onClick: plan => {
        history.push({
          pathname: `/clients/${plan.slug}/update`,
          state: plan,
        });
      },
    },
    {
      value: 'Delete',
      onClick: plan => DialogHelper
        .confirmDelete(confirm, plan.name, 'plan')
        .then(() => dispatch(deletePlan(plan)))
    },
  ];

  const headers = [
    {
      name: 'Category',
      selector: 'category_slug',
      sortable: true,
      searchable: true,
      width: '25%',
      cell: row => {
        const plan = planCategories.filter(plan => plan.slug === row.category_slug)[0];
        TableHelper.customizeCellValue(row, 'category_slug', plan.name + ' ' + row.name);
        return JsxHelper.createTableMultiLineCell({
          header: row.name,
          subheader: plan.name,
        });
      },
    },
    JsxHelper.createTableTextHeader('price', 'Price', '10%'),
    {
      name: 'Assigned To',
      selector: 'assignee_slug',
      sortable: true,
      searchable: true,
      width: '20%',
      cell: row => {
        row = TableHelper.customizeCellValue(row, 'assignee_slug', '');
        if (row.assignee_slug) {
          const customer = customers.find(customer => customer.slug === row.assignee_slug);
          row.custom_assignee_slug = customer ? customer.display_name : '-';
        } else {
          row.custom_assignee_slug = 'Any';
        }
        return JsxHelper.createBubble({
          text: row.custom_assignee_slug,
          background: row.custom_assignee_slug === 'Any' ? 'info' : 'alternative',
          tooltip: row.custom_assignee_slug === 'Any'
            ? 'This plan is available to all clients'
            : 'This plan is available to a specific client',
          small: true,
        });
      },
    },
    JsxHelper.createTableActionsHeader(actions, '45%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
      link: '/clients',
    },
    {
      text: 'Agency Plans',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Agency Plans</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({ label: 'Add Plan', linkTo: `/clients/plan/create` })}
          {JsxHelper.createBackButton('/clients')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable columns={headers} body={customerPlans || []}/>
      </Content>
    </Fragment>
  );
};

export default Plan;
